import { ERROR_FILE_INVALID } from '../../const/text';
import { saveFile } from '../api/files';
import { refreshToken } from "../crud/refreshToken";
import errorMessage from "../errorMessage";

/**
 * Load file and get url on success
 *
 * @param file
 * @param onSuccess
 * @param errorText
 * @returns {Promise<void>}
 */
const loadFile = async (file, onSuccess, errorText = '') => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    // set data
    const resp = await saveFile(formData);
    if (onSuccess) onSuccess(resp.data.path);
  } catch (err) {
    console.log(errorText);
    alert(ERROR_FILE_INVALID + errorText);
    if(err?.response?.status === 401) {
      await refreshToken(async () => {
        const resp = await saveFile(formData);
        if (onSuccess) onSuccess(resp.data.path);
      })
    } else {
      await errorMessage(err, '')
    }
  }
};

export default loadFile;
