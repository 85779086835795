import { getDeliveries } from "../../lib/api/delivery";
import { getDomains } from '../../lib/api/domains';

export const getFiltersData = () => Promise.allSettled([
    getDomains(),
])
    .then((res) => ({
        domains: res[0]?.value?.data,
    }));

export const getData = getDeliveries;
