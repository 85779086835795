import { getCooperationB2B, getCooperationB2BStatus, getCooperationB2BTypes } from '../../lib/api/cooperation_b2b';
import { getAdminsAll } from "../../lib/api/admins";

export const getFiltersData = () => Promise.allSettled([
    getCooperationB2BStatus(), getCooperationB2BTypes(), getAdminsAll()
])
    .then((res) => ({
        statuses: res[0]?.value?.data,
        types: res[1]?.value?.data,
        managers: res[2]?.value?.data?.data
    }));

export const getData = getCooperationB2B;
