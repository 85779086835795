import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { getProduct } from '../../lib/api/products';
import InputFetchResult from '../../components/ui/InputFetchResult';
import { INPUT_MAX_LENGTH } from "../../const/view";
import { APPLICATION_STATUS, APPLICATION_TYPES } from "../../lib/const/product";
import ColFull from "../../components/ui/FormEditView/ColFull";
import Button from "../../components/ui/Button";
import {
  createOrderProductFeedback,
  editProductFeedback
} from "../../lib/api/product_feedback";
import s from "./ProductFeedbackEdit.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import { DEVELOPMENT_ENVIRONMENT, DOMAIN_API } from "../../const/api";
import { Helmet } from "react-helmet";
import errorMessage from "../../lib/errorMessage";
import { parseAll } from "../../lib/api/_parseApi";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import SelectInputSearch from "../../components/ui/SelectInputSearch";
import { getDeliveriesSettlements } from "../../lib/api/delivery";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";

const ProductFeedbackEditView = () => {
  const { id: pfID } = useParams();
  const navigate = useNavigate();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: pfID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/product-feedback/not-found',
      remove: `/app/product-feedback${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/product-feedback${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Форма обратной связи создана',
      edit: 'Форма обратной связи обновлена',
      remove: 'Форма обратной связи удалена',
    },
  });

  const [disabled, setDisabled] = useState(false);
  const [phone, setPhone] = useState('');

  const handleCreateOrder = async () => {
    setDisabled(true);
    await handleSubmit((dataF) => {
      let parseData = {...dataF};
      parseData = parseAll(parseData, false, ["type", "product_id", "domain_id", "city_id"]);
      editProductFeedback(pfID, parseData)
          .then(res => toast.success("Стауст обновлен"))
          .catch(async (err) => await errorMessage(err , ''))
          .finally(() => setDisabled(false));
    })();
    await createOrderProductFeedback(pfID)
        .then(res => navigate(`/app/order/update/${res.data.id}`))
        .catch(async (err) => await errorMessage(err , ''))
        .finally(() => setDisabled(false));
  }

  const handleProcessed = () => {
    setDisabled(true);
    handleSubmit((dataF) => {
      let parseData = {
        ...dataF,
        status: 1
      }

      parseData = parseAll(parseData, false, ["type", "product_id", "domain_id", "city_id"])
      editProductFeedback(pfID, parseData)
          .then(res => {
            toast.success("Стауст обновлен")
            navigate('/app/product-feedback')
          })
          .catch(async (err) => await errorMessage(err , ''))
          .finally(() => setDisabled(false));
    })()
  }

  useEffect(() => {
    if(data?.fields?.status === 1) {
      setDisabled(true);
    }

    if(data?.fields?.phone) {
      setPhone(data?.fields?.phone)
    }
  }, [data]);

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product feedback edit`} />
      <div className="box-wrap">
        {pfID
          ? (
            <Title>
              Форма обратной связи -
              <small>{data && data.fields.id}</small>
            </Title>
          )
          : <Title>Новая форма обратной связи</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* domain_id */}
          {pfID ? (
              <Group>
                <ColLeft>
                  <label className="form-label" htmlFor="pf-domain">Сайт, с которого отправлена заявка*:</label>
                </ColLeft>
                <ColRight>
                  <input
                      className="form-input"
                      type="text"
                      id="pf-domain"
                      value={data?.fields?.domain?.title}
                      maxLength={INPUT_MAX_LENGTH}
                      readOnly
                  />
                  <input name="domain_id" type="hidden" value={data?.fields?.domain?.id} ref={register} />
                </ColRight>
              </Group>
          ) : (
              <Group>
                <ColLeft>
                  <label className="form-label" htmlFor="pf-domain">Сайт, с которого отправлена заявка*:</label>
                </ColLeft>
                <ColRight>
                  {data
                      ? (
                          <>
                            <select
                                name="domain_id"
                                className={cn('form-select', {
                                  'is-error': errors.domain_id,
                                })}
                                id="pf-domain"
                                ref={register({
                                  required: VALIDATION.select.required,
                                })}
                            >
                              {data && !!data.domains && data.domains.map((domain) => (
                                  <option
                                      key={domain.id}
                                      value={domain.id}
                                  >
                                    {domain.title}
                                  </option>
                              ))}
                            </select>

                            {errors.domain_id && <p className="form-input-hint">{errors.domain_id.message}</p>}
                          </>
                      )
                      : (
                          <div className="p-relative">
                            <div className="loading" />
                          </div>
                      )}
                </ColRight>
              </Group>
          )}

          {/* type */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-type">Выбрать тип заявки*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="type"
                      className={cn('form-select', {
                        'is-error': errors.type,
                      })}
                      id="pf-type"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                      <option value="">выберите тип заявки</option>
                      {APPLICATION_TYPES.map((type) => (
                        <option
                          key={type.id}
                          value={type.id}
                        >
                          {type.title}
                        </option>
                      ))}
                    </select>

                    {errors.type
                      && <p className="form-input-hint">{errors.type.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

         {/* surname */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-surname">Фамилия:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="surname"
                type="text"
                id="pf-surname"
                placeholder="Введите фамилию"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* name */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-name">Имя*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.name,
                })}
                name="name"
                type="text"
                id="pf-name"
                placeholder="Введите имя"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.name
              && <p className="form-input-hint">{errors.name.message}</p>}
            </ColRight>
          </Group>

          {/* phone */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-phone">Телефон*:</label>
            </ColLeft>
            <ColRight>
              <InputMask mask="+389999999999" value={phone} onChange={(e) => setPhone(e.target.value)}>
                {(inputProps) => (
                    <input
                        className={cn('form-input', {
                          'is-error': errors.phone,
                        })}
                        name="phone"
                        type="phone"
                        id="pf-phone"
                        placeholder="Введите номер телефона"
                        maxLength={INPUT_MAX_LENGTH}
                        ref={register({
                          required: VALIDATION.req.required,
                        })}
                        {...inputProps}
                    />
                )}
              </InputMask>
              {errors.phone
              && <p className="form-input-hint">{errors.phone.message}</p>}
            </ColRight>
          </Group>

          {/* email */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-email">Емайл:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="email"
                type="email"
                id="pf-email"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* settlement_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-settlement-id">Область/Населенный пункт:</label>
            </ColLeft>
            <ColRight>
              {data ? (
                  <div className={s_Select.select__inner}>
                    <SelectInputSearch
                        api={getDeliveriesSettlements}
                        formProps={data}
                        formPropsName={data?.fields?.settlement?.id}
                        formH={formH}
                        params={{
                          title: data?.fields?.settlement?.title
                        }}
                        labelTitle='title'
                        id="pf-settlement-id"
                        name="settlement_id"
                        placeholder="Область/Населенный пункт"
                        searchName='title'
                        clearValue={false}
                    />
                    <label className={`${s_Select.label} ${s_Select.label_react_select}`} htmlFor="pf-settlement-id">Область/Населенный пункт:</label>
                    {errors?.settlement_id && <p className="form-input-hint">{errors?.settlement_id?.message}</p>}
                  </div>
              ) : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
              )}
            </ColRight>
          </Group>

         {/* comment */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-comment">Комментарий:</label>
            </ColLeft>
            <ColRight>
              <textarea
                name="comment"
                className="form-input"
                id="pf-comment"
                placeholder="Введите комментарий"
                rows="4"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* product_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="product_id">Товар*:</label>
            </ColLeft>
            <ColRight>
              <InputFetchResult
                  name="product_id"
                  placeholder="Введите JDE товара"
                  form={formH}
                  searchParam="jde[]"
                  func={getProduct}
                  defVal={data?.fields?.product}
                  required
              />
            </ColRight>
          </Group>

          {/* product */}
          {!!data?.fields?.product?.warehouse_quantity_main?.length && (
              <Group>
                <ColLeft>
                  <label className="form-label" htmlFor="product-warehouse">Наличие:</label>
                </ColLeft>
                <ColRight col={2}>
                  {data?.fields?.product?.warehouse_quantity_main?.map((ware, indProdWare) => (
                      <div className={s_Table.availability__block} key={`product-warehouses-${indProdWare}`}>
                        {ware.quantity ? (
                            <>
                              <div className={s_Table.availability__stock}>
                                {ware.number.toString() === '310' ? '510' : ware.number}
                              </div>
                              <div className={s_Table.availability__counter}>
                                {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                              </div>
                            </>
                        ) : null}

                      </div>
                  ))}
                </ColRight>
              </Group>
          )}
          {/* status */}
          {!pfID && (
              <Group>
                <ColLeft>
                  <label className="form-label" htmlFor="pf-status">Выбрать статус запроса:</label>
                </ColLeft>
                <ColRight>
                  {data
                      ? (
                          <>
                            <select
                                name="status"
                                className='form-select'
                                id="pf-status"
                                ref={register}
                            >
                              <option value="">выберите статус запроса</option>
                              {APPLICATION_STATUS.map((status) => (
                                  <option
                                      key={status.id}
                                      value={status.id}
                                  >
                                    {status.title}
                                  </option>
                              ))}
                            </select>
                          </>
                      )
                      : (
                          <div className="p-relative">
                            <div className="loading" />
                          </div>
                      )}
                </ColRight>
              </Group>
          )}

          {/* create order */}
          {pfID && (
              <Group>
                <ColFull>
                  <div className={s.btn_block}>
                    {disabled ? data?.fields?.order_id && (
                        <Button purple full onClick={() => navigate(`/app/order/update/${data?.fields?.order_id}`)}>Открыть заказ</Button>
                    ) : (
                        <Button purple full onClick={handleCreateOrder} disabled={disabled}>Создать заказ</Button>
                    )}
                    <Button green full onClick={handleProcessed}>Обработан</Button>
                  </div>
                </ColFull>
              </Group>
          )}
        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default ProductFeedbackEditView;
