import _parseApi, { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import {
    createWh,
    getWhEdit,
    editWh,
    removeWh, getWhParcelSenders
} from "../../lib/api/warehouses";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (whId) => Promise.allSettled([getDomains(), getWhParcelSenders(), whId && getWhEdit(whId)])
    .then(async (res) => {
        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if (findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        return ({
            domains: res[0]?.value?.data,
            parcelSenders: res[1]?.value?.data,
            fields: whId && res[2]?.value?.data,
        })
    });

export const create = createWh;
export const edit = editWh;
export const remove = removeWh;

export const parseDataSend = (data, catId) => {
    let d = {...data};
    const isUpdate = typeof catId !== 'undefined';

    if (!d?.parcel_sender_id?.length) d.parcel_sender_id = null
    else d.parcel_sender_id = +d.parcel_sender_id

    d = parseAll(d, true, [
        // 'bonus_limit',
        // 'bonus_value',
        // 'dimension_type',
        // 'document_type',
        // 'is_active',
        // 'for_business'
    ], true);

    if (!!d?.phones?.length) {
        d = _parseApi.parseAllArr(d, [
            {
                name: 'phones',
                removeEmt: true,
            },
        ], true);
    }

    return d;
};

export const parseDataGet = (data) => {
    const d = {...data};
    const toStr = [
        'type',
        'latitude',
        'longitude',
        'code',
        'latitude',
        'work_time_ua',
        'work_time_ru',
        'email',
        'address_ua',
        'address_ru',
        'city_id',
    ];

    toStr.forEach((p) => {
        d[p] = `${d[p]}`;
    });

    if (!!d.phones?.length) {
        d.phones = d.phones.map(ph => ph.phone)
    }

    return d;
};
