/**
 * Window.confirm as a Promise
 *
 * @param msg
 * @returns {Promise<unknown>}
 */
/* eslint-disable */
const confirmDialog = (msg) => new Promise((resolve, reject) => {
  const confirmed = window.confirm(msg);

  return confirmed ? resolve(true) : reject(false);
});

export default confirmDialog;
