import cn from 'classnames';
import React from 'react';
import s from './TableDynamicFields.module.scss';

const TableDynamicFields = ({
  children,
  attrs,
  onAdd,
  hover,
  center,
}) => {
  function addHandler() {
    if (onAdd) onAdd();
  }

  return (
    <div className={cn(s.wrap, attrs && attrs.className)}>
      <table className={cn('table', s.table,
        { 'table-hover': hover },
        { 'text-center': center })}
      >
        {children}
      </table>

      {onAdd && (
        <div className={s.btnWrap}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addHandler}
          >
            Добавить
          </button>
        </div>
      )}
    </div>
  );
};

export default TableDynamicFields;
