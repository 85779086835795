import {
  BANNER_CATALOG_HORIZONTAL_GET,
  BANNER_CATALOG_HORIZONTAL_CREATE,
  BANNER_CATALOG_HORIZONTAL_GET_EDIT, BANNER_CATALOG_HORIZONTAL_EDIT, BANNER_CATALOG_HORIZONTAL_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBannerCatalogHorizontal = (params) => fetchAx({
  url: BANNER_CATALOG_HORIZONTAL_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createBannerCatalogHorizontal = (data) => fetchAx({
  url: BANNER_CATALOG_HORIZONTAL_CREATE,
  method: 'POST',
  data,
});

export const getBannerCatalogHorizontalEdit = (id) => fetchAx({
  url: BANNER_CATALOG_HORIZONTAL_GET_EDIT.join(id),
  method: 'GET',
});

export const editBannerCatalogHorizontal = (id, data) => fetchAx({
  url: BANNER_CATALOG_HORIZONTAL_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBannerCatalogHorizontal = (id) => fetchAx({
  url: BANNER_CATALOG_HORIZONTAL_DELETE.join(id),
  method: 'DELETE',
});
