import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove, getFiltersData } from "./api";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import StatusCircle from "../../components/ui/StatusCircle";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";

const ProductConsultationList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        filtersData,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product consultation list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', "Ім'я", 'Телефон', 'Email', 'Тип', 'Канал', 'Дата та час консультації', 'Магазин',
                        "Ім'я консультанта", 'Назва товару', 'Створено'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'name'},
                            {type: 'text', name: 'phone'},
                            {type: 'text', name: 'email'},
                            {
                                type: 'select', name: 'is_online',
                                options: [
                                    {
                                        id: 0,
                                        title: 'Не активна',
                                    },
                                    {
                                        id: 1,
                                        title: 'Активна',
                                    },
                                ],
                            },
                            {
                                type: 'select', name: 'channel_type',
                                options: [
                                    {
                                        id: 'google-duo',
                                        title: 'Google Duo'
                                    }
                                ]
                            },
                            {type: 'text', name: 'created_at'},
                            {
                                type: 'select',
                                name: 'warehouse_id',
                                optProps: ['', 'title_ru'],
                                options: filtersData?.wareHouses && filtersData.wareHouses
                            },
                            {
                                type: 'select',
                                name: 'manager_id',
                                optProps: ['id', 'name_ru'],
                                options: filtersData?.specialists && filtersData.specialists
                            },
                            {type: 'text', name: 'topic_title_ru'},
                            {type: 'date', name: 'date_from'},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={12}/>
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`prod-feed-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Форму консультацій з товарів видалено',
                                    }}
                                    editLink={`/app/product-consultation/update/${e.id}`}
                                    permissionRemove='admin.product-consultation.destroy'
                                    permissionEdit='admin.product-consultation.edit'
                                >
                                    <td>
                                        {checkPermission('admin.product-consultation.edit')
                                            ? <Link to={`/app/product-consultation/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.email || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.is_online ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.channel_type || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.booked_for ? getTZtoDate(e?.booked_for, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.warehouse?.title_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.manager?.name_ua || e?.manager?.name_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.topic?.title_ru || e?.topic?.title_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(new Date(e?.created_at), true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={12}>Немає форм консультацій з товарів</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default ProductConsultationList;
