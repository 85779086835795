import {Link, useLocation, useNavigate} from 'react-router-dom';
import React from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getSpecOfferType } from '../../lib/const/specOffer';
import { geDomainName } from '../../lib/const/domains';
import { getData, remove, getFiltersData } from './api';
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewPagination from '../../components/common/TableListViewNew/Pagination';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from '../../components/common/TableListViewNew/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import StatusCircle from "../../components/ui/StatusCircle";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../const/view";
import InputWrapper from "../../components/ui/InputWrapper";

const SpecOfferList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    filtersData,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      pushUrlParams({
        ...urlParams,
        jde: e.target.value
      })
    }
  }

  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Spec offer list`} />
      {!load && (
          <TopPagination
              tabs={(
                  <InputWrapper label="Пошук по JDE" id="spec-offer-search-jde" style={{ marginBottom: 0 }}>
                    <input
                        name="jde"
                        className={s_Input.input}
                        type="text"
                        id="spec-offer-search-jde"
                        placeholder="Пошук по JDE"
                        defaultValue={urlParams?.jde || ''}
                        onKeyDown={onEnterPress}
                        maxLength={INPUT_MAX_LENGTH}
                    />
                  </InputWrapper>
              )}
              pagination={(
                  <>
                    {rest?.total > 0 && (
                        <>
                          <TableListViewPagination
                              length={+rest?.last_page}
                              current={+rest?.current_page}
                              loc={location}
                              navigate={navigate}
                          />
                          <TableListViewPerPage
                              urlParams={urlParams}
                              onSelect={(val, params) => pushUrlParams(params)}
                              total={+rest?.total}
                          />
                        </>
                    )}
                  </>
              )}
          />
      )}
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название УКР</th>
            <th>Название РУС</th>
            <th>Домен</th>
            <th>Статус активность</th>
            <th>Тип предложения</th>
            <th>Срок действия от</th>
            <th>Срок действия до</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
            urlParams={urlParams}
            fields={[
              {},
              { type: 'text', name: 'title_ua' },
              { type: 'text', name: 'title_ru' },
              { type: 'select', name: 'domain_id', options: filtersData?.domains },
              {
                type: 'select',
                name: 'is_active',
                options: [
                  {
                    id: 0,
                    title: 'отключена',
                  },
                  {
                    id: 1,
                    title: 'активна',
                  },
                ],
              },
              {
                type: 'select',
                name: 'type',
                options: [
                  {
                    id: 1,
                    title: 'Акционные товары',
                  },
                  {
                    id: 2,
                    title: 'Купи А - получи Б в подарок',
                  },
                  {
                    id: 3,
                    title: 'Купи А - получи Б со скидкой',
                  },
                  {
                    id: 4,
                    title: 'Купи А+Б - получи скидку на комплект',
                  },
                  {
                    id: 5,
                    title: 'Купи А - получи Б по промокоду',
                  },
                  {
                    id: 6,
                    title: 'Купи А - получи Б и В в подарок',
                  },
                  {
                    id: 7,
                    title: 'Скидка по промокоду',
                  },
                  {
                    id: 8,
                    title: 'Акция с бонусами',
                  },
                ],
              },
              { type: 'date', name: 'date_from' },
              { type: 'date', name: 'date_to' },
              {},
            ]}
            onSubmit={pushUrlParams}
          />
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={9} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`so-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Специальное предложение удаленно',
                    }}
                    editLink={`/app/spec-offer/update/${e.id}`}
                    permissionRemove='admin.special-offer.destroy'
                    permissionEdit='admin.special-offer.edit'
                  >
                    <td>
                      {checkPermission('admin.special-offer.edit')
                          ? <Link to={`/app/spec-offer/update/${e.id}`} state={{ from: location.pathname + location.search }}>{e.id}</Link>
                          : <span>{e.id}</span>
                      }
                    </td>
                    <td>
                      <span>
                        {e.title_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.title_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {geDomainName(e.domain_id)?.name || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Отключена" />}
                      </span>
                    </td>
                    <td>
                      <span>
                        {getSpecOfferType(e.type)?.name || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.date_from ? getTZtoDate(e.date_from, true) : '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.date_to ? getTZtoDate(e.date_to, true) : '---'}
                      </span>
                    </td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={8} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default SpecOfferList;
