import forOwn from 'lodash/forOwn';
import _parseApi, { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';

import {
  createSpecOfferBanners,
  editSpecOfferBanners,
  getSpecOfferBannersEdit,
  removeSpecOfferBanners,
} from '../../lib/api/specOfferBanners';
import { getSpecOffersALl } from '../../lib/api/specOffers';
import { getSpecOfferBannersTag } from '../../lib/api/specOfferBannersTag';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (id) => Promise.allSettled([getDomains(), id
&& getSpecOfferBannersEdit(id), getSpecOffersALl(), getSpecOfferBannersTag({ per_page: 5000 })])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: id && res[1]?.value?.data,
      special_offers: res[2]?.value?.data,
      tags: res[3]?.value?.data?.data,
    })
  });

export const create = createSpecOfferBanners;
export const edit = editSpecOfferBanners;
export const remove = removeSpecOfferBanners;

export const parseDataSend = (data, catId) => {
  let d = { ...data };
  const isUpdate = typeof catId !== 'undefined';

    d['date_from'] = `${d.date_from} ${d.time_from}`;
    d['date_to'] = `${d.date_to} ${d.time_to}`;

  // const typeName = SPEC_TYPES[+d['types'] - 1].types;
  // d['characteristic_list'] = d['characteristic'][typeName];

  // parse all lvl1
  d = parseAll(d, !isUpdate,
    [
      'position',
    ]);

  // parse characteristic_list
  const CH_LIST = 'characteristic_list';
  forOwn(d[CH_LIST], (arr, k) => {
    d[CH_LIST] = _parseApi.parseAllArr(d[CH_LIST], [
      {
        name: k,
      },
    ], !isUpdate);
  });

  if(!!d?.special_offers_copy?.length) {
    d.special_offers = [...d?.special_offers_copy]
  } else {
    d.special_offers = [];
  }

  if(!!d?.tags?.length) {
    d?.tags?.forEach((item, index) => {
      if(!!item?.length && (item !== "[object Object]")) {
        return item;
      } else {
        d?.tags?.splice(index, 1);
      }
    })
  } else {
    d.tags = [];
  }

  delete d['date_from-dop'];
  delete d['date_to-dop'];
  delete d['time_from'];
  delete d['time_to'];

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  // const typeName = SPEC_TYPES[+d['type'] - 1].type;
  //
  // d.characteristic_list = {
  // 	[typeName]: d['characteristic_list']
  // }

  return d;
};
