import {
  BANNER_MAIN_PAGE_GET,
  BANNER_MAIN_PAGE_GET_ALL,
  BANNER_MAIN_PAGE_CREATE,
  BANNER_MAIN_PAGE_GET_EDIT,
  BANNER_MAIN_PAGE_EDIT,
  BANNER_MAIN_PAGE_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBannerHome = (params) => fetchAx({
  url: BANNER_MAIN_PAGE_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    visibility: 'all',
    ...params,
  },
});

export const getBannerHomeAll = () => fetchAx({
  url: BANNER_MAIN_PAGE_GET_ALL,
  method: 'GET',
});

export const createBannerHome = (data) => fetchAx({
  url: BANNER_MAIN_PAGE_CREATE,
  method: 'POST',
  params: data,
});

export const getBannerHomeEdit = (id) => fetchAx({
  url: BANNER_MAIN_PAGE_GET_EDIT.join(id),
  method: 'GET',
});

export const editBannerHome = (id, data) => fetchAx({
  url: BANNER_MAIN_PAGE_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBannerHome = (id) => fetchAx({
  url: BANNER_MAIN_PAGE_DELETE.join(id),
  method: 'DELETE',
});
