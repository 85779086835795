import { getOrders, removeOrder, getOrderSources, getDeliveriesStatuses } from "../../lib/api/order";
import { getDomains } from "../../lib/api/domains";
import { getWhAll } from "../../lib/api/warehouses";
import { getAdminsAll } from "../../lib/api/admins";
import { getStatusAll } from "../../lib/api/statuses";
import { getPayments } from "../../lib/api/payments";
import { getDeliveries } from "../../lib/api/delivery";
import { LIST_PER_PAGE } from "../../const/view";

export const getFiltersData = () => Promise.allSettled([
  getDomains(), getWhAll(), getAdminsAll(), getOrderSources(), getDeliveriesStatuses(), getStatusAll(), getPayments(),
    getDeliveries({ per_page: LIST_PER_PAGE.xxxl })
])
  .then((res) => {
      const parseDeliveryTypes = res[7]?.value?.data?.data?.map(deliveryType => {
          const temp = {...deliveryType};
          const findDomainName = res[0]?.value?.data?.find(domain => domain.id === temp.domain_id)?.title;
          temp.title = `(${findDomainName}) ${temp.title}`;
          return temp;
      }).sort((a, b) => {
          if (a.domain_id < b.domain_id) {
              return -1;
          }
          if (a.domain_id > b.domain_id) {
              return 1;
          }
          return 0;
      });

      return ({
          domains: res[0]?.value?.data,
          wareHouses: res[1]?.value?.data?.data,
          managers: res[2]?.value?.data?.data,
          sources: res[3]?.value?.data,
          deliveryStatus: res[4]?.value?.data,
          statuses: res[5]?.value?.data?.data,
          payments: res[6]?.value?.data?.data,
          deliveryTypes: parseDeliveryTypes,
      })
  });

export const getData = getOrders;
export const remove = removeOrder;
