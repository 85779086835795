import {
    createArticle,
    editArticle,
    removeArticle,
    getArticleEdit,
    getArticleTypes,
    getArticleStatus,
} from "../../lib/api/articles";
import { parseAll } from "../../lib/api/_parseApi";
import { getCatTree } from "../../lib/api/categories";
import { getMedia } from "../../lib/api/media";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (articleID) =>
    Promise.allSettled([articleID && getArticleEdit(articleID), getArticleTypes(), getArticleStatus(), getCatTree(1), articleID && getMedia({
        'entity_type': 'article',
        'entity_id': articleID,
        page: 1,
        per_page: 500,
    })])
        .then(async (res) => {
            const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

            if (findStatus401) {
                return await refreshToken(() => window.location.reload())
            }

            const parseCategories = res[3]?.value?.data?.map(item => {
                const parseSecondLevel = item.children.map(itemSecond => {
                    const parseThirdLevel = itemSecond.children.map(itemThird => {
                        const parseFourthLevel = itemThird.children.map(itemFourth => ({
                            value: itemFourth.id,
                            label: itemFourth.title,
                            children: null,
                        }));
                        return {
                            value: itemThird.id,
                            label: itemThird.title,
                            children: parseFourthLevel,
                        };
                    });
                    return {
                        value: itemSecond.id,
                        label: itemSecond.title,
                        children: parseThirdLevel,
                    };
                })
                return {
                    value: item.id,
                    label: item.title,
                    children: parseSecondLevel,
                };
            });
            const parseMedia = res[4]?.value?.data?.data?.map(item => ({
                id: item.id.toString(),
                src: item?.conversions?.original,
                alts: item?.custom_properties,
            }));

            return ({
                fields: articleID && res[0]?.value?.data,
                types: res[1]?.value?.data,
                status: res[2]?.value?.data,
                categories: parseCategories,
                assets_data: parseMedia,
            })
        })


export const create = createArticle;
export const edit = editArticle;
export const remove = removeArticle;


export const parseDataGet = (data) => {
    const d = { ...data?.data, ...data?.included };

    const imagesIdsRu = [];
    const videosIdsRu = [];

    if(!!d['body_ru']?.length) {
        const findTagImg = d['body_ru']?.split('<x-image');
        if(findTagImg?.length > 1) {
            findTagImg?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const imageId = temp[0]?.split('id=')[1]?.replaceAll('"', '');

                    imagesIdsRu.push(imageId);
                }
            });

            if(!!imagesIdsRu?.length) {
                const parseBodyRu = findTagImg?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const imageId = temp[0]?.split('id=')[1]?.replaceAll('"', '');
                        const findImg = data['assets_data']?.find(img => img?.id?.toString() === imageId?.toString());
                        temp[0] = `<img src="${findImg?.src ? findImg.src : "https://via.placeholder.com/300x300"}" alt="${findImg?.id ? findImg.id : ""}"`;

                        return temp.join('/>');
                    }
                    return item;
                });
                d['body_ru'] = parseBodyRu.join('');
            }
        }

        const findTagVideos = d['body_ru']?.split('<x-video');
        if(!!findTagVideos?.length) {
            findTagVideos?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const videoId = temp[0]?.split('id=')[1]?.replaceAll('"', '');

                    videosIdsRu.push(videoId);
                }
            });

            if(!!videosIdsRu?.length) {
                const parseBodyRu = findTagVideos?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const videoId = temp[0]?.split('id=')[1]?.replaceAll('"', '');
                        const findVideo = data['assets_data']?.find(img => img?.id?.toString() === videoId?.toString());
                        const itemVideo = `<video src="${findVideo?.src ? findVideo.src : ""}"></video>`;

                        temp.splice(0, 1);

                        return `${itemVideo}${temp.join('/>')}`;
                    }
                    return item;
                });
                d['body_ru'] = parseBodyRu.join('');
            }
        }
    }

    const imagesIdsUa = [];
    const videosIdsUa = [];

    if(!!d['body_ua']?.length) {
        const findTagImg = d['body_ua']?.split('<x-image');
        if(findTagImg?.length > 1) {
            findTagImg?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const imageId = temp[0]?.split('id=')[1]?.replaceAll('"', '');

                    imagesIdsUa.push(imageId);
                }
            });
            if(!!imagesIdsUa?.length) {
                const parseBodyUa = findTagImg?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const imageId = temp[0]?.split('id=')[1]?.replaceAll('"', '');
                        const findImg = data['assets_data']?.find(img => img?.id?.toString() === imageId?.toString());
                        temp[0] = `<img src="${findImg?.src ? findImg.src : "https://via.placeholder.com/300x300"}" alt="${findImg?.id ? findImg.id : ""}"`;

                        return temp.join('/>');
                    }
                    return item;
                });

                d['body_ua'] = parseBodyUa.join('');
            }
        }

        const findTagVideos = d['body_ua']?.split('<x-video');
        if(!!findTagVideos?.length) {
            findTagVideos?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const videoId = temp[0]?.split('id=')[1]?.replaceAll('"', '');

                    videosIdsUa.push(videoId);
                }
            });

            if(!!videosIdsUa?.length) {
                const parseBodyUa = findTagVideos?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const videoId = temp[0]?.split('id=')[1]?.replaceAll('"', '');
                        const findVideo = data['assets_data']?.find(img => img?.id?.toString() === videoId?.toString());
                        const itemVideo = `<video src="${findVideo?.src ? findVideo.src : ""}"></video>`;

                        temp.splice(0, 1);

                        return `${itemVideo}${temp.join('/>')}`;
                    }
                    return item;
                });
                d['body_ua'] = parseBodyUa.join('');
            }
        }
    }

    return d;
};

export const parseDataSend = (data, clientID) => {
    let d = { ...data };

    if(!!d['assets_data']?.length) {
        d['assets_data'] = JSON.parse(d['assets_data']);
    }

    if(!!d['assets_data_ru']?.length) {
        d['assets_data_ru'] = JSON.parse(d['assets_data_ru']);
    }

    if(!!d['assets_data_ua']?.length) {
        d['assets_data_ua'] = JSON.parse(d['assets_data_ua']);
    }

    const isUpdate = typeof clientID !== 'undefined';

    const imagesIdsRu = [];

    const imagesSrcRu = [];
    const videoSrcRu = [];

    const imagesIdsUa = [];

    const imagesSrcUa = [];
    const videoSrcUa = [];

    if(!!d['body_ru']['text']?.length) {
        const findTagImg = d['body_ru']['text']?.split('<img');
        if(findTagImg?.length >= 1) {
            findTagImg?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const imageAlt = temp[0]?.split('alt="')[1]?.split('\"')[0];
                    const imageSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                    imagesIdsRu.push(imageAlt);
                    imagesSrcRu.push(imageSrc);
                }
            });

            if(!!imagesIdsRu?.length || !!imagesSrcRu?.length) {

                const parseBodyRu = findTagImg?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const imageSrc = temp[0]?.split('src="')[1]?.split('\"')[0];
                        const findImg = d['assets_data_ru']?.find(img => img?.src === imageSrc);

                        temp[0] = `<x-image id="${findImg?.id}"`;
                        if(temp[1]) {
                            return temp.join('/>');
                        }

                        return temp.join('/>');
                    }
                    return item;
                });
                d['body_ru']['text'] = parseBodyRu.join('');
            }
        }

        const findTagVideo = d['body_ru']['text']?.split('<video');
        if(findTagVideo?.length >= 1) {
            findTagVideo?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('</video>');
                    const videoSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                    videoSrcRu.push(videoSrc);
                }
            });

            if(!!videoSrcRu?.length) {

                const parseBodyRu = findTagImg?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('</video>');
                        const videoSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                        const findVideo = d?.assets_videos_ru?.find(video => video === videoSrc);
                        const splitVideo = findVideo?.split('/');
                        const findVideoId = splitVideo?.[splitVideo?.length - 2];

                        temp[0] = `<x-video id="${findVideoId}"`;
                        if(temp[1]) {
                            return temp.join('/>');
                        }

                        return temp.join('/>');
                    }
                    return item;
                });
                d['body_ru']['text'] = parseBodyRu.join('');
            }
        }
    }

    if(!!d['body_ua']['text']?.length) {
        const findTagImg = d['body_ua']['text']?.split('<img');
        if(findTagImg?.length >= 1) {
            findTagImg?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('/>');
                    const imageAlt = temp[0]?.split('alt="')[1]?.split('\"')[0];
                    const imageSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                    imagesIdsUa.push(imageAlt);
                    imagesSrcUa.push(imageSrc);
                }
            });

            if(!!imagesIdsUa.length || !!imagesSrcUa?.length) {

                const parseBodyUa = findTagImg?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('/>');
                        const imageSrc = temp[0]?.split('src="')[1]?.split('\"')[0];
                        const findImg = d['assets_data_ua']?.find(img => img?.src === imageSrc);

                        temp[0] = `<x-image id="${findImg?.id}"`;
                        if(temp[1]) {
                            return temp.join('/>');
                        }

                        return temp.join('/>');
                    }
                    return item;
                });
                d['body_ua']['text'] = parseBodyUa.join('');
            }
        }

        const findTagVideo = d['body_ua']['text']?.split('<video');
        if(findTagVideo?.length >= 1) {
            findTagVideo?.forEach((item, index) => {
                if (index >= 1) {
                    const temp = item?.split('</video>');
                    const videoSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                    videoSrcUa.push(videoSrc);
                }
            });

            if(!!videoSrcUa?.length) {

                const parseBodyUa = findTagVideo?.map((item, index) => {
                    if (index >= 1) {
                        let temp = item?.split('</video>');
                        const videoSrc = temp[0]?.split('src="')[1]?.split('\"')[0];

                        const findVideo = d?.assets_videos_ua?.find(video => video === videoSrc);
                        const splitVideo = findVideo?.split('/');
                        const findVideoId = splitVideo?.[splitVideo?.length - 2];

                        temp[0] = `<x-video id="${findVideoId}"`;
                        if(temp[1]) {
                            return temp.join('/>');
                        }

                        return temp.join('/>');
                    }
                    return item;
                });
                d['body_ua']['text'] = parseBodyUa.join('');
            }
        }
    }

    let parseCategories;

    if(!!d?.cat_ids?.length) {
        parseCategories = d?.cat_ids?.split(',').map(cat => `cat_${cat}`);
    }
    const parseProducts = d?.products?.filter((item, pos) => d?.products.indexOf(item) === pos);
    if(!!parseCategories?.length) {
        d['prod_cat_ids'] = parseCategories;
    }

    if(!!d?.products?.length) {
        d['prod_cat_ids'] = parseProducts;
    }

    if(!!d?.products?.length && !!parseCategories?.length) {
        d['prod_cat_ids'] = [...parseProducts, ...parseCategories];
    }

    if(!d?.expired_at?.length) {
        d.expired_at = null;
    }

    if(!d?.prod_cat_ids?.length) {
        d.prod_cat_ids = [];
    }

    const parseConstructor = (key) => {
        let getCss = d[key]?.css;

        let parseCss = getCss.replaceAll('* { box-sizing: border-box; } body {margin: 0;}', '').replaceAll('img{max-width:100%;}', '');
        let parseHtml;

        if(d[key]?.text?.includes('<style>')) {
            parseHtml = d[key]?.text?.replaceAll('<body>', '')?.replaceAll('</body>', '')?.replaceAll('* { box-sizing: border-box; } body {margin: 0;}', '')?.replaceAll('img{max-width:100%;}', '');
            return parseHtml;
        } else {
            parseHtml = d[key]?.text?.replaceAll('<body>', '')?.replaceAll('</body>', '')?.replaceAll('* { box-sizing: border-box; } body {margin: 0;}', '')?.replaceAll('img{max-width:100%;}', '');
            const parseHtmlCss = `<style>${parseCss}</style>${parseHtml}`;
            return parseHtmlCss;
        }
    }

    if(!!d?.body_ua?.text?.length) {
        d['body_ua'] = parseConstructor("body_ua");
    } else {
        d['body_ua'] = '';
    }

    if(!!d?.body_ru?.text?.length) {
        d['body_ru'] = parseConstructor("body_ru");
    } else {
        d['body_ru'] = '';
    }

    if(!!d?.body_ru?.text?.length && !!d?.body_ua?.text?.length) {
        d['body_ua'] = parseConstructor("body_ua");
        d['body_ru'] = parseConstructor("body_ru");
    }

    delete d['product_ids'];
    delete d['products'];
    delete d['cat_ids'];
    delete d['publish_at-dop'];
    delete d['expired_at-dop'];
    delete d['assets_data'];
    delete d['assets_data_ru'];
    delete d['assets_data_ua'];
    delete d['product_images'];

    d = parseAll(d, true);

    return d;
};

