import { parseAll } from "../../lib/api/_parseApi";
import {
    createStatusChange,
    editStatusChange,
    getStatusChangeEdit,
    removeStatusChange,
} from "../../lib/api/status_change";
import { getRoles } from "../../lib/api/roles";
import { getStatusAll } from "../../lib/api/statuses";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (statusChangeId) => Promise.allSettled([getRoles(), getStatusAll(), statusChangeId
&& getStatusChangeEdit(statusChangeId)])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      return ({
          roles: res[0]?.value?.data?.data,
          statuses: res[1]?.value?.data?.data,
          fields: statusChangeId && res[2]?.value?.data,
      })
  });

export const create = createStatusChange;
export const edit = editStatusChange;
export const remove = removeStatusChange;

export const parseDataSend = (data, statusId) => {
  let d = { ...data };
  const isUpdate = typeof statusId !== 'undefined';

  d = parseAll(d, !isUpdate, ['role', 'status', 'status_to']);

  return d;
};

export const parseDataGet = (data, id) => {
  const d = { ...data };

  const isUpdate = typeof id !== 'undefined';

  return d;
}
