import { editDelivery, getDeliveryEdit, getDeliveriesProviders } from "../../lib/api/delivery";
import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import { getPayments } from "../../lib/api/payments";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pId) => Promise.allSettled([getDomains(), getPayments(), getDeliveriesProviders(), pId && getDeliveryEdit(pId)])
.then(async (res) => {
  const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

  if (findStatus401) {
    return await refreshToken(() => window.location.reload())
  }

  return ({
    domains: res[0]?.value?.data,
    payments: res[1]?.value?.data?.data,
    providers: res[2]?.value?.data,
    fields: pId && res[3]?.value?.data
  })
})

export const edit = editDelivery;

export const parseDataSend = (data) => {
  let d = { ...data };

  if(!d?.price_product_id?.length) {
    d['price_product_id'] = null;
  } else {
    d = parseAll(d, false, ['price_product_id']);
  }

  d = parseAll(d, true);

  return d;
};
