import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import InputFile from '../../components/ui/InputFile';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import s from "./UnaccounredProducts.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";

const UnaccountedProductsEditView = () => {
  const { id: unProdID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: unProdID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/unaccounted-products/not-found',
      remove: `/app/unaccounted-products${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/unaccounted-products${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Не підрахунковий товар створено',
      edit: 'Не підрахунковий товар оновлено',
      remove: 'Не підрахунковий товар видалено',
    },
  });
/* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Unaccounted products edit`} />
      <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

        <button type="submit" className="d-hide" />

        <TopPanelChild
            title={unProdID ? data && data?.fields?.title_ua : "Новий не підрахунковий товар"}
            breadcrumbs={{
              items: [
                {
                  title: "Головна",
                  url: "/app",
                },
                {
                  title: "Не підрахункові товари",
                  url: `/app/unaccounted-products${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                },
                {
                  title: unProdID ? data && data?.fields?.title_ua : "Не підрахунковий товар"
                },
              ],
            }}
            back={`/app/unaccounted-products${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
            id={unProdID}
            children={<>
              {/* is_active */}
              <CheckboxSwitch
                  name="is_active"
                  id="cat-is-active"
                  label="Статус наявності"
                  formH={formH}
                  defaultChecked={data?.fields?.is_active}
              />
            </>}
            btnEdit
        />

        <Form attrs={{ onSubmit: handleSubmit(submitHandler) }}>

          {/* title_ua */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputWrapper
                  label="Назва УКР"
                  id="up-name-ua"
                  errorComponent={formH?.errors?.title_ua &&
                  <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.title_ua,
                    })}
                    type="text"
                    placeholder="Назва УКР"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="title_ua"
                    id="up-name-ua"
                    defaultValue={data?.fields?.title_ua}
                />
              </InputWrapper>
            </ColFull>
          </Group>

          {/* title_ru */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputWrapper
                  label="Назва РУС"
                  id="up-name-ru"
                  errorComponent={formH?.errors?.title_ru &&
                  <p className="form-input-hint">{formH?.errors?.title_ru?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.title_ru,
                    })}
                    type="text"
                    placeholder="Назва РУС"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="title_ru"
                    id="up-name-ru"
                    defaultValue={data?.fields?.title_ru}
                />
              </InputWrapper>
            </ColFull>
          </Group>

          {/* code */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputWrapper
                  label="Код товару"
                  id="up-code"
                  errorComponent={formH?.errors?.code &&
                  <p className="form-input-hint">{formH?.errors?.code?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.code,
                    })}
                    type="number"
                    placeholder="Код товару"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="code"
                    id="up-code"
                    defaultValue={data?.fields?.code}
                />
              </InputWrapper>
            </ColFull>
          </Group>

          {/* img_alt_ua */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputWrapper
                  label="Короткий опис УКР"
                  id="up-img-alt-ua"
                  errorComponent={formH?.errors?.img_alt_ua &&
                  <p className="form-input-hint">{formH?.errors?.img_alt_ua?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.img_alt_ua,
                    })}
                    type="text"
                    placeholder="Короткий опис УКР"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="img_alt_ua"
                    id="up-img-alt-ua"
                    defaultValue={data?.fields?.img_alt_ua}
                />
              </InputWrapper>
            </ColFull>
          </Group>

          {/* img_alt_ru */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputWrapper
                  label="Короткий опис РУС"
                  id="up-img-alt-ru"
                  errorComponent={formH?.errors?.img_alt_ru &&
                  <p className="form-input-hint">{formH?.errors?.img_alt_ru?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.img_alt_ru,
                    })}
                    type="text"
                    placeholder="Короткий опис РУС"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="img_alt_ru"
                    id="up-img-alt-ru"
                    defaultValue={data?.fields?.img_alt_ru}
                />
              </InputWrapper>
            </ColFull>
          </Group>

          {/* img */}
          <Group attrs={{ style: { margin: '0 0 6px 0', maxWidth: '480px' } }}>
            <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
              <InputFile
                name="img"
                val={watch('img')}
                formH={formH}
                ref={register}
                acceptStr="PNG, JPG, GIF"
                accept="image/*"
                sizeMB={1}
                className={s.block__img}
                bottomText="1530х1530px"
                labelDownload="Додати зображення"
              />
            </ColFull>
          </Group>

        </Form>

        {load && <Loader />}
      </Form>
    </>
  );
};

export default UnaccountedProductsEditView;
