import {
    getSortBannersTypes,
    getSortBannerCatalogHorizontal,
    removeSortBannerCatalogHorizontal,
    updateSortBannerCatalogHorizontal,
    refreshSortBannerCatalogHorizontal, editSortBannerCatalogHorizontal
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getSortBannersTypes(), getCatTree()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banner_types: res[1]?.value?.data,
        categories: res[2]?.value?.data
    }));

export const edit = editSortBannerCatalogHorizontal;
export const getData = getSortBannerCatalogHorizontal;
export const updateSort = updateSortBannerCatalogHorizontal;

export const refreshSort = refreshSortBannerCatalogHorizontal;
export const remove = removeSortBannerCatalogHorizontal;
