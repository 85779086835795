import React, { useCallback, useEffect, useState } from 'react';
import TypeOne from './types/TypeOne';
import { getSpecOfferType } from '../../lib/const/specOffer';
import TypeTwo from './types/TypeTwo';
import TypeThree from './types/TypeThree';
import TypeFour from './types/TypeFour';
import TypeSix from './types/TypeSix';
import TypeEight from './types/TypeEight';

const useController = ({
  formH,
  defaultValues = [],
  markers = [],
  categories = [],
  domains = [],
  additional_services = [],
  bonus_type = [],
  unaccounted_products = [],
}) => {
  // set types
  const [type, setType] = useState({
    id: null,
    title: null,
  });

  const [bonusTypeID, setBonusTypeID] = useState(null);

  const [products, setProducts] = useState([]);
  const [bonusProduct1, setBonusProduct1] = useState([]);
  const [bonusProduct2, setBonusProduct2] = useState([]);
  const [bonusProducts, setBonusProducts] = useState([]);
  const [bonus, setBonus] = useState([]);
  const [giftProducts, setGiftProducts] = useState([]);
  const [discountProducts, setDiscountProducts] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const [price, setPrice] = useState([]);
/* eslint-disable */
  const _initType = useCallback(() => {
    if (defaultValues !== null) {
      const title = getSpecOfferType(defaultValues?.type)?.name;
      setType({
        id: defaultValues?.type,
        title,
      });
    }
  }, [defaultValues]);

  const _initDefVal = useCallback(() => {
    if (defaultValues !== null) {
      const _t = setTimeout(() => {
        // set products
        const products = defaultValues[`special_offer_type${type.id}_products`];
        if (products?.length !== 0) {
          setProducts(products);
        }

        // set bonus_product1
        const bonus_product1 = defaultValues[`special_offer_type${type.id}_bonus_products1`];
        if (bonus_product1?.length !== 0) {
          setBonusProduct1(bonus_product1);
        }

        // set bonus_product2
        const bonus_product2 = defaultValues[`special_offer_type${type.id}_bonus_products2`];
        if (bonus_product2?.length !== 0) {
          setBonusProduct2(bonus_product2);
        }

        // set bonus_product2
        const price = defaultValues[`special_offer_type${type.id}_price`];
        if (price?.length !== 0) {
          setPrice(price);
        }

        // set bonus_products
        const bonus_products = defaultValues[`special_offer_type${type.id}_bonus_products`];
        if (bonus_products?.length !== 0) {
          setBonusProducts(bonus_products);
        }

        // set bonus_products_type
        const bonus_products_type = defaultValues[`type${type.id}_bonus_products`];
        if (bonus_products_type?.length !== 0) {
            setBonusProducts(bonus_products_type);
        }

        // set bonus
        const bonus = defaultValues[`special_offer_type${type.id}_bonus`];
        if (bonus?.length !== 0) {
          setBonus(bonus);
        }

        // gift
        const gift_products = defaultValues[`type${type.id}_product_gift`];
        if (gift_products?.length !== 0) {
          setGiftProducts(gift_products);
        }

        // discount
        const discount_products = defaultValues?.special_offer_type4_discount;
        if (discount_products?.length !== 0) {
          setDiscountProducts(discount_products);
        }

        // set payment types
        const payment_types = defaultValues.special_offer_payments;
        if (payment_types?.length !== 0) {
          setPaymentTypes(payment_types);
        }

        // set promo codes
        const promo_codes = defaultValues[`type${type.id}_promo_code`];
        if (promo_codes?.length !== 0) {
          setPromoCodes(promo_codes);
        }

        // set discount_type
        const discount_type = defaultValues[`special_offer_type${type.id}_discount`];
        if (discount_type?.length !== 0) {
            setDiscountProducts(discount_type);
        }
      }, 150);
      return () => clearTimeout(_t);
    }
  }, [defaultValues, type.id]);

  useEffect(() => {
    if (defaultValues && defaultValues.length !== 0) {
      _initType();
    }
  }, [defaultValues, _initType]);

  useEffect(() => {
    _initDefVal();
  }, [_initDefVal]);

  const SpecialOfferType = (id) => {
    if (id.toString() === '1') {
      return (
        <TypeOne
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            unaccounted_products,
            additional_services,
            bonus_types: bonus_type,
            defaultValues,
          }}
          state={{
            typeID: type.id,
            products,
            bonus,
          }}
          action={{
            setBonusTypeID,
          }}
        />
      );
    } if (id.toString() === '2') {
      return (
        <TypeTwo
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            additional_services,
            bonus_types: bonus_type,
          }}
          state={{
            typeID: type.id,
            products,
            giftProducts,
          }}
        />
      );
    } if (id.toString() === '3') {
      return (
        <TypeThree
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            additional_services,
            bonus_types: bonus_type,
          }}
          state={{
            typeID: type.id,
            products,
            bonusProducts,
            discountProducts,
          }}
        />
      );
    } if (id.toString() === '4') {
      return (
        <TypeFour
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            additional_services,
            bonus_types: bonus_type,
          }}
          state={{
            typeID: type.id,
            bonusProducts,
            discountProducts,
            products,
          }}
        />
      );
    } if (id.toString() === '6') {
      return (
        <TypeSix
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            additional_services,
            bonus_types: bonus_type,
          }}
          state={{
            typeID: type.id,
            bonusTypeID,
            products,
            bonusProduct1,
            bonusProduct2,
            price,
          }}
          action={{
            setBonusTypeID,
          }}
        />
      );
    } if (id.toString() === '8') {
      return (
        <TypeEight
          formH={formH}
          list={{
            domains,
            markers,
            categories,
            additional_services,
            bonus_types: bonus_type,
          }}
          state={{
            typeID: type.id,
            bonusTypeID,
            products,
            bonus,
          }}
          action={{
            setBonusTypeID,
          }}
        />
      );
    }
  };

  return {
    state: {
      type: {
        id: type.id,
        title: type.title,
      },
      paymentTypes,
    },
    action: {
      setType: (e) => {
        if (e.target.value !== 'null') {
          setType({
            id: e.target.value,
            title: e.target.options[e.target.value]?.innerText,
          });
        } else {
          setType({
            id: null,
            title: null,
          });
        }
      },

    },
    render: {
      SpecialOfferType,
    },
  };
};

export default useController;
