import React, { useEffect, useState } from "react";
import s from "./InputMulti.module.scss";
import icon_close_red from "../../../assets/icons/icon_close_red.svg";
import Button from "../Button";
import s_Input from "../InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../InputWrapper";

const InputMulti = ({
  name,
  maxItems,
  form,
  defVal
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(!!defVal?.length){
      defVal.forEach(v => setItems((prev) => [...prev,
        <InputWrapper
            label="Телефон"
            id={`wh-${name}-phone-${prev.length}`}
            style={{ margin: 0 }}
        >
          <input
              className={s_Input.input}
              type="text"
              placeholder="Телефон"
              name={`${name}[${prev.length}].phone`}
              ref={form.register}
              id={`wh-${name}-phone-${prev.length}`}
              defaultValue={v}
          />
        </InputWrapper>,
    ]))
    }
  }, [defVal])

  const addItem = () => {
    setItems((prev) => [...prev,
      <InputWrapper
          label="Телефон"
          id={`wh-${name}-phone-${prev.length}`}
          style={{ margin: 0 }}
      >
        <input
            className={s_Input.input}
            type="text"
            placeholder="Телефон"
            name={`${name}[${prev.length}].phone`}
            ref={form.register}
            id={`wh-${name}-phone-${prev.length}`}
        />
      </InputWrapper>,
    ]);

  };
  const removeItem = (index) => {
    setItems((prev) => {
      const temp = [...prev];
      if (index !== -1) {
        temp.splice(index, 1);
        return temp;
      }
    });
  };
  return (
    <fieldset name={name} ref={form.register}>

      {/* eslint-disable-next-line react/no-array-index-key */}
      {items.map((el, i) => (
          <div style={{ display: "flex", gap: 16, marginBottom: 16 }} key={`input-${name}-key-${i}`}>
            <div style={{ width: '100%' }}>{el}</div>
            <div className={s.btn_remove}>
              <img src={icon_close_red} onClick={() => removeItem(i)} alt="icon remove" />
            </div>
          </div>
      ))}
      {/* eslint-disable-next-line max-len */}
      {maxItems !== items.length && <Button purple onClick={() => addItem()}>Додати телефон</Button>}
    </fieldset>
  );
};

export default InputMulti;
