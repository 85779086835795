import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createTopBottomMenu, editTopBottomMenu, getTopBottomMenuEdit, removeTopBottomMenu,
} from '../../lib/api/top_and_bottom_menu';
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (tbmId) => Promise.allSettled([getDomains(), tbmId
&& getTopBottomMenuEdit(tbmId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: tbmId && res[1]?.value?.data,
    })
  });

export const create = createTopBottomMenu;
export const edit = editTopBottomMenu;
export const remove = removeTopBottomMenu;

export const parseDataSend = (data, tbmId) => {
  let d = { ...data };
  const isUpdate = typeof tbmId !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
