export default (editor, opts = {}) => {
    const bm = editor.BlockManager;
    const style = `
        <style>
            h4.h4-native {
                font-size: 24px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h4.h4-native {
                    font-size: 22px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h4.h4-native {
                    font-size: 18px !important;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <h4 class="h4-native d-flex align-center justify-content-center mb-2 mb-lg-4"><span>Заголовок H4</span></h4>
            ${style}
        `,
    })
};