import axios from "axios";
import moment from "moment";
import {CLIENT_ID, CLIENT_SECRET, DOMAIN_API} from "../const/api";
import { oauthTokenRefreshUser } from "./api/auth";
import {refreshTokenAndCb} from "./crud/refreshToken";
/**
 * Axios Wrapper
 *
 * @param props
 * @returns {Promise}
 */
const fetchAx = (props) => {
  const token = localStorage.getItem('tokenData')
  if(token){
    const btoa = JSON.parse(token)
    const resProps = {
      ...props,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${btoa.token_type} ${btoa.token}`,
        ...props.headers,
      },
    };

    return axios(resProps);
  }
  const resProps = {
    ...props,
    headers: {
      'Content-Type': 'application/json',
      ...props.headers,
    },
  };

  return axios(resProps);
}

axios.interceptors.response.use((response) => response,  async (error) => {
  // const originalConfig = error.config;
  // if (error.response) {
  //   if (error.response.status === 401 && !originalConfig._retry) {
  //     originalConfig._retry = true;
  //
  //     try {
  //       if(localStorage?.tokenData) {
  //         const tokenData = JSON.parse(localStorage.tokenData);
  //
  //         if (moment(tokenData?.expires_in * 1000).toDate() < moment().toDate()) {
  //           if (tokenData.refresh_token) {
  //             const data = {
  //               grant_type: 'refresh_token',
  //               refresh_token: tokenData.refresh_token,
  //               client_id: CLIENT_ID,
  //               client_secret: CLIENT_SECRET,
  //             }
  //
  //             await oauthTokenRefreshUser(data)
  //                 .then((res) => {
  //                   tokenData.refresh_token = res.data.refresh_token;
  //                   tokenData.token = res.data.access_token;
  //                   tokenData.expires_in = Number(((new Date() / 1000) + res.data.expires_in).toFixed());
  //                   localStorage.setItem('tokenData', JSON.stringify(tokenData));
  //                   window.location.reload()
  //                   return res;
  //                 });
  //             return Promise.reject(originalConfig);
  //           }
  //         }
  //       }
  //       return Promise.reject(error);
  //     } catch (_error) {
  //       if (_error.response && _error.response.data) {
  //         return Promise.reject(_error.response.data);
  //       }
  //
  //       return Promise.reject(_error);
  //     }
  //   }
  // }
  return Promise.reject(error);

});

export default fetchAx;
