import { editPayment, getPaymentEdit } from "../../lib/api/payments";
import {parseAll} from "../../lib/api/_parseApi";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pId) => Promise.allSettled([pId && getPaymentEdit(pId)])
.then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
        return await refreshToken(() => window.location.reload())
    }

    return ({
        fields: pId && res[0]?.value?.data
    })
})

export const edit = editPayment;


export const parseDataSend = (data) => {
  let d = { ...data };

  d = parseAll(d, true);

  return d;
};
