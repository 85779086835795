import React, {useCallback, useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, remove } from './api';
import TopNavTabs from "../../components/ui/TopNavTabs/TopNavTabs";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PermissionClientList = () => {
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    setRest,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  useEffect(() => {
    if (rest && rest.client) { setFilteredData(rest.client); }
  }, [rest]);

  const filterByName = useCallback((e) => {
    const { value } = e.target;
    setFilteredData(rest?.client?.filter((d) => d.title.toLowerCase().includes(value.toLowerCase())));
  }, [rest]);

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Permission client list`} />
      {/* <NavFormTabs tabs={[*/}
      {/*  {*/}
      {/*    title: 'Админы',*/}
      {/*    url: '/app/permissions/admin',*/}
      {/*  },*/}
      {/*  {*/}
      {/*    title: 'Клиенты',*/}
      {/*    url: '/app/permissions/client',*/}
      {/*    isActive: true,*/}
      {/*  },*/}
      {/*]}*/}
      {/*/>*/}
      <TableListViewTable>
        <thead>
          <tr>
            <th>Название</th>
            <th> </th>
          </tr>

          <tr>
            <th>
              <input
                onChange={filterByName}
              />
            </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={10} />
            )
            : filteredData.length
                ? filteredData.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`permission-client-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Разрешение удалено',
                    }}
                    permissionRemove='admin.admin.permissions.destroy'
                    permissionEdit='admin.admin.permissions.edit'
                    // editLink={`/app/main/permission/update/${e.id}`}
                  >
                    <td>{e.title || '-'}</td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={10} />
                  </TableListViewRow>
            )}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default PermissionClientList;
