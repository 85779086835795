import { getBannerHome, removeBannerHome } from '../../lib/api/bannerHome';
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains()
])
    .then((res) => ({
        domains: res[0]?.value?.data
    }));

export const getData = getBannerHome;
export const remove = removeBannerHome;
