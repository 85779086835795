import React from 'react';
import { uuid } from 'uuidv4';
import {Controller, useForm} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import InputFile from '../../components/ui/InputFile';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const MediaEditView = () => {
  const { id: mediaID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: mediaID,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/media/not-found',
      remove: `/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Медиа создана',
      edit: 'Медиа обновлена',
      remove: 'Медиа удалена',
    },
  });

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Media edit`} />
      <div className="box-wrap">
        {mediaID
          ? (
            <Title>
              Медиа
            </Title>
          )
          : <Title>Новая медиа</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* custom_properties.alt_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-alt-ua">Alt УКР:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="custom_properties.alt_ua"
                  className='form-input'
                  type="text"
                  id="media-alt-ua"
                  placeholder="Введите текст"
                  maxLength={INPUT_MAX_LENGTH}
                  defaultValue={data?.fields?.custom_properties?.alt_ua}
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* custom_properties.alt_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-alt-ru">Alt РУ:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="custom_properties.alt_ru"
                  className='form-input'
                  type="text"
                  id="media-alt-ru"
                  placeholder="Введите текст"
                  maxLength={INPUT_MAX_LENGTH}
                  defaultValue={data?.fields?.custom_properties?.alt_ru}
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft />
            <ColRight>
              <label className="form-switch">
                <input
                  name="is_active"
                  type="checkbox"
                  id="marker-status-active"
                  defaultChecked={data?.fields?.is_active}
                  ref={register}
                />
                <i className="form-icon" />
                {' '}
                Статус активности
              </label>
            </ColRight>
          </Group>

          {/* domain_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-domain-id">Домен*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                        name="domain_id"
                        className={cn('form-select', {
                          'is-error': errors?.domain_id,
                        })}
                        id="media-domain-id"
                        ref={register}
                    >
                      <option value="">выберите домен</option>
                      {data && !!data.domains && data.domains.map((domain) => (
                          <option
                              key={`${uuid()}`}
                              value={domain.id}
                              selected={data?.fields?.domain_id === domain.id}
                          >
                            {domain.title}
                          </option>
                      ))}
                    </select>
                    {errors?.domain_id
                    && <p className="form-input-hint">{errors?.domain_id?.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* collection_name */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-collection-name">Тип изображения*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="collection_name"
                      className={cn('form-select', {
                        'is-error': errors?.collection_name,
                      })}
                      id="media-collection-name"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                       <option value="">выберите тип</option>
                      {data && !!data?.types && data?.types?.map((type) => (
                        <option
                          key={`${uuid()}`}
                          value={type.value}
                          selected={data?.fields?.collection_name === type.value}
                        >
                          {type.title}
                        </option>
                      ))}
                    </select>

                    {errors?.collection_name
                      && <p className="form-input-hint">{errors?.collection_name?.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* position */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-position">Позиция*:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="position"
                  className={cn('form-input', {
                    'is-error': errors.position,
                  })}
                  type="number"
                  id="media-position"
                  placeholder="Введите номер позиция"
                  maxLength={INPUT_MAX_LENGTH}
                  defaultValue={data?.fields?.position}
                  ref={register}
              />
              {errors.priority && <p className="form-input-hint">{errors.priority.message}</p>}
            </ColRight>
          </Group>

          {/* conversions.original */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="media-conversions-original">Изображение:</label>
            </ColLeft>
            <ColRight>
              <img src={data?.fields?.conversions?.original} />
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default MediaEditView;
