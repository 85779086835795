import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PubSub as ps } from "pubsub-js";
import { EDIT } from "../../../const/pubsub";
import { DOMAIN_API } from "../../../const/api";
import Breadcrumbs from "./Breadcrumbs";
import Button from "../../../components/ui/Button";
import s from "./topPanel.module.scss";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import icon_accept from "../../../assets/icons/icon_accept.svg";
import icon_save from "../../../assets/icons/icon_save.svg";
import icon_send from "../../../assets/icons/icon_send.svg";
import icon_pdf from "../../../assets/icons/icon_pdf.svg";
import { getOrderDocumentPdf, sendOrderDocumentClient } from "../../../lib/api/order_document";
import Print from "../Print";
import errorMessage from "../../../lib/errorMessage";
import { toast } from "react-toastify";
import { refreshToken } from "../../../lib/crud/refreshToken";

const TopPanel = ({ formProps, breadcrumbs, orderID, documentID, printData }) => {
    const form = formProps.formH;

    const [disableClientBtn, setDisableClientBtn] = useState(false);
    const [disablePdfBtn, setDisablePdfBtn] = useState(false);

    const formatDate = (value) => {
        const date = new Date(value).toLocaleDateString();
        const dateTime = new Date(value).toLocaleTimeString().slice(0,-3);
        return `${date} ${dateTime}`;
    };

    function applyHandler() {
        localStorage.setItem('apply', 'on');
        ps.publish(EDIT.save);
    }

    function createHandler() {
        ps.publish(EDIT.save);
    }

    const sendClient = async () => {
        setDisableClientBtn(true);
        await sendOrderDocumentClient(orderID, documentID).then(res => {
            if(res?.data?.message) {
                toast(res?.data?.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (res?.data?.success) {
                toast('Отправлено клиенту', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await sendOrderDocumentClient(orderID, documentID).then(res => {
                            if(res?.data?.message) {
                                toast(res?.data?.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }

                            if (res?.data?.success) {
                                toast('Отправлено клиенту', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        })
                    })
                } else {
                    await errorMessage(err, `/api/admin/order/${orderID}/document/${documentID}/send-by-email POST`)
                }
            })
            .finally(() => setDisableClientBtn(false))
    };

    const createPdf = async () => {
        setDisablePdfBtn(true);
        await getOrderDocumentPdf(orderID, documentID).then(res => {
            window.open(`${DOMAIN_API}/api/admin/order/${orderID}/document/${documentID}/pdf`,'_blank');
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getOrderDocumentPdf(orderID, documentID).then(res => {
                            window.open(`${DOMAIN_API}/api/admin/order/${orderID}/document/${documentID}/pdf`,'_blank');
                        })
                    })
                } else {
                    await errorMessage(err, `/api/admin/order/${orderID}/document/${documentID}/pdf GET`)
                }
            })
            .finally(() => setDisablePdfBtn(false));
    };

    return (
        <div>
            <div className={s.breadcrumbs__wrapper}>
                <div>
                    <Breadcrumbs {...breadcrumbs}/>
                    <div className={s.title}>
                        <div>Документ #{documentID}</div>
                    </div>
                </div>
                <div className={s.order_edited}>
                        <div className={s.order_edited__block}>
                            <div className={s.order_edited__title}>Отредактировано:</div>
                            <div className={s.order_edited__date}>{formatDate(formProps?.data?.fields?.updated_at)}</div>
                        </div>
                    </div>
            </div>
            <div className={s.footer}>
                <div>
                    <Link to={`/app/order/update/${orderID}/document`}>
                        <img src={icon_arrow_back} alt="Arrow back icon"/>
                        Назад
                    </Link>
                </div>
                <div className={s.buttons__wrapper}>
                    <div className={s.buttons__group}>
                        {formProps?.data?.fields?.id && (
                            <>
                                <div className={s.button__block}>
                                    <Button green onClick={applyHandler}>
                                        <img src={icon_accept} alt="Accept icon"/>
                                        Применить
                                    </Button>
                                </div>
                                <div className={s.button__block}>
                                    <Button green onClick={createHandler}>
                                        <img src={icon_save} alt="Save icon"/>
                                        Сохранить
                                    </Button>
                                </div>
                            </>
                        )}
                        <div className={s.button__block}>
                            <Print printData={printData} />
                        </div>
                        <div className={s.button__block}>
                            <Button purple onClick={createPdf} disabled={disablePdfBtn}>
                                <img src={icon_pdf} alt="PDF icon"/>
                                Сформировать PDF
                            </Button>
                        </div>
                        <div className={s.button__block}>
                            <Button purple onClick={sendClient} disabled={disableClientBtn}>
                                <img src={icon_send} alt="Send icon"/>
                                Отправить заказчику
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopPanel;