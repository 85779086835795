import {parseAll} from '../../lib/api/_parseApi';
import {createTopic, editTopic, getTopicEdit, removeTopic,} from '../../lib/api/devices';
import {refreshToken} from "../../lib/crud/refreshToken";
import {getCatTree} from "../../lib/api/categories";

export const getData = (deviceId) => Promise.allSettled([deviceId && getTopicEdit(deviceId), getCatTree()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }
    return ({
      fields: deviceId && res[0]?.value?.data,
      categories: res && res[1]?.value?.data,
    })
  });

export const create = createTopic;
export const edit = editTopic;
export const remove = removeTopic;

export const parseDataSend = (data, soId) => {
  let d = {...data};
  const isUpdate = typeof soId !== 'undefined';

  d = parseAll(d, !isUpdate, ['is_active']);

  return d;
};

export const parseDataGet = (data) => {
  const d = {...data};

  return d;
};
