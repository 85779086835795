import cn from 'classnames';
import React from 'react';

import s from './Form.module.scss';

const Form = ({ children, attrs, Tag = 'form' }) => {
  const attrsMerge = {
    ...attrs,
    className: cn('form-horizontal', s.form, attrs && attrs.className),
  };

  return (
    <Tag {...attrsMerge} onKeyDown={e => {
      if(e.keyCode === 13) {
        e.preventDefault();
        return false;
      }
    }}>{children}</Tag>
  );
};

export default Form;
