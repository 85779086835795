import React from 'react';

const IconRemove = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8332 5.83337C15.6122 5.83337 15.4003 5.92117 15.244 6.07745C15.0877 6.23373 14.9999 6.44569 14.9999 6.66671V15.9926C14.976 16.414 14.7866 16.8088 14.4728 17.0912C14.1591 17.3736 13.7465 17.5205 13.3249 17.5001H6.67492C6.25333 17.5205 5.84074 17.3736 5.527 17.0912C5.21327 16.8088 5.02382 16.414 4.99992 15.9926V6.66671C4.99992 6.44569 4.91212 6.23373 4.75584 6.07745C4.59956 5.92117 4.3876 5.83337 4.16658 5.83337C3.94557 5.83337 3.73361 5.92117 3.57733 6.07745C3.42105 6.23373 3.33325 6.44569 3.33325 6.66671V15.9926C3.35704 16.8561 3.72207 17.675 4.3484 18.2699C4.97473 18.8649 5.81131 19.1873 6.67492 19.1667H13.3249C14.1885 19.1873 15.0251 18.8649 15.6514 18.2699C16.2778 17.675 16.6428 16.8561 16.6666 15.9926V6.66671C16.6666 6.44569 16.5788 6.23373 16.4225 6.07745C16.2662 5.92117 16.0543 5.83337 15.8332 5.83337Z" fill="#fff"/>
            <path d="M16.6667 3.33337H13.3333V1.66671C13.3333 1.44569 13.2455 1.23373 13.0892 1.07745C12.933 0.921171 12.721 0.833374 12.5 0.833374H7.5C7.27898 0.833374 7.06702 0.921171 6.91074 1.07745C6.75446 1.23373 6.66666 1.44569 6.66666 1.66671V3.33337H3.33333C3.11232 3.33337 2.90036 3.42117 2.74408 3.57745C2.5878 3.73373 2.5 3.94569 2.5 4.16671C2.5 4.38772 2.5878 4.59968 2.74408 4.75596C2.90036 4.91224 3.11232 5.00004 3.33333 5.00004H16.6667C16.8877 5.00004 17.0996 4.91224 17.2559 4.75596C17.4122 4.59968 17.5 4.38772 17.5 4.16671C17.5 3.94569 17.4122 3.73373 17.2559 3.57745C17.0996 3.42117 16.8877 3.33337 16.6667 3.33337ZM8.33333 3.33337V2.50004H11.6667V3.33337H8.33333Z" fill="#fff"/>
            <path d="M9.16666 14.1667V8.33333C9.16666 8.11232 9.07887 7.90036 8.92259 7.74408C8.76631 7.5878 8.55434 7.5 8.33333 7.5C8.11232 7.5 7.90036 7.5878 7.74408 7.74408C7.5878 7.90036 7.5 8.11232 7.5 8.33333V14.1667C7.5 14.3877 7.5878 14.5996 7.74408 14.7559C7.90036 14.9122 8.11232 15 8.33333 15C8.55434 15 8.76631 14.9122 8.92259 14.7559C9.07887 14.5996 9.16666 14.3877 9.16666 14.1667Z" fill="#fff"/>
            <path d="M12.4999 14.1667V8.33333C12.4999 8.11232 12.4121 7.90036 12.2558 7.74408C12.0996 7.5878 11.8876 7.5 11.6666 7.5C11.4456 7.5 11.2336 7.5878 11.0773 7.74408C10.9211 7.90036 10.8333 8.11232 10.8333 8.33333V14.1667C10.8333 14.3877 10.9211 14.5996 11.0773 14.7559C11.2336 14.9122 11.4456 15 11.6666 15C11.8876 15 12.0996 14.9122 12.2558 14.7559C12.4121 14.5996 12.4999 14.3877 12.4999 14.1667Z" fill="#fff"/>
        </svg>

    );
};

export default IconRemove;