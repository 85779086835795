import grapesjs from "grapesjs";
import loadComponents from "./components";
import loadBlock from "./blocks";

export default grapesjs.plugins.add('collFeaturesComponent', (editor, opts) => {
    let options = {
        label: 'Col Features img',
        name: 'col Features img',
        category: 'Col img',
    };

    for(let name in options) {
        if(!(name in opts)) opts[name] = options[name];
    }

    loadBlock(editor, opts);
    loadComponents(editor, opts);
})