import { useState } from "react";
import { toast } from "react-toastify";
import { ERROR_RESP, WONT_REMOVE } from "../../const/text";
import confirmDialog from "../confirmDialog";
import { refreshToken } from "./refreshToken";

const useRemove = ({
  id, api, isMounted, success, alert, confirmMessage = true
}) => {
  const [load, setLoad] = useState(false);

  // send remove
  async function sendRemove() {
    setLoad(true);

    try {
      if (!isMounted) return;

      await api(id);

      if (success) success();
      if (alert) toast(alert);
    } catch (err) {
      if(err?.response?.status === 401) {
        await refreshToken(async () => {
          await api(id);
        })
      } else {
        toast(ERROR_RESP, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    setLoad(false);
  }

  // handle remove
  async function removeHandler() {
    try {
      if (confirmMessage) {
        await confirmDialog(WONT_REMOVE);
        await sendRemove();
      } else {
        await sendRemove();
      }
    } catch (err) {
      if(err?.response?.status === 401) {
        await refreshToken(async () => {
          if (confirmMessage) {
            await confirmDialog(WONT_REMOVE);
            await sendRemove();
          } else {
            await sendRemove();
          }
        })
      } else {
        toast(ERROR_RESP, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return {
    load,
    removeHandler,
  };
};

export default useRemove;
