import React, { useState, useEffect } from 'react';
import s_Modal from './modal.module.scss';
import s_Table from "../orderTable.module.scss";
import Button from "../../../../components/ui/Button";
import { getProductAvailability } from "../../../../lib/api/products";
import Loader from "../../../../components/ui/Loader";
import  {refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";

const ModalAvailability = ({ active, setActive, productId, title }) => {
    const [warehouses, setWarehouses] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true);
        getProductAvailability(productId)
            .then(res => setWarehouses(res?.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getProductAvailability(productId)
                            .then(res => setWarehouses(res?.data))
                    })
                } else {
                    await errorMessage(err, `/api/admin/order/${formProps?.orderID} PUT`)
                }
            }).finally(() => setLoad(false));
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper}>
                            <table className={s_Table.table}>
                                <thead className={s_Table.thead}>
                                    <tr>
                                        <th>
                                            <div>ID Магазина</div>
                                        </th>
                                        <th>
                                            <div>Магазин</div>
                                        </th>
                                        <th>
                                            <div>К-во</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={s_Table.tbody}>
                                    {!load ? (
                                        <>
                                            {warehouses.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div>{item.id}</div>
                                                    </td>
                                                    <td>
                                                        <div>{item.title}</div>
                                                    </td>
                                                    <td>
                                                        <div>{item.quantity}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : <Loader />}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAvailability;