import Form from '../../components/ui/FormEditView/Form';
import Group from "../../components/ui/FormEditView/Group";
import ColLeft from "../../components/ui/FormEditView/ColLeft";
import ColRight from "../../components/ui/FormEditView/ColRight";
import GridEditView from '../../components/ui/GridEditView';
import cn from "classnames";
import {INPUT_MAX_LENGTH} from "../../const/view";
import React from "react";
import {useForm} from "react-hook-form";
import useEdit from "../../lib/crud/useEdit";
import {edit, getData} from './api';
import Loader from "../../components/ui/Loader";
import {useParams} from "react-router-dom";
import Title from "../../components/ui/FormEditView/Title";
import InputFile from "../../components/ui/InputFile";
import {parseDataSend} from "./api";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PaymentEditView = () => {
    const { id: pId } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        watch,
        handleSubmit,
        reset,
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: pId,
        updateFields: reset,
        handleSubmit,
        api: {
            edit,
            get: getData,
        },
        parse: {
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/payment/not-found',
            save: `/app/payments${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            edit: 'Оплата обновлена',
        },
    })

    return(
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Payment edit`} />
            <div className="box-wrap">
                {pId
                    ? (
                        <Title>
                            Оплата -
                            <small>{data && data?.fields?.title}</small>
                        </Title>
                    )
                    : <Title>Новая оплата</Title>
                }
                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide" />

                    {/*title_ua*/}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="title_ua">Название УКР*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ua"
                                className={cn('form-input', {
                                    'is-error': errors.title_ua,
                                })}
                                type="text"
                                id="title_ua"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                            {errors.title_ua  && <p className="form-input-hint">{errors.title_ua.message}</p>}
                        </ColRight>
                    </Group>

                    {/*title_ru*/}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="title_ru">Название РУС*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ru"
                                className={cn('form-input', {
                                    'is-error': errors.title_ru,
                                })}
                                type="text"
                                id="title_ru"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                            {errors.title_ru  && <p className="form-input-hint">{errors.title_ru.message}</p>}
                        </ColRight>
                    </Group>

                    {/* icon */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="icon">Иконка:</label>
                            <div>Максимальное разрешерине иконки 1920x1080</div>
                        </ColLeft>
                        <ColRight>
                             <InputFile
                               name="icon"
                               val={watch('icon')}
                               formH={formH}
                               ref={register}
                               acceptStr="PNG, JPG, GIF"
                               accept="image/*"
                               sizeMB={1}
                             />
                        </ColRight>
                    </Group>

                </Form>
                {load && <Loader />}
            </div>
        </GridEditView>
    )
}

export default PaymentEditView
