import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import TableDynamicFieldsHead from '../../components/common/TableDynamicFields/TableDynamicFieldsHead';
import TableDynamicFieldsRowH from '../../components/common/TableDynamicFields/TableDynamicFieldsRowH';
import TableDynamicFieldsBody from '../../components/common/TableDynamicFields/TableDynamicFieldsBody';
import TableDynamicFieldsRow from '../../components/common/TableDynamicFields/TableDynamicFieldsRow';
import TableDynamicFields from '../../components/common/TableDynamicFields';

const TableAddress = ({
  updateID, formH, nameParent, hidden
}) => {
  const { fields, append, remove } = useFieldArray(
    {
      control: formH.control,
      name: nameParent,
      keyName: 'field_id',
    },
  );

  // add empty single row if 0
  useEffect(() => {
    if (fields.length) return;

    append({}, false);
  }, [fields]);

  function handleRemove(i) {
    remove(i);
  }

  return (
    <>
      <TableDynamicFields
        attrs={{
          className: hidden && '-d-none',
        }}
        onAdd={() => append({title_ru: '', title_ua: '', street_ru: '', street_ua: '', latitude: '', longitude: ''})}
      >
        <TableDynamicFieldsHead>
          <TableDynamicFieldsRowH>
            <th>Название УКР</th>
            <th>Название РУ</th>
            <th>Адрес УКР</th>
            <th>Адрес РУ</th>
            <th>Широта</th>
            <th>Долгота</th>
          </TableDynamicFieldsRowH>
        </TableDynamicFieldsHead>

        <TableDynamicFieldsBody>
          {fields.map((e, i) => (
            <TableDynamicFieldsRow
              key={e.field_id}
              onRemove={() => handleRemove(i)}
            >
              <td>
                {(updateID && e.id) && (
                  <input
                    type="hidden"
                    name={`${nameParent}[${i}].id`}
                    ref={formH.register()}
                    defaultValue={e.id}
                  />
                )}

                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.title_ua,
                  })}
                  name={`${nameParent}[${i}].title_ua`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.title_ua}
                />

                {formH.errors?.[nameParent]?.[i]?.title_ua && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].title_ua.message}</p>
                )}
              </td>
              <td>
                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.title_ru,
                  })}
                  name={`${nameParent}[${i}].title_ru`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.title_ru}
                />

                {formH.errors?.[nameParent]?.[i]?.title_ru && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].title_ru.message}</p>
                )}
              </td>
              <td>
                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.street_ua,
                  })}
                  name={`${nameParent}[${i}].street_ua`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.street_ua}
                />

                {formH.errors?.[nameParent]?.[i]?.street_ua && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].street_ua.message}</p>
                )}
              </td>
              <td>
                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.street_ru,
                  })}
                  name={`${nameParent}[${i}].street_ru`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.street_ru}
                />

                {formH.errors?.[nameParent]?.[i]?.street_ru && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].street_ru.message}</p>
                )}
              </td>
              <td>
                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.latitude,
                  })}
                  name={`${nameParent}[${i}].latitude`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.latitude}
                />

                {formH.errors?.[nameParent]?.[i]?.latitude && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].latitude.message}</p>
                )}
              </td>
              <td>
                <textarea
                  className={cn('form-input', {
                    'is-error': formH.errors?.[nameParent]?.[i]?.longitude,
                  })}
                  name={`${nameParent}[${i}].longitude`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                  defaultValue={e.longitude}
                />

                {formH.errors?.[nameParent]?.[i]?.longitude && (
                  <p className="form-input-hint">{formH.errors[nameParent][i].longitude.message}</p>
                )}
              </td>

            </TableDynamicFieldsRow>
          ))}
        </TableDynamicFieldsBody>
      </TableDynamicFields>
    </>
  );
};

export default TableAddress;
