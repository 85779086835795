import { getDomains } from '../../lib/api/domains';
import { getAdditionalServices, removeAdditionalService } from '../../lib/api/additional_services';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
])
  .then((res) => {
    if (res) {
      return {
        domains: res[0]?.value?.data
      };
    }
    return {};
  });

export const getData = getAdditionalServices;
export const remove = removeAdditionalService;
