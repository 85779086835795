import {
    STATUS_CREATE, STATUS_GET_EDIT, STATUS_EDIT, STATUS_DELETE, STATUSES_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getStatuses = (params) => fetchAx({
    url: STATUSES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const getStatusAll = (params) => fetchAx({
    url: STATUSES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.xxl,
        ...params,
    },
});

export const createStatus = (data) => fetchAx({
    url: STATUS_CREATE,
    method: 'POST',
    data,
});

export const getStatusEdit = (id) => fetchAx({
    url: STATUS_GET_EDIT.join(id),
    method: 'GET',
});

export const editStatus = (id, data) => fetchAx({
    url: STATUS_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeStatus = (id) => fetchAx({
    url: STATUS_DELETE.join(id),
    method: 'DELETE',
});
