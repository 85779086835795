import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import cn from 'classnames';
import { toast } from "react-toastify";
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {getMedia, removeMedia} from "../../lib/api/media";
import { ModalMedia } from "./Modal";
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import GalleryImages from "../../components/ui/GalleryImages";
import errorMessage from "../../lib/errorMessage";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "./BannerManagementHead.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import ImageLoad from "../../components/ui/ImageLoad";

const BannerManagementHeadEditView = () => {
  const { id: bmhID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: bmhID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/banner-management/head/not-found',
      remove: `/app/banner-management/head${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/banner-management/head${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Банер над шапкою сайту створено',
      edit: 'Банер над шапкою сайту оновлено',
      remove: 'Банер над шапкою сайту видалено',
    },
  });

  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);
  const [typeCoverDataUa, setTypeCoverDataUa] = useState([]);
  const [typeTableDataUa, setTypeTableDataUa] = useState([]);
  const [typeMobileDataUa, setTypeMobileDataUa] = useState([]);

  const [typeCoverDataRu, setTypeCoverDataRu] = useState([]);
  const [typeTableDataRu, setTypeTableDataRu] = useState([]);
  const [typeMobileDataRu, setTypeMobileDataRu] = useState([]);

  const [dataModal, setDataModal] = useState({});

  const [date, setDate] = useState({
    starts_at: '',
    finish_at: '',
  });
  const [time, setTime] = useState({
    time_from: '00:00:00',
    time_to: '23:59:59',
  });

  useEffect(() => {
    if(data?.fields?.starts_at || data?.fields?.finish_at) {
      setDate({
        starts_at: data?.fields?.starts_at?.split(' ')[0],
        finish_at: data?.fields?.finish_at?.split(' ')[0],
      });

      setTime({
        time_from: data?.fields?.starts_at?.split(' ')[1],
        time_to: data?.fields?.finish_at?.split(' ')[1],
      });
    }
  }, [data]);

  useEffect(() => {
    if(bmhID) {
      getMedia({
        'entity_type': 'promo_header',
        'entity_id': bmhID,
        page: 1,
        per_page: 500,
      }).then(res => {
        const filterLang = (lang) => res?.data?.data?.filter(item => item?.custom_properties?.locale === lang);
        const filterLangRu = filterLang('ru');
        const filterCoverRu = filterLangRu?.filter(item => item?.collection_name === 'cover');
        const filterTableRu = filterLangRu?.filter(item => item?.collection_name === 'tablet');
        const filterMobileRu = filterLangRu?.filter(item => item?.collection_name === 'mobile');

        if(filterCoverRu) {
          setTypeCoverDataRu(filterCoverRu);
        }

        if(filterTableRu) {
          setTypeTableDataRu(filterTableRu);
        }

        if(filterMobileRu) {
          setTypeMobileDataRu(filterMobileRu);
        }

        const filterLangUa = filterLang('ua');
        const filterCoverUa = filterLangUa?.filter(item => item?.collection_name === 'cover');
        const filterTableUa = filterLangUa?.filter(item => item?.collection_name === 'tablet');
        const filterMobileUa = filterLangUa?.filter(item => item?.collection_name === 'mobile');

        if(filterCoverUa) {
          setTypeCoverDataUa(filterCoverUa);
        }

        if(filterTableUa) {
          setTypeTableDataUa(filterTableUa);
        }

        if(filterMobileUa) {
          setTypeMobileDataUa(filterMobileUa);
        }

      }).catch(err => errorMessage(err, '/api/admin/media GET'))
    }
  }, [bmhID]);

  /* eslint-disable */

  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner head edit`} />
        <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

          <button type="submit" className="d-hide" />

          <TopPanelChild
              title={bmhID ? data && data?.fields?.name : "Новий банер над шапкою сайту"}
              breadcrumbs={{
                items: [
                  {
                    title: "Головна",
                    url: "/app",
                  },
                  {
                    title: "Банер над шапкою сайту",
                    url: `/app/banner-management/head${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: bmhID ? data && data?.fields?.name: "Новий банер над шапкою сайту"
                  },
                ],
              }}
              back={`/app/banner-management/head${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              id={bmhID}
              children={<div className={s.top__item}>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="b-is-active"
                    label="Активність"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </div>}
              btnEdit
          />

          {!load ? (
              <div className={s.top}>
                <div className={s.top__input}>
                  {/* name */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва"
                          id="bml-name"
                          errorComponent={errors?.name &&
                          <p className="form-input-hint">{errors?.name?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.name,
                            })}
                            type="text"
                            placeholder="Назва"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="name"
                            id="bml-name"
                            defaultValue={data?.fields?.name}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* domain_id */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          {data
                              ? (
                                  <select
                                      name="domain_id"
                                      className={cn(s_Select.select, {
                                        [s_Select.select_error]: formH.errors?.domain_id,
                                      })}
                                      id="b-domain"
                                      ref={formH.register({
                                        required: VALIDATION.select.required,
                                      })}
                                  >
                                    <option value="">виберіть домен</option>
                                    {data && !!data.domains && data.domains.map((domain) => (
                                        <option
                                            key={domain.id}
                                            value={domain.id}
                                            selected={domain.id === data?.fields?.domain_id}
                                        >
                                          {domain.title}
                                        </option>
                                    ))}
                                  </select>
                              )
                              : (
                                  <div className="p-relative">
                                    <div className="loading" />
                                  </div>
                              )}
                          <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                        </div>
                        {formH.errors?.domain_id
                        && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>

                  {/* position */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Позиція"
                          id="bml-position"
                          errorComponent={errors?.position &&
                          <p className="form-input-hint">{errors?.position?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.position,
                            })}
                            type="number"
                            placeholder="Позиція"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="position"
                            id="bml-position"
                            defaultValue={data?.fields?.position}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* starts_at */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="starts_at"
                              id="bml-starts-at"
                              placeholder="Термін дії від"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.starts_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                starts_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-date_form">Термін дії від</label>
                        </div>
                        {errors?.starts_at
                        && <p className="form-input-hint">{errors?.starts_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_from"
                              id="bml-time-from"
                              placeholder="Час"
                              ref={register}
                              value={time.time_from}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_from: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_from">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* finish_at */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="finish_at"
                              id="bml-finish-at"
                              placeholder="Термін дії до"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.finish_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                finish_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-finish_at">Термін дії до</label>
                        </div>
                        {errors?.finish_at
                        && <p className="form-input-hint">{errors?.finish_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_to"
                              id="bml-time_to"
                              placeholder="Час"
                              ref={register}
                              value={time.time_to}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_to: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_to">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                </div>
                <div className={s.top__img_block}>

                  {/* link_ua */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання УКР"
                          id="bml-link-ua"
                          errorComponent={errors?.link_ua &&
                          <p className="form-input-hint">{errors?.link_ua?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ua,
                            })}
                            type="text"
                            placeholder="Посилання УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ua"
                            id="bml-link-ua"
                            defaultValue={data?.fields?.link_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block} style={{ flexDirection: 'column' }}>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ua`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataUa}
                            setImageData={setTypeCoverDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='cover'
                            windowName='desktop'
                            width={'100%'}
                            height={60}
                            classNameMedia={s.media}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1530x50 px
                        </div>
                      </div>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ua`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeTableDataUa}
                            setImageData={setTypeTableDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='tablet'
                            windowName='tablet'
                            width={'80%'}
                            height={60}
                            classNameMedia={s.media}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1230x50 px
                        </div>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ImageLoad
                            id={`product_img_ua`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataUa}
                            setImageData={setTypeMobileDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='mobile'
                            windowName='mobile'
                            width={'60%'}
                            height={60}
                            classNameMedia={s.media}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x40 px
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={s.top__img_block}>
                  {/* link_ru */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання РУС"
                          id="bml-link-ru"
                          errorComponent={errors?.link_ru &&
                          <p className="form-input-hint">{errors?.link_ru?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ru,
                            })}
                            type="text"
                            placeholder="Посилання РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ru"
                            id="bml-link-ru"
                            defaultValue={data?.fields?.link_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block} style={{ flexDirection: 'column' }}>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ru`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataRu}
                            setImageData={setTypeCoverDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='cover'
                            windowName='desktop'
                            width={'100%'}
                            height={60}
                            classNameMedia={s.media}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1530x50 px
                        </div>
                      </div>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ru`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeTableDataRu}
                            setImageData={setTypeTableDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='tablet'
                            windowName='tablet'
                            width={'80%'}
                            height={60}
                            classNameMedia={s.media}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1230x50 px
                        </div>
                      </div>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ru`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataRu}
                            setImageData={setTypeMobileDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_header'
                            collectionName='mobile'
                            windowName='mobile'
                            width={'60%'}
                            height={60}
                            classNameMedia={s.media}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x40 px
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : <Loader />}
        </Form>

        {activeMedia && <ModalMedia
            active={activeMedia}
            setActive={setActiveMedia}
            data={dataModal}
            collectionName={dataModal?.collection_name || 'cover'}
            locale={dataModal?.custom_properties?.locale === 'ru' ? 'ru' : 'ua'}
            setImageData={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataRu
                    : dataModal?.collection_name === 'tablet'
                        ? setTypeTableDataRu
                        : dataModal?.collection_name === 'mobile' && setTypeMobileDataRu
                : dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataUa
                    : dataModal?.collection_name === 'tablet'
                        ? setTypeTableDataUa
                        : dataModal?.collection_name === 'mobile' && setTypeMobileDataUa
            }
            title='Редагування картинки'
        />}
        {activeRemove && <ModalRemove
            active={activeRemove}
            setActive={setActiveRemove}
            data={dataModal}
            funcRemove={removeMedia}
            setRemoveItem={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataRu
                    : dataModal?.collection_name === 'tablet'
                        ? setTypeTableDataRu
                        : dataModal?.collection_name === 'mobile' && setTypeMobileDataRu
                : dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataUa
                    : dataModal?.collection_name === 'tablet'
                        ? setTypeTableDataUa
                        : dataModal?.collection_name === 'mobile' && setTypeMobileDataUa
            }
            title='Видалити банер меню'
        />}
      </>
  )

  /*return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner head edit`} />
      <div className="box-wrap">
        {bmhID
          ? (
            <Title>
              Банер над шапкой сайта -
              <small>{data && data.fields.title_ru}</small>
            </Title>
          )
          : <Title>Новый Банер категории</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/!* link *!/}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-link">Ссылка:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="link"
                  className={cn('form-input', {
                    'is-error': errors.link,
                  })}
                  type="text"
                  id="bml-link"
                  placeholder="Введите текст"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.link && <p className="form-input-hint">{errors.link.message}</p>}
            </ColRight>
          </Group>

          {/!* color_code *!/}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-color-code">Цвет:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="color_code"
                  className={cn('form-input', {
                    'is-error': errors.color_code,
                  })}
                  type="text"
                  id="bml-color_code"
                  placeholder="Введите цвет"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.color_code && <p className="form-input-hint">{errors.color_code.message}</p>}
            </ColRight>
          </Group>

          {bmhID && (
              <>
                <div className="divider text-center" data-content="Изображение дектопные РУ" />

                <div>Максимальное разрешение картинки 4096x2160</div>
                <GalleryImages
                    id={`product_img_ru`}
                    nameParent={`product_images[image_list]`}
                    formH={formH}
                    accept="image/!*"
                    acceptStr="PNG, JPG, GIF"
                    imageData={typeCoverDataRu}
                    setImageData={setTypeCoverDataRu}
                    setActiveMedia={setActiveMedia}
                    setActiveRemove={setActiveRemove}
                    setDataModal={setDataModal}
                    prodID={bmhID}
                    entityType='promo_header'
                    collectionName='cover'
                    altData={{
                      alt_ru: '',
                      locale: 'ru'
                    }}
                    // limit={12}
                />

                <div className="divider text-center" data-content="Изображение дектопные УКР" />

                <div>Максимальное разрешение картинки 4096x2160</div>
                <GalleryImages
                    id={`product_img_ua`}
                    nameParent={`product_images[image_list]`}
                    formH={formH}
                    accept="image/!*"
                    acceptStr="PNG, JPG, GIF"
                    imageData={typeCoverDataUa}
                    setImageData={setTypeCoverDataUa}
                    setActiveMedia={setActiveMedia}
                    setActiveRemove={setActiveRemove}
                    setDataModal={setDataModal}
                    prodID={bmhID}
                    entityType='promo_header'
                    collectionName='cover'
                    altData={{
                      alt_ua: '',
                      locale: 'ua'
                    }}
                    // limit={12}
                />

                <div className="divider text-center" data-content="Изображение мобильные РУ" />

                <div>Максимальное разрешение картинки 4096x2160</div>
                <GalleryImages
                    id={`product_img_mobile_ru`}
                    nameParent={`product_images[image_list]`}
                    formH={formH}
                    accept="image/!*"
                    acceptStr="PNG, JPG, GIF"
                    imageData={typeMobileDataRu}
                    setImageData={setTypeMobileDataRu}
                    setActiveMedia={setActiveMedia}
                    setActiveRemove={setActiveRemove}
                    setDataModal={setDataModal}
                    prodID={bmhID}
                    entityType='promo_header'
                    collectionName='mobile'
                    altData={{
                      alt_ru: '',
                      locale: 'ru'
                    }}
                    // limit={12}
                />

                <div className="divider text-center" data-content="Изображение мобильные УКР" />

                <div>Максимальное разрешение картинки 4096x2160</div>
                <GalleryImages
                    id={`product_img_mobile_ua`}
                    nameParent={`product_images[image_list]`}
                    formH={formH}
                    accept="image/!*"
                    acceptStr="PNG, JPG, GIF"
                    imageData={typeMobileDataUa}
                    setImageData={setTypeMobileDataUa}
                    setActiveMedia={setActiveMedia}
                    setActiveRemove={setActiveRemove}
                    setDataModal={setDataModal}
                    prodID={bmhID}
                    entityType='promo_header'
                    collectionName='mobile'
                    altData={{
                      alt_ua: '',
                      locale: 'ua'
                    }}
                    // limit={12}
                />
              </>
          )}

        </Form>

        {activeMedia && <ModalMedia
            active={activeMedia}
            setActive={setActiveMedia}
            data={dataModal}
            collectionName={dataModal?.collection_name === 'cover' ? 'cover' : 'mobile'}
            locale={dataModal?.custom_properties?.locale === 'ru' ? 'ru' : 'ua'}
            setImageData={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
            }
            title='Редактирование картинки'
        />}
        {activeRemove && <ModalRemove
            active={activeRemove}
            setActive={setActiveRemove}
            data={dataModal}
            funcRemove={removeMedia}
            setRemoveItem={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
            }
            title='Удалить изображение'
        />}

        {load && <Loader />}
      </div>
    </GridEditView>
  );*/
};

export default BannerManagementHeadEditView;
