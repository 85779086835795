import React, { useCallback, useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import { getData, remove } from './api';
import TopNavTabs from '../../components/ui/TopNavTabsNew/TopNavTabs';
import TopPagination from '../../components/common/TableListViewNew/TopPagination';

import { geDomainName } from '../../lib/const/domains';
import checkPermission from '../../lib/checkPermission';
import Input from "../../components/common/TableListViewNew/Input/Input";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PopularCategoryList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [filteredTitleRu, setFilteredTitleRu] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  useEffect(() => {
    if (rest && rest?.length) { setFilteredData(rest); }
  }, [rest]);

  const clearFilter = () => {
    setFilteredData(rest);
    setFilteredTitleRu("");
  };

  const filterByTitleRu = useCallback((e) => {
    const { value } = e.target;
    setFilteredTitleRu(value);
    setFilteredData(rest?.filter((d) => d.category.title_ru
      .toLowerCase().includes(value.toLowerCase())));
  }, [rest]);
  /* eslint-disable */
  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Popular categories list`} />
      {!load && (
      <TopPagination
          tabs={(
              <TopNavTabs tabs={[
                {
                  title: 'Преимущества',
                  url: '/app/main/benefits',
                  permissionName: 'admin.benefit.index',
                },
                {
                  title: 'Популярные товары',
                  url: '/app/main/popular-goods',
                  permissionName: 'admin.popularity-block.all',
                },
                {
                  title: 'Уникальные сервисы',
                  url: '/app/main/unique-services',
                  permissionName: 'admin.services-block.index',
                },
                {
                  title: 'Сервисы',
                  url: '/app/main/services',
                  permissionName: 'admin.main-page-service.all',
                },
                {
                  title: 'Новинки',
                  url: '/app/main/newest',
                  permissionName: 'admin.newest-block.all',
                },
                {
                  title: 'Популярные категории',
                  url: '/app/main/popular-categories',
                  permissionName: 'admin.main-page-category-block.all',
                  isActive: true,
                },
              ]}
              />
          )}
      />
      )}
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название УКР</th>
            <th>
              <div>
                <span>Название РУС</span>
                <Input
                    value={filteredTitleRu}
                    onChange={filterByTitleRu}
                    funcClearValue={clearFilter}
                    style={{ maxWidth: "80px" }}
                />
              </div>
            </th>
            <th>Домен</th>
            <th> </th>
          </tr>

        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={5} />
            )
            : filteredData?.length
              ? filteredData?.map((e, i) => (
                <TableListViewRow
                  id={e.id}
                  key={`${uuid()}`}
                  remove={{
                    api: remove,
                    success: () => setFilteredData((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]),
                    alert: 'Популярная категория удалена',
                  }}
                  editLink={`/app/main/popular-category/update/${e.id}`}
                  permissionRemove='admin.main-page-category-block.destroy'
                  permissionEdit='admin.main-page-category-block.edit'
                >
                  <td>
                    {checkPermission('admin.main-page-category-block.edit')
                        ? <Link to={`/app/main/popular-category/update/${e.id}`}>{e.id}</Link>
                        : <span>{e.id}</span>
                    }
                  </td>
                  <td>
                    <span>
                      {e.category?.title_ua || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {e.category?.title_ru || '---'}
                    </span>
                  </td>
                  <td>
                    <span>
                      {geDomainName(e?.domain_id)?.name}
                    </span>
                  </td>
                </TableListViewRow>
              ))
              : (
                <TableListViewRow disabled>
                  <td colSpan={5} />
                </TableListViewRow>
              )}
        </tbody>
      </TableListViewTable>

    </div>
  );
};

export default PopularCategoryList;
