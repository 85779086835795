import React from "react";
import s from './orderItem.module.scss'
import Header from "./Header";
import cn from "classnames";

const Item = ({ children, title, statusDisableAll, onClick, styleHeader, lookAllTitle }) => (
  <div className={s.order_item}>
    <Header onClick={onClick} style={styleHeader}>
        <div>{title}</div>
        {lookAllTitle && (<div style={{color: "#5755D1", fontSize: '12px'}}>{lookAllTitle}</div>)}
    </Header>
    <div className={cn(s.order_item_main, {
        [s.disable_block]: statusDisableAll
    })}>{children}</div>
  </div>
)

export default Item
