import { Link } from 'react-router-dom';
import qs from 'query-string';
import cn from 'classnames';
import React from 'react';
import getPagination from '../../../../lib/getPagination';

import s from './Pagination.module.scss';

// @todo: use back (fallback getPagination)
const TableListViewPagination = ({
  length,
  current,
  total,
  perPage,
  loc,
}) => {
  function getUrl(n) {
    return qs.stringifyUrl({
      url: loc.search,
      query: {
        page: n,
      },
    });
  }

  const prevUrl = current - 1 ? getUrl(current - 1) : loc.pathname;
  const nextUrl = current !== length ? getUrl(current + 1) : getUrl(length);
  const { pages } = getPagination({
    totalItems: total,
    currentPage: current,
    pageSize: perPage,
    maxPages: 5,
  });

  return (
    <div className={s.wrap}>
      <ul className="pagination">
        {length > perPage && (
          <>
            <li className={cn('page-item', { disabled: current === 1 })}>
              <Link to={loc.pathname} tabIndex="-1">Нач</Link>
            </li>
            <li className={cn('page-item', { disabled: current === 1 })}>
              <Link to={prevUrl} tabIndex="-1">Пред</Link>
            </li>
          </>
        )}

        {length && !!pages?.length && pages.map((numb) => {
          const n = +numb;
          const active = n === +current;

          return (
            <li
              className={cn('page-item', { active })}
              key={`pagination-${n}`}
            >
              <Link to={getUrl(n)}>{n}</Link>
            </li>
          );
        })}

        {length > perPage && (
          <>
            <li className={cn('page-item', { disabled: current === length })}>
              <Link to={nextUrl}>След</Link>
            </li>
            <li className={cn('page-item', { disabled: current === length })}>
              <Link to={getUrl(length)}>Посл</Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default TableListViewPagination;
