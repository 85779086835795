import { DELETE_MEDIA, EDIT_MEDIA, GET_MEDIA, SEND_MEDIA, SEND_POSITION_MEDIA, TYPES_MEDIA } from '../../const/api';
import fetchAx from '../fetchAx';
/* eslint-disable */
export const getMedia = (params) => fetchAx({
  method: 'GET',
  url: GET_MEDIA,
  params,
});

export const getMediaTypes = () => fetchAx({
  method: 'GET',
  url: TYPES_MEDIA,
});

export const saveMedia = (data) => fetchAx({
  method: 'POST',
  url: SEND_MEDIA,
  data,
});

export const savePositionMedia = (data) => fetchAx({
  method: 'POST',
  url: SEND_POSITION_MEDIA,
  data,
});

export const getMediaEdit = (id) => fetchAx({
  method: 'GET',
  url: EDIT_MEDIA.join(id),
});

export const editMedia = (id, data) => fetchAx({
  method: 'PUT',
  url: EDIT_MEDIA.join(id),
  data,
});

export const removeMedia = (id) => fetchAx({
  method: 'DELETE',
  url: DELETE_MEDIA.join(id),
});