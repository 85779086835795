import React, { useEffect, useState } from 'react';
import s from "./PromocodeTab.module.scss";
import s_Row from "../../../../components/common/TableListViewNew/Row/Row.module.scss";
import s_Block from "../../PromocodeSeriesEditView.module.scss";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import Button from "../../../../components/ui/Button";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import fetchAx from "../../../../lib/fetchAx";
import { DOMAIN_API } from "../../../../const/api";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";
import { toast } from "react-toastify";
import { getPromo } from "../../api";
import importIcon from "../../../../assets/icons/icon_import.svg";
import addIcon from "../../../../assets/icons/icon_add.svg";
import IconTrash from "../../../../components/icon/IconTrash";
import TableListViewTable from "../../../../components/common/TableListViewNew/Table";
import TableListViewRow from "../../../../components/common/TableListViewNew/Row";
import Input from "../../../../components/common/TableListViewNew/Input/Input";
import {createCurtCoupon, removeCurtRuleCoupon} from "../../../../lib/api/cart_rules";
import TableListViewPagination from "../../../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../../../components/common/TableListViewNew/SelectPerPage";
import Loader from "../../../../components/ui/Loader";
import checkPermission from "../../../../lib/checkPermission";
import icon_delete from "../../../../assets/icons/icon_delete.svg";

const PromocodeTab = ({ activeTab, data, formH, serID }) => {
    const couponTypeWatch = formH.watch('coupon_type');
    const [load, setLoad] = useState(false);

    const [promocodeResponse, setPromocodeResponse] = useState(null);
    const [promocodeList, setPromocodeList] = useState([]);
    const [promoCode, setPromoCode] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleAddSubmit = async () => {
        setLoad(true);
        await createCurtCoupon({
            code: promoCode,
            cart_rule_id: +data?.fields?.id
        }).then((res) => {
            setPromocodeList(prev => [res.data, ...prev]);
            setPromoCode('');
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await createCurtCoupon({
                        code: promoCode,
                        cart_rule_id: +data?.fields?.id
                    }).then((res) => {
                        setPromocodeList(prev => [res.data, ...prev]);
                        setPromoCode('');
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        }).finally(() => setLoad(false));
    };

    const handleRemoveSubmit = async (id) => {
        await removeCurtRuleCoupon(id).then((res) => {
            const findRemoveCoupon = promocodeList?.findIndex(item => item.id === id);

            if (findRemoveCoupon !== -1) {
                setPromocodeList(prev => {
                    const temp = [...prev];
                    if (findRemoveCoupon !== -1) {
                        temp.splice(findRemoveCoupon, 1);
                    }
                    return temp;
                })

                setPromocodeResponse(prev => {
                    const temp = {...prev};
                    temp.meta.total = temp.meta.total - 1;
                    return temp;
                })
            }
            toast(res?.data?.message || 'Промокод видаленно', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await removeCurtRuleCoupon(id).then((res) => {
                        const findRemoveCoupon = promocodeList?.findIndex(item => item.id === id);

                        if (findRemoveCoupon !== -1) {
                            setPromocodeList(prev => {
                                const temp = [...prev];
                                if (findRemoveCoupon !== -1) {
                                    temp.splice(findRemoveCoupon, 1);
                                }
                                return temp;
                            })

                            setPromocodeResponse(prev => {
                                const temp = {...prev};
                                temp.meta.total = temp.meta.total - 1;
                                return temp;
                            })
                        }
                        toast(res?.data?.message || 'Промокод видаленно', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
    };

    const handleRemoveAllSubmit = async () => {
        const checkRemove = confirm('Вы хотите удалить все?');
        if(checkRemove) {
            await fetchAx({
                url: `${DOMAIN_API}/api/admin/cart-rules/${data?.fields?.id}/delete-all-coupons`,
                method: 'DELETE'
            }).then((res) => {
                setPromocodeList([]);
                toast("Всі промокоди видалено", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await fetchAx({
                            url: `${DOMAIN_API}/api/admin/cart-rules/${data?.fields?.id}/delete-all-coupons`,
                            method: 'DELETE'
                        }).then((res) => {
                            setPromocodeList([]);
                            toast("Всі промокоди видалено", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        })
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
        }
    };

    const handleImportSubmit = async (files) => {

        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLoad(true);
        const result = await fetchAx({
            url: `${DOMAIN_API}/api/admin/cart-rules/${serID}/import`,
            method: 'POST',
            data: formData
        }).then((res) => toast("Промокода добавлены", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    const result = await fetchAx({
                        url: `${DOMAIN_API}/api/admin/cart-rules/${serID}/import`,
                        method: 'POST',
                        data: formData
                    }).then((res) => toast("Промокода добавлены", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }))
                    if(result) {
                        await getPromo(serID, {code: searchText})
                            .then((res) => setPromocodeList(res.data.data))
                            .catch(async (err) => await errorMessage(err , ''));
                    }
                })
            } else {
                await errorMessage(err, '')
            }
        }).finally(() => setLoad(false));

        if(result) {
            getPromo(serID, {code: searchText})
                .then((res) => setPromocodeList(res.data.data))
                .catch(async (err) => await errorMessage(err , ''));
        }
    };

    const handleOnChangeFilter = (e) => setSearchText(e.target.value);
    const clearFilter = () => setSearchText('');

    useEffect(() => {
        if(data) {
            setPromocodeList(data.coupons);
        }
    }, [data]);

    useEffect(() => {
        getPromo(data?.fields?.id, {code: searchText})
            .then((res) => {
                setPromocodeResponse(res.data)
                setPromocodeList(res.data.data)
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getPromo(data?.fields?.id, {code: searchText})
                            .then((res) => {
                                setPromocodeResponse(res.data)
                                setPromocodeList(res.data.data)
                            })
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
    }, [searchText]);

    return (
        <div style={{
            opacity: activeTab === 2 ? 1 : 0,
            visibility: activeTab === 2 ? 'visible' : 'hidden',
            height: activeTab === 2 ? 'auto' : 0,
        }}>
            {couponTypeWatch === '5' ? (
                <>
                    <div className={s.block_top}>
                        <div className={s.block_top__left}>
                            <div className={s.block_top__input}>
                                <InputWrapper label="Додати код" id="ps-promo-code-add">
                                    <input
                                        name="promo_code_text"
                                        className={s_Input.input}
                                        type="text"
                                        id="ps-promo-code-add"
                                        placeholder="Додати код"
                                        value={promoCode}
                                        onChange={e => setPromoCode(e.target.value)}
                                        maxLength={INPUT_MAX_LENGTH}
                                    />
                                </InputWrapper>
                            </div>
                            <div className={s.block_top__button}>
                                <Button purple onClick={handleAddSubmit}>
                                    <img src={addIcon} alt="add"/>
                                    Добавить
                                </Button>
                                <div className={s_Block.input__wrapper}>
                                    <input type="file" className={s_Block.input__file} id="inputGroupFile01"
                                           onChange={(e) => handleImportSubmit(e.target.files)}
                                    />
                                    <label htmlFor="inputGroupFile01" className={s_Block.input__file_button}>
                            <span className={s_Block.input__file_button_text}>
                                <img src={importIcon} alt="import"/>
                                Импорт
                            </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={s.block_top__right}>
                            <Button red onClick={handleRemoveAllSubmit}>
                                <IconTrash />
                                Видалити все
                            </Button>
                        </div>
                    </div>
                    {promocodeResponse && (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                            <TableListViewPagination
                                length={+promocodeResponse?.meta?.last_page}
                                current={+promocodeResponse?.meta?.current_page}
                                url={`api/admin/cart-rules/${data?.fields?.id}/coupons`}
                                setData={setPromocodeList}
                            />
                            <TableListViewPerPage
                                total={+promocodeResponse?.meta?.total}
                            />
                        </div>
                    )}
                    <div className={s.table_wrapper}>
                        <TableListViewTable>
                            <thead>
                                <tr>
                                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                                        <div>
                                            <span style={{ whiteSpace: 'nowrap' }}>Код промокоду</span>
                                            <Input
                                                value={searchText}
                                                onChange={handleOnChangeFilter}
                                                placeholder="Пошук"
                                                funcClearValue={clearFilter}
                                                style={{ maxWidth: "100%", width: '100%' }}
                                                styleWrapper={{ width: '100%' }}
                                            />
                                        </div>
                                    </th>
                                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                                        Дата створення
                                    </th>
                                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                                        Термін дії
                                    </th>
                                    <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                                        Кількість використань
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                            { !!promocodeList?.length
                                ? promocodeList?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`table-promo-code-id-${i}`}
                                        controlsOff
                                    >
                                        <td>
                                          <span>
                                            {e.code || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e.created_at || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e.expired_at || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e.times_used || 0}
                                          </span>
                                        </td>
                                        <td>
                                            {checkPermission('admin.cart-rule-coupons.destroy') && (
                                                <button type="button" className={s_Row.btn_danger} onClick={() => handleRemoveSubmit(e?.id)}>
                                                    <img src={icon_delete} alt="icon delete" />
                                                </button>
                                            )}
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={3} style={{ paddingTop: '22px' }}>Немає промокодів</td>
                                    </TableListViewRow>
                                )}
                            </tbody>
                        </TableListViewTable>
                    </div>
                    {load && <Loader />}
                </>
            ) : (
                <div>Увімкніть промокоди</div>
            )}

        </div>
    );
};

export default PromocodeTab;