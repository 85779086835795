import React from "react";
import icon_mark_remove from "../../../../assets/icons/icon_mark_remove.svg";
import s from "./Input.module.scss";

const Input = ({ type = "text", value, onChange, funcClearValue, style, placeholder, styleWrapper }) => {
    return (
        <div className={s.wrap} style={styleWrapper}>
            <input
                className={s.input}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={style}
            />
            <div className={s.wrap__img} onClick={funcClearValue}>
                <img src={icon_mark_remove} alt="Icon remove" />
            </div>
        </div>
    );
};

export default Input;