import { getCatTree } from '../../lib/api/categories';
import { getDomains } from '../../lib/api/domains';
import { getWh, removeWh } from '../../lib/api/warehouses';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
  getCatTree(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
    categories: res[1]?.value?.data,
  }));

export const getData = getWh;
export const remove = removeWh;
