export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.custom-image-block-two > div.col {
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
            
            }
            
            div.custom-image-block-two__block {
                border: 1px solid #e5e5e5;
            }
        
            div.desc_text_custom > span {
                font-size: 18px;
                line-height: 24px;
            }
            
            div.small_text_custom > span {
                font-size: 14px;
                line-height: 16px;
            }
            
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 769px) {
                div.custom-image-block-two__wrapper {
                    flex-direction: column-reverse;
                }
            }
            
            @media screen and (max-width: 576px) {
                 h3.h3-native {
                    font-size: 24px !important;
                }
                
                div.small_text_custom > span {
                    font-size: 11px;
                    line-height: 13px;
                }
                
                div.desc_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row row-cols-1 custom-image-block-two">
                <div class="col d-flex custom-image-block-two__wrapper">
                    <div class="d-flex custom-image-block-two__block">
                        <img src="https://via.placeholder.com/588x576" alt="" />
                    </div>
                    <div class="d-flex custom-image-block-two__block">
                        <img src="https://via.placeholder.com/376x576" alt="" />
                    </div>
                </div>
            </div>
            ${style}
        `,
    })
};