import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    create, edit, getData, parseDataGet, parseDataSend, remove,
} from "./api";
import useEdit from "../../lib/crud/useEdit";
import Loader from "../../components/ui/Loader";
import Form from "../../components/ui/FormEditView/Form";
import { DEVELOPMENT_ENVIRONMENT, SECTIONS_CREATE } from "../../const/api";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "./Section.module.scss";
import s_RSelect from "../../components/ui/RSelect/RSelect.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import cn from "classnames";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import { VALIDATION } from "../../const/text";
import InputWrapper from "../../components/ui/InputWrapper";
import { INPUT_MAX_LENGTH } from "../../const/view";
import ImageLoad from "../../components/ui/ImageLoad";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import Button from "../../components/ui/Button";
import Modal from "../../components/ui/Modal";
import { ModalMedia } from "../BannerManagementHeadEditView/Modal";
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import { getMedia, removeMedia } from "../../lib/api/media";
import errorMessage from "../../lib/errorMessage";
import RSelect from "../../components/ui/RSelect";
import CreatableSelect from "react-select/creatable";

const findPathById = (items, targetId, path = []) => {
    for (const item of items) {
        // Add the current item to the path
        const newPath = [...path, { id: item.id, title: item.title, url: `/app/section/update/${item.id}`, linkA: true }];

        // Check if the current item is the target
        if (item.id === targetId) {
            return newPath;
        }

        // Recursively search in children
        if (item.children.length > 0) {
            const foundPath = findPathById(item.children, targetId, newPath);
            if (foundPath) {
                return foundPath;
            }
        }
    }
    // If the item is not found, return null
    return null;
};

const SectionEditView = () => {
    const {id: sectID} = useParams();
    const formH = useForm();
    const {
        handleSubmit,
        reset,
        watch
    } = formH;

    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            title: "Головна",
            url: "/app"
        },
        {
            id: 1,
            title: "Панель адміністратора",
            url: '/app/section/update/1',
            linkA: true
        }
    ]);

    const {load, data, submitHandler} = useEdit({
        targetID: sectID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/section/not-found',
            remove: '/app/section/update/',
            save: '/app/section/update/',
            reload: true,
        },
        alerts: {
            create: 'Меню створено',
            edit: 'Меню оновлено',
            remove: 'Меню видалено',
        },
    });

    const [activeModalCreate, setActiveModalCreate] = useState(false);
    const [activeMedia, setActiveMedia] = useState(false);
    const [activeRemove, setActiveRemove] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [dataModal, setDataModal] = useState({});

    const [chooseRoles, setChooseRoles] = useState([]);
    const [chooseAliases, setChooseAliases] = useState([]);

    useEffect(() => {
        if(sectID) {
            getMedia({
                'entity_type': 'section',
                'entity_id': sectID,
                page: 1,
                per_page: 500,
            })
                .then(res => setImageData(res?.data?.data))
                .catch(err => errorMessage(err, '/api/admin/media GET'))
        }
    }, [sectID]);

    useEffect(() => {
        if(!!data?.fields?.roles?.length) {
            setChooseRoles(data?.fields?.roles?.map(item => ({ id: item.id, name: item.name })))
        }

        if(!!data?.fields?.aliases?.length) {
            setChooseAliases(data?.fields?.aliases?.map(item => ({ value: item, label: item })))
        }

        if (data?.fields) {
            setBreadcrumbs([
                {
                    title: "Головна",
                    url: "/app"
                },
                {
                    title: "Панель адміністратора",
                    url: '/app/section/update/1',
                    linkA: true
                }
            ])
        }
    }, [data?.fields]);

    useEffect(() => {
        if (data?.allSections && sectID) {
            const createBreadcrumbs = findPathById(data?.allSections.children, +sectID)

            if (createBreadcrumbs) {
                setBreadcrumbs([
                    {
                        title: "Головна",
                        url: "/app"
                    },
                    {
                        title: "Панель адміністратора",
                        url: '/app/section/update/1',
                        linkA: true
                    },
                    ...createBreadcrumbs
                ])
            }
        }
    }, [data?.allSections, sectID]);

    useEffect(() => {
        if (breadcrumbs) {
            localStorage.setItem('sectionPrevId', breadcrumbs[breadcrumbs?.length - 2]?.id || 1)
        }
    }, [breadcrumbs]);

    /* eslint-disable */
    return (
        <>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Section edit`}/>
            <Form attrs={{onSubmit: handleSubmit(submitHandler), style: {paddingTop: 0}}}>

                <button type="submit" className="d-hide"/>

                <TopPanelChild
                    title={sectID ? data && data?.fields?.title : "Створити нове меню"}
                    breadcrumbs={{
                        items: breadcrumbs
                    }}
                    back={`/app/section/update/${breadcrumbs[breadcrumbs?.length - 2]?.id || 1}`}
                    backLinkA={true}
                    id={sectID}
                    children={<CheckboxSwitch
                        name="is_active"
                        id="cat-is-active"
                        label="Активність"
                        formH={formH}
                        defaultChecked={data?.fields?.is_active}
                    />}
                    btnEdit
                />

                {!load ? (
                    <div>
                        <div className={s.top}>
                            <div className={s.img_wrapper}>
                                <ImageLoad
                                    id={`product_img_ru`}
                                    nameParent={`product_images[image_list]`}
                                    formH={formH}
                                    accept="image/*"
                                    acceptStr="PNG, JPG, GIF"
                                    imageData={imageData}
                                    setImageData={setImageData}
                                    setActiveMedia={setActiveMedia}
                                    setActiveRemove={setActiveRemove}
                                    setDataModal={setDataModal}
                                    prodID={sectID}
                                    entityType='section'
                                    collectionName='cover'
                                    width="52px"
                                    height="52px"
                                    windowName=""
                                    langLabel=""
                                    textLoadImg=""
                                    altData={{
                                        alt_ua: '',
                                        locale: 'ua'
                                    }}
                                    styleMedia={{'& > div > div': {justifyContent: 'center'}}}
                                    showEditBtn={false}
                                />
                            </div>
                            <InputWrapper
                                label="Назва меню"
                                id="section-title"
                                errorComponent={formH.errors?.title &&
                                <p className="form-input-hint">{formH?.errors?.title?.message}</p>}
                                style={{ margin: 0, maxWidth: '400px', width: '100%' }}
                            >
                                <input
                                    className={cn(s_Input.input, {
                                        [s_Input.input_error]: formH.errors?.title,
                                    })}
                                    type="text"
                                    placeholder="Назва меню"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={formH.register({
                                        required: VALIDATION.req.required
                                    })}
                                    name="title"
                                    id="section-title"
                                    defaultValue={data?.fields?.title}
                                />
                            </InputWrapper>

                            <InputWrapper
                                label="Посилання"
                                id="section-url"
                                errorComponent={formH.errors?.url &&
                                <p className="form-input-hint">{formH?.errors?.url?.message}</p>}
                                style={{ margin: 0, maxWidth: '400px', width: '100%' }}
                            >
                                <input
                                    className={cn(s_Input.input, {
                                        [s_Input.input_error]: formH.errors?.url,
                                    })}
                                    type="text"
                                    placeholder="Посилання"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={formH.register({
                                        required: VALIDATION.req.required
                                    })}
                                    name="url"
                                    id="section-url"
                                    defaultValue={data?.fields?.url}
                                />
                            </InputWrapper>

                            <div style={{ maxWidth: '400px' }}>
                                <RSelect
                                    props={{
                                        value: chooseRoles,
                                        onChange: (opt) => {setChooseRoles(opt)},
                                        placeholder: 'Ролі:',
                                        options: data?.roles,
                                        isMulti: true,
                                        getOptionLabel: (opt) => opt.name,
                                        getOptionValue: (opt) => opt.id
                                    }}
                                />

                                {!!chooseRoles?.length && chooseRoles?.map((item, index) =>
                                    <input
                                        key={`role-id-item-hidden-${index}`}
                                        type="hidden"
                                        ref={formH.register}
                                        name={`role_ids[${index}]`}
                                        value={item.id}
                                    />
                                )}
                            </div>
                            <div style={{ maxWidth: '400px' }}>
                                <CreatableSelect
                                    className={s_RSelect.select}
                                    value={chooseAliases}
                                    onChange={(opt) => {setChooseAliases(opt)}}
                                    placeholder='Псевдоніми:'
                                    options={chooseAliases}
                                    isMulti
                                    isClearable
                                />

                                {!!chooseAliases?.length && chooseAliases?.map((item, index) =>
                                    <input
                                        key={`aliases-item-hidden-${index}`}
                                        type="hidden"
                                        ref={formH.register}
                                        name={`aliases[${index}]`}
                                        value={item.value}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={s.table_wrapper}>
                            <TableListViewTable>
                                <TableHead
                                    arrayChildren={[
                                        'ID', 'Назва меню', 'Іконка', 'Посилання', 'Дочірні', 'Статус активності'
                                    ]}
                                />
                                <tbody>
                                    {data?.fields?.children?.length
                                        ? data?.fields?.children?.map((e, i) => (
                                            <TableListViewRow
                                                id={e?.id}
                                                key={`selection-item-${e?.id}`}
                                                editLink={`/app/section/update/${e?.id}`}
                                                permissionEdit='admin.admin.sections.edit'
                                                deleteButton={false}
                                            >
                                                <td>
                                                    <a href={`/app/section/update/${e?.id}`}>{e.id}</a>
                                                </td>
                                                <td>{e.title}</td>
                                                <td>{e?.cover?.original ? <img src={e?.cover?.original} alt="Icon menu" /> : '---'}</td>
                                                <td>{e.url}</td>
                                                <td>
                                                    {!!e?.children?.length ? (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                                            /&nbsp;{e?.children.map(child => (
                                                            <div key={`selection-item-child-item-${child.id}`}>
                                                                {child.title} /&nbsp;
                                                            </div>
                                                        ))}
                                                        </div>
                                                    ) : '---'}
                                                </td>
                                                <td>
                                                    {e.is_active
                                                        ? <StatusCircle green text="Активна" />
                                                        : <StatusCircle red text="Не активна" />}
                                                </td>
                                            </TableListViewRow>
                                        ))
                                        : (
                                            <TableListViewRow disabled>
                                                <td colSpan={6}>Немає</td>
                                            </TableListViewRow>
                                        )}
                                </tbody>
                            </TableListViewTable>

                            <Button onClick={() => setActiveModalCreate(true)} border>Створити дочірню</Button>
                        </div>
                    </div>
                ) : <Loader/>}
            </Form>

            {activeModalCreate && (
                <Modal
                    active={activeModalCreate}
                    setActive={setActiveModalCreate}
                    title='Створити'
                    api={SECTIONS_CREATE}
                    href='/app/section/update'
                    permissionName='admin.sections.store'
                    fields={[
                        { type: 'text', name: 'title', label: 'Назва меню', placeholder: 'Назва меню' },
                        { type: 'text', name: 'url', label: 'Посилання', placeholder: 'Посилання' }
                    ]}
                    defaultSendValue={{
                        parent_id: +sectID
                    }}
                    redirect={false}
                />
            )}

            {activeMedia && <ModalMedia
                active={activeMedia}
                setActive={setActiveMedia}
                data={dataModal}
                collectionName='cover'
                locale='ua'
                setImageData={setImageData}
                title='Редагування картинки'
            />}
            {activeRemove && <ModalRemove
                active={activeRemove}
                setActive={setActiveRemove}
                data={dataModal}
                funcRemove={removeMedia}
                setRemoveItem={setImageData}
                title='Видалити банер меню'
            />}
        </>
    )
};

export default SectionEditView;
