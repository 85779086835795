import { parseAll } from "../../lib/api/_parseApi";
import {
    createSubscriber,
    editSubscriber,
    getSubscriberEdit,
    removeSubscriber,
} from "../../lib/api/subscribers";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (subsID) => Promise.allSettled([subsID
&& getSubscriberEdit(subsID)])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      return ({
          fields: subsID && res[0]?.value?.data,
      })
  });

export const create = createSubscriber;
export const edit = editSubscriber;
export const remove = removeSubscriber;

export const parseDataSend = (data, statusId) => {
  let d = { ...data };
  const isUpdate = typeof statusId !== 'undefined';

  d = parseAll(d, !isUpdate, ['role', 'status', 'status_to']);

  return d;
};

export const parseDataGet = (data, id) => {
  const d = { ...data };

  const isUpdate = typeof id !== 'undefined';

  return d;
}
