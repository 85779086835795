export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.col {
                min-height: 50px;
            }
            
            div.four-block-hover {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            div.four-block-item {
                background-color: rgb(229, 229, 229);
                border: 1px solid rgba(0, 0, 0, 0.26);
                border-radius: 20px;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 156px;
                max-width: 300px;
                transition: all 0.3s;
            }
            
            div.four-block-item:hover {
                background-color: rgb(206, 206, 206);
            }
            
            div.four-block-img {
                max-width: 56px;
                width: 56px;
                margin-right: 20px;
            }
            div.four-block-img > img {
                max-width: 56px;
                width: 56px;
            }
            div.four-block-text {
                width: calc(100% - 76px);
            }
            h4.four-block-title {
                text-align: left !important;
                display: block;
                word-wrap: break-word;
            }
            div.four-block-desc {
                text-align: left !important;
            }
            h4.four-block-title > span {
                display: block;
                text-align: left !important;
                word-wrap: break-word;
            }
            div.four-block-desc > span {
                text-align: left !important;
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row row-cols-1 row-cols-sm-2 row-cols-xxl-4 four-block-hover">
                <div class="col m-2 four-block-item">
                    <div class="four-block-img"><img src="https://via.placeholder.com/56" alt="hover img"/></div>
                    <div class="four-block-text">
                        <h4 class="h4-native mb-1 four-block-title">
                            <span class="text-left">Title</span>
                        </h4>
                        <div class="desc_text_custom mb-1 four-block-desc">
                            <span class="text-left">desc text</span>
                        </div>
                    </div>
                </div>
                <div class="col m-2 four-block-item">
                    <div class="four-block-img"><img src="https://via.placeholder.com/56" alt="hover img"/></div>
                    <div class="four-block-text">
                        <h4 class="h4-native mb-1 four-block-title">
                            <span class="text-left">Title</span>
                        </h4>
                        <div class="desc_text_custom mb-1 four-block-desc">
                            <span class="text-left">desc text</span>
                        </div>
                    </div>
                </div>
                <div class="col m-2 four-block-item">
                    <div class="four-block-img"><img src="https://via.placeholder.com/56" alt="hover img"/></div>
                    <div class="four-block-text">
                        <h4 class="h4-native mb-1 four-block-title">
                            <span class="text-left">Title</span>
                        </h4>
                        <div class="desc_text_custom mb-1 four-block-desc">
                            <span class="text-left">desc text</span>
                        </div>
                    </div>
                </div>
                <div class="col m-2 four-block-item">
                    <div class="four-block-img"><img src="https://via.placeholder.com/56" alt="hover img"/></div>
                    <div class="four-block-text">
                        <h4 class="h4-native mb-1 four-block-title">
                            <span class="text-left">Title</span>
                        </h4>
                        <div class="desc_text_custom mb-1 four-block-desc">
                            <span class="text-left">desc text</span>
                        </div>
                    </div>
                </div>
            </div>
            ${style}
        `,
    })
};