import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import cn from "classnames";
import { INPUT_MAX_LENGTH } from "../../const/view";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VALIDATION } from "../../const/text";
import useEdit from "../../lib/crud/useEdit";
import {
  create, edit, getData, remove, parseDataSend,
} from './api';
import Loader from "../../components/ui/Loader";
import { useParams } from "react-router-dom";
import CategoryWithProduct from "./components/CategoryWithProduct/CategoryWithProduct";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import InputRadio from "../../components/ui/InputRadio";
import s from "./FeedsEditView.module.scss";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_OrderStyle from "../OrderEditView/fakeData/orderStyle.module.scss";
import s_Select from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_FormSelect from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import { getProduct } from "../../lib/api/products";
import SelectInput from "../../components/ui/SelectInput";
import TableItems from "../../components/ui/TableItems/TableItems";
import CopyComponent from "../../components/ui/CopyComponent";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";

const FeedsEditView = () => {
    const { id: fId } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: fId,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData
        },
        parse: {
            send: parseDataSend
        },
        redirects: {
            notFound: '/app/feed/not-found',
            remove: `/app/feed${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/feed${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`
        },
        alerts: {
            create: 'Фид создан',
            edit: 'Фид обновлен',
            remove: 'Фид удален'
        }
    });

    const [disabled, setDisabled] = useState(true);

    const [productsAList, setProductsAList] = useState([]);
    const [productsAJDEFilter, setProductsAJDEFilter] = useState([]);

    const [productsBList, setProductsBList] = useState([]);
    const [productsBJDEFilter, setProductsBJDEFilter] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const parseDataA = useCallback(() => {
        const jde = [...productsAJDEFilter]

        const totArr = [...jde];
        setProductsAList(totArr);
    }, [productsAJDEFilter]);

    const parseDataB = useCallback(() => {
        const jde = [...productsBJDEFilter]

        const totArr = [...jde];
        setProductsBList(totArr);
    }, [productsBJDEFilter]);

    const removeAItem = (e, el) => {
        e.preventDefault();
        const temp = [...productsAList];
        const index = temp.indexOf(el);
        if (index !== -1) {
            temp.splice(index, 1);
            setProductsAList(temp);
        }
    };

    const removeBItem = (e, el) => {
        e.preventDefault();
        const temp = [...productsBList];
        const index = temp.indexOf(el);
        if (index !== -1) {
            temp.splice(index, 1);
            setProductsBList(temp);
        }
    };

    useEffect(() => {
        setProductsAList([]);
        parseDataA();
    }, [parseDataA]);

    useEffect(() => {
        setProductsBList([]);
        parseDataB();
    }, [parseDataB]);

    useEffect(() => {
        if (data?.fields?.included_products) {
            setProductsAList([...data?.fields?.included_products]);
        }
    }, [data?.fields?.included_products]);

    useEffect(() => {
        if (data?.fields?.excluded_products) {
            setProductsBList([...data?.fields?.excluded_products]);
        }
    }, [data?.fields?.excluded_products]);

    useEffect(() => {
        if (!!data?.fields?.warehouses?.length) {
            const parseWarehouses = data?.fields?.warehouses?.map(item => `${item.id}`);
            setWarehouses([...parseWarehouses]);
        }
    }, [data?.fields?.warehouses]);

    return (
        <Form attrs={{
            onSubmit: handleSubmit(submitHandler),
        }}
        >
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Feed edit`} />
            <button type="submit" className="d-hide" />
            <div className={s.inner}>
                <div className={s.block}>

                    {/* is_active */}
                    <Group attrs={{className: s.mb20}}>
                        <ColFull>
                            <CheckboxSwitch
                                name="is_active"
                                id="im-is-active"
                                label="Активность"
                                formH={formH}
                                defaultChecked={data?.fields?.is_active}
                            />
                        </ColFull>
                    </Group>

                    {/* title */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Название фида" id="f-title">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.title,
                                    })}
                                    type="text"
                                    placeholder="Название фида"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="title"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-title"
                                />
                            </InputWrapper>
                            {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
                        </ColFull>
                    </Group>

                    {/* filename */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Имя файла фида*" id="f-filename">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.filename,
                                    })}
                                    type="text"
                                    placeholder="Название рассрочки"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="filename"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-filename"
                                />
                            </InputWrapper>
                            {errors.filename && <p className="form-input-hint">{errors.filename.message}</p>}
                        </ColFull>
                    </Group>

                    {/* utm_enabled */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.checkbox__wrapper}>
                                <input
                                    className={s_Select.checkbox}
                                    type="checkbox"
                                    id="f-utm-enabled"
                                    name="utm_enabled"
                                    ref={register}
                                    defaultChecked={data?.fields?.utm_enabled}
                                />
                                <label htmlFor="f-utm-enabled" className={s_Select.label}>Добавить UTM теги</label>
                            </div>
                        </ColFull>
                    </Group>

                    {/* type */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_OrderStyle.select__inner}>
                                <div className={s_OrderStyle.select__wrapper}>
                                    <select
                                        name="type"
                                        className={s_OrderStyle.select}
                                        id="f-type"
                                        ref={register({
                                            required: VALIDATION.req.required,
                                        })}
                                    >
                                        <option value="">выберите тип</option>
                                        {!!data?.feedTypes?.length && data?.feedTypes?.map(type => (
                                            <option value={type.type} selected={type.type === data?.fields?.type}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_OrderStyle.label} htmlFor="f-type">Тип</label>
                                </div>
                                {errors?.type
                                && <p className="form-input-hint">{errors.type?.message}</p>}
                            </div>
                            <div>
                                В поле "name" файла будет подставлятся выбранное значение вместо названия
                            </div>
                        </ColFull>
                    </Group>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_OrderStyle.select__inner}>
                                <div className={s_OrderStyle.select__wrapper}>
                                    <select
                                        name="domain_id"
                                        className={s_OrderStyle.select}
                                        id="f-domain-id"
                                        ref={register({
                                            required: VALIDATION.req.required,
                                        })}
                                    >
                                        <option value="">выберите домен</option>
                                        {!!data?.domains?.length && data?.domains?.map(domain => (
                                            <option value={domain.id} selected={domain.id === data?.fields?.domain_id}>
                                                {domain.title}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_OrderStyle.label} htmlFor="f-domain-id">Домен*</label>
                                </div>
                                {errors?.domain_id
                                && <p className="form-input-hint">{errors.domain_id?.message}</p>}
                            </div>
                        </ColFull>
                    </Group>

                    {/* free_delivery_from_price */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Бесплатная доставка от, грн." id="f-free-delivery-from-price">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.free_delivery_from_price,
                                    })}
                                    type="text"
                                    placeholder="Бесплатная доставка от, грн."
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="free_delivery_from_price"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-free-delivery-from-price"
                                />
                            </InputWrapper>
                            {errors.free_delivery_from_price && <p className="form-input-hint">{errors.free_delivery_from_price.message}</p>}
                        </ColFull>
                    </Group>

                    {/* link page */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                            <ColFull>
                                <CopyComponent copyText={data?.fields?.feed_url}>
                                    <div>{data?.fields?.feed_url}</div>
                                </CopyComponent>
                            </ColFull>
                        </Group>
                </div>

                {!!data && (
                    <CategoryWithProduct data={data} formH={formH} />
                )}

                <div className={s.block}>
                    <div className={s.title}>Наличие</div>
                    <div>
                        <div className={`${s.warehouse__block} ${s.warehouse__radio}`}>
                            <InputRadio name="warehouses_all" id="warehouses-all" label="Все" formH={formH} onChange={() => setDisabled(true)} defaultChecked />
                            <InputRadio name="warehouses_all" id="warehouses-in" label="На складе" formH={formH} onChange={() => setDisabled(false)} />
                        </div>
                        {!!data?.wareHouses?.length && (
                            <div className={s.warehouse__block}>
                                <ul>
                                    {data.wareHouses.map((house, index) => {
                                        const checked = warehouses?.includes(house.id.toString());
                                        return (
                                            <li
                                                key={index}
                                                className={cn('', {
                                                    [s.disabled]: disabled
                                                })}
                                            >
                                                <div className={s_FormSelect.checkbox__wrapper}>
                                                    <input
                                                        className={s_FormSelect.checkbox}
                                                        type="checkbox"
                                                        id={`warehouse-${index}`}
                                                        name={`warehouses[${index}]`}
                                                        value={house.id}
                                                        checked={checked}
                                                        onChange={(e) => {
                                                            setWarehouses(prev => {
                                                                const temp = [...prev];
                                                                if(!!temp?.length) {
                                                                    const included = temp?.indexOf(`${house.id}`);
                                                                    if(included !== -1) {
                                                                        temp.splice(included, 1);
                                                                        return temp;
                                                                    }
                                                                    return [
                                                                        ...temp,
                                                                        `${house.id}`,
                                                                    ]
                                                                }
                                                                return [`${house.id}`];
                                                            })
                                                        }}
                                                        disabled={disabled}
                                                        ref={register}
                                                    />
                                                    <label htmlFor={`warehouse-${index}`} className={s_FormSelect.label}>{house.title_ru}</label>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )}
                        {disabled && !!data?.wareHouses?.length && (
                            <>
                                <input type="hidden" name={`warehouses_check_all`} value={disabled} ref={register}/>
                                {data.wareHouses.map((house, index) => (
                                    <input
                                        type="hidden"
                                        name={`warehouses-all[${index}]`}
                                        value={house.id}
                                        ref={register}
                                    />
                                ))}
                            </>
                        )}
                    </div>

                    {/* Включать товар со склада сторонних поставщиков */}
                    {/*<Group attrs={{className: 'mt-0 mb-0'}}>*/}
                    {/*    <ColFull>*/}
                    {/*        <div className={s_Select.checkbox__wrapper}>*/}
                    {/*            <input*/}
                    {/*                className={s_Select.checkbox}*/}
                    {/*                type="checkbox"*/}
                    {/*                id="f-teg-umt1"*/}
                    {/*                name="teg_umt1"*/}
                    {/*                ref={register}*/}
                    {/*                defaultChecked={data?.fields?.teg_umt1}*/}
                    {/*            />*/}
                    {/*            <label htmlFor="f-teg-umt1" className={s_Select.label}>Включать товар со склада сторонних поставщиков</label>*/}
                    {/*        </div>*/}
                    {/*    </ColFull>*/}
                    {/*</Group>*/}

                    {/* quantity_from */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Количество на складе сторонних поставщиков от" id="f-quantity-from">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.quantity_from,
                                    })}
                                    type="text"
                                    placeholder="Количество на складе сторонних поставщиков от"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="quantity_from"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-quantity-from"
                                />
                            </InputWrapper>
                            {errors.quantity_from && <p className="form-input-hint">{errors.quantity_from.message}</p>}
                        </ColFull>
                    </Group>

                    {/* price_from */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Стоимость от:" id="f-price-from">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.price_from,
                                    })}
                                    type="text"
                                    placeholder="Стоимость от:"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="price_from"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-price-from"
                                />
                            </InputWrapper>
                            {errors.price_from && <p className="form-input-hint">{errors.price_from.message}</p>}
                        </ColFull>
                    </Group>

                    {/* price_to */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Стоимость до:" id="f-price-to">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.price_to,
                                    })}
                                    type="text"
                                    placeholder="Стоимость до:"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="price_to"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="f-price-to"
                                />
                            </InputWrapper>
                            {errors.price_to && <p className="form-input-hint">{errors.price_to.message}</p>}
                        </ColFull>
                    </Group>

                    {/* included_products */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="JDE, которые попадают в XML:" id="f-included-products" customStyle={{ top: '25px', transform: 'translateY(-100%) scale(0.8)'}}>
                                <SelectInput
                                    searchBy="value"
                                    className={s_Input.input}
                                    title="jde[]"
                                    name="included_products[]"
                                    api={{ get: getProduct }}
                                    methods={{
                                        set: setProductsAJDEFilter,
                                    }}
                                    visibleSelected={false}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>
                    {!!productsAList?.length && (
                        <TableItems
                            formH={formH}
                            list={productsAList}
                            title="Выбраные товары"
                            name="included_products"
                            thNames={['ID', '']}
                            func={{
                                remove: removeAItem,
                            }}
                            titleVisible={false}
                        />
                    )}

                    {/* excluded_products */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="JDE, которые попадают в XML:" id="f-included-products" customStyle={{ top: '25px', transform: 'translateY(-100%) scale(0.8)'}}>
                                <SelectInput
                                    className={s_Input.input}
                                    searchBy="value"
                                    title="jde[]"
                                    name="excluded_products[]"
                                    api={{ get: getProduct }}
                                    methods={{
                                        set: setProductsBJDEFilter,
                                    }}
                                    visibleSelected={false}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>
                    {!!productsBList?.length && (
                        <TableItems
                            formH={formH}
                            list={productsBList}
                            title="Исключенные товары"
                            name="excluded_products"
                            thNames={['ID', '']}
                            func={{
                                remove: removeBItem,
                            }}
                            titleVisible={false}
                        />
                    )}
                </div>
            </div>
        {load && <Loader />}
    </Form>
    )
}

export default FeedsEditView
