import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import Button from "../../../components/ui/Button";
import s from "../../OrderEditView/TopPanel/topPanel.module.scss";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import icon_accept from "../../../assets/icons/icon_accept.svg";
import icon_save from "../../../assets/icons/icon_save.svg";
import { toast } from "react-toastify";
import Loader from "../../../components/ui/Loader";
import errorMessage from "../../../lib/errorMessage";
import { editInCart, editInCartAbandon } from "../../../lib/api/in_carts";

const TopPanel = ({ formProps, breadcrumbs, editBtn }) => {
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    const formatDate = (value) => {
        const date = new Date(value).toLocaleDateString();
        const dateTime = new Date(value).toLocaleTimeString().slice(0,-3);
        return `${date} ${dateTime}`;
    };

    function createOrder() {
        setLoad(true);
        const copyData = {...formProps?.data?.fields};

        if(!!!copyData?.name) {
            copyData['name'] = copyData?.user?.name || copyData?.user_info?.name;
        }

        if(!!!copyData?.surname) {
            copyData['surname'] = copyData?.user?.surname || copyData?.user_info?.surname;
        }

        if(!!!copyData?.phone) {
            copyData['phone'] = copyData?.user?.phone || copyData?.user_info?.phone;
        }

        if(!!!copyData?.email) {
            copyData['email'] = copyData?.user?.email || copyData?.user_info?.email;
        }

        delete copyData['source'];

        editInCart(formProps?.data?.fields?.id, copyData).then((res) => {
            navigate(`/app/order/update/${res?.data?.order?.id}`);
            toast('Заказ создан', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .catch(async (err) => await errorMessage(err, '/api/admin/incart PUT'))
            .finally(() => setLoad(false));
    }

    function annulHandler() {
        setLoad(true);
        editInCartAbandon(formProps?.data?.fields?.id).then((res) => {
            navigate("/app/in-carts");
            toast('Заказ аннулирован', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
            .catch(async (err) => await errorMessage(err, `/api/admin/incart/${formProps?.data?.fields?.id}/abandon POST`))
            .finally(() => setLoad(false));
    }

    const disableCancelOrder = formProps?.data?.fields?.status === 32;

    return (
        <>
            <div>
                <div className={s.breadcrumbs__wrapper}>
                    <div>
                        <Breadcrumbs {...breadcrumbs}/>
                        <div className={s.title}>
                            <div>{`Samsung: Заказ #${formProps?.data?.fields?.id} от ${formatDate(formProps?.data?.fields?.created_at)}`}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.footer}>
                <div>
                    <Link to="/app/in-carts">
                        <img src={icon_arrow_back} alt="Arrow back icon"/>
                        Назад
                    </Link>
                </div>
                {editBtn && (
                    <div className={s.buttons__wrapper}>
                        <div className={s.buttons__group}>
                            <div className={s.button__block}>
                                <Button red onClick={annulHandler} disabled={disableCancelOrder}>
                                    <img src={icon_accept} alt="Accept icon"/>
                                    Аннулирован
                                </Button>
                            </div>
                            <div className={s.button__block}>
                                <Button green onClick={createOrder} disabled={disableCancelOrder}>
                                    <img src={icon_save} alt="Save icon"/>
                                    В заказ
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {load && <Loader />}
        </>
    );
};

export default TopPanel;