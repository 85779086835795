import {
    CANCEL_VARIANTS_CREATE, CANCEL_VARIANTS_DELETE, CANCEL_VARIANTS_EDIT,
    CANCEL_VARIANTS_GET, CANCEL_VARIANTS_GET_EDIT,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getCancelVariants = (params) => fetchAx({
    url: CANCEL_VARIANTS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const createCancelVariant = (data) => fetchAx({
    url: CANCEL_VARIANTS_CREATE,
    method: 'POST',
    data,
});

export const getCancelVariantEdit = (id) => fetchAx({
    url: CANCEL_VARIANTS_GET_EDIT.join(id),
    method: 'GET',
});

export const editCancelVariant = (id, data) => fetchAx({
    url: CANCEL_VARIANTS_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeCancelVariant = (id) => fetchAx({
    url: CANCEL_VARIANTS_DELETE.join(id),
    method: 'DELETE',
});