import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from "../../../components/ui/FormEditView/Form";
import Loader from "../../../components/ui/Loader";
import TableListViewRow from "../../../components/common/TableListView/Row";
import TableListViewTable from "../../../components/common/TableListView/Table";
import errorMessage from "../../../lib/errorMessage";
import checkPermission from "../../../lib/checkPermission";
import getTZtoDate from "../../../lib/getTZtoDate";
import { getFiltersData } from "../../ReviewsList/api";
import { getReviews } from "../../../lib/api/reviews";

const TabReviews = ({index = 3, formProps}) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const [reviewsList, setReviewsList] = useState(null);
    const [filtersData, setFiltersData] = useState(null);
    const [load, setLoad] = useState(false);
    const [filterParams, setFilterParams] = useState(null);

    const handleChangeParams = (e) => setFilterParams(prev => {
        let temp;
        if (prev) {
            temp = {
                ...prev,
                [e.target.name]: e.target.value
            };
        } else {
            temp = {
                [e.target.name]: e.target.value
            }
        }

        Object.keys(temp).forEach(key => {
            if (!temp[key]?.length) {
                delete temp[key];
            }
        });

        return temp;
    });

    const changeHandler = async (e) => {
        const tempParams = {...filterParams};
        handleChangeParams(e);

        if (!e?.target?.value?.length) {
            delete tempParams[e.target.name];
        } else {
            tempParams[e.target.name] = e.target.value;
        }

        await getReviews({...tempParams})
            .then(res => setReviewsList(res.data))
            .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
            .finally(() => setLoad(false));
    }

    const onEnterPress = async (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            await getReviews({...filterParams})
                .then(res => setReviewsList(res.data))
                .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
                .finally(() => setLoad(false));
        }
    }

    useEffect(() => {
        if (formProps?.data?.fields?.id) {
            setLoad(true);

            getReviews({user_id: formProps?.data?.fields?.id})
                .then(res => setReviewsList(res.data))
                .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
                .finally(() => setLoad(false));

            getFiltersData().then(res => setFiltersData(res))
                .catch(async (err) => await errorMessage(err, `Filters Data`))
                .finally(() => setLoad(false));

            setFilterParams({
                user_id: formProps?.data?.fields?.id?.toString()
            })
        }
    }, [formProps?.data]);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide"/>

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">
                    {!load ? (
                        <>
                            <TableListViewTable>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th style={{ minWidth: '400px' }}>Отзыв</th>
                                    <th>Рейтинг</th>
                                    <th>Пользователь</th>
                                    <th>Менеджер</th>
                                    <th>Товар</th>
                                    <th>Статус активности</th>
                                    <th>Дата создания</th>
                                    <th>Дата обновления</th>
                                </tr>
                                <tr>
                                    <th />
                                    <th />
                                    <th>
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="text"
                                                name="rating_from"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.rating_from || ''}
                                                style={{ width: '100%' }}
                                            />
                                            -
                                            <input
                                                type="text"
                                                name="rating_to"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.rating_to || ''}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </th>
                                    <th />
                                    <th />
                                    <th>
                                        <input
                                            type="text"
                                            name="jde"
                                            onChange={handleChangeParams}
                                            onKeyDown={onEnterPress}
                                            value={filterParams?.jde || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                    <th>
                                        <select
                                            name="status"
                                            onChange={changeHandler}
                                            value={filterParams?.status || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите статус</option>
                                            {!!filtersData?.statuses?.length && filtersData?.statuses?.map((item, index) => {
                                                return (
                                                    <option key={`user-statuses-item-${index}`} value={item.value}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <input
                                            type="date"
                                            name="created_from"
                                            onKeyDown={onEnterPress}
                                            onChange={handleChangeParams}
                                            value={filterParams?.created_from || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                    <th>
                                        <input
                                            type="date"
                                            name="created_to"
                                            onKeyDown={onEnterPress}
                                            onChange={handleChangeParams}
                                            value={filterParams?.created_to || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {!!reviewsList?.data?.length
                                    ? reviewsList?.data?.map((e, i) => (
                                        <TableListViewRow
                                            id={i}
                                            key={`user-review-item-${i}`}
                                            controlsOff
                                        >
                                            <td>
                                                {checkPermission('admin.social.reviews.edit')
                                                    ? <Link to={`/app/review/update/${e.id}`} target="_blank">{e.id}</Link>
                                                    : <span>{e.id}</span>
                                                }
                                            </td>
                                            <td style={{ minWidth: '400px' }}>{e?.body}</td>
                                            <td>{e?.rating}</td>
                                            <td>{e?.user ? e?.user?.name : '---'}</td>
                                            <td>{e?.manager ? e?.manager?.name : '---'}</td>
                                            <td>{e?.product ? e?.product?.title : '---'}</td>
                                            <td>{filtersData ? filtersData?.statuses?.find(status => status?.value === e?.status)?.title : e?.status}</td>
                                            <td>{e?.created_at ? getTZtoDate(e.created_at, true) : '---'}</td>
                                            <td>{e?.updated_at ? getTZtoDate(e.updated_at, true) : '---'}</td>
                                        </TableListViewRow>
                                    ))
                                    : (
                                        <TableListViewRow disabled>
                                            <td colSpan={8} style={{textAlign: 'center', paddingTop: '10px'}}>
                                                Нет данных
                                            </td>
                                        </TableListViewRow>
                                    )}
                                </tbody>
                            </TableListViewTable>
                        </>
                    ) : <Loader/>}
                </Form>

                {formProps.load && <Loader/>}
            </div>
        </form>
    );
};

export default TabReviews;
