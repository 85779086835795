import React, { useCallback, useEffect, useState } from 'react';
import ColLeft from '../../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../../components/ui/FormEditView/ColRight';
import Group from '../../../../components/ui/FormEditView/Group';
import SelectInput from '../../../../components/ui/SelectInput';
import { getProduct } from '../../../../lib/api/products';
import TableItems from '../../../../components/ui/TableItems';
import removeDuplicates from "../../../../lib/helpers/removeDuplicates";

const TypeEight = ({ list, state, formH }) => {
  const { typeID } = state;

  const [productsAList, setProductsAList] = useState([]);
  const [productsAMarkerFilter, setProductsAMarkerFilter] = useState([]);
  const [productsACategoriesFilter, setProductsACategoriesFilter] = useState([]);
  const [productsAJDEFilter, setProductsAJDEFilter] = useState([]);

  useEffect(() => {
    if (state?.products) {
      setProductsAList([...state?.products]);
      setProductsAJDEFilter([...state?.products]);
    }
  }, [state?.products]);

  const parseDataA = useCallback(() => {
    const mrk = [...productsAMarkerFilter];
    const ctg = [...productsACategoriesFilter];
    const jde = [...productsAJDEFilter];

    const parseJde = removeDuplicates(jde);

    const totArr = [...mrk, ...ctg, ...parseJde];
    setProductsAList(totArr);
  }, [productsAMarkerFilter, productsACategoriesFilter, productsAJDEFilter]);

  const removeAItem = (e, el) => {
    e.preventDefault();
    const temp = [...productsAList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsAList(temp);
    }
  };

  useEffect(() => {
    setProductsAList([]);
    parseDataA();
  }, [parseDataA]);

  const domainWatch = formH.watch('domain_id');
  const categoriesList = list.categories.filter(item => item.domain_id.toString() === domainWatch);

  /* eslint-disable */
  return (
    <>
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Домен:</label>
        </ColLeft>
        <ColRight>
          <select
            name="domain_id"
            className="form-select"
            id="so-domain"
            ref={formH.register()}
          >
            { list.domains.map((domain) => domain.title === 'samsungshop'
                          && (
                            <option
                              key={`so-domain-${domain.id}`}
                              value={domain.id}
                            >
                              {domain.title}
                            </option>
                          ))}
          </select>
        </ColRight>
      </Group>

      <div
        className="divider text-center"
        data-content="Выбрать товары которые принимают участие в специальном предложении"
      />
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-markers">Выбрать по маркеру:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="markers"
            name="markers"
            list={list.markers}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAMarkerFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-categories">Выбрать по категории:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="categories"
            name="categories"
            list={categoriesList[0]?.children}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsACategoriesFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-jde">Выбрать по JDE:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="value"
            title="jde[]"
            name="jde[]"
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAJDEFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      {productsAList && productsAList.length !== 0 && (
        <TableItems
          formH={formH}
          list={productsAList}
          title="Выбраные товары А"
          name={`special_offer_type${typeID}_product`}
          thNames={['JDE код', 'Название', '']}
          func={{
            remove: removeAItem,
          }}
        />
      )}

      <div
        className="divider text-center"
        data-content="Бонусы"
      />

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Тип бонуса:</label>
        </ColLeft>
        <ColRight>
          <select
            name={`special_offer_type${typeID}_bonus[0].bonus_type`}
            className="form-select"
            id="so-bonus_type"
            ref={formH.register()}
          >
            <option value="">Выберите тип бонуса</option>
            <option value="1">Процентное начисление бонусов</option>
            <option value="2">Абсолютное начисление бонусов</option>
            <option value="3">Умножить бонусы</option>
          </select>
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Значение бонуса:</label>
        </ColLeft>
        <ColRight>
          <input
            name={`special_offer_type${typeID}_bonus[0].bonus_value`}
            className="form-select"
            id="so-bonus_value"
            placeholder="Введите значение бонуса"
            ref={formH.register()}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Лимит начисления бонусов:</label>
        </ColLeft>
        <ColRight>
          <input
            name={`special_offer_type${typeID}_bonus[0].bonus_limit`}
            className="form-select"
            id="so-bonus_limit"
            placeholder="Введите лимит начисления бонусов"
            ref={formH.register()}
          />
        </ColRight>
      </Group>
    </>
  );
};

export default TypeEight;
