// view / styles / dom ...

export const LIST_PER_PAGE = {
  sm: 2,
  default: 10,
  l: 20,
  xl: 50,
  xxl: 100,
  xxxl: 500,
};

export const INPUT_MAX_LENGTH = 255;
export const INPUT_MIN_LENGTH = 3;
export const PASSWORD_MIN_LENGTH = 8;