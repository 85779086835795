export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            .link-div-native > div {
                min-height: 100px;
            }
        </style>
    `;


    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `<a class="link-div-native" href="" target="_blank"><div></div></a> ${style}`,
    })
};