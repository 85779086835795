import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createCreditType, editCreditType, getCreditTypeEdit, removeCreditType,
} from '../../lib/api/creditTypes';
import { getBanks } from '../../lib/api/banks';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (bpId) => Promise.allSettled([getDomains(), getBanks(), bpId
&& getCreditTypeEdit(bpId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      banks: res[1]?.value?.data?.data,
      fields: bpId && res[2]?.value?.data,
    })
  });

export const create = createCreditType;
export const edit = editCreditType;
export const remove = removeCreditType;

export const parseDataSend = (data, catId) => {
  let d = { ...data };
  const isUpdate = typeof catId !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'bonus_limit',
    'bonus_value',
    'dimension_type',
    'document_type',
    'is_active',
    'for_business',
    'catalog_type',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    'dimension_type',
    'document_type',
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
