import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import ColLeft from "../../components/ui/FormEditView/ColLeft";
import ColRight from "../../components/ui/FormEditView/ColRight";
import GridEditView from "../../components/ui/GridEditView";
import cn from "classnames";
import { INPUT_MAX_LENGTH } from "../../const/view";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VALIDATION } from "../../const/text";
import useEdit from "../../lib/crud/useEdit";
import { edit, getData } from "./api";
import Loader from "../../components/ui/Loader";
import { useParams } from "react-router-dom";
import Title from "../../components/ui/FormEditView/Title";
import { parseDataSend } from "./api";
import InputFetchResult from "../../components/ui/InputFetchResult";
import { getProductSearch } from "../../lib/api/products";
import { uid } from "react-uid";
import ColFull from "../../components/ui/FormEditView/ColFull";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";

const DeliveryEditView = () => {
    const { id: dId } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: dId,
        updateFields: reset,
        handleSubmit,
        api: {
            edit,
            get: getData,
        },
        parse: {
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/delivery/not-found',
            save: `/app/deliveries${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            edit: 'Доставка обновлена',
        },
    });

    const [productByJDE, setProductByJDE] = useState(null);

    const getProductByJDE = async (id) => {
        const prod = await getProductSearch({'id': id})
        const res = prod?.data?.data[0]
        setProductByJDE(res)
        return res
    }

    const [selectedPayments, setSelectedPayments] = useState([]);

    useEffect(() => {
        if(data?.fields?.price_product_id){
            getProductByJDE(data?.fields?.price_product_id)
        }

        if (!!data?.fields?.payments?.length) {
            setSelectedPayments(data?.fields?.payments?.map(p => p.id))
        }
    }, [data?.fields])

    return(
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Delivery edit`} />
            <div className="box-wrap">
                {dId
                    ? (
                        <Title>
                            Доставка -
                            <small>{data && data?.fields?.title || data?.fields?.title_ru}</small>
                        </Title>
                    )
                    : <Title>Новая доставка</Title>
                }
                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide" />

                    <Group>
                        <ColFull>
                            <div>{data?.fields?.provider}</div>
                        </ColFull>
                    </Group>

                    {/* is_active */}
                    <Group>
                        <ColLeft>
                            <label className="form-switch">
                                <input
                                    name="is_active"
                                    type="checkbox"
                                    id="d-is-active"
                                    ref={register}
                                />
                                <i className="form-icon" />
                                {' '}
                                Статус активности
                            </label>
                        </ColLeft>
                        <ColRight>
                            <label className="form-switch">
                                <input
                                    name="is_hidden"
                                    type="checkbox"
                                    id="d-is-hidden"
                                    ref={register}
                                />
                                <i className="form-icon" />
                                {' '}
                                Скрыть
                            </label>
                        </ColRight>
                    </Group>

                    {/*title_ua*/}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="title_ua">Название УКР*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ua"
                                className={cn('form-input', {
                                    'is-error': errors.title_ua,
                                })}
                                type="text"
                                id="title_ua"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                            {errors.title_ua  && <p className="form-input-hint">{errors.title_ua.message}</p>}
                        </ColRight>
                    </Group>

                    {/*title_ru*/}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="title_ru">Название РУС*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ru"
                                className={cn('form-input', {
                                    'is-error': errors.title_ru,
                                })}
                                type="text"
                                id="title_ru"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                            {errors.title_ru  && <p className="form-input-hint">{errors.title_ru.message}</p>}
                        </ColRight>
                    </Group>

                    {/* payments */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="d-payments">Способы оплаты:</label>
                        </ColLeft>
                        <ColRight>
                            {data && !!data.payments?.length
                              ? (
                                <>
                                    <select
                                      name="payments"
                                      className={cn('form-select', {
                                          'is-error': errors.payments,
                                      })}
                                      id="d-payments"
                                      ref={register({
                                          required: VALIDATION.select.required,
                                      })}
                                      multiple
                                      onChange={(e) => {
                                          const tempResult = [];

                                          for (let i = 0; i < e?.currentTarget?.length; i++) {
                                              if (e?.currentTarget[i]?.selected) {
                                                  tempResult.push(+e?.currentTarget[i]?.value);
                                              }
                                          }

                                          setSelectedPayments(tempResult);
                                      }}
                                      value={selectedPayments}
                                    >
                                        {data?.payments?.map((payment) => (
                                            <option
                                                key={`d-payment-item-${payment.id}`}
                                                value={payment.id}
                                            >
                                                {payment.title_ru}
                                            </option>
                                        ))}
                                    </select>

                                    {errors.payments
                                    && <p className="form-input-hint">{errors.payments.message}</p>}
                                </>
                              )
                              : (
                                <div className="p-relative">
                                    <div className="loading" />
                                </div>
                              )}
                        </ColRight>
                    </Group>


                    {/* provider */}
                    {!dId && <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="provider-id">Провайдер:</label>
                            </ColLeft>
                            <ColRight>
                                <select
                                    name="provider"
                                    className="form-select"
                                    id="provider-id"
                                    ref={register}
                                >
                                    <option value="">выберите провадер</option>
                                    {data?.providers.map((provider) => (
                                        <option
                                            key={uid(provider.provider)}
                                            value={provider.provider}
                                        >
                                            {provider.name}
                                        </option>
                                    ))}
                                </select>
                            </ColRight>
                        </Group>
                    }
                </Form>
                {load && <Loader />}
            </div>

             <div className="box-wrap box-wrap--mb mt-2">
                 <Form Tag="div">

                     {/* domain_id */}
                     <Group>
                         <ColLeft>
                             <label className="form-label" htmlFor="d-domain">Домен:</label>
                         </ColLeft>
                         <ColRight>
                             {data
                                 ? (
                                     <>
                                         <select
                                             name="domain_id"
                                             className={cn('form-select', {
                                                 'is-error': errors.domain_id,
                                             })}
                                             id="d-domain"
                                             ref={register({
                                                 required: VALIDATION.select.required,
                                             })}
                                         >
                                             <option value="">выберите домен</option>
                                             {data && !!data.domains && data.domains.map((domain) => (
                                                 <option
                                                     key={domain.id}
                                                     value={domain.id}
                                                 >
                                                     {domain.title}
                                                 </option>
                                             ))}
                                         </select>

                                         {errors.domain_id
                                         && <p className="form-input-hint">{errors.domain_id.message}</p>}
                                     </>
                                 )
                                 : (
                                     <div className="p-relative">
                                         <div className="loading" />
                                     </div>
                                 )}
                         </ColRight>
                     </Group>

                     {/* price_product_id */}
                     <Group>
                         <ColLeft>
                             <label className="form-label" htmlFor="as-id">ID:</label>
                         </ColLeft>
                         <ColRight>
                             <InputFetchResult
                                 defVal={productByJDE}
                                 name="price_product_id"
                                 form={formH}
                                 searchParam="id"
                                 func={getProductSearch}
                                 placeholder="Введите id товара"
                             />
                         </ColRight>
                     </Group>
                 </Form>
                 {load && <Loader />}
             </div>
        </GridEditView>
    )
}

export default DeliveryEditView
