import React from 'react';
import s from "./InputWrapper.module.scss";

const InputWrapper = ({ id, label, children, customStyle = {}, errorComponent, style }) => {
    return (
        <div className={s.wrapper} style={style}>
            <div className={s.input__wrapper}>
                {children}
                <label htmlFor={id} className={s.label} style={customStyle}>{label}</label>
            </div>
            {errorComponent}
        </div>
    );
};

export default InputWrapper;