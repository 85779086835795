import React from "react";
import Block from "../../../components/ui/Block/Block";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s from "../Warehouse.module.scss";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";

const typeList = [
    {
        id: 1,
        title: 'Власний склад'
    },
    {
        id: 2,
        title: 'Роздрібний магазин'
    },
    {
        id: 3,
        title: 'Склад постачальника'
    },
    {
        id: 4,
        title: 'Пункт самовивезення'
    },
    {
        id: 5,
        title: 'Віртуальний'
    },
]

const BasicInformation = ({ formH, data }) => {
    return (
        <Block
            title="Основна інформація"
            classNameWrapper={s.block}
            styleContent={{ height: '70vh', overflow: 'hidden auto', backgroundColor: '#fff' }}
        >
            {/* number */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper
                        label="Номер складу"
                        id="wh-number"
                        errorComponent={formH?.errors?.number &&
                        <p className="form-input-hint">{formH?.errors?.number?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH?.errors?.number,
                            })}
                            type="number"
                            placeholder="Номер складу"
                            ref={formH.register({
                                required: VALIDATION.req.required
                            })}
                            name="number"
                            id="wh-number"
                            defaultValue={data?.fields?.number}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* title_ua */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper
                        label="Назва складу УКР"
                        id="wh-title-ua"
                        errorComponent={formH?.errors?.title_ua &&
                        <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH?.errors?.title_ua,
                            })}
                            type="text"
                            placeholder="Назва складу УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                                required: VALIDATION.req.required
                            })}
                            name="title_ua"
                            id="wh-title-ua"
                            defaultValue={data?.fields?.title_ua}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* title_ru */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper
                        label="Назва складу РУС"
                        id="wh-title-ru"
                        errorComponent={formH?.errors?.title_ru &&
                        <p className="form-input-hint">{formH?.errors?.title_ru?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH?.errors?.title_ru,
                            })}
                            type="text"
                            placeholder="Назва складу РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                                required: VALIDATION.req.required
                            })}
                            name="title_ru"
                            id="wh-title-ru"
                            defaultValue={data?.fields?.title_ru}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* type */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                            <select
                                name="type"
                                className={cn(s_Select.select, {
                                    [s_Select.select_error]: formH.errors?.type,
                                })}
                                id="wh-type"
                                ref={formH.register({
                                    required: VALIDATION.select.required,
                                })}
                            >
                                <option value="">виберіть тип</option>
                                {typeList?.map((type) => (
                                    <option
                                        key={`wh-type-item-${type.id}`}
                                        value={type.id}
                                        selected={type?.id?.toString() === data?.fields?.type?.toString()}
                                    >
                                        {type.title}
                                    </option>
                                ))}
                            </select>
                            <label className={s_Select.label} htmlFor="wh-type">Тип складу</label>
                        </div>
                        {formH.errors?.type
                        && <p className="form-input-hint">{formH.errors.type?.message}</p>}
                    </div>
                </ColFull>
            </Group>

            {/* code */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper
                        label="Унікальний ідентифікатор складу"
                        id="wh-code"
                        errorComponent={formH?.errors?.code &&
                        <p className="form-input-hint">{formH?.errors?.code?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH?.errors?.code,
                            })}
                            type="number"
                            placeholder="Унікальний ідентифікатор складу"
                            ref={formH.register({
                                required: VALIDATION.req.required
                            })}
                            name="code"
                            id="wh-code"
                            defaultValue={data?.fields?.code}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* display_store */}
            <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <CheckboxSwitch
                        name="display_store"
                        id="wh-display-store"
                        label="Відображення складу на сторінці “Магазини” (https://samsungshop.com.ua/)"
                        formH={formH}
                        defaultChecked={data?.fields?.display_store}
                        fontWeight={400}
                        reverse
                    />
                </ColFull>
            </Group>

            {/* domain_id */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                            <select
                                name="domain_id"
                                className={s_Select.select}
                                id="wh-domain-id"
                                ref={formH.register}
                            >
                                <option value="">виберіть домен</option>
                                {!!data?.domains?.length && data?.domains?.map((domain) => (
                                    <option
                                        key={`wh-domain-item-${domain.id}`}
                                        value={domain.id}
                                        selected={domain?.id?.toString() === data?.fields?.domain_id?.toString()}
                                    >
                                        {domain.title}
                                    </option>
                                ))}
                            </select>
                            <label className={s_Select.label} htmlFor="wh-domain-id">Імпорт/відображення цін складу на сайті</label>
                        </div>
                    </div>
                </ColFull>
            </Group>

            {/* status */}
            <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <CheckboxSwitch
                        name="status"
                        id="wh-status"
                        label="Статус відображення на сайтах кількості доступних для замовлення товарів"
                        formH={formH}
                        defaultChecked={data?.fields?.status}
                        fontWeight={400}
                        reverse
                    />
                </ColFull>
            </Group>

            {/* quantity_type */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                            <select
                                name="quantity_type"
                                className={cn(s_Select.select, {
                                    [s_Select.select_error]: formH.errors?.quantity_type,
                                })}
                                id="wh-quantity-type"
                                ref={formH.register({
                                    required: VALIDATION.select.required,
                                })}
                                defaultValue={data?.fields?.quantity_type}
                            >
                                <option value="">виберіть значення</option>
                                <option value={0}>Приймає кількість одиниць товару</option>
                                <option value={1}>Приймає наявність товару (в наявності/не в наявності)</option>
                            </select>
                            <label className={s_Select.label} htmlFor="wh-quantity-type">
                                Параметри обліку даних щодо наявності товарів на складі
                            </label>
                        </div>
                        {formH.errors?.quantity_type
                        && <p className="form-input-hint">{formH.errors.quantity_type?.message}</p>}
                    </div>
                </ColFull>
            </Group>

            {/* can_work_without_light */}
            <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <CheckboxSwitch
                        name="can_work_without_light"
                        id="wh-can-work-without-light"
                        label="Працюємо без світла"
                        formH={formH}
                        defaultChecked={data?.fields?.can_work_without_light}
                        fontWeight={400}
                        reverse
                    />
                </ColFull>
            </Group>

            {/* has_parking */}
            <Group attrs={{ style: { margin: '0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <CheckboxSwitch
                        name="has_parking"
                        id="wh-has-parking"
                        label="Наявність парковки"
                        formH={formH}
                        defaultChecked={data?.fields?.has_parking}
                        fontWeight={400}
                        reverse
                    />
                </ColFull>
            </Group>
        </Block>
    );
};

export default BasicInformation;