import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import routes from './routes';
import { useEffect } from "react";
import { isAuth, isAuthenticated } from "./lib/hooks/useAuth";

function App() {
  const navigate = useNavigate();

  const { pathname } = useLocation()

  if(!localStorage?.tokenData){
    if(!pathname.includes('/login')) {
      let currentUrl = `${window.location.pathname}${window.location.search}`;
      localStorage.setItem('followingLink', currentUrl);
    }
  }

  if(localStorage.followingLink && localStorage.tokenData){
    window.location.href = localStorage.getItem('followingLink');
  }

  if(!localStorage.getItem('per_page')) {
    localStorage.setItem('per_page', '20');
  }

  useEffect(() => {
    localStorage.setItem('canRefresh', 'true');
    isAuth(navigate);
  }, [isAuthenticated]);

  const routing = useRoutes(routes(isAuthenticated()));
  return routing;
}

export default App;
