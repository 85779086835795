import { getAccessoryRelation, removeAccessoryRelation } from '../../lib/api/accessory_relation';
import { getDomains } from '../../lib/api/domains';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
  }));

export const getData = getAccessoryRelation;
export const remove = removeAccessoryRelation;
