import React, {memo, useCallback, Fragment, useEffect, useMemo, useState} from "react";
import {getProduct} from "../../../../lib/api/products";
import errorMessage from "../../../../lib/errorMessage";
import parseCategories from "../../../../lib/helpers/parseCategories";
import cn from "classnames";
import s from "./block.module.scss";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../../AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../../assets/icons/icon_search.svg";
import icon_close_red from "../../../../assets/icons/icon_close_red.svg";
import {toast} from "react-toastify";
import Loader from "../../../../components/ui/Loader";
import {refreshToken} from "../../../../lib/crud/refreshToken";
import Button from "../../../../components/ui/Button";
import {specOfferCalculate} from "../../../../lib/api/specOffers";
import Input from "../../../../components/common/TableListViewNew/Input/Input";
import Group from "../../../../components/ui/FormEditView/Group";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import RSelect from "../../../../components/ui/RSelect";

const ProductBlock = memo(({
                             name,
                             sendName,
                             title,
                             domainWatch,
                             activeTab,
                             showActive,
                             formH,
                             data,
                             priceCart = false,
                             priceOrder = false,
                             showBtnCalculate = false,
                             discountPercent = null,
                             discountValue = null,
                             discountType3ProductA = null,
                             rightSectionSecondOptionName,
                           }) => {
  const [load, setLoad] = useState(false);

  const [productsAList, setProductsAList] = useState([]);
  const [addProductA, setAddProductA] = useState('');

  const productsList = useMemo(() => {
    return productsAList;
  }, [productsAList]);

  const [searchProduct, setSearchProduct] = useState('');
  const [filterProductsList, setFilterProductsList] = useState([]);

  const memoFilterProductsList = useMemo(() => {
    if (!!filterProductsList?.length) {
      if (!!searchProduct?.length) {
        return filterProductsList?.filter((item => {
          if (!!searchProduct?.split(' ')?.length) {
            const splitSearchText = searchProduct?.split(' ');
            return splitSearchText?.some(itemS => item?.jde?.includes(itemS));
          } else {
            return item?.jde?.includes(searchProduct);
          }
        }))
      }
      return filterProductsList;
    }
    return [];
  }, [filterProductsList, searchProduct])

  const handleAddProductA = useCallback(async () => {
    if (!!addProductA.length) {
      setLoad(true);
      await getProduct({jde: addProductA, per_page: 5000}).then((res) => {
        if (!!res?.data?.data?.length) {
          setProductsAList(prev => {
            if (!!prev?.length) {
              const temp = [...prev];

              res?.data?.data?.forEach((item) => {
                const findProduct = temp.findIndex(tempItem => tempItem.id === item?.id);
                if (findProduct !== -1) {
                  temp[findProduct] = item;
                } else {
                  temp.push(item);
                }
              });

              return temp;
            } else {
              return res.data.data
            }

          })
        } else {
          toast(`Товару не ${addProductA} існує`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setAddProductA('');
      })
        .catch(async (err) => {
          if (err?.response?.status === 401) {
            await refreshToken(async () => {
              await getProduct({jde: addProductA}).then((res) => {
                if (!!res?.data?.data?.length) {
                  setProductsAList(prev => {
                    if (!!prev?.length) {
                      const temp = [...prev];

                      res?.data?.data?.forEach((item) => {
                        const findProduct = temp.findIndex(tempItem => tempItem.id === item?.id);
                        if (findProduct !== -1) {
                          temp[findProduct] = item;
                        } else {
                          temp.push(item);
                        }
                      });

                      return temp;
                    } else {
                      return [res.data.data]
                    }

                  })
                } else {
                  toast(`Товару не ${addProductA} існує`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                setAddProductA('');
              })
            })
          } else {
            await errorMessage(err, '/api/admin/product GET')
          }
        })
        .finally(() => setLoad(false))
    }
  }, [productsAList, addProductA])

  const onEnterPressAddProduct = useCallback((e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleAddProductA();
    }
  }, [productsAList, addProductA])

  const removeAItem = useCallback((el) => {
    const temp = [...productsAList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsAList(temp);
      setFilterProductsList(temp);
    }
  }, [productsAList]);

  const removeAllProduct = useCallback(() => {
    setProductsAList([]);
    setFilterProductsList([]);
  }, [productsAList, filterProductsList]);

  const handleChangePriceA = useCallback((e, productId) => {
    setProductsAList(prev => {
      if (!!prev?.length) {
        const temp = [...prev];

        const findIndex = temp?.findIndex(item => +item.id === +productId)

        if (findIndex !== -1) {
          temp[findIndex] = {
            ...temp[findIndex],
            pivot: {
              ...temp[findIndex]?.pivot,
              [e.target.name]: e.target.value
            }
          }
        }

        return temp;
      }
    })
  }, [productsAList]);

  const handleOnChangeFilter = (e) => setSearchProduct(e.target.value);
  const clearFilter = () => setSearchProduct('');

  // MARKERS
  const [filterMarkers, setFilterMarkers] = useState([]);
  const [searchMarkers, setSearchMarkers] = useState('');

  const handleFilterMarkers = useCallback(() => {
    if (!!searchMarkers?.length) {
      const filterMarker = data?.markers.filter(item => item.title.toLowerCase() === searchMarkers.toLowerCase());
      setFilterMarkers(filterMarker);
    } else {
      setFilterMarkers(data?.markers)
    }
  }, [searchMarkers, filterMarkers]);

  const onEnterPressFilterMarkers = useCallback((e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleFilterMarkers();
    }
  }, [searchMarkers, filterMarkers]);

  const addAsyncProducts = (dataAsync) => {
    setProductsAList(prev => {
      const temp = [...prev];
      dataAsync.forEach(item => {
        const findProduct = temp.findIndex(tempItem => tempItem.id === item.id);
        if (findProduct !== -1) {
          temp[findProduct] = item;
        } else {
          temp.push(item);
        }
      })
      return temp;
    })
  }

  const handleAddMarkersProductA = useCallback(async (id) => {
    if (id) {
      setLoad(true);
      await getProduct({markers: [id], per_page: 5000}).then((res) => {
        if (!!res?.data?.data?.length) {
          addAsyncProducts(res.data.data);
        } else {
          toast(`Товаров ${id} не існує`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
        .catch(async (err) => await errorMessage(err, '/api/admin/product GET'))
        .finally(() => setLoad(false))
    }
  }, [productsAList, data?.markers]);

  // CATEGORY
  const [categories, setCategories] = useState([]);
  const [tabCategory, setTabCategory] = useState(0);

  const categoriesList = useMemo(() => {
    return data?.categories?.filter(item => item.domain_id.toString() === domainWatch?.toString());
  }, [data, domainWatch]);

  const handleAddCategoriesProductA = useCallback(async (id) => {
    if (id) {
      setLoad(true);
      await getProduct({categories: id, per_page: 5000}).then((res) => {
        if (!!res?.data?.data?.length) {
          addAsyncProducts(res.data.data);
        } else {
          toast(`Товаров ${id} не існує`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
        .catch(async (err) => await errorMessage(err, '/api/admin/product GET'))
        .finally(() => setLoad(false))
    }
  }, [productsAList, categories]);

  const calculateProducts = async () => {
    const parseProducts = productsList?.map(product => product?.id);
    const dataSend = {
      domain_id: domainWatch,
      discount_value: discountValue || null,
      discount_percent: discountPercent || null,
      products: parseProducts
    }

    await specOfferCalculate(dataSend).then((res) => {
      if (res?.data) {
        setProductsAList((prev) => {
          const temp = [...prev];

          res?.data?.forEach(item => {
            const findProduct = temp?.findIndex(prodTemp => prodTemp.id === item?.product_id);

            if (findProduct !== -1) {

              if (temp?.[findProduct]?.pivot) {
                temp[findProduct].pivot.price_cart = item.price_full;
                temp[findProduct].pivot.price_order = item.price_discount;
              } else {
                temp[findProduct].pivot = {
                  price_cart: item.price_full,
                  price_order: item.price_discount
                };
              }

            }
          });

          return temp;
        })
      }
    }).catch(err => errorMessage(err, '/api/admin/special-offer/calculate-discount POST'))
  }

  useEffect(() => {
    if (!!categoriesList?.length) setCategories(parseCategories(categoriesList[0].children));
  }, [categoriesList, domainWatch]);

  useEffect(() => {
    if (!!data?.fields?.[name]?.length) {
      setProductsAList(data?.fields[name]);
    }

    if (!!data?.markers?.length) {
      setFilterMarkers(data?.markers);
    }

    if (!!data?.fields?.products) {
      setProductsAList(data?.fields?.products);
    }
  }, [data]);

  useEffect(() => {
    if (!!productsAList?.length) {
      setFilterProductsList(productsAList);
    }
  }, [productsAList]);

  // Select bundls

  const [selectedType3, setSelectedType3] = useState([]);

  useEffect(() => {
    const copyEl = data?.fields?.selections?.map((el) => {
      return {...el, title_ua: el.title}
    })

    setSelectedType3(copyEl)
  }, [data?.selections?.data, data?.fields?.selections]);

  return (
    <>
      <div className={cn(s.cat__block, {
        [s.overflowHidden]: activeTab !== showActive
      })} style={{maxWidth: '430px', marginRight: "32px"}}>
        <div className={s.tabs__text}>
                        <span className={cn('', {
                          [s.tabs__active]: tabCategory === 0
                        })} onClick={() => setTabCategory(0)}>Категорія</span>
          |
          <span className={cn('', {
            [s.tabs__active]: tabCategory === 1
          })}
                onClick={() => setTabCategory(1)}>{rightSectionSecondOptionName ? 'Вибірки' : 'Вибір по маркеру'}</span>
        </div>

        <div style={tabCategory === 1 && rightSectionSecondOptionName ? {display: 'flex'} : {display: 'none'}}>
          <Group attrs={{className: s.rselect__wrapper}}>
            <ColFull>
              <RSelect
                props={{
                  value: selectedType3,
                  onChange: (opt) => {
                    setSelectedType3(opt)
                  },
                  placeholder: rightSectionSecondOptionName ? 'Обрати Вибірки' : 'Виберіть сумісні бандли',
                  options: data?.selections?.data.map((el) => {
                    return {...el, title_ua: el.title}
                  }),
                  isMulti: true,
                  getOptionLabel: (opt) => opt.title_ua,
                  getOptionValue: (opt) => opt.id,
                }}
              />

              {selectedType3?.length > 0 && selectedType3.map((item, index) =>
                <input
                  key={`selections${item.id}`}
                  type="hidden"
                  ref={formH.register}
                  name={`selections[${item.id}]`}
                  value={item.id}
                />
              )}
            </ColFull>
          </Group>
        </div>

        <div style={tabCategory === 1 && rightSectionSecondOptionName ? {display: 'none'} : {display: 'block'}}>
          <div className={cn(s.cat__list, {
            [s.overflowHidden]: tabCategory !== 0
          })}>
            <ul>
              {!!categories?.length && categories.map((cat) => {
                if (!!cat?.children?.length) {
                  return (
                    <Fragment key={cat.value}>
                      <li onClick={() => handleAddCategoriesProductA(cat.value)}>{cat.label}</li>
                      <ul>
                        {cat?.children?.map(catChildFirst => {
                          if (!!catChildFirst?.children?.length) {
                            return (
                              <Fragment key={catChildFirst.value}>
                                <li
                                  style={{marginLeft: "10px"}}
                                  onClick={() => handleAddCategoriesProductA(catChildFirst.value)}
                                >
                                  {catChildFirst.label}
                                </li>
                                <ul style={{marginLeft: "20px"}}>
                                  {catChildFirst?.children?.map(catChildSecond => {
                                    if (!!catChildSecond?.children?.length) {
                                      return (
                                        <Fragment key={catChildSecond.value}>
                                          <li
                                            style={{marginLeft: "20px"}}
                                            onClick={() => handleAddCategoriesProductA(catChildSecond.value)}
                                          >
                                            {catChildSecond.label}
                                          </li>
                                          <ul style={{marginLeft: "40px"}}>
                                            {catChildSecond?.children?.map(catChildThird => {
                                              return <li
                                                key={catChildThird.value}
                                                onClick={() => handleAddCategoriesProductA(catChildThird.value)}
                                              >
                                                {catChildThird.label}
                                              </li>
                                            })}
                                          </ul>
                                        </Fragment>
                                      )
                                    } else {
                                      return <li
                                        key={catChildSecond.value}
                                        style={{marginLeft: "20px"}}
                                        onClick={() => handleAddCategoriesProductA(catChildSecond.value)}
                                      >
                                        {catChildSecond.label}
                                      </li>
                                    }
                                  })}
                                </ul>
                              </Fragment>
                            )
                          }
                          return <li
                            key={catChildFirst.value}
                            style={{marginLeft: "10px"}}
                            onClick={() => handleAddCategoriesProductA(catChildFirst.value)}
                          >
                            {catChildFirst.label}
                          </li>
                        })}
                      </ul>
                    </Fragment>
                  )
                }
                return (
                  <li key={cat.value} onClick={() => handleAddCategoriesProductA(cat.value)}>
                    {cat.label}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={cn(s.cat__list, {
            [s.overflowHidden]: tabCategory !== 1
          })}>
            <InputWrapper label="Пошук" id="search-prod">
              <input
                className={s_Input.input}
                type="text"
                placeholder="Пошук"
                id="search-prod"
                value={searchMarkers}
                onChange={e => setSearchMarkers(e.target.value)}
                onKeyDown={onEnterPressFilterMarkers}
              />
              <div className={s_Cat.input_icon} onClick={handleFilterMarkers}>
                <img src={icon_search} alt="Icon search"/>
              </div>
            </InputWrapper>
          </div>
          <div className={cn(s.cat__list, {
            [s.overflowHidden]: tabCategory !== 1
          })}>
            <ul>
              {!!filterMarkers?.length && filterMarkers?.map((marker) => {
                return (
                  <li key={marker.id} onClick={() => handleAddMarkersProductA(marker.id)}>
                    {marker.title}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      {/*tabCategory*/}

      <div style={tabCategory === 1 && rightSectionSecondOptionName ? {display: 'none'} : {display: 'flex'}}>
        <div className={cn(s.cat__block, {
          [s.overflowHidden]: activeTab !== showActive
        })} style={{maxWidth: "770px", width: "100%"}}>
          <div className={s.top}>
            <div className={s.top__title}>{title}</div>
            {showBtnCalculate &&
              <Button disabled={!productsList?.length} onClick={calculateProducts} purple>Розрахувати з %</Button>}
          </div>
          <div>
            <InputWrapper label="Додати за JDE" id="search-prod">
              <input
                className={s_Input.input}
                type="text"
                placeholder="Додати за JDE"
                id="search-prod"
                value={addProductA}
                onChange={(e) => setAddProductA(e.target.value)}
                onKeyDown={onEnterPressAddProduct}
              />
              <div className={s_Cat.input_icon} onClick={handleAddProductA}>
                <img src={icon_search} alt="Icon search"/>
              </div>
            </InputWrapper>
          </div>
          <div>
            <div className={s.listA__header}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span className={s.listA__label} style={{whiteSpace: 'nowrap'}}>Назва товару</span>
                <Input
                  value={searchProduct}
                  onChange={handleOnChangeFilter}
                  placeholder="Пошук JDE"
                  funcClearValue={clearFilter}
                  style={{maxWidth: "100%", width: '100%'}}
                  styleWrapper={{width: '100%'}}
                />
              </div>
              <div style={{display: "flex"}}>
                {discountType3ProductA && <span className={`${s.listA__label} ${s.listA__label_price}`}>Скидка</span>}
                {priceCart && <span className={`${s.listA__label} ${s.listA__label_price}`}>Кошик</span>}
                {priceOrder && <span className={`${s.listA__label} ${s.listA__label_price}`}>Замовлення</span>}
                <span
                  className={`${s.listA__label} ${s.listA__label_clear}`}
                  onClick={removeAllProduct}
                >
                                Очистити список
                        </span>
              </div>
            </div>
            <div className={s.listA__body}>
              <div className={s.cat__list}>
                <ul>
                  {!!memoFilterProductsList?.length ? memoFilterProductsList?.map((productA, index) => {

                    return (
                      <li key={productA.id} className={s.listA__item}>
                        <div style={{maxWidth: `${(priceCart || priceOrder) && "450px"}`, width: "100%"}}>
                          {productA.title_ua} ({productA.jde})
                        </div>
                        {discountType3ProductA && <input
                          className={s.listA__input}
                          type="number"
                          name="discount_value"
                          value={+productA?.pivot?.discount_value || ''}
                          style={{marginRight: '40px'}}
                          onChange={(e) => handleChangePriceA(e, productA?.id)}
                        />}
                        {priceCart && <input
                          className={s.listA__input}
                          type="number"
                          name="price_cart"
                          value={+productA?.pivot?.price_cart || ''}
                          onChange={(e) => handleChangePriceA(e, productA?.id)}
                        />}
                        {priceOrder && <input
                          className={s.listA__input}
                          type="number"
                          name="price_order"
                          value={+productA?.pivot?.price_order || ''}
                          onChange={(e) => handleChangePriceA(e, productA?.id)}
                        />}
                        <div onClick={() => removeAItem(productA)}>
                          <img src={icon_close_red} alt="Icon close" style={{maxWidth: 'max-content'}}/>
                        </div>
                      </li>
                    )
                  }) : (
                    <li className={s.listA__item} style={{cursor: "auto"}}>
                      Немає товарів
                    </li>
                  )}
                </ul>
              </div>
              {!!productsList?.length ? productsList?.map((productA, index) => {
                if (priceCart && priceOrder) {
                  return (
                    <Fragment key={`choose-product-${index}`}>
                      <input
                        type="hidden"
                        value={productA.id}
                        name={`${sendName}[${index}].product_id`}
                        ref={formH.register}
                      />
                      <input
                        type="hidden"
                        value={+productA?.pivot?.price_cart || ''}
                        name={`${sendName}[${index}].price_cart`}
                        ref={formH.register}
                      />
                      <input
                        type="hidden"
                        value={+productA?.pivot?.price_order || ''}
                        name={`${sendName}[${index}].price_order`}
                        ref={formH.register}
                      />
                    </Fragment>
                  )
                }

                if (priceCart) {
                  return (
                    <Fragment key={`choose-product-${index}`}>
                      <input
                        type="hidden"
                        value={productA.id}
                        name={`${sendName}[${index}].product_id`}
                        ref={formH.register}
                      />
                      <input
                        type="hidden"
                        value={+productA?.pivot?.price_cart || ''}
                        name={`${sendName}[${index}].price_cart`}
                        ref={formH.register}
                      />
                    </Fragment>
                  )
                }

                if (priceOrder) {
                  return (
                    <Fragment key={`choose-product-${index}`}>
                      <input
                        type="hidden"
                        value={productA.id}
                        name={`${sendName}[${index}].product_id`}
                        ref={formH.register}
                      />
                      <input
                        type="hidden"
                        value={+productA?.pivot?.price_order || ''}
                        name={`${sendName}[${index}].price_order`}
                        ref={formH.register}
                      />
                    </Fragment>
                  )
                }

                if (discountType3ProductA) {
                  return (
                    <Fragment key={`choose-product-${index}`}>
                      <input
                        type="hidden"
                        value={productA.id}
                        name={`${sendName}[${index}].product_id`}
                        ref={formH.register}
                      />
                      <input
                        type="hidden"
                        value={+productA?.pivot?.discount_value || ''}
                        name={`${sendName}[${index}].discount_value`}
                        ref={formH.register}
                      />
                    </Fragment>
                  )
                }
                return <input
                  key={`choose-product-${index}`}
                  type="hidden"
                  value={productA.id}
                  name={`${sendName}[${index}]`}
                  ref={formH.register}
                />
              }) : <input
                type="hidden"
                value={[]}
                name={sendName}
                ref={formH.register}
              />}
            </div>
          </div>
        </div>
      </div>
      {load && <Loader/>}
    </>
  );
});

export default ProductBlock;