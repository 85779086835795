import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const TreeCustom = ({ categoryArray, selectValue, setArrayTrigger, formH, name, id }) => {
    const [expanded, setExpanded] = useState([1]);
    const [selected, setSelected] = useState([]);
    const [categoryArrayParse, setCategoryArrayParse] = useState({});

    const handleSelect = (event, nodeIds) => {
        let res = [];
        setSelected(prev => {
            const temp = [...prev];

            nodeIds?.forEach(node => {
                if (temp?.includes(+node)) {
                    const findIndex = temp?.findIndex((item) => item === +node)
                    if (findIndex !== -1) {
                        temp.splice(findIndex, 1);
                    }
                } else {
                    temp.push(+node);
                }
            });

            res = temp

            return temp;
        })

        if (setArrayTrigger) {
            setArrayTrigger(res)
        }
    };

    const renderTree = (nodes) => (
        <TreeItem
            key={`${name}-${nodes.value}`}
            id={`tree-custom-item-${nodes.value}`}
            nodeId={+nodes.value}
            label={nodes.value !== 1 ? <Box sx={{ display: "flex" }}>
                <Box sx={{ mr: 2, width: "18px", height: "18px", display: "flex", alignItems: "center" }}>
                    {nodes.children ? expanded?.includes(+nodes.value) ? (
                        <ExpandMoreIcon
                            width={18}
                            height={18}
                            onClick={() => {
                                setExpanded(prev => {
                                    const temp = [...prev];

                                    if (temp.includes(+nodes.value)) {
                                        const findInd = temp.findIndex(item => item === +nodes.value)
                                        if (findInd !== -1) {
                                            temp.splice(findInd, 1)
                                        }
                                    } else {
                                        temp.push(+nodes.value)
                                    }

                                    return temp;
                                })
                            }}
                        />
                    ) : (
                        <ChevronRightIcon
                            width={18}
                            height={18}
                            onClick={() => {
                                setExpanded(prev => {
                                    const temp = [...prev];

                                    if (temp.includes(+nodes.value)) {
                                        const findInd = temp.findIndex(item => item === +nodes.value)
                                        if (findInd !== -1) {
                                            temp.splice(findInd, 1)
                                        }
                                    } else {
                                        temp.push(+nodes.value)
                                    }

                                    return temp;
                                })
                            }}
                        />
                    ) : null}
                </Box>
                <div>{nodes.label}</div>
            </Box> : null}
            sx={{ fontSize: "16px" }}
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    useEffect(() => {
        if (categoryArray) {
            setCategoryArrayParse({
                value: 1,
                label: "Категории",
                children: categoryArray
            })
        }

        if (typeof selectValue !== 'string') {
            const parseSelectValue = selectValue?.map(item => +item)
            setSelected(parseSelectValue)
            setExpanded([1, ...parseSelectValue])
        }
    }, []);

    return (
        <Box>
            <TreeView
                aria-label="controlled"
                id={id}
                expanded={expanded.length ? expanded : "1"}
                selected={selected}
                onNodeSelect={handleSelect}
                multiSelect
            >
                {renderTree(categoryArrayParse)}
            </TreeView>

            {!!selected?.length && selected?.map((select, index) => {
                return (
                    <input
                        type="hidden"
                        name={`${name}[${index}]`}
                        value={select}
                        key={`tree-custom-input-${index}`}
                        ref={formH.register}
                    />
                )
            })}
        </Box>
    );
};

export default TreeCustom;