import {
    getSortBannerCatalog,
    removeSortBannerCatalog,
    getSortBannersTypes,
    updateSortBannerCatalog,
    refreshSortBannerCatalog, editSortBannerCatalog
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getSortBannersTypes(), getCatTree()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banner_types: res[1]?.value?.data,
        categories: res[2]?.value?.data
    }));

export const edit = editSortBannerCatalog;

export const getData = getSortBannerCatalog;
export const updateSort = updateSortBannerCatalog;

export const refreshSort = refreshSortBannerCatalog;
export const remove = removeSortBannerCatalog;
