import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, getData, edit, parseDataGet, parseDataSend,
} from './api';
import InputMulti from '../../components/ui/InputMulti';
import TableAddress from './TableAddress'
import {VALIDATION} from "../../const/text";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const ContactEditView = () => {
  const { id: cID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: cID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/contact/not-found',
      save: `/app/contact${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Контакт создан',
    },
  });

/* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Contact edit`} />
      <div className="box-wrap">
        {cID
          ? (
            <Title>
              Склад -
              <small>{data && data.fields.title_ru}</small>
            </Title>
          )
          : <Title>Новый контакт</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* email */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="wh-email">Емайл:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.email,
                })}
                name="email"
                type="email"
                id="wh-email"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                // ref={register}
                ref={register({
                  required: VALIDATION.req.required,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Не валидный email адрес"
                  }
                })}
              />
              {errors.email && <p className="form-input-hint text-error">{errors.email.message}</p>}
            </ColRight>
          </Group>

          {/* phones */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="wh-phones">Телефон:</label>
            </ColLeft>
            <ColRight>
              <InputMulti type="text" name="phone" maxItems={4} form={formH} defVal={data?.fields?.phones}/>
            </ColRight>
          </Group>
        </Form>

        {/* address */}
        {data
          ? (
            <TableAddress
              updateID={cID}
              formH={formH}
              nameParent="address"
            />
          )
          : <Loader />
        }

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default ContactEditView;
