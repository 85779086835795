import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import { getCatTree } from '../../lib/api/categories';
import { refreshToken } from "../../lib/crud/refreshToken";

import {
  createAccessoryRelation,
  editAccessoryRelation,
  getAccessoryRelationEdit, removeAccessoryRelation,
} from '../../lib/api/accessory_relation';


export const getData = (arID) => Promise.allSettled([getDomains(), getCatTree(), arID
&& getAccessoryRelationEdit(arID)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    const parseCategories = res[1]?.value?.data?.map(item => {
      const parseSecondLevel = item.children.map(itemSecond => {
        const parseThirdLevel = itemSecond.children.map(itemThird => {
          const parseFourthLevel = itemThird.children.map(itemFourth => {
            return ({
              value: `cat_${itemFourth.id}`,
              label: itemFourth.title,
              children: [],
            })
          });
          return {
            value: `cat_${itemThird.id}`,
            label: itemThird.title,
            children: parseFourthLevel,
          };
        });
        return {
          value: `cat_${itemSecond.id}`,
          label: itemSecond.title,
          children: parseThirdLevel,
        };
      })
      return {
        value: `cat_${item.id}`,
        label: item.title,
        children: parseSecondLevel,
      };
    });

    return ({
      domains: res[0]?.value?.data,
      categories: parseCategories,
      fields: arID && res[2]?.value?.data,
    });
  });

export const create = createAccessoryRelation;
export const edit = editAccessoryRelation;
export const remove = removeAccessoryRelation;

// getCatSearch({
//   domain_id: 1,
//   is_active: 1,
//   lang: 'ru',
// }).then(res => parseCategories(res.data));
//
// const catCounts = [];
//
// const parseCategories = (array) => {
//   const parseCat = array.map(item => {
//     const parseSecondLevel = item.children.map(itemSecond => {
//       const parseThirdLevel = itemSecond.children.map(itemThird => {
//         const parseFourthLevel = itemThird.children.map(itemFourth => ({
//           cat_id: itemFourth.id,
//           products_count: itemFourth.products_count,
//         }));
//         return [
//             {
//               cat_id: itemThird.id,
//               products_count: itemThird.products_count
//             },
//             ...parseFourthLevel,
//         ];
//       });
//       const flatThird = [...parseThirdLevel.flat()];
//       return [
//         {
//           cat_id: itemSecond.id,
//           products_count: itemSecond.products_count
//         },
//         ...flatThird,
//       ];
//     });
//     const flatSecond = [...parseSecondLevel.flat()];
//     return [
//       {
//         cat_id: item.id,
//         products_count: item.products_count
//       },
//       ...flatSecond,
//     ];
//   });
//   const flatCat = [...parseCat.flat()];
//
//   return catCounts.push(...flatCat);
// };

export const parseDataSend = (data, markerId) => {
  let d = { ...data };
  const isUpdate = typeof markerId !== 'undefined';

  if(!!d.product_from_ids.length) {
    if(d.type_choose_product_from_ids === 'product') {
      const parseFrom = d.product_from_ids.split(',').map(item => item).filter(item => !item.includes('cat_'));
      d.from = parseFrom;
    }
    if(d.type_choose_product_from_ids === 'category') {
      const parseFrom = d.product_from_ids.split(',').map(item => item).filter(item => item.includes('cat_'));
      d.from = parseFrom;
    }
  } else {
    d.from = [];
  }

  if(!!d.product_to_ids.length) {

    if(d.type_choose_product_to_ids === 'product') {
      const parseTo = d.product_to_ids.split(',').map(item => item).filter(item => !item.includes('cat_'));
      d.to = parseTo;
    }
    if(d.type_choose_product_to_ids === 'category') {
      const parseTo = d.product_to_ids.split(',').map(item => item).filter(item => item.includes('cat_'));
      d.to = parseTo;
    }
  } else {
    d.to = [];
  }

  delete d.product_from_ids;
  delete d.product_to_ids;

  delete d.categoriesA;
  delete d.categoriesB;

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  if(!!d.data.cat_to_ids.length) {
    const parseFrom = d.data.cat_to_ids.map(item => `cat_${item}`);
    d.data.cat_to_ids = parseFrom;
  }

  if(!!d.data.cat_from_ids.length) {
    const parseTo = d.data.cat_from_ids.map(item => `cat_${item}`);
    d.data.cat_from_ids = parseTo;
  }
  return d;
};
