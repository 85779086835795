import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createProductConsultation,
  getProductConsultationEdit,
  editProductConsultation,
  removeProductConsultation,
} from '../../lib/api/product_consultation';
import { getCitiesAll } from "../../lib/api/cities";
import { getTopicsAll } from "../../lib/api/devices";
import { getConsultationsManagersAll } from "../../lib/api/specialists";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pcId) => Promise.allSettled([getDomains(), pcId
&& getProductConsultationEdit(pcId), getTopicsAll(), getConsultationsManagersAll(), getCitiesAll()])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      return ({
          domains: res[0]?.value?.data,
          fields: pcId && res[1]?.value?.data,
          topics: res[2]?.value?.data?.data,
          specialists: res[3]?.value?.data?.data,
          cities: res[4]?.value?.data,
      })
  });

export const create = createProductConsultation;
export const edit = editProductConsultation;
export const remove = removeProductConsultation;

export const parseDataSend = (data, pfId) => {
  let d = { ...data };
  const isUpdate = typeof pfId !== 'undefined';

  d = parseAll(d, !isUpdate, ['topic_id']);

  const parseBookedFor = new Date(d.booked_for).toISOString();
  d.booked_for = parseBookedFor

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  d.consultation_datetime = new Date(d.consultation_datetime)
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
