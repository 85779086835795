const checkPermission = (permissionName) => {
    const roles = JSON.parse(localStorage.getItem('roles'))?.includes('super-admin');
    if(roles) {
        return true;
    }

    const permissions = JSON.parse(localStorage.getItem('permissions'));

    if(typeof permissionName !== 'undefined' && !!permissions?.length) {
        return permissions?.some(itemSome => itemSome === permissionName);
    }
}


export default checkPermission;