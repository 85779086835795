import React from "react";
import { Link } from "react-router-dom";
import Item from '../Item'
import UserInfo from "../../../components/ui/UserInfo";
import getTZtoDate from "../../../lib/getTZtoDate";
import checkPermission from "../../../lib/checkPermission";
import s from "./orderStyle.module.scss";
import Loader from "../../../components/ui/Loader";

const UserProfile = ({ data, loadBonus, bonusData }) => {
    return(
        <Item title="Профиль пользователя">
            {!loadBonus ? (
                <UserInfo>
                    <li>
                        <div>ID</div>
                        {data?.user?.id
                            ? checkPermission('admin.user.edit')
                                ? <strong><Link to={`/app/user/client/update/${data?.user?.id}`}>{data?.user?.id}</Link></strong>
                                : <strong>{data?.user?.id ? data?.user?.id : '--' }</strong>
                            : (
                                <strong>
                                    <div className={s.no_user} />
                                </strong>
                            )}
                    </li>
                    <li>
                        <div>Фамилия</div>
                        <strong>
                            {data?.user?.surname
                                ? data?.user?.surname
                                : bonusData?.name
                                    ? bonusData?.name?.split(" ")[0]
                                    : '---'
                            }
                        </strong>
                    </li>
                    <li>
                        <div>Имя</div>
                        <strong>
                            {data?.user?.name
                                ? data?.user?.name
                                : bonusData?.name
                                    ? bonusData?.name?.split(" ")[1]
                                    : '---'
                            }
                        </strong>
                    </li>
                    <li>
                        <div>Отчество</div>
                        <strong>
                            {data?.user?.patronymic
                                ? data?.user?.patronymic
                                : bonusData?.name
                                    ? bonusData?.name?.split(" ")[2]
                                    : '---'
                            }
                        </strong>
                    </li>
                    <li>
                        <div>Телефон</div>
                        <strong>
                            {data?.user?.phone
                                ? data?.user?.phone
                                : bonusData?.phone
                                    ? bonusData?.phone
                                    : '---'
                            }
                        </strong>
                    </li>
                    <li>
                        <div>E-mail</div>
                        <strong>
                            {data?.user?.email ? data?.user?.email : '---'}
                        </strong>
                    </li>
                    <li>
                        <div>Дата регистрации</div>
                        <strong>
                            {data?.user?.created_at ? getTZtoDate(data?.user?.created_at, false) : '---'}
                        </strong>
                    </li>
                    {bonusData && data?.domain_id?.toString() !== '2' && (
                        <>
                            <li>
                                <div>Номер карты ПЛ</div>
                                <strong>{bonusData?.card_number}</strong>
                            </li>
                            <li>
                                <div>Количество бонусов</div>
                                <strong>{bonusData?.balance?.active ? bonusData?.balance?.active : 0}</strong>
                            </li>
                            <li>
                                <div>Заблокированно бонусов</div>
                                <strong>{bonusData?.balance?.blocking ? bonusData?.balance?.blocking : 0}</strong>
                            </li>
                        </>
                    )}
                </UserInfo>
            ) : <Loader />}
        </Item>
    );
};

export default UserProfile;
