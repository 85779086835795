import { getEmailSubscription, getEmailSubscriptionSources } from "../../lib/api/email_subscription";

export const getFiltersData = () => Promise.allSettled([
    getEmailSubscriptionSources(),
])
    .then((res) => {
        const parseSource = res[0]?.value?.data?.map(item => ({id: item, title: item}))
        return ({
            sources: parseSource
        })
    });

export const getData = getEmailSubscription;