import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { VALIDATION } from '../../../const/text';
import Group from '../../../components/ui/FormEditView/Group';
import ColLeft from '../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../components/ui/FormEditView/ColRight';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH } from "../../../const/view";

const TabMetaTeg = ({ index = 0, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">
                    {/* meta_title_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-title-ua">Мета тэг title УКР:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                className='form-input'
                                name="meta_title_ua"
                                type="text"
                                id="art-meta-title-ua"
                                placeholder="Введите анонс"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register}
                            />
                        </ColRight>
                    </Group>

                    {/* meta_title_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-title-ru">Мета тэг title РУ:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                className='form-input'
                                name="meta_title_ru"
                                type="text"
                                id="art-meta-title-ru"
                                placeholder="Введите анонс"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register}
                            />
                        </ColRight>
                    </Group>

                    {/* meta_desc_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-desc-ua">Мета тэг description УКР:</label>
                        </ColLeft>
                        <ColRight>
                              <textarea
                                  name="meta_desc_ua"
                                  className="form-input"
                                  id="art-meta-desc-ua"
                                  placeholder="Введите текст"
                                  rows="4"
                                  ref={form.register}
                              />
                        </ColRight>
                    </Group>

                    {/* meta_desc_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-desc-ru">Мета тэг description РУ:</label>
                        </ColLeft>
                        <ColRight>
                              <textarea
                                  name="meta_desc_ru"
                                  className="form-input"
                                  id="art-meta-desc-ru"
                                  placeholder="Введите текст"
                                  rows="4"
                                  ref={form.register}
                              />
                        </ColRight>
                    </Group>

                    {/* meta_keywords_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-keywords-ua">Мета тэг keywords УКР:</label>
                        </ColLeft>
                        <ColRight>
                              <textarea
                                  name="meta_keywords_ua"
                                  className="form-input"
                                  id="art-meta-keywords-ua"
                                  placeholder="Введите текст"
                                  rows="4"
                                  ref={form.register}
                              />
                        </ColRight>
                    </Group>

                    {/* meta_keywords_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-meta-keywords-ru">Мета тэг keywords РУ:</label>
                        </ColLeft>
                        <ColRight>
                              <textarea
                                  name="meta_keywords_ru"
                                  className="form-input"
                                  id="art-meta-keywords-ru"
                                  placeholder="Введите текст"
                                  rows="4"
                                  ref={form.register}
                              />
                        </ColRight>
                    </Group>

                </Form>

                {formProps.load && <Loader />}
            </div>
        </form>
    );
};

export default TabMetaTeg;
