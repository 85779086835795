import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import useEdit from '../../lib/crud/useEdit';
import {
    getData, parseDataGet, parseDataSend, create, edit, remove
} from './api';
import { Editor } from "@tinymce/tinymce-react";
import Title from "../../components/ui/FormEditView/Title";
import Group from "../../components/ui/FormEditView/Group";
import ColLeft from "../../components/ui/FormEditView/ColLeft";
import ColRight from "../../components/ui/FormEditView/ColRight";
import cn from "classnames";
import { INPUT_MAX_LENGTH } from "../../const/view";
import { VALIDATION } from "../../const/text";
import s from "./Template.module.scss";
import {DEVELOPMENT_ENVIRONMENT, TINY_API_KEY} from "../../const/api";
import {Helmet} from "react-helmet";

const TemplateEditView = () => {
    const { id: templateID } = useParams();
    const formH = useForm();
    const {
        handleSubmit,
        reset,
        errors,
        register,
        watch,
    } = formH;

    const { data, load, submitHandler } = useEdit({
        targetID: templateID,
        // updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/template/not-found',
            remove: `/app/template${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/template${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Шаблон создан',
            edit: 'Шаблон обновлен',
            remove: 'Шаблон удален',
        },
    });

    const formProps = {
        templateID,
        data,
        handleSubmit,
        submitHandler,
        formH,
        load
    };

    const initialValue = formProps?.data?.fields?.text ? formProps?.data?.fields?.text : '';

    const [value, setValue] = useState(initialValue ?? '');

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Template edit`} />
            {templateID
                ? (
                    <Title>
                        Шаблон -
                        <small>{data && data?.fields?.name}</small>
                    </Title>
                )
                : <Title>Новый шаблон</Title>}

            {/* name */}
            <Group attrs={{ className: s.block_input }}>
                <ColLeft>
                    <label className="form-label" htmlFor="temp-name">Название шаблона*:</label>
                </ColLeft>
                <ColRight>
                    <input
                        name="name"
                        className={cn('form-input', {
                            'is-error': errors.name,
                        })}
                        type="text"
                        id="temp-name"
                        placeholder="Введите текст"
                        maxLength={INPUT_MAX_LENGTH}
                        defaultValue={data?.fields?.name}
                        ref={register({
                            required: VALIDATION.req.required,
                        })}
                    />
                    {errors.name && <p className="form-input-hint">{errors.name.message}</p>}
                </ColRight>
            </Group>

            {/* is_active */}
            <Group attrs={{ className: s.block_input }}>
                <ColLeft />
                <ColRight>
                    <label className="form-switch">
                        <input
                            name="is_active"
                            type="checkbox"
                            id="temp-status-active"
                            defaultChecked={data?.fields?.is_active}
                            ref={register}
                        />
                        <i className="form-icon" />
                        {' '}
                        Статус активности
                    </label>
                </ColRight>
            </Group>
            <div>
                <Editor
                    apiKey={TINY_API_KEY}
                    initialValue={initialValue}
                    value={value}
                    onEditorChange={(newValue) => setValue(newValue)}
                    init={{
                        width: '100%',
                        height: '900px',
                        menubar: false,
                        language: 'ru',
                        extended_valid_elements: 'script[language|type|src]',
                        toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',
                        image_advtab: true,

                        file_picker_callback: function (cb) {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {
                                const file = this.files[0];

                                const reader = new FileReader();
                                reader.onload = function () {

                                    const id = 'blobid' + (new Date()).getTime();
                                    const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                    const base64 = reader.result.split(',')[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };

                            input.click();
                        },
                        plugins: ['link', 'code', 'lists', 'preview'],
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                />
                <input type="text" name="text" value={value} ref={register} style={{ display: 'none' }} readOnly />
            </div>
        </div>
    );
};

export default TemplateEditView;