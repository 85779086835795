export default (editor, opts = {}) => {
    const bm = editor.BlockManager;
    const style = `
        <style>
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h3.h3-native {
                    font-size: 24px !important;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `<h3 class="h3-native d-flex align-center justify-content-center mb-2 mb-lg-4"><span>Заголовок H3</span></h3>${style}`,
    })
};