import {
  CREDIT_OFFER_DELETE,
  CREDIT_OFFER_EDIT, CREDIT_OFFER_GET_EDIT, CREDIT_OFFER_CREATE, CREDIT_OFFERS_GET,
} from '../../const/api';

import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getCreditOffers = (params) => fetchAx({
  url: CREDIT_OFFERS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getCreditOffersAll = () => fetchAx({
  url: CREDIT_OFFERS_GET,
  method: 'GET',
});

export const createCreditOffer = (data) => fetchAx({
  url: CREDIT_OFFER_CREATE,
  method: 'POST',
  data,
});

export const getCreditOfferEdit = (id) => fetchAx({
  url: CREDIT_OFFER_GET_EDIT.join(id),
  method: 'GET',
});

export const editCreditOffer = (id, data) => fetchAx({
  url: CREDIT_OFFER_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeCreditOffer = (id) => fetchAx({
  url: CREDIT_OFFER_DELETE.join(id),
  method: 'DELETE',
});
