import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, remove } from "./api";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";

const DevicesList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Device list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Назва УКР', 'Назва РУ', 'Дата від', 'Дата до', 'Позиція', 'Статус активності'
                    ]}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={6}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`device-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Пристрій видалено',
                                    }}
                                    editLink={`/app/device/update/${e.id}`}
                                    permissionRemove='admin.consultations.topics.destroy'
                                    permissionEdit='admin.consultations.topics.edit'
                                >
                                    <td>
                                        {checkPermission('admin.consultations.topics.edit')
                                            ? <Link to={`/app/device/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title_ua || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title_ru || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.starts_at ? getTZtoDate(e.starts_at, true) : '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.finish_at ? getTZtoDate(e.finish_at, true) : '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.position || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                      </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={6}>Немає пристроїв</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default DevicesList;
