import React, {useEffect} from 'react';
import {uuid} from 'uuidv4';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {VALIDATION} from '../../const/text';
import {INPUT_MAX_LENGTH} from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import {
    create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import useCreditOffersProduct from "../../lib/hooks/useCreditOffersProducts";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import TreeCredit from "../../components/ui/TreeCredit";
import s from '../InstallmentManagmentEditView/InstallmentManagmentEditView.module.scss';
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import useDomainCategoriesProducts from "../../lib/hooks/useDomainCategoriesProducts";

const CreditOffersEditView = () => {
    const {id: creditID} = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
        watch
    } = formH;

    const bank_id = watch('bank_id');
    const credit_type_id = watch('credit_type_id');
    const domainWatch = watch('domain_id');

    const {load, data, submitHandler} = useEdit({
        targetID: creditID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/credit-offers/not-found',
            remove: `/app/credit-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/credit-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Кредитное предложение создано',
            edit: 'Кредитное предложение обновлено',
            remove: 'Кредитное предложение удалено',
        },
    });

    const {action, state} = useDomainCategoriesProducts({
        defaultValues: data?.fields,
    });

    useEffect(() => {
        if (data?.fields?.tf_product_id) {
            state.setBankProductId(data?.fields?.tf_product_id);
        }
    }, [data?.fields?.tf_product_id]);

    return (
        <div className={s.inner}>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Credit offer edit`}/>
            <div className={s.block}>
                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide"/>

                    {/* is_active */}
                    <Group attrs={{className: s.mb20}}>
                        <ColFull>
                            <CheckboxSwitch
                                id="im-is-active"
                                name="is_active"
                                label="Активность"
                                formH={formH}
                                defaultChecked={data?.fields?.data?.is_active}
                            />
                        </ColFull>
                    </Group>

                    {/* title */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Название рассрочки" id="credit-offer-name">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.title,
                                    })}
                                    type="text"
                                    placeholder="Название рассрочки"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="title"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="credit-offer-name"
                                />
                            </InputWrapper>
                            {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
                        </ColFull>
                    </Group>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {data
                                        ? (
                                            <select
                                                className={cn(s_Select.select, {
                                                    'is-error': errors.domain_id,
                                                })}
                                                name="domain_id"
                                                id="credit-offer-domain"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                                onClick={action.getDomain}
                                            >
                                                <option value={null}>Домен не выбран</option>
                                                {data && !!data.domains && data.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading"/>
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="im-domain">Домен</label>
                                </div>
                            </div>
                            {errors.domain_id
                            && <p className="form-input-hint">{errors.domain_id.message}</p>}
                        </ColFull>
                    </Group>

                    {/* bank_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {data
                                        ? (
                                            <select
                                                className={cn(s_Select.select, {
                                                    'is-error': errors.bank_id,
                                                })}
                                                name="bank_id"
                                                id="im-bank-id"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                <option value={null}>Банк не выбран</option>
                                                {data && !!data.banks && data?.banks.map((bank) => (
                                                    <option
                                                        key={`${uuid()}`}
                                                        value={bank.id}
                                                        selected={(+bank_id || data?.fields?.bank_id) === bank.id}
                                                    >
                                                        {bank.title_ru || bank.title_ua}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading"/>
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="im-bank-id">Банк</label>
                                </div>
                            </div>
                            {errors.bank_id && <p className="form-input-hint">{errors.bank_id.message}</p>}
                        </ColFull>
                    </Group>

                    {/* credit_type_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        className={cn(s_Select.select, {
                                            'is-error': errors.credit_type_id,
                                        })}
                                        name="credit_type_id"
                                        id="credit-type-id"
                                        ref={register({
                                            required: VALIDATION.select.required,
                                        })}
                                    >
                                        <option value="">Выберите тип</option>
                                        {data && !!data.creditTypes && data.creditTypes.data.map((creditType) => (
                                            <option
                                                key={`${uuid()}`}
                                                value={creditType.id}
                                                selected={(+credit_type_id || data?.fields?.credit_type_id) === creditType.id}
                                            >
                                                {creditType.title}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_Select.label} htmlFor="credit-type-id">Тип кредита</label>
                                </div>
                            </div>
                            {errors.credit_type_id &&
                            <p className="form-input-hint">{errors.credit_type_id.message}</p>}
                        </ColFull>
                    </Group>

                    {/* bank_code */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Введите код" id="bank-code">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.bank_code,
                                    })}
                                    type="text"
                                    placeholder="Введите код"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="bank_code"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="bank-code"
                                />
                            </InputWrapper>
                            {errors.bank_code && <p className="form-input-hint">{errors.bank_code.message}</p>}
                        </ColFull>
                    </Group>

                    {/* min_sum */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Минимальная сумма кредита" id="min-sum">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.min_sum,
                                    })}
                                    type="text"
                                    placeholder="Минимальная сумма кредита"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="min_sum"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="min-sum"
                                />
                            </InputWrapper>
                            {errors.min_sum && <p className="form-input-hint">{errors.min_sum.message}</p>}
                        </ColFull>
                    </Group>

                    {/* max_sum */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Максимальная сумма кредита" id="max-sum">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.max_sum,
                                    })}
                                    type="text"
                                    placeholder="Максимальная сумма кредита"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="max_sum"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="max-sum"
                                />
                            </InputWrapper>
                            {errors.max_sum && <p className="form-input-hint">{errors.max_sum.message}</p>}
                        </ColFull>
                    </Group>
                </Form>
            </div>
            <TreeCredit
                state={state}
                action={action}
                dataAll={data}
                formH={formH}
                domainId={domainWatch}
            />
        </div>
    );
};

export default CreditOffersEditView;