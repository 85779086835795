import { CLIENT_ID, CLIENT_SECRET, DOMAIN_API } from "../../const/api";
import { oauthTokenRefreshUser } from "../api/auth";
import removeLocalStorage from "../helpers/removeLocalStorage";

export const refreshToken = async (cb) => {
    if(localStorage?.tokenData) {
        const tokenData = JSON.parse(localStorage.tokenData);
        const data = {
            grant_type: 'refresh_token',
            refresh_token: tokenData.refresh_token,
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
        }
        if (localStorage.getItem('canRefresh') === 'true') {
            localStorage.setItem('canRefresh', 'false')
            await oauthTokenRefreshUser(data)
                .then(async (res) => {
                    localStorage.setItem('canRefresh', 'true')
                    tokenData.refresh_token = res.data.refresh_token;
                    tokenData.token = res.data.access_token;
                    tokenData.expires_in = Number(((new Date() / 1000) + res.data.expires_in).toFixed());
                    localStorage.setItem('tokenData', JSON.stringify(tokenData));
                    if (cb) {
                        await cb()

                    } else {
                        window.location.reload()
                    }
                    return res;
                }).catch((err) => {
                    let currentUrl = `${window.location.pathname}${window.location.search}`;
                    localStorage.setItem('followingLink', currentUrl);
                    removeLocalStorage()
                    window.location.href = `${DOMAIN_API}/login`
                }).finally(() => localStorage.setItem('canRefresh', 'true'));
        }
    }
}