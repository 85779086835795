import { parseAll } from "../../lib/api/_parseApi";
import {
  createSection,
  getSectionEdit,
  editSection,
  removeSection,
} from "../../lib/api/sections";
import { refreshToken } from "../../lib/crud/refreshToken";
import {getRoles} from "../../lib/api/roles";

export const getData = (sectionId) => Promise.allSettled([
  sectionId && getSectionEdit(sectionId), getRoles(), getSectionEdit(1)
])
  .then(async (res) => {
    const findStatus401 = res?.find(item => item?.reason?.response?.status === 401)

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: sectionId && res[0]?.value?.data,
      roles : res[1]?.value?.data?.data,
      allSections : res[2]?.value?.data,
    })
  });

export const create = createSection;
export const edit = editSection;
export const remove = removeSection;

export const parseDataSend = (data, catId) => {
  let d = { ...data };
  const isUpdate = typeof catId !== 'undefined';

  d = parseAll(d, !isUpdate, []);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
