import {
  BANNER_CATALOG_GET_ALL,
  BANNER_CATALOG_GET,
  BANNER_CATALOG_CREATE,
  BANNER_CATALOG_GET_EDIT,
  BANNER_CATALOG_EDIT, BANNER_CATALOG_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBannerCatalog = (params) => fetchAx({
  url: BANNER_CATALOG_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getBannerCatalogAll = () => fetchAx({
  url: BANNER_CATALOG_GET_ALL,
  method: 'GET',
});

export const createBannerCatalog = (data) => fetchAx({
  url: BANNER_CATALOG_CREATE,
  method: 'POST',
  data,
});

export const getBannerCatalogEdit = (id) => fetchAx({
  url: BANNER_CATALOG_GET_EDIT.join(id),
  method: 'GET',
});

export const editBannerCatalog = (id, data) => fetchAx({
  url: BANNER_CATALOG_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBannerCatalog = (id) => fetchAx({
  url: BANNER_CATALOG_DELETE.join(id),
  method: 'DELETE',
});
