import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import s_Table from "../orderTable.module.scss";
import Button from "../../../../components/ui/Button";
import { createCancelVariant, getCancelVariants, removeCancelVariant } from "../../../../lib/api/cancel_variants";
import { toast } from "react-toastify";
import Group from "../../../../components/ui/FormEditView/Group";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { LIST_PER_PAGE } from "../../../../const/view";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";

const ModalCancelVariant = ({ active, setActive, title, cancelVariants, setCancelVariants }) => {
    const [cancelVariantsCreate, setCancelVariantsCreate] = useState({
        title_ru: '',
        title_ua: '',
        is_active: true,
    });

    useEffect(() => {
        getCancelVariants({ per_page: LIST_PER_PAGE.xxxl })
            .then((res) => {
                setCancelVariants(res.data.data);
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getCancelVariants({ per_page: LIST_PER_PAGE.xxxl })
                            .then((res) => {
                                setCancelVariants(res.data.data);
                            })
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
    },[]);


    const handleRemove = async (i, id) => {

        await removeCancelVariant(id).then((res) => {
            toast('Причина отказа удалена', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await removeCancelVariant(id).then((res) => {
                        toast('Причина отказа удалена', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        })

        setCancelVariants((prev) => {
            const productsData = [...prev];

            if (productsData[i]) {
                productsData.splice(i, 1);
            }

            return productsData;
        });
    };

    const handleCreate = async () => {
        await createCancelVariant(cancelVariantsCreate).then((res) => {
            console.log(res);
            toast('Причина отказа создана', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await createCancelVariant(cancelVariantsCreate).then((res) => {
                        console.log(res);
                        toast('Причина отказа создана', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });

        await getCancelVariants()
            .then((res) => setCancelVariants(res.data.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getCancelVariants()
                            .then((res) => setCancelVariants(res.data.data))
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
        setCancelVariantsCreate({
            title_ru: '',
            title_ua: '',
            is_active: true,
        })
    };

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                    </div>
                </div>
                <div>
                    {!!cancelVariants.length && (
                        <div className={s_Modal.modal__box}>
                            <div className={s_Table.table__inner}>
                                <div className={s_Table.table__wrapper}>
                                    <table className={s_Table.table}>
                                        <thead className={s_Table.thead}>
                                        <tr>
                                            <th>
                                                <div>ID</div>
                                            </th>
                                            <th>
                                                <div>Название ру</div>
                                            </th>
                                            <th>
                                                <div>Статус активности</div>
                                            </th>
                                            <th> </th>
                                        </tr>
                                        </thead>
                                        <tbody className={s_Table.tbody}>
                                        {cancelVariants.map((e, ind) => (
                                            <tr key={e.id}>
                                                <td>
                                                    <div>{e.id}</div>
                                                </td>
                                                <td>
                                                    <div>{e.title_ru}</div>
                                                </td>
                                                <td>
                                                    <div>{e.is_active ? 'активна' : 'отключена'}</div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button type="button" className={s_Table.btn__delete} onClick={() => handleRemove(ind, e.id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={s_Modal.wrapper__input}>
                        <div className={s_Modal.suptitle}>Создать причину отказа</div>

                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <InputWrapper label="Название причины отказа" id="cen-title-ru">
                                    <input
                                        className={s.input}
                                        type="text"
                                        placeholder="Название причины отказа"
                                        name="title_ru"
                                        id="cen-title-ru"
                                        value={cancelVariantsCreate.title_ru}
                                        onChange={(e) => setCancelVariantsCreate(prev => ({
                                            ...prev,
                                            title_ru: e.target.value,
                                            title_ua: e.target.value,
                                        }))}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>
                    </div>
                </div>

                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{ justifyContent: 'space-between' }}>
                        <div>
                            <Button purple onClick={handleCreate} disabled={!cancelVariantsCreate.title_ru.length}>Создать</Button>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCancelVariant;