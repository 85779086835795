import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { sendSms } from "../../../../lib/api/order";
import Button from "../../../../components/ui/Button";
import s_Modal from "./modal.module.scss";
import sSelect from "../orderStyle.module.scss";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";

const ModalSendSMS = ({ active, setActive, title, formProps }) => {
    const [type, setType] = useState(null);

    const handleOnChange = ({ target }) => {
        const result = formProps.data.smsList.find(item =>  item.key === target.value);
        setType(result);
    }
    const handleSubmit = async () => {
        if(formProps?.data?.fields?.user_id) {
            await sendSms({type_id: type.key, user_id: formProps?.data?.fields?.user_id, order_id: formProps?.orderID }).then((res) => {
                toast("Отправлено", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(res);
                setActive(false);
            }).catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await sendSms({type_id: type.key, user_id: formProps?.data?.fields?.user_id, order_id: formProps?.orderID }).then((res) => {
                            toast("Отправлено", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            console.log(res);
                            setActive(false);
                        })
                    })
                } else {
                    await errorMessage(err, '')
                }
            }).finally(() => setActive(false));
        } else {
            await sendSms({type_id: type.key, order_id: formProps?.orderID}).then((res) => {
                toast("Отправлено", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(res);
                setActive(false);
            }).catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        sendSms({type_id: type.key, order_id: formProps?.orderID}).then((res) => {
                            toast("Отправлено", {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            console.log(res);
                            setActive(false);
                        })
                    })
                } else {
                    await errorMessage(err, '')
                }
            }).finally(() => setActive(false));
        }
    }

    useEffect(() => {
        if(!!formProps?.data?.smsList?.length) {
            setType(formProps?.data?.smsList[0])
        }
    }, [formProps?.data?.smsList]);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                    </div>
                </div>
                <div>
                    <div className={sSelect.select__inner}>
                        <div className={sSelect.select__wrapper}>
                            <select
                                name="sms"
                                className={sSelect.select}
                                id="to_sms"
                                onChange={handleOnChange}
                            >
                                {formProps?.data?.smsList?.map((item, index) => (
                                    <option value={item.key} key={index}>{item.name}</option>
                                ))}
                            </select>
                            <label className={sSelect.label} htmlFor="to_sms">Тип СМС</label>
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                        <div className={s_Modal.modal__buttons}>
                            <div>
                                <Button purple onClick={handleSubmit}>Отправить</Button>
                            </div>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSendSMS;