import { useEffect, useState } from "react";
import qs, { stringify } from "query-string";
import errorMessage from "../errorMessage";
import { refreshToken } from "./refreshToken";
import { getNotices } from "../api/notices";
import { setNoticeChange } from "../../store/notice/action";
import { useDispatch } from "react-redux";

const useList = ({
  api, apiFilters, location, navigate, isMounted, filters = {}
}) => {
  const [rest, setRest] = useState(null);
  const [filtersData, setFiltersData] = useState(null);
  const [load, setLoad] = useState(true);
  const [urlParams, setUrlParams] = useState(null);
  const dispatch = useDispatch();
  // load filters data
  useEffect(() => {
    if (!apiFilters) return;

    (async function () {
      try {
        if (!isMounted) return;
        // get data
        const d = await apiFilters();
        // set data
        setFiltersData(d);
      } catch (err) {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            const d = await apiFilters();
            // set data
            setFiltersData(d);
          })
        } else {
          await errorMessage(err, '')
        }
      }
    }());
  }, []);

  // load data
  useEffect(() => {
    const params = qs.parse(location.search);
    let tempFilters = {};
    if(filters) {
      tempFilters = filters;
      Object.entries(tempFilters)?.map(a=>Object.entries(a[1])?.filter(b=>b[1].length)?.length? a : delete tempFilters[a[0]]);
      setUrlParams({...params, ...filters, per_page: localStorage.getItem('per_page')});
    }

    (async function () {
      if (!load) setLoad(true);
      try {
        if (!isMounted) return;
        // get data
        const d = await api({...params, ...tempFilters, per_page: localStorage.getItem('per_page')});
        await getNotices()
            .then((res) => dispatch(setNoticeChange(res.data)))
            .catch((err) => errorMessage(err, 'Notice'))
        // set data
        setRest(d.data);
      } catch (err) {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            const d = await api({...params, ...tempFilters, per_page: localStorage.getItem('per_page')});

            // set data
            setRest(d.data);

            if (apiFilters) {
              const dFilter = await apiFilters();
              // set data
              setFiltersData(dFilter);
            }

            await getNotices()
                .then((res) => dispatch(setNoticeChange(res.data)))
          })
        } else {
          await errorMessage(err, '')
        }
      }

      setLoad(false);
    }());
  }, [location]);

  // push url with params
  function pushUrlParams(params) {
    const cloneUrlParams = { ...urlParams };
    delete cloneUrlParams.page;

    const paramsStr = `?${ stringify(
      {
        ...cloneUrlParams,
        ...params,
      },
      {
        skipEmptyString: true,
      },
    )}`;
    navigate(paramsStr);
  }

  return {
    rest,
    filtersData,
    load,
    setLoad,
    urlParams,
    setRest,
    pushUrlParams,
  };
};

export default useList;
