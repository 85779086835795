/**
 *
 *
 * @param id
 * @param arrId
 * @param obj
 * @returns {*}
 */
const getObjByArrId = (id, arrId, obj) => {
  const i = arrId.indexOf(id);

  return i > -1 ? obj[i] : {};
};

export default getObjByArrId;
