export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.col {
                min-height: 50px;
            }
            
            div.scale-custom {
                overflow: hidden;
            }
            
            div.scale-custom > img,
            div.scale-custom > picture {
                transform: scale(1);
                transition: all 0.5s;
            }
            div.scale-custom > img:hover,
            div.scale-custom > picture:hover {
                transform: scale(1.5);
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row row-cols-1 image-five-scale-left">
                <div class="col col-4">
                    <div class="row row-cols-1">
                        <div class="col col-12 overflow-hidden">
                            <div class="scale-custom">
                                <img src="https://via.placeholder.com/370x480" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="row row-cols-1">
                        <div class="col col-12 overflow-hidden">
                            <div class="scale-custom">
                                <img src="https://via.placeholder.com/370x230" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-8">
                    <div class="row row-cols-2 overflow-hidden">
                        <div class="col col-6">
                            <div class="scale-custom">
                                <img src="https://via.placeholder.com/370x230" alt="" />
                            </div>
                        </div>
                        <div class="col col-6 overflow-hidden">
                            <div class="scale-custom">
                                <img src="https://via.placeholder.com/370x230" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="row row-cols-1">
                        <div class="col col-12 overflow-hidden">
                            <div class="scale-custom">
                                <img src="https://via.placeholder.com/765x485" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ${style}
        `,
    })
};