import {
  REDIRECT_CREATE, REDIRECT_GET_EDIT, REDIRECT_EDIT, REDIRECT_DELETE, REDIRECTS_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

// all
export const getRedirects = (params) => fetchAx({
  url: REDIRECTS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params
  }
});


export const createRedirect = (data) => fetchAx({
  url: REDIRECT_CREATE,
  method: 'POST',
  data,
});

export const getRedirectEdit = (id) => fetchAx({
  url: REDIRECT_GET_EDIT.join(id),
  method: 'GET',
});

export const editRedirect = (id, data) => fetchAx({
  url: REDIRECT_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeRedirect = (id) => fetchAx({
  url: REDIRECT_DELETE.join(id),
  method: 'DELETE',
});
