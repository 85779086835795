import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {PubSub as ps} from 'pubsub-js';
import cn from 'classnames';
import {EDIT} from '../../../const/pubsub';
import fetchAx from "../../../lib/fetchAx";
import {DOMAIN_API} from "../../../const/api";

const BtnAdminGroupEdit = ({back, update = false, newData, setActiveModal, data}) => {
    const [disable, setDisable] = useState(true);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    // pub / sub
    useEffect(() => {
        const tokenEnable = ps.subscribe(EDIT.enable, () => setDisable(false));
        const tokenLoad = ps.subscribe(EDIT.load, (n, isL) => setLoad(isL));

        return () => {
            ps.unsubscribe(tokenEnable);
            ps.unsubscribe(tokenLoad);
        };
    }, []);

    function saveHandler() {
        let iternalPhone = !!newData.internal_phone ? newData.internal_phone : null
        if(+newData?.internal_phone !== data?.fields?.internal_phone ) {
            fetchAx({
                url: `${DOMAIN_API}/api/admin/admins?lang=ua&internal_phone=${iternalPhone}`,
                method: 'GET',
            }).then(r => {
                if (r?.data?.meta?.total === 0) {
                    ps.publish(EDIT.save);
                } else {
                    setActiveModal(true)
                }
            })
        } else {
            ps.publish(EDIT.save);
        }
    }

    function applyHandler() {
        let iternalPhone = !!newData.internal_phone ? newData.internal_phone : null
        if(+newData?.internal_phone !== data?.fields?.internal_phone ) {
            fetchAx({
                url: `${DOMAIN_API}/api/admin/admins?lang=ua&internal_phone=${iternalPhone}`,
                method: 'GET',
            }).then(r => {
                if (r?.data?.meta?.total === 0) {
                    localStorage.setItem('apply', 'on');
                    ps.publish(EDIT.save);
                } else {
                    setActiveModal(true)
                }
            })
        } else {
            localStorage.setItem('apply', 'on');
            ps.publish(EDIT.save);
        }
    }


    return (
        <>
            {update && (
                <button
                    className="btn btn-link"
                    id="edit-btn"
                    type="button"
                    onClick={applyHandler}
                    disabled={disable || load}
                >
                    Применить
                </button>
            )}

            <button
                className="btn ml-1"
                type="button"
                onClick={() => navigate(back)}
                disabled={load}
            >
                Назад
            </button>

            <button
                className={cn('btn btn-primary ml-1', {loading: load})}
                id="save-btn"
                type="button"
                onClick={saveHandler}
                disabled={disable || load}
            >
                Сохранить
            </button>
        </>
    );
};

export default BtnAdminGroupEdit;
