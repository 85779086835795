import React from "react";
import s from "./SliderMui.module.scss";
import Slider from "@mui/material/Slider";

const SliderMui = ({
                       min = 2,
                       max = 25,
                       valueLabelDisplay = 'auto',
                       defaultValue = 6,
                       value,
                       onChange,
                       customStyleBlack = true
}) => {
    return (
        <Slider
            defaultValue={defaultValue}
            aria-label="Default"
            valueLabelDisplay={valueLabelDisplay}
            min={min}
            max={max}
            value={value}
            color={'info'}
            onChange={onChange}
            className={customStyleBlack ? s.slider_black : ''}
        />
    );
};

export default SliderMui;