import cn from "classnames";
import React from "react";
import './UserInfo.scss'

const UserInfo = ({ children, attrs }) => {
    const attrsMerge = {
        ...attrs,
        className: cn('order-info_list', attrs && attrs.className),
    };
    return (
        <ul {...attrsMerge}>{children}</ul>
    );
}

export default UserInfo