import React from 'react';
import s from "./DateRangeCustom.module.scss";
import s_input from "../InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import InputWrapper from "../InputWrapper";

const DateRangeCustom = ({ startDateName, endDateName, formH, urlParams, handlerOnChange, style }) => {
    return (
        <div className={s.wrap} style={style}>
            <InputWrapper id={`filter-table-${startDateName}`}>
                <input
                    className={s_input.input}
                    type="date"
                    maxLength={INPUT_MAX_LENGTH}
                    name={startDateName}
                    ref={formH.register}
                    id={`filter-table-${startDateName}`}
                    defaultValue={urlParams && urlParams[startDateName]}
                    onChange={handlerOnChange}
                />
            </InputWrapper>
            {!style?.height && <div>-</div>}
            <InputWrapper id={`filter-table-${startDateName}`}>
                <input
                    className={s_input.input}
                    type="date"
                    maxLength={INPUT_MAX_LENGTH}
                    name={endDateName}
                    ref={formH.register}
                    id={`filter-table-${endDateName}`}
                    defaultValue={urlParams && urlParams[endDateName]}
                    onChange={handlerOnChange}
                />
            </InputWrapper>
        </div>
    );
};

export default DateRangeCustom;