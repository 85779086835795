import React, { useState } from 'react';
import { uuid } from 'uuidv4';
import TableListViewTable from '../../common/TableListView/Table';
import TableListViewRow from '../../common/TableListView/Row';

import s from './TableItems.module.scss';
import { VALIDATION } from '../../../const/text';
import cn from "classnames";

const TableItem = ({
  formH = {},
  JDEinTable = true,
  titleVisible = true,
  title = '',
  isPrice = false,
  isDiscount = false,
  name = '',
  addName = '',
  list = [],
  thNames = [],
  func,
  searchVisible = true,
  typePrice = false
}) => {
  const [value, setValue] = useState('');
  const [searchList, setSearchList] = useState([]);

  const handleChange = (e) => {
    const val = e.target.value;
    setValue(val);
    setSearchList([]);

    if (val.length !== 0 && val.length > 3) {
      const temp = [];

      list.forEach((el) => {
        const listTitle = el?.jde?.toLowerCase();

        if (listTitle.includes(val.toLowerCase())) {
          temp.push(el);
          setSearchList(temp);
        }
      });
    } else {
      setSearchList([]);
    }
  };

  const renderList = () => {
    if (searchList && searchList.length !== 0) {
      return searchList.map((e, i) => (
        <>
          <TableListViewRow controlsOff key={`${uuid()}`}>
            <td style={{ display: 'none' }}>
              <input
                type="text"
                name={`${name}[${i}]${addName}`}
                value={+e.id}
                ref={formH.register}
                readOnly
              />
            </td>
            {JDEinTable
              && <td>{e.jde}</td>}
            <td>{e.title_ru || e.title_ua || e.title}</td>
            {typePrice && (
                <td>
                  <div>
                    <select
                        name={`${name}[${i}].price_type`}
                        className={cn('form-select', {
                          'is-error': formH.errors?.[name]?.[i]?.price_type,
                        })}
                        id={`${name}-select-item-price_type`}
                        ref={formH.register({
                          required: VALIDATION.select.required,
                        })}
                        defaultValue={e?.pivot?.price_type || 'promo'}
                    >
                      <option value="promo">Промо</option>
                      <option value="rrp">РРЦ</option>
                    </select>
                    {formH.errors?.[name]?.[i]?.price_type
                    && <p className="form-input-hint">{formH.errors?.[name]?.[i]?.price_type?.message}</p>}
                  </div>
                </td>
            )}
            {(isPrice || isDiscount) && (
              <td>
                <input
                  type="number"
                  className="form-input"
                  name={`${name}[${i}]${isPrice ? '.price' : ''}${isDiscount ? '.discount_percent' : ''}`}
                  placeholder={`${isPrice ? 'Введите цену' : ''}${isDiscount ? 'Введите процент скидки' : ''}`}
                  ref={formH.register({
                    required: VALIDATION.req.required,
                  })}
                />
                {isDiscount && (
                  <input
                    type="number"
                    className="form-input"
                    name={`${name}[${i}].discount_value`}
                    placeholder="Введите абсолютную скидку"
                    ref={formH.register({
                      required: VALIDATION.req.required,
                    })}
                  />
                )}
              </td>
            )}
            <td className={s.table_item__table__button}>
              <button
                type="button"
                className="btn btn-action s-circle mr-2"
                onClick={() => func.remove(e)}
              >
                <i className="icon icon-delete" />
              </button>
            </td>
          </TableListViewRow>

        </>
      ));
    }

    if (list && list.length !== 0) {
      return list.map((e, i) => (
        <TableListViewRow controlsOff key={`${uuid()}`}>
          <td style={{ display: 'none' }}>
            <input
              type="text"
              name={`${name}[${i}]${addName}`}
              value={+e.id}
              ref={formH.register()}
              readOnly
            />
          </td>
          {JDEinTable
            && <td>{e.jde || e}</td>}
          {titleVisible && <td>{e.title_ru || e.title_ua || e.title}</td>}
          {typePrice && (
              <td>
                <div>
                  <select
                      name={`${name}[${i}].price_type`}
                      className={cn('form-select', {
                        'is-error': formH.errors?.[name]?.[i]?.price_type,
                      })}
                      id={`${name}-select-item-price_type`}
                      ref={formH.register({
                        required: VALIDATION.select.required,
                      })}
                      defaultValue={e?.pivot?.price_type || 'promo'}
                  >
                    <option value="promo">Промо</option>
                    <option value="rrp">РРЦ</option>
                  </select>
                  {formH.errors?.[name]?.[i]?.price_type
                  && <p className="form-input-hint">{formH.errors?.[name]?.[i]?.price_type?.message}</p>}
                </div>
              </td>
          )}
          {isPrice && (
              <>
                <td>
                  <input
                      type="number"
                      className="form-input"
                      name={`${name}[${i}].price_cart`}
                      placeholder='Введите цену для козрины'
                      defaultValue={list.find((el) => el.id === e.id).pivot?.price_cart}
                      ref={formH.register()}
                  />
                </td>
                <td>
                  <input
                      type="number"
                      className="form-input"
                      name={`${name}[${i}].price_order`}
                      placeholder='Введите цену для заказа'
                      defaultValue={list.find((el) => el.id === e.id).pivot?.price_order}
                      ref={formH.register()}
                  />
                </td>
              </>
          )}
          {isDiscount && (
              <td>
                <input
                    type="number"
                    className="form-input"
                    name={`${name}[${i}].price_order`}
                    placeholder='Введите цену для заказа'
                    defaultValue={list.find((el) => el.id === e.id).pivot?.price_order}
                    ref={formH.register()}
                />
              </td>
          )}

          <td className={s.table_item__table__button}>
            <button
              type="button"
              className="btn btn-action s-circle mr-2"
              onClick={(event) => func.remove(event, e)}
            >
              <i className="icon icon-delete" />
            </button>
          </td>
        </TableListViewRow>
      ));
    }

    return <></>;
  };

  return (
    <>
      <div className="divider text-center" data-content={title} />
      {searchVisible &&
        <div className={s.table_item__search}>
        <input
            type="text"
            id={`${name}_id`}
            value={value}
            placeholder="Поиск..."
            onChange={handleChange}
            className="form-input"
        />
      </div>
      }
      <div className={s.table_item__table}>
        <TableListViewTable>
          <thead>
            <tr>
              {thNames.length !== 0 && thNames.map((el) => <th key={`${uuid()}`}>{el}</th>)}
            </tr>
          </thead>
          <tbody>
            {renderList()}
          </tbody>
        </TableListViewTable>
      </div>
    </>
  );
};

export default TableItem;
