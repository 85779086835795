import React, { Fragment } from 'react';
import s from "./TopPanelNew.module.scss";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
    return (
        <div className={s.breadcrumbs}>
            {items && items.map((item, index) => (
                <Fragment key={index}>
                    {item.url ? (
                        <>
                            {item.linkA
                                ? <a href={item.url}>{item.title}</a>
                                : <Link to={item.url}>{item.title}</Link>} {index !== (items?.length - 1) && '/'}
                        </>
                    ) : <span>{item.title}</span>}
                </Fragment>

            ))}
        </div>
    );
};

export default Breadcrumbs;