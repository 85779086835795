import cn from "classnames";
import React, { useState } from "react";
import useRemove from "../../../../lib/crud/useRemove";
import useIsMounted from "../../../../lib/useIsMounted";

import s from "./Row.module.scss";
import checkPermission from "../../../../lib/checkPermission";
import icon_edit from "../../../../assets/icons/icon_edit.svg";
import icon_delete from "../../../../assets/icons/icon_delete.svg";
import ModalUseRemove from "../../../ui/Modal/ModalUseRemove";
import {useSortable} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragHandle from "../../../ui/DragComponents/DragHandle";

const TableListViewRow = ({
  id,
  children,
  attrs,
  editLink,
  remove = { api: null, success: null, alert: null },
  permissionRemove,
  permissionEdit,
  disabled = false,
  controlsOff = false,
  deleteButton = true,
  showDeleteButton = false,
  tagA = true,
  setActiveModal,
  setActiveId,
}) => {
  const [activeRemoveModal, setActiveRemoveModal] = useState(false);
  const isMounted = useIsMounted();
  const { load, removeHandler } = useRemove({
    id,
    isMounted,
    confirmMessage: false,
    ...remove,
  });
  const attrsMerge = {
    ...attrs,
    className: cn(s.table_row, attrs && attrs.className,
      { [s.trLoad]: load },
      { [s.trDisabled]: disabled }),
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  };

  return (
    <tr ref={setNodeRef} style={style} {...attrsMerge}>
      <td>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <DragHandle {...attributes} {...listeners} />
          <span>
            {id || '---'}
          </span>
        </div>

      </td>
      {children}

      {showDeleteButton && (
          checkPermission(permissionRemove) && (
            <td>
              <div className="table-controls">
                  <button
                      type="button"
                      className={`btn btn-action s-circle ${s.btn_danger}`}
                      onClick={() => setActiveRemoveModal(true)}
                  >
                    <i className="icon icon-delete" />
                  </button>
              </div>
            </td>
      ))}

      {!controlsOff && (
        <td style={{ width: '75px' }}>
          <div className="table-controls">
            {editLink ? checkPermission(permissionEdit) && tagA ? (
                <a href={editLink || '/'} className="mr-2">
                  <img src={icon_edit} alt="Icon edit" />
                </a>
            ) : (
                <div className="mr-2" onClick={() => {
                  setActiveModal(true);
                  setActiveId(id);
                }}>
                  <img src={icon_edit} style={{ maxWidth: 'max-content' }} alt="Icon edit" />
                </div>
            ) : null}
            {deleteButton && (
                checkPermission(permissionRemove) && (
                    <button type="button" className={s.btn_danger} onClick={() => setActiveRemoveModal(true)}>
                      <img src={icon_delete} alt="icon delete" />
                    </button>
                )
            )}
          </div>
        </td>
      )}
      {activeRemoveModal && (
          <ModalUseRemove
              active={activeRemoveModal}
              setActive={setActiveRemoveModal}
              removeHandler={removeHandler}
          />
      )}
    </tr>
  );
};

export default TableListViewRow;
