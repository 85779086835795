import cn from 'classnames';
import React from 'react';

// import s from "./Group.module.scss";

const Group = ({ children, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className, 'form-group columns' ),
    // className: cn('form-horizontal', s.form, attrs && attrs.className)
  };

  return (
    <div {...attrsMerge}>{children}</div>
  );
};

export default Group;
