import fetchAx from '../fetchAx';
import {
    CONTACT_CREATE,
    CONTACTS_GET,
} from '../../const/api';

export const getContacts = (params) => fetchAx({
    url: CONTACTS_GET,
    method: 'GET',
    params: {
        ...params,
  },
});

export const createContact = (data) => fetchAx({
    url: CONTACT_CREATE,
    method: 'POST',
    data,
});

export const editContact = (id ,data) => fetchAx({
    url: CONTACT_CREATE,
    method: 'POST',
    data,
});
