import {Link, useLocation, useNavigate} from 'react-router-dom';
import React from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewPagination from '../../components/common/TableListView/Pagination';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewFooter from '../../components/common/TableListView/Footer';
import TableListViewPerPage from '../../components/common/TableListView/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, remove } from './api';
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const RedirectsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Redirect list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>С URL</th>
            <th>На URL</th>
            <th>Статус</th>
            <th>Дата добавления</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
              urlParams={urlParams}
              fields={[
                { type: 'text', name: 'id' },
                { type: 'text', name: 'url' },
                { type: 'text', name: 'url_to' },
                { type: 'text', name: 'status' },
                { type: 'date', name: 'date_from', secondLvl: { type: 'date', name: 'date_to' } },
                {},
              ]}
              onSubmit={pushUrlParams}
          />

        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={7} />
            )
            : (rest && (
              rest?.data?.length !== 0
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`redirect-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Редирект удален',
                    }}
                    editLink={`/app/redirect/update/${e.id}`}
                    permissionRemove='admin.seo.meta.destroy'
                    permissionEdit='admin.seo.meta.edit'
                  >
                    <td>
                        {checkPermission('admin.seo.meta.edit')
                            ? <Link to={`/app/redirect/update/${e.id}`}>{e.id}</Link>
                            : <span>{e.id}</span>
                        }
                    </td>
                    <td>{e.url}</td>
                    <td>{e.url_to}</td>
                    <td>{e.status}</td>
                    <td>{e?.created_at && getTZtoDate(e.created_at, true)}</td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={7} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>

      {!load && (
        <TableListViewFooter
          left={(
              <>
                  {rest?.total && (
                      <TableListViewPagination
                          length={+rest?.last_page}
                          current={+rest?.current_page}
                          total={+rest?.total}
                          perPage={+rest?.per_page}
                          loc={location}
                      />
                  )}
              </>
          )}
          right={(
            <TableListViewPerPage
              urlParams={urlParams}
              onSelect={(val, params) => pushUrlParams(params)}
            />
          )}
          rightCn="col-auto"
        />
      )}
    </div>
  );
};

export default RedirectsList;
