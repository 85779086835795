import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createProductB2b,
  getProductB2bEdit,
  editProductB2b,
  removeProductB2b,
} from '../../lib/api/product_b2b';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pcId) => Promise.allSettled([getDomains(), pcId
&& getProductB2bEdit(pcId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: pcId && res[1]?.value?.data,
    })
  });

export const create = createProductB2b;
export const edit = editProductB2b;
export const remove = removeProductB2b;

export const parseDataSend = (data, pfId) => {
  let d = { ...data };
  const isUpdate = typeof pfId !== 'undefined';

  if (d?.product_id) d = parseAll(d, !isUpdate, ['product_id']);

  d = parseAll(d, !isUpdate);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
