import { combineReducers } from 'redux';
import layout from './layout/reducer';
import orderEdit from './orderEdit/reducer';
import orderList from './orderList/reducer';
import notice from './notice/reducer';

export default combineReducers({
  layout,
  orderEdit,
  orderList,
  notice,
});
