import { parseAll } from "../../lib/api/_parseApi";
import {
    createTemplate,
    editTemplate,
    getTemplateEdit,
    removeTemplate,
} from "../../lib/api/template";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (orId) => Promise.allSettled([orId
&& getTemplateEdit(orId)])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      return ({
          fields: orId && res[0]?.value?.data,
      })
  });

export const create = createTemplate;
export const edit = editTemplate;
export const remove = removeTemplate;

export const parseDataSend = (data, soId) => {
  let d = { ...data };
  const isUpdate = typeof soId !== 'undefined';

  d = parseAll(d, !isUpdate);

  return d;
};

export const parseDataGet = (data, id) => {
  const d = { ...data };

  const isUpdate = typeof id !== 'undefined';

  return d;
}
