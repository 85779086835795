import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Group from '../../../components/ui/FormEditView/Group';
import ColLeft from '../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../components/ui/FormEditView/ColRight';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import { INPUT_MAX_LENGTH } from "../../../const/view";
import {getLoyaltyBalance, getLoyaltyHistory} from "../../../lib/api/loyalty";
import TableListViewRow from "../../../components/common/TableListView/Row";
import TableListViewTable from "../../../components/common/TableListView/Table";
import errorMessage from "../../../lib/errorMessage";
import TableListViewPagination from "../../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../../components/common/TableListViewNew/SelectPerPage";
import getTZtoDate from "../../../lib/getTZtoDate";
import { getStatusAll } from "../../../lib/api/statuses";
import { findOrderStatusById } from "../../../lib/helpers/order";

const TabInfoUser = ({ index = 1, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const [bonusData, setBonusData] = useState(null);
    const [load, setLoad] = useState(false);

    const [rest, setRest] = useState(null);
    const [orderStatus, setOrderStatus] = useState([]);

    useEffect( () => {
        if (formProps?.data?.fields?.domain_id === 1) {
            if(formProps?.data?.fields?.phone) {
                setLoad(true);
                getLoyaltyBalance(formProps?.data?.fields?.phone)
                    .then(res => setBonusData(res.data))
                    .catch(async (err) => await errorMessage(err, `/loyalty/user/balance/${formProps?.data?.fields?.phone}`))
                    .finally(() => setLoad(false));
            }

            if(formProps?.data?.fields?.id) {
                setLoad(true);
                getLoyaltyHistory(formProps?.data?.fields?.id)
                    .then(res => setRest(res?.data))
                    .catch(async (err) => await errorMessage(err, `/loyalty/user/history/${formProps?.data?.fields?.id}`))
                    .finally(() => setLoad(false));
            }
        }
    }, [formProps?.data]);

    useEffect( () => {
        setLoad(true);
        getStatusAll()
            .then(res => setOrderStatus(res?.data?.data))
            .catch(async (err) => await errorMessage(err, '/status'))
            .finally(() => setLoad(false));
    }, []);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">
                    {!load ? bonusData && (
                        <>
                            {/* cardNumber */}
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="pb2b-cardNumber">Номер карты лояльности:</label>
                                </ColLeft>
                                <ColRight>
                                    <input
                                        className='form-input'
                                        name="cardNumber"
                                        type="text"
                                        id="pb2b-cardNumber"
                                        placeholder="Карта лояльности"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={form.register}
                                        value={bonusData?.card_number ? bonusData?.card_number : '---'}
                                        readOnly
                                    />
                                </ColRight>
                            </Group>

                            {/* bonusActive */}
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="pb2b-bonusActive">Активные бонусы:</label>
                                </ColLeft>
                                <ColRight>
                                    <input
                                        className='form-input'
                                        name="bonusActive"
                                        type="text"
                                        id="pb2b-bonusActive"
                                        placeholder="bonusActive"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={form.register}
                                        value={bonusData?.balance?.active ? bonusData?.balance?.active : 0}
                                        readOnly
                                    />
                                </ColRight>
                            </Group>

                            {/* bonusBlocked */}
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="pb2b-bonusBlocked">Заблокированные бонусы:</label>
                                </ColLeft>
                                <ColRight>
                                    <input
                                        className='form-input'
                                        name="bonusBlocked"
                                        type="text"
                                        id="pb2b-bonusBlocked"
                                        placeholder="bonusBlocked"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={form.register}
                                        value={bonusData?.balance?.blocking ? bonusData?.balance?.blocking : 0}
                                        readOnly
                                    />
                                </ColRight>
                            </Group>

                            {/* bonusBurnt */}
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="pb2b-bonusBurnt">Сгоревшие бонусы:</label>
                                </ColLeft>
                                <ColRight>
                                    <input
                                        className='form-input'
                                        name="bonusBurnt"
                                        type="text"
                                        id="pb2b-bonusBurnt"
                                        placeholder="bonusBurnt"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={form.register}
                                        value={bonusData?.balance?.burnt ? bonusData?.balance?.burnt : 0}
                                        readOnly
                                    />
                                </ColRight>
                            </Group>

                            <div>
                                {!!rest?.data?.length ? (
                                    <>
                                        {rest?.meta && (
                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                                <TableListViewPagination
                                                    length={+rest?.meta?.last_page}
                                                    current={+rest?.meta?.current_page}
                                                    url={`api/admin/loyalty/user/history/${formProps?.data?.fields?.id}`}
                                                    setData={setRest}
                                                    allResponse
                                                    params={{
                                                        per_page: 20
                                                    }}
                                                />
                                                <TableListViewPerPage
                                                    total={+rest?.meta?.total}
                                                />
                                            </div>
                                        )}
                                        <TableListViewTable>
                                            <thead>
                                            <tr>
                                                <th>Дата заказа</th>
                                                <th>№ заказа</th>
                                                <th>Статус заказа</th>
                                                <th>Код товара</th>
                                                <th>Наименование товара</th>
                                                <th>Кол-во,шт</th>
                                                <th>Сумма</th>
                                                <th>Начислено бонусов</th>
                                                <th>Списано бонусов</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rest?.data.map((e, i) => (
                                                <TableListViewRow
                                                    id={i}
                                                    key={`user-history-item-${i}`}
                                                    controlsOff
                                                >
                                                    <td>{e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}</td>
                                                    <td>{e?.order_id || '---'}</td>
                                                    <td>
                                                        {e.status
                                                            ? !!formProps.data?.statuses?.length
                                                                ? findOrderStatusById(formProps.data?.statuses, e.status)
                                                                    ? findOrderStatusById(formProps.data?.statuses, e.status)?.name
                                                                    : e.status
                                                                : e.status
                                                            : '---'}
                                                    </td>
                                                    <td>{e?.jde || '---'}</td>
                                                    <td>{e?.title || '---'}</td>
                                                    <td>{e?.quantity || 0}</td>
                                                    <td>{e?.total || 0}</td>
                                                    <td>{e?.reward_points || 0}</td>
                                                    <td>{e?.invoice_points || 0}</td>
                                                </TableListViewRow>
                                            ))}

                                            </tbody>
                                        </TableListViewTable>
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center' }}>Нет данных о бонусах</div>
                                )}
                            </div>
                        </>
                    ) : <Loader />}
                </Form>

                {formProps.load && <Loader />}
            </div>
        </form>
    );
};

export default TabInfoUser;
