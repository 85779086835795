import { getDomains } from '../../lib/api/domains';
import {
    createOrder,
    getOrderEdit,
    editOrder,
    removeOrder,
    getLogOrder,
    addOrderPromoCode,
    removeOrderPromoCode,
    getSms,
    checkVisitOrderEdit
} from '../../lib/api/order';
import { getWhAll } from '../../lib/api/warehouses';
import { getCancelVariants } from '../../lib/api/cancel_variants';
import { parseAll } from "../../lib/api/_parseApi";
import { getStatusAll } from "../../lib/api/statuses";
import { LIST_PER_PAGE } from "../../const/view";
import filterStatusOrder from "../../lib/helpers/filterStatusOrderShopManager";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (orId) => Promise.allSettled([
    getDomains(), orId && getOrderEdit(orId), getWhAll(), getCancelVariants({ per_page: LIST_PER_PAGE.xxxl }),
    getSms(), orId && checkVisitOrderEdit(orId), getStatusAll()
])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      const parseSmsList = Object.values(res[4]?.value?.data);

      return ({
          domains: res[0]?.value?.data,
          fields: orId && res[1]?.value?.data,
          wareHouses: res[2]?.value?.data?.data,
          cancelVariants: res[3]?.value?.data?.data,
          smsList: parseSmsList,
          editInfo: res[5]?.value?.data,
          statuses: res[6]?.value?.data?.data
      });
  });

export const create = createOrder;
export const edit = editOrder;
export const remove = removeOrder;
export const getLog = getLogOrder;
export const addPromo = addOrderPromoCode;
export const removePromo = removeOrderPromoCode;

export const parseDataSend = (data, soId) => {
  let d = { ...data };
  const isUpdate = typeof soId !== 'undefined';

  const parseProducts = d?.products?.map(item => ({
      ...item,
      discount_absolute: +item.discount_absolute,
      discount_bpm: +item.discount_bpm,
      discount_percent: +item.discount_percent,
      selected: +item.selected,
      domain_id: +item.domain_id,
      manager_id: item.manager_id,
      product_id: +item.product_id,
      quantity: +item.quantity,
      user_id: +item.user_id,
      user_type: +item.user_type,
      warehouse_id: item.warehouse_id,
  }));

  if(parseProducts) {
      d.products = [...parseProducts];
  }

  if(d.credit_user?.tin.length < 7 ) {
      delete d.credit_user;
  }

  if(d.status_top_check) {
      d.status = d.status_top;
  }

  d['order_comment'] = parseAll(d['order_comment'], true);
  d['order_user_info'] = parseAll(d['order_user_info'], true, ['callback_disabled']);
  d['credit_user'] = parseAll(d['credit_user'], true, ['credit_type_id', 'credit_offer_id', 'bank_id']);

  delete d?.delivery['estimated_delivery_date-dop'];

  d = parseAll(d, !isUpdate);

  if(!d?.order_comment?.text?.length) {
      delete d['order_comment'];
  }

  return d;
};

export const parseDataGet = (data, id) => {
  const d = { ...data };

  const isUpdate = typeof id !== 'undefined';
  if (isUpdate) {
    if(d.user_info){
      d.order_user_info = d.user_info;
    }
    if(d.delivery_nova_poshta_address){
      d.order_delivery_nova_poshta_address = d.delivery_nova_poshta_address;
    }
    if(d.delivery_nova_poshta_warehouse){
       d.order_delivery_nova_poshta_warehouse = d.delivery_nova_poshta_warehouse;
    }
    if(d.delivery_delfast){
      d.order_delivery_delfast = d.delivery_delfast
    }
    if(d.delivery_justin){
      d.order_delivery_justin = d.delivery_justin
    }

    d['status_changes'] = filterStatusOrder(d.status_changes);
  }

  return d;
}
