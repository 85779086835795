import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import { getCatAll, getCatTree } from '../../lib/api/categories';
import { getCreditTypes } from '../../lib/api/creditTypes';
import { getBanks } from '../../lib/api/banks';
import {
  createCreditOffer, editCreditOffer, getCreditOfferEdit, removeCreditOffer,
} from '../../lib/api/creditOffers';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (bankId) => Promise.allSettled([getDomains(), getCatTree(),
  getCreditTypes(), getBanks(), getCatAll(), bankId && getCreditOfferEdit(bankId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    const parseCategories = res[1]?.value?.data?.map(item => {
      const parseSecondLevel = item.children.map(itemSecond => {
        const parseThirdLevel = itemSecond.children.map(itemThird => {
          return {
            value: itemThird.id,
            label: itemThird.title,
            children: [],
          };
        });
        return {
          value: itemSecond.id,
          label: itemSecond.title,
          children: parseThirdLevel,
        };
      })
      return {
        value: item.id,
        label: item.title,
        children: parseSecondLevel,
      };
    });
    return ({
      domains: res[0]?.value?.data,
      categories: parseCategories,
      creditTypes: res[2]?.value?.data,
      banks: res[3]?.value?.data?.data,
      categoryAll: res[4]?.value?.data,
      fields: bankId && res[5]?.value?.data,
    });
  });

export const create = createCreditOffer;
export const edit = editCreditOffer;
export const remove = removeCreditOffer;

export const parseDataSend = (data, bankId) => {
  let d = { ...data };

  const isUpdate = typeof bankId !== 'undefined';

  if(d.categories !== '') {
    d.categories = d.categories.split(',');
  } else {
    d.categories = [];
  }

  d.categories = d.categories.filter(item => {
    return item.length < 4;
  });

  if(d.products_turn_on !== '') {
    d.products_turn_on = d.products_turn_on.split(',');
  } else {
    d.products_turn_on = [];
  }

  d.products_turn_on = d.products_turn_on.filter((item, pos) => {
    return d.products_turn_on.indexOf(item) === pos;
  });

  if(d.products_turn_off !== '') {
    d.products_turn_off = d.products_turn_off.split(',');
  } else {
    d.products_turn_off = [];
  }

  d.products_turn_off = d.products_turn_off.filter((item, pos) => {
    return d.products_turn_off.indexOf(item) === pos;
  });

  d = parseAll(d, !isUpdate, [
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
