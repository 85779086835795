import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import loadOptions from "./loadOptions";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";

const defaultAdditional = {
    page: 1
};

const loadPageOptions = (q, prevOptions, { page }) => async (api, searchName) => {
    const { options, hasMore } = await loadOptions(api, q, page, searchName);

    return {
        options,
        hasMore,

        additional: {
            page: page + 1
        }
    };
};

const SelectInputSearch = ({
                               api,
                               placeholder,
                               name,
                               id,
                               formH,
                               formProps,
                               formPropsName,
                               setValue,
                               onChangeObj,
                               params,
                               labelTitle,
                               searchName,
                               clearValue = true
}) => {
    const [dataInput, setDataInput] = useState(null);

    useEffect(() => {
        if(dataInput?.value && setValue) {
            setValue(dataInput.value);
        }
    }, [dataInput]);

    useEffect(() => {
       if(formPropsName) {
           api(
               {...params}
           ).then(res => {
               const result = res.data.data.find(item => item.id === formPropsName);
               if(result) {
                   setDataInput({
                       value: result.id,
                       label: result[labelTitle],
                   })
               }
           }).catch(async (err) => {
               if(err?.response?.status === 401) {
                   await refreshToken(async () => {
                       await api(
                           {...params}
                       ).then(res => {
                           const result = res.data.data.find(item => item.id === formPropsName);
                           if(result) {
                               setDataInput({
                                   value: result.id,
                                   label: result[labelTitle],
                               })
                           }
                       })
                   })
               } else {
                   await errorMessage(err , '')
               }
           })
        }
    }, []);

    return (
        <>
            <input
                type="hidden"
                value={dataInput?.value}
                name={name}
                id={id}
                ref={formH.register}

            />
            <AsyncPaginate
                additional={defaultAdditional}
                value={dataInput}
                loadOptions={(q, prevOptions, { page }) => loadPageOptions(q, prevOptions, { page })(api, searchName)}
                debounceTimeout={1000}
                onChange={(e) => {
                    onChangeObj && onChangeObj('settlement_id', e?.value ? e.value : e, 'delivery', formProps?.data?.fields?.delivery?.id);
                    setDataInput(e);
                }}
                placeholder={placeholder}
                classNamePrefix="react-select"
                isClearable={clearValue}
            />
        </>
    )
}

export default SelectInputSearch;