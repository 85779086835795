import React, {useEffect, useState} from 'react';
import Breadcrumbs from './Breadcrumbs';
import BtnAction from './BtnAction';

import s from './TopPanel.module.scss';
import BtnExport from "./BtnExport";
import Modal from "../../ui/Modal";
import checkPermission from "../../../lib/checkPermission";
import BtnPrint from "./BtnPrint";
import LinkToAnotherPage from "./LinkToAnotherPage";

const TopPanel = ({
                      breadcrumbs,
                      btnAction,
                      btnSettings,
                      btnExport,
                      btnModal,
                      btnPrint,
                      btnClearParams,
                      right,
                      cols = 6,
                      linkToAnotherPage,
                      customStyles
                    }) => {
  const [activeModal, setActiveModal] = useState(false);

    useEffect(() => {
        localStorage.removeItem('ids');
    }, []);
    return (
        <div className={`box-wrap box-wrap--py-sm box-wrap--full-w ${customStyles ? s.wrap__customStyles : s.wrap}`}>
            <div className="columns">
                <div className={`${s.col} column col-${cols}`}>
                    {breadcrumbs && (
                        <Breadcrumbs {...breadcrumbs} />
                    )}
                </div>
                <div className={`${s.col} ${s.colRight} column col-${12 - cols}`}>
                    {btnClearParams && <div className="mr-2"><BtnAction {...btnClearParams} /></div>}
                    {linkToAnotherPage && <div className="mr-2"><LinkToAnotherPage {...linkToAnotherPage} /></div>}
                    {btnExport && (checkPermission(btnExport.permissionName) && <BtnExport {...btnExport} />)}
                    {btnSettings && (checkPermission(btnSettings.permissionName) && <BtnAction {...btnSettings} />)}
                    {right || (
                        btnAction && (checkPermission(btnAction.permissionName) && <BtnAction {...btnAction} />)
                    )}
                    {btnPrint && checkPermission(btnPrint.permissionName) && <div className="mr-2"><BtnPrint {...btnPrint} /></div>}
                    {btnModal && (
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => setActiveModal(true)}>Создать</button>
                            <Modal active={activeModal} setActive={setActiveModal} {...btnModal} />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
};

export default TopPanel;
