import React from 'react';
import cn from 'classnames';

import s from './TableDynamicFieldsRow.module.scss';

const TableDynamicFieldsRow = ({
  children, attrs, onRemove, move, onlyRemove
}) => {
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className),
  };

  function removeHandler() {
    if (onRemove) onRemove();
  }

  return (
    <tr {...attrsMerge}>
      {children}

      {/* controls */}
      {(onRemove || move) && (
        <td>
          <div className={s.controls}>
            {onRemove && (
              <button
                type="button"
                className="btn btn-action btn-sm s-circle"
                onClick={removeHandler}
              >
                <i className="icon icon-delete" />
              </button>
            )}

            {!onlyRemove && (
              <button
                type="button"
                className="btn btn-action btn-link btn-sm s-circle ml-2"
              >
                <i className="icon icon-resize-vert" />
              </button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default TableDynamicFieldsRow;
