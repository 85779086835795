export default (editor, opts = {}) => {
    const dc = editor.DomComponents;

    dc.addType(opts.name, {
        model: {
            defaults: {},
        },
        isComponent: (el) => {
            if(el.className && el.className.includes('image-five-right')) {
                return {
                    type: opts.name,
                };
            }
        },
    });
};