// @todo
/* eslint-disable */
export const BONUS_TYPE = [
  {
    id: 1,
    title: 'Не Подучетные товары',
  },
  {
    id: 2,
    title: 'Дополнительные сервисы',
  },
  {
    id: 3,
    title: 'Скидка',
  },
];

export const getBonusType = (type) => BONUS_TYPE.find((el) => type.toString() === el.id?.toString());
