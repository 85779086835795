import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData } from "./api";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { DOMAINS } from "../../lib/const/domains";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import getTZtoDate from "../../lib/getTZtoDate";

const EmailSubscription = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        filtersData,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Email subscription list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={[
                        'ID', 'Домен', 'Email', 'Івент', 'Дата створення', 'Дата оновлення'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'select', name: 'domain_id', options: DOMAINS},
                            {type: 'text', name: 'email'},
                            {
                                type: 'select',
                                name: 'source',
                                options: !!filtersData?.sources?.length && filtersData?.sources
                            },
                            {type: 'date_range', startName: 'date_from', endName: 'date_to', width: '18%'},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5}/>
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e) => (
                                <TableListViewRow id={e.id} key={`domain-item-${e.id}`} controlsOff>
                                    <td>
                                        <span>
                                            {e?.id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.domain?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                         <span>
                                            {e?.email || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.source || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e.created_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.updated_at ? getTZtoDate(e.updated_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={5}>Немає Email-подписки</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default EmailSubscription;
