import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import ColLeft from '../FormEditView/ColLeft';
import ColRight from '../FormEditView/ColRight';
import { VALIDATION } from '../../../const/text';
import Group from '../FormEditView/Group';
import ColFull from "../FormEditView/ColFull";
import s_OrderStyle from "../../../containers/OrderEditView/fakeData/orderStyle.module.scss";
import s_Input from "../InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import InputWrapper from "../InputWrapper";
/* eslint-disable */
const PriceType = ({
  defVal = 1,
  defPrice,
  isReq = false,
  formH,
}) => {
  const {
    register,
    errors,
  } = formH;

  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(defVal);
  }, [defVal]);

  return (
    <Group attrs={{ style: { margin: 0, padding: 0 } }}>
      <ColFull attrs={{ style: { display: 'flex', gap: 14, flexWrap: 'wrap', margin: 0, padding: 0 } }}>
        <div className={s_OrderStyle.select__inner} style={{ minWidth: '250px' }}>
          <div className={s_OrderStyle.select__wrapper}>
            <select
                name="price_type"
                className={cn(s_OrderStyle.select, {
                  [s_OrderStyle.select_error]: errors.price_type,
                })}
                id="as-price-types"
                ref={register({
                  required: isReq ? VALIDATION.select.required : false,
                })}
                onChange={(e) => setSelected(e.target.value)}
                defaultValue={1}
            >
              <option value={1}>Відсоток</option>
              <option value={2}>Абсолютне значення</option>
            </select>
            <label className={s_OrderStyle.label} htmlFor="so-domain-id">Відсоток|Абсолютне значення</label>
          </div>
          {isReq && errors.price_type && <p className="form-input-hint">{errors.price_type.message}</p>}
        </div>

        <InputWrapper
            label="Вартість сервісу"
            id="as-price"
            errorComponent={formH.errors?.price &&
            <p className="form-input-hint">{formH?.errors?.price?.message}</p>}
            style={{ margin: 0, maxWidth: '175px' }}
        >
          <input
              className={cn(s_Input.input, {
                [s_Input.input_error]: formH.errors?.price,
              })}
              type="number"
              placeholder="Вартість сервісу"
              maxLength={INPUT_MAX_LENGTH}
              ref={formH.register({
                required: VALIDATION.req.required
              })}
              name="price"
              id="as-price"
              defaultValue={defPrice}
          />
        </InputWrapper>
      </ColFull>
    </Group>
  );
};

export default PriceType;
