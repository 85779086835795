import cn from 'classnames';
import React from 'react';

import s from './Table.module.scss';

const TableListViewTable = ({ children, attrs, noSticky = false }) => {
  const attrsMerge = {
    ...attrs,
    className: cn('table table-hover', s.table, attrs && attrs.className),
  };

  return (
    <div className={cn(s.wrap, {
      'table_no_sticky': noSticky
    })}>
      <table {...attrsMerge}>{children}</table>
    </div>
  );
};

export default TableListViewTable;
