// status
import getObjByArrId from '../helpers/getObjByArrId';

export const APPLICATION_TYPES = [
  {
    id: 1,
    title: 'Сообщить о наличии',
  },
  {
    id: 2,
    title: 'Подбор альтернативы',
  },
  {
    id: 3,
    title: 'Уточнить наличие',
  },
]

export const APPLICATION_STATUS = [
  {
    id: 0,
    title: 'Не обработан',
  },
  {
    id: 1,
    title: 'Обработан',
  },
]

export const PRODUCT_STATUS = [
  {
    id: 1,
    title: 'Программа Trade-In',
  },
  {
    id: 2,
    title: 'Проданный',
  },
  {
    id: 3,
    title: 'Архивный',
  },
  {
    id: 4,
    title: 'Активный',
  },
  {
    id: 5,
    title: 'Скрытый',
  },
  {
    id: 6,
    title: 'Непроверенный',
  },
  {
    id: 7,
    title: 'Ожидается',
  },
  {
    id: 8,
    title: 'Сканировать IMEI',
  },
  {
    id: 9,
    title: 'Сканировать СН',
  },
  {
    id: 10,
    title: 'Под заказ',
  },
  {
    id: 11,
    title: 'Предзаказ',
  },
];

export const PRODUCT_STATUS_NEW = [
  {
    id: 2,
    title: 'Проданный',
    color: '#5755d1'
  },
  {
    id: 3,
    title: 'Архивный',
    color: '#22a4cd'
  },
  {
    id: 4,
    title: 'Активный',
    color: '#34a853'
  },
  {
    id: 5,
    title: 'Скрытый',
    color: '#a2b3c2'
  },
  {
    id: 6,
    title: 'Непроверенный',
  },
  {
    id: 7,
    title: 'Ожидается',
    color: '#e89a03',
  },
  {
    id: 11,
    title: 'Предзаказ',
    color: '#e2cd0b',
  },
];

export const PRODUCT_ADDITIONALLY = [
  {
    id: 1,
    title: 'Программа Trade-In',
  },
  {
    id: 8,
    title: 'Сканировать IMEI',
  },
  {
    id: 9,
    title: 'Сканировать СН',
  },
  {
    id: 10,
    title: 'Под заказ',
  },
];

// stock types
export const PROD_STOCK_TYPE_1 = {
  id: 1,
  title: 'Собственный склад',
  hasId: (id) => +id === 1,
};
export const PROD_STOCK_TYPE_2 = {
  id: 2,
  title: 'Розничный магазин',
  hasId: (id) => +id === 2,
};
export const PROD_STOCK_TYPE_3 = {
  id: 3,
  title: 'Склад поставщика',
  hasId: (id) => +id === 3,
};
export const PROD_STOCK_TYPE_4 = {
  id: 4,
  title: 'Пункт самовывоза',
  hasId: (id) => +id === 4,
};

export const PROD_STOCK_TYPES = [
  PROD_STOCK_TYPE_1,
  PROD_STOCK_TYPE_2,
  PROD_STOCK_TYPE_3,
  PROD_STOCK_TYPE_4,
];

export const PROD_STOCK_TYPES_ID = [1, 2, 3, 4];

export const getProdStockType = (id) => getObjByArrId(id, PROD_STOCK_TYPES_ID, PROD_STOCK_TYPES);

// stock types
export const PROD_DESC_INFO_STATUS_1 = {
  id: 1,
  title: 'На проверку',
  hasId: (id) => +id === 1,
};
export const PROD_DESC_INFO_STATUS_2 = {
  id: 2,
  title: 'Принят',
  hasId: (id) => +id === 2,
};
export const PROD_DESC_INFO_STATUS_3 = {
  id: 3,
  title: 'Не принят',
  hasId: (id) => +id === 3,
};
export const PROD_DESC_INFO_STATUS_4 = {
  id: 4,
  title: 'Убрать с описания',
  hasId: (id) => +id === 4,
};

export const PROD_DESC_INFO_STATUS = [
  PROD_DESC_INFO_STATUS_1,
  PROD_DESC_INFO_STATUS_2,
  PROD_DESC_INFO_STATUS_3,
  PROD_DESC_INFO_STATUS_4,
];

export const PROD_DESC_INFO_STATUS_ID = [1, 2, 3, 4];

export const PRODUCT_LANG_CONSTRUCTOR = [
  {
    id: 1,
    title: 'RU',
    value: 'ru'
  },
  {
    id: 2,
    title: 'UA',
    value: 'ua'
  }
]
