export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.title_text_custom > span {
                font-size: 24px;
                line-height: 31px;
                font-weight: bold;
            }
            
            @media screen and (max-width: 992px) {
                div.title_text_custom > span {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
            
            @media screen and (max-width: 480px) {
                div.title_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="title_text_custom mb-2 mb-lg-4"><span>Ваш тайтл</span></div>
            ${style}
        `,
    })
};