import React, { forwardRef } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import { ERROR_FILE_SIZE_INVALID } from '../../../const/text';
import parseStrPat from '../../../lib/parseStrPat';
import loadMedia from '../../../lib/helpers/loadMedia';
import getMbToBytes from '../../../lib/helpers/getMbToBytes';

import s from './MediaFile.module.scss';
/* eslint-disable */
// @todo: isolate from react-hook-form
const MediaFile = forwardRef(({
  name,
  val,
  defVal,
  typeFile,
  formH,
  acceptStr,
  accept,
  sizeMB = 5,
  className,
  style,
  setImageData,
  prodID,
  indexItem,
  maxWidth = 1920,
  maxHeight = 1080,
  removeBtn = true,
  sm = true,
  entity_type = 'product',
  collection_name = 'image',
}, ref) => {

  async function fileHandler(e) {
    const fList = e.target.files;
    if (!fList.length) return;

    let reader = new FileReader();
    reader.onload = function(e) {
      let img = document.createElement('img');
      img.onload = function() {
        if(maxWidth < this.width || maxHeight < this.height) {
          alert(`Допустимый размер картинки: ${maxWidth}x${maxHeight}`);
        }
      };
      img.src = e.target.result;
    }
    reader.readAsDataURL(fList[0]);

    // sizeMB validation
    if (sizeMB && fList[0].size > getMbToBytes(sizeMB)) {
      alert(parseStrPat(ERROR_FILE_SIZE_INVALID, { n: '5мб' }));
      return;
    }

    // load file to server
    await loadMedia(
        fList[0],
        (data) => {
          setImageData(prev => {
            const temp = [...prev];

            temp[indexItem] = data;

            return temp;
          })
        },
        acceptStr,
        1,
        collection_name,
        prodID,
        entity_type,
    );
  }

  return (
    <div className={className} style={style}>
      <div className={s.wrap}>

        <input
          className={s.inputHidden}
          type="text"
          name={name}
          ref={ref}
          {...defVal && { defaultValue: defVal }}
        />

        <div
          className={cn('s-rounded', s.imgBox,
            // {[s.imgBoxError]: !!formH.errors[name]}
            { [s.imgBoxError]: !!get(formH.errors, name) })}
        >
          {/*<input*/}
          {/*  type="file"*/}
          {/*  onChange={fileHandler}*/}
          {/*  accept={accept}*/}
          {/*/>*/}

          {val && typeFile?.includes("video") ? (
              <video controls style={{ width: '175px' }}>
                <source src={val} />
                Your browser does not support the video tag.
              </video>
          ) : (
                <img src={val} alt="" />
            )}

        </div>
      </div>
    </div>
  );
});

export default MediaFile;
