import { TRADE_IN_GET } from '../../const/api'
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

export const getTradeIn = (params) => fetchAx({
    url: TRADE_IN_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});