import { parseAll } from '../../lib/api/_parseApi';
import { editHrDomainMask, getHrDomainMaskEdit, removeHrDomainMask } from "../../lib/api/hrDomainMask";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (hrDomainMaskId) => Promise.allSettled([hrDomainMaskId && getHrDomainMaskEdit(hrDomainMaskId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: hrDomainMaskId && res[0]?.value?.data,
    })
  });

export const edit = editHrDomainMask;
export const remove = removeHrDomainMask;

export const parseDataSend = (data, soId) => {
  let d = { ...data };
  const isUpdate = typeof soId !== 'undefined';

  d = parseAll(d, !isUpdate);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
