import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import s from "./TopPanelNew.module.scss";
import Button from "../../ui/Button";

const BtnClearParams = ({ title, href, funcRedux }) => {
    const dispatch = useDispatch();

    return (
        <Link to={href} className={`link ${s.display}`}>
          <Button border onClick={() => {
              funcRedux && funcRedux(dispatch)()
          }}>
                {title}
          </Button>
        </Link>
    )
};

export default BtnClearParams;