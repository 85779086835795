import React, {useCallback, useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import Group from '../../../components/ui/FormEditView/Group';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import CheckboxTreeWrap from "../../../components/ui/CheckboxTreeWrap";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import SelectInput from "../../../components/ui/SelectInput";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s from "../artircle.module.scss";
import { getProduct } from "../../../lib/api/products";
import TableItems from "../../../components/ui/TableItems/TableItems";
import removeDuplicates from "../../../lib/helpers/removeDuplicates";
import errorMessage from "../../../lib/errorMessage";

const TabProduct = ({ index = 0, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const [productsList, setProductsList] = useState([]);
    const [productsJDEFilter, setProductsJDEFilter] = useState([]);

    const parseData = useCallback(() => {
        const jde = [...productsJDEFilter]

        const totArr = [...jde];
        setProductsList(removeDuplicates(totArr));
    }, [productsJDEFilter]);

    const removeItem = (e, el) => {
        e.preventDefault();
        const temp = [...productsList];
        const index = temp.indexOf(el);
        if (index !== -1) {
            temp.splice(index, 1);
            setProductsList(temp);
            setProductsJDEFilter(temp);
        }
    };

    useEffect(() => {
        setProductsList([]);
        parseData();
    }, [parseData]);

    useEffect(() => {
        if(formProps?.data?.fields?.product_ids) {
            for (let i = 0; i < formProps?.data?.fields?.product_ids.length; i++) {
                getProduct({id: formProps?.data?.fields?.product_ids[i]}).then(res => {
                    setProductsJDEFilter(prev => {
                        if(typeof prev !== "undefined") {
                            return [...prev, ...res.data.data];
                        } else {
                            return [...res.data.data]
                        }
                    })
                }).catch(async (err) => await errorMessage(err , ''));
            }
        }
    }, []);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">

                    <div className={`divider text-center ${s.divider}`} data-content="Категории" />

                    <div className={s.tree_wrap}>
                        <CheckboxTreeWrap
                            data={formProps?.data?.categories[0].children}
                            setData={() => {}}
                            formH={form}
                            dataAll={formProps?.data}
                            nameParent={`cat_ids`}
                            marginLeft={false}
                        />
                    </div>

                    <div className="divider text-center" data-content="Товары" />

                    {/* product_ids */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Товары:" id="art-products" customStyle={{ top: '25px', transform: 'translateY(-100%) scale(0.8)'}}>
                                <SelectInput
                                    searchBy="value"
                                    className={s_Input.input}
                                    title="jde[]"
                                    name="products[]"
                                    api={{ get: getProduct }}
                                    methods={{
                                        set: setProductsJDEFilter,
                                    }}
                                    visibleSelected={false}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>
                    {!!productsList?.length && (
                        <TableItems
                            formH={form}
                            list={productsList}
                            title="Выбраные товары"
                            name="products"
                            thNames={['ID', 'Название', '']}
                            func={{
                                remove: removeItem,
                            }}
                        />
                    )}
                </Form>

                {formProps.load && <Loader />}
            </div>
        </form>
    );
};

export default TabProduct;
