import { getTradeIn } from '../../lib/api/trade_in';
import { getWhAll } from "../../lib/api/warehouses";

export const getFiltersData = () => Promise.allSettled([getWhAll()])
    .then((res) => {

        const filterWarehouse = res[0]?.value?.data?.data?.filter(item => item?.type === 2);

        return {
            wareHouses: filterWarehouse
        }
    });

export const getData = getTradeIn;
