import React from 'react';
import cn from 'classnames';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import Content from './Content';

import s from './GridEditView.module.scss';

const GridEditView = ({
  left, right, children, col, reverse = false, styleWrap
}) => (
  <div className={s.wrap} style={styleWrap}>
    <div className={cn('columns',
      { [s.reverse]: reverse })}
    >
      <SidebarLeft>{left}</SidebarLeft>
      <Content col={col}>{children}</Content>
      <SidebarRight>{right}</SidebarRight>
    </div>
  </div>
);

export default GridEditView;
