import {
    TRY_AND_BUY_GET,
    TRY_AND_BUY_CREATE,
    TRY_AND_BUY_GET_EDIT,
    TRY_AND_BUY_EDIT,
    TRY_AND_BUY_STATUSES_GET, TRY_AND_BUY_SHOPS_GET, TRY_AND_BUY_DEVICES_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getTryAndBuy = (params) => fetchAx({
    url: TRY_AND_BUY_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const createTryAndBuy = (data) => fetchAx({
    url: TRY_AND_BUY_CREATE,
    method: 'POST',
    data,
});

export const getTryAndBuyEdit = (id) => fetchAx({
    url: TRY_AND_BUY_GET_EDIT.join(id),
    method: 'GET',
});

export const editTryAndBuy = (id, data) => fetchAx({
    url: TRY_AND_BUY_EDIT.join(id),
    method: 'PUT',
    data,
});

export const getTryAndBuyStatus = (params) => fetchAx({
    url: TRY_AND_BUY_STATUSES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getTryAndBuyShops = (params) => fetchAx({
    url: TRY_AND_BUY_SHOPS_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getTryAndBuyDevices = (params) => fetchAx({
    url: TRY_AND_BUY_DEVICES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});
