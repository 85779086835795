import React from "react";
import s from './orderMainContainer.module.scss'
import Form from "../../../components/ui/FormEditView/Form";

const MainContainer = ({ submitHandler, handleSubmit, children }) => (
  <div className={s.order_main_container}>
    <Form attrs={{
      onSubmit: handleSubmit(submitHandler),
    }}
    >
      <button type="submit" className="d-hide" />
    {children}
    </Form>
  </div>
)

export default MainContainer
