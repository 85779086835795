import _parseApi, { parseAll } from '../../lib/api/_parseApi';
import {
  createContact,
  getContacts,
  editContact
} from '../../lib/api/contact';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = () => Promise.allSettled([getContacts()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: res[0]?.value?.data
    })
  });

export const create = createContact;
export const edit = editContact;

export const parseDataSend = (data) => {
  let d = { ...data };

  d = parseAll(d, true, [

  ], true);

   d = _parseApi.parseAllArr(d, [
     {
       name: 'phone',
       removeEmt: true,
     },
  ], true);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  if(!!d.phones?.length){
    d.phones = d.phones.map(ph => ph.phone)
  }

  return d;
};
