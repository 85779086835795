import fetchAx from "../fetchAx";
import { LOYALTY_BALANCE_GET, LOYALTY_HISTORY_GET } from "../../const/api";

export const getLoyaltyBalance = (id) => fetchAx({
    url: LOYALTY_BALANCE_GET.join(id),
    method: 'GET',
});

export const getLoyaltyHistory = (id, params) => fetchAx({
    url: LOYALTY_HISTORY_GET.join(id),
    method: 'GET',
    params: {
        per_page: 20,
        page: 1,
        ...params
    }
});