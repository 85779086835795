import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createUniqueService, editUniqueService, getUniqueServiceEdit, removeUniqueService,
} from '../../lib/api/unique_service';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (uniqueServiceID) => Promise.allSettled([getDomains(), uniqueServiceID
&& getUniqueServiceEdit(uniqueServiceID)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: uniqueServiceID && res[1]?.value?.data,
    })
  });

export const create = createUniqueService;
export const edit = editUniqueService;
export const remove = removeUniqueService;

export const parseDataSend = (data, uniqueServiceID) => {
  let d = { ...data };
  const isUpdate = typeof uniqueServiceID !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
