export default (editor, opts = {}) => {
    const bm = editor.BlockManager;
    const style = `
        <style>
            ul.ul-native {
                    list-style-type: disc;
            }
            ul.ul-native > li {
                padding: 5px !important;
                margin-bottom: 5px !important;
                font-size: 18px;
            }
            
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <i class="fa fa-list"></i>
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <ul class="ul-native">
                <li>Список 1</li>
                <li>Список 2</li>
                <li>Список 3</li>
            </ul>
            ${style}
        `,
    })
};