import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import s from './Modal.module.scss';
import Button from "../Button";
import fetchAx from "../../../lib/fetchAx";
import Group from "../FormEditView/Group";
import ColFull from "../FormEditView/ColFull";
import InputWrapper from "../InputWrapper";
import {INPUT_MAX_LENGTH, LIST_PER_PAGE} from "../../../const/view";
import s_input from "./../InputWrapper/InputWrapper.module.scss";
import InputMask from "react-input-mask";
import s_Select from "../FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../../../containers/OrderEditView/fakeData/orderTable.module.scss";
import s_OrderStyle from "../../../containers/OrderEditView/fakeData/orderStyle.module.scss";
import { uid } from "react-uid";
import errorMessage from "../../../lib/errorMessage";
import getTZtoDate from "../../../lib/getTZtoDate";
import { refreshToken } from "../../../lib/crud/refreshToken";

const Modal = ({ active, setActive, title, api, href, fields, defaultSendValue, apiFields, redirect = true }) => {
    const [sendData, setSendData] = useState({});
    const [dynamicData, setDynamicData] = useState([]);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    const [dateMinDisable, setDateMinDisable] = useState(new Date());
    const [dateMaxDisable, setDateMaxDisable] = useState(new Date());

    const handlerOnChange = (e) => {
        if(e.target.name !== 'device_number'){
            setSendData(prev => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value,
                }
            })
        } else {
            setSendData(prev => {
                return {
                    ...prev,
                    [e.target.name]: e.target.name === 'device_number' ? fields?.filter(item=>item?.name === 'device_number')[0]?.options?.filter(item => item?.device_id === +e?.target?.value)[0]?.id : e.target.value,
                    device_id: e.target.name === 'device_number' ? +e.target.value : null
                }
            })
        }
    };

    const createItem = async () => {
        if((sendData?.status !== 'waiting' && (!sendData?.device_id || !sendData?.device_number )) && href === '/app/try-and-buy' ){
            const err = {response: {status: 'customDocumentSelect'}}
            await errorMessage(err, 'Оберіть пристрій')
        } else {
        setLoad(true);
        fetchAx({
            url: api,
            method: 'POST',
            data: {
                ...sendData,
                device_number: sendData?.device_number && +sendData.device_number,
                device_id: sendData?.device_id && +sendData.device_id,
                preferred_date: sendData?.preferred_date ? sendData.preferred_date : dateMinDisable,
            }
        }).then(res => {
            if(redirect) {
                navigate(`${href}/${res.data.id}`);
            } else {
                window.location.reload();
            }
            setActive(false);
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        fetchAx({
                            url: api,
                            method: 'POST',
                            data: {
                                ...sendData,
                                device_number: sendData?.device_number && +sendData.device_number,
                                device_id: sendData?.device_id && +sendData.device_id
                            }
                        }).then(res => {
                            if(redirect) {
                                navigate(`${href}/${res.data.id}`);
                            } else {
                                window.location.reload();
                            }
                            setActive(false);
                        })
                    })
                } else {
                    await errorMessage(err, '')
                }
            })
            .finally(() => setLoad(false));
        };
    };

    const Input = (props) => (
        <InputMask mask="99/99/9999" value={props.value} onChange={props.onChange}>
            {(inputProps) => <MaterialInput {...inputProps} type="tel" disableUnderline />}
        </InputMask>
    );

    useEffect(() => {
        if(defaultSendValue) {
            setSendData(prev => ({
                ...prev,
                ...defaultSendValue,
            }))
        }
    }, [defaultSendValue]);

    useEffect(() => {
        setDynamicData([])
        if(apiFields?.length !== 0) {
            apiFields?.forEach(async (item) => {
                await item.api().then((res) => {

                    if (item?.parse && item?.idInput === 'input-category-id') {
                        const parseCategoriesAction = [];

                        const parseCategoriesActionNodes = (nodes) => {
                            if (!!nodes.children?.length) {
                                nodes.children.map((node) => parseCategoriesActionNodes(node))
                            }

                            return parseCategoriesAction.push({
                                id: nodes.id,
                                title: nodes.title
                            });
                        }

                        parseCategoriesActionNodes(res?.data?.[0])
                        setDynamicData(prev => ([...prev, {
                            name: item.nameInput,
                            id: item.idInput,
                            label: item.labelInput,
                            options: parseCategoriesAction
                        }]))
                    } else {
                        setDynamicData(prev => ([...prev, {
                            name: item.nameInput,
                            id: item.idInput,
                            label: item.labelInput,
                            options: res?.data?.data || res?.data
                        }]))
                    }
                })
            })
        }
    }, [apiFields]);

    useEffect(() => {
        const parseDate = getTZtoDate(dateMinDisable);
        const parseDateMax = getTZtoDate(dateMaxDisable);

        const parseDateMin = (date, addDate) => {
            let getDay = Number(date.split('.')[0]) + addDate;
            let getMouth = Number(date.split('.')[1]);
            const getYear = date.split('.')[2];
            if(getMouth < 10) {
                getMouth = '0' + getMouth.toString();
            }
            if(getDay < 10) {
                getDay = '0' + getDay.toString();
            }
            return `${getYear}-${getMouth}-${getDay}`;
        };

        setDateMinDisable(parseDateMin(parseDate, 2));
        setDateMaxDisable(parseDateMin(parseDateMax, 4));
    }, []);

    return (
        <div
            className={active ? `${s.modal} ${s.active}` : s.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.modal__header}>
                    <div className={s.modal__title}>{title}</div>
                </div>
                <div className={s.modal__box} style={{ overflowX: "hidden" }}>
                    {fields.map((item, index) => {
                        let el;
                        const valKey = item.optProps ? item.optProps[0] : 'id';
                        const titleKey = item.optProps ? item.optProps[1] : 'title';

                        switch (item.type) {
                            case ('phone'):
                                el = (
                                    <InputWrapper label={item.label} id={`ps-${item.name}`}>
                                        <InputMask mask="+389999999999" value={sendData[item.name]} onChange={handlerOnChange}>
                                            {(inputProps) =>
                                                <input
                                                    className={s_input.input}
                                                    type={item.type}
                                                    placeholder={item.label}
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    name={item.name}
                                                    id={`ps-${item.name}`}
                                                    value={sendData[item.name]}
                                                    onChange={handlerOnChange}
                                                    {...inputProps}
                                                />
                                            }
                                        </InputMask>
                                    </InputWrapper>
                                );

                                break;

                            case ('date'):
                                el = (
                                    <InputWrapper
                                        label={item.label}
                                        id={`ps-${item.name}`}
                                    >
                                        {item?.min ? (
                                            <input
                                                className={s_input.input}
                                                type={item.type}
                                                placeholder={item.label}
                                                maxLength={INPUT_MAX_LENGTH}
                                                name={item.name}
                                                id={`ps-${item.name}`}
                                                value={sendData[item.name] ? sendData[item.name] : dateMinDisable}
                                                onChange={handlerOnChange}
                                                min={dateMinDisable}
                                                max={dateMaxDisable}
                                            />
                                        ) : (
                                            <input
                                                className={s_input.input}
                                                type={item.type}
                                                placeholder={item.label}
                                                maxLength={INPUT_MAX_LENGTH}
                                                name={item.name}
                                                id={`ps-${item.name}`}
                                                value={sendData[item.name]}
                                                onChange={handlerOnChange}
                                            />
                                        )}

                                    </InputWrapper>
                                );

                                break;

                            case ('text'):
                            case ('email'):
                                el = (
                                    <InputWrapper
                                        label={item.label}
                                        id={`ps-${item.name}`}
                                    >
                                        <input
                                            className={s_input.input}
                                            type={item.type}
                                            placeholder={item.label}
                                            maxLength={INPUT_MAX_LENGTH}
                                            name={item.name}
                                            id={`ps-${item.name}`}
                                            value={sendData[item.name]}
                                            onChange={handlerOnChange}
                                            // onKeyDown={onEnterPress}
                                            // defaultValue={urlParams && urlParams[f.name]}
                                            {...item.attr}
                                        />
                                    </InputWrapper>
                                );

                                break;

                            case ('number'):
                                el = (
                                    <InputWrapper
                                        label={item.label}
                                        id={`ps-${item.name}`}
                                    >
                                        <input
                                            className={s_input.input}
                                            type={item.type}
                                            placeholder={item.label}
                                            maxLength={INPUT_MAX_LENGTH}
                                            name={item.name}
                                            id={`ps-${item.name}`}
                                            value={sendData[item.name]}
                                            onChange={handlerOnChange}
                                            {...item.attr}
                                        />
                                    </InputWrapper>
                                );

                                break;

                            case ('checkbox'):
                                el = (
                                    <InputWrapper
                                        label={item.label}
                                        id={`ps-${item.name}`}
                                    >
                                        <div className={s_Select.checkbox__wrapper}>
                                            <input
                                                className={s_Select.checkbox}
                                                type='checkbox'
                                                name={item.name}
                                                id={item.id}
                                                {...item.attr}
                                            />
                                            <label htmlFor={item.id} className={`${s_Select.label} ${s_Table.label}`} />
                                        </div>
                                    </InputWrapper>

                                );

                                break;

                            case ('select'):
                                el = (
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name={item.name}
                                                className={s_OrderStyle.select}
                                                onChange={handlerOnChange}
                                                {...item.attr}
                                            >
                                                <option value="">---</option>
                                                {item.options && item.options.map((e) => (
                                                    <option
                                                        value={e[valKey]}
                                                        key={uid(e)}
                                                    >
                                                        {e[titleKey]}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`to-${item?.name}`}>{item.placeholder}</label>
                                        </div>
                                    </div>
                                );

                                break;

                            case ('select_try_and_buy'):
                                const copyCities = item?.options
                                const uniqueCities = copyCities?.filter((shop, index, self) =>
                                    index === self.findIndex((s) => s.settlement?.id === shop.settlement?.id)
                                );

                                const copyShops = item?.options
                                const uniqueShops = copyShops?.filter((shop, index) => {
                                    return shop?.settlement?.id === +sendData?.settlement_id
                                });

                                let arrayForSearch = item.name !== 'shop_id' ? item.options : uniqueShops
                                el = (
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name={item.name}
                                                className={s_OrderStyle.select}
                                                onChange={handlerOnChange}
                                                {...item.attr}
                                            >
                                                <option value="">---</option>

                                                {item.name !== 'settlement_id' ? item.options && arrayForSearch.map((e) => (
                                                    <option
                                                        value={e[valKey]}
                                                        key={uid(e)}
                                                    >
                                                        {e[titleKey]}
                                                    </option>
                                                )) : uniqueCities && uniqueCities.map((e) => (
                                                    <option
                                                        value={e?.settlement?.id}
                                                        key={uid(e?.settlement?.id)}
                                                    >
                                                        {e?.settlement?.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`to-${item?.name}`}>{item.placeholder}</label>
                                        </div>
                                    </div>
                                );

                                break;
                            case ('textarea'):
                                el = (
                                    <div className={s_OrderStyle.textarea__wrapper}>
                                        <textarea
                                            name={item.name}
                                            className={s_OrderStyle.textarea}
                                            id={item.id}
                                            placeholder={item.placeholder}
                                            rows={item?.row ? item?.row : '4'}
                                            maxLength={INPUT_MAX_LENGTH}
                                            onChange={handlerOnChange}
                                        />
                                        <label className={s_OrderStyle.label__textarea} htmlFor={item.id}>
                                            {item.placeholder}
                                        </label>
                                    </div>
                                );

                                break;

                            default:
                                el = null;
                        }

                        return (
                            <Fragment key={index}>
                                <Group attrs={{className: 'mb-1'}}>
                                    <ColFull>
                                        {el}
                                    </ColFull>
                                </Group>
                            </Fragment>
                        );
                    })}
                    {dynamicData && dynamicData?.length !== 0 && dynamicData.map((item, index) => {
                        let el;

                        el = (
                            <div className={s_OrderStyle.select__inner}>
                                <div className={s_OrderStyle.select__wrapper}>
                                    <select
                                        id={item.id}
                                        name={item.name}
                                        className={s_OrderStyle.select}
                                        onChange={handlerOnChange}
                                    >
                                        <option value="">---</option>
                                        {!!item?.options?.length && item.options.map((e) => (
                                            <option
                                                value={e?.id}
                                                key={e?.id || index}
                                            >
                                                {e?.title}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_OrderStyle.label} htmlFor={apiFields?.[index]?.idInput}>
                                        {apiFields?.[index]?.labelInput}
                                    </label>
                                </div>
                            </div>
                        );

                        return (
                            <Fragment key={index}>
                                <Group attrs={{className: 'mb-1'}}>
                                    <ColFull>
                                        {el}
                                    </ColFull>
                                </Group>
                            </Fragment>
                        );
                    })}
                </div>
                <div className={s.modal__footer}>
                    <div className={s.modal__group}>
                        <div>
                            <Button purple onClick={createItem} disabled={load}>Создать</Button>
                        </div>
                        <div>
                            <Button border onClick={() => {
                                setActive(false);
                                setDynamicData([])
                            }}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;