import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import ru from "date-fns/locale/ru";
import { VALIDATION } from '../../const/text';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { INPUT_MAX_LENGTH } from "../../const/view";
import { APPLICATION_STATUS } from "../../lib/const/product";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

registerLocale('ru', ru)
setDefaultLocale(ru)

const ProductConsultationEditView = () => {
  const { id: pcID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    watch
  } = formH;
  const { load, data, submitHandler } = useEdit({
    targetID: pcID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/product-consultation/not-found',
      remove: `/app/product-consultation${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/product-consultation${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Форма консультации по товарам создана',
      edit: 'Форма консультации по товарам обновлена',
      remove: 'Форма консультации по товарам удалена',
    },
  });
  const [startDate, setStartDate] = useState(new Date());
  const [wareHouseData, setWareHouseData] = useState([]);
  const bookedWatch = watch('booked_for');
  const cityWatch = watch('city_id');

  useEffect(() => {
    if(data?.fields?.booked_for) {
      setStartDate(new Date(bookedWatch))
    }
  }, [data]);



  useEffect(() => {
    if(cityWatch) {
      const wareData = data?.cities.find(city => city.id === +cityWatch).warehouses;
      setWareHouseData(wareData);
    }
  }, [cityWatch]);

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product consultation edit`} />
      <div className="box-wrap">
        {pcID
          ? (
            <Title>
              Форма консультации по товарам -
              <small>{data && data.fields.id}</small>
            </Title>
          )
          : <Title>Новая форма консультации по товарам</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* name */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-name">ФИО*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.name,
                })}
                name="name"
                type="text"
                id="pf-name"
                placeholder="Введите имя"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.name
              && <p className="form-input-hint">{errors.name.message}</p>}
            </ColRight>
          </Group>

          {/* phone */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-phone">Телефон*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.phone,
                })}
                name="phone"
                type="phone"
                id="pf-phone"
                placeholder="Введите номер телефона"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.phone
              && <p className="form-input-hint">{errors.phone.message}</p>}
            </ColRight>
          </Group>

          {/* email */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-email">Email*:</label>
            </ColLeft>
            <ColRight>
              <input
                  className={cn('form-input', {
                    'is-error': errors.email,
                  })}
                  name="email"
                  type="text"
                  id="pf-email"
                  placeholder="Введите email"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.email && <p className="form-input-hint">{errors.email.message}</p>}
            </ColRight>
          </Group>

          {/* booked_for */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pc-booked-for">Дата и время консультации*:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="booked_for"
                  type="hidden"
                  value={startDate}
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register}
              />
              {data
                  ? (
                      <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          onSelect={(date) => setStartDate(date)}
                          timeInputLabel="Время:"
                          dateFormat="dd-MM-yyyy HH:mm"
                          showTimeInput
                          ref={register}
                          name="booked_for"
                          wrapperClassName="w-100"
                          className={cn('form-input')}
                      />
                  )
                  : (
                      <div className="p-relative">
                        <div className="loading" />
                      </div>
                  )}
            </ColRight>
          </Group>

          {/* topic_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pс-topic-id">Товар*:</label>
            </ColLeft>
            <ColRight>
              <select
                name="topic_id"
                className='form-select'
                id="pс-topic-id"
                ref={register}
              >
                <option value="">выберите устройство</option>
                {data?.topics?.map((topic) => (
                    <option
                        key={topic.id}
                        value={topic.id}
                        selected={topic.id === data?.fields?.topic_id}
                    >
                      {topic.title_ru || topic.title_ua}
                    </option>
                ))}
              </select>
            </ColRight>
          </Group>

          {/* status */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-status">Выбрать cтатус запроса:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="status"
                      className='form-select'
                      id="pf-status"
                      ref={register}
                    >
                      <option value="">выберите cтатус запроса</option>
                      {APPLICATION_STATUS.map((status) => (
                        <option
                          key={status.id}
                          value={status.id}
                        >
                          {status.title}
                        </option>
                      ))}
                    </select>
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* is_online */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-is-online">Онлайн:</label>
            </ColLeft>
            <ColRight>
              <label className="form-switch">
                <input
                    name="is_online"
                    type="checkbox"
                    id="pf-is-online"
                    defaultChecked={data?.fields?.is_online}
                    ref={register}
                />
                <i className="form-icon" />
              </label>
            </ColRight>
          </Group>

          {/* channel_type */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-channel-type">Канал:</label>
            </ColLeft>
            <ColRight>
              <input
                  className={'form-input'}
                  name="channel_type"
                  type="text"
                  id="pf-channel-type"
                  placeholder="Введите канал"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* manager_id */}
            <Group>
              <ColLeft>
                <label className="form-label" htmlFor="pf-manager-id">
                  Ответственный менеджер:
                </label>
              </ColLeft>
              <ColRight>
                <select
                    name="manager_id"
                    className='form-select'
                    id="pс-manager-id"
                    ref={register}
                >
                  <option value="">выберите менеджера</option>
                  {data?.specialists?.map((specialist) => (
                      <option
                          key={specialist.id}
                          value={specialist.id}
                          selected={specialist.id === data?.fields?.manager_id}
                      >
                        {specialist.name_ru || specialist.name_ua}
                      </option>
                  ))}
                </select>
              </ColRight>
            </Group>

          {/* city_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-city-id">
                Город:
              </label>
            </ColLeft>
            <ColRight>
              <select
                  name="city_id"
                  className='form-select'
                  id="pс-city-id"
                  ref={register}
              >
                <option value="">выберите город</option>
                {data?.cities?.map((city) => (
                    <option
                        key={city.id}
                        value={city.id}
                        selected={city.id === data?.fields?.city_id}
                    >
                      {city.title}
                    </option>
                ))}
              </select>
            </ColRight>
          </Group>

          {/* warehouse_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-warehouse-id">
                Магазин:
              </label>
            </ColLeft>
            <ColRight>
              <select
                  name="warehouse_id"
                  className='form-select'
                  id="pс-warehouse-id"
                  ref={register}
              >
                <option value="">выберите магазин</option>
                {wareHouseData?.map((wareHouse) => (
                    <option
                        key={wareHouse.id}
                        value={wareHouse.id}
                        selected={wareHouse.id === data?.fields?.warehouse_id}
                    >
                      {wareHouse.title}
                    </option>
                ))}
              </select>
            </ColRight>
          </Group>

          </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default ProductConsultationEditView;
