import grapesjs from "grapesjs";
import loadComponents from "./components";
import loadBlock from "./blocks";

export default grapesjs.plugins.add('showOnDomainComponent', (editor, opts) => {
  let options = {
    label: 'Domain Block',
    name: 'DomainBlock',
    category: 'Show Domain',
  };

  for(let name in options) {
    if(!(name in opts)) opts[name] = options[name];
  }

  loadBlock(editor, opts);
  loadComponents(editor, opts);
})