import { getStatusChange, removeStatusChange } from '../../lib/api/status_change';
import { getRoles } from "../../lib/api/roles";
import { getStatusAll } from "../../lib/api/statuses";

export const getFiltersData = () => Promise.allSettled([getRoles(), getStatusAll()])
    .then((res) => ({
          roles: res[0]?.value?.data?.data,
          statuses: res[1]?.value?.data?.data,
        }));

export const getData = getStatusChange;
export const remove = removeStatusChange;
