import Constants from "./constants";

export const initialSate = {};

const reducer = (state = initialSate, action) => {
    switch (action.type) {
        case Constants.ON_CHANGE:
            return {
                ...state,
                ...action.payload,
            };
        case Constants.NEW_BPM_DISCOUNT:
            return {
                ...state,
                isBPMDiscount: action.payload,
            }
        case Constants.ON_CHANGE_OBJ:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    [action.payload.key]: action.payload.value,
                    'id': action.payload.id,
                }
            };

        case Constants.ON_CHANGE_DELIVERY_OBJ:
            if (state?.delivery?.[action?.payload?.name]) {
                return {
                    ...state,
                    'delivery': {
                        ...state['delivery'],
                        'id': action.payload.deliveryId,
                        [action.payload.name]: {
                            ...state.delivery[action.payload.name],
                            [action.payload.key]: action.payload.value,
                            'id': action.payload.childId,
                        }
                    }
                }
            }
            return {
                ...state,
                'delivery': {
                    ...state['delivery'],
                    'id': action.payload.deliveryId,
                    [action.payload.name]: {
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.childId,
                    }
                }
            };

        case Constants.ON_CHANGE_PRODUCT:
            if (!!state?.products?.length) {
                const products = [...state.products];
                let findProduct = products.find(prod => prod.id === action.payload.id);
                const removeProduct = products.filter(prod => prod.id !== action.payload.id);

                let result = [];
                if (findProduct) {
                    findProduct = {
                        ...findProduct,
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    }
                    result = [...removeProduct, findProduct];
                } else {
                    result = [...removeProduct];
                    result.push({
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    });
                }

                return {
                    ...state,
                    products: [...result]
                };
            } else {
                const products = [];
                products.push({
                    [action.payload.key]: action.payload.value,
                    'id': action.payload.id,
                });
                return {
                    ...state,
                    products
                };
            }

        case Constants.ON_CHANGE_PRODUCT_SERVICE:
            if (!!state?.products?.length) {
                const products = [...state.products];
                let findProduct = products.find(prod => prod.id === action.payload.prodId);
                const removeProduct = products.filter(prod => prod.id !== action.payload.prodId);
                let result = [];

                if (!!findProduct?.additional_services?.length) {
                    findProduct = {
                        ...findProduct,
                        'id': action.payload.prodId,
                    }

                    let findService = findProduct.additional_services.find(service => service.id === action.payload.id);
                    const removeService = findProduct.additional_services.filter(service => service.id !== action.payload.id);
                    findService = {
                        ...findService,
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    }

                    findProduct = {
                        ...findProduct,
                        'additional_services': [...removeService, findService]
                    }
                    result = [...removeProduct, findProduct];
                    return {
                        ...state,
                        products: [...result]
                    };
                } else {
                    const additionalService = [{
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    }];
                    findProduct = {
                        ...findProduct,
                        'id': action.payload.prodId,
                        'additional_services': [...additionalService],
                    }

                    result = [...removeProduct, findProduct];

                    return {
                        ...state,
                        products: [...result]
                    };
                }

            } else {
                const products = [];
                const additionalService = [{
                    [action.payload.key]: action.payload.value,
                    'id': action.payload.id,
                }];
                products[0] = {
                    'id': action.payload.prodId,
                    'additional_services': [...additionalService],
                }
                return {
                    ...state,
                    products: [...products]
                };
            }

        case Constants.ON_CHANGE_PRODUCT_SERVICE_MODAL:
            if (!!state?.products?.length) {
                const tempProducts = [...state.products];
                const findProduct = tempProducts?.findIndex(prod => prod?.id?.toString() === action?.payload?.product_id?.toString());

                if ((findProduct >= 0) && tempProducts) {
                    const tempService = [...tempProducts[findProduct]?.additional_services];

                    if (typeof tempService !== 'undefined') {
                        tempProducts[findProduct]['additional_services'] = [
                            ...tempService,
                            {...action?.payload?.data},
                        ];
                    }
                } else {
                    tempProducts.push({id: action?.payload?.product_id, additional_services: [action?.payload?.data]});

                }

                return {
                    ...state,
                    products: [...tempProducts]
                };

            } else {
                let products = [];
                const additionalService = [{
                    ...action.payload.data,
                    [action.payload.key]: action.payload.value,
                }];
                products = [
                    {
                        'id': action.payload.product_id,
                        'additional_services': [...additionalService],
                    }
                ];

                return {
                    ...state,
                    products: [...products]
                };
            }

        case Constants.REMOVE_PRODUCT_SERVICE_MODAL:
            if (!!state?.products?.length) {
                const tempProducts = [...state?.products];
                const findProduct = tempProducts?.findIndex(prod => prod?.id?.toString() === action?.payload?.product_id?.toString());
                const findService = tempProducts[findProduct]?.additional_services?.findIndex(service => service?.id?.toString() === action.payload.service_id?.toString());

                const tempService = [...tempProducts[findProduct]?.additional_services];

                tempService.splice(findService, 1);

                tempProducts[findProduct] = {
                    'id': action.payload.product_id,
                    additional_services: [...tempService],
                };

                return {
                    ...state,
                    products: [...tempProducts]
                };
            } else {
                return {
                    ...state,
                };
            }

        case Constants.ON_CHANGE_PRODUCT_OFFER:
            if (!!state?.products?.length) {
                const products = [...state.products];
                let findProduct = products.find(prod => prod.id === action.payload.prodId);
                const removeProduct = products.filter(prod => prod.id !== action.payload.prodId);
                let result = [];

                if (!!findProduct?.special_offers?.length) {
                    findProduct = {
                        ...findProduct,
                        'id': action.payload.prodId,
                    }

                    let findOffer = findProduct.special_offers.find(service => service.id === action.payload.id);
                    const removeOffer = findProduct.special_offers.filter(service => service.id !== action.payload.id);
                    findOffer = {
                        ...findOffer,
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    }

                    findProduct = {
                        ...findProduct,
                        'special_offers': [...removeOffer, findOffer]
                    }
                    result = [...removeProduct, findProduct];
                    return {
                        ...state,
                        products: [...result]
                    };
                } else {
                    const specialOffers = [{
                        [action.payload.key]: action.payload.value,
                        'id': action.payload.id,
                    }];
                    findProduct = {
                        ...findProduct,
                        'id': action.payload.prodId,
                        'special_offers': [...specialOffers],
                    }

                    result = [...removeProduct, findProduct];

                    return {
                        ...state,
                        products: [...result]
                    };
                }

            } else {
                const products = [];
                const specialOffers = [{
                    [action.payload.key]: action.payload.value,
                    'id': action.payload.id,
                }];
                products[0] = {
                    'id': action.payload.prodId,
                    'special_offers': [...specialOffers],
                }
                return {
                    ...state,
                    products: [...products]
                };
            }
        // if(!!state?.products?.length) {
        //   if(!!state?.products[action.payload.index]?.special_offers?.length) {
        //     const products = [...state.products];
        //     products[action.payload.index] = {
        //       ...products[action.payload.index],
        //       'id': action.payload.prodId,
        //     }
        //     products[action.payload.index]['special_offers'][action.payload.indexOffer] = {
        //       ...products[action.payload.index]['special_offers'][action.payload.indexOffer],
        //       [action.payload.key]: action.payload.value,
        //       'id': action.payload.id,
        //     };
        //     return {
        //       ...state,
        //       products: [...products]
        //     };
        //   } else {
        //     const products = [...state.products];
        //     const specialOffers = new Array(action.payload.indexOffer);
        //     products[action.payload.index] = {
        //       ...products[action.payload.index],
        //       'special_offers': [...specialOffers],
        //       'id': action.payload.prodId,
        //     }
        //     products[action.payload.index].special_offers[action.payload.indexOffer] = {
        //       [action.payload.key]: action.payload.value,
        //       'id': action.payload.id,
        //     };
        //     return {
        //       ...state,
        //       products: [...products]
        //     };
        //   }
        //
        // } else {
        //   const products = [];
        //   const specialOffers = [{
        //     [action.payload.key]: action.payload.value,
        //     'id': action.payload.id,
        //   }];
        //   products.push({
        //     'id': action.payload.prodId,
        //     'special_offers': [...specialOffers],
        //   })
        //   return {
        //     ...state,
        //     products: [...products]
        //   };
        // }

        case Constants.CLEAR_FORM:
            return {};
        case Constants.ADD_SELECTED_ORDER:
            return {...state, selectedOrders: action.payload};

        default:
            return state;
    }
};

export default reducer;
