import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const BanksEditView = () => {
  const { id: domainID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: domainID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/domain/not-found',
      remove: `/app/domains${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/domains${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Домен создан',
      edit: 'Домен обновлен',
      remove: 'Домен удален',
    },
  });
  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Domain edit`} />
      <div className="box-wrap">
        {domainID
          ? (
            <Title>
              Домен -
              <small>{data && data.fields.title}</small>
            </Title>
          )
          : <Title>Новый домен</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-name-ru">Название*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title"
                className={cn('form-input', {
                  'is-error': errors.title,
                })}
                type="text"
                id="domain-name"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
            </ColRight>
          </Group>

          {/* domain */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-name-ua">Домен*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="domain"
                className={cn('form-input', {
                  'is-error': errors.domain,
                })}
                type="text"
                id="domain-name"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.domain && <p className="form-input-hint">{errors.domain.message}</p>}
            </ColRight>
          </Group>
        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default BanksEditView;
