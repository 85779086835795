import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove, getFiltersData } from "./api";
import s from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";
import getTZtoDate from "../../lib/getTZtoDate";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";

const SubscribersList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    const ref = useRef(null);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = async () => {
        if (ref?.current?.checked) {
            setSelectAll(true);
            const temp = rest?.data?.map(e => e.id);
            setSelected(temp);
        } else {
            setSelectAll(false);
            setSelected([]);
        }
    };

    const handleSelect = (id) => {
        const temp = [...selected];
        const index = temp.indexOf(id);

        if (index !== -1) {
            temp.splice(index, 1);
            setSelected(temp);
        } else setSelected((prev) => ([...prev, id]));
    };

    useEffect(() => {
        if (!!selected?.length) {
            localStorage.setItem('ids', JSON.stringify(selected));
        } else {
            localStorage.removeItem('ids');
        }
    }, [selected]);

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Subscriptions list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={[
                        '', 'ID', "Ім'я", 'Прізвище', 'Телефон', 'Email', 'Instagram', 'Сторінка', 'Створено'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {
                                type: 'checkbox', name: 'select_all', id: 'table-select_all', attr: {
                                    onClick: () => handleSelectAll(),
                                    defaultChecked: selectAll,
                                    ref
                                }
                            },
                            {},
                            {type: 'text', name: 'name'},
                            {type: 'text', name: 'surname'},
                            {type: 'text', name: 'phone'},
                            {type: 'text', name: 'email'},
                            {type: 'text', name: 'instagram'},
                            {
                                type: 'select',
                                name: 'type',
                                options: filtersData?.types && filtersData.types,
                                optProps: ['value', 'title']
                            },
                            {type: 'date_range', startName: 'date_from', endName: 'date_to', width: '18%'}
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={8}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`subscriber-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Подписка удалена',
                                    }}
                                    editLink={`/app/subscriber/update/${e.id}`}
                                    controlsOff
                                >
                                    <td>
                                        <div className={s.checkbox__wrapper}>
                                            <input
                                                className={s.checkbox}
                                                type="checkbox"
                                                id={`table_product_${i}`}
                                                name={`product_${i}`}
                                                checked={selected?.find((i) => i === e.id)}
                                                onClick={() => handleSelect(e.id)}
                                            />
                                            <label htmlFor={`table_product_${i}`}
                                                   className={`${s.label} ${s_Table.label}`}/>
                                        </div>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.surname || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.email || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.instagram || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.type || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={8}>Немає подписок</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default SubscribersList;
