import { useEffect } from 'react';

/**
 * Hook for toggle body className per page
 *
 * @param name
 */
const useLayout = (name) => {
  useEffect(() => {
    document.body.classList.add(name);
    return () => document.body.classList.remove(name);
  }, [name]);
};

export default useLayout;
