import {
  TEMPLATE_GET_EDIT, TEMPLATE_CREATE,
  TEMPLATE_GET, TEMPLATE_EDIT, TEMPLATE_DELETE
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getTemplates = (params) => fetchAx({
  url: TEMPLATE_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createTemplate = (data) => fetchAx({
  url: TEMPLATE_CREATE,
  method: 'POST',
  data
});

export const getTemplateEdit = (id) => fetchAx({
  url: TEMPLATE_GET_EDIT.join(id),
  method: 'GET',
});

export const editTemplate = (id, data) => fetchAx({
  url: TEMPLATE_EDIT.join(id),
  method: 'PUT',
  data
});

export const removeTemplate = (id) => fetchAx({
  url: TEMPLATE_DELETE.join(id),
  method: 'DELETE',
});
