import React, { useState } from "react";
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputWrapper from "../../../components/ui/InputWrapper";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import icon_info from "../../../assets/icons/icon_info.svg";

const OrderUserInfo = ({ formProps }) => {
    const form = formProps.formH;
    const dataFields = formProps.data && formProps.data.fields;
    const [phoneInfo, setPhoneInfo] = useState(false);

    return(
        <Item title="Данные заказчика">
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag="div">

                    {/* user_info.surname */}
                    <Group attrs={{className: 'mb-1'}}>
                        <ColFull>
                            <InputWrapper
                                label="Фамилия*"
                                id="oui-surname"
                            >
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Фамилия*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="surname"
                                    id="oui-surname"
                                    value={dataFields?.user_info?.surname}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* user_info.name */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper
                                label="Имя*"
                                id="oui-name"
                            >
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Имя*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="name"
                                    id="oui-name"
                                    value={dataFields?.user_info?.name}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* user_info.patronymic */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Отчество" id="oui-patronymic">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Отчество"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="patronymic"
                                    id="oui-patronymic"
                                    value={dataFields?.user_info?.patronymic}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* user_info.email */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <InputWrapper label="E-mail" id="oui-email">
                                <input
                                    className={s.input}
                                    type="email"
                                    placeholder="E-mail"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="email"
                                    id="oui-email"
                                    value={dataFields?.user_info?.email}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* user_info.phone */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <InputWrapper
                                label="Телефон*"
                                id="oui-phone"
                            >
                                <input
                                    className={s.input}
                                    type="phone"
                                    placeholder="Телефон*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="phone"
                                    id="oui-phone"
                                    value={dataFields?.user_info?.phone}
                                    readOnly
                                />
                                <div className={s.phone_info} onClick={() => setPhoneInfo(!phoneInfo)}>
                                    <img src={icon_info} alt="Icon info" />
                                </div>
                            </InputWrapper>
                            {phoneInfo && <div className={s.phone_popup}><b>Например:</b> 0(44)441-02-23
                                или 0444410223</div>}
                        </ColFull>
                    </Group>

                    {/* user_info.callback_disabled */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <div className={s_Select.checkbox__wrapper}>
                                <input
                                    className={s_Select.checkbox}
                                    type="checkbox"
                                    id="oui-phone_dont_call_back"
                                    name="callback_disabled"
                                    ref={form.register}
                                    checked={dataFields?.user_info?.callback_disabled}
                                    readOnly
                                />
                                <label htmlFor="oui-phone_dont_call_back" className={s_Select.label}>Не перезванивать</label>
                            </div>
                        </ColFull>
                    </Group>

                    {/* user_info.card_number */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <InputWrapper label="Номер карточки покупателя" id="oui-card_number">
                                <input
                                    className={s.input}
                                    type="number"
                                    placeholder="Номер карточки покупателя"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="card_number"
                                    id="oui-card_number"
                                    value={dataFields?.user_info?.card_number}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* user_info.company_name */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <InputWrapper label="Компания" id="oui-company_name">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Компания"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="company_name"
                                    id="oui-company_name"
                                    value={dataFields?.user_info?.company_name}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    <input
                        type="hidden"
                        ref={form.register}
                        name="user_id"
                        value={dataFields?.user_id}
                    />

                    <input
                        type="hidden"
                        ref={form.register}
                        name="user_type"
                        value={dataFields?.user_type}
                    />

                </Form>
            </form>
        </Item>
    )
}

export default OrderUserInfo
