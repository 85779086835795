const parseCategories = (arrayCategories) => {
    return arrayCategories?.map(item => {
        const parseSecondLevel = item?.children?.map(itemSecond => {
            const parseThirdLevel = itemSecond?.children?.map(itemThird => {
                const parseFourthLevel = itemThird?.children?.map(itemFourth => ({
                    value: itemFourth.id,
                    label: itemFourth.title,
                    children: null,
                }));
                return {
                    value: itemThird.id,
                    label: itemThird.title,
                    children: parseFourthLevel,
                };
            });
            return {
                value: itemSecond.id,
                label: itemSecond.title,
                children: parseThirdLevel,
            };
        })
        return {
            value: item.id,
            label: item.title,
            children: parseSecondLevel,
        };
    });
};

export default parseCategories;