import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import useEdit from '../../lib/crud/useEdit';
import {
    getData, create, edit, remove, parseDataGet, parseDataSend
} from './api';
import { Editor } from "@tinymce/tinymce-react";
import TopPanel from "./TopPanel/TopPanel";
import {DEVELOPMENT_ENVIRONMENT, TINY_API_KEY} from "../../const/api";
import {Helmet} from "react-helmet";

const OrderDocumentEditView = () => {
    const { '*': url, id: documentID } = useParams();
    const parseUrl = url.split('/');
    const formH = useForm();
    const orderId = parseUrl[2];

    const {
        handleSubmit,
        reset,
        register,
        watch,
    } = formH;

    const { data, load, submitHandler } = useEdit({
        targetID: documentID,
        // updateFields: reset,
        handleSubmit,
        orderID: orderId,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: `/app/order/update/${orderId}/document/not-found`,
            remove: `/app/order/update/${orderId}/document/`,
            save: `/app/order/update/${orderId}/document/`,
        },
        alerts: {
            create: 'Документ создан',
            edit: 'Документ обновлен',
            remove: 'Документ удален',
        },
    });

    const formProps = {
        documentID,
        data,
        handleSubmit,
        submitHandler,
        formH,
        load
    };

    const initialValue = formProps?.data?.fields?.text ? formProps?.data?.fields?.text : '';

    const [value, setValue] = useState(initialValue ?? '');

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Order document edit`} />
            <TopPanel formProps={formProps} orderID={orderId} documentID={data?.fields?.id} printData={formProps?.data?.fields?.text} breadcrumbs={{
                items: [
                    {
                        title: 'Головна',
                        url: '/app',
                    },
                    {
                        title: 'Заказы',
                        url: '/app/orders',
                    },
                    {
                        title: `Samsung: Заказ #${orderId}`,
                        url: `/app/order/update/${orderId}`,
                    },
                    {
                        title: 'Документы',
                        url: `/app/order/update/${orderId}/document`,
                    },
                    {
                        title: `Документ #${data?.fields?.id}`
                    }
                ]}} />
            <div>
                <Editor
                    apiKey={TINY_API_KEY}
                    initialValue={initialValue}
                    value={value}
                    onEditorChange={(newValue) => setValue(newValue)}
                    init={{
                        width: '100%',
                        height: '900px',
                        menubar: false,
                        language: 'ru',
                        extended_valid_elements: 'script[language|type|src]',
                        toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',
                        image_advtab: true,

                        file_picker_callback: function (cb) {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {
                                const file = this.files[0];

                                const reader = new FileReader();
                                reader.onload = function () {

                                    const id = 'blobid' + (new Date()).getTime();
                                    const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                    const base64 = reader.result.split(',')[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };

                            input.click();
                        },
                        plugins: ['link', 'code', 'lists', 'preview'],
                        content_style: "@import url('https://fonts.cdnfonts.com/css/dejavu-sans'); body { font-family:DejaVu Sans,sans-serif !important; font-size:14px } table { font-family:DejaVu Sans,sans-serif !important } table > tbody > tr > td {font-family:DejaVu Sans,sans-serif;}",
                        // content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />
                <input type="text" name="text" value={value} ref={register} style={{ display: 'none' }} readOnly />
                <input type="text" name="manager_id" value={data?.fields?.manager_id} ref={register} style={{ display: 'none' }} readOnly />
            </div>
        </div>
    );
};

export default OrderDocumentEditView;