import React from "react";
import s from "../../../../components/ui/Modal/Modal.module.scss";
import Button from "../../../../components/ui/Button";

const ModalDelete = ({ active, setActive, title, funcRemove, indexProduct, idProduct }) => {
    return (
        <div
            className={active ? `${s.modal} ${s.active}` : s.modal}
            onClick={() => setActive()}
        >
            <div
                className={s.modal__content}
                style={{minWidth: 'auto'}}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.modal__header}>
                    <div className={s.modal__title} style={{textAlign: "center", marginRight: 0}}>
                        {title ? title : 'Видалити'}
                    </div>
                </div>
                <div className={s.modal__footer}>
                    <div className={s.modal__group} style={{justifyContent: "center"}}>
                        <div>
                            <Button border onClick={() => setActive()}>Ні, лишити!</Button>
                        </div>
                        <div>
                            <Button red onClick={() => {
                                if ((typeof indexProduct === "number") && (typeof idProduct === "number")) {
                                    return funcRemove(indexProduct, idProduct);
                                }
                                return funcRemove();
                            }}>Так, видалити!</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDelete;