import React from "react";
import { Link } from "react-router-dom";
import s from "./TopPanelNew.module.scss";
import Button from "../../ui/Button";
import icon_add from "../../../assets/icons/icon_add.svg";

const BtnCreate = ({ title, href }) => {
    return (
        <Link to={href} className={`link ${s.display}`}>
          <Button purple>
                <img src={icon_add} alt="Icon create" />
                {title}
          </Button>
        </Link>
    )
};

export default BtnCreate;