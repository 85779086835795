import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Item from "../Item";
import s from "./orderStyle.module.scss";
import getTZtoDate from "../../../lib/getTZtoDate";
import {getOrderSimilarEdit} from "../../../lib/api/order";
import errorMessage from "../../../lib/errorMessage";
import Button from "../../../components/ui/Button";

const OrderPreviousOrders = ({ formProps }) => {
    const dataFields = formProps.data && formProps.data.fields;

    const [similar, setSimilar] = useState([]);

    const getSimilar = async () => {
        await getOrderSimilarEdit(dataFields?.id)
            .then((res) => setSimilar(res?.data))
            .catch(err => errorMessage(err, ''));
    }

    return (
        <Item title={`Предыдущие заказы (${dataFields?.orders_count || 0})`} onClick={getSimilar} styleHeader={{ cursor: 'pointer' }}>
            <ul className={s.prevOrder}>
                {!!similar?.length && similar?.map((order, index) => {
                    if (index > 4) {
                        return null;
                    }
                    return (
                        <li className={s.prevOrder__item} key={index}>
                            <div className={s.prevOrder__block}>
                                <div className={s.prevOrder__box}><Link to={`/app/order/update/${order.id}`} target="_blank" rel="noopener noreferrer">№{order.id}</Link> от {getTZtoDate(order.created_at)}</div>
                                <div className={s.prevOrder__status}>
                                    {formProps?.data?.statuses.find(item => item.id === order.status)?.name}
                                </div>
                            </div>
                            {!!order?.items?.length && order?.items?.map((cart, ind) => (
                                <>
                                    <div className={s.prevOrder__block} key={`similar-order-item-${ind}`}>
                                        <div className={s.prevOrder__box_info}>{cart.title}</div>
                                        <div className={s.prevOrder__box_price}>
                                            <div>{cart.quantity} шт.</div>
                                            <div>{cart.total}</div>
                                        </div>
                                    </div>
                                    {!!cart?.special_offers?.length && cart?.special_offers?.map((specOffer, indSpecOffer) => (
                                        <div className={s.prevOrder__block} key={`similar-order-spec-offer-item-${indSpecOffer}`}>
                                            <div className={s.prevOrder__box_info}>{specOffer.title}</div>
                                            <div className={s.prevOrder__box_price}>
                                                <div>{specOffer.quantity} шт.</div>
                                                <div>{specOffer.total}</div>
                                            </div>
                                        </div>
                                    ))}
                                    {!!cart?.additional_services?.length && cart?.additional_services?.map((addService, indAddService) => (
                                        <div className={s.prevOrder__block} key={`similar-order-add-service-item-${indAddService}`}>
                                            <div className={s.prevOrder__box_info}>{addService.title}</div>
                                            <div className={s.prevOrder__box_price}>
                                                <div>{addService.quantity} шт.</div>
                                                <div>{addService.total}</div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ))}
                        </li>
                    )
                })}

                {similar?.length > 5 && dataFields?.user?.phone && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0' }}>
                            <Link to={`/app/orders?phone=${dataFields?.user?.phone}`} style={{ color: '#fff' }} target="_blank">
                                <Button purple small>
                                    Показать еще
                                </Button>
                            </Link>
                    </div>
                )}
            </ul>
        </Item>
    );
};

export default OrderPreviousOrders;