import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { VALIDATION } from '../../../const/text';
import Group from '../../../components/ui/FormEditView/Group';
import ColLeft from '../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../components/ui/FormEditView/ColRight';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH } from "../../../const/view";

const TabInfoUser = ({ index = 0, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const email = form.watch('email');
    const password = form.watch('password');

    useEffect(() => {
        if(email || password) {
            document.getElementById('pb2b-email')?.removeAttribute('readonly');
            document.getElementById('pb2b-password')?.removeAttribute('readonly');
        }
    }, [email, password]);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />
            <div style={{width: '700px'}}>
                {/* statuses */}
                <div className="box-wrap box-wrap--mb">
                    <Form Tag="div">
                        {/*surname*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-surname">Фамилия:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className='form-input'
                                    name="surname"
                                    type="text"
                                    id="pb2b-surname"
                                    placeholder="Введите фамилию"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                />
                            </ColRight>
                        </Group>
                        {/*name*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-name">Имя*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': form.errors.name,
                                    })}
                                    name="name"
                                    type="text"
                                    id="pb2b-name"
                                    placeholder="Введите имя"
                                    maxLength={INPUT_MAX_LENGTH}
                                    minLength={INPUT_MIN_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {form.errors.name
                                    && <p className="form-input-hint">{form.errors.name.message}</p>}
                            </ColRight>
                        </Group>
                        {/*patronymic*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-patronymic">Отчество:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className='form-input'
                                    name="patronymic"
                                    type="text"
                                    id="pb2b-patronymic"
                                    placeholder="Введите отчество"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                />
                            </ColRight>
                        </Group>
                        {/*email*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-email">Email*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': form.errors.email,
                                    })}
                                    name="email"
                                    type="email"
                                    id="pb2b-email"
                                    placeholder="Введите email"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {form.errors.email
                                    && <p className="form-input-hint">{form.errors.email.message}</p>}
                            </ColRight>
                        </Group>
                        {/*phone*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-phone">Телефон*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': form.errors.phone,
                                    })}
                                    name="phone"
                                    type="phone"
                                    id="pb2b-phone"
                                    placeholder="Введите номер телефона"
                                    maxLength={13}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {form.errors.phone
                                    && <p className="form-input-hint">{form.errors.phone.message}</p>}
                            </ColRight>
                        </Group>
                        {/*password*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-password">Пароль:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': form.errors.password,
                                    })}
                                    name="password"
                                    type="password"
                                    id="pb2b-password"
                                    placeholder="Введите пароль"
                                    maxLength={INPUT_MAX_LENGTH}
                                    minLength='6'
                                    ref={form.register}
                                    defaultValue=""
                                />
                                {form.errors.password
                                    && <p className="form-input-hint">{form.errors.password.message}</p>}
                            </ColRight>
                        </Group>
                        {/*status*/}
                        {formProps.clientID ?
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="as-status">Статус активности:</label>
                                </ColLeft>
                                <ColRight>
                                    <select
                                        name="status"
                                        className={cn('form-select', {
                                            'is-error': form.errors.status,
                                        })}
                                        id="as-status"
                                        ref={form.register({
                                            required: VALIDATION.req.required,
                                        })}
                                        // defaultValue="0"
                                    >
                                        <option value="">Выберите статус</option>
                                        <option value="active">Активен</option>
                                        <option value="disabled">Не активный</option>
                                        <option value="draft">Draft</option>
                                        <option value="verification-pending">Ожидает верификации</option>
                                    </select>
                                    {form.errors.status
                                        && <p className="form-input-hint">{form.errors.status.message}</p>}
                                </ColRight>
                            </Group>
                            :
                            <input type="hidden" name="is_active" value='0'  ref={form.register} /> // status client is default number 0
                        }

                        {/*domain_id*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="cm-domain-id">Домен:</label>
                            </ColLeft>
                            <ColRight>
                                {formProps
                                    ? (
                                        <>
                                            <select
                                                name="domain_id"
                                                className={cn('form-select', {
                                                    'is-error': form.errors.domain_id,
                                                })}
                                                id="cm-domain-id"
                                                ref={form.register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {!!formProps?.data?.domains?.length && formProps?.data.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                        selected={domain.id === dataFields?.domain_id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>

                                            {form.errors.domain_id
                                                && <p className="form-input-hint">{form.errors.domain_id.message}</p>}
                                        </>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading" />
                                        </div>
                                    )}
                            </ColRight>
                        </Group>
                    </Form>
                    </div>
                    {formProps.load && <Loader />}
                </div>
        </form>
);
};

export default TabInfoUser;
