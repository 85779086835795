import Select from "react-select";
import React from "react";
import s from "./RSelect.module.scss";

const RSelect = ({ props }) => (
  <Select
    placeholder="Виберіть значення"
    {...props}
    className={s.select}
    isClearable
  />
);

export default RSelect;
