import {
    BRAND_CREATE, BRAND_GET_EDIT, BRAND_EDIT, BRAND_DELETE, BRANDS_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBrands = (params) => fetchAx({
    url: BRANDS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const createBrand = (data) => fetchAx({
    url: BRAND_CREATE,
    method: 'POST',
    data,
});

export const getBrandEdit = (id) => fetchAx({
    url: BRAND_GET_EDIT.join(id),
    method: 'GET',
});

export const editBrand = (id, data) => fetchAx({
    url: BRAND_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeBrand = (id) => fetchAx({
    url: BRAND_DELETE.join(id),
    method: 'DELETE',
});
