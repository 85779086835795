import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { VALIDATION } from "../../const/text";
import { INPUT_MAX_LENGTH } from "../../const/view";
import useEdit from "../../lib/crud/useEdit";
import Loader from "../../components/ui/Loader";
import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import {
    create, edit, getData, parseDataGet, parseDataSend, remove,
} from "./api";
import useDomainCategoriesProducts from "../../lib/hooks/useDomainCategoriesProducts";
import s from "./InstallmentManagmentEditView.module.scss";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TreeCredit from "../../components/ui/TreeCredit";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";

const InstallmentManagementEditView = () => {
    const {id: IMID} = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
        watch,
    } = formH;

    const {load, data, submitHandler} = useEdit({
        targetID: IMID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/installment-management/not-found',
            remove: `/app/installment-management${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/installment-management${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Рассрочка создана',
            edit: 'Рассрочка обновлена',
            remove: 'Рассрочка удалена',
        },
    });

    const domainWatch = watch('domain_id');

    const {action, state} = useDomainCategoriesProducts({
        defaultValues: data?.fields,
    });
    /* eslint-disable */
    return (
        <div className={s.inner}>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Installment management edit`}/>
            <Form attrs={{
                onSubmit: handleSubmit(submitHandler),
                className: s.wrapper
            }}
            >
                <button type="submit" className="d-hide"/>
                <div className={s.block}>


                    {/* is_active */}
                    <Group attrs={{className: s.mb20}}>
                        <ColFull>
                            <CheckboxSwitch
                                name="is_active"
                                id="im-is-active"
                                label="Активность"
                                formH={formH}
                                defaultChecked={data?.fields?.is_active}
                            />
                        </ColFull>
                    </Group>

                    {/* title_ru */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Название рассрочки" id="im-name-ru">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.title_ru,
                                    })}
                                    type="text"
                                    placeholder="Название рассрочки РУ"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="title_ru"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="im-name-ru"
                                />
                            </InputWrapper>
                            {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
                        </ColFull>
                    </Group>

                    {/* bank_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {data
                                        ? (
                                            <select
                                                className={cn(s_Select.select, {
                                                    'is-error': errors.bank_id,
                                                })}
                                                name="bank_id"
                                                id="im-bank-id"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                <option value={null}>Банк не выбран</option>
                                                {data && !!data.banks && data.banks.map((bank) => (
                                                    <option
                                                        key={bank.id}
                                                        value={bank.id}
                                                    >
                                                        {bank.title_ru || bank.title_ua}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading"/>
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="im-bank-id">Банк</label>
                                </div>
                            </div>
                            {errors.bank_id && <p className="form-input-hint">{errors.bank_id.message}</p>}
                        </ColFull>
                    </Group>

                    {/* payment_count */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Количество платежей по рассрочке*" id="payment-count">
                                <input
                                    className={cn(s_Input.input, {
                                        'is-error': errors.payment_count,
                                    })}
                                    type="text"
                                    placeholder="Количество платежей по рассрочке*"
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="payment_count"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="payment-count"
                                />
                            </InputWrapper>
                            {errors.payment_count && <p className="form-input-hint">{errors.payment_count.message}</p>}
                        </ColFull>
                    </Group>

                    {/* coefficient */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Коэффициент" id="payment-count">
                                <input
                                    className={s_Input.input}
                                    type="number"
                                    placeholder="Коэффициент"
                                    ref={register}
                                    name="coefficient"
                                    maxLength={INPUT_MAX_LENGTH}
                                    id="payment-count"
                                    defaultValue={1}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {data
                                        ? (
                                            <select
                                                className={cn(s_Select.select, {
                                                    'is-error': errors.domain_id,
                                                })}
                                                name="domain_id"
                                                id="im-domain"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                                onClick={action.getDomain}
                                            >
                                                <option value={null}>Домен не выбран</option>
                                                {data && !!data.domains && data.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading"/>
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="im-domain">Домен</label>
                                </div>
                            </div>
                            {errors.domain_id
                            && <p className="form-input-hint">{errors.domain_id.message}</p>}
                        </ColFull>
                    </Group>

                </div>

                <TreeCredit
                    state={state}
                    action={action}
                    dataAll={data}
                    formH={formH}
                    domainId={domainWatch}
                />

            </Form>

            {load && <Loader/>}

        </div>
    );
};

export default InstallmentManagementEditView;
