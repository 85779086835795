import { uuid } from 'uuidv4';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import GridEditView from "../../components/ui/GridEditView";
import React, { useEffect, useRef, useState } from "react";
import Title from "../../components/ui/FormEditView/Title";
import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import ColLeft from "../../components/ui/FormEditView/ColLeft";
import ColRight from "../../components/ui/FormEditView/ColRight";
import cn from "classnames";
import { INPUT_MAX_LENGTH, INPUT_MIN_LENGTH } from "../../const/view";
import { VALIDATION } from "../../const/text";
import useEdit from "../../lib/crud/useEdit";
import { create, edit, getData, parseDataSend, remove } from "./api";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import ModalAdminVerification from "./Modal/ModalAdminVerification";
import BtnAdminGroupEdit from "./Modal/BtnAdminGroupEdit";
import TopPanel from "../../components/common/TopPanel";

const AdminEditView = () => {
    const { id: adminID } = useParams();
    const formH = useForm();
    const selectRef = useRef(null);

    const {
        watch,
        register,
        errors,
        handleSubmit,
        reset,
        control,
        formState
    } = formH;
    const { load, data, submitHandler } = useEdit({
        targetID: adminID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/admin/not-found',
            remove: `/app/admins${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/admins${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Админ создан',
            edit: 'Админ обновлен',
            remove: 'Админ удален',
        },
    });

    const [activeRoles, setActiveRoles] = useState(null);
    const [newData, setNewData] = useState({});
    const [activeModal, setActiveModal] = useState(false);

    useEffect(() => {
        if(data) {
            setActiveRoles(data?.fields?.roles)
            setNewData(data?.fields)
        }
    }, [data]);

    const updateDataHandler = (info) => {
        setNewData(prev => ({ ...prev, [info.name]: info.value }));
    }

    const getSelectedRoles = () => {
        const selectedRoles = Array.from(selectRef.current.selectedOptions)
            .map(option => option.value);

        setNewData(prev => ({ ...prev, ['role_ids']: selectedRoles }));
    };

    return (
        <>
            <TopPanel
                customStyles={true}
                breadcrumbs={{
                    items: [
                        {
                            title: 'Dashboard',
                            url: '/app',
                            permissionName: 'admin.admin.index',
                        },
                        {
                            title: 'Админы',
                            url: `/app/admins${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                            permissionName: 'admin.admin.index',
                        },
                        {
                            title: 'Админ',
                            permissionName: 'admin.admin.store',
                        },
                    ],
                }}
                right={<BtnAdminGroupEdit newData={newData} data={data} setActiveModal={setActiveModal}
                                          back={`/app/admins${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}

                                          update
                />}
            />
            <GridEditView>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Admins edit`} />
                <div className="box-wrap" style={{ marginTop: "100px" }}>
                    {adminID
                        ? (
                            <Title>
                                Админ -
                                <small>{data?.fields.id}</small>
                            </Title>
                        )
                        : <Title>Новый админ</Title>}

                    <Form
                        attrs={{
                            onSubmit: handleSubmit(submitHandler),
                        }}
                    >
                        <button type="submit" className="d-hide" />

                        {/* name */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-name">Имя*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': errors.name,
                                    })}
                                    name="name"
                                    type="text"
                                    id="pb2b-name"
                                    placeholder="Введите имя"
                                    maxLength={INPUT_MAX_LENGTH}
                                    minLength={INPUT_MIN_LENGTH}
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {errors.name
                                    && <p className="form-input-hint">{errors.name.message}</p>}
                            </ColRight>
                        </Group>

                        {/* email */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-email">Email*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': errors.email,
                                    })}
                                    name="email"
                                    type="email"
                                    id="pb2b-email"
                                    placeholder="Введите email"
                                    maxLength={INPUT_MAX_LENGTH}
                                    defaultValue={data?.fields?.email}
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {errors.email
                                    && <p className="form-input-hint">{errors.email.message}</p>}
                            </ColRight>
                        </Group>

                        {/* phone */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-phone">Телефон*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': errors.phone,
                                    })}
                                    name="phone"
                                    type="phone"
                                    id="pb2b-phone"
                                    placeholder="Введите номер телефона"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {errors.phone
                                    && <p className="form-input-hint">{errors.phone.message}</p>}
                            </ColRight>
                        </Group>
                        {/*password*/}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-password">Пароль:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input', {
                                        'is-error': errors.password,
                                    })}
                                    name='password'
                                    type="password"
                                    id="pb2b-password"
                                    placeholder="Введите пароль"
                                    maxLength={INPUT_MAX_LENGTH}
                                    minLength='6'
                                    defaultValue=""
                                    ref={register}
                                />
                                {errors.password
                                    && <p className="form-input-hint">{errors.password.message}</p>}
                            </ColRight>
                        </Group>

                        {/* status */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="admin-status">Выбрать статус:</label>
                            </ColLeft>
                            <ColRight>
                                {data
                                    ? (
                                        <>
                                            <select
                                                name="status"
                                                className="form-select"
                                                id="admin-status"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                                onChange={e => updateDataHandler({ name: e.target.name, value: e.target.value })}
                                            >
                                                <option value="">выберите статус</option>
                                                {data?.statuses?.map((status) => (
                                                    <option
                                                        key={status.value}
                                                        value={status.value}
                                                    >
                                                        {status.title}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors?.status
                                                && <p className="form-input-hint">{errors?.status?.message}</p>}
                                        </>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading" />
                                        </div>
                                    )}
                            </ColRight>
                        </Group>

                        {/* role_ids */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-role-ids">Роль*:</label>
                            </ColLeft>
                            <ColRight>
                                <>
                                    <select
                                        name="role_ids"
                                        className={cn('form-select', {
                                            'is-error': errors.role_ids,
                                        })}
                                        id="pb2b-role-ids"
                                        ref={(e) => {
                                            register({
                                                required: VALIDATION.select.required,
                                            })(e);
                                            selectRef.current = e;
                                        }}
                                        multiple
                                        value={newData?.role_ids}
                                        onChange={() => getSelectedRoles()}
                                    >
                                        <>
                                            <option disabled value="" selected>Выберите роль пользователя</option>
                                            {data && !!data.roles && data.roles.map((role) => (
                                                <option
                                                    key={`${uuid()}`}
                                                    value={role.id}
                                                    selected={activeRoles?.some((i) => i.id === role.id)}
                                                >
                                                    {role.name}
                                                </option>
                                            ))}
                                        </>
                                    </select>
                                    {errors.role_ids
                                        && <p className="form-input-hint">{errors.role_ids.message}</p>}</>
                            </ColRight>
                        </Group>

                        {/* internal_phone */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-email">Идентификатор в Бинотел:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    className={cn('form-input')}
                                    name="internal_phone"
                                    type="number"
                                    id="admin-internal_phone"
                                    placeholder="Введите идентификатор в Бинотел"
                                    ref={register}
                                    onChange={(e) => updateDataHandler({ name: e.target.name, value: e.target.value })}
                                    defaultValue={data?.fields?.internal_phone}
                                />
                            </ColRight>
                        </Group>

                        {/* prev_internal_phone */}
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="pb2b-prev_internal_phone">Бывший id в Бинотел:</label>
                            </ColLeft>
                            <ColRight>
                                <div id='prev_internal_phone'>{data?.fields?.internal_phones?.length ? data?.fields?.internal_phones[0]?.internal_phone : '---'}</div>
                            </ColRight>
                        </Group>

                    </Form>

                </div>

                <ModalAdminVerification active={activeModal} setActive={setActiveModal}/>
            </GridEditView>
        </>
    )
}
export default AdminEditView;