import forOwn from 'lodash/forOwn';
import _parseApi, { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';

import {
  createSpecOfferBannersTag,
  editSpecOfferBannersTag,
  getSpecOfferBannersTagEdit,
  removeSpecOfferBannersTag,
} from '../../lib/api/specOfferBannersTag';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (id) => Promise.allSettled([getDomains(), id && getSpecOfferBannersTagEdit(id)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: id && res[1]?.value?.data,
    })
  });

export const create = createSpecOfferBannersTag;
export const edit = editSpecOfferBannersTag;
export const remove = removeSpecOfferBannersTag;

export const parseDataSend = (data, catId) => {
  let d = { ...data };
  const isUpdate = typeof catId !== 'undefined';

  // const typeName = SPEC_TYPES[+d['types'] - 1].types;
  // d['characteristic_list'] = d['characteristic'][typeName];

  // parse all lvl1
  d = parseAll(d, !isUpdate,
    [
      'position',
    ]);

  // parse characteristic_list
  const CH_LIST = 'characteristic_list';
  forOwn(d[CH_LIST], (arr, k) => {
    d[CH_LIST] = _parseApi.parseAllArr(d[CH_LIST], [
      {
        name: k,
      },
    ], !isUpdate);
  });

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  // const typeName = SPEC_TYPES[+d['type'] - 1].type;
  //
  // d.characteristic_list = {
  // 	[typeName]: d['characteristic_list']
  // }

  return d;
};
