import React, { useState } from 'react';
import OrderProductTable from "./orderProductTable";
import TopPanel from "../TopPanel/TopPanel";
import { ModalAvailability } from "../../OrderEditView/fakeData/Modal";

const ProductsList = ({formProps}) => {
    const form = formProps.formH;
    const [productId, setProductId] = useState(null);
    const [activeAvailability, setActiveAvailability] = useState(false);

    /* eslint-disable */
    return (
        <>
            <TopPanel
                formProps={formProps}
                editBtn
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                        },
                        {
                            title: 'В козрине',
                            url: '/app/in-carts',
                        },
                        {
                            title: formProps?.data?.fields?.id ? `Samsung: Заказ #${formProps?.data?.fields?.id}` : 'Новый заказ',
                        },
                    ]
                }}
            />
            <OrderProductTable
                form={form}
                formProps={formProps}
                setActiveAvailability={setActiveAvailability}
                setProductId={setProductId}
            />
            {activeAvailability && (
                <ModalAvailability
                    active={activeAvailability}
                    setActive={setActiveAvailability}
                    productId={productId}
                    title="Наличие"
                />
            )}
        </>
    );
};

export default ProductsList;
