import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import InputFile from '../../components/ui/InputFile';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { getProductSearch } from '../../lib/api/products';
import PriceType from '../../components/ui/PriceType';
import InputFetchResult from "../../components/ui/InputFetchResultNew";
import useDomainCategoriesProducts from "../../lib/hooks/useDomainCategoriesProductsNew";
import removeDuplicates from "../../lib/helpers/removeDuplicates";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelNew from "../../components/common/TopPanelNew";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s from "./AdditionalServicesEdit.module.scss";
import s_OrderStyle from "../OrderEditView/fakeData/orderStyle.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import ColFull from "../../components/ui/FormEditView/ColFull";
import RSelect from "../../components/ui/RSelect";
import TreeCredit from "../../components/ui/TreeCreditNew";
import CopyComponent from "../../components/ui/CopyComponent";

const AdditionalServicesEditView = () => {
  const { id: addServiceID } = useParams();
  const navigate = useNavigate();
  const formH = useForm({
    defaultValues: {
      domain_id: '1'
    }
  });
  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
    reset,
    getValues
  } = formH;

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const [productsAList, setProductsAList] = useState([]);
  const [productsAJDEFilter, setProductsAJDEFilter] = useState([]);

  const [productsBList, setProductsBList] = useState([]);
  const [productsBJDEFilter, setProductsBJDEFilter] = useState([]);

  const [productByJDE, setProductByJDE] = useState(null)

  const { load, data, submitHandler } = useEdit({
    targetID: addServiceID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/additional-service/not-found',
      remove: `/app/additional-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/additional-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Дополнительный сервис создан',
      edit: 'Дополнительный сервис обновлен',
      remove: 'Дополнительный сервис удален',
    },
  });

  const [domainWatch, setDomainWatch] = useState(1);

  const [selectedRelatedAdditionalService, setSelectedRelatedAdditionalService] = useState(null);

  const parseCategories = useCallback(() => {
    const arr = categoriesFilter.length
      ? [...categoriesFilter]
      : data?.fields?.categories
        ? [...data?.fields?.categories]
        : [];

    const totArr = removeDuplicates(arr);
    setCategoriesList(totArr);
  }, [categoriesFilter, data?.fields?.categories]);

  const getProductByJDE = async (jde) => {
    const prod = await getProductSearch({'jde': jde})
    const res = prod?.data?.data[0]
    setProductByJDE(res)
    return res
  }

  useEffect(() => {
    if(data?.fields?.jde){
      getProductByJDE(data?.fields?.jde)
    }

    if(data?.fields?.categories){
      setCategoriesFilter(data?.fields?.categories)
    }
  }, [data?.fields])

  useEffect(() => {
    if (data?.fields?.products_turn_on) {
      setProductsAList([...data?.fields?.products_turn_on]);
      }
  }, [data?.fields?.products_turn_on]);

  useEffect(() => {
    if (data?.fields?.products_turn_off) {
      setProductsBList([...data?.fields?.products_turn_off]);
    }
  }, [data?.fields?.products_turn_off]);

  useEffect(() => {
    reset({...getValues()})
  },[data?.fields])

  const parseDataA = useCallback(() => {
    const jde = [...productsAJDEFilter]

    const totArr = [...jde];
    setProductsAList(totArr);
  }, [productsAJDEFilter]);

  const parseDataB = useCallback(() => {
    const jde = [...productsBJDEFilter]

    const totArr = [...jde];
    setProductsBList(totArr);
  }, [productsBJDEFilter]);

  useEffect(() => {
    setCategoriesList([]);
    parseCategories();
  }, [parseCategories]);

  useEffect(() => {
    setProductsAList([]);
    parseDataA();
  }, [parseDataA]);

  useEffect(() => {
    setProductsBList([]);
    parseDataB();
  }, [parseDataB]);

  const removeCategory = (e, el) => {
    e.preventDefault();
    const temp = [...categoriesList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setCategoriesList(temp);
    }
  };

  const { action, state } = useDomainCategoriesProducts({
    defaultValues: data?.fields,
    domainId: +domainWatch
  });

  useEffect(() => {
    if(!!data?.fields?.related_additional_service?.length) {
      setSelectedRelatedAdditionalService(data?.fields?.related_additional_service);
    }
  }, [data?.fields?.related_additional_service]);

  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Additional services edit`} />
      <Form attrs={{ onSubmit: handleSubmit(submitHandler) }}>
        <button type="submit" className="d-hide" />

        <TopPanelNew
            title={addServiceID ? data && data?.fields?.title_ua : "Новий додатковий сервіс"}
            breadcrumbs={{
              items: [
                {
                  title: "Dashboard",
                  url: "/app",
                },
                {
                  title: "Додаткові сервіси",
                  url: `/app/additional-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                },
                {
                  title: addServiceID ? data && data?.fields?.title_ua : "Новий додатковий сервіс",
                },
              ],
            }}
            back={`/app/additional-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
            btnEdit
            btnApply={!!addServiceID}
            showIsAutoAppend={(
                <CheckboxSwitch
                    name="is_auto_append"
                    id="im-is_auto_append"
                    label="Додавати автоматично"
                    formH={formH}
                    defaultChecked={data?.fields?.is_auto_append}
                />
            )}
            showIsActive={(
              <CheckboxSwitch
                  name="is_active"
                  id="im-is-active"
                  label="Активность"
                  formH={formH}
                  defaultChecked={data?.fields?.is_active}
              />
            )}
            btnRemove={{
              api: remove,
              alert: `Видалити “${data?.fields?.title_ua}”?`,
              href: `/app/additional-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              id: addServiceID,
              navigate
            }}
            marginNone
        />

        <div style={{ display: "flex", gap: 32, marginTop: "32px" }}>
          <div style={{ display: "flex", gap: 14, flexDirection: "column", maxWidth: '440px' }}>
            {/* jde */}
            <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
              <div className={s.wrapper_search_input}>
                <InputFetchResult
                    defVal={productByJDE}
                    name="jde"
                    sendJDE
                    form={formH}
                    searchParam="jde[]"
                    func={getProductSearch}
                    placeholder="Введите jde товара"
                    required
                />
              </div>

              {data?.fields?.jde && (
                  <CopyComponent copyText={data?.fields?.jde}>
                    {data?.fields?.jde}
                  </CopyComponent>
              )}
            </div>

            {/* title_ua */}
            <div>
              <InputWrapper
                  label="Назва сервісу УКР"
                  id="as-name-ua"
                  errorComponent={formH.errors?.title_ua &&
                  <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  style={{ margin: 0 }}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH.errors?.title_ua,
                    })}
                    type="text"
                    placeholder="Назва сервісу УКР"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="title_ua"
                    id="as-name-ua"
                    defaultValue={data?.fields?.title_ua}
                />
              </InputWrapper>
            </div>

            {/* title_ru */}
            <div>
              <InputWrapper
                  label="Назва сервісу РУС"
                  id="as-name-ru"
                  errorComponent={formH.errors?.title_ru &&
                  <p className="form-input-hint">{formH?.errors?.title_ru?.message}</p>}
                  style={{ margin: 0 }}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH.errors?.title_ru,
                    })}
                    type="text"
                    placeholder="Назва сервісу РУС"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="title_ru"
                    id="as-name-ru"
                    defaultValue={data?.fields?.title_ru}
                />
              </InputWrapper>
            </div>

            {/* text_ua */}
            <div className={s_Select.textarea__wrapper} style={{ margin: 0 }}>
              <textarea
                  name="text_ua"
                  className={s_Select.textarea}
                  id="ad-text-ua"
                  placeholder="Опис сервісу УКР"
                  rows="4"
                  ref={formH.register}
                  defaultValue={data?.fields?.text_ua}
                  maxLength={500}
              />
              <label className={s_Select.label__textarea} htmlFor="ad-text-ua">Опис сервісу УКР</label>
            </div>

            {/* text_ru */}
            <div className={s_Select.textarea__wrapper} style={{ margin: 0 }}>
              <textarea
                  name="text_ru"
                  className={s_Select.textarea}
                  id="ad-text-ru"
                  placeholder="Опис сервісу РУС"
                  rows="4"
                  ref={formH.register}
                  defaultValue={data?.fields?.text_ru}
                  maxLength={500}
              />
              <label className={s_Select.label__textarea} htmlFor="ad-text-ru">Опис сервісу РУС</label>
            </div>

            {/* period */}
            <div className={s_OrderStyle.select__inner} style={{ margin: 0 }}>
              <div className={s_OrderStyle.select__wrapper} style={{ margin: 0 }}>
                <select
                    name="period"
                    className={cn(s_OrderStyle.select, {
                      [s_OrderStyle.select_error]: errors?.period,
                    })}
                    id="ad-period"
                    ref={register({
                      required: VALIDATION.select.required,
                    })}
                    defaultValue={data?.fields?.period || 1}
                >
                  <option value={1}>12 місяців</option>
                  <option value={2}>24 місяців</option>
                  <option value={3}>36 місяців</option>
                </select>
                <label className={s_OrderStyle.label} htmlFor="ad-period">Період</label>
              </div>
              {errors?.period && <p className="form-input-hint">{errors.period?.message}</p>}
            </div>

            {/* Price */}
            <PriceType
                defVal={data && data?.fields?.price_type}
                defPrice={data && data?.fields?.price}
                isReq
                formH={formH}
            />

            {/* related additional services */}
            <Group attrs={{ className: s.rselect__wrapper }}>
              <ColFull>
                <div className={s.rselect__wrapper_title}>Сумісні сервіси</div>
                <RSelect
                    props={{
                      value: selectedRelatedAdditionalService,
                      onChange: (opt) => {setSelectedRelatedAdditionalService(opt)},
                      placeholder: 'Виберіть сумісні бандли',
                      options: data?.additionalServices || [],
                      isMulti: true,
                      getOptionLabel: (opt) => opt.title_ua,
                      getOptionValue: (opt) => opt.id
                    }}
                />

                {!!selectedRelatedAdditionalService?.length && selectedRelatedAdditionalService?.map((item, index) =>
                    <input
                        key={`related_additional_service_hidden_${index}`}
                        type="hidden"
                        ref={register}
                        name={`related_additional_service[${index}]`}
                        value={item.id}
                    />
                )}
              </ColFull>
            </Group>

            {/* img */}
            <Group attrs={{ style: { margin: 0, padding: 0 } }}>
              <ColFull attrs={{ style: { margin: 0, padding: 0 } }}>
                <Controller
                    as={InputFile}
                    control={control}
                    className={cn(s.block__img, {
                      'is-error': errors.img,
                    })}
                    name="img"
                    rules={{ required: VALIDATION.req.required }}
                    val={watch('img')}
                    formH={formH}
                    acceptStr="PNG, JPG, GIF"
                    accept="image/*"
                    sizeMB={1}
                    maxWidth={100}
                    maxHeight={100}
                    width={440}
                    height={246}
                    objectFit="contain"
                    labelDownload="Додати зображення"
                />
                {errors.img && <p className="form-input-hint">{errors.img.message}</p>}
              </ColFull>
            </Group>
          </div>
          <div className={s.tree}>
            <TreeCredit
                state={state}
                action={action}
                dataAll={data}
                formH={formH}
                domainId={domainWatch}
                selectCategoryPriceType
                typePriceProduct
                setWatchDomain={true}
                setDomainWatch={setDomainWatch}
            />
          </div>
        </div>

        {load && <Loader />}
      </Form>
    </>
  );
};

export default AdditionalServicesEditView;
