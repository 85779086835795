import { getCatTree } from '../../lib/api/categories';
import { getDomains } from '../../lib/api/domains';
import { getIM, removeIM } from '../../lib/api/InstallmentManagement';
import { getBanks } from "../../lib/api/banks";

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
  getCatTree(),
  getBanks(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
    categories: res[1]?.value?.data,
    banks: res[2]?.value?.data,
  }));

export const getData = getIM;
export const remove = removeIM;
