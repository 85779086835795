import React from 'react';
import { DOMAIN_API } from '../../../const/api'
import fetchAx from "../../../lib/fetchAx";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";
import Button from "../../ui/Button";
import filterDateTimeExport from "../../../lib/helpers/filterDateTimeExport";

const BtnExport = ({ title, href, filename, removeParams = false, params = {}, newStyle = false }) => {
    let getParams;
    if(window.location.search.indexOf('&') != -1){
        getParams = window.location.search.split('&').map(function(item){
            if(item.indexOf('?') !== -1) {
                let currentValue = item.split('?');
                currentValue = currentValue[1].split('=');
                return {[currentValue[0]]: currentValue[1]}
            }
            let currentValue = item.split('=');
            return {[currentValue[0]]: currentValue[1]}
        });
    } else {
        let currentValue = window.location.search.replace(/\?/, '').split('=');
        getParams = {[currentValue[0]]: currentValue[1]};
    }

  const handleDownload = () => {
    let result = {};
    for (let i = 0; i < getParams.length; i++) {
        result = {
            ...result,
            ...getParams[i]
        }
    }

    const paramIds = JSON.parse(localStorage.getItem('ids'));

    if(!!paramIds?.length) {
        result.ids = paramIds;
    }

    delete result['per_page'];

    const temp = {...params};

    if(removeParams) {

        if(!!temp['status']?.length) {
            temp['status'] = temp?.status?.join(',');
        }

        if(temp['status_send'] === false) {
            delete temp['status'];
            delete params['status'];
        }

        if(!!temp['warehouse_id']?.length) {
            temp['warehouse_id'] = temp?.warehouse_id?.join(',');
        }

        if(temp['warehouse_send'] === false) {
            delete temp['warehouse_id'];
            delete params['warehouse_id'];
        }

        if(!!temp['domain']?.length) {
            temp['domain'] = temp?.domain?.join(',');
        }

        if(temp['domain_send'] === false) {
            delete temp['domain'];
            delete params['domain'];
        }

        if(!!temp['columns']?.length) {
            temp['columns'] = temp?.columns?.join(',');
        }

        delete temp['status_send'];
        delete params['status_send'];
        delete temp['warehouse_send'];
        delete params['warehouse_send'];
        delete temp['domain_send'];
        delete params['domain_send'];

        if(!!Object?.entries(temp)?.length) {
            Object?.entries(temp)?.map(a=>Object?.entries(a[1])?.filter(b=>b[1].length)?.length? a : delete temp[a[0]]);
        }
    }

    if(result) {
        if (result?.created_from) {
            result.created_from = filterDateTimeExport(result?.created_from);
        }

        if (result?.created_to) {
            result.created_to = filterDateTimeExport(result?.created_to);
        }

        if (result?.updated_from) {
            result.updated_from = filterDateTimeExport(result?.updated_from);
        }

        if (result?.updated_to) {
            result.updated_to = filterDateTimeExport(result?.updated_to);
        }
    }

    fetchAx( {
      url: `${DOMAIN_API}${href}`,
      responseType: 'blob',
      params: {
        ...params,
        ...result,
        ...temp
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch(async (err) => {
        if(err?.response?.status === 401) {
            await refreshToken(async () => {
                await fetchAx( {
                    url: `${DOMAIN_API}${href}`,
                    responseType: 'blob',
                    params: {
                        ...params,
                        ...result,
                        ...temp
                    },
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    })
            })
        } else {
            await errorMessage(err, '')
        }
    })
  }

  return newStyle ? (
      <Button onClick={handleDownload} green>
          {title}
      </Button>
  ) : (
    <button
      className="btn btn-success mr-2"
      onClick={handleDownload}
    >
      {title}
    </button>
)}

export default BtnExport;
