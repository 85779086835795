import React, { useEffect, useState } from "react";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import getImgUrl from "../../../lib/helpers/getImgUrl";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s from "../ServiceEdit.module.scss";

const BlockUa = ({ formH, data }) => {
    const [showInfo, setShowInfo] = useState({
        title_ua: '',
        subscription_ua: '',
    });

    const handleChange = (e) => {
        setShowInfo(prev => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        if(data?.fields) {
            const parseData = {
                title_ua: data?.fields?.title_ua,
                subscription_ua: data?.fields?.subscription_ua,
            };
            setShowInfo(parseData);
        }
    }, [data]);

    return (
        <div className={s.block}>
            <div className={s.block__header}>Українською</div>
            <div className={s.block__content}>
                <div className={s.block__fields}>
                    {/* title_ua */}
                    <div>
                        <InputWrapper
                            label="Название*"
                            id="ser-title-ua"
                            errorComponent={formH.errors?.title_ua &&
                            <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: formH.errors?.title_ua,
                                })}
                                type="text"
                                placeholder="Название*"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register({
                                    required: VALIDATION.req.required
                                })}
                                name="title_ua"
                                id="ser-title-ua"
                                value={showInfo?.title_ua}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    </div>

                    {/* subscription_ua */}
                    <div>
                        <InputWrapper
                            label="Описание*"
                            id="ser-subscription-ua"
                            errorComponent={formH.errors?.subscription_ua &&
                            <p className="form-input-hint">{formH?.errors?.subscription_ua?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: formH.errors?.subscription_ua,
                                })}
                                type="text"
                                placeholder="Описание*"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register({
                                    required: VALIDATION.req.required
                                })}
                                name="subscription_ua"
                                id="ser-subscription-ua"
                                value={showInfo?.subscription_ua}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    </div>

                    {/* link_ua */}
                    <div>
                        <InputWrapper
                            label="Cсылка*"
                            id="ser-link-ua"
                            errorComponent={formH.errors?.link_ua &&
                            <p className="form-input-hint">{formH?.errors?.link_ua?.message}</p>}
                        >
                            <input
                                className={cn(s_Input.input, {
                                    [s_Input.input_error]: formH.errors?.link_ua,
                                })}
                                type="text"
                                placeholder="Cсылка*"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register({
                                    required: VALIDATION.req.required
                                })}
                                name="link_ua"
                                id="ser-link-ua"
                                defaultValue={data?.fields?.link_ua}
                            />
                        </InputWrapper>
                    </div>
                </div>
                <div className={s.show}>
                    <div className={s.show__img}>
                        <img src={getImgUrl(data?.fields?.img)} alt="Icon" />
                    </div>
                    <div className={s.show__title}>{showInfo?.title_ua}</div>
                    <div className={s.show__desc}>{showInfo?.subscription_ua}</div>
                </div>
            </div>
        </div>
    );
};

export default BlockUa;