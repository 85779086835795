import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { getProduct } from '../../lib/api/products';
import InputFetchResult from '../../components/ui/InputFetchResult';
import { INPUT_MAX_LENGTH } from "../../const/view";
import { APPLICATION_STATUS } from "../../lib/const/product";
import { getAdmins } from "../../lib/api/admins";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const ProductConsultationEditView = () => {
  const { id: pb2bID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: pb2bID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/product-b2b/not-found',
      remove: `/app/product-b2b${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/product-b2b${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Форма B2B-заявка на покупку создана',
      edit: 'Форма B2B-заявка на покупку обновлена',
      remove: 'Форма B2B-заявка на покупку удалена',
    },
  });

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product b2b edit`} />
      <div className="box-wrap">
        {pb2bID
          ? (
            <Title>
              Форма B2B-заявка на покупку -
              <small>{data && data?.fields?.id}</small>
            </Title>
          )
          : <Title>Новая форма B2B-заявка на покупку</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

         {/* surname */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-surname">Фамилия*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors?.surname,
                })}
                name="surname"
                type="text"
                id="pb2b-surname"
                placeholder="Введите фамилию"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.surname
              && <p className="form-input-hint">{errors.surname.message}</p>}
            </ColRight>
          </Group>

          {/* name */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-name">Имя*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.name,
                })}
                name="name"
                type="text"
                id="pb2b-name"
                placeholder="Введите имя"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.name
              && <p className="form-input-hint">{errors.name.message}</p>}
            </ColRight>
          </Group>

          {/* phone */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-phone">Телефон*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.phone,
                })}
                name="phone"
                type="phone"
                id="pb2b-phone"
                placeholder="Введите номер телефона"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.phone
              && <p className="form-input-hint">{errors.phone.message}</p>}
            </ColRight>
          </Group>

          {/* email */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-email">Емайл*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.email,
                })}
                name="email"
                type="email"
                id="pb2b-email"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.email
              && <p className="form-input-hint">{errors.email.message}</p>}
            </ColRight>
          </Group>

          {/* function */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pf-function">Функция:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="function"
                type="text"
                id="pb2b-function"
                placeholder="Введите функцию"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* position */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-position">Должность*:</label>
            </ColLeft>
            <ColRight>
              <input
                className={cn('form-input', {
                  'is-error': errors.position,
                })}
                name="position"
                type="text"
                id="pb2b-position"
                placeholder="Введите должность"
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.position
              && <p className="form-input-hint">{errors.position.message}</p>}
            </ColRight>
          </Group>

          {/* branch */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-position">Отрасль:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="branch"
                type="text"
                id="pb2b-branch"
                placeholder="Введите отрасль"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* employees_number */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-employees_number">Количество работников:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="employees_number"
                type="text"
                id="pb2b-employees_number"
                placeholder="Введите количество работников"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* product_solution */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-product_solution">Продукт/решение:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="product_solution"
                type="text"
                id="pb2b-product_solution"
                placeholder="Введите продукт/решение"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* subject */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-subject">Тема:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="subject"
                type="text"
                id="pb2b-subject"
                placeholder="Введите тему"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* message */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="pb2b-message">Вопрос/сообщение:</label>
            </ColLeft>
            <ColRight>
              <input
                className="form-input"
                name="message"
                type="text"
                id="pb2b-message"
                placeholder="Введите вопрос/сообщение"
                ref={register}
              />
            </ColRight>
          </Group>

          {/* product_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="product_id">Товар*:</label>
            </ColLeft>
            <ColRight>
              <InputFetchResult name="product_id" placeholder="Введите JDE товара" form={formH} searchParam="jde[]" func={getProduct} defVal={data?.fields?.product} />
            </ColRight>
          </Group>

          {/* status */}
          {pb2bID ?
              <Group>
                <ColLeft>
                  <label className="form-label" htmlFor="pf-status">Выбрать cтатус запроса:</label>
                </ColLeft>
                <ColRight>
                  {data
                      ? (
                          <>
                            <select
                                name="status"
                                className='form-select'
                                id="pf-status"
                                ref={register}
                            >
                              <option value="">выберите cтатус запроса</option>
                              {APPLICATION_STATUS.map((status) => (
                                  <option
                                      key={status.id}
                                      value={status.id}
                                  >
                                    {status.title}
                                  </option>
                              ))}
                            </select>
                          </>
                      )
                      : (
                          <div className="p-relative">
                            <div className="loading" />
                          </div>
                      )}
                </ColRight>
              </Group>
              :
              <input type="hidden" value='1' name='status'  id="pf-status" ref={register} />
          }

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default ProductConsultationEditView;
