import React from 'react';

const IconDelete = ({ color = '#000' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1592_7222)">
                <path d="M9.99992 19.1668C11.8129 19.1668 13.5852 18.6292 15.0927 17.622C16.6001 16.6147 17.775 15.1831 18.4688 13.5081C19.1626 11.8331 19.3442 9.99 18.9905 8.21184C18.6368 6.43368 17.7637 4.80034 16.4817 3.51835C15.1998 2.23637 13.5664 1.36333 11.7883 1.00964C10.0101 0.655937 8.16698 0.837468 6.49199 1.53127C4.817 2.22507 3.38536 3.39999 2.37811 4.90744C1.37087 6.41489 0.833252 8.18717 0.833252 10.0002C0.836119 12.4304 1.80281 14.7603 3.52128 16.4788C5.23974 18.1973 7.56965 19.164 9.99992 19.1668ZM9.99992 2.50017C11.4833 2.50017 12.9333 2.94003 14.1667 3.76414C15.4001 4.58825 16.3614 5.75959 16.929 7.13004C17.4967 8.50048 17.6452 10.0085 17.3558 11.4633C17.0664 12.9182 16.3521 14.2546 15.3032 15.3035C14.2543 16.3524 12.918 17.0667 11.4631 17.3561C10.0082 17.6454 8.50024 17.4969 7.1298 16.9293C5.75935 16.3616 4.58801 15.4003 3.7639 14.1669C2.93979 12.9336 2.49992 11.4835 2.49992 10.0002C2.50213 8.01172 3.29301 6.10535 4.69906 4.6993C6.1051 3.29326 8.01148 2.50237 9.99992 2.50017Z" fill={color} />
                <path d="M6.91074 13.089C7.06701 13.2452 7.27893 13.333 7.4999 13.333C7.72088 13.333 7.9328 13.2452 8.08907 13.089L9.99991 11.1782L11.9107 13.089C12.0679 13.2408 12.2784 13.3248 12.4969 13.3229C12.7154 13.321 12.9244 13.2334 13.0789 13.0789C13.2334 12.9243 13.3211 12.7153 13.323 12.4968C13.3249 12.2783 13.2409 12.0678 13.0891 11.9107L11.1782 9.99985L13.0891 8.08901C13.2409 7.93185 13.3249 7.72135 13.323 7.50285C13.3211 7.28435 13.2334 7.07534 13.0789 6.92083C12.9244 6.76633 12.7154 6.67869 12.4969 6.67679C12.2784 6.67489 12.0679 6.75889 11.9107 6.91068L9.99991 8.82151L8.08907 6.91068C7.9319 6.75889 7.7214 6.67489 7.5029 6.67679C7.28441 6.67869 7.0754 6.76633 6.92089 6.92083C6.76638 7.07534 6.67874 7.28435 6.67684 7.50285C6.67494 7.72135 6.75894 7.93185 6.91074 8.08901L8.82157 9.99985L6.91074 11.9107C6.75451 12.0669 6.66675 12.2789 6.66675 12.4998C6.66675 12.7208 6.75451 12.9327 6.91074 13.089Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1592_7222">
                    <rect width="20" height="20"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default IconDelete;