import { parseAll } from '../../lib/api/_parseApi';
import {
  editInCart, getInCartEdit
} from '../../lib/api/in_carts';
import { getStatusAll } from "../../lib/api/statuses";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (inCartId) => Promise.allSettled([inCartId
&& getInCartEdit(inCartId), getStatusAll()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: inCartId && res[0]?.value?.data,
      statuses: res[1]?.value?.data?.data,
    })
  });

export const edit = editInCart;

export const parseDataSend = (data, cancelVariantId) => {
  let d = { ...data };
  const isUpdate = typeof cancelVariantId !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
