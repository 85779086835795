export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.custom-col-features {
                gap: 16px;
                justify-content: flex-start !important;
            }
            
            div.custom-col-features-item {
                border: 1px solid #e5e5e5;
                background-color: #fff;
                width: 330px;
                padding: 0 !important;
            }
            
            div.custom-col-features-item-content {
                display: flex;
                flex-direction: column;
                flex: 1;
                min-height: 288px;
                padding: 37px 23px;
            }
            
            div.custom-col-features-item-content > h3.h3-native {
                padding: 0 !important;
                text-align: left;
            }
            
            div.custom-col-features-item-content > h3.h3-native > span {
                text-align: left;
            }
            
            div.custom-col-features-item-content > div.desc_text_custom {
                text-align: left;
            }
            
            div.custom-col-features-item-content > div.small_text_custom {
                text-align: left;
            }
            
            div.desc_text_custom > span {
                font-size: 18px;
                line-height: 24px;
            }
            
            div.small_text_custom > span {
                font-size: 14px;
                line-height: 16px;
            }
            
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h3.h3-native {
                    font-size: 24px !important;
                }
               
                div.small_text_custom > span {
                    font-size: 11px;
                    line-height: 13px;
                }
                
                div.desc_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row custom-col-features">
                <div class="custom-col-features-item">
                    <div class="col d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                    <div class="custom-col-features-item-content">
                        <h3 class="h3-native d-flex mb-2"><span class="text-left">Безупречный экран для безупречной графики</span></h3>
                        <div class="desc_text_custom d-flex mb-4"><span class="text-left">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости. Благодаря адаптивной частоте обновления до 120 Гц обеспечивается необходимая плавность на дисплее и низкое потребление энергии.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-left">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3. Яркость дисплея может достигать уровня 1750 nit, усиливая контраст между светлыми и темными фрагментами контента для лучшего качества. Коэффициент контраста составляет 3 000 000:1, что сделает ваш мобильный опыт незабываемым.</span></div>
                    </div>
                </div>
                <div class="custom-col-features-item">
                    <div class="col d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                    <div class="custom-col-features-item-content">
                        <h3 class="h3-native d-flex mb-2"><span class="text-left">Безупречный экран для безупречной графики</span></h3>
                        <div class="desc_text_custom d-flex mb-4"><span class="text-left">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости. Благодаря адаптивной частоте обновления до 120 Гц обеспечивается необходимая плавность на дисплее и низкое потребление энергии.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-left">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3. Яркость дисплея может достигать уровня 1750 nit, усиливая контраст между светлыми и темными фрагментами контента для лучшего качества. Коэффициент контраста составляет 3 000 000:1, что сделает ваш мобильный опыт незабываемым.</span></div>
                    </div>
                </div>
                <div class="custom-col-features-item">
                    <div class="col d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                    <div class="custom-col-features-item-content">
                        <h3 class="h3-native d-flex mb-2"><span class="text-left">Безупречный экран для безупречной графики</span></h3>
                        <div class="desc_text_custom d-flex mb-4"><span class="text-left">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости. Благодаря адаптивной частоте обновления до 120 Гц обеспечивается необходимая плавность на дисплее и низкое потребление энергии.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-left">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3. Яркость дисплея может достигать уровня 1750 nit, усиливая контраст между светлыми и темными фрагментами контента для лучшего качества. Коэффициент контраста составляет 3 000 000:1, что сделает ваш мобильный опыт незабываемым.</span></div>
                    </div>
                </div>
                <div class="custom-col-features-item">
                    <div class="col d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                    <div class="custom-col-features-item-content">
                        <h3 class="h3-native d-flex mb-2"><span class="text-left">Безупречный экран для безупречной графики</span></h3>
                        <div class="desc_text_custom d-flex mb-4"><span class="text-left">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости. Благодаря адаптивной частоте обновления до 120 Гц обеспечивается необходимая плавность на дисплее и низкое потребление энергии.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-left">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3. Яркость дисплея может достигать уровня 1750 nit, усиливая контраст между светлыми и темными фрагментами контента для лучшего качества. Коэффициент контраста составляет 3 000 000:1, что сделает ваш мобильный опыт незабываемым.</span></div>
                    </div>
                </div>
            </div>
            ${style}
        `,
    })
};