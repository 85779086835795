const parseTree = (treeArray, nameLabel = 'title', nameValue = 'id', addLabelName = null, parent_id = null,) => {
    if (!Array.isArray(treeArray)) {
        return [];
    }

    function parseNode(node) {
        if (!node.children || node.children.length === 0) {
            return {
                value: node[nameValue],
                label: !addLabelName ? node?.[nameLabel] : `${node[nameLabel]} (${node?.[addLabelName]})`,
                parent_id:  node?.["parent_id"] && node?.["parent_id"]
            };
        }

        return {
            value: node[nameValue],
            label: !addLabelName ? node?.[nameLabel] : `${node[nameLabel]} (${node?.[addLabelName]})`,
            children: node.children.map(parseNode)
        };
    }

    return treeArray.map(parseNode);
};

export default parseTree;