import grapesjs from "grapesjs";
import loadComponents from "./components";
import loadBlock from "./blocks";

export default grapesjs.plugins.add('swiperSlideFiveComponent', (editor, opts) => {
    let options = {
        label: 'Swiper slide 5',
        name: 'cswiperFive',
        category: 'Slider',
    };

    for(let name in options) {
        if(!(name in opts)) opts[name] = options[name];
    }

    loadBlock(editor, opts);
    loadComponents(editor, opts);
})