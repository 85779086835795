import {
  CONSULTATIONS_MANAGER_CREATE,
  CONSULTATIONS_MANAGER_GET_EDIT,
  CONSULTATIONS_MANAGER_EDIT,
  CONSULTATIONS_MANAGER_DELETE,
  CONSULTATIONS_MANAGERS_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getConsultationsManagers = (params) => fetchAx({
  url: CONSULTATIONS_MANAGERS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    page: 1,
    ...params,
  },
});

export const getConsultationsManagersAll = (params) => fetchAx({
  url: CONSULTATIONS_MANAGERS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    page: 1,
    ...params,
  },
});

export const createConsultationsManager = (data) => fetchAx({
  url: CONSULTATIONS_MANAGER_CREATE,
  method: 'POST',
  data,
});

export const getConsultationsManagerEdit = (id) => fetchAx({
  url: CONSULTATIONS_MANAGER_GET_EDIT.join(id),
  method: 'GET',
});

export const editConsultationsManager = (id, data) => fetchAx({
  url: CONSULTATIONS_MANAGER_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeConsultationsManager = (id) => fetchAx({
  url: CONSULTATIONS_MANAGER_DELETE.join(id),
  method: 'DELETE',
});