import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import {useParams} from 'react-router-dom';
import useEdit from "../../lib/crud/useEdit";
import {create, edit, getData, parseDataSend, remove,} from './api';
import Loader from "../../components/ui/Loader";
import useTabsForm from "../../lib/useTabsForm";
import getTZtoDate from "../../lib/getTZtoDate";
import TabInfoUser from "./tabs/TabInfoUser";
import TabBonus from "./tabs/TabBonus";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import TabOrders from "./tabs/TabOrders";
import TabReviews from "./tabs/TabReviews";
import TabCalls from "./tabs/TabCalls";

import SideNavEditViewNew from "../../components/common/SideNavEditViewNew";
import GridEditViewNew from "../../components/ui/GridEditViewNew";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import {getCallsHistory} from "../../lib/api/order";
import errorMessage from "../../lib/errorMessage";

const UserEditView = () => {
    const {id: clientID} = useParams();
    const [tabList, setTabList] = useState(['Информация пользователя', 'Заказы', 'Отзывы'])
    const [callsHistory, setCallsHistory] = useState(null)
    const [loadCalls, setLoadCalls] = useState(false);

    const tabsForm = useTabsForm({
        tabs: tabList
    });

    const {load, data, submitHandler} = useEdit({
        targetID: clientID,
        submitCb: (cb) => tabsForm.validate(null, cb),
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/user/not-found',
            remove: `/app/users/client${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/users/client${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Пользователь создан',
            edit: 'Пользователь обновлен',
            remove: 'Пользователь удален',
        },
    });

    const formProps = {
        clientID,
        load,
        data,
        tabsForm,
        submitHandler,
    };

    useEffect(() => {
        if (data?.fields?.domain_id === 1) {
            setTabList(['Информация пользователя', 'Бонусы', 'Заказы', 'Отзывы', 'Звонки'])
        }

        if (data?.fields?.phone) {
            setLoadCalls(true);
            const callsHistoryFunc = async () => {
                await getCallsHistory(data?.fields?.phone)
                    .then((res) => setCallsHistory(res.data))
                    .catch(async (err) => await errorMessage(err, `/api/admin/binotel/${data?.fields?.phone}`))
                    .finally(() => setLoadCalls(false));
            }

            callsHistoryFunc()
        }

    }, [data])
    return (
        <div>
            <TopPanelChild
                title={data?.fields?.name ? data?.fields?.name : "Новий користувач"}
                breadcrumbs={{
                    items: [
                        {
                            title: "Головна",
                            url: "/app",
                            permissionName: 'admin.user.index',
                        },
                        {
                            title: "Користувачі",
                            url: "/app/users/client",
                            permissionName: 'admin.user.index',
                        },
                        {
                            title: clientID && data?.fields?.name ? data?.fields?.name : "Новий користувач"
                        },
                    ],
                }}
                back="/app/users/client"
                id={clientID}
                topInfo={[
                    {
                        title: 'Карта лояльности:',
                        isShow: data?.fields?.domain_id === 1,
                        value: data?.fields?.card_number ? data.fields.card_number : "",
                    },
                    {
                        title: 'ID',
                        value: data?.fields?.id ? data?.fields?.id : ''
                    },
                    {
                        title: 'Дата створення',
                        value: data?.fields?.created_at ? getTZtoDate(data.fields.created_at, true) : ''
                    },
                    {
                        title: 'Дата редагування',
                        value: data?.fields?.updated_at ? getTZtoDate(data.fields.updated_at, true) : ''
                    }
                ]}
                btnEdit
            />

            <GridEditViewNew
                left={(
                    <div className="box-wrap--client box-wrap--sticky box-wrap--mb">
                        {data && (
                            <SideNavEditViewNew
                                items={tabsForm.items}
                                onClick={tabsForm.setActiveIndex}
                                checkActiveCb={tabsForm.isActive}
                                checkErrorCb={tabsForm.isError}
                            />
                        )}

                        {load && <Loader/>}
                    </div>
                )}
                styleWrap={{gridColumnGap: "20px", padding: "0 30px", gridTemplateColumns: "110px 92%"}}
            >
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - User client edit`}/>
                {/*{ formProps.tabsForm.getDStyle(4).visibility && <div className="box-wrap box-wrap--mb">*/}
                {/*    {clientID*/}
                {/*        ? (*/}
                {/*            <Title single>*/}
                {/*                Пользователь -*/}
                {/*                <small>{data && data.fields.id}</small>*/}
                {/*            </Title>*/}
                {/*        )*/}
                {/*        : <Title single>Новый пользователь</Title>}*/}
                {/*</div>}*/}

                <div className="p-relative">
                    {data ? <TabInfoUser formProps={formProps} index={0}/> : <Loader/>}
                    {data ? <TabBonus formProps={formProps} index={1}/> : <Loader/>}
                    {data ? <TabOrders formProps={formProps} index={2}/> : <Loader/>}
                    {data ? <TabReviews formProps={formProps} index={3}/> : <Loader/>}
                    {data ? <TabCalls formProps={formProps} callsHistory={callsHistory} loadCalls={loadCalls} index={4}/> : <Loader/>}
                </div>
            </GridEditViewNew>
        </div>
    )
}

export default UserEditView