import Constants from "./constants";

export const setFilters = (dispatch) => (data) => dispatch({
  type: Constants.ON_CHANGE_ORDER_FILTERS,
  data,
});

export const clearFilters = (dispatch) => (data) => dispatch({
  type: Constants.CLEAR_ORDER_FILTERS,
  data,
});
