import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {DEVELOPMENT_ENVIRONMENT, DOMAIN_API} from "../../const/api";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import {edit, getData, getFiltersData, remove} from "./api";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import checkPermission from "../../lib/checkPermission";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import Button from "../../components/ui/Button";
import TopPanelNew from "../../components/common/TopPanelNew";
import errorMessage from "../../lib/errorMessage";
import {refreshSortProducts, updateSortProducts} from "../../lib/api/sort";
import Portal from "../../components/ui/Portal";
import ModalCreate from "./Modal/ModalCreate";
import IconAdd from "../../components/icon/iconAdd";
import icon_save from "../../assets/icons/icon_save.svg";
import rangeArrObjs from "../../lib/rangeArrObjs";
import {toast} from "react-toastify";

const SortProductsList = () => {
    const [selectedSort, setSelectedSort] = useState([]);
    const [sortArray, setSortArray] = useState([1]);
    const [cardList, setCardList] = useState([])
    const [openEdit, setOpenEdit] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);

    const params = new URL(window.location.href).searchParams;
    const domain_id = params.get('domain_id');
    const category_id = params.get('category_id');

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
        filters: {
            domain_id: domain_id ? domain_id : '1',
            category_id: category_id ? category_id : '39'
        }
    });

    const handleOnClose = () => {
        const portal = document.getElementById("modal-root");
        portal.className = "";
        setOpenEdit(false);
    }

    const handleOnOpen = () => setOpenEdit(true);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (!!filtersData?.categories?.length) {
            setCategories(filtersData?.categories?.filter(item => item.domain_id?.toString() === urlParams?.['domain_id']?.toString()));
        }
    }, [filtersData?.categories, urlParams?.['domain_id']]);

    const handleSelectSort = (e, value) => {
        setSelectedSort(prev => {
            const temp = [...prev];

            const parseItem = {
                id: e?.id,
                position: +value
            };

            if (!!temp?.length) {
                const findSortIndex = temp.findIndex(itemSelect => itemSelect.id === parseItem.id)

                if (findSortIndex !== -1) {
                    temp[findSortIndex] = parseItem;
                    return temp;
                }
                temp.push(parseItem);
            } else {
                temp.push(parseItem);
            }

            return temp;
        })
    }

    const handleUpdateSortProducts = async () => {
        setLoad(true);
        await updateSortProducts({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '39',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
            positions: selectedSort
        })
            .then((res) => setRest(prev => {
                const temp = {...prev};

                temp.data = res.data?.data;

                return temp;
            }))
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    const handleRefreshSort = async () => {
        setLoad(true);
        await refreshSortProducts({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '39',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })
            .then((res) => {
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                });
                toast('Рефреш виконано', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        if (rest?.meta?.total) {
            const totalArray = [...Array(rest?.meta?.total + 1).keys()].filter(item => !!item);
            if (!!rest?.data?.length) {
                rest?.data?.forEach(item => {
                    if (!totalArray?.includes(item?.manual_sort)) {
                        totalArray.push(item?.manual_sort)
                    }
                })
            }
            setSortArray(totalArray);
        }

        if (rest?.data?.length) {
            setCardList(rest?.data)
        }

        if (!rest?.data?.length) {
            setCardList([])
        }
    }, [rest?.meta?.total, rest]);

    // ----- Drag&Drop

    const dropHandler = async (e, card) => {
        e.preventDefault()

        const id = currentCard.product_id ? currentCard.product_id : currentCard.id
        const index = card.manual_sort ? card.manual_sort : card.sort

        await edit({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '1',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })(id, index ).then((res) => setRest(prev => {
            const temp = {...prev};
            temp.data = res.data?.data;

            return temp;
        }))
    }

    return (
        <>
            <TopPanelNew
                title='Сортування'
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                            permissionName: 'admin.sort.products',
                        },
                        {
                            title: 'Сортування',
                            url: `/app/sort/products${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                            permissionName: 'admin.sort.products',
                        },
                    ],
                }}
                customButtonTop={checkPermission('admin.sort.products.update')
                    ? (
                        <>
                            <Button onClick={handleUpdateSortProducts} disabled={!selectedSort?.length} purple>
                                <img src={icon_save} alt="icon save"/>
                                Зберегти / Сортувати
                            </Button>
                        </>
                    ) : null
                }
                positionSticky
            />

            <div>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Sort products list`}/>
                {!load && (
                    <TopPagination
                        tabs={(
                            <TopNavTabs tabs={[
                                {
                                    title: 'Товари',
                                    url: '/app/sort/products',
                                    permissionName: 'admin.sort.products',
                                    isActive: true
                                },
                                {
                                    title: 'Банера',
                                    url: '/app/sort/banner/catalog',
                                    permissionName: 'admin.sort.banner-catalog'
                                },
                                {
                                    title: 'Акції',
                                    url: '/app/sort/special-offers',
                                    permissionName: 'admin.sort.special-offers'
                                },
                                {
                                    title: 'Категорії супутніх товарів',
                                    url: '/app/sort/categories',
                                    permissionName: 'admin.sort.category-sort'
                                }
                            ]}
                            />
                        )}
                        pagination={(
                            <>
                                {rest?.meta?.total ? (
                                    <>
                                        <TableListViewPagination
                                            length={+rest?.meta?.last_page}
                                            current={+rest?.meta?.current_page}
                                            loc={location}
                                            navigate={navigate}
                                        />
                                        <TableListViewPerPage
                                            urlParams={urlParams}
                                            onSelect={(val, params) => pushUrlParams(params)}
                                            total={+rest?.meta?.total}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}
                        stickySecond
                    />
                )}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 28}}>
                    <div style={{display: 'flex', gap: '24px'}}>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!filtersData?.domains?.length
                                    ? (
                                        <select
                                            name="domain_id"
                                            className={s_Select.select}
                                            id="b-domain"
                                            onChange={(e) => pushUrlParams({
                                                ...urlParams,
                                                domain_id: e.target.value,
                                                category_id: filtersData?.categories?.find(item => item.domain_id?.toString() === e.target.value)?.children?.[0]?.id
                                            })}
                                        >
                                            {!!filtersData?.domains?.length && filtersData?.domains.map((domain) => (
                                                <option
                                                    key={domain.id}
                                                    value={domain.id}
                                                    selected={domain.id?.toString() === urlParams?.domain_id}
                                                >
                                                    {domain.title}
                                                </option>
                                            ))}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                            </div>
                        </div>

                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!categories?.[0]?.children?.length
                                    ? (
                                        <select
                                            name="categories"
                                            className={s_Select.select}
                                            id="b-categories"
                                            onChange={(e) => pushUrlParams({
                                                ...urlParams,
                                                category_id: e.target.value
                                            })}
                                        >
                                            {!!categories?.length && rangeArrObjs(categories, 'children', (e, i, iArr, depth) => {
                                                const nest = new Array(depth).join('--');

                                                if (iArr?.length === 1) {
                                                    return null;
                                                }

                                                if (!!e?.children?.length) {
                                                    return null;
                                                }

                                                if (urlParams?.domain_id) {
                                                    if (urlParams?.domain_id?.toString() === e.domain_id?.toString()) {
                                                        return (
                                                            <option
                                                                key={iArr.join('-')}
                                                                value={e.id}
                                                                selected={urlParams?.category_id?.toString()
                                                                    ? e.id?.toString() === urlParams?.category_id?.toString()
                                                                    : e.id?.toString() === '39'
                                                                }
                                                            >
                                                                {`${nest} ${e.title}`}
                                                            </option>
                                                        );
                                                    }
                                                } else {
                                                    if (e.domain_id?.toString() === '1') {
                                                        return (
                                                            <option
                                                                key={iArr.join('-')}
                                                                value={e.id}
                                                                selected={urlParams?.category_id?.toString()
                                                                    ? e.id?.toString() === urlParams?.category_id?.toString()
                                                                    : e.id?.toString() === '39'
                                                                }
                                                            >
                                                                {`${nest} ${e.title}`}
                                                            </option>
                                                        );
                                                    }
                                                }
                                            })}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-categories">Категорії</label>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '16px'}}>
                        <Button
                            onClick={handleRefreshSort}
                            style={{
                                display: checkPermission('admin.sort.products.refresh')
                                    ? 'flex'
                                    : 'none'
                            }}
                            purple
                        >
                            Рефреш сортування
                        </Button>
                        <Button
                            onClick={handleOnOpen}
                            style={{
                                display: checkPermission('admin.sort.products.move-to')
                                    ? 'flex'
                                    : 'none'
                            }}
                            border
                        >
                            <IconAdd color="#5755d1"/>
                            Додати товар
                        </Button>
                    </div>
                </div>
                <TableListViewTable>
                    <TableHead
                        arrayChildren={[
                            'ID', 'JDE', 'Назва товару УКР', 'Назва товару РУС',
                            'Позиція', 'Активність', 'Приховано', 'Статус'
                        ]}
                        filterChildren={<TableListViewFiltersRow
                            urlParams={urlParams}
                            fields={[
                                {},
                                {type: 'text', name: 'jde'},
                                {type: 'text', name: 'title_ua'},
                                {type: 'text', name: 'title_ru'},
                                {},
                                {},
                                {},
                                {
                                    type: 'select',
                                    name: 'state',
                                    options: !!filtersData?.product_states?.length && filtersData?.product_states,
                                    optProps: ["value", "title"]
                                },
                                {}
                            ]}
                            onSubmit={pushUrlParams}
                        />}
                    />
                    <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={8}/>
                        )
                        : (cardList && (
                            cardList?.length
                                ? cardList?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`sort-product-item-${e.id}`}
                                        remove={{
                                            api: remove({
                                                domain_id: urlParams?.['domain_id'],
                                                category_id: urlParams?.['category_id'],
                                            }),
                                            success: () => setRest(() => {
                                                const spliceResult = getSplicedData(i, rest);
                                                window.location.reload();

                                                spliceResult.meta.to = spliceResult.meta.to - 1;
                                                spliceResult.meta.total = spliceResult.meta.total - 1;
                                                return spliceResult;
                                            }),
                                            alert: 'Сортування товару видалено',
                                        }}
                                        permissionRemove='admin.sort.products.delete'
                                        card={e}
                                        setCurrentCard={setCurrentCard}
                                        drop={dropHandler}
                                    >
                                        <td>
                                            {checkPermission('admin.product.index') ? (
                                                <a href={`${DOMAIN_API}/app/product/update/${e?.product_id}`}>{e?.product_id}</a>
                                            ) : (
                                                <span>
                                                    {e?.product_id || '---'}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <span>
                                                {e.jde || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ua || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ru || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <select
                                                name={`sort-name-${e.id}`}
                                                className="form-select"
                                                onChange={(event) => handleSelectSort(e, event.target.value)}
                                                defaultValue={e?.manual_sort}
                                            >
                                                {sortArray.map((item) => (
                                                    <option value={item} key={`sort-name-${item}`}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <span>
                                                {e.is_active ? <StatusCircle green text="Активна"/> :
                                                    <StatusCircle red text="Вимкнено"/>}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.is_hidden ? <StatusCircle grey text="Приховано"/> :
                                                    <StatusCircle blue text="Не приховано"/>}
                                            </span>
                                        </td>
                                        <td>
                                            {!!filtersData?.product_states?.length
                                                ? filtersData?.product_states?.find(itemState => itemState?.value === e?.state)?.title
                                                : e?.state}
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={8} style={{paddingTop: '22px'}}>Немає Сортування Товарів</td>
                                    </TableListViewRow>
                                )
                        ))}
                    </tbody>
                </TableListViewTable>
            </div>

            {openEdit && (
                <Portal children={<ModalCreate
                    domainId={urlParams?.['domain_id']}
                    categoryId={urlParams?.['category_id']}
                    onClose={handleOnClose}
                    setRest={setRest}
                    rest={rest}
                />}/>
            )}
        </>
    );
};

export default SortProductsList;
