import { getCancelVariants } from '../../lib/api/cancel_variants';
import { parseAll } from "../../lib/api/_parseApi";
import {
    createOrderDocument,
    editOrderDocument,
    getOrderDocumentEdit, removeOrderDocument
} from "../../lib/api/order_document";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (templateId) => {
    const orderId = location.pathname.split('/')[4];

    return Promise.all([getOrderDocumentEdit(orderId, templateId),getCancelVariants()])
        .then(async (res) => {
            const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

            if (findStatus401) {
                return await refreshToken(() => window.location.reload())
            }

            return ({
                fields: orderId && res[0].data,
                cancelVariants: res[1].data.data,
            })
        });
}

export const create = createOrderDocument;
export const edit = editOrderDocument;
export const remove = removeOrderDocument;

export const parseDataSend = (data, soId) => {
  let d = { ...data };

  const isUpdate = typeof soId !== 'undefined';

  d = parseAll(d, !isUpdate, ['manager_id', 'template_id']);
  return d;
};

export const parseDataGet = (data, id) => {
  const d = { ...data };

  const isUpdate = typeof id !== 'undefined';

  return d;
}
