import {
  CAT_CREATE, CAT_GET_TREE, CAT_GET_EDIT, CAT_EDIT, CAT_DELETE, CAT_GET, CAT_GET_ALL, CAT_GET_SEARCH,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getCat = (params) => fetchAx({
  url: CAT_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getCatAll = (params) => fetchAx({
  url: CAT_GET_ALL,
  method: 'GET',
  params: {
    ...params,
  },
});

export const getCatSearch = (params) => fetchAx({
  url: CAT_GET_SEARCH,
  method: 'GET',
  params: {
    lang: 'ru',
    is_active: 1,
    ...params,
  },
});

export const getCatTree = (domainId) => fetchAx({
  url: CAT_GET_TREE,
  method: 'GET',
  params: {
    domain_id: domainId,
  },
});

export const createCat = (data) => fetchAx({
  url: CAT_CREATE,
  method: 'POST',
  data,
});

export const getCatEdit = (id) => fetchAx({
  url: CAT_GET_EDIT.join(id),
  method: 'GET',
});

export const editCat = (id, data) => fetchAx({
  url: CAT_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeCat = (id) => fetchAx({
  url: CAT_DELETE.join(id),
  method: 'DELETE',
});
