import React, { useState } from "react";
import { toast } from 'react-toastify';
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import Loader from "../../../components/ui/Loader";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import Button from "../../../components/ui/Button";
import InputWrapper from "../../../components/ui/InputWrapper";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";

const OrderCreditInfo = ({ formProps }) => {
    const [creditType, setCreditType] = useState([]);
    const [creditOffer, setCreditOffer] = useState([]);
    const [creditBank, setCreditBank] = useState([]);
    const dataFields = formProps.data && formProps.data.fields;
    const form = formProps.formH;

    return (
        <Item title="Информация о кредите">
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag="div">

                    {/* credit_user.credit_type_id */}
                    <Group attrs={{className: 'mt-1 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="credit_user.credit_type_id"
                                                className={s_Select.select}
                                                id="tf-credit_type_id"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value={null}>выберите к-во платежей</option>
                                                {creditType.length !== 0 && creditType?.map((credit) => (
                                                    <option
                                                        key={credit.id}
                                                        value={credit.id}
                                                        selected={credit.id === dataFields?.credit_user?.credit_type_id}
                                                    >
                                                        {credit.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="tf-credit_type_id">Тип кредита / к-во платежей*</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* credit_user.bank_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="credit_user.bank_id"
                                                className={s_Select.select}
                                                id="tf-bank_id"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value={null}>Банк не выбран</option>
                                                {creditBank.map(bank => (
                                                    <option
                                                        key={bank.bank_id}
                                                        value={bank.bank_id}
                                                        selected={bank.bank_id === dataFields?.credit_user?.bank_id}
                                                    >
                                                        {bank.bank_name}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="tf-bank">Банк*</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* credit_user.credit_offer_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                            <ColFull>
                                <div className={s_Select.select__inner}>
                                    <div className={s_Select.select__wrapper}>
                                        {formProps.data
                                            ? (
                                                <select
                                                    name="credit_user.credit_offer_id"
                                                    className={s_Select.select}
                                                    id="tf-credit_offer_id"
                                                    ref={form.register}
                                                    disabled
                                                >
                                                    <option value={null}>Название кредита не выбрано</option>
                                                    {creditOffer.length !== 0 && creditOffer.map((offer) => (
                                                        <option
                                                            key={offer.id}
                                                            value={offer.id}
                                                            selected={offer.id === dataFields?.credit_user?.credit_offer_id}
                                                        >
                                                            {offer.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            )
                                            : (
                                                <div className="p-relative">
                                                    <div className="loading" />
                                                </div>
                                            )}
                                        <label className={s_Select.label} htmlFor="tf-credit_offer_id">Название кредита</label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>

                    {/* credit_user.surname */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Фамилия*" id="tf-surname">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Фамилия*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="credit_user.surname"
                                    id="tf-surname"
                                    value={dataFields?.credit_user?.surname}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.name */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper label="Имя*" id="tf-name">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Имя*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="credit_user.name"
                                    id="tf-name"
                                    value={dataFields?.credit_user?.name}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.patronymic */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper label="Отчество*" id="tf-client_patronymic">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Отчество*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="credit_user.patronymic"
                                    id="tf-patronymic"
                                    value={dataFields?.credit_user?.patronymic}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.tin */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper label="Идентификационный код*" id="tf-tin">
                                <input
                                    className={s.input}
                                    type="number"
                                    placeholder="Идентификационный код*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="credit_user.tin"
                                    id="tf-tin"
                                    value={dataFields?.credit_user?.tin}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* button submit */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <Button purple full disabled onClick={() => {
                                handleSubmit(dataFields?.id);
                                toast("Заявка отправлена", {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }}>Отправить заявку</Button>
                        </ColFull>
                    </Group>
                </Form>

                {formProps.load && <Loader />}
            </form>
        </Item>
    )
}

export default OrderCreditInfo