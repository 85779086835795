import { getTryAndBuy, getTryAndBuyStatus, getTryAndBuyShops, getTryAndBuyDevices } from '../../lib/api/tryAndBuy';

export const getFiltersData = () => Promise.allSettled([getTryAndBuyStatus(), getTryAndBuyShops(), getTryAndBuyDevices()])
    .then((res) => ({
        status: res[0]?.value?.data,
        shops: res[1]?.value?.data,
        devices: res[2]?.value?.data,
    }));

export const getData = getTryAndBuy;
