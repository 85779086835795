import React from "react";

const IconAdd = ({ color = 'white' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1.66667C16.8383 1.66667 18.3333 3.16167 18.3333 5V15C18.3333 16.8383 16.8383 18.3333 15 18.3333H5C3.16167 18.3333 1.66667 16.8383 1.66667 15V5C1.66667 3.16167 3.16167 1.66667 5 1.66667H15ZM15 0H5C2.23833 0 0 2.23833 0 5V15C0 17.7617 2.23833 20 5 20H15C17.7617 20 20 17.7617 20 15V5C20 2.23833 17.7617 0 15 0Z" fill={color}/>
            <path d="M10.0001 15C9.54008 15 9.16675 14.6275 9.16675 14.1667V5.83333C9.16675 5.37333 9.54008 5 10.0001 5C10.4601 5 10.8334 5.37333 10.8334 5.83333V14.1667C10.8334 14.6275 10.4601 15 10.0001 15Z" fill={color}/>
            <path d="M5 9.99996C5 9.53996 5.3725 9.16663 5.83333 9.16663H14.1667C14.6267 9.16663 15 9.53996 15 9.99996C15 10.46 14.6267 10.8333 14.1667 10.8333H5.83333C5.3725 10.8333 5 10.46 5 9.99996Z" fill={color}/>
        </svg>
    );
};

export default IconAdd;