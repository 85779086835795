import React from 'react';
import s_Modal from './modal.module.scss';
import s_Table from "../orderTable.module.scss";
import s from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import Button from "../../../../components/ui/Button";

const ModalShipment = ({ active, setActive, title, form }) => {
    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper}>
                            <table className={s_Table.table}>
                                <thead className={s_Table.thead}>
                                    <tr>
                                        <th>
                                            <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                <input
                                                    className={s.checkbox}
                                                    type="checkbox"
                                                    id={`table_shipment_all`}
                                                    name={`shipment_all`}
                                                    ref={form.register}
                                                />
                                                <label htmlFor={`table_shipment_all`} className={`${s.label} ${s_Table.label}`}/>
                                            </div>
                                        </th>
                                        <th>
                                            <div>Код товара</div>
                                        </th>
                                        <th>
                                            <div>Название товара</div>
                                        </th>
                                        <th>
                                            <div>К-во</div>
                                        </th>
                                        <th>
                                            <div>Цена</div>
                                        </th>
                                        <th>
                                            <div>Стоимость</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={s_Table.tbody}>
                                    <tr>
                                        <td>
                                            <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                <input
                                                    className={s.checkbox}
                                                    type="checkbox"
                                                    id={`table_product_1`}
                                                    name={`product_1`}
                                                    ref={form.register}
                                                />
                                                <label htmlFor={`table_product_1`}
                                                       className={`${s.label} ${s_Table.label}`}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>3020074</div>
                                        </td>
                                        <td>
                                            <div>
                                                <a href="#">Смартфон Samsung Galaxy S21Ultra G998B 12GB/256GB Phantom Silver</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div>1</div>
                                        </td>
                                        <td>
                                            <div>14 999.00</div>
                                        </td>
                                        <td>
                                            <div>14 999.00</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                <input
                                                    className={s.checkbox}
                                                    type="checkbox"
                                                    id={`table_shipment_1`}
                                                    name={`shipment_1`}
                                                    ref={form.register}
                                                />
                                                <label htmlFor={`table_shipment_1`}
                                                       className={`${s.label} ${s_Table.label}`}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>3020074</div>
                                        </td>
                                        <td>
                                            <div>
                                                <a href="#">Планшет Samsung Tab S7 FE SM-T733 Wi Fi 4/64 Green</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div>1</div>
                                        </td>
                                        <td>
                                            <div>14 999.00</div>
                                        </td>
                                        <td>
                                            <div>14 999.00</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button purple>Скачать</Button>
                        </div>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalShipment;