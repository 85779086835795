import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewPagination from '../../components/common/TableListViewNew/Pagination';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from '../../components/common/TableListViewNew/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import { getData, remove } from './api';

import { geDomainName } from '../../lib/const/domains';
import checkPermission from "../../lib/checkPermission";
import StatusCircle from "../../components/ui/StatusCircle";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const BenefitList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Benefit list`} />
      {!load && (
          <TopPagination
              tabs={(
                  <TopNavTabs tabs={[
                      {
                        title: 'Преимущества',
                        url: '/app/main/benefits',
                        permissionName: 'admin.benefit.index',
                        isActive: true,
                      },
                      {
                        title: 'Популярные товары',
                        url: '/app/main/popular-goods',
                        permissionName: 'admin.popularity-block.all',
                      },
                      {
                        title: 'Уникальные сервисы',
                        url: '/app/main/unique-services',
                        permissionName: 'admin.services-block.index',
                      },
                      {
                        title: 'Сервисы',
                        url: '/app/main/services',
                        permissionName: 'admin.main-page-service.all',
                      },
                      {
                        title: 'Новинки',
                        url: '/app/main/newest',
                        permissionName: 'admin.newest-block.all',
                      },
                      {
                        title: 'Популярные категории',
                        url: '/app/main/popular-categories',
                        permissionName: 'admin.main-page-category-block.all',
                      },
                    ]}
                  />
              )}
              pagination={(
                  <>
                    {+rest?.total && (
                        <>
                          <TableListViewPagination
                              length={+rest?.last_page}
                              current={+rest?.current_page}
                              loc={location}
                              navigate={navigate}
                          />
                          <TableListViewPerPage
                              urlParams={urlParams}
                              onSelect={(val, params) => pushUrlParams(params)}
                              total={+rest?.total}
                          />
                        </>
                    )}
                  </>
              )}
          />
      )}
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название преимущества УКР</th>
            <th>Название преимущества РУС</th>
            <th>Описание УКР</th>
            <th>Описание РУС</th>
            <th>Ссылка УКР</th>
            <th>Ссылка РУС</th>
            <th>Позиция</th>
            <th>Домен</th>
            <th>Статус активность</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={11} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`benefit-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Преимущество удалено',
                    }}
                    editLink={`/app/main/benefit/update/${e.id}`}
                    permissionRemove='admin.benefit.destroy'
                    permissionEdit='admin.benefit.edit'
                  >
                    <td>
                      {checkPermission('admin.benefit.edit')
                          ? <Link to={`/app/main/benefit/update/${e.id}`}>{e.id}</Link>
                          : <span>{e.id}</span>
                      }
                    </td>
                    <td>
                      <span>
                        {e.title_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.title_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.subscription_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.subscription_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.link_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.link_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.position}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.domain_id && geDomainName(e.domain_id)?.name}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Отключена" />}
                      </span>
                    </td>

                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={11} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default BenefitList;
