export const scrollIntoViewHelper = (errors) => {
    const firstError = Object.keys(errors)[0];

    if(!!Object?.keys(errors[firstError])?.length) {
        const childError = Object.keys(errors[firstError])[0];

        let elChild = document.querySelector(`[name='${firstError}.${childError}']`);

        if(elChild) {
            elChild.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }

    } else {
        let el = document.querySelector(`[name='${firstError}']`);
        if(el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    }
}