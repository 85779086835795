import {
  POPULAR_GOOD_CREATE,
  POPULAR_GOOD_DELETE,
  POPULAR_GOOD_GET_EDIT,
  POPULAR_GOOD_EDIT,
  POPULAR_GOOD_GET_ALL,
} from '../../const/api';
import fetchAx from '../fetchAx';

export const getPopularGoodsAll = (params) => fetchAx({
  url: POPULAR_GOOD_GET_ALL,
  method: 'GET',
  params: {
    ...params,
  },
});

export const createPopularGood = (data) => fetchAx({
  url: POPULAR_GOOD_CREATE,
  method: 'POST',
  data,
});

export const getPopularGoodEdit = (id) => fetchAx({
  url: POPULAR_GOOD_GET_EDIT.join(id),
  method: 'GET',
});

export const editPopularGood = (id, data) => fetchAx({
  url: POPULAR_GOOD_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removePopularGood = (id) => fetchAx({
  url: POPULAR_GOOD_DELETE.join(id),
  method: 'DELETE',
});
