import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData } from "./api";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { COOPERATION_B2B_CREATE, DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import getTZtoDate from "../../lib/getTZtoDate";
import TopPanelNew from "../../components/common/TopPanelNew";
import { ModalEditCooperationB2B } from "./Modal";
import StatusCircle from "../../components/ui/StatusCircle";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import checkPermission from "../../lib/checkPermission";

const CooperationB2BList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
    filtersData,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  const findStatusColor = (value) => {
      switch (value) {
          case "completed": {
              return <StatusCircle green text={filtersData?.statuses?.find(item => item.value?.toString() === value?.toString())?.title} />
          }

          case "pending": {
              return <StatusCircle yellow text={filtersData?.statuses?.find(item => item.value?.toString() === value?.toString())?.title} />
          }

          case "canceled": {
              return <StatusCircle red text={filtersData?.statuses?.find(item => item.value?.toString() === value?.toString())?.title} />
          }

          default: {
              return <StatusCircle grey text="---" />
          }
      }
  };

  const [activeModal, setActiveModal] = useState(false);
  const [activeId, setActiveId] = useState(false);

  return (
    <>
        <TopPanelNew
            title="B2B-Співпраця (заявки)"
            breadcrumbs={{
                items: [
                    {
                        title: 'Головна',
                        url: '/app',
                        permissionName: 'admin.application-forms.index',
                    },
                    {
                        title: 'B2B-Співпраця (заявки)',
                        url: '/app/cooperation-b2b',
                        permissionName: 'admin.application-forms.index',
                    },
                ],
            }}
            btnClearParams={{
                title: 'Скинути фільтри',
                href: '/app/cooperation-b2b',
                permissionName: 'admin.application-forms.index',
            }}
            btnModal={{
                title: 'Створити заявку B2B-Співпраця',
                api: COOPERATION_B2B_CREATE,
                href: '/app/cooperation-b2b',
                permissionName: 'admin.application-forms.store',
                redirect: false,
                fields: [
                    { type: 'text', name: 'name', label: 'ПІБ', placeholder: 'ПІБ' },
                    { type: 'phone', name: 'phone', label: 'Телефон', placeholder: 'Телефон' },
                    { type: 'email', name: 'email', label: 'Email', placeholder: 'Email' },
                    { type: 'text', name: 'company_name', label: 'Назва компанії', placeholder: 'Назва компанії' },
                    { type: 'number', name: 'company_hours', label: 'Кількість робітників', placeholder: 'Кількість робітників' },
                    {
                        type: 'select',
                        name: 'type',
                        options: filtersData?.types?.length && filtersData?.types,
                        label: 'Тип заявки',
                        placeholder: 'Тип заявки',
                        optProps: ['value', 'title']
                    },
                    {
                        type: 'textarea',
                        name: 'manager_comment',
                        id: 'cooperation-b2b-manager-comment-create',
                        placeholder: 'Комментар'
                    },
                ],
                defaultSendValue: {
                    status: 'pending',
                    domain_id: 1
                }
            }}
        />
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Cooperation B2B list`} />
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Ім’я', 'Телефон', 'Коментар', 'Email', 'Компанія', 'К-ть роб.', 'Тип заявки',
                        'Дата створення', 'Статус', 'Менеджер'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            { },
                            { },
                            { type: 'text', name: 'phone' },
                            { },
                            { type: 'text', name: 'email' },
                            { type: 'text', name: 'company_name' },
                            { },
                            {
                                type: 'select',
                                name: 'type',
                                options: filtersData?.types?.length && filtersData?.types,
                                optProps: ['value', 'title']
                            },
                            { type: 'date_range', startName: 'created_from', endName: 'created_to', width: '18%' },
                            {
                                type: 'select',
                                name: 'status',
                                options: filtersData?.statuses?.length && filtersData?.statuses,
                                optProps: ['value', 'title']
                            },
                            { },
                            { }
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={11} />
                        )
                        : (rest && (
                            !!rest?.data?.length
                                ? rest?.data?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`cooperation-b2b-item-${e.id}`}
                                        permissionEdit='admin.application-forms.edit'
                                        editLink
                                        deleteButton={false}
                                        setActiveModal={setActiveModal}
                                        setActiveId={setActiveId}
                                        tagA={false}
                                    >
                                        <td>
                                            {checkPermission('admin.application-forms.edit')
                                                ? <span style={{ color: '#5755d9', cursor: 'pointer' }} onClick={() => {
                                                    setActiveModal(true);
                                                    setActiveId(e.id);
                                                }}>{e.id}</span>
                                                : <span>{e.id}</span>
                                            }
                                        </td>
                                        <td>
                                            <span>{e.name || '---'}</span>
                                        </td>
                                        <td>
                                            <span>{e.phone || '---'}</span>
                                        </td>
                                        <td>
                                            <span>{e.manager_comment || '---'}</span>
                                        </td>
                                        <td>
                                            <span>{e?.email || '---'}</span>
                                        </td>
                                        <td>
                                            <span>{e?.company_name || '---'}</span>
                                        </td>
                                        <td>
                                            <span>{e?.company_hours || '---'}</span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.type ? filtersData?.types?.find(item => item.value === e?.type)?.title : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.status ? findStatusColor(e?.status) : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.manager_id ? !!filtersData?.managers?.length && filtersData?.managers?.find(item => item.id === e?.manager_id)?.name : '---'}
                                            </span>
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={11}>Немає заявок B2B-Співпраця</td>
                                    </TableListViewRow>
                                )
                        ))}
                </tbody>
            </TableListViewTable>
            {activeModal && (
                <ModalEditCooperationB2B
                    setActive={setActiveModal}
                    active={activeModal}
                    activeId={activeId}
                    filtersData={filtersData}
                    title="Обробка заявки B2B-Співпраця"
                />
            )}
        </div>
    </>
  );
};

export default CooperationB2BList;
