const filterDateTimeExport = (date = '') => {
    let result = '';

    if (date) {
        const decodedString = decodeURIComponent(date);

        result = decodedString.replace("%20", " ").replace(/%3A/g, ":");
    }

    return result;
};

export default filterDateTimeExport;