import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import Button from "../../../components/ui/Button";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import errorMessage from "../../../lib/errorMessage";
import { createQF, editQF, getQFEdit } from "../../../lib/api/quickFilter";

const ModalQuickFilter = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            title_ua: '',
            title_ru: ''
        }
    });

    const {
        register,
        errors,
        handleSubmit,
        setValue
    } = formH;

    const submitHandler = async (data) => {
        if (editId) {
            await editQF(editId, data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        } else {
            await createQF(data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        }
    }

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getQFEdit(editId).then((res) => {
                setValue('title_ua', res.data.title_ua);
                setValue('title_ru', res.data.title_ru);
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Новый швидкий фільтр'}</div>
                        </div>
                    </div>
                    <div>
                        {/* title_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Назва УКР"
                                    id="quick-filter-name-ua"
                                    errorComponent={errors?.title_ua &&
                                    <p className="form-input-hint">{errors?.title_ua?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.title_ua,
                                        })}
                                        type="text"
                                        placeholder="Назва УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ua"
                                        id="quick-filter-name-ua"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* title_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Назва РУС"
                                    id="quick-filter-name-ru"
                                    errorComponent={errors?.title_ru &&
                                    <p className="form-input-hint">{errors?.title_ru?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.title_ru,
                                        })}
                                        type="text"
                                        placeholder="Назва РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ru"
                                        id="quick-filter-name-ru"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__group}>
                            <div className={s_Modal.modal__buttons}>
                                <div>
                                    <Button purple type='submit' disabled={load}>Додати</Button>
                                </div>
                            </div>

                            <div>
                                <Button border onClick={() => setActive(false)}>Скасувати</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalQuickFilter;