import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import {
  createBannerMenu, editBannerMenu, getBannerMenuEdit, removeBannerMenu,
} from "../../lib/api/bannerMenu";
import { refreshToken } from "../../lib/crud/refreshToken";
import { getCategoriesMenu } from "../../lib/api/categories_menu";
import {LIST_PER_PAGE} from "../../const/view";
import {getCatTree} from "../../lib/api/categories";

export const getData = (bmhId) => Promise.allSettled([
    getDomains(), getCatTree(1),
  bmhId && getBannerMenuEdit(bmhId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    const parseCategoriesAction = [];

    const parseCategoriesActionNodes = (nodes) => {
      if (!!nodes.children?.length) {
        nodes.children.map((node) => parseCategoriesActionNodes(node))
      }

      return parseCategoriesAction.push({
        id: nodes.id,
        title: nodes.title
      });
    }

    parseCategoriesActionNodes(res[1]?.value?.data[0])

    return ({
      domains: res[0]?.value?.data,
      categories: parseCategoriesAction,
      fields: bmhId && res[2]?.value?.data,
    })
  });

export const create = createBannerMenu;
export const edit = editBannerMenu;
export const remove = removeBannerMenu;

export const parseDataSend = (data, bmhId) => {
  let d = { ...data };
  const isUpdate = typeof bmhId !== 'undefined';

  if (d?.starts_at) {
    d['starts_at'] = `${d.starts_at} ${d.time_from}`;
  }

  if (d?.finish_at) {
    d['finish_at'] = `${d.finish_at} ${d.time_to}`;
  }

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  delete d.time_from;
  delete d.time_to;

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
