import {
  PERMISSION_WATCHED_EDIT,
  PERMISSIONS_DELETE,
  PERMISSIONS_GET, PERMISSIONS_TREE_GET,
} from '../../const/api';
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

export const getPermissions = (params) => fetchAx({
  url: PERMISSIONS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  }
});

export const getPermissionsTree = () => fetchAx({
  url: PERMISSIONS_TREE_GET,
  method: 'GET',
});

export const editPermissionWatched = (id) => fetchAx({
  url: PERMISSION_WATCHED_EDIT.join(id),
  method: 'PUT',
});

export const removePermissions = (id) => fetchAx({
  url: PERMISSIONS_DELETE.join(id),
  method: 'DELETE',
});
