import React from 'react';
import s from './InputRadio.module.scss';

const InputRadio = ({ id, label, name, defaultChecked, value, onChange, onClick, formH, valueInput }) => {
    return (
        <div className={s.radio__wrapper}>
            <input
                type="radio"
                name={name}
                id={id}
                className={s.radio__input}
                ref={formH?.register}
                onClick={onClick}
                checked={value}
                defaultChecked={defaultChecked}
                onChange={onChange}
                value={valueInput}
            />
            <label className={s.radio__label} htmlFor={id}>{label}</label>
        </div>
    );
};

export default InputRadio;