import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import Button from "../../../components/ui/Button";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import {createDocument, editDocument, getDocumentEdit, getDocumentsTypes} from "../../../lib/api/documents";
import errorMessage from "../../../lib/errorMessage";
import InputFileDocument from "../../../components/ui/InputFileDocument";
import Textarea from "../../../components/ui/Textarea";
import sSelect from "../../OrderEditView/fakeData/orderStyle.module.scss";
import loadMedia from "../../../lib/helpers/loadMedia";

const ModalDocument = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [file, setFile] = useState(null);
    const [dataSelect, setDataSelect] = useState(null);

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            title_ua: '',
            position: 1,
            type: '',
            is_active: false
        }
    });

    const {
        register,
        errors,
        watch,
        handleSubmit,
        setValue
    } = formH;

    const isActiveWatch = watch('is_active')

    const submitHandler = async (data) => {
        if (editId) {
            await editDocument(editId, data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        } else {
            await createDocument(data).then(async (resCreate) => {
                if (file && resCreate?.data?.id) {
                    await loadMedia(
                        file,
                        () => window.location.reload(),
                        'PNG, JPG, PDF',
                        '1',
                        'images',
                        resCreate?.data?.id,
                        'document');
                } else {
                    window.location.reload();
                }
            }).catch(async (err) => await errorMessage(err, ''))
        }
    }

    useEffect(() => {
        const getFiltersData = async () => {
            await getDocumentsTypes()
                .then((res) => setDataSelect([...res?.data]))
                .catch(async (err) => await errorMessage(err, ''));
        }

        getFiltersData();
    }, []);

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getDocumentEdit(editId).then((res) => {
                setValue('title_ua', res?.data?.data?.title_ua);
                setValue('title_ru', res?.data?.data?.title_ua);
                setValue('position', res?.data?.data?.position);
                setValue('type', res?.data?.data?.type);
                setValue('is_active', !!res.data?.data.is_active);

                if (res?.data?.data?.file) {
                    const parseName = res?.data?.data?.file?.original?.split('/');
                    const nameDoc = parseName[parseName?.length - 1];
                    setFileData({
                        id: res?.data?.data?.file?.id,
                        url: res?.data?.data?.file?.original,
                        title: nameDoc
                    })
                }
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Новий документ'}</div>
                        </div>
                        <div>
                            {/* is_active */}
                            <CheckboxSwitch
                                name="is_active"
                                id="document-is-active"
                                formH={formH}
                                defaultChecked={isActiveWatch}
                            />
                        </div>
                    </div>
                    <div>
                        {/* title */}
                        <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <Textarea
                                    name="title_ua"
                                    id="document-title-ua"
                                    placeholder="Назва документа"
                                    formH={formH}
                                    // defaultValue={data?.fields?.short_desc_ua}
                                />
                            </ColFull>
                        </Group>

                        {/* position */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputWrapper
                                    label="Позиція"
                                    id="document-position"
                                    errorComponent={errors?.position &&
                                    <p className="form-input-hint">{errors?.position?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.position,
                                        })}
                                        type="number"
                                        placeholder="Позиція"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="position"
                                        id="document-position"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <div className={sSelect.select__inner}>
                                    <div className={sSelect.select__wrapper}>
                                        <select
                                            name="type"
                                            className={cn(sSelect.select, {
                                                'is-error': errors.type,
                                            })}
                                            id="document-type"
                                            ref={register({
                                                required: VALIDATION.select.required,
                                            })}
                                        >
                                            <option value="">виберіть тип</option>
                                            {!!dataSelect?.length && dataSelect?.map((item) => (
                                                <option
                                                    key={`document-type-item-${item.value}`}
                                                    value={item.value}
                                                >
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                        <label className={sSelect.label} htmlFor="document-type">Тип</label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>

                        {/* file */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull attrs={{ style: { padding: 0 } }}>
                                <InputFileDocument
                                    name="file"
                                    val={fileData}
                                    formH={formH}
                                    ref={register}
                                    acceptStr="PNG, JPG, PDF"
                                    accept="image/*,.pdf"
                                    sizeMB={5}
                                    placeHolder="Завантажити документ"
                                    className="d-flex"
                                    documentId={editId ? editId : 1}
                                    setFileAfterSave={editId ? false : setFile}
                                    border
                                />
                            </ColFull>
                        </Group>
                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__buttons}>
                            <Button purple type='submit' disabled={load}>{editId ? 'Зберегти' : 'Додати'}</Button>
                            <Button border onClick={() => setActive(false)}>Скасувати</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalDocument;