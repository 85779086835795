import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s_Constructor from "../../ProductEditView/tabs/constructor/TypeField/TypeField.module.scss";
import {Editor} from "@tinymce/tinymce-react";
import {TINY_API_KEY} from "../../../const/api";
import {VALIDATION} from "../../../const/text";
import React from "react";

export const TinyComponent = ({titleValue, setTitleValue, register, placeholder,name, id}) => {
  return (
    <>
      <Group attrs={{style: {margin: 0, maxWidth: '100%'}}}>
        <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
          <div
          >
            <Editor
              apiKey={TINY_API_KEY}
              value={titleValue}
              onEditorChange={(newValue) => setTitleValue(newValue)}
              init={{
                width: '886px',
                height: '785px',
                menubar: false,
                language: 'ru',
                toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',
                placeholder,
                plugins: ['link', 'code', 'lists', 'preview'],
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <input
              type="text"
              name={name}
              id={id}
              value={titleValue}
              ref={register}
              style={{display: 'none'}}
              readOnly
            />
          </div>
        </ColFull>
      </Group>
    </>
  );
};