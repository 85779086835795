import {
  UNACCOUNTED_PRODUCTS_GET_EDIT,
  UNACCOUNTED_PRODUCTS_EDIT,
  UNACCOUNTED_PRODUCTS_CREATE,
  UNACCOUNTED_PRODUCTS_GET_ALL, UNACCOUNTED_PRODUCTS_GET, UNACCOUNTED_PRODUCTS_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getUnaccountedProducts = (params) => fetchAx({
  url: UNACCOUNTED_PRODUCTS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getUnaccountedProductsAll = () => fetchAx({
  url: UNACCOUNTED_PRODUCTS_GET_ALL,
  method: 'GET',
});

export const createUnaccountedProduct = (data) => fetchAx({
  url: UNACCOUNTED_PRODUCTS_CREATE,
  method: 'POST',
  data,
});

export const getUnaccountedProductEdit = (id) => fetchAx({
  url: UNACCOUNTED_PRODUCTS_GET_EDIT.join(id),
  method: 'GET',
});

export const editUnaccountedProduct = (id, data) => fetchAx({
  url: UNACCOUNTED_PRODUCTS_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeUnaccountedProduct = (id) => fetchAx({
  url: UNACCOUNTED_PRODUCTS_DELETE.join(id),
  method: 'DELETE',
});
