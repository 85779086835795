import React, {useState} from 'react';
import Item from "../Item";
import {getOrderSimilarEdit} from "../../../lib/api/order";
import errorMessage from "../../../lib/errorMessage";
import s from "./orderTable.module.scss";
import getTZtoDate from "../../../lib/getTZtoDate";
import IconIncomingCall from "../../../components/icon/iconIncomingCall";
import IconOutgoingCall from "../../../components/icon/iconOutgoingCall";
import IconLinkChain from "../../../components/icon/iconLinkChain";
import IconDroppedCall from "../../../components/icon/iconDroppedCall";
import IconMissedCall from "../../../components/icon/iconMissedCall";

const OrderCallsHistory = ({callsHistory}) => {
    const [viewingLength, setViewingLength] = useState(2);

    const onClickHandler = () => {
        if (callsHistory && viewingLength <= 2) {
            setViewingLength(callsHistory.length)
        } else {
            setViewingLength(2)
        }
    }

    const getPhoneIcon = (call) => {
        if(!call?.billsec && !call?.internal_phone) {
            return <IconDroppedCall title={"Пропущеный"}/>
        } else if(!call?.billsec && call?.internal_phone) {
            return <IconMissedCall title={"Недозвон клиенту"}/>
        } else if(call?.type === 'incoming') {
            return <IconIncomingCall title={"Входящий"}/>
        } else {
            return <IconOutgoingCall title={"Исходящий"}/>
        }
    }

    return (
        <Item title={`Історія дзвінків (${callsHistory?.length || 0})`}
              styleHeader={{cursor: 'pointer', display: "flex", justifyContent: "space-between"}}
              lookAllTitle={!!callsHistory?.length && viewingLength === 2 ? "Дивитись всі" : !!callsHistory?.length ? 'Згорнути' : ""} onClick={onClickHandler}>
            {!!callsHistory?.length && (
                <table className={s.table__calls}>
                    <thead>
                    <tr>
                        <th>Дата та час</th>
                        <th>Вх./Вих.</th>
                        <th>Менеджер</th>
                        <th>Лінк</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!!callsHistory?.length && (
                        callsHistory?.map((e, index, i) => {
                            if (index <= viewingLength) {
                                return (
                                    <tr>
                                        <td>
                                            <span style={{fontSize: "13px", fontWeight: "bold"}}>
                                                  {e?.starts_at ? getTZtoDate(e?.starts_at, true) : '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {getPhoneIcon(e)}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.managers && e?.managers[0]?.name ? e.managers.map(manager => manager.name).join(', ')
                                                    : e.internal_phone ? e.internal_phone : '---' }
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.record_link && <a href={e?.record_link} target="_blank" rel="noopener noreferrer">
                                                    <IconLinkChain color="primary"/>
                                                </a> || '---'}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    )}

                    </tbody>
                </table>
            )}
        </Item>
    );
};

export default OrderCallsHistory;