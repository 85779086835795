import React, { useRef, useState } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import loadMedia from '../../../lib/helpers/loadMedia';
import useSortable from '../../../lib/useSortable';
import MediaFile from '../MediaFile';
import { savePositionMedia } from "../../../lib/api/media";
import errorMessage from "../../../lib/errorMessage";

import s from './GalleryImages.module.scss';
import { refreshToken } from "../../../lib/crud/refreshToken";
import downloadIcon from "../../../assets/icons/icon_download.svg";
import dragDropIcon from "../../../assets/icons/icon_drag_drop.svg";
import CopyComponent from "../CopyComponent";
import fileDownload from "../../../lib/helpers/fileDownload";

const GalleryImages = ({
                       id,
                       nameParent,
                       formH,
                       accept,
                       limit,
                       acceptStr,
                       minOneRequired,
                       maxWidth = 3840,
                       maxHeight = 2160,
                       marginLeft = 195,
                       width = 175,
                       height = 180,
                       setActiveMedia,
                       prodID,
                       setDataModal,
                       setActiveRemove,
                       imageData,
                       setImageData,
                       entityType = 'product',
                       collectionName = 'images',
                       altData = {},
                       langLabel = 'UA',
                       windowName = 'mobile',
                       showDeleteBtn = true,
                       showCreateBtn = true,
                       classNameImg,
                       setLoad,
                       copyBtn = false,
                       downloadBtn = false,
                       typeFile
}) => {
      const nameOneReq = `${id}_oneReq`;
      const [addDisable, setAddDisable] = useState(false);
      const listRef = useRef(null);

      const _swap = (prop1, prop2) => {
          if(prop1 === prop2) return;

          setImageData(prev => {
              let temp = [...prev];
              let tmp = temp[prop1];
              temp[prop1] = temp[prop2];
              temp[prop2] = tmp;

              const parseImageData = temp?.map(item => item.id);
              savePositionMedia({position: parseImageData})
                  .then(res => {})
                  .catch(async (err) => await errorMessage(err, '/api/admin/media/position POST'))
              return temp;
          });
      }

      useSortable({
        ref: listRef,
        settings: {
          handle: ".sortablejs-custom-handle",
          forceFallback: true,
          onEnd: (evt) => {
              if(listRef?.current?.textContent !== 'Выберите файл') {
                  return _swap(evt.oldIndex, evt.newIndex);
              }
          },
        },
      });

    async function fileHandler(e) {
        const fList = e.target.files;

        if (!fList.length) return;

        let reader = new FileReader();
        reader.onload = function(e) {
            if (typeFile === "video") {
                let video = document.createElement('video');

                video.src = e.target.result;
            } else {
                let img = document.createElement('img');
                img.onload = function() {
                    if(maxWidth < this.width && maxHeight < this.height) {
                        alert(`Допустимый размер картинки: ${maxWidth}x${maxHeight}`);
                    }
                };
                img.src = e.target.result;
            }

        }
        reader.readAsDataURL(fList[0]);
        try {
          // const compressedFile = await imageCompression(imageFile, options);

            for (let i = 0; i < fList.length; i++) {
                if (setLoad) {
                    setLoad(true);
                }

                await loadMedia(
                    fList[i],
                    (data) => {
                        const parseImageData = imageData?.map(item => item.id);

                        let result = [data?.id];
                        if(parseImageData) {
                            result = [...parseImageData, data?.id];
                        }

                        setImageData(prev => {
                            if (!!prev?.length) {
                                return [
                                    ...prev,
                                    data
                                ]
                            }
                            return [data]
                        });

                        if (result) {
                            savePositionMedia({position: result})
                                .then(res => {})
                                .catch(async (err) => {
                                    if(err?.response?.status === 401) {
                                        await refreshToken(async () => {
                                            await savePositionMedia({position: result}).then(res => {})
                                        })
                                    } else {
                                        await errorMessage(err, '/api/admin/media/position POST')
                                    }
                                }).finally(() => {
                                if (setLoad) {
                                    setLoad(false);
                                }
                            })
                        }
                    },
                    acceptStr,
                    1,
                    collectionName,
                    prodID,
                    entityType,
                    altData,
                );
            }

        } catch (error) {
            await errorMessage(error, '')
        }
    }

    return (
      <div className={s.wrap}>
          {showCreateBtn && (
              <div className={cn(s.box_add, {
                  [s.boxError]: minOneRequired && get(formH.errors, nameOneReq),
                  [s.p_relative]: !imageData.length,
              })}
                   style={{ width: width, height: height }}
              >
                  <div className={s.drop}>
                      <label htmlFor={id}>
                          <input
                              id={id}
                              className="d-hide"
                              type="file"
                              onChange={fileHandler}
                              accept={accept}
                              multiple
                          />
                      </label>
                      <div className={s.drop__block}>
                          <img src={downloadIcon} alt="download" />
                          <p>{typeFile === "video" ? "Додати відео" : "Додати зображення"}</p>
                      </div>
                  </div>
              </div>
          )}
          <div
              className={s.list}
              ref={listRef}
          >
              {(minOneRequired && !imageData?.length) && <input type="hidden" name={nameOneReq} ref={formH.register({ required: true })} />}

              {!!imageData?.length && imageData.map((e, i) => {
                  const name = `${nameParent}[${i}]`;
                  return (
                      <div
                          key={e.id}
                          className={cn(s.box, {
                              [s.cover_image]: e?.collection_name === 'cover',
                              [s.ml_0]: !showCreateBtn,
                          })}
                          style={{ width: width, height: height, marginLeft: i === 0 && marginLeft }}
                      >
                          <Item
                              val={e?.conversions?.original}
                              typeFile={e?.mime_type}
                              index={i}
                              name={name}
                              formH={formH}
                              setActiveMedia={setActiveMedia}
                              setActiveRemove={setActiveRemove}
                              setDataModal={setDataModal}
                              setImageData={setImageData}
                              defVal={e}
                              prodID={prodID}
                              acceptStr={acceptStr}
                              entityType={entityType}
                              collectionName={collectionName}
                              showDeleteBtn={showDeleteBtn}
                              className={classNameImg}
                              copyBtn={copyBtn}
                              downloadBtn={downloadBtn}
                              setLoad={setLoad}
                          />
                      </div>
                  );
              })}
          </div>

          {/*{!addDisable && (*/}
          {/*  <div className={cn(s.box,*/}
          {/*    { [s.boxError]: minOneRequired && get(formH.errors, nameOneReq) })}*/}
          {/*  >*/}
          {/*    <div className={s.drop}>*/}
          {/*      <label htmlFor={id}>*/}
          {/*        <input*/}
          {/*          id={id}*/}
          {/*          className="d-hide"*/}
          {/*          type="file"*/}
          {/*          onChange={fileHandler}*/}
          {/*          accept={accept}*/}
          {/*          multiple*/}
          {/*        />*/}
          {/*      </label>*/}

          {/*      <button*/}
          {/*        type="button"*/}
          {/*        className="btn btn-action btn-sm"*/}
          {/*      >*/}
          {/*        <i className="icon icon-plus" />*/}
          {/*      </button>*/}
          {/*       <p className={s.phDrop}>Выберите файл</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
      </div>
  );
};

const Item = ({
                  val,
                  typeFile,
                  index,
                  name,
                  formH,
                  setActiveMedia,
                  setDataModal,
                  setActiveRemove,
                  defVal,
                  setImageData,
                  prodID,
                  acceptStr,
                  collectionName,
                  entityType,
                  showDeleteBtn,
                  className,
                  copyBtn,
                  downloadBtn = false,
                  setLoad
}) => {
  const [showComp, setShowComp] = useState('');

  const Block = (showDeleteBtn, copyBtn, setLoad) => {
      const handleDownloadImg = async (e) => {
          e.preventDefault();

          const fileName = val?.split('/')?.[val?.split('/')?.length - 1];

          if (setLoad) {
              setLoad(true);
              await fileDownload(val, fileName, setLoad);
          } else {
            await fileDownload(val, fileName);
          }
      };

      return (
          <div className={s.hover__btn_group}>
              <button className="btn btn-action s-circle" type="button" style={{ position: 'absolute', top: '10px', left: '10px' }}>
                  <img src={dragDropIcon} style={{ height: 'auto'}} alt="icon drag and drop" className="sortablejs-custom-handle bi-grip-horizontal list-group-icon" />
              </button>
              {downloadBtn && (
                  <a
                      href={val}
                      download="1.png"
                      className='btn btn-action s-circle'
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onClick={handleDownloadImg}
                  >
                      <img style={{ height: '18px' }} src={downloadIcon} alt="icon download" />
                  </a>
              )}

              <button className='btn btn-action s-circle' type='button' onClick={() => {
                  setDataModal(defVal);
                  setActiveMedia(true);
              }}>
                  <i className="icon icon-edit" />
              </button>

              {showDeleteBtn && (
                  <button
                      className={`btn btn-action s-circle ${s.btn_danger}`}
                      type="button"
                      onClick={() => {
                          setDataModal({
                              ...defVal,
                              'indexItem': index
                          });
                          setActiveRemove(true);
                      }}
                  >
                      <i className="icon icon-delete" />
                  </button>
              )}

              {copyBtn && (
                  <button className="btn btn-action s-circle" type="button">
                      <CopyComponent copyText={val} />
                  </button>
              )}
          </div>
      )
  }
  const handleLeave = () => setShowComp('');
  const handleHover = () => setShowComp(Block(showDeleteBtn, copyBtn, setLoad));

  return (
    <div className={`position-relative ${className ? className : ''}`} style={{ position: 'relative' }} onMouseOver={handleHover} onMouseLeave={handleLeave}>
      <MediaFile
        name={name}
        defVal={val}
        typeFile={typeFile}
        // val={formH.watch(name)}
        val={val}
        ref={formH.register()}
        formH={formH}
        removeBtn={false}
        setImageData={setImageData}
        prodID={prodID}
        indexItem={index}
        acceptStr={acceptStr}
        entity_type={entityType}
        collection_name={collectionName}
      />
        {showComp}
    </div>
  );
};

export default GalleryImages;
