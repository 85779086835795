import { ENV_DEV } from '../../const/env';

/**
 * Get Image URL with backend domain
 * (used for redirects images)
 *
 * @param url
 * @returns {string|*}
 */

const getImgUrl = (url) => {
  return `${process.env.REACT_APP_CDN_DOMAIN}/${url}`
  // const d = ENV_DEV ? '' : process.env.REACT_APP_BACKEND_DOMAIN;
  // const pref = `/storage/app/${url}`;
  //
  // return d ? d + pref : pref;
};

export default getImgUrl;
