import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import TopPanelNew from "../../components/common/TopPanelNew"
import s from "./PopularGood.module.scss";
import ShowBlock from "./components/ShowBlock";
import FieldsBlock from "./components/FieldsBlock";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";

const PopularGoodEditView = () => {
  const { id: popularGoodID } = useParams();
  const formH = useForm();
  const {
    handleSubmit,
    reset,
  } = formH;

  const navigate = useNavigate();

  const { load, data, submitHandler } = useEdit({
    targetID: popularGoodID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/popular-good/not-found',
      remove: `/app/main/popular-goods${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/popular-goods${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Популярный товар создан',
      edit: 'Популярный товар обновлен',
      remove: 'Популярный товар удален',
    },
  });
  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Popular goods edit`} />
      <TopPanelNew
          title={popularGoodID ? `Популярный товар - ${data && data?.fields?.product?.title_ru}` : "Новый популярный товар"}
          breadcrumbs={{
            items: [
              {
                title: "Dashboard",
                url: "/app",
              },
              {
                title: "Популярный товар",
                url: `/app/main/popular-goods${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              },
              {
                title: popularGoodID ? `Популярный товар - ${data && data?.fields?.product?.title_ru}` : "Новый популярный товар",
              },
            ],
          }}
          back={`/app/main/popular-goods${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          btnRemove={{
            api: remove,
            alert: `Удалить популярный товар “${data?.fields?.product?.title_ru}”?`,
            href: `/app/main/popular-goods${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            navigate
          }}
          btnEdit
      />

      <div>
        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          <div className={s.wrap}>
            <FieldsBlock data={data} formH={formH} />
            {data?.fields?.product && (
              <ShowBlock data={data} position={data?.fields?.position} desc={data?.fields?.desc_ua} />
            )}
          </div>
        </Form>
      </div>

      {load && <Loader />}
    </>
  );
};

export default PopularGoodEditView;
