import { getSpecOffer, removeSpecOffer } from '../../lib/api/specOffers';
import { getDomains } from '../../lib/api/domains';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
  }));

export const getData = getSpecOffer;
export const remove = removeSpecOffer;
