export default (editor, opts = {}) => {
    const dc = editor.DomComponents;

    dc.addType(opts.name, {
        model: {
            defaults: {
                traits: [
                    {
                        type: "text",
                        name: "href",
                        label: "Link",
                        changeProp: '',
                    },
                    {
                        type: "text",
                        name: "title",
                        label: "Title",
                        changeProp: '',
                    },
                ],
            },
        },
        isComponent: (el) => {
            if(el.className && el.className.includes('link-div-native')) {
                return {
                    type: opts.name,
                };
            }
        },
    });
};