import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import InputFile from '../../components/ui/InputFileNew';
import getTZtoDate from '../../lib/getTZtoDate';
import TopPanelNew from '../../components/common/TopPanelNew';
import s from './ServiceEdit.module.scss';
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import BlockUa from "./component/BlockUa";
import BlockRu from "./component/BlockRu";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const ServiceEditView = () => {
  const { id: serviceID } = useParams();
  const formH = useForm();
  const {
    errors,
    handleSubmit,
    watch,
    control,
    reset,
  } = formH;

  const navigate = useNavigate();

  const { load, data, submitHandler } = useEdit({
    targetID: serviceID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/service/not-found',
      remove: `/app/main/services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Сервис создан',
      edit: 'Сервис обновлен',
      remove: 'Сервис удален',
    },
  });

/* eslint-disable */

  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Services edit`} />
      <TopPanelNew
          title={serviceID ? `Сервис - ${data && data.fields.title_ru}` : "Новый сервис"}
          breadcrumbs={{
            items: [
              {
                title: "Dashboard",
                url: "/app",
              },
              {
                title: "Сервисы",
                url: `/app/main/services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              },
              {
                title: serviceID ? `Сервис - ${data && data.fields.title_ru}` : "Новый сервис",
              },
            ],
          }}
          back={`/app/main/services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          updateDate={data?.fields?.updated_at && getTZtoDate(data?.fields?.updated_at, true)}
          btnRemove={{
            api: remove,
            alert: `Удалить cервис “${data?.fields?.title_ru}”?`,
            href: "/app/main/services",
            navigate
          }}
          btnEdit
      />

      <Form attrs={{
        onSubmit: handleSubmit(submitHandler),
      }}
      >
        <button type="submit" className="d-hide" />

        <div className={s.top}>
          <div className={s.top__item}>
            {/* domain_id */}
            <div className={s.top__input}>
              <div className={s_Select.select__inner}>
                <div className={s_Select.select__wrapper}>
                  {data
                      ? (
                          <select
                              name="domain_id"
                              className={cn(s_Select.select, {
                                [s_Select.select_error]: formH.errors?.domain_id,
                              })}
                              id="ser-domain"
                              ref={formH.register({
                                required: VALIDATION.select.required,
                              })}
                          >
                            <option value="">выберите домен</option>
                            {data && !!data.domains && data.domains.map((domain) => (
                                <option
                                    key={domain.id}
                                    value={domain.id}
                                    selected={domain.id === data?.fields?.domain_id}
                                >
                                  {domain.title}
                                </option>
                            ))}
                          </select>
                      )
                      : (
                          <div className="p-relative">
                            <div className="loading" />
                          </div>
                      )}
                  <label className={s_Select.label} htmlFor="ser-domain">Домен*</label>
                </div>
                {formH.errors?.domain_id
                && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
              </div>
            </div>

            {/* position */}
            <div className={s.top__input}>
              <InputWrapper
                  label="Позиция*"
                  id="ser-position"
                  errorComponent={formH.errors?.position &&
                  <p className="form-input-hint">{formH?.errors?.position?.message}</p>}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH.errors?.position,
                    })}
                    type="text"
                    placeholder="Позиция*"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="position"
                    id="ser-position"
                    defaultValue={data?.fields?.position}
                />
              </InputWrapper>
            </div>

            <div className={s.top__input}>
              <div className={s_Select.select__inner}>
                <div className={s_Select.select__wrapper}>
                  {data
                      ? (
                          <select
                              name="service_block_id"
                              className={cn(s_Select.select, {
                                [s_Select.select_error]: formH.errors?.service_block_id,
                              })}
                              id="ser-service-block-id"
                              ref={formH.register({
                                required: VALIDATION.select.required,
                              })}
                          >
                            {data && !!data.blockServices && data.blockServices.map((blockService, i) => (
                                <option
                                    key={`ser-blockServices-${i}`}
                                    value={blockService.id}
                                >
                                  {blockService.title_ru}
                                </option>
                            ))}
                          </select>
                      )
                      : (
                          <div className="p-relative">
                            <div className="loading" />
                          </div>
                      )}
                  <label className={s_Select.label} htmlFor="ser-service-block-id">Сервис блок id*:</label>
                </div>
                {formH.errors?.service_block_id
                && <p className="form-input-hint">{formH.errors.service_block_id?.message}</p>}
              </div>
            </div>

            {/* service_block_id */}
            <div className={`${s.top__input} ${s.top__file}`}>
              <div>
                <Controller
                    as={InputFile}
                    control={control}
                    className={cn('form-input-file', {
                      'is-error': errors.title_ua,
                    })}
                    name="img"
                    rules={{ required: VALIDATION.req.required }}
                    val={watch('img')}
                    formH={formH}
                    acceptStr="PNG, JPG, GIF"
                    accept="image/*"
                    sizeMB={1}
                    maxWidth={100}
                    maxHeight={100}
                />
                {errors.img && <p className="form-input-hint">{errors.img.message}</p>}
              </div>
              {!watch('img') && (
                <div className={s.top__file_text}>
                  Максимальное разрешение
                  <br/>изображения 100x100 px
                </div>
              )}
            </div>
          </div>
          <div className={s.top__item}>
            {/* is_active */}
            <CheckboxSwitch
                name="is_active"
                formH={formH}
                id="ser-is-active"
                label="Активность"
                defaultChecked={data?.fields?.is_active}
            />
          </div>
        </div>

        <div className={s.content}>
          <BlockUa formH={formH} data={data} />
          <BlockRu formH={formH} data={data} />
        </div>

      </Form>

      {load && <Loader />}
    </>
  );
};

export default ServiceEditView;
