import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import { geDomainName } from "../../lib/const/domains";

const AdditionalServicesList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Additional services list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                          {+rest?.total && (
                              <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.total}
                                />
                              </>
                          )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                  arrayChildren={[
                      { title: 'ID', attr: { style: { width: '38px' } } },
                      'Назва УКР', 'Назва РУС', { title: 'Домен', attr: { style: { width: '125px' } } },
                      { title: 'Статус активності', attr: { style: { width: '130px' } } },
                      { title: 'Додана автоматично', attr: { style: { width: '130px' } } },
                      { title: 'JDE', attr: { style: { width: '100px' } } }
                  ]}
                  filterChildren={<TableListViewFiltersRow
                    urlParams={urlParams}
                    fields={[
                      {},
                      {type: 'text', name: 'title_ua', attr: { style: { width: '100%' } }, attrLabel: { style: { width: '100%' } }},
                      {type: 'text', name: 'title_ru', attr: { style: { width: '100%' } }, attrLabel: { style: { width: '100%' } }},
                      {
                        type: 'select',
                        name: 'domain_id',
                        options: !!filtersData?.domains?.length && filtersData?.domains
                      },
                        {
                            type: 'select',
                            name: 'is_active',
                            options: [
                                {
                                    id: 0,
                                    title: 'Не активна'
                                },
                                {
                                    id: 1,
                                    title: 'Активна'
                                }
                            ]
                        },
                        {},
                      {type: 'text', name: 'jde', attr: { style: { width: '100px' } }},
                      {}
                    ]}
                    onSubmit={pushUrlParams}
                    loading={!filtersData}
                  />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={6}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`add_serv-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Додаткову послугу видалено',
                                    }}
                                    editLink={`/app/additional-service/update/${e.id}`}
                                    permissionRemove='admin.additional-service.destroy'
                                    permissionEdit='admin.additional-service.edit'
                                >
                                    <td>
                                        {checkPermission('admin.additional-service.edit')
                                            ? <Link to={`/app/additional-service/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                          {e?.title_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title_ru || '---'}
                                      </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.domain_id ? geDomainName(e.domain_id)?.name : '---'}
                                        </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e.is_active ? <StatusCircle green text="Активна"/> : <StatusCircle red text="Не активна"/>}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e.is_auto_append ? <StatusCircle green text="Так"/> : <StatusCircle red text="Ні"/>}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.jde || '---'}
                                      </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={6}>Немає додаткових послуг</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default AdditionalServicesList;
