import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import s_Table from "../orderTable.module.scss";
import Button from "../../../../components/ui/Button";
import { getAudit } from "../../../../lib/api/audit";
import getTZtoDate from "../../../../lib/getTZtoDate";
import getNumbersWithSpaces from "../../../../lib/getNumbersWithSpaces";
import errorMessage from "../../../../lib/errorMessage";
import { refreshToken } from "../../../../lib/crud/refreshToken";

const ModalLogOnOrder = ({ active, setActive, title, id }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getAudit({order_id: id})
            .then((res) => setData(res?.data?.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getAudit({order_id: id})
                            .then((res) => setData(res?.data?.data))
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            });
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper}>
                            <table className={`${s_Table.table} ${s_Modal.width_auto}`}>
                                <thead className={s_Table.thead}>
                                    <tr>
                                        <th>
                                            <div>Дата</div>
                                        </th>
                                        <th>
                                            <div>Ивент</div>
                                        </th>
                                        <th>
                                            <div>Тип атрибута</div>
                                        </th>
                                        <th>
                                            <div>Старые значения</div>
                                        </th>
                                        <th>
                                            <div>Новые значения</div>
                                        </th>
                                        <th>
                                            <div>Менеджер</div>
                                        </th>
                                        <th>
                                            <div>Сумма</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={s_Table.tbody}>
                                    {!!data?.length && data.map(item => {
                                        let parseOldValues;
                                        let parseNewValues;

                                        if(item?.old_values) {
                                            parseOldValues = Object.keys(item?.old_values);
                                        }
                                        if(item?.new_values) {
                                            parseNewValues = Object.keys(item?.new_values);
                                        }

                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    <div>{getTZtoDate(item?.created_at, true)}</div>
                                                </td>
                                                <td>
                                                    <div>{item?.event}</div>
                                                </td>
                                                <td>
                                                    <div>{item?.auditable_type}</div>
                                                </td>
                                                <td>
                                                    <div>{parseOldValues && parseOldValues?.map(oldValue => <div>{oldValue}: <b>{item?.old_values[oldValue]}</b></div>)}</div>
                                                </td>
                                                <td>
                                                    <div>{parseNewValues && parseNewValues?.map(newValue => <div>{newValue}: <b>{item?.new_values[newValue]}</b></div>)}</div>
                                                </td>
                                                <td>
                                                    <div>{item?.user?.name}</div>
                                                </td>
                                                <td>
                                                    <div>{item?.order_total ? `${getNumbersWithSpaces(item?.order_total)} грн` : '---'} </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div>
                        <Button border onClick={() => setActive(false)}>Закрыть</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLogOnOrder;