import fetchAx from '../fetchAx';
import {
    DOMAIN_API,
    ORDER_DOCUMENTS_GET,
} from '../../const/api';
// import { LIST_PER_PAGE } from "../../const/view";

export const getOrderDocuments = () => {
    const orderId = location.pathname.split('/')[4];

    return fetchAx({
        url: ORDER_DOCUMENTS_GET.join(orderId),
        method: 'GET',
    });
};

export const getOrderDocumentPdfInfo = (orderId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document`,
    method: 'GET',
});

export const getOrderDocument = (orderId, templateId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}`,
    method: 'GET',
});

export const getOrderDocumentPdf = (orderId, templateId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}/pdf`,
    method: 'GET',
});

export const getOrderDocumentEdit = (orderId, templateId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}/edit`,
    method: 'GET',
});

export const createOrderDocument = (orderId, data) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document`,
    method: 'POST',
    data
});

export const editOrderDocument = (orderId, templateId, data) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}`,
    method: 'PUT',
    data
});

export const removeOrderDocument = (orderId, templateId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}`,
    method: 'DELETE',
});

export const sendOrderDocumentClient = (orderId, documentId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${documentId}/send-by-email`,
    method: 'POST',
});