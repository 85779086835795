import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewPagination from '../../components/common/TableListView/Pagination';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewFooter from '../../components/common/TableListView/Footer';
import TableListViewPerPage from '../../components/common/TableListView/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import TableListViewFiltersRow from '../../components/common/TableListView/FiltersRow';
import { getData, getFiltersData, sendData } from './api';
import { geDomainName } from "../../lib/const/domains";
import getTZtoDate from "../../lib/getTZtoDate";
import { getOrderStatusPaymentName, order_status_payment } from "../../lib/const/order";
import s from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";
import sSelect from "../OrderEditView/fakeData/orderStyle.module.scss";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PurchaseList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    const ref = useRef(null);
    const refSelect = useRef(null);
    const [selected, setSelected] = useState({items: []});
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = async () => {
        if(ref?.current?.checked) {
            setSelectAll(true);
            const selectAll = rest?.data.map(item => ({
                ...item.product,
                order_id: item.order_id,
                product_id: item.product.id,
                id: item.id,
            }));
            setSelected({ items: [...selectAll] });
        } else {
            setSelectAll(false);
            setSelected({ items: [] });
        }
    };

    const handleSelect = (product, order_id, id) => {
        let resultIndex;
        product.order_id = order_id;
        product.product_id = product.id;
        product.id = id;

        selected.items.find((item, index) => {
            if(item.id === product.id) {
                resultIndex = index;
            }
            return item.id === product.id
        });

        if(resultIndex === 0 || resultIndex) {
            setSelected((prev) => {
                const selected = {items: [...prev.items]};
                if (selected.items[resultIndex]) {
                    selected.items.splice(resultIndex, 1);
                }
                return selected;
            });
        } else {
            setSelected((prev) => ({
                items: [...prev.items, product]
            }));
        }
    };

    const handleSelectSubmit = async () => {
        try {
            setLoad(!load);
            await sendData({products: [...selected.items]})
                .then((res) => {
                    if(res.data.purchase_order_ids.length === 0) {
                        alert("Выбирете все товара из заказа");
                        setLoad(!load);
                    }
                });
            setSelected({ items: [] });
            navigate(location.search);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Purchase list`} />
            <TableListViewTable>
                <thead>
                    <tr>
                        <th />
                        <th>ID</th>
                        <th>Номер заказа</th>
                        <th>Дата/время</th>
                        <th>Категория</th>
                        <th>Код JDE</th>
                        <th>Название товара</th>
                        <th>Количество для закупки</th>
                        <th>Скидка, BPM</th>
                        <th>Скидка, %</th>
                        <th>Скидка, абс</th>
                        <th>Цена</th>
                        <th>Дата отгрузки</th>
                        <th>Cтатус оплаты</th>
                        <th>Менеджер</th>
                        <th>Создан в магазине</th>
                        <th>Магазин отгрузки</th>
                        <th>Комментарий</th>
                    </tr>

                    <TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            { type: 'checkbox', name: 'select_all', id: 'table-select_all', attr: {
                                    onClick: () => handleSelectAll(),
                                    defaultChecked: selectAll,
                                    ref
                                }},
                            {},
                            { type: 'text', name: 'order_id' },
                            {},
                            {},
                            { type: 'text', name: 'jde' },
                            { type: 'text', name: 'title' },
                            {},
                            {},
                            {},
                            {},
                            {},
                            { type: 'date', name: 'date_from', secondLvl: { type: 'date', name: 'date_to' } },
                            { type: 'select', name: 'status_payment', options: order_status_payment },
                            { type: 'select', name: 'manager_id', options: filtersData?.managers && filtersData.managers, optProps: ['id', 'name'] },
                            { type: 'select', name: 'domain', options: filtersData?.domains && filtersData.domains },
                            { type: 'text', name: 'warehouse_id', options: filtersData?.wareHouses && filtersData.wareHouses },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />
                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={10} />
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => {
                                return(
                                    <TableListViewRow
                                        id={e.id} key={`purchase-item-${e.id}`}
                                        permissionRemove='admin.purchase.destroy'
                                        permissionEdit='admin.purchase.edit'
                                        controlsOff
                                    >
                                        <td>
                                            <div className={s.checkbox__wrapper}>
                                                <input
                                                    className={s.checkbox}
                                                    type="checkbox"
                                                    id={`table_purchase_${e.id}`}
                                                    name={`purchase_${i}`}
                                                    checked={selected?.items?.find((i) => i.id === e.id)?.id === e.id}
                                                    onChange={() => handleSelect(e.product, e.order_id, e.id)}
                                                />
                                                <label htmlFor={`table_purchase_${e.id}`}
                                                       className={`${s.label} ${s_Table.label}`}/>
                                            </div>
                                        </td>
                                        <td>{e.id}</td>
                                        <td><Link to={`/app/order/update/${e.order_id}`}>{e.order_id}</Link></td>
                                        <td>{getTZtoDate(e.updated_at, true)}</td>
                                        <td>{e.product.categories[0].title}</td>
                                        <td>{e.product.jde}</td>
                                        <td>{e.product.title}</td>
                                        <td>{e.purchase_quantity}</td>
                                        <td>{e.discount_bpm}</td>
                                        <td>{e.discount_percent}</td>
                                        <td>{e.discount_absolute}</td>
                                        <td>{e.price_final}</td>
                                        <td>{e.order.delivery_date ? getTZtoDate(e.order.delivery_date, true) : '---'}</td>
                                        <td>{getOrderStatusPaymentName(e?.order?.status_payment)?.name}</td>
                                        <td>{e?.manager?.name} {e?.manager?.surname}</td>
                                        <td>{geDomainName(e.order.domain_id).name}</td>
                                        <td>{e.order.warehouse_id}</td>
                                        <td>{e.order.comment_additional}</td>
                                    </TableListViewRow>
                                )
                            }
                            )
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={10} />
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
            <div className={s_Table.table_settings}>
                <div className={s_Table.table_settings__block}>
                    <div className={sSelect.select__wrapper}>
                        <select
                            name="selected_type"
                            className={sSelect.select}
                            id="tab-selected_type"
                            ref={refSelect}
                        >
                            <option value="">выберите действие</option>
                            <option value={1}>На закупке</option>
                        </select>
                        <label className={sSelect.label} htmlFor="tab-selected_type">Действие</label>
                    </div>
                </div>
                <div>
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSelectSubmit}
                        disabled={load}
                    >
                        Применить
                    </button>
                </div>
            </div>

            {!load && (
                <TableListViewFooter
                    left={(
                        <>
                            {+rest?.total && (
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    total={+rest?.total}
                                    perPage={+rest?.per_page}
                                    loc={location}
                                />
                            )}
                        </>
                    )}
                    right={(
                        <TableListViewPerPage
                            urlParams={urlParams}
                            onSelect={(val, params) => pushUrlParams(params)}
                        />
                    )}
                    rightCn="col-auto"
                />
            )}
        </div>
    );
};

export default PurchaseList;