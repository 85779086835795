import { getBanks, getBanksAll, removeBank } from '../../lib/api/banks';
import { getDomains } from '../../lib/api/domains';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
  getBanksAll(),
])
  .then((res) => {
    const renameBankArrObjKeys = [];
    if (res) {
      res[1]?.value?.data?.data?.forEach((el) => {
        const obj = {};
        Object.entries(el).forEach((o) => {
          const firstElement = o[0];
          const secondElement = o[1];

          if (firstElement.match('tf_bank_id')) {
            obj.id = secondElement;
            obj.title = secondElement;
          }
        });
        renameBankArrObjKeys.push(obj);
      });

      return {
        domains: res[0]?.value?.data,
        banks: renameBankArrObjKeys,
      };
    }
    return {};
  });

export const getData = getBanks;
export const remove = removeBank;
