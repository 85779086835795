import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const RedirectEditView = () => {
  const { id: redirectID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: redirectID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/redirect/not-found',
      remove: `/app/redirects${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/redirects${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Редирект создан',
      edit: 'Редирект обновлен',
      remove: 'Редирект удален',
    },
  });
/* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Redirect edit`} />
      <div className="box-wrap">
        {redirectID
          ? <Title>Редирект</Title>
          : <Title>Новый редирект</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* url */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="rdt-url">
                С URL*:
              </label>
            </ColLeft>
            <ColRight>
              <input
                name="url"
                className={cn('form-input', {
                  'is-error': errors.url,
                })}
                type="text"
                id="rdt-url"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                defaultValue={data?.fields?.url}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.url && <p className="form-input-hint">{errors.url.message}</p>}
            </ColRight>
          </Group>

          {/* url_to */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="rdt-url-to">
                На URL*:
              </label>
            </ColLeft>
            <ColRight>
              <input
                  name="url_to"
                  className={cn('form-input', {
                    'is-error': errors.url_to,
                  })}
                  type="text"
                  id="rdt-url-to"
                  placeholder="Введите текст"
                  defaultValue={data?.fields?.url_to}
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.url_to && <p className="form-input-hint">{errors.url_to.message}</p>}
            </ColRight>
          </Group>

          {/* status */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="rdt-status">Статус:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="status"
                  className={cn('form-input', {
                    'is-error': errors.status,
                  })}
                  type="number"
                  id="rdt-status"
                  placeholder="Введите текст"
                  defaultValue={data?.fields?.status}
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.status && <p className="form-input-hint">{errors.status.message}</p>}
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default RedirectEditView;
