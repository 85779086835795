import { CITY_GET, CITY_ADMIN_GET } from '../../const/api';
import { LIST_PER_PAGE } from "../../const/view";
import fetchAx from '../fetchAx';
/* eslint-disable */
export const getCities = (params) => fetchAx({
  url: CITY_GET,
  method: 'GET',
  params,
});

export const getCitiesAll = (params) => fetchAx({
  url: CITY_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    ...params,
  },
});

export const getCitiesAdmin = (params) => fetchAx({
  url: CITY_ADMIN_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    ...params,
  },
});
