import {
    SECTION_MENU_GET,
    SECTIONS_GET,
    SECTIONS_CREATE,
    SECTIONS_GET_CREATE,
    SECTIONS_NODE_AFTER_NEIGHBOR_EDIT,
    SECTIONS_NODE_BEFORE_NEIGHBOR_EDIT,
    SECTIONS_PARENT_APPEND_NODE_EDIT,
    SECTION_GET,
    SECTIONS_GET_EDIT,
    SECTIONS_EDIT,
    SECTIONS_DELETE, SECTION_SWITCH_ROLE_EDIT
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSections = () => fetchAx({
    url: SECTIONS_GET,
    method: 'GET'
});

export const createSection = (data) => fetchAx({
    url: SECTIONS_CREATE,
    method: 'POST',
    data,
});

export const getSectionsCreate = (params) => fetchAx({
    url: SECTIONS_GET_CREATE,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params
    }
});

export const editSectionSwitchRole = (sectionId, roleId) => fetchAx({
    url: SECTION_SWITCH_ROLE_EDIT(sectionId, roleId),
    method: 'PUT'
});

export const editSectionsNodeAfterNeighbor = (nodeId, neighbor, data) => fetchAx({
    url: SECTIONS_NODE_AFTER_NEIGHBOR_EDIT(nodeId, neighbor),
    method: 'PUT',
    data,
});

export const editSectionsNodeBeforeNeighbor = (nodeId, neighbor, data) => fetchAx({
    url: SECTIONS_NODE_BEFORE_NEIGHBOR_EDIT(nodeId, neighbor),
    method: 'PUT',
    data,
});

export const editSectionsParentAppendNode = (parent, nodeId, data) => fetchAx({
    url: SECTIONS_PARENT_APPEND_NODE_EDIT(parent, nodeId),
    method: 'PUT',
    data,
});

export const getSection = (id) => fetchAx({
    url: SECTION_GET.join(id),
    method: 'GET'
});

export const editSection = (id, data) => fetchAx({
    url: SECTIONS_EDIT.join(id),
    method: 'PUT',
    data
});

export const removeSection = (id) => fetchAx({
    url: SECTIONS_DELETE.join(id),
    method: 'DELETE'
});

export const getSectionEdit = (id) => fetchAx({
    url: SECTIONS_GET_EDIT.join(id),
    method: 'GET'
});

export const getSectionMenu = () => fetchAx({
    url: SECTION_MENU_GET,
    method: 'GET'
});
