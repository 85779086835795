import React from "react";
import s from "../../review.module.scss";
import Group from "../../../../components/ui/FormEditView/Group";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import getTZtoDate from "../../../../lib/getTZtoDate";
import { Editor } from "@tinymce/tinymce-react";
import { TINY_API_KEY } from "../../../../const/api";

const ReviewModerator = ({replySend, setReplySend, data, checkEdit}) => {
    const initialValue = data?.fields?.data?.reply ? data?.fields?.data?.reply : '';

    return (
        <div className={s.block}>
            <div className={s.block__header}>Ответ модератора</div>
            <div className={s.block__content}>
                <div>
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <Editor
                                apiKey={TINY_API_KEY}
                                initialValue={initialValue}
                                value={replySend}
                                onEditorChange={(newValue) => setReplySend(newValue)}
                                disabled={!checkEdit}
                                init={{
                                    width: '100%',
                                    height: '225px',
                                    menubar: false,
                                    language: 'ru',
                                    toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code | numlist bullist| preview | media customSlider',

                                    plugins: ['link', 'code', 'lists', 'preview'],
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                            />
                        </ColFull>
                    </Group>
                    <div className={s.moderator}>
                        <div className={s.moderator__label}>
                            Модератор: <strong>{data?.fields?.data?.manager ? data?.fields?.data?.manager?.name : '---'}</strong>
                        </div>
                        <div className={s.block__date}>{getTZtoDate(data?.fields?.data?.updated_at, true)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewModerator;