import {
  DOCUMENTS_GET,
  DOCUMENT_CREATE,
  DOCUMENT_DELETE,
  DOCUMENT_GET_EDIT,
  DOCUMENT_EDIT, DOCUMENTS_TYPES_GET, DOCUMENTS_TO_POST, SORT_SPECIAL_OFFERS_REFRESH, DOCUMENTS_REFRESH_POST
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getDocuments = (params) => fetchAx({
  url: DOCUMENTS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    page: 1,
    ...params
  },
});

export const createDocument = (data) => fetchAx({
  url: DOCUMENT_CREATE,
  method: 'POST',
  data,
});

export const getDocumentEdit = (id) => fetchAx({
  url: DOCUMENT_GET_EDIT.join(id),
  method: 'GET',
});

export const editDocument = (id, data) => fetchAx({
  url: DOCUMENT_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeDocument = (id) => fetchAx({
  url: DOCUMENT_DELETE.join(id),
  method: 'DELETE',
});

export const getDocumentsTypes = (params) => fetchAx({
  url: DOCUMENTS_TYPES_GET,
  method: 'GET',
  params: {
    ...params
  }
});

export const editDocumentTo = (id, move) => fetchAx({
  url: DOCUMENTS_TO_POST(id, move),
  method: 'POST',
});

export const refreshDocument = (params) => fetchAx({
  url: DOCUMENTS_REFRESH_POST,
  method: 'POST',
  params
});
