import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";
import {
  createBannerHead,
  editBannerHead,
  getBannerHeadEdit,
  removeBannerHead,
} from "../../lib/api/bannerHead";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (bmhId) => Promise.allSettled([getDomains(), getCatTree(), bmhId
&& getBannerHeadEdit(bmhId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      categories: res[1]?.value?.data,
      fields: bmhId && res[2]?.value?.data,
    })
  });

export const create = createBannerHead;
export const edit = editBannerHead;
export const remove = removeBannerHead;

export const parseDataSend = (data, bmhId) => {
  let d = { ...data };
  const isUpdate = typeof bmhId !== 'undefined';

  if(!!d?.starts_at?.length) {
    d['starts_at'] = `${d?.starts_at} ${d?.time_from}`
  }

  if(!!d?.finish_at?.length) {
    d['finish_at'] = `${d?.finish_at} ${d?.time_to}`
  }

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  delete d.time_from;
  delete d.time_to;

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
