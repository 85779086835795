export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            button.ac-dm-trigger {
                display: flex !important;
            }
            button.ac-dm-trigger > span {
                padding: 13px 0;
            }
            
            .ac-panel > div {
                display: flex;
            }
            
            @media screen and (max-width: 475px){
                button.ac-dm-trigger > span {
                    padding: 5px 0;
                }
            }
            
            p.ac-text {
                text-align: left;
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="accordion-diamond-container">
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Чим Galaxy S23 Ultra відрізняється від Galaxy S22 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Galaxy S23 Ultra оснащено камерою з найвищою серед смартфонів Galaxy роздільною здатністю – 200 МП (у S22 Ultra – 108 МП). З таким самим акумулятором на 5 000 мА·год (типове значення), що й у S22 Ultra, Galaxy S23 Ultra забезпечує відтворення відео протягом приблизно 26 годин і вищу продуктивність завдяки покращеній обчислювальній потужності.</p>
        </div>
    </div>
  </div>

  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>У яких кольорах випускаються смартфони Galaxy S23 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Смартфони Galaxy S23 Ultra доступні в лавандовому, кремовому, зеленому і фантом чорному кольорах.4 Серед розмаїття відтінків точно знайдеться той, який вам сподобається.</p>
        </div>
    </div>
  </div>

  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Які переваги мають камери Galaxy S23 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
          <p>Galaxy S23 Ultra оснащено селфі-камерою на 12 МП, ультраширококутною камерою на 12 МП, ширококутною камерою на 200 МП, телефотокамерою на 10 МП із 10-кратним оптичним зумом і телефотокамерою на 10 МП з 3х оптичним зумом. Штучний інтелект забезпечує чітку деталізацію й реалістичні кольори на селфі, які було знято в умовах слабкого освітлення. Карта глибини прискорює фокусування для селфі. Аналіз дальності за допомогою штучного інтелекту покращує чіткість нічних портретів.</p>
        </div>
    </div>
  </div>
  
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Як здійснювати астрофотозйомку за допомогою додатка Expert RAW?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Galaxy S23 Ultra дає змогу робити вражаючі астрофотознімки без спеціального обладнання. Просто натисніть значок сузір’я в правому верхньому куті додатка Expert RAW, наведіть камеру на нічне небо і знімайте з витримкою до 10 хвилин. Потім штучний інтелект покращить чіткість зображення місяця й зірок.</p>
        </div>
    </div>
  </div>
  
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Чи підходить Galaxy S23 Ultra для ігор?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Смартфон Galaxy S23 Ultra підтримує частоту оновлення екрана 120 Гц, має акумулятор на 5 000 мА·год (типове значення) і побудований на основі мобільної платформи Snapdragon® 8 Gen 2 для Galaxy, яка мінімізує час завантаження й використання енергії.</p>
        </div>
    </div>
  </div>
  
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Який розмір екрана Galaxy S23 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Galaxy S23 Ultra має гігантський дисплей із діагоналлю 6,8 дюйма, на якому дуже зручно грати в ігри, редагувати фотографії, робити нотатки тощо.</p>
        </div>
    </div>
  </div>
  
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Чи постачається перо S Pen разом із Galaxy S23 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Galaxy S23 Ultra постачається з вбудованим пером S Pen, яке тепер підтримує ще більше додатків.</p>
        </div>
    </div>
  </div>
  
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger ac-dm-trigger"><span>Скільки пам’яті в Galaxy S23 Ultra?</span></button>
    </h6>
    <div class="ac-panel">
        <div>
            <p>Galaxy S23 Ultra можна придбати з таким об’ємом пам’яті: 256 ГБ, 512 ГБ або 1 ТБ.</p>
        </div>
    </div>
  </div>
</div>
${style}
        `,
    })
};