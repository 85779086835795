import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cn from "classnames";
import s from "../SpecOfferEdit.module.scss";
import parseCategories from "../../../lib/helpers/parseCategories";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../assets/icons/icon_search.svg";
import InputWrapper from "../../../components/ui/InputWrapper";
import IconDelete from "../../../components/icon/IconDelete";
import {getProduct} from "../../../lib/api/products";
import errorMessage from "../../../lib/errorMessage";
import icon_close_red from "../../../assets/icons/icon_close_red.svg";
import ProductBlock from "./block/ProductBlock";

const TypeSix = ({
                     activeTab,
                     setActiveTab,
                     tabList,
                     domainWatch,
                     data,
                     formH
}) => {
    return (
        <div style={{ width: "100%" }}>
            <ul className={s.tab}>
                {!!tabList.length && tabList.map((el, index) => {
                    return (
                        <li className={cn(s.tab__item, {
                            ['active']: el.id === activeTab,
                            [s.tab__line]: el.id === activeTab,
                        })} key={index}>
                            <div onClick={() => setActiveTab(el.id)}>{el.title}</div>
                        </li>
                    )
                })}
            </ul>
            <div className={s.tabs__wrapper}>

                {/* TAB A */}
                <ProductBlock
                    name="special_offer_type6_products"
                    sendName="special_offer_type6_product"
                    title="Товар А"
                    domainWatch={domainWatch}
                    data={data}
                    formH={formH}
                    activeTab={activeTab}
                    showActive={0}
                />

                {/* TAB B */}
                <ProductBlock
                    name="special_offer_type6_bonus_products1"
                    sendName="special_offer_type6_bonus_product1"
                    title="Товар Б"
                    domainWatch={domainWatch}
                    data={data}
                    formH={formH}
                    activeTab={activeTab}
                    showActive={1}
                    priceCart
                    priceOrder
                />

                {/* TAB C */}
                <ProductBlock
                    name="special_offer_type6_bonus_products2"
                    sendName="special_offer_type6_bonus_product2"
                    title="Товар В"
                    domainWatch={domainWatch}
                    data={data}
                    formH={formH}
                    activeTab={activeTab}
                    showActive={2}
                    priceCart
                    priceOrder
                />
            </div>
        </div>
    );
};

export default TypeSix;