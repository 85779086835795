import {
  IN_CARTS_GET,
  IN_CARTS_GET_EDIT,
  IN_CARTS_EDIT,
  IN_CARTS_ABANDON_GET_EDIT,
  CANCEL_VARIANTS_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getInCarts = (params) => fetchAx({
  url: IN_CARTS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getInCartEdit = (id) => fetchAx({
  url: IN_CARTS_GET_EDIT.join(id),
  method: 'GET',
});

export const editInCart = (id, data) => fetchAx({
  url: IN_CARTS_EDIT.join(id),
  method: 'PUT',
  data,
});

export const editInCartAbandon = (id) => fetchAx({
  url: IN_CARTS_ABANDON_GET_EDIT.join(id),
  method: 'POST',
});