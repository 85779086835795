export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.custom-col-characteristic {
                align-items: normal !important;
            }
            
            div.custom-col-characteristic-item {
                border: 1px solid #e5e5e5;
                border-top: 0;
                min-height: 137px;
                padding: 34px 24px;
            }
            
            div.custom-col-characteristic-item:first-child {
                border-left: 0;
            }
            div.custom-col-characteristic-item:last-child {
                border-right: 0;
            }
            
            div.custom-col-characteristic-item > h3.h3-native {
                padding: 0 !important;
            }
        
            div.desc_text_custom > span {
                font-size: 18px;
                line-height: 24px;
            }
            
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                 h3.h3-native {
                    font-size: 24px !important;
                }
                
                div.desc_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row custom-col-characteristic">
                <div class="col d-flex flex-column custom-col-characteristic-item">
                    <h3 class="h3-native d-flex mb-1"><span>10 МП</span></h3>
                    <div class="desc_text_custom d-flex"><span>Телефотокамера з 3-кратним оптичним зумом</span></div>
                </div>
                <div class="col d-flex flex-column custom-col-characteristic-item">
                    <h3 class="h3-native d-flex mb-1"><span>50 МП</span></h3>
                    <div class="desc_text_custom d-flex"><span>Ширококутна камера</span></div>
                </div>
                <div class="col d-flex flex-column custom-col-characteristic-item">
                    <h3 class="h3-native d-flex mb-1"><span>12 МП</span></h3>
                    <div class="desc_text_custom d-flex"><span>Ультраширококутна камера</span></div>
                </div>
                <div class="col d-flex flex-column custom-col-characteristic-item">
                    <h3 class="h3-native d-flex mb-1"><span>10 МП</span></h3>
                    <div class="desc_text_custom d-flex"><span>Камера на кришці</span></div>
                </div>
                <div class="col d-flex flex-column custom-col-characteristic-item">
                    <h3 class="h3-native d-flex mb-1"><span>4 МП</span></h3>
                    <div class="desc_text_custom d-flex"><span>Камера, вмонтована під дисплей</span></div>
                </div>
            </div>
            ${style}
        `,
    })
};