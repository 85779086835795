import cn from 'classnames';
import React from 'react';

import s from './Title.module.scss';

const Title = ({
  children, single = false, Tag = 'h2', className,
}) => (
  <Tag className={cn(
    s.title,
    className,
    { [s.titleSingle]: single },
  )}
  >
    {children}
  </Tag>
);

export default Title;
