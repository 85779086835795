import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {uid} from "react-uid";
import RSelect from "../../../components/ui/RSelect";
import {handleGet} from "../../../components/ui/SelectInputSearch/loadOptions";
import {
    getDeliveriesSettlements,
    getDeliveryOffices,
    getDeliveryStreets,
    getDeliveryTerminals,
    getDeliveryTimeIntervals,
    getDeliveryTypes
} from "../../../lib/api/delivery";
import Constants from "../../../store/orderEdit/constants";
import cn from "classnames";
import Form from "../../../components/ui/FormEditView/Form";
import Loader from "../../../components/ui/Loader";
import Group from "../../../components/ui/FormEditView/Group";
import {VALIDATION} from "../../../const/text";
import Item from "../Item";
import s from "./orderStyle.module.scss";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_FormSelect from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Select from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import SelectInputSearch from "../../../components/ui/SelectInputSearch";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import ReactSelectSearch from "../../../components/ui/ReactSelectSearch";
import getTZtoDate from "../../../lib/getTZtoDate";
import InputMask from "react-input-mask";
import errorMessage from "../../../lib/errorMessage";

const OrderDelivery = ({
                           formProps,
                           ttnNumber,
                           setTtnNumber,
                           statusDisableAll,
                           requiredFields,
                           adminCanEdit,
                           setFieldsReqEmail,
                           fieldsReqEmail,
                           data,
                           orderStatus,
                           oldOrderStatus
                       }) => {
    const dispatch = useDispatch();
    const orderEdit = useSelector(state => state.orderEdit);

    const [disableBlockAll, setDisableBlockAll] = useState(false);

    const onChange = (key, value) => dispatch({type: Constants.ON_CHANGE, payload: {[key]: value}});
    const onChangeObj = (key, value, name, id) => dispatch({
        type: Constants.ON_CHANGE_OBJ,
        payload: {key, value, name, id}
    });
    const onChangeDeliverObj = (key, value, name, deliveryId, childId) => dispatch({
        type: Constants.ON_CHANGE_DELIVERY_OBJ,
        payload: {key, value, name, deliveryId, childId}
    });

    const dataFields = formProps.data && formProps.data.fields;
    const form = formProps.formH;
    const delivery_type = form.watch('delivery_type');
    const type_payment = form.watch('type_payment');

    const [deliveryTypeData, setDeliveryTypeData] = useState([]);
    const [paymentTypeData, setPaymentTypeData] = useState([]);
    const [timeData, setTimeData] = useState([]);
    const [settlementId, setSettlementId] = useState(null);
    const [deliveryTypeName, setDeliveryTypeName] = useState(null);
    const [dateMinDisable, setDateMinDisable] = useState(new Date());
    const [anotherRecipient, setAnotherRecipient] = useState(false);
    const [showAllFields, setShowAllFields] = useState(false);

    /* offices */
    const [officesData, setOfficesData] = useState([]);
    const [officesId, setOfficesId] = useState(null);

    /* terminals */
    const [terminalsData, setTerminalsData] = useState([]);
    const [terminalId, setTerminalId] = useState(null);

    const dateWatch = form.watch('delivery.estimated_delivery_date');
    const patronymicWatch = form.watch('delivery.recipient.patronymic');

    const handlePhone = (e) => {
        onChangeDeliverObj('id', null, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
        onChangeDeliverObj('phone', e.target.value, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
    };

    const handleTimeInterval = (e) => {
        onChangeDeliverObj('time_interval', e.target.value, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id);
        onChangeDeliverObj('time_interval_id', null, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id);
    };

    useEffect(() => {
        if (dataFields?.delivery?.ttn_number) {
            setTtnNumber(dataFields?.delivery?.ttn_number);
        }

        /* offices */
        if (dataFields?.delivery?.office?.delivery_office_id !== null) {
            setOfficesId(dataFields?.delivery?.office?.delivery_office_id)
        }

        /* terminals */
        if (dataFields?.delivery?.terminal?.delivery_terminal_id !== null) {
            setTerminalId(dataFields?.delivery?.terminal?.delivery_terminal_id)
        }
        if (dataFields?.delivery?.recipient?.id) {
            setAnotherRecipient(true);
        }
    }, [dataFields]);

    useEffect(() => {
        const parseDate = getTZtoDate(dateMinDisable);

        const parseDateMin = (date) => {
            let getDay = Number(date.split('.')[0]);
            let getMouth = Number(date.split('.')[1]);
            const getYear = date.split('.')[2];
            if (getMouth < 10) {
                getMouth = '0' + getMouth.toString();
            }
            if (getDay < 10) {
                getDay = '0' + getDay.toString();
            }
            return `${getYear}-${getMouth}-${getDay}`;
        };

        setDateMinDisable(parseDateMin(parseDate))
    }, []);

    useEffect(() => {
        if (settlementId) {
            getDeliveryTypes({
                settlement_id: settlementId,
                order_id: dataFields.id,
            })
                .then((res) => setDeliveryTypeData(res.data))
                .catch(async (err) => await errorMessage(err, 'api/admin/order/delivery/delivery-types'));
        }
    }, [settlementId]);

    useEffect(() => {
        const getListOffices = async () => {
            await getDeliveryOffices({
                settlement_id: settlementId,
                delivery_type_id: delivery_type
            })
                .then((res) => setOfficesData(res.data))
                .catch(async (err) => await errorMessage(err, 'api/admin/order/delivery/offices'));

            await getDeliveryTerminals({
                settlement_id: settlementId,
                delivery_type_id: delivery_type
            })
                .then((res) => setTerminalsData(res.data))
                .catch(async (err) => await errorMessage(err, 'api/admin/order/delivery/offices'));
        };

        if (settlementId && delivery_type) {
            getListOffices();
        }
    }, [settlementId, delivery_type]);

    useEffect(() => {
        if (typeof delivery_type !== 'undefined') {
            setFieldsReqEmail(prev => ({
                ...prev,
                delivery_type: delivery_type
            }))
        }
    }, [delivery_type]);

    useEffect(() => {
        if (settlementId && dateWatch && delivery_type) {
            getDeliveryTimeIntervals({
                delivery_type: delivery_type,
                settlement_id: settlementId,
                delivery_date: dateWatch,
            })
                .then((res) => setTimeData(res.data))
                .catch(async (err) => await errorMessage(err, '/api/admin/order/delivery/time-intervals'));
        }
    }, [settlementId, dateWatch, delivery_type]);

    useEffect(() => {
        if (settlementId && !!deliveryTypeData?.length) {
            const result = deliveryTypeData.find((item) => item.id === (+delivery_type || dataFields?.delivery_type || 1));

            if (typeof result !== 'undefined') {
                setPaymentTypeData(result.payments);
                setDeliveryTypeName(result.type);
            } else {
                setPaymentTypeData(deliveryTypeData[0].payments);
            }
        }
    }, [delivery_type, dataFields?.delivery_type, deliveryTypeData]);

    const handleChange = (e) => setTtnNumber(e.target.value);

    useEffect(() => {
        if (!!orderEdit?.promoCode && (oldOrderStatus === 3 && orderStatus !== 1 && orderStatus !== 17) && oldOrderStatus !== orderStatus && anotherRecipient && patronymicWatch?.trim().length < 3) {
            form.setError('delivery.recipient.patronymic', {type: 'text', message: "Поле должно содержать не менее 3 символов"});
        } else if ((fieldsReqEmail?.delivery_type !== '6' && fieldsReqEmail?.delivery_type !== '7') && anotherRecipient && (oldOrderStatus === 3 && orderStatus !== 1  && orderStatus !== 17) && oldOrderStatus !== orderStatus && patronymicWatch?.trim().length < 3) {
            form.setError('delivery.recipient.patronymic', {type: 'text', message: "Поле должно содержать не менее 3 символов"});
        } else if (orderStatus === 4 && !!orderEdit?.promoCode && anotherRecipient && patronymicWatch?.trim().length < 3) {
            form.setError('delivery.recipient.patronymic', {type: 'text', message: "Поле должно содержать не менее 3 символов"});
        } else if ((fieldsReqEmail.delivery_type !== '6' && fieldsReqEmail.delivery_type !== '7')  && anotherRecipient && orderStatus === 4 && patronymicWatch?.trim().length < 3) {
            form.setError('delivery.recipient.patronymic', {type: 'text', message: "Поле должно содержать не менее 3 символов"})
        } else {
            form.clearErrors('delivery.recipient.patronymic');
        }

        if (requiredFields) {
            if (settlementId) {
                form.clearErrors('delivery.settlement_id');
            } else {
                form.setError('delivery.settlement_id', {type: 'text', message: "Поле не должно быть пустым"});
            }

            if (dateWatch) {
                form.clearErrors('delivery.estimated_delivery_date');
            } else if (dataFields?.delivery?.estimated_delivery_date && (dateWatch === undefined)) {
                form.clearErrors('delivery.estimated_delivery_date');
            } else {
                form.setError('delivery.estimated_delivery_date', {
                    type: 'text',
                    message: "Поле не должно быть пустым"
                });
            }
        } else {
            form.clearErrors('delivery.settlement_id');
            form.clearErrors('delivery.estimated_delivery_date');
        }

    }, [requiredFields, settlementId, dateWatch, patronymicWatch, orderEdit, orderEdit?.promoCode, orderStatus, anotherRecipient, oldOrderStatus]);

    useEffect(() => {
        if (requiredFields && (deliveryTypeName === "DELIVERY_TYPE_OFFICE")) {
            form.setError('delivery.office.delivery_office_id', {type: 'text', message: 'Поле не должно быть пустым'});

            if (!!officesId?.toString()?.length) {
                form.clearErrors('delivery.office.delivery_office_id');
            }

        } else {
            form.clearErrors('delivery.office.delivery_office_id');
        }

        if (requiredFields && (deliveryTypeName === "DELIVERY_TYPE_TERMINAL")) {
            form.setError('delivery.terminal.delivery_terminal_id', {
                type: 'text',
                message: 'Поле не должно быть пустым'
            });

            if (!!terminalId?.toString()?.length) {
                form.clearErrors('delivery.terminal.delivery_terminal_id');
            }

        } else {
            form.clearErrors('delivery.terminal.delivery_terminal_id');
        }
    }, [requiredFields, officesId, terminalId, deliveryTypeName]);

    useEffect(() => {
        if (settlementId) {
            form.setError('delivery_type', {type: 'text', message: 'Поле не должно быть пустым'});
            form.setError('type_payment', {type: 'text', message: 'Поле не должно быть пустым'});
            if (!!delivery_type?.length) {
                form.clearErrors('delivery_type');
            }
            if (!!type_payment?.length) {
                form.clearErrors('type_payment');
            }
        } else {
            form.clearErrors('delivery_type');
            form.clearErrors('type_payment');
        }

        if (settlementId && delivery_type && type_payment) {
            setShowAllFields(true);
        } else {
            setShowAllFields(false);
        }
    }, [settlementId, delivery_type, type_payment]);

    useEffect(() => {
        if (settlementId && !!dataFields?.delivery_type && !dataFields?.delivery?.id) {
            onChange('delivery_type', dataFields?.delivery_type);
        }
        if (settlementId && !!dataFields?.type_payment && !dataFields?.delivery?.id) {
            onChange('type_payment', dataFields?.type_payment);
        }
    }, [settlementId]);

    useEffect(() => {
        handleGet(getDeliveriesSettlements, '', 1, 'title', true)
    }, []);

    useEffect(() => {
        if (data?.fields?.status === 33) {
            setDisableBlockAll(false);
        } else {
            setDisableBlockAll(statusDisableAll);
        }
    }, [statusDisableAll, data]);

    /*BPMDISCOUNT*/

    useEffect(() => {
        dispatch({
            type: Constants.ON_CHANGE,
            payload: {delivery_type: delivery_type}
        });
        dispatch({
            type: Constants.ON_CHANGE,
            payload: {type_payment: type_payment}
        });
    }, [type_payment, delivery_type])

    useEffect(() => {
        if (orderEdit?.isBPMDiscount?.status && type_payment === '1' && delivery_type === '6') {
            const err = {response: {status: "customBPNDiscount"}}
            errorMessage(err, 'Замовлення БОПИС, приберіть списання бонусів з товарів щоб продовжити!')
        }
    }, [orderEdit?.isBPMDiscount, type_payment, delivery_type])
    /* eslint-disable */
    return (
        <Item title="Информация о доставке" statusDisableAll={disableBlockAll}>
            <form>
                <button type="submit" className="d-hide"/>
                <Form Tag="div">

                    <input
                        type="hidden"
                        name="delivery.settlement_id"
                        value={dataFields?.delivery?.settlement_id}
                        ref={form.register}
                    />

                    <input
                        type="hidden"
                        name="delivery_type"
                        value={dataFields?.delivery_type}
                        ref={form.register}
                    />

                    {/* delivery.settlement_id */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s.select__inner}>
                                <SelectInputSearch
                                    api={getDeliveriesSettlements}
                                    formProps={formProps}
                                    formPropsName={formProps?.data?.fields?.delivery?.settlement_id}
                                    formH={form}
                                    params={{
                                        'title': formProps?.data?.fields?.delivery?.settlement?.title
                                    }}
                                    labelTitle='title'
                                    setValue={setSettlementId}
                                    id="to-settlement-id"
                                    name="delivery.settlement_id"
                                    placeholder="Город"
                                    onChangeObj={onChangeObj}
                                    searchName='title'
                                    clearValue={false}
                                />
                                <label className={`${s.label} ${s.label_react_select}`}
                                       htmlFor="or-warehouse_id">Город</label>
                                {form.errors?.delivery?.settlement_id &&
                                    <p className="form-input-hint">{form.errors?.delivery?.settlement_id?.message}</p>}
                            </div>
                        </ColFull>
                    </Group>
                    {!!settlementId?.toString()?.length && (
                        <>
                            {/* delivery_type */}
                            {!!deliveryTypeData.length && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s.select__inner}>
                                            <div className={s.select__wrapper}>
                                                <select
                                                    name="delivery_type"
                                                    className={cn(s.select, {
                                                        [s.select_error]: form.errors?.delivery_type,
                                                    })}
                                                    id="to_delivery_type"
                                                    ref={requiredFields ? form.register({
                                                        required: VALIDATION.select.required,
                                                    }) : form.register}
                                                    onChange={(e) => onChange('delivery_type', e.target.value)}
                                                >
                                                    <option value="">выберите тип доставки</option>
                                                    {deliveryTypeData.map((type) => (
                                                        <option
                                                            key={type.id}
                                                            value={type.id}
                                                            selected={type.id === dataFields?.delivery_type}
                                                        >
                                                            {type.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={s.label} htmlFor="to_delivery_type">Служба
                                                    доставки*</label>
                                            </div>
                                            {form.errors?.delivery_type
                                                &&
                                                <p className="form-input-hint">{form.errors.delivery_type?.message}</p>}
                                        </div>
                                    </ColFull>
                                </Group>
                            )}

                            {/* type_payment */}
                            {!!paymentTypeData.length && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s.select__inner}>
                                            <div className={s.select__wrapper}>
                                                <select
                                                    name="type_payment"
                                                    className={cn(s.select, {
                                                        [s.select_error]: form.errors?.type_payment,
                                                    })}
                                                    id="to_type_payment"
                                                    ref={requiredFields ? form.register({
                                                        required: VALIDATION.select.required,
                                                    }) : form.register}
                                                    onChange={(e) => onChange('type_payment', e.target.value)}
                                                >
                                                    <option value="">выберите способ</option>
                                                    {paymentTypeData.map((type) => (
                                                        <option
                                                            key={uid(type)}
                                                            value={type.id}
                                                            selected={type.id === dataFields?.type_payment}
                                                        >
                                                            {type.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={s.label} htmlFor="to_type_payment">Вид оплаты*</label>
                                            </div>
                                            {form.errors?.type_payment
                                                &&
                                                <p className="form-input-hint">{form.errors.type_payment?.message}</p>}
                                        </div>
                                    </ColFull>
                                </Group>
                            )}
                        </>
                    )}

                    {showAllFields ? (
                        <>
                            {((deliveryTypeName === "DELIVERY_TYPE_ADDRESS") && !!delivery_type?.length) && (
                                <>
                                    {/* delivery.address.street */}
                                    <Group attrs={{className: 'mt-1 mb-1'}}>
                                        <ColFull>
                                            <div className={s.select__inner}>
                                                <ReactSelectSearch
                                                    name="delivery.address.street"
                                                    id="to-street"
                                                    placeholder="Улица"
                                                    api={getDeliveryStreets}
                                                    formProps={formProps}
                                                    formH={form}
                                                    minLength={2}
                                                    onChangeDeliverObj={onChangeDeliverObj}
                                                    params={{
                                                        settlement_id: settlementId,
                                                    }}
                                                    errorEndpointMessage="/api/admin/order/delivery/streets GET"
                                                    setLoad={formProps?.setLoad}
                                                />
                                                <label className={`${s.label} ${s.label_react_select}`}
                                                       htmlFor="to-street">Улица</label>
                                            </div>
                                        </ColFull>
                                    </Group>

                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>

                                        {/* delivery.address.house_number */}
                                        <ColFull attrs={{className: s.col_full}}>
                                            <InputWrapper
                                                label="Номер дома*"
                                                id="to-house-number"
                                                errorComponent={form.errors?.delivery?.address?.house_number
                                                    &&
                                                    <p className="form-input-hint">{form.errors?.delivery?.address?.house_number.message}</p>}
                                            >
                                                <input
                                                    className={cn(s_Input.input, {
                                                        [s_Input.input_error]: form.errors?.delivery?.address?.house_number,
                                                    })}
                                                    name="delivery.address.house_number"
                                                    type="text"
                                                    id="to-house-number"
                                                    placeholder="Номер дома*"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register({
                                                        required: VALIDATION.req.required
                                                    })}
                                                    defaultValue={dataFields?.delivery?.address?.house_number}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => onChangeDeliverObj('house_number', e.target.value, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id)}
                                                />
                                            </InputWrapper>

                                            {/* delivery.address.apartment_number */}
                                            <InputWrapper
                                                label="Номер квартиры"
                                                id="to-apartment-number"
                                                errorComponent={form.errors?.delivery?.address?.apartment_number
                                                    && <p
                                                        className="form-input-hint">{formH.errors?.delivery?.address?.apartment_number?.message}</p>}
                                            >
                                                <input
                                                    className={s_Input.input}
                                                    name="delivery.address.apartment_number"
                                                    type="number"
                                                    min={0}
                                                    id="to-apartment_number"
                                                    placeholder="Номер квартиры"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register}
                                                    defaultValue={dataFields?.delivery?.address?.apartment_number}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => onChangeDeliverObj('apartment_number', e.target.value, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id)}
                                                />
                                            </InputWrapper>
                                        </ColFull>
                                    </Group>

                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>
                                        <ColFull attrs={{className: s.col_full}}>
                                            {/* delivery.address.floor */}
                                            <InputWrapper label="Этаж" id="to-floor">
                                                <input
                                                    className={s_Input.input}
                                                    name="delivery.address.floor"
                                                    type="number"
                                                    min={0}
                                                    id="to-floor"
                                                    placeholder="Этаж"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register}
                                                    defaultValue={dataFields?.delivery?.address?.floor}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => onChangeDeliverObj('floor', e.target.value, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id)}
                                                />
                                            </InputWrapper>

                                            {/* delivery.address.has_elevator */}
                                            <div className={s_Select.checkbox__wrapper}>
                                                <input
                                                    className={s_Select.checkbox}
                                                    type="checkbox"
                                                    id="to-has-elevator"
                                                    name="delivery.address.has_elevator"
                                                    ref={form.register}
                                                    defaultChecked={dataFields?.delivery?.address?.has_elevator}
                                                    onChange={(e) => onChangeDeliverObj('has_elevator', e.target.checked, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id)}
                                                />
                                                <label htmlFor="to-has-elevator" className={s_Select.label}>Лифт</label>
                                            </div>
                                        </ColFull>
                                    </Group>
                                </>
                            )}

                            {deliveryTypeName === "DELIVERY_TYPE_OFFICE" && (
                                <>
                                    {/* delivery.office.delivery_office_id */}
                                    {!!officesData.length && (
                                        <Group attrs={{className: 'mt-1 mb-1'}}>
                                            <ColFull>
                                                <div className={s.select__inner}>
                                                    <div className={cn('', {
                                                        "react_select__wrapper_error": form.errors?.delivery?.office?.delivery_office_id
                                                    })}>
                                                        <RSelect
                                                            props={{
                                                                options: officesData,
                                                                getOptionLabel: (e) => ((delivery_type === '5') || (dataFields?.delivery_type?.toString() === '5')) ? `${e?.title} ${e?.short_address}` : e?.title,
                                                                getOptionValue: (e) => e.id,
                                                                classNamePrefix: 'react-select',
                                                                placeholder: 'Точка выдачи*',
                                                                defaultValue: officesData?.find(item => item.id === dataFields?.delivery?.office?.delivery_office_id),
                                                                onChange: (e) => {
                                                                    onChangeDeliverObj('delivery_office_id', e?.id ? e?.id : '', 'office', dataFields?.delivery?.id, dataFields?.delivery?.office?.id);
                                                                    return setOfficesId(e?.id ? e?.id : '');
                                                                },
                                                            }}
                                                        />
                                                        {form.errors?.delivery?.office?.delivery_office_id &&
                                                            <p
                                                                className="form-input-hint">{form.errors?.delivery?.office?.delivery_office_id?.message}</p>}
                                                        <label className={`${s.label} ${s.label_react_select}`}
                                                               htmlFor="to-office-id">Точка
                                                            выдачи*</label>
                                                    </div>

                                                    {requiredFields && (
                                                        <input type="hidden" ref={form.register({
                                                            required: VALIDATION.req.required,
                                                        })} name="delivery.office.delivery_office_id"
                                                               value={officesId}/>
                                                    )}
                                                    {!requiredFields &&
                                                        <input type="hidden" ref={form.register}
                                                               name="delivery.office.delivery_office_id"
                                                               value={officesId}/>}
                                                </div>
                                            </ColFull>
                                        </Group>
                                    )}
                                </>
                            )}

                            {deliveryTypeName === "DELIVERY_TYPE_TERMINAL" && (
                                <>
                                    {/* delivery.terminal.delivery_terminal_id */}
                                    {!!terminalsData?.length && (
                                        <Group attrs={{className: 'mt-1 mb-1'}}>
                                            <ColFull>
                                                <div className={s.select__inner}>
                                                    <div className={cn('', {
                                                        "react_select__wrapper_error": form.errors?.delivery?.terminal?.delivery_terminal_id
                                                    })}>
                                                        <RSelect
                                                            props={{
                                                                options: terminalsData,
                                                                getOptionLabel: (e) => ((delivery_type === '5') || (dataFields?.delivery_type?.toString() === '5')) ? `${e?.title} ${e?.short_address}` : e?.title,
                                                                getOptionValue: (e) => e.id,
                                                                classNamePrefix: 'react-select',
                                                                placeholder: 'Точка выдачи*',
                                                                defaultValue: terminalsData?.find(item => item.id === dataFields?.delivery?.terminal?.delivery_terminal_id),
                                                                onChange: (e) => {
                                                                    onChangeDeliverObj('delivery_terminal_id', e?.id ? e?.id : '', 'terminal', dataFields?.delivery?.id, dataFields?.delivery?.terminal?.id);
                                                                    return setTerminalId(e?.id ? e?.id : '');
                                                                },
                                                            }}
                                                        />
                                                        {form.errors?.delivery?.terminal?.delivery_terminal_id &&
                                                            <p
                                                                className="form-input-hint">{form.errors?.delivery?.terminal?.delivery_terminal_id?.message}</p>}
                                                        <label className={`${s.label} ${s.label_react_select}`}
                                                               htmlFor="to-terminal-id">Точка
                                                            выдачи*</label>
                                                    </div>

                                                    {requiredFields && (
                                                        <input type="hidden" ref={form.register({
                                                            required: VALIDATION.req.required,
                                                        })} name="delivery.terminal.delivery_terminal_id"
                                                               value={terminalId}/>
                                                    )}
                                                    {!requiredFields &&
                                                        <input type="hidden" ref={form.register}
                                                               name="delivery.terminal.delivery_terminal_id"
                                                               value={terminalId}/>}
                                                </div>
                                            </ColFull>
                                        </Group>
                                    )}
                                </>
                            )}

                            {/* notify_client */}
                            {deliveryTypeName === "DELIVERY_TYPE_PICKUP" && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s_FormSelect.checkbox__wrapper}>
                                            <input
                                                className={s_FormSelect.checkbox}
                                                type="checkbox"
                                                id="to-notify-client"
                                                name="notify_client"
                                                ref={form.register}
                                                onChange={(e) => onChange('notify_client', e.target.checked)}
                                            />
                                            <label htmlFor="to-notify-client" className={s_FormSelect.label}>Сообщить
                                                клиенту про доставку в
                                                магазин</label>
                                        </div>
                                    </ColFull>
                                </Group>
                            )}

                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull attrs={{className: s.col_full}}>
                                    <div className={s_Select.checkbox__wrapper}>
                                        <input
                                            className={s_Select.checkbox}
                                            type="checkbox"
                                            id="to-another-recipient"
                                            name="another_recipient"
                                            checked={anotherRecipient}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    onChangeDeliverObj('id', null, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    onChangeDeliverObj('surname', dataFields?.delivery?.recipient?.surname, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    onChangeDeliverObj('name', dataFields?.delivery?.recipient?.name, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    onChangeDeliverObj('phone', dataFields?.delivery?.recipient?.phone, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    onChangeDeliverObj('recipient', dataFields?.delivery?.recipient?.patronymic, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                } else {
                                                    onChangeObj('recipient', null, 'delivery', dataFields?.delivery?.id);
                                                }
                                                setAnotherRecipient(e.target.checked);
                                            }}
                                        />
                                        <label htmlFor="to-another-recipient" className={s_Select.label}>Другой
                                            получатель</label>
                                    </div>
                                </ColFull>
                            </Group>

                            {anotherRecipient && (
                                <>
                                    {/* delivery.recipient.surname */}
                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>
                                        <ColFull>
                                            <InputWrapper
                                                label="Фамилия*"
                                                id="to-surname"
                                                errorComponent={form.errors?.delivery?.recipient?.surname &&
                                                    <p className="form-input-hint">{form.errors.delivery?.recipient?.surname?.message}</p>}
                                            >
                                                <input
                                                    className={cn(s_Input.input, {
                                                        [s_Input.input_error]: form.errors?.delivery?.recipient?.surname,
                                                    })}
                                                    type="text"
                                                    placeholder="Фамилия*"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register({
                                                        required: VALIDATION.req.required
                                                    })}
                                                    name="delivery.recipient.surname"
                                                    id="to-surname"
                                                    defaultValue={dataFields?.delivery?.recipient?.surname}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => {
                                                        onChangeDeliverObj('id', null, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                        onChangeDeliverObj('surname', e.target.value, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    }}
                                                />
                                            </InputWrapper>
                                        </ColFull>
                                    </Group>

                                    {/* delivery.recipient.name */}
                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>
                                        <ColFull>
                                            <InputWrapper
                                                label="Имя*"
                                                id="to-name"
                                                errorComponent={form.errors?.another_recipient?.name &&
                                                    <p className="form-input-hint">{form?.errors?.delivery?.recipient?.name?.message}</p>}
                                            >
                                                <input
                                                    className={cn(s_Input.input, {
                                                        [s_Input.input_error]: form.errors?.delivery?.recipient?.name,
                                                    })}
                                                    type="text"
                                                    placeholder="Имя*"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register({
                                                        required: VALIDATION.req.required
                                                    })}
                                                    name="delivery.recipient.name"
                                                    id="to-name"
                                                    defaultValue={dataFields?.delivery?.recipient?.name}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => {
                                                        onChangeDeliverObj('id', null, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                        onChangeDeliverObj('name', e.target.value, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    }}
                                                />
                                            </InputWrapper>
                                        </ColFull>
                                    </Group>

                                    {/* delivery.recipient.patronymic */}
                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>
                                        <ColFull>
                                            <InputWrapper
                                                label="Отчество"
                                                id="to-patronymic"
                                                errorComponent={form.errors?.delivery?.recipient?.patronymic &&
                                                    <p className="form-input-hint">{form?.errors?.delivery?.recipient?.patronymic?.message}</p>}
                                            >
                                                <input
                                                    className={cn(s_Input.input, {
                                                        [s_Input.input_error]: form.errors?.delivery?.recipient?.patronymic,
                                                    })}
                                                    type="text"
                                                    placeholder="Отчество"
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ref={form.register({
                                                        required: VALIDATION.req.required
                                                    })}
                                                    name="delivery.recipient.patronymic"
                                                    id="to-patronymic"
                                                    defaultValue={dataFields?.delivery?.recipient?.patronymic}
                                                    readOnly={disableBlockAll}
                                                    onChange={(e) => {
                                                        onChangeDeliverObj('id', null, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                        onChangeDeliverObj('patronymic', e.target.value, 'recipient', dataFields?.delivery?.id, dataFields?.delivery?.recipient?.id);
                                                    }}
                                                />
                                            </InputWrapper>
                                        </ColFull>
                                    </Group>

                                    {/* delivery.recipient.phone */}
                                    <Group attrs={{
                                        className: cn('mt-1 mb-1', {
                                            [s_Input.z_index_5]: disableBlockAll,
                                        })
                                    }}>
                                        <ColFull>
                                            <InputWrapper
                                                label="Телефон*"
                                                id="to-phone"
                                                errorComponent={form.errors?.delivery?.recipient?.phone &&
                                                    <p className="form-input-hint">{form?.errors?.delivery?.recipient?.phone?.message}</p>}
                                            >
                                                <InputMask mask="+389999999999"
                                                           defaultValue={dataFields?.delivery?.recipient?.phone}
                                                           readOnly={disableBlockAll} onChange={handlePhone}>
                                                    {(inputProps) => <input
                                                        className={cn(s_Input.input, {
                                                            [s_Input.input_error]: form.errors?.delivery?.recipient?.phone,
                                                        })}
                                                        type="phone"
                                                        placeholder="Телефон*"
                                                        maxLength={INPUT_MAX_LENGTH}
                                                        name="delivery.recipient.phone"
                                                        id="to-phone"
                                                        ref={form.register({
                                                            required: VALIDATION.req.required
                                                        })}
                                                        defaultValue={dataFields?.delivery?.recipient?.phone}
                                                        readOnly={disableBlockAll}
                                                        onChange={handlePhone}
                                                        {...inputProps}
                                                    />}
                                                </InputMask>
                                            </InputWrapper>
                                        </ColFull>
                                    </Group>
                                </>
                            )}

                            {/* delivery.estimated_delivery_date */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <div className={s_Input.wrapper}>
                                        <div className={s_Input.input__wrapper}>
                                            <input
                                                className={s_Input.input}
                                                type="date"
                                                name="delivery.estimated_delivery_date"
                                                id="to-estimated-delivery-date"
                                                defaultValue={dataFields?.delivery?.estimated_delivery_date}
                                                min={dateMinDisable}
                                                placeholder="Выберите дату"
                                                ref={form.register}
                                                onChange={(e) => onChangeObj('estimated_delivery_date', e.target.value, 'delivery', dataFields?.delivery?.id)}
                                            />
                                            <label className={s_Input.label} htmlFor="to_delivery_date">Дата планируемой
                                                доставки</label>
                                        </div>
                                        {form.errors?.delivery?.estimated_delivery_date
                                            &&
                                            <p className="form-input-hint">{form.errors.delivery?.estimated_delivery_date?.message}</p>}
                                    </div>
                                </ColFull>
                            </Group>

                            {deliveryTypeName === "DELIVERY_TYPE_ADDRESS" && (
                                <>
                                    {/* delivery.address.time_interval_id */}
                                    {!!timeData.length ? (
                                        <Group attrs={{className: 'mt-1 mb-1'}}>
                                            <ColFull>
                                                <div className={s.select__inner}>
                                                    <div className={s.select__wrapper}>
                                                        <select
                                                            name="delivery.address.time_interval_id"
                                                            className={s.select}
                                                            id="to-time-interval-id"
                                                            ref={form.register}
                                                            onChange={(e) => {
                                                                onChangeDeliverObj('time_interval_id', e.target.value, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id);
                                                                onChangeDeliverObj('time_interval', null, 'address', dataFields?.delivery?.id, dataFields?.delivery?.address?.id);
                                                            }}
                                                        >
                                                            <option value="">выберите время</option>
                                                            {!!timeData.length && timeData.map(time => (
                                                                <option key={time.id} value={time.id}
                                                                        selected={time.id === dataFields?.delivery?.address?.time_interval_id}>{time.title}</option>
                                                            ))}
                                                        </select>
                                                        <label className={s.label} htmlFor="to-time-interval-id">Время
                                                            доставки</label>
                                                    </div>
                                                </div>
                                            </ColFull>
                                        </Group>
                                    ) : delivery_type !== '1' && (
                                        <Group attrs={{
                                            className: cn('mt-1 mb-1', {
                                                [s_Input.z_index_5]: disableBlockAll,
                                            })
                                        }}>
                                            <ColFull>
                                                <InputWrapper label='Время доставки' id='to-time-interval'>
                                                    <InputMask mask="99:99-99:99"
                                                               defaultValue={dataFields?.delivery?.address?.time_interval}
                                                               readOnly={disableBlockAll} onChange={handleTimeInterval}>
                                                        {(inputProps) => (
                                                            <input
                                                                className={s_Input.input}
                                                                type='text'
                                                                placeholder='Время доставки'
                                                                ref={form.register}
                                                                name='delivery.address.time_interval'
                                                                id='to-time-interval'
                                                                {...inputProps}
                                                            />
                                                        )}
                                                    </InputMask>
                                                </InputWrapper>
                                            </ColFull>
                                        </Group>
                                    )}
                                </>
                            )}

                            {/* delivery.ttn_number */}
                            <Group attrs={{
                                className: cn('mt-1 mb-1', {
                                    [s_Input.z_index_5]: disableBlockAll,
                                })
                            }}>
                                <ColFull>
                                    <InputWrapper label="ТТН" id="to-ttn-number">
                                        <input
                                            className={s_Input.input}
                                            type="text"
                                            placeholder="ТТН"
                                            ref={form.register}
                                            value={ttnNumber}
                                            onChange={(e) => {
                                                onChangeObj('ttn_number', e.target.value, 'delivery', dataFields?.delivery?.id);
                                                handleChange(e);
                                            }}
                                            name="delivery.ttn_number"
                                            id="to-ttn-number"
                                            readOnly={disableBlockAll}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* delivery_status */}
                            {dataFields?.delivery_status && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s.text__wrapper}>
                                            <div>Статус доставки</div>
                                            <div className={s.text}>{dataFields?.delivery_status}</div>
                                        </div>
                                    </ColFull>
                                </Group>
                            )}
                            <input
                                type="hidden"
                                ref={form.register}
                                name="delivery_status"
                                id="to-delivery-status"
                            />

                            {/* delivery.customer_comment */}
                            <Group attrs={{
                                className: cn('mt-1 mb-1', {
                                    [s_Input.z_index_5]: disableBlockAll,
                                })
                            }}>
                                <ColFull>
                                    <div className={s.textarea__wrapper}>
                                    <textarea
                                        name="delivery.customer_comment"
                                        className={s.textarea}
                                        id="to-customer-comment"
                                        placeholder="Комментарий клиента"
                                        rows="4"
                                        ref={form.register}
                                        defaultValue={dataFields?.delivery?.customer_comment}
                                        readOnly={adminCanEdit ? false : disableBlockAll}
                                        maxLength={INPUT_MAX_LENGTH}
                                        onChange={(e) => onChangeObj('customer_comment', e.target.value, 'delivery', dataFields?.delivery?.id)}
                                    />
                                        <label className={s.label__textarea} htmlFor="to-customer-comment">Комментарий
                                            клиента</label>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* delivery.delivery_comment */}
                            <Group attrs={{
                                className: cn('mt-1 mb-1', {
                                    [s_Input.z_index_5]: disableBlockAll,
                                })
                            }}>
                                <ColFull>
                                    <div className={s.textarea__wrapper}>
                                    <textarea
                                        name="delivery.delivery_comment"
                                        className={s.textarea}
                                        id="to-delivery-comment"
                                        placeholder="Комментарий для почты"
                                        rows="4"
                                        ref={form.register}
                                        defaultValue={dataFields?.delivery?.delivery_comment}
                                        readOnly={adminCanEdit ? false : disableBlockAll}
                                        maxLength={INPUT_MAX_LENGTH}
                                        onChange={(e) => onChangeObj('delivery_comment', e.target.value, 'delivery', dataFields?.delivery?.id)}
                                    />
                                        <label className={s.label__textarea} htmlFor="to-delivery-comment">Комментарий
                                            для почты</label>
                                    </div>
                                </ColFull>
                            </Group>
                        </>
                    ) : settlementId && (
                        <Group attrs={{
                            className: cn('mt-1 mb-1', {
                                [s_Input.z_index_5]: disableBlockAll,
                            })
                        }}>
                            <ColFull>
                                <div className={s.textarea__wrapper}>
                                    <textarea
                                        name="delivery.customer_comment"
                                        className={s.textarea}
                                        id="to-customer-comment"
                                        placeholder="Комментарий клиента"
                                        rows="4"
                                        ref={form.register}
                                        defaultValue={dataFields?.delivery?.customer_comment}
                                        readOnly={adminCanEdit ? false : disableBlockAll}
                                        maxLength={INPUT_MAX_LENGTH}
                                        onChange={(e) => onChangeObj('customer_comment', e.target.value, 'delivery', dataFields?.delivery?.id)}
                                    />
                                    <label className={s.label__textarea} htmlFor="to-customer-comment">Комментарий
                                        клиента</label>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                </Form>

                {formProps.load && <Loader/>}
            </form>
        </Item>
    );
};

export default OrderDelivery;
