import {
    STATUS_CHANGE_CREATE, STATUS_CHANGE_GET_EDIT, STATUS_CHANGE_EDIT, STATUS_CHANGE_DELETE, STATUS_CHANGE_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getStatusChange = (params) => fetchAx({
    url: STATUS_CHANGE_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const createStatusChange = (data) => fetchAx({
    url: STATUS_CHANGE_CREATE,
    method: 'POST',
    data,
});

export const getStatusChangeEdit = (id) => fetchAx({
    url: STATUS_CHANGE_GET_EDIT.join(id),
    method: 'GET',
});

export const editStatusChange = (id, data) => fetchAx({
    url: STATUS_CHANGE_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeStatusChange = (id) => fetchAx({
    url: STATUS_CHANGE_DELETE.join(id),
    method: 'DELETE',
});
