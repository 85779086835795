import { getDomains } from '../../lib/api/domains';
import { getCreditTypes, removeCreditType } from '../../lib/api/creditTypes';
import { getBanks } from '../../lib/api/banks';
/* eslint-disable */
export const getFiltersData = () => Promise.allSettled([
  getDomains(),
  getBanks(),
])
  .then((res) => {
    const renameBankArrObjKeys = [];
    res && res[1]?.value?.data?.data?.map((el) => {
      const obj = {};
      Object.entries(el).map((o) => {
        const _k = o[0];
        const _v = o[1];

        if (_k.match('tf_bank_id')) {
          obj.id = _v;
          obj.title = _v;
        }
      });
      renameBankArrObjKeys.push(obj);
    });

    return {
      domains: res[0]?.value?.data,
      banks: renameBankArrObjKeys,
    };
  });

export const getData = getCreditTypes;
export const remove = removeCreditType;
