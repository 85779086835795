import { getRoles, removeRole } from '../../lib/api/roles';

export const getFiltersData = () => Promise.allSettled([])
    .then(() => ({
        /* domains: res[0]?.value?.data,
        categories: res[1]?.value?.data */
    }));

export const getData = getRoles;
export const remove = removeRole;
