import { getSpecOfferBanners, removeSpecOfferBanners } from "../../lib/api/specOfferBanners";
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains(),
])
    .then((res) => ({
        domains: res[0]?.value?.data,
    }));

export const getData = getSpecOfferBanners;
export const remove = removeSpecOfferBanners;
