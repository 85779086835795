import React, { Fragment, useState } from "react";
import Button from "../../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import s_Table from '../orderTable.module.scss';
import icon_lock from '../../../../assets/icons/icon_lock.svg';
import icon_unlock from "../../../../assets/icons/icon_unlock.svg";
import cn from "classnames";
import s from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import { getOrderEdit, orderReceiptReturn } from "../../../../lib/api/order";
import { toast } from "react-toastify";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";

const ModalReturnProduct = ({ active, setActive, productsData, setProductsData, title, formProps }) => {
    const [load, setLoad] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [chooseProducts, setChooseProducts] = useState([]);
    const [chooseServices, setChooseServices] = useState([]);
    const [chooseSpecOffers, setChooseSpecOffers] = useState([]);

    const handleChangeDisabled = () => setDisabled(!disabled);

    const handleClickReturnProduct = async () => {
        const parseProduct = [
            ...chooseProducts,
            ...chooseServices,
            ...chooseSpecOffers
        ].map(item => {
            delete item.send;
            return item;
        }).filter(item => typeof item.quantity !== 'undefined');

        setLoad(true);

        await orderReceiptReturn(formProps?.orderID, parseProduct).then(async (res) => {
            if(res.data.message || res.data.error) {
                toast.error(res.data.message || res.data.error, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast('Заказ оновлений', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            if (formProps.orderID) {
                await getOrderEdit(formProps.orderID).then((resOrder) => {
                    formProps.setData(prev => {
                        const temp = {...prev};

                        temp.fields = {...resOrder.data}

                        return temp;
                    })
                    if (!!resOrder?.data?.products?.length) {
                        resOrder?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                };
                                return productsData;
                            })
                        })
                    }
                }).catch((err) => errorMessage(err, '')).finally(() => {
                    setActive(false);
                })
            }
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await orderReceiptReturn(formProps?.orderID, parseProduct).then(async (res) => {
                        if(res.data.message || res.data.error) {
                            toast.error(res.data.message || res.data.error, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast('Заказ оновлений', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                        if (formProps.orderID) {
                            await getOrderEdit(formProps.orderID).then((resOrder) => {
                                formProps.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = {...resOrder.data}

                                    return temp;
                                })
                            }).catch((err) => errorMessage(err, '')).finally(() => {
                                setActive(false);
                            })
                        }
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        }).finally(() => setLoad(false));
    }

    const handleCheckedChange = (setArray, product, receiptableType) => {
        setArray(prev => {
            if (!!prev?.length) {
                const temp = [...prev];
                const findSpecOfferIndex = temp.findIndex(item => item.receiptable_id === product.id);

                if (findSpecOfferIndex !== -1) {
                    temp.splice(findSpecOfferIndex, 1);
                } else {
                    temp.push({ send: true, receiptable_id: product.id, receiptable_type: receiptableType });
                }
                return temp;
            } else {
                return [{ send: true, receiptable_id: product.id, receiptable_type: receiptableType }];
            }
        })
    }
    const handleChange = (e, setArray, product, receiptableType) => {
        if (e.target.value.length > 1) {
            if (e.target.value.split('')?.[0]?.toString() === '0') e.target.value = e.target.value.split('')[1]
        }
        const valueToNumber = +e.target.value;

        setArray(prev => {
            if (!!prev?.length) {
                const temp = [...prev];
                const findProductIndex = temp.findIndex(item => item.receiptable_id === product.id);

                if (findProductIndex !== -1) {
                    temp[findProductIndex].quantity = valueToNumber <= product.quantity
                        ? valueToNumber
                        : product.quantity;
                } else {
                    temp.push({ send: true, receiptable_id: product.id, receiptable_type: receiptableType, quantity: valueToNumber });
                }
                return temp;
            } else {
                return [{ send: true, receiptable_id: product.id, receiptable_type: receiptableType, quantity: valueToNumber }];
            }
        })
    }

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                    </div>
                    <div className={cn(s_Modal.modal__unlock, {
                        [s_Modal.modal__lock]: disabled,
                    })} onClick={handleChangeDisabled}>
                        {disabled ? (
                            <>
                                <img src={icon_lock} alt="Icon lock" />
                                Разблокировать
                            </>
                        ) : (
                            <>
                                <img src={icon_unlock} alt="Icon lock" />
                                Заблокировать
                            </>
                        )}
                    </div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper}>
                            {!load ? (
                                    <table className={s_Table.table}>
                                        <thead className={s_Table.thead}>
                                            <tr>
                                                <th />
                                                <th>
                                                    <div>JDE</div>
                                                </th>
                                                <th>
                                                    <div>Назва товару</div>
                                                </th>
                                                <th>
                                                    <div>К-ть</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={s_Table.tbody}>
                                            {!!productsData.length ? productsData.map((product, indexProduct) => {

                                                return (
                                                    <Fragment key={`modal-table-tr-product-${product?.product?.jde || indexProduct}`}>
                                                        <tr>
                                                            <td>
                                                                <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                    <input
                                                                        className={s.checkbox}
                                                                        type="checkbox"
                                                                        id={`modal-table-product-${indexProduct}`}
                                                                        name={`products[${indexProduct}].selected`}
                                                                        defaultChecked={!!chooseProducts?.length
                                                                            ? chooseProducts?.find(item => item.receiptable_id === product.id)?.send
                                                                            : false}
                                                                        onChange={(e) => {
                                                                            handleCheckedChange(setChooseProducts, product, 'products');
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`modal-table-product-${indexProduct}`}
                                                                           className={`${s.label} ${s_Table.label}`}/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>{product?.product?.jde}</div>
                                                            </td>
                                                            <td>
                                                                <div>{product?.product?.title_ua || product?.product?.title_ru}</div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <input
                                                                        type="number"
                                                                        placeholder={product.return_quantity || '0'}
                                                                        name={`products[${indexProduct}].return_quantity`}
                                                                        disabled={!!localStorage?.getItem('roles')?.length
                                                                            ? JSON.parse(localStorage?.getItem('roles'))?.some(itemRole => !['admin', 'super-admin']?.includes(itemRole))
                                                                            : true}
                                                                        value={chooseProducts?.find(item => item.receiptable_id === product.id)?.quantity}
                                                                        max={product.quantity - product.return_quantity}
                                                                        readOnly={!chooseProducts?.find(item => item.receiptable_id === product.id)?.send}
                                                                        onChange={(e) => {
                                                                            handleChange(e, setChooseProducts, product, 'products');
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {!!product?.product?.additional_services?.length && product?.product?.additional_services?.map((service, indexService) => (
                                                            <tr key={`modal-table-tr-additional-services-${service?.id || indexService}`}>
                                                                <td>
                                                                    <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                        <input
                                                                            className={s.checkbox}
                                                                            type="checkbox"
                                                                            id={`modal-table-product-${indexProduct}-additional-service-${indexService}`}
                                                                            name={`products[${indexProduct}].selected`}
                                                                            checked={!!chooseServices?.length
                                                                                ? chooseServices?.find(item => item.receiptable_id === service.id)?.send
                                                                                : false}
                                                                            onChange={(e) => {
                                                                                handleCheckedChange(setChooseServices, service, 'product_additional_services');
                                                                            }}
                                                                        />
                                                                        <label htmlFor={`modal-table-product-${indexProduct}-additional-service-${indexService}`}
                                                                               className={`${s.label} ${s_Table.label}`}/>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>{service?.additional_service?.jde}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{service?.additional_service?.title_ua || service?.title}</div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <input
                                                                            type="number"
                                                                            defaultValue={service?.return_quantity || ''}
                                                                            name={`products[${indexProduct}].additional_services[${indexService}].return_quantity`}
                                                                            disabled={!!localStorage?.getItem('roles')?.length
                                                                                ? JSON.parse(localStorage?.getItem('roles'))?.some(itemRole => !['admin', 'super-admin']?.includes(itemRole))
                                                                                : true}
                                                                            placeholder={service.return_quantity || '0'}
                                                                            value={!!chooseServices?.length
                                                                                ? chooseServices?.find(item => item.receiptable_id === service.id)?.quantity || ''
                                                                                : service?.return_quantity || ''}
                                                                            max={service.quantity - service.return_quantity}
                                                                            readOnly={!chooseServices?.find(item => item.receiptable_id === service.id)?.send}
                                                                            onChange={(e) => {
                                                                                handleChange(e, setChooseServices, service, 'product_additional_services');
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {!!product?.product?.special_offers?.length && product.product.special_offers?.map((offer, indexOffer) => {
                                                            return (
                                                                <tr key={`modal-table-tr-special-offers-${offer?.id || indexOffer}`}>
                                                                    <td>
                                                                        <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                            <input
                                                                                className={s.checkbox}
                                                                                type="checkbox"
                                                                                id={`modal-table-product-${indexProduct}-special-offers-${indexOffer}`}
                                                                                name={`products[${indexProduct}].selected`}
                                                                                checked={!!chooseSpecOffers?.length
                                                                                    ? chooseSpecOffers?.find(item => item.receiptable_id === offer.id)?.send
                                                                                    : false}
                                                                                onChange={(e) => {
                                                                                    handleCheckedChange(setChooseSpecOffers, offer, 'product_special_offers');
                                                                                }}
                                                                            />
                                                                            <label htmlFor={`modal-table-product-${indexProduct}-special-offers-${indexOffer}`}
                                                                                   className={`${s.label} ${s_Table.label}`}/>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>{offer?.promo_product?.jde}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div>{offer?.promo_product?.title}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <input
                                                                                type="number"
                                                                                name={`products[${indexProduct}].special_offers[${indexOffer}].return_quantity`}
                                                                                disabled={!!localStorage?.getItem('roles')?.length
                                                                                    ? JSON.parse(localStorage?.getItem('roles'))?.some(itemRole => !['admin', 'super-admin']?.includes(itemRole))
                                                                                    : true}
                                                                                placeholder={offer.return_quantity || '0'}
                                                                                defaultValue={offer?.return_quantity || ''}
                                                                                value={!!chooseSpecOffers?.length
                                                                                    ? chooseSpecOffers?.find(item => item.receiptable_id === offer.id)?.quantity || ''
                                                                                    : offer?.return_quantity || ''}
                                                                                max={offer.quantity - offer.return_quantity}
                                                                                readOnly={!chooseSpecOffers?.find(item => item.receiptable_id === offer.id)?.send}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, setChooseSpecOffers, offer, 'product_special_offers');
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            }) : <div style={{ padding: 10 }}>Немає товарів</div>}
                                        </tbody>
                                    </table>
                                )
                                : (
                                    <div className="p-relative">
                                        <div className="loading" />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{justifyContent: 'flex-end'}}>
                        <div className={s_Modal.modal__buttons}>
                            <div>
                                <Button purple disabled={disabled} onClick={handleClickReturnProduct}>Повернути</Button>
                            </div>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalReturnProduct;