import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import GalleryImages from '../../../components/ui/GalleryImages';
import { ModalMedia } from "../Modal";
import ModalRemove from "../../../components/ui/Modal/ModalRemove";
import { getMedia, removeMedia } from "../../../lib/api/media";
import errorMessage from "../../../lib/errorMessage";

const TabImages = ({ index = 0, formProps }) => {
  const dataFields = formProps.data && formProps.data.fields;
  const form = useForm({
    defaultValues: dataFields,
  });

  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);
  const [typeCoverData, setTypeCoverData] = useState([]);

  // add to tabs form
  useEffect(() => {
    formProps.tabsForm.addForm(form, index);
  }, []);


  function submitHandler(e) {
    formProps.tabsForm.validate(e, formProps.submitHandler);
  }

  const [dataModal, setDataModal] = useState({});

  useEffect(() => {
    getMedia({
      'entity_type': 'article',
      'entity_id': formProps?.data?.fields?.id,
    }).then(res => {
      const filterCover = res?.data?.data?.filter(item => item?.collection_name === 'cover');
      if(filterCover) {
        setTypeCoverData(filterCover);
      }
    }).catch(async (err) => await errorMessage(err , ''))
  }, []);

  /* eslint-disable */
  return (
    <form
      style={formProps.tabsForm.getDStyle(index)}
      onSubmit={submitHandler}
    >
      <button type="submit" className="d-hide" />

      <div className="box-wrap box-wrap--mb">
        <Form Tag="div">
          <h4>Галерея изображений</h4>

          <div
              className="box-wrap box-wrap--mb"
          >
            {/*<h5>title</h5>*/}

            <div className="text-center">
              <p className="label">Титульные изображения</p>
            </div>
            <div>Максимальное разрешение картинки 1920x1080</div>

            {/* cover */}
            {dataFields?.id && (
                <GalleryImages
                    id={`product_img`}
                    nameParent={`product_images[image_list]`}
                    formH={form}
                    accept="image/*"
                    acceptStr="PNG, JPG, GIF"
                    imageData={typeCoverData}
                    setImageData={setTypeCoverData}
                    setActiveMedia={setActiveMedia}
                    setActiveRemove={setActiveRemove}
                    setDataModal={setDataModal}
                    prodID={dataFields?.id}
                    entityType='article'
                    collectionName='cover'
                    // limit={12}
                />
            )}

          </div>

          {activeMedia && <ModalMedia
              active={activeMedia}
              setActive={setActiveMedia}
              data={dataModal}
              collectionName={'cover'}
              setImageData={setTypeCoverData}
              title='Редактирование картинки'
          />}
          {activeRemove && <ModalRemove
              active={activeRemove}
              setActive={setActiveRemove}
              data={dataModal}
              funcRemove={removeMedia}
              setRemoveItem={setTypeCoverData}
              title='Удалить изображение'
          />}
        </Form>

        {formProps.load && <Loader />}
      </div>
    </form>
  );
};

export default TabImages;
