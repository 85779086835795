import React from 'react';
// import cn from "classnames";

// import s from "./GridEditViewContent.module.scss";

const GridEditViewContent = ({ children, col = 7 }) => (
  <div className={`column col-lg-12 col-${col}`}>
    {children}
  </div>
);

export default GridEditViewContent;
