import {Link, useLocation, useNavigate} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewPagination from '../../components/common/TableListView/Pagination';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewFooter from '../../components/common/TableListView/Footer';
import TableListViewPerPage from '../../components/common/TableListView/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, getFiltersData, remove } from './api';
import { getRoles } from "../../lib/api/roles";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const StatusChangeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    filtersData,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  const [rolesData, setRolesData] = useState([]);

  const getStatusName = (id) => filtersData?.statuses?.find(status => status.id === id)

  useEffect(() => {
    getRoles().then((res) => setRolesData(res?.data?.data));
  }, []);

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Status change list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Роль</th>
            <th>Текущий статус</th>
            <th>Следующий статус</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
              urlParams={urlParams}
              fields={[
                {},
                { type: 'select', name: 'role_id', options: filtersData?.roles && filtersData.roles, optProps: ["id", "name"] },
                { type: 'select', name: 'status', options: filtersData?.statuses && filtersData.statuses, optProps: ["id", "name"] },
                { type: 'select', name: 'status_to', options: filtersData?.statuses && filtersData.statuses, optProps: ["id", "name"] },
                {},
              ]}
              onSubmit={pushUrlParams}
          />

        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={7} />
            )
            : (rest && (
              rest?.data?.length !== 0
                ? rest?.data?.map((e, i) => {
                    let findRoleName = {};
                    if(e?.role_id) {
                      findRoleName  = rolesData?.find(role => role?.id === e?.role_id)?.name;
                    }

                    return (
                        <TableListViewRow
                            id={e.id}
                            key={`status-change-item-${e.id}`}
                            remove={{
                              api: remove,
                              success: () => setRest(getSplicedData(i, rest)),
                              alert: 'Статус измениния удален',
                            }}
                            editLink={`/app/status-change/update/${e.id}`}
                            permissionRemove='admin.status.change.destroy'
                            permissionEdit='admin.status.change.edit'
                        >
                          <td>
                            {checkPermission('admin.status.change.edit')
                                ? <Link to={`/app/status-change/update/${e.id}`}>{e.id}</Link>
                                : <span>{e.id}</span>
                            }
                          </td>
                          <td>{e?.role_id ? findRoleName : '---'}</td>
                          <td>{getStatusName(e?.status)?.name}</td>
                          <td>{getStatusName(e?.status_to)?.name}</td>
                        </TableListViewRow>
                    )
                  })
                : (
                  <TableListViewRow disabled>
                    <td colSpan={7} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>

      {!load && (
        <TableListViewFooter
          left={(
              <>
                {+rest?.total && (
                    <TableListViewPagination
                        length={+rest?.last_page}
                        current={+rest?.current_page}
                        total={+rest?.total}
                        perPage={+rest?.per_page}
                        loc={location}
                    />
                )}
              </>
          )}
          right={(
            <TableListViewPerPage
              urlParams={urlParams}
              onSelect={(val, params) => pushUrlParams(params)}
            />
          )}
          rightCn="col-auto"
        />
      )}
    </div>
  );
};

export default StatusChangeList;
