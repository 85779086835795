import {
  ADDITIONAL_SERVICE_GET,
  ADDITIONAL_SERVICE_CREATE,
  ADDITIONAL_SERVICE_GET_EDIT,
  ADDITIONAL_SERVICE_EDIT,
  ADDITIONAL_SERVICE_GET_ALL,
  ADDITIONAL_SERVICE_DELETE,
} from '../../const/api';

import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getAdditionalServices = (params) => fetchAx({
  url: ADDITIONAL_SERVICE_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getAdditionalServicesAll = () => fetchAx({
  url: ADDITIONAL_SERVICE_GET_ALL,
  method: 'GET',
});

export const createAdditionalService = (data) => fetchAx({
  url: ADDITIONAL_SERVICE_CREATE,
  method: 'POST',
  data,
});

export const getAdditionalServiceEdit = (id) => fetchAx({
  url: ADDITIONAL_SERVICE_GET_EDIT.join(id),
  method: 'GET',
});

export const editAdditionalService = (id, data) => fetchAx({
  url: ADDITIONAL_SERVICE_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeAdditionalService = (id) => fetchAx({
  url: ADDITIONAL_SERVICE_DELETE.join(id),
  method: 'DELETE',
});
