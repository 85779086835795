import React from 'react';

import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const SpecOfferBannerTagEditView = () => {
  const { id: sobtID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: sobtID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/spec-offer-banners/tag/not-found',
      remove: `/app/spec-offer-banners/tag${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/spec-offer-banners/tag${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Тег банера создан',
      edit: 'Тег банера обновлен',
      remove: 'Тег банера удален',
    },
  });

/* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Spec offer banners tag edit`} />
      <div className="box-wrap">
        {sobtID
          ? (
            <Title>
              Банер -
              <small>{data && data.fields.title_ru}</small>
            </Title>
          )
          : <Title>Новый тег банера</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="sofbt-name-ru">Название тега РУС*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ru"
                className={cn('form-input', {
                  'is-error': errors.title_ru,
                })}
                type="text"
                id="sofbt-name-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
            </ColRight>
          </Group>

          {/* title_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="sofbt-name-ua">Название тега УКР*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ua"
                className={cn('form-input', {
                  'is-error': errors.title_ua,
                })}
                type="text"
                id="sofbt-name-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
            </ColRight>
          </Group>
        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default SpecOfferBannerTagEditView;
