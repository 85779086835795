import qs from "query-string";
import React from "react";
import ReactPaginate from "react-paginate";
import { DOMAIN_API } from "../../../../const/api";
import fetchAx from "../../../../lib/fetchAx";
import { LIST_PER_PAGE } from "../../../../const/view";

const TableListViewPagination = ({
  length,
  current,
  loc,
  url,
  setData,
  navigate,
  allResponse = false,
  params = {}
}) => {
  function getUrl(n) {
    return qs.stringifyUrl({
      url: loc ? loc.search : `${DOMAIN_API}/${url}`,
      query: {
        page: n,
      },
    });
  }

  const handlePageClick = (event) => {
    if (navigate) {
      navigate(getUrl(event.selected + 1))
    } else if (url && setData) {
      fetchAx({
        url: getUrl(event.selected + 1),
        method: 'GET',
        params: {
          per_page: LIST_PER_PAGE.default,
          ...params
        }
      }).then((res) => {
        if (allResponse) {
          setData(res.data);
        } else {
          setData(res.data.data);
        }
      })
    }
  };

  return (
      <ReactPaginate
          nextLabel={null}
          previousLabel={null}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={length}
          pageClassName="page_item"
          pageLinkClassName="page_link"
          previousClassName="page_previous"
          previousLinkClassName="page_link"
          nextClassName="page_next"
          nextLinkClassName="page_link"
          breakLabel="..."
          breakClassName="page_item"
          breakLinkClassName="page_link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={current - 1}
      />
  )
};

export default TableListViewPagination;
