import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOrderDocument } from "../../../lib/api/order_document";
import { getTemplates } from "../../../lib/api/template";
import Button from "../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import sSelect from "../../OrderEditView/fakeData/orderStyle.module.scss";
import { refreshToken } from "../../../lib/crud/refreshToken";
import errorMessage from "../../../lib/errorMessage";

const ModalAddDocument = ({ active, setActive, title, orderID }) => {
    const navigate = useNavigate();
    const [documentData, setDocumentData] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        getTemplates({is_active: 1})
            .then((res) => setDocumentData(res.data.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        getTemplates({is_active: 1})
                            .then((res) => setDocumentData(res.data.data))
                    })
                } else {
                    await errorMessage(err, '')
                }
            })
            .finally(() => setDisabled(false));
    }, [active]);

    const handleClick = async () => {
        setDisabled(true);
        const id = await getOrderDocument(orderID, ref?.current?.value)
            .then(res => {
                return res.data.id;
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        const id = await getOrderDocument(orderID, ref?.current?.value)
                            .then(res => {
                                return res.data.id;
                            })
                        if(id) {
                            navigate(id.toString());
                        }
                    })
                } else {
                    await errorMessage(err, '')
                }
            })
            .finally(() => setDisabled(false));
        if(id) {
            navigate(id.toString());
        }
    };

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                    </div>
                </div>
                <div>
                    <div className={sSelect.select__inner}>
                        <div className={sSelect.select__wrapper}>
                            <select
                                name="template"
                                className={sSelect.select}
                                id="od_template"
                                ref={ref}
                            >
                                {documentData?.map((item) => (
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                ))}
                            </select>
                            <label className={sSelect.label} htmlFor="od_template">Шаблон</label>
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                        <div className={s_Modal.modal__buttons}>
                            <div>
                                <Button purple disabled={disabled} onClick={handleClick}>Создать</Button>
                            </div>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddDocument;