import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import errorMessage from "../../../lib/errorMessage";
import {DOMAIN_API} from "../../../const/api";

import ReactToPrint from "react-to-print";
import fetchAx from "../../../lib/fetchAx";

import styles from "./TopPanel.module.scss";

const BtnPrint = ({title}) => {
    const orderEdit = useSelector(state => state.orderEdit);
    const [value, setValue] = useState('');
    const [readyToPrint, setReadyToPrint] = useState(false);
    let componentRef = useRef();

    const handlePrint = async () => {
      if (!orderEdit?.selectedOrders.length) {
        const err = {response: {status: 'customDocumentSelect'}}
        errorMessage(err, 'Оберіть замовлення')
      } else {
        for (const item of orderEdit.selectedOrders) {
          try {
            const orderList = orderEdit?.selectedOrders.map(item => item.id);
            await fetchAx({
              url: `${DOMAIN_API}/api/admin/order/documents`,
              method: 'POST',
              data: {order_ids: orderList},
            })
              .then(async (res) => {
                if(!res.data[0]){
                  let err = {response: {status: 'customDocumentPrint'}}
                  errorMessage(err, `Документів не знайдено`);
                } else {
                  const text = await res.data.map((el, index) => index ? '<div style="padding: 15px; margin-top: 50px;  page-break-before: always; ">' + el.text : el.text + '</div>').join('\n');
                  setValue(text);

                  setReadyToPrint(true);
                }
              })
          } catch (err) {
            errorMessage(err, ``);
          }
        }
      }
    }

    useEffect(() => {
      if (readyToPrint) {
        const wrapper = document.querySelector('#ref');
        wrapper.innerHTML = value;
        if (componentRef.current) {
          componentRef.current.handlePrint();
        }
        setReadyToPrint(false);
      }
    }, [readyToPrint, value])

    return (
      <div>
        <button onClick={handlePrint} className="btn btn-primary">
          {title}
        </button>
        <ReactToPrint
          trigger={() => <div/>} // Здесь можно не отображать триггер
          content={() => document.querySelector("#ref")}
          ref={(el) => (componentRef.current = el)}
        />
        <div className={styles.hiddenWindow} >
          <div ref={componentRef} id="ref" style={{margin: "30px", fontSize: "14px"}}>
            {value}
          </div>
        </div>
      </div>
    )
  }
;

export default BtnPrint;