import { getDomains } from "../../lib/api/domains";
import { getAdminsAll } from "../../lib/api/admins";
import { getCatTree } from "../../lib/api/categories";
import {getServiceCenterList, getServiceCenterRegistrations, removeServiceCenterRegistrations} from "../../lib/api/service_centers";


export const getFiltersData = () => Promise.allSettled([
  getDomains(), getCatTree(), getServiceCenterList()
])
  .then((res) => ({
      domains: res[0]?.value?.data,
      categories: res[1]?.value?.data,
      services: res[2]?.value?.data,
  }));

export const getData = getServiceCenterRegistrations;
export const remove = removeServiceCenterRegistrations;
