import { getProductB2b, removeProductB2b } from '../../lib/api/product_b2b';
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
  }));

export const getData = getProductB2b;
export const remove = removeProductB2b;
