import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const UniqueServiceEditView = () => {
  const { id: uniqueServiceID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: uniqueServiceID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/unique-service/not-found',
      remove: `/app/main/unique-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/unique-services${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Уникальный сервис создан',
      edit: 'Уникальный сервис обновлен',
      remove: 'Уникальный сервис удален',
    },
  });
/* eslint-disable */
  return (
    <GridEditView>
      <div className="box-wrap">
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Unique services edit`} />
        {uniqueServiceID
          ? (
            <Title>
              Уникальный сервис -
              <small>{data && data.fields.title_ru}</small>
            </Title>
          )
          : <Title>Новый уникальный сервис</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-name-ua">Название УКР:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ua"
                className={cn('form-input', {
                  'is-error': errors.title_ua,
                })}
                type="text"
                id="benefit-name-ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
            </ColRight>
          </Group>

          {/* title_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-name-ru">Название РУC:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ru"
                className={cn('form-input', {
                  'is-error': errors.title_ru,
                })}
                type="text"
                id="benefit-name-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
            </ColRight>
          </Group>

          {/* subsc_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-subscription_ua">Описание УКР:</label>
            </ColLeft>
            <ColRight>
              <input
                name="subscription_ua"
                className={cn('form-input', {
                  'is-error': errors.subscription_ua,
                })}
                type="text"
                id="benefit-subscription_ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.subscription_ua && <p className="form-input-hint">{errors.subscription_ua.message}</p>}
            </ColRight>
          </Group>

          {/* subsc_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-subscription_ru">Описание РУС:</label>
            </ColLeft>
            <ColRight>
              <input
                name="subscription_ru"
                className={cn('form-input', {
                  'is-error': errors.subscription_ru,
                })}
                type="text"
                id="benefit-subscription_ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.subscription_ru && <p className="form-input-hint">{errors.subscription_ru.message}</p>}
            </ColRight>
          </Group>

          {/* domain_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="so-domain">Домен*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="domain_id"
                      className={cn('form-select', {
                        'is-error': errors.domain_id,
                      })}
                      id="so-domain"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                      {data && !!data.domains && data.domains.map((domain, i) => (
                        <option
                          key={`so-domain-${i}`}
                          value={domain.id}
                        >
                          {domain.title}
                        </option>
                      ))}
                    </select>

                    {errors.domain_id
                                        && <p className="form-input-hint">{errors.domain_id.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft />
            <ColRight>
              <label className="form-switch">
                <input
                  name="is_active"
                  type="checkbox"
                  id="benefit-status-active"
                  ref={register}
                />
                <i className="form-icon" />
                {' '}
                Статус активности
              </label>
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default UniqueServiceEditView;
