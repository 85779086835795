import {
    SELECTIONS_GET,
    SELECTION_CREATE,
    SELECTION_DELETE,
    SELECTION_GET_EDIT,
    SELECTION_EDIT,
    SELECTION_CATEGORY_DELETE
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSelections = (params) => fetchAx({
    url: SELECTIONS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params
    }
});

export const createSelection = (data) => fetchAx({
    url: SELECTION_CREATE,
    method: 'POST',
    data
});

export const getSelectionEdit = (id) => fetchAx({
    url: SELECTION_GET_EDIT.join(id),
    method: 'GET'
});

export const editSelection = (id, data) => fetchAx({
    url: SELECTION_EDIT.join(id),
    method: 'PUT',
    data
});

export const removeSelection = (id) => fetchAx({
    url: SELECTION_DELETE.join(id),
    method: 'DELETE',
});

export const removeSelectionCategory = (id, catId) => fetchAx({
    url: SELECTION_CATEGORY_DELETE(id, catId),
    method: 'DELETE',
});
