import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData } from "./api";
import getTZtoDate from "../../lib/getTZtoDate";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";

const TradeInList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Trade in list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={[
                        "Ім'я", 'Email', 'Телефон', 'Новий пристрій', 'Старий пристрій', 'Оціночна вартість', 'Магазин',
                        'Створено'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {
                                type: 'select',
                                name: 'warehouse_id',
                                options: !!filtersData?.wareHouses?.length && filtersData.wareHouses,
                                optProps: ['id', 'title_ru']
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={7}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow key={`trade-it-item-${i}`} controlsOff>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.email || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.product?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        {e?.devices?.map(item => (
                                            <div>{item?.title},</div>
                                        ))}
                                    </td>
                                    <td>
                                        <span>
                                            {e?.trade_in_price || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.warehouse?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7}>Немає TradeIn</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default TradeInList;