import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import { getData, remove } from './api';
import TopNavTabs from '../../components/ui/TopNavTabsNew/TopNavTabs';

import { geDomainName } from '../../lib/const/domains';
import checkPermission from '../../lib/checkPermission';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import StatusCircle from "../../components/ui/StatusCircle";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const UniqueServiceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Unique services list`} />
      {!load && (
          <TopPagination
              tabs={(
                  <TopNavTabs tabs={[
                    {
                      title: 'Преимущества',
                      url: '/app/main/benefits',
                      permissionName: 'admin.benefit.index',
                    },
                    {
                      title: 'Популярные товары',
                      url: '/app/main/popular-goods',
                      permissionName: 'admin.popularity-block.all',
                    },
                    {
                      title: 'Уникальные сервисы',
                      url: '/app/main/unique-services',
                      permissionName: 'admin.services-block.index',
                      isActive: true,
                    },
                    {
                      title: 'Сервисы',
                      url: '/app/main/services',
                      permissionName: 'admin.main-page-service.all',
                    },
                    {
                      title: 'Новинки',
                      url: '/app/main/newest',
                      permissionName: 'admin.newest-block.all',
                    },
                    {
                      title: 'Популярные категории',
                      url: '/app/main/popular-categories',
                      permissionName: 'admin.main-page-category-block.all',
                    },
                  ]}
                  />
              )}
          />
      )}
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название УКР</th>
            <th>Название РУС</th>
            <th>Описание УКР</th>
            <th>Описание РУС</th>
            <th>Домен</th>
            <th>Статус активность</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={8} />
            )
            : (rest && (
              rest?.length
                ? rest?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`unique_service-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]),
                      alert: 'Уникальный сервис удален',
                    }}
                    editLink={`/app/main/unique-service/update/${e.id}`}
                    permissionRemove='admin.services-block.destroy'
                    permissionEdit='admin.services-block.edit'
                  >
                    <td>
                      {checkPermission('admin.services-block.edit')
                          ? <Link to={`/app/main/unique-service/update/${e.id}`}>{e.id}</Link>
                          : <span>{e.id}</span>
                      }
                    </td>
                    <td>
                      <span>
                        {e?.title_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.title_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.subscription_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.subscription_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.domain_id && geDomainName(e.domain_id)?.name}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Отключена" />}
                      </span>
                    </td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={8} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default UniqueServiceList;
