import { PURCHASE_GET, PURCHASE_SEND } from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getPurchases = (params) => fetchAx({
  url: PURCHASE_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const sendPurchase = (data) => fetchAx({
  url: PURCHASE_SEND,
  method: 'POST',
  data
});