import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import useRemove from '../../../../lib/crud/useRemove';
import useIsMounted from '../../../../lib/useIsMounted';

import s from './Row.module.scss';
import checkPermission from "../../../../lib/checkPermission";

const TableListViewRow = ({
  id,
  children,
  attrs,
  editLink,
  remove = { api: null, success: null, alert: null },
  permissionRemove,
  permissionEdit,
  disabled = false,
  controlsOff = false,
  deleteButton = true,
  showDeleteButton = false,
}) => {
  const isMounted = useIsMounted();
  const { load, removeHandler } = useRemove({
    id,
    isMounted,
    ...remove,
  });
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className,
      { [s.trLoad]: load },
      { [s.trDisabled]: disabled }),
  };

  return (
    <tr {...attrsMerge}>
      {children}

      {showDeleteButton && (
          checkPermission(permissionRemove) && (
            <td>
              <div className="table-controls">
                  <button
                      type="button"
                      className={`btn btn-action s-circle ${s.btn_danger}`}
                      onClick={removeHandler}
                  >
                    <i className="icon icon-delete" />
                  </button>
              </div>
            </td>
      ))}

      {!controlsOff && (
        <td>
          <div className="table-controls">
            {checkPermission(permissionEdit) && (
                <Link
                    to={editLink || '/'}
                    className="btn btn-action s-circle mr-2"
                >
                  <i className="icon icon-edit" />
                </Link>
            )}
            {deleteButton && (
                checkPermission(permissionRemove) && (
                    <button
                        type="button"
                        className={`btn btn-action s-circle ${s.btn_danger}`}
                        onClick={removeHandler}
                    >
                      <i className="icon icon-delete" />
                    </button>
                )
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default TableListViewRow;
