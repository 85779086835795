import React, {useEffect, useMemo, useState} from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import sSelect from "../../OrderEditView/fakeData/orderStyle.module.scss";
import Button from "../../../components/ui/Button";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import { getDomains } from "../../../lib/api/domains";
import errorMessage from "../../../lib/errorMessage";
import {createCategoriesMenu, editCategoriesMenu, getCategoriesMenuEdit} from "../../../lib/api/categories_menu";
import {getCatTree} from "../../../lib/api/categories";

const ModalCategoriesMenu = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);
    const [dataSelect, setDataSelect] = useState(null);

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            view_type: 1,
            position: 1,
            domain_id: 1
        }
    });

    const {
        register,
        errors,
        watch,
        handleSubmit,
        setValue
    } = formH;
    const domainIdWatch = watch('domain_id');

    const submitHandler = async (data) => {
        if (editId) {
            await editCategoriesMenu(editId, data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        } else {
            await createCategoriesMenu(data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        }
    }

    const filterCategory = useMemo(() => {
        const parseCategoriesAction = [];
        if (domainIdWatch && dataSelect?.category) {

            const parseCategoriesActionNodes = (nodes) => {

                if (!!nodes.children?.length) {
                    nodes.children.map((node) => parseCategoriesActionNodes(node))
                }

                return parseCategoriesAction.push({
                    id: nodes.id,
                    title: nodes.title
                });
            }

            const findChooseDomainCat = dataSelect?.category?.findIndex(item => item?.domain_id?.toString() === domainIdWatch?.toString())

            if (findChooseDomainCat !== -1) {
                parseCategoriesActionNodes(dataSelect?.category[findChooseDomainCat])
            }
        }
        return parseCategoriesAction
    }, [domainIdWatch, dataSelect?.category])

    useEffect(() => {
        const getFiltersData = () => Promise.allSettled([getDomains(), getCatTree()])
            .then((res) => setDataSelect({
                domains: res[0]?.value?.data,
                category: res[1]?.value?.data,
            }));

        getFiltersData()
    }, []);

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getCategoriesMenuEdit(editId).then((res) => {
                setValue('view_type', res.data.view_type);
                setValue('position', res.data.position);
                setValue('domain_id', res.data.domain_id);
                setValue('category_id', res.data.category_id);
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Нове меню категорій'}</div>
                        </div>
                    </div>
                    <div>
                        {/* view_type */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <div className={sSelect.select__inner}>
                                    <div className={sSelect.select__wrapper}>
                                        <select
                                            name="view_type"
                                            className={cn(sSelect.select, {
                                                'is-error': errors.view_type,
                                            })}
                                            id="cm-view-type"
                                            ref={register({
                                                required: VALIDATION.select.required,
                                            })}
                                        >
                                            <option value={1}>Списком</option>
                                            <option value={2}>Плиткою</option>
                                        </select>
                                        <label className={sSelect.label} htmlFor="cm-view-type">Тип</label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>

                        {/* position */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Позиція"
                                    id="cm-position"
                                    errorComponent={errors?.position &&
                                    <p className="form-input-hint">{errors?.position?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.position,
                                        })}
                                        type="number"
                                        placeholder="Позиція"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="position"
                                        id="cm-position"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* domain_id */}
                        {!!dataSelect?.domains?.length && (
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull>
                                    <div className={sSelect.select__inner}>
                                        <div className={sSelect.select__wrapper}>
                                            <select
                                                name="domain_id"
                                                className={cn(sSelect.select, {
                                                    'is-error': errors.domain_id,
                                                })}
                                                id="cm-domain"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {dataSelect?.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={sSelect.label} htmlFor="cm-domain">Домен</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                        )}

                        {/* category_id */}
                        {!!filterCategory?.length && (
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull>
                                    <div className={sSelect.select__inner}>
                                        <div className={sSelect.select__wrapper}>
                                            <select
                                                name="category_id"
                                                className={cn(sSelect.select, {
                                                    'is-error': errors.category_id,
                                                })}
                                                id="cm-category-id"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {filterCategory.map((cat) => (
                                                    <option
                                                        key={cat.id}
                                                        value={cat.id}
                                                    >
                                                        {cat.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={sSelect.label} htmlFor="cm-category-id">Вибрати категорію</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                        )}
                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__group}>
                            <div className={s_Modal.modal__buttons}>
                                <div>
                                    <Button purple type='submit' disabled={load}>Додати</Button>
                                </div>
                            </div>

                            <div>
                                <Button border onClick={() => setActive(false)}>Скасувати</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalCategoriesMenu;