import { useEffect } from 'react';
import Sortable from 'sortablejs';

const useSortable = ({ ref, settings = {} }) => {
  useEffect(() => {
    const instSort = new Sortable(ref.current, {
      animation: 150,
      ...settings,
    });

    return () => instSort.destroy();
  }, [ref]);
};

export default useSortable;
