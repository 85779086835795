import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData } from "./api";
import { remove } from "../SpecOfferList/api";
import getTZtoDate from "../../lib/getTZtoDate";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";

const EasyProList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Easy pro list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}

            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={[
                        'ID', "Ім'я", 'Телефон', 'Пристрій', 'Магазин', 'Створено'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {},
                            {},
                            {},
                            {
                                type: 'select',
                                name: 'shop_id',
                                options: !!filtersData?.wareHouses?.length && filtersData.wareHouses,
                                optProps: ['id', 'title']
                            },
                            {}
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`easy-pro-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Форму Easy pro видалено',
                                    }}
                                    permissionRemove='admin.easy-pro.destroy'
                                    permissionEdit='admin.easy-pro.edit'
                                    controlsOff
                                >
                                    <td>
                                        <span>
                                            {e?.id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.device?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.shop?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={5}>Немає форм EasyPro</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default EasyProList;