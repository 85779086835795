import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const UnaccountedProductsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
      <div>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Unaccounted products list`} />
        {!load && (
            <TopPagination
                pagination={(
                    <>
                        {+rest?.total && (
                            <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.total}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )}
        <TableListViewTable>
          <TableHead
              arrayChildren={[ { title: 'ID', attr: { style: { width: '40px' } } }, 'Назва УКР', 'Назва РУС', { title: 'Код товару', attr: { style: { width: '100px' } } }, 'Статус наявності' ]}
              filterChildren={<TableListViewFiltersRow
                  urlParams={urlParams}
                  fields={[
                    { },
                    { type: 'text', name: 'title_ua', attr: { style: { width: '100%' } }, attrLabel: { style: { width: '100%' } } },
                    { type: 'text', name: 'title_ru', attr: { style: { width: '100%' } }, attrLabel: { style: { width: '100%' } } },
                    { },
                    { type: 'select', name: 'is_active', options: [
                            {
                                id: 0,
                                title: 'Недоступний',
                            },
                            {
                                id: 1,
                                title: 'Доступний',
                            },
                        ]
                    },
                    { },
                  ]}
                  onSubmit={pushUrlParams}
              />}
          />
          <tbody>
          {load
              ? (<TableListViewRowLoader cols={5} />)
              : (rest && (
                  rest?.data?.length
                      ? rest?.data?.map((e, i) => (
                          <TableListViewRow
                              id={e?.id}
                              key={`up-item-${e?.id}`}
                              remove={{
                                api: remove,
                                success: () => setRest(getSplicedData(i, rest)),
                                alert: 'Не підраховуваний товар видалено',
                              }}
                              editLink={`/app/unaccounted-product/update/${e?.id}`}
                              permissionRemove='admin.unaccounted-product.destroy'
                              permissionEdit='admin.unaccounted-product.edit'
                          >
                            <td>
                              {checkPermission('admin.unaccounted-product.edit')
                                  ? <Link to={`/app/unaccounted-product/update/${e.id}`}>{e.id}</Link>
                                  : <span>{e.id}</span>
                              }
                            </td>
                            <td>
                                  <span>
                                    {e?.title_ua || '---'}
                                  </span>
                            </td>
                            <td>
                                  <span>
                                    {e?.title_ru || '---'}
                                  </span>
                            </td>
                              <td>
                                  <span>
                                    {e?.code || '---'}
                                  </span>
                              </td>
                            <td>
                                  <span>
                                    {e.is_active ? <StatusCircle green text="Доступний для замовлення" /> : <StatusCircle red text="Недоступний для замовлення" />}
                                  </span>
                            </td>
                          </TableListViewRow>
                      ))
                      : (
                          <TableListViewRow disabled>
                            <td colSpan={5}>Немає не підраховуваних товарів</td>
                          </TableListViewRow>
                      )
              ))}
          </tbody>
        </TableListViewTable>
      </div>
  );
};

export default UnaccountedProductsList;
