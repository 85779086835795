import React from "react";
import s from "../Newest.module.scss";
import icon_newsest from "../../../assets/icons/icon_newsest.svg";
import icon_one_year from "../../../assets/icons/icon_one_year.svg";
import icon_check_green from "../../../assets/icons/icon_check_green.svg";
import icon_mono from "../../../assets/icons/icon_mono.svg";
import icon_privatbank from "../../../assets/icons/icon_privatbank.svg";
import icon_heart from "../../../assets/icons/icon_heart.svg";
import icon_comparison from "../../../assets/icons/icon_comparison.svg";

const ShowBlock = ({ coverImages, data }) => {
    return (
        <div className={s.show}>
            <div className={s.show__top}>
                <ul className={s.markers}>
                    <li className={s.markers__item}>
                        <img src={icon_newsest} alt="Icon newest" />
                    </li>
                    <li className={s.markers__item}>
                        <img src={icon_one_year} alt="Icon on year" />
                    </li>
                </ul>
                <div className={s.show__img}>
                    <img src={coverImages[0]?.conversions?.original} alt="" />
                </div>
                <ul className={s.colors}>
                    <li>
                        <div className={`${s.colors__item} ${s.colors__item_pink}`} />
                    </li>
                    <li className={s.colors__active}>
                        <div className={`${s.colors__item} ${s.colors__item_black}`} />
                    </li>
                    <li>
                        <div className={`${s.colors__item} ${s.colors__item_white}`} />
                    </li>
                </ul>
            </div>
            <div>
                <div className={s.show__title}>{data && data?.fields?.product?.title_ru}</div>
                <div className={s.show__jde}>
                    <div className={s.show__jde_green}>
                        <img src={icon_check_green} alt="Icon check green" />
                        <span>B наявності</span>
                    </div>
                    <div>Код: {data && data?.fields?.product?.jde}</div>
                </div>
                <div className={s.show__price}>
                    XX XXX
                    <span className={s.show__price_small}> грн</span>
                </div>
                <div className={s.show__credit}>
                    <div>
                        <img src={icon_mono} alt="Icon mono" />
                        <span>х XX міс</span>
                    </div>
                    <div>
                        <img src={icon_privatbank} alt="Icon privat bank" />
                        <span>х XX міс</span>
                    </div>
                </div>
                <div className={s.show__footer}>
                    <div className={s.show__btn}>Купити</div>
                    <div>
                        <img src={icon_heart} alt="Icon heart"/>
                    </div>
                    <div>
                        <img src={icon_comparison} alt="Icon comparison" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowBlock;