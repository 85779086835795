import React from "react";
import s from './orderItemsContainer.module.scss'

const ItemsContainer = ({ children }) => (
  <div className={s.order_items_container} >
    {children}
  </div>
)

export default ItemsContainer
