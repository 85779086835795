import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";

const ReactSelectSearch = ({
                               placeholder,
                               name,
                               id,
                               formH,
                               formProps,
                               api,
                               minLength,
                               onChangeDeliverObj,
                               params,
                               paramTitle = 'title',
                               errorEndpointMessage,
                               setLoad
}) => {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const handleInputChange = value => {
        setValue(value);
    };

    const handleChange = value => {
        setSelectedValue(value);
    };

    const fetchData = async (initialValue, defaultValue = false) => {
        if(!!minLength) {

            if(initialValue.length >= minLength) {
                setIsLoading(true);

                if (setLoad) {
                    setLoad(true);
                }

                return await api({
                    ...params,
                    [paramTitle]: initialValue,
                })
                    .then((res) => {
                        if(defaultValue === true) {
                            setSelectedValue({
                                id: res.data[0].id,
                                title: res.data[0].title || res.data[0].title_ru || res.data[0].title_ua,
                            });
                        }
                        return res.data;
                    })
                    .catch(async (err) => {
                        if(err?.response?.status === 401) {
                            return await refreshToken(async () => {
                                return await api({
                                    ...params,
                                    [paramTitle]: initialValue,
                                })
                                    .then((res) => {
                                        if(defaultValue === true) {
                                            setSelectedValue({
                                                id: res.data[0].id,
                                                title: res.data[0].title || res.data[0].title_ru || res.data[0].title_ua,
                                            });
                                        }
                                        return res.data;
                                    })
                            })
                        } else {
                            await errorMessage(err, errorEndpointMessage)
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);

                        if (setLoad) {
                            setLoad(false);
                        }
                    });
            }
        } else {
            setIsLoading(true);

            if (setLoad) {
                setLoad(true);
            }

            return await api({
                ...params,
                [paramTitle]: initialValue,
            })
                .then((res) => {
                    if(defaultValue === true) {
                        setSelectedValue({
                            id: res.data[0].id,
                            title: res.data[0].title || res.data[0].title_ru || res.data[0].title_ua,
                        });
                    }
                    return res.data;
                })
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        return await refreshToken(async () => {
                            return await api({
                                ...params,
                                [paramTitle]: initialValue,
                            })
                                .then((res) => {
                                    if(defaultValue === true) {
                                        setSelectedValue({
                                            id: res.data[0].id,
                                            title: res.data[0].title || res.data[0].title_ru || res.data[0].title_ua,
                                        });
                                    }
                                    return res.data;
                                })
                        })
                    } else {
                        await errorMessage(err, errorEndpointMessage)
                    }
                })
                .finally(() => {
                    setIsLoading(false);

                    if (setLoad) {
                        setLoad(false);
                    }
                });
        }


    }

    useEffect(() => {
        if(formProps?.data?.fields?.delivery?.address?.street) {
            fetchData(formProps?.data?.fields?.delivery?.address?.street, true);
        }
    }, []);

    return (
        <>
            <input
                type="hidden"
                value={selectedValue?.title}
                name={name}
                id={id}
                ref={formH.register}

            />
            <AsyncSelect
                loadOptions={fetchData}
                isLoading={isLoading}
                onInputChange={handleInputChange}
                onChange={(e) => {
                    onChangeDeliverObj && onChangeDeliverObj('street', e?.title ? e.title : e, 'address', formProps?.data?.fields?.delivery?.id, formProps?.data?.fields?.delivery?.address?.id)
                    handleChange(e);
                }}
                getOptionLabel={e => e.title || e.title_ru || e.title_ua}
                getOptionValue={e => e.id}
                value={selectedValue}
                placeholder={placeholder}
                classNamePrefix="react-select"
                cacheOptions
                defaultOptions
                isClearable
            />

        </>
    )
}

export default ReactSelectSearch;