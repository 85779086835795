import React, { useCallback, useEffect, useState } from 'react';
import ColLeft from '../../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../../components/ui/FormEditView/ColRight';
import Group from '../../../../components/ui/FormEditView/Group';
import SelectInput from '../../../../components/ui/SelectInput';
import { getProduct } from '../../../../lib/api/products';
import TableItems from '../../../../components/ui/TableItems';
import SelectLocalInput from '../../../../components/ui/SelectLocalInput';
import {VALIDATION} from "../../../../const/text";
import cn from "classnames";
import removeDuplicates from "../../../../lib/helpers/removeDuplicates";

const TypeOne = ({
  list, state, formH,
}) => {
  const { typeID } = state;

  const [discountType, setDiscountType] = useState(null);
  const [selectValue, setSelectValue] = useState('');
  const [bonusTypeID, setBonusTypeID] = useState(null);

  const [productsAList, setProductsAList] = useState([]);
  const [productsAMarkerFilter, setProductsAMarkerFilter] = useState([]);
  const [productsACategoriesFilter, setProductsACategoriesFilter] = useState([]);
  const [productsAJDEFilter, setProductsAJDEFilter] = useState([]);

  useEffect(() => {
    if (state?.products) {
      setProductsAList([...state?.products]);
      setProductsAJDEFilter([...state?.products]);
    }
  }, [state?.products]);

  const parseDataA = useCallback(() => {
    const mrk = [...productsAMarkerFilter];
    const ctg = [...productsACategoriesFilter];
    const jde = [...productsAJDEFilter];

    const parseJde = removeDuplicates(jde);

    const totArr = [...mrk, ...ctg, ...parseJde];
    setProductsAList(totArr);
  }, [productsAMarkerFilter, productsACategoriesFilter, productsAJDEFilter]);

  const removeAItem = (e, el) => {
    e.preventDefault();
    const temp = [...productsAList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsAList(temp);
    }
  };

  useEffect(() => {
    if (state?.bonus?.bonus_type3?.discount_percent) {
      setDiscountType('1');
    }
    if (state?.bonus?.bonus_type3?.discount_value) {
      setDiscountType('2');
    }
  }, [state]);

   useEffect(() => {
    if (discountType){
      setSelectValue(discountType)
    }
  }, [discountType]);

  useEffect(() => {
    setProductsAList([]);
    parseDataA();
  }, [parseDataA]);

  const domainWatch = formH.watch('domain_id');
  const categoriesList = list.categories.filter(item => item.domain_id.toString() === domainWatch);

  /* eslint-disable */
  return (
    <>
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Домен:</label>
        </ColLeft>
        <ColRight>
          <select
            name="domain_id"
            className="form-select"
            id="so-domain"
            ref={formH.register()}
          >
            { list.domains.map((domain) => domain.title === 'samsungshop'
                && (
                    <option
                        key={`so-domain-${domain.id}`}
                        value={domain.id}
                    >
                      {domain.title}
                    </option>
                ))}
          </select>
        </ColRight>
      </Group>

      <div className="divider text-center" data-content="Выбрать товары , которые принимают участие в специальном предложении" />
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-markers">Выбрать по маркеру:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="markers"
            name="markers"
            list={list.markers}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAMarkerFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-categories">Выбрать по категории:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="categories"
            name="categories"
            list={categoriesList[0]?.children}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsACategoriesFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-jde">Выбрать по JDE:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="value"
            title="jde[]"
            name="jde[]"
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAJDEFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      {productsAList && productsAList.length !== 0 && (
        <TableItems
          formH={formH}
          list={productsAList}
          title="Выбраные товары"
          name={`special_offer_type${typeID}_product`}
          thNames={['JDE код', 'Название', '']}
          func={{
            remove: removeAItem,
          }}
        />
      ) }

      <div className="divider text-center" data-content="Выбрать товары , которые являются бонусом акции" />

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Тип бонуса:</label>
        </ColLeft>
        <ColRight>
          <select
            name="special_offer_type1_bonus.bonus_type"
            className="form-select"
            id="so-domain"
            onChange={(e) => setBonusTypeID(e.target.value)}
            defaultValue={bonusTypeID}
            ref={formH.register()}
          >
            <option value="">Выберите тип бонуса</option>
            { list?.bonus_types.map((type, i) => (
              <option
                key={`so-type-${i}`}
                value={type.id}
              >
                {type.title}
              </option>
            ))}
          </select>
        </ColRight>
      </Group>

      {(bonusTypeID ? bonusTypeID.toString() === '1' : formH.control.defaultValuesRef.current.special_offer_type1_bonus?.bonus_type === 1)
         && (
           <Group>
             <ColLeft>
               <label className="form-label" htmlFor="so-unaccounted_products">Не подучетные товары:</label>
             </ColLeft>
             <ColRight>
               <SelectLocalInput
                 name="special_offer_type1_bonus_type1"
                 list={list && list?.unaccounted_products}
                 formH={formH}
                 defVal={state?.unaccounted_products || list.defaultValues.special_offer_type1_bonus?.bonus_type1}
                 req
               />
             </ColRight>
           </Group>
         )}

      { (bonusTypeID ? bonusTypeID.toString() === '2' : formH.control.defaultValuesRef.current.special_offer_type1_bonus?.bonus_type === 2)
        && (
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="so-additional_services">Дополнительные сервисы:</label>
            </ColLeft>
            <ColRight>
              <SelectLocalInput
                name="special_offer_type1_bonus_type2"
                list={list && list?.additional_services}
                formH={formH}
                defVal={state?.additional_services || list.defaultValues.special_offer_type1_bonus?.bonus_type2}
                req
              />
            </ColRight>
          </Group>
        )}

      {(bonusTypeID ? bonusTypeID.toString() === '3' : formH.control.defaultValuesRef.current.special_offer_type1_bonus?.bonus_type === 3) && (
        <>
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="so-name-ru">Размер скидки:</label>
            </ColLeft>
            <ColRight>
              <>
                <select onChange={(e) => setSelectValue(e.target.value)} className="form-select" value={selectValue}>
                  <option value="">Выберите скидку</option>
                  <option value="1">Процентная скидка</option>
                  <option value="2">Абсолютная скидка</option>
                </select>
                {(selectValue.toString() === '1')
                && (
                    <>
                      <input
                          type="number"
                          ref={formH.register({
                            required: VALIDATION.req.required,
                          })}
                          className={cn('form-input', {
                            'is-error': formH.errors.special_offer_type1_bonus_type3?.discount_percent,
                          })}
                          placeholder="Введите процентную скидку"
                          name={`special_offer_type${typeID}_bonus_type3.discount_percent`}
                          defaultValue={state?.bonus?.bonus_type3?.discount_percent}
                      />
                      {formH.errors.special_offer_type1_bonus_type3?.discount_percent && <p className="form-input-hint">{formH.errors.special_offer_type1_bonus_type3.discount_percent.message}</p>}
                    </>
                )}

                {(selectValue.toString() === '2')
                && (
                    <>
                      <input
                          type="number"
                          ref={formH.register({
                            required: VALIDATION.req.required,
                          })}
                          className={cn('form-input', {
                            'is-error': formH.errors.special_offer_type1_bonus_type3?.discount_value,
                          })}
                          placeholder="Введите абсолютную скидку"
                          name={`special_offer_type${typeID}_bonus_type3.discount_value`}
                          defaultValue={state?.bonus?.bonus_type3?.discount_value}
                      />
                      {formH.errors.special_offer_type1_bonus_type3?.discount_value && <p className="form-input-hint">{formH.errors.special_offer_type1_bonus_type3.discount_value.message}</p>}
                </>
                )}
              </>
            </ColRight>
          </Group>
        </>
      )}
    </>
  );
};

export default TypeOne;
