import React, { useEffect, useRef, useState } from "react";
import s from "./InputFetchResult.module.scss";
import cn from "classnames";
import {VALIDATION} from "../../../const/text";
import s_Input from "../InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import InputWrapper from "../InputWrapper";
import { refreshToken } from "../../../lib/crud/refreshToken";
import errorMessage from "../../../lib/errorMessage";

// @todo: isolate from react-hook-form
const InputFetchResult = ({
                            name,
                            form,
                            fields,
                            func,
                            callback,
                            callbackWithRowId,
                            searchParam,
                            defVal,
                            placeholder,
                            sendJDE = false,
                            required = false,
                            params,
                            classNameObj,
                            id,
                            setProductId,
                            setProductJDE,
                            perPage = 30,
                            message = 'Не знайдено',
                            clearValue
                          }) => {
    const [page, setPage] = useState(2);
    const [isLastPage, setIsLastPage] = useState(false);
    const [value, setValue] = useState({
        id: null,
        title: '',
    });

    const [open, setOpen] = useState(false);
    const [result, setResult] = useState([]);
    const ref = useRef(null);

    useEffect(() => {
        setValue({
            id: sendJDE ? defVal?.jde : defVal?.id,
            title: defVal?.title_ua || defVal?.title_ru || defVal?.title || defVal?.name,
        });
    }, [defVal]);

    useEffect(() => {
        if (clearValue && defVal?.title === null) {
            setValue({
                id: null,
                title: ''
            });
        }

    }, [defVal?.title, clearValue]);

    useEffect(() => {
        if (setProductId && value?.id) {
            setProductId(value.id);
        }
    }, [value]);

    const handleChange = async (e) => {
        if (!e?.target?.value?.length && setProductJDE) {
            setProductJDE('');
        }

        await func({
            [searchParam.toString()]: e?.target?.value,
            per_page: perPage,
            ...params,
        }).then((res) => {
            setPage(2);
            setIsLastPage(false);
            if (e?.target?.value.length !== 0) {
                setOpen(true);
                setResult(res?.data?.data);
            } else {
                setOpen(false);
                setResult([]);
            }
        }).catch(async (err) => {
            if (err?.response?.status === 401) {
                await refreshToken(async () => {
                    await func({
                        [searchParam.toString()]: ref?.current?.value,
                        per_page: perPage,
                        page: page
                    }).then((res) => {
                        if (ref?.current?.value?.length !== 0) {
                            setOpen(true);
                            if (!!res?.data?.data?.length) {
                                setResult(prev => [...prev, ...res?.data?.data]);
                                setPage(prev => prev + 1);
                            } else {
                                setIsLastPage(true);
                            }
                        } else {
                            setOpen(false);
                            setResult([]);
                        }
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
    };

    const loadMore = async (e) => {
        const scrollTopMax = e?.target.scrollHeight - (e?.target.scrollTop - 2) <= e?.target.offsetHeight;

        if (scrollTopMax && !isLastPage) {
            await func({
                [searchParam.toString()]: ref?.current?.value,
                per_page: perPage,
                page: page
            }).then((res) => {
                if (ref?.current?.value?.length !== 0) {
                    setOpen(true);
                    if (!!res?.data?.data?.length) {
                        setResult(prev => [...prev, ...res?.data?.data]);
                        setPage(prev => prev + 1);
                    } else {
                        setIsLastPage(true);
                    }
                } else {
                    setOpen(false);
                    setResult([]);
                }
            }).catch(async (err) => {
                if (err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await func({
                            [searchParam.toString()]: ref?.current?.value,
                            per_page: perPage,
                            page: page
                        }).then((res) => {
                            if (ref?.current?.value?.length !== 0) {
                                setOpen(true);
                                if (!!res?.data?.data?.length) {
                                    setResult(prev => [...prev, ...res?.data?.data]);
                                    setPage(prev => prev + 1);
                                } else {
                                    setIsLastPage(true);
                                }
                            } else {
                                setOpen(false);
                                setResult([]);
                            }
                        })
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
        }
    };

    const handleClick = (el) => {
        setOpen(false);
        if (callback) {
            if (callbackWithRowId) {
                const parrent = name.split("]")[0];
                const id = parrent.split("[")[1];
                callback(id, el);
            } else callback(el)
        }

        if (setProductJDE) {
            setProductJDE(el.jde);
        }

        return setValue({
            id: fields ? el[fields.id] : (sendJDE ? el.jde : el.id),
            title: fields ? el[fields.name] : (el?.title_ua || el?.title_ru || el?.title || el.tf_bank_product_name),
        });
    };

    const renderResults = result && result.length !== 0 ? result.map((el) => (
        <div
            role="button"
            tabIndex={0}
            className={s.inputFetchResult__option}
            key={fields ? el[fields.id] : el.id}
            onKeyDown={() => false}
            onClick={() => handleClick(el)}
        >
            {fields ? el[fields.name] : (el?.title_ua || el?.title_ru || el.title || el.tf_bank_product_name)}
        </div>
    )) : <div>{message}</div>;
    return (
        <div className={s.inputFetchResult__wrap}>
            <InputWrapper
                label={placeholder}
                id={id}
                style={{ margin: 0 }}
                errorComponent={form?.errors[name] &&
                <p className="form-input-hint">{form?.errors[name]?.message}</p>}
            >
                <input
                    className={cn(s_Input.input, {
                        [s_Input.input_error]: form?.errors[name],
                    })}
                    type="text"
                    placeholder={placeholder}
                    maxLength={INPUT_MAX_LENGTH}
                    id={id}
                    onChange={handleChange}
                    value={value.title !== '' ? value.title : ''}
                    onInput={(e) => setValue({id: null, title: e?.target.value})}
                />
            </InputWrapper>
            <input
                type="text"
                name={name}
                ref={required
                    ? form.register({required: VALIDATION.req.required})
                    : form.register}
                value={value.id}
                style={{display: 'none'}}/>
            {open ? (
                <div className={`${s.inputFetchResult__container} ${classNameObj ? classNameObj.input_mt : ''}`}
                     onScroll={loadMore}>
                    {renderResults}
                </div>
            ) : null}
        </div>
    );
};

export default InputFetchResult;
