import React from "react";
import s from './orderItem.module.scss'
import Header from "./Header";

const Item = ({ children, title }) => (
  <div className={s.order_item}>
    <Header>{title}</Header>
    <div className={s.order_item_main}>{children}</div>
  </div>
)

export default Item
