// @todo
/* eslint-disable */
export const SPEC_OFFER_TYPES = [
  {
    id: 1,
    name: 'Акционные товары',
  },
  {
    id: 2,
    name: 'Купи А - получи Б в подарок',
  },
  {
    id: 3,
    name: 'Купи А - получи Б со скидкой',
  },
  {
    id: 4,
    name: 'Купи А+Б - получи скидку на комплект',
  },
  {
    id: 5,
    name: 'Купи А - получи Б по промокоду',
  },
  {
    id: 6,
    name: 'Купи А - получи Б и В в подарок',
  },
  {
    id: 7,
    name: 'Скидка по промокоду',
  },
  {
    id: 8,
    name: 'Акция с бонусами',
  },
  {
    id: 9,
    name: 'Купи А или Б - получи В или Г в подарок',
  },
];

export const getSpecOfferType = (type) => SPEC_OFFER_TYPES.find((el) => type.toString() === el.id?.toString());
