import React from 'react';
import s from './Footer.module.scss';

const TableListViewFooter = ({
  left,
  right,
  leftCn = '',
  rightCn = '',
}) => (
  <div className={s.wrap}>
    <div className="columns">
      <div className={`column ${s.col} ${leftCn}`}>
        {left}
      </div>
      <div className={`column ${s.col} ${rightCn}`}>
        {right}
      </div>
    </div>
  </div>
);

export default TableListViewFooter;
