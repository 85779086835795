export default (editor, opts = {}) => {
    const bm = editor.BlockManager

    const style = `
        <style>
            .domain-id-custom > div {
                min-height: 100px;
            }
            
        </style>
    `

    bm.add(opts.name, {
        label: `
        <div class="gjs-block-label">
            ${opts.label}
        </div>
    `,
        category: opts.category,
        content: `
<div class="domain-id-custom" data-domain-id="1">
<div class="col"></div>
</div>
${style}
    `
    })
}
