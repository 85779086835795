import {
  MARKER_GET,
  MARKER_CREATE,
  MARKER_DELETE,
  MARKER_GET_EDIT,
  MARKER_EDIT, MARKER_GET_ALL, MARKER_TYPES_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getMarkers = (params) => fetchAx({
  url: MARKER_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getMarkersAll = () => fetchAx({
  url: MARKER_GET_ALL,
  method: 'GET',
});

export const createMarker = (data) => fetchAx({
  url: MARKER_CREATE,
  method: 'POST',
  data
});

export const getMarkerEdit = (id) => fetchAx({
  url: MARKER_GET_EDIT.join(id),
  method: 'GET',
});

export const editMarker = (id, data) => fetchAx({
  url: MARKER_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeMarker = (id) => fetchAx({
  url: MARKER_DELETE.join(id),
  method: 'DELETE',
});

export const getMarkerTypes = (params) => fetchAx({
  url: MARKER_TYPES_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});
