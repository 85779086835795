import React from 'react';
import { Link } from "react-router-dom";
import Item from "../Item";
import getTZtoDate from "../../../lib/getTZtoDate";
import s from "../../OrderEditView/fakeData/orderStyle.module.scss";

const OrderPreviousOrders = ({ formProps }) => {
    const dataFields = formProps.data && formProps.data.fields;

    return (
        <Item title={`Предыдущие заказы (${dataFields?.prev_orders?.length ? dataFields?.prev_orders?.length : '0'})`}>
            <ul className={s.prevOrder}>
                {dataFields?.prev_orders && dataFields?.prev_orders.map((order, index) => (
                    <li className={s.prevOrder__item} key={index}>
                        <div className={s.prevOrder__block}>
                            <div className={s.prevOrder__box}><Link to={`/app/order/update/${order.id}`}>№{order.id}</Link> от {getTZtoDate(order.created_at)}</div>
                            <div className={s.prevOrder__status}>
                                {formProps?.data?.statuses?.find(item => item?.id === order?.status)?.name}
                            </div>
                        </div>
                        {order?.carts && order.carts.map((cart, ind) => (
                            <div className={s.prevOrder__block} key={ind}>
                                <div className={s.prevOrder__box_info}>{cart.product.title_ru}</div>
                                <div className={s.prevOrder__box_price}>
                                    <div>{cart.quantity} шт.</div>
                                    <div>{cart.price_final}</div>
                                </div>
                            </div>
                        ))}
                    </li>
                ))}
            </ul>
        </Item>
    );
};

export default OrderPreviousOrders;