import {editSortProducts, getSortProducts, removeSortProduct} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";
import { getProductStates } from "../../lib/api/products";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getCatTree(), getProductStates()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        categories: res[1]?.value?.data,
        product_states: res[2]?.value?.data
    }));

export const edit = editSortProducts;
export const getData = getSortProducts;
export const remove = removeSortProduct;
