export const ERROR_RESP = 'Sorry, request failed... \n Please reload page and try again';
export const WONT_REMOVE = 'Вы действительно хотите удалить ?';
export const ERROR_FILE_INVALID = 'Допустимый формат файла: ';
export const ERROR_FILE_SIZE_INVALID = 'Допустимый размер файла: %n';

// validation
const requiredField = 'Поле не повинно бути порожнім';

export const VALIDATION = {
  req: {
    required: requiredField,
  },
  select: {
    required: 'Выберите одно из значений',
  },
  pass: {
    required: requiredField,
    minLength: 'Пароль должен состоять не менее чем из 6 символов',
    // minLength: 'Пароль %test должен состоять не менее чем из %n символов'
  },
};
