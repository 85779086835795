import React from 'react';

import Wrapper from './Wrapper';

const LoadingIndicator = () => (
  <Wrapper>
    <div className="loading loading-lg" />
  </Wrapper>
);

export default LoadingIndicator;
