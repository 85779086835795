/**
 * Each array of objects recursively (range array)
 * 	- indexArr - array of last nesting indexes
 * 	- depth - depth of nesting array
 *
 * @param arr
 * @param propCh - each object prop which contains of children
 * @param cb
 */
// @todo: optimize
/* eslint-disable */
const rangeArrObjs = (arr, propCh, cb) => {
  const res = [];

  const eachArr = (e, i, indexArr, depth) => {
    const d = depth + 1;
    const iArr = [...indexArr];
    iArr[depth] = i;

    if (cb) res.push(cb(e, i, iArr, d));

    if (e[propCh]) {
      e[propCh].forEach((e, i) => {
        eachArr(e, i, iArr, d);
      });
    }
  };

  arr.forEach((e, i) => {
    eachArr(e, i, [], 0);
  });

  return res;
};

export default rangeArrObjs;
