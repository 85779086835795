import { Link } from 'react-router-dom';
import { uuid } from 'uuidv4';
import React from 'react';
import checkPermission from "../../../lib/checkPermission";
import s from "./TopNavTabs.module.scss";
import cn from "classnames";

const TopNavTabs = ({ tabs }) => (
    <ul className={s.tab}>
        {!!tabs.length && tabs.map((el) => {
            if(checkPermission(el.permissionName)) {
                return (
                    <li className={cn(s.tab__item, {
                        ['active']: el?.isActive,
                        [s.tab__line]: el?.isActive,
                    })} key={`${uuid()}`}>
                        <Link to={`${el.url}`}>{el.title}</Link>
                    </li>
                )
            }
            return null;
        })}
    </ul>
)

export default TopNavTabs;
