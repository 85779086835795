import React, { Fragment, useEffect, useState } from 'react';
import { uuid } from "uuidv4";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import icon_arrow from './../../assets/icons/icon_arrow-down-black.svg';
import icon_eye_green from './../../assets/icons/icon_eye_green_open.svg';
import icon_eye_red from './../../assets/icons/icon_eye_red_close.svg';
import icon_pencil_green from './../../assets/icons/icon_pencil_green.svg';
import icon_pencil_red from './../../assets/icons/icon_pencil_red.svg';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import ColFull from "../../components/ui/FormEditView/ColFull";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s_Main from "./Role.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import { VALIDATION } from "../../const/text";
import Divider from "@mui/material/Divider";
import errorMessage from "../../lib/errorMessage";
import { editPermissionWatched } from "../../lib/api/permissionsAdmin";
import {editSectionSwitchRole} from "../../lib/api/sections";

const findChildById = (children, id) => {
  for (let child of children) {
    if (child.id === id) {
      return true;
    }
    if (child.children) {
      const found = findChildById(child.children, id);
      if (found) {
        return true;
      }
    }
  }
  return false;
}

const ItemType = ({ type, choosePermissions, setChoosePermissions, toggleWatched }) => {
  const [openType, setOpenType] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (!!choosePermissions?.length) {
      const checkChoosePermission = [];

      if (!!type?.items?.length && !!choosePermissions?.length) {
        type?.items?.forEach(item => {
          if (choosePermissions.find(permission => permission?.id?.toString() === item?.id?.toString())) {
            checkChoosePermission.push(true)
          }
        })
      }

      setCheckAll(checkChoosePermission?.length === type?.items?.length)
    }
  }, [choosePermissions])

  return (
      <Fragment key={`permission-item-${type?.type}-${uuid()}`}>
        <div className={s_Main.role_item__bottom}>
          <div
              style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
              onClick={() => setOpenType(prev => !prev)}
          >
            <img
                style={{ transform: openType ? 'rotate(0)' : 'rotate(-90deg)' }}
                src={icon_arrow}
                alt="Arrow down"
            />
            <span>{type?.title || type?.type} {!!type?.items?.length && type?.items?.some(typeItem => !typeItem?.is_watched) && <sup style={{ color: '#34A853' }}>New</sup>}</span>
          </div>

          <CheckboxSwitch
              name={type.type}
              id={`permission-item-checkbox-${type?.type}-${uuid()}`}
              onClickFunc={async (check) => {
                setCheckAll(check);
                setChoosePermissions(prev => {
                  if (!!prev?.length) {
                    const temp = [...prev];

                    if (check) {
                      if (!!type?.items?.length) {
                        type?.items?.forEach(item => {
                          const findIndex = temp.findIndex(permission => permission?.id?.toString() === item?.id?.toString())
                          if (findIndex === -1) {
                            temp.push({ id: item?.id, name: item?.name });
                          }
                        })
                      }
                    } else {
                      if (!!type?.items?.length) {
                        type?.items?.forEach(item => {
                          const findIndex = temp.findIndex(permission => permission?.id?.toString() === item?.id?.toString())
                          if (findIndex !== -1) {
                            temp.splice(findIndex, 1);
                          }
                        })
                      }
                    }

                    return temp;
                  }

                  if (check) {
                    return !!type?.items?.length ? type?.items?.map(permission => ({id: permission?.id})) : []
                  }
                  return []
                })

                if (!!type?.items?.length) {
                  type?.items.map(async (typeItem) => {
                    if (!typeItem?.is_watched) {
                      await editPermissionWatched(typeItem?.id)?.then((res) => {
                        typeItem.is_watched = true;
                        toggleWatched(typeItem.id)
                      }).catch((err) => errorMessage(err, ''))
                    }
                  })
                }
              }}
              defaultChecked={checkAll}
          />
        </div>

        {openType && !!type?.items?.length && type?.items?.map(permission => {
          return (
              <div
                  key={`permission-item-${permission?.id}`}
                  className={s_Main.role_item__bottom}
                  style={{ padding: 6, margin: '0 10px 0 30px' }}
              >
                <span>{permission?.title}{!permission?.is_watched && <sup style={{ color: '#34A853' }}>New</sup>}</span>

                <CheckboxSwitch
                    name={permission.name}
                    id={`permission-item-checkbox-${permission?.id}`}
                    onClickFunc={async (checked) => {
                      setChoosePermissions(prev => {

                        if (!!prev?.length) {
                          const temp = [...prev];
                          const findIndexChoose = temp?.findIndex(item => item?.id?.toString() === permission?.id?.toString());

                          if (findIndexChoose !== -1) {
                            temp?.splice(findIndexChoose, 1)
                          } else {
                            temp.push({ id: permission?.id, name: permission?.name })
                          }

                          return temp;
                        }

                        if (checked) {
                          return [{ id: permission?.id, name: permission?.name }];
                        }

                        return [];
                      })

                      if (!permission?.is_watched) {
                        await editPermissionWatched(permission?.id)?.then((res) => {
                          permission.is_watched = true;
                          toggleWatched(permission.id)
                        }).catch((err) => errorMessage(err, ''))
                      }
                    }}
                    defaultChecked={!!choosePermissions?.length
                        ? choosePermissions?.some(item => item?.id?.toString() === permission?.id?.toString())
                        : false}
                />
              </div>
          )
        })}

        <Divider variant="middle" component="span" />
      </Fragment>
  )
}

const ItemFirstMenu = (props) => {
  const {
    itemMenu,
    choosePermissions,
    setChoosePermissions,
    setOpenMenuFirst,
    setOpenMenuId,
    openMenuId,
    rID,
    updateNodeRoles,
    toggleWatched
  } = props;

  let haveRole = !!itemMenu?.roles?.length && itemMenu?.roles?.some(role => role?.id?.toString() === rID?.toString())
  const [checkAll, setCheckAll] = useState(false);
  const [checkNew, setCheckNew] = useState(false);
  const findEditType = !!itemMenu?.typePermission?.length && itemMenu?.typePermission?.find(typePermission => typePermission.type === 'edit')

  useEffect(() => {
    if (!!choosePermissions?.length) {
      const checkChoosePermission = [];

      if (!!itemMenu?.typePermission?.length) {
        const findEditType = itemMenu?.typePermission?.findIndex(type => type.type === 'edit');
        if (findEditType !== -1) {
          itemMenu?.typePermission[findEditType]?.items?.forEach(item => {
            if (choosePermissions.find(permission => permission?.id?.toString() === item?.id?.toString())) {
              checkChoosePermission.push(true)
            }
          })
        }
        setCheckAll(checkChoosePermission?.length === itemMenu?.typePermission[findEditType]?.items?.length)
      }
    }
  }, [choosePermissions])

  useEffect(() => {
    if (!!itemMenu?.typePermission?.length) {
      let checkIsWatched = false;
      itemMenu?.typePermission.forEach(typePermission => {
        if (!!typePermission?.items?.length) {
          if (typePermission?.items.some(typeItem => !typeItem?.is_watched)) {
            checkIsWatched = true
          }
          return
        }
        return
      })
      setCheckNew(checkIsWatched)
    }
  }, [itemMenu])

  return (
      <div
          className={s_Main.role_item} key={`role-section-item-${itemMenu.id}`}
          style={{ height: itemMenu?.id === openMenuId ? 'auto' : '64px' }}
      >
        <div className={cn(s_Main.role_item__top, {
          [s_Main.active_block]: itemMenu?.id === openMenuId
        })} onClick={() => {
          setOpenMenuFirst(prev => !prev)
          setOpenMenuId(prevChoose => {
            if (itemMenu?.id !== prevChoose) {
              return itemMenu?.id
            }

            return null;
          });
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <img src={itemMenu?.cover?.original || ''} alt="" />
            <span>{itemMenu.title} {checkNew && <sup style={{ color: '#34A853' }}>New</sup>}</span>
          </div>

          <div className={s_Main.role_item__img_block}>
            <button type="button" onClick={async () => {
              await editSectionSwitchRole(itemMenu?.id, rID)
                  .then((res) => {
                    updateNodeRoles(itemMenu?.id, 'roles', res.data.roles)
                    haveRole = !!res.data.roles?.length && res.data.roles?.some(role => role?.id?.toString() === rID?.toString())
                  })
                  .catch((err) => errorMessage(err, ''))
            }}>
              <img src={haveRole ? icon_eye_green : icon_eye_red} alt="Icon eye green" />
            </button>

            <button type="button">
              {findEditType && <img src={checkAll ? icon_pencil_green : icon_pencil_red} alt="Icon pencil" />}
            </button>
          </div>
        </div>

        {itemMenu?.id === openMenuId && !!itemMenu?.typePermission?.length && itemMenu?.typePermission?.map((type) => {
          return <ItemType
              type={type}
              choosePermissions={choosePermissions}
              setChoosePermissions={setChoosePermissions}
              toggleWatched={toggleWatched}
          />
        })}
        {itemMenu?.id === openMenuId && !itemMenu?.typePermission?.length && !itemMenu?.children?.length && <div className={s_Main.role_item__bottom}><span>Немає пермішинів</span></div>}
      </div>
  )
}

const RoleEditView = () => {
  const { id: rID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: rID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/role/not-found',
      remove: `/app/roles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/roles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Роль створено',
      edit: 'Роль оновлено',
      remove: 'Роль видалено',
    },
  });

  const [sectionsList, setSectionsList] = useState([]);
  const [choosePermissions, setChoosePermissions] = useState([]);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [openMenuIdSecond, setOpenMenuIdSecond] = useState(null);
  const [openMenuIdThird, setOpenMenuIdThird] = useState(null);
  const [openMenuFirst, setOpenMenuFirst] = useState(false);
  const [openMenuSecond, setOpenMenuSecond] = useState(false);
  const [openMenuThird, setOpenMenuThird] = useState(false);

  useEffect(() => {
    if (!!data?.fields?.permissions?.length) {
      setChoosePermissions(data?.fields?.permissions);
    }
  }, [data?.fields?.permissions])

  useEffect(() => {
    if (!!data?.sections?.length) {
      setSectionsList(data?.sections);
    }
  }, [data?.sections])

  const updateNodeRoles = (nodeId, name, value) => {
    const updateNameRecursive = (nodes) => {
      return nodes.map(node => {
        if (node.id === nodeId) {
          return { ...node, [name]: value };
        } else if (node.children) {
          return { ...node, children: updateNameRecursive(node.children) };
        }
        return node;
      });
    };

    setSectionsList(updateNameRecursive(sectionsList));
  };

  const updatePermissions = (items, itemId) => {
    return items.map((item) => {
      const updatedItem = { ...item };

      if (!!updatedItem?.typePermission?.length) {
        updatedItem.typePermission = updatedItem.typePermission.map((permission) => {
          const updatedPermission = { ...permission };

          updatedPermission.items = updatedPermission.items.map((permItem) => {
            if (permItem.id === itemId) {
              return { ...permItem, is_watched: true };
            }
            return permItem;
          });
          return updatedPermission;
        });
      }

      if (!!updatedItem?.permissions?.length) {
        updatedItem.permissions = updatedItem.permissions.map((permission) => {
          if (permission.id === itemId) {
            return { ...permission, is_watched: true };
          }
          return permission;
        });
      }

      if (!!updatedItem.children?.length) {
        updatedItem.children = updatePermissions(item.children, itemId);
      }
      return updatedItem;
    });
  };

  const toggleWatched = (itemId) => {
    const updatedPermissions = updatePermissions(sectionsList, itemId);
    setSectionsList(updatedPermissions);
  };

  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Role edit`} />

      <Form attrs={{onSubmit: handleSubmit(submitHandler)}}>

        <button type="submit" className="d-hide" />

        <TopPanelChild
            title={rID ? data?.fields?.name && data?.fields?.name : 'Нова роль'}
            breadcrumbs={{
              items: [
                {
                  title: 'Головна',
                  url: '/app',
                  permissionName: 'admin.admin.roles.index'
                },
                {
                  title: 'Ролі',
                  url: `/app/roles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  permissionName: 'admin.admin.roles.index'
                },
                {
                  title: rID ? data?.fields?.name && data?.fields?.name : 'Нова роль'
                },
              ],
            }}
            back={`/app/roles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
            id={rID}
            btnEdit
        />

        <div className={s_Main.top}>
          {/* name */}
          <Group attrs={{ style: { margin: 0 } }}>
            <ColFull attrs={{ style: { padding: 0 } }}>
              <InputWrapper
                  label="Назва ролі"
                  id="role-name"
                  errorComponent={errors?.name &&
                  <p className="form-input-hint">{errors?.name?.message}</p>}
                  style={{ margin: 0 }}
              >
                <input
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH.errors?.name,
                    })}
                    type="text"
                    placeholder="Назва ролі"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={formH.register({
                      required: VALIDATION.req.required
                    })}
                    name="name"
                    id="role-name"
                    defaultValue={data?.fields?.name}
                />
              </InputWrapper>
            </ColFull>
          </Group>
          {!!sectionsList?.length && (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 32 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                  {sectionsList.map((itemMenu, indexItemMenu) => {
                    return <ItemFirstMenu
                        itemMenu={itemMenu}
                        index={indexItemMenu}
                        choosePermissions={choosePermissions}
                        setChoosePermissions={setChoosePermissions}
                        setOpenMenuId={setOpenMenuId}
                        openMenuId={openMenuId}
                        allSections={sectionsList}
                        setOpenMenuFirst={setOpenMenuFirst}
                        updateNodeRoles={updateNodeRoles}
                        toggleWatched={toggleWatched}
                        rID={rID}
                    />
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                  {sectionsList.map((itemMenu) => {
                    if(!!itemMenu?.children?.length) {
                      return itemMenu.id === openMenuId ? itemMenu?.children.map((item, index) => {
                        return <ItemFirstMenu
                            itemMenu={item}
                            index={index}
                            choosePermissions={choosePermissions}
                            setChoosePermissions={setChoosePermissions}
                            setOpenMenuId={setOpenMenuIdSecond}
                            openMenuId={openMenuIdSecond}
                            allSections={sectionsList}
                            setOpenMenuFirst={setOpenMenuSecond}
                            updateNodeRoles={updateNodeRoles}
                            toggleWatched={toggleWatched}
                            rID={rID}
                        />
                      }) : null
                    }
                    return null
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                  {sectionsList.map((itemMenu) => {
                    if(!!itemMenu?.children?.length) {
                      return (
                          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                            {(itemMenu.id === openMenuId) && itemMenu?.children.map((item) => {

                              if(!!item?.children?.length) {
                                return item.id === openMenuIdSecond ? item?.children.map((itemThird, indexItemThird) => {
                                  return <ItemFirstMenu
                                      itemMenu={itemThird}
                                      index={indexItemThird}
                                      choosePermissions={choosePermissions}
                                      setChoosePermissions={setChoosePermissions}
                                      setOpenMenuId={setOpenMenuIdThird}
                                      openMenuId={openMenuIdThird}
                                      allSections={sectionsList}
                                      setOpenMenuFirst={setOpenMenuThird}
                                      updateNodeRoles={updateNodeRoles}
                                      toggleWatched={toggleWatched}
                                      rID={rID}
                                  />
                                }) : null
                              }

                              return null;
                            })}
                          </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
          )}
        </div>

        {!!choosePermissions?.length && choosePermissions?.map((permission, index) => (
            <input type="hidden" name={`permissions[${index}].id`} value={permission.id} ref={register} />
        ))}

        {load && <Loader />}
      </Form>
    </>
  );
};

export default RoleEditView;
