import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  QF_CREATE, QF_DELETE,
  QF_EDIT,
  QF_GET,
  QF_GET_EDIT,
} from '../../const/api';

export const getQF = (params) => fetchAx({
  url: QF_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getQFAll = () => fetchAx({
  url: QF_GET,
  method: 'GET',
});

export const createQF = (data) => fetchAx({
  url: QF_CREATE,
  method: 'POST',
  data,
});

export const getQFEdit = (id) => fetchAx({
  url: QF_GET_EDIT.join(id),
  method: 'GET',
});

export const editQF = (id, data) => fetchAx({
  url: QF_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeQF = (id) => fetchAx({
  url: QF_DELETE.join(id),
  method: 'DELETE',
});
