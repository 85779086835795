export default (editor, opts = {}) => {
    const dc = editor.DomComponents;
    const defaultType = dc.getType("default");
    const defaultView = defaultType.view;

    dc.addType(opts.name, {
        model: {
            defaults: {
                traits: [
                    {
                        type: "select",
                        name: "progressType",
                        label: "Progress Type",
                        changeProp: 1,
                        options: [
                            { value: "bullets", name: "Bullets" },
                            { value: "fraction", name: "Fraction" },
                            { value: "progressbar", name: "Progressbar" },
                        ],
                    },
                ],
                script: function () {
                    const progressType = "{[ progressType ]}";

                    const initLib = function () {
                        const swiper = new Swiper(".mySwiper", {
                            spaceBetween: 30,
                            centerSlides: true,
                            loop: false,
                            autoplay: false,
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                                type: progressType,
                            },

                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                        });
                    }

                    if(typeof Swiper === 'undefined') {
                        const script = document.createElement("script");
                        script.onload = initLib;
                        script.src = 'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js';
                        document.body.appendChild(script);
                    } else {
                        initLib()
                    }
                },
            },
        },
        isComponent: (el) => {
            if(el.className && el.className.includes('swiper-container')) {
                return {
                    type: opts.name,
                };
            }
        },
        view: defaultView.extend({
            init({ model }) {
                this.listenTo(model, "change:progressType", this.updateScript);
            },
        }),
    });
};