/**
 * Get Mb to Bytes
 *
 * @param mb
 * @returns {number}
 */
/* eslint-disable */
const getMbToBytes = (mb) => mb * Math.pow(1024, 2).toFixed(1);

export default getMbToBytes;
