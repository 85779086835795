const removeLocalStorage = () => {
    localStorage.removeItem('tokenData');
    // localStorage.removeItem('roles');
    // localStorage.removeItem('permissions');
    // localStorage.removeItem('per_page');
    // localStorage.removeItem('userName');
    // localStorage.removeItem('userNameLogin');
    // localStorage.removeItem('userId');
};

export default removeLocalStorage;