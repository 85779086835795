import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import cn from "classnames";
import { VALIDATION } from "../../const/text";

import useEdit from "../../lib/crud/useEdit";
import Loader from "../../components/ui/Loader";
import Form from "../../components/ui/FormEditView/Form";
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from "./api";
import { getProduct } from "../../lib/api/products";
import InputFetchResult from "../../components/ui/InputFetchResultNew";
import { INPUT_MAX_LENGTH } from "../../const/view";
import GalleryImages from "../../components/ui/GalleryImages";
import { getMedia, removeMedia } from "../../lib/api/media";
import errorMessage from "../../lib/errorMessage";
import { ModalMedia } from "./Modal";
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import TopPanelNew from "../../components/common/TopPanelNew";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s from "./Newest.module.scss";
import ShowBlock from "./component/ShowBlock";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const NewestEditView = () => {
  const { id: newestID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const navigate = useNavigate();

  const { load, data, submitHandler } = useEdit({
    targetID: newestID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/newest/not-found',
      remove: `/app/main/newest${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/newest${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Новинка создана',
      edit: 'Новинка обновлена',
      remove: 'Новинка удалена',
    },
  });

  const [productId, setProductId] = useState('');

  const [typeCoverData, setTypeCoverData] = useState([]);
  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);

  const [dataModal, setDataModal] = useState({});
  const [coverImages, setCoverImages] = useState([]);

  useEffect(() => {
    if(data?.fields?.product_id) {
      setProductId(data?.fields?.product_id);
      getMedia({
        'entity_type': 'product',
        'entity_id': data?.fields?.product_id,
        page: 1,
        per_page: 500,
      }).then(res => {
        const filterCoverBlack = res?.data?.data?.filter(item => item?.collection_name === 'cover-black');
        const filterCover = res?.data?.data?.filter(item => item?.collection_name === 'cover');


        if(!!filterCoverBlack?.length) {
          setTypeCoverData(filterCoverBlack);
        }

        if(!!filterCover?.length) {
          setCoverImages(filterCover);
        }
      }).catch(async (err) => await errorMessage(err, '/api/admin/media GET'))
    }
  }, [data?.fields?.product_id]);

  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Newest edit`} />
      <TopPanelNew
          title={newestID ? `Новинка - ${data && data?.fields?.product?.title_ru}` : "Новая новинка"}
          breadcrumbs={{
            items: [
              {
                title: "Dashboard",
                url: "/app",
              },
              {
                title: "Популярный товар",
                url: `/app/main/newest${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              },
              {
                title: newestID ? `Новинка - ${data && data?.fields?.product?.title_ru}` : "Новая новинка",
              },
            ],
          }}
          back={`/app/main/newest${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          btnRemove={{
            api: remove,
            alert: `Удалить новинку “${data?.fields?.product?.title_ru}”?`,
            href: `/app/main/newest${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            navigate
          }}
          btnEdit
      />

      <div>
        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />
          <div className={s.wrap}>
            <div className={s.left}>
              {/* domain_id */}
              <div className={s.left__item}>
                <div className={s_Select.select__inner}>
                  <div className={s_Select.select__wrapper}>
                    {data
                        ? (
                            <select
                                name="domain_id"
                                className={cn(s_Select.select, {
                                  [s_Select.select_error]: formH.errors?.domain_id,
                                })}
                                id="new-domain"
                                ref={formH.register({
                                  required: VALIDATION.select.required,
                                })}
                            >
                              <option value="">выберите домен</option>
                              {data && !!data.domains && data.domains.map((domain) => (
                                  <option
                                      key={domain.id}
                                      value={domain.id}
                                      selected={domain.id === data?.fields?.domain_id}
                                  >
                                    {domain.title}
                                  </option>
                              ))}
                            </select>
                        )
                        : (
                            <div className="p-relative">
                              <div className="loading" />
                            </div>
                        )}
                    <label className={s_Select.label} htmlFor="new-domain">Домен*</label>
                  </div>
                  {formH.errors?.domain_id
                  && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                </div>
              </div>

              {/* position */}
              <div className={s.left__item}>
                <InputWrapper
                    label="Позиция*"
                    id="new-position"
                    errorComponent={formH.errors?.position &&
                    <p className="form-input-hint">{formH?.errors?.position?.message}</p>}
                >
                  <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH.errors?.position,
                      })}
                      type="text"
                      placeholder="Позиция*"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={formH.register({
                        required: VALIDATION.req.required
                      })}
                      name="position"
                      id="new-position"
                      defaultValue={data?.fields?.position}
                  />
                </InputWrapper>
              </div>

              {/* product_id */}
              <div className={s.left__item}>
                <InputFetchResult
                    name="product_id"
                    form={formH}
                    searchParam="jde[]"
                    func={getProduct}
                    defVal={data?.fields?.product}
                    id="new-product-id"
                    placeholder="Навание товара / JDE*"
                    setProductId={setProductId}
                    required
                />
              </div>

              {productId && (
                  <div className={s.left__item}>
                    <div className="text-center mt-2">
                      <p className="label">Титульные изображения</p>
                    </div>
                    <div>Максимальное разрешение картинки 1920x1080</div>
                    <GalleryImages
                        id="nt-images"
                        nameParent={`images`}
                        formH={formH}
                        accept="image/*"
                        acceptStr="PNG, JPG, GIF"
                        imageData={typeCoverData}
                        setImageData={setTypeCoverData}
                        setActiveMedia={setActiveMedia}
                        setActiveRemove={setActiveRemove}
                        setDataModal={setDataModal}
                        prodID={productId}
                        entityType='product'
                        collectionName='cover-black'
                    />

                    {activeMedia && <ModalMedia
                        active={activeMedia}
                        setActive={setActiveMedia}
                        data={dataModal}
                        collectionName={'cover-black'}
                        setImageData={setTypeCoverData}
                        title='Редактирование картинки'
                    />}
                    {activeRemove && <ModalRemove
                        active={activeRemove}
                        setActive={setActiveRemove}
                        data={dataModal}
                        funcRemove={removeMedia}
                        setRemoveItem={setTypeCoverData}
                        title='Удалить изображение'
                    />}
                  </div>
              )}
            </div>
            <ShowBlock data={data} coverImages={coverImages} />
          </div>
        </Form>
      </div>

      {load && <Loader />}
    </>
  );
};

export default NewestEditView;
