import Constants from "./constants";

export const setNoticeChange = (data) => ({
  type: Constants.NOTICE_CHANGE,
  payload: data,
});

export const setClearNotice = () => ({
  type: Constants.CLEAR_NOTICE,
  notice: []
});
