import {
  PERMISSIONS_CLIENT_DELETE,
  PERMISSIONS_CLIENT_GET,
} from '../../const/api';
import fetchAx from '../fetchAx';

export const getPermissionsClient = () => fetchAx({
  url: PERMISSIONS_CLIENT_GET,
  method: 'GET',
});

export const removePermissionsClient = (id) => fetchAx({
  url: PERMISSIONS_CLIENT_DELETE.join(id),
  method: 'DELETE',
});
