/**
 * Get array without removed item by index
 *
 * @param index
 * @param obj
 * @returns {Object}
 */
const getSplicedData = (index, obj) => {
  const d = [...obj.data];
  d.splice(index, 1);

  return {
    ...obj,
    data: [
      ...d,
    ],
  };
};

export default getSplicedData;
