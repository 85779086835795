import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VALIDATION } from '../../const/text';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
    getData, remove, edit, create, parseDataGet, parseDataSend,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const StatusChangeEditView = () => {
    const { id: statusChangeID } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: statusChangeID,
        // updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/status-change/not-found',
            remove: `/app/status-change${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/status-change${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Статус изменения создан',
            edit: 'Статус изменения обновлен',
            remove: 'Статус изменения удален',
        },
    });

    /* eslint-disable */
    return (
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Status change edit`} />
            <div className="box-wrap">
                {statusChangeID
                    ? (
                        <Title>
                            Статус -
                            <small>{data && data.fields.title_ru}</small>
                        </Title>
                    )
                    : <Title>Новый статус</Title>}

                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide" />

                    {/* role_id */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-role-id">Роль*:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name="role_id"
                                className="form-select"
                                id="so-role-id"
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            >
                                <option value="">выберите роль</option>
                                {data && data.roles.map((role) => <option value={role.id} selected={data?.fields?.role_id  === role.id}>{role.name}</option>)}
                            </select>
                            {errors.role_id && <p className="form-input-hint">{errors.role_id.message}</p>}
                        </ColRight>
                    </Group>

                    {/* status */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-status">Текущий статус*:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name="status"
                                className="form-select"
                                id="so-status"
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            >
                                <option value="">выберите текущий статус</option>
                                {data && data.statuses.map((status) => <option value={status.id} selected={data?.fields?.status  === status.id}>{status.name}</option>)}
                            </select>
                            {errors.status && <p className="form-input-hint">{errors.status.message}</p>}
                        </ColRight>
                    </Group>

                    {/* status_to */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-status_to">Следующий статус*:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name="status_to"
                                className="form-select"
                                id="so-status_to"
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            >
                                <option value="">выберите следующий статус</option>
                                {data && data.statuses.map((status) => <option value={status.id} selected={data?.fields?.status_to === status.id}>{status.name}</option>)}
                            </select>
                            {errors.status_to && <p className="form-input-hint">{errors.status_to.message}</p>}
                        </ColRight>
                    </Group>

                </Form>

                {load && <Loader />}
            </div>
        </GridEditView>
    );
};

export default StatusChangeEditView;
