import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from "../../../components/ui/FormEditView/Form";
import Loader from "../../../components/ui/Loader";
import TableListViewRow from "../../../components/common/TableListView/Row";
import TableListViewTable from "../../../components/common/TableListView/Table";
import errorMessage from "../../../lib/errorMessage";
import { getOrders } from "../../../lib/api/order";
import checkPermission from "../../../lib/checkPermission";
import getTZtoDate from "../../../lib/getTZtoDate";
import { getOrderStatusPaymentName, order_status_payment } from "../../../lib/const/order";
import getNumbersWithSpaces from "../../../lib/getNumbersWithSpaces";
import {geDomainName} from "../../../lib/const/domains";
import { getFiltersData } from "../../OrderList/api";
import { findDeliveryTypeNameById, findOrderStatusById } from "../../../lib/helpers/order";
import TableListViewPagination from "../../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../../components/common/TableListViewNew/SelectPerPage";

const TabOrders = ({index = 2, formProps}) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    const [orderList, setOrderList] = useState(null);
    const [filtersData, setFiltersData] = useState(null);
    const [load, setLoad] = useState(false);
    const [filterParams, setFilterParams] = useState(null);

    const handleChangeParams = (e) => setFilterParams(prev => {
        let temp;
        if (prev) {
            temp = {
                ...prev,
                [e.target.name]: e.target.value
            };
        } else {
            temp = {
                [e.target.name]: e.target.value
            }
        }

        Object.keys(temp).forEach(key => {
            if (!temp[key]?.length) {
                delete temp[key];
            }
        });

        return temp;
    });

    const changeHandler = async (e) => {
        const tempParams = {...filterParams};
        handleChangeParams(e);

        if (!e?.target?.value?.length) {
            delete tempParams[e.target.name];
        } else {
            tempParams[e.target.name] = e.target.value;
        }

        await getOrders({...tempParams})
            .then(res => setOrderList(res.data))
            .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
            .finally(() => setLoad(false));
    }

    const onEnterPress = async (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            await getOrders({...filterParams})
                .then(res => setOrderList(res.data))
                .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
                .finally(() => setLoad(false));
        }
    }

    useEffect(() => {
        if (formProps?.data?.fields?.id) {
            setLoad(true);

            getOrders({user_id: formProps?.data?.fields?.id})
                .then(res => setOrderList(res.data))
                .catch(async (err) => await errorMessage(err, `Order List ${formProps?.data?.fields?.id}`))
                .finally(() => setLoad(false));

            getFiltersData().then(res => setFiltersData(res))
                .catch(async (err) => await errorMessage(err, `Filters Data`))
                .finally(() => setLoad(false));

            setFilterParams({
                user_id: formProps?.data?.fields?.id?.toString()
            })
        }
    }, [formProps?.data]);

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide"/>

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">
                    {!load ? (
                        <>
                            {orderList?.meta && (
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <TableListViewPagination
                                        length={+orderList?.meta?.last_page}
                                        current={+orderList?.meta?.current_page}
                                        url={`api/admin/order`}
                                        setData={setOrderList}
                                        allResponse
                                        params={filterParams}
                                    />
                                    <TableListViewPerPage
                                        total={+orderList?.meta?.total}
                                    />
                                </div>
                            )}
                            <TableListViewTable>
                                <thead>
                                <tr>
                                    <th>Номер / JDE</th>
                                    <th>Дата</th>
                                    <th>Склад</th>
                                    <th>Комментарий</th>
                                    <th>Статус заказа</th>
                                    <th>Статус оплаты</th>
                                    <th>Способ оплаты</th>
                                    <th>Стоимость</th>
                                    <th>Как создан</th>
                                    <th>Оператор</th>
                                    <th>Менеджер создатель</th>
                                    <th>Сайт</th>
                                    <th>Дата план доставки</th>
                                    <th>Способ доставки</th>
                                    <th>ТТН</th>
                                    <th>Статус доставки</th>
                                </tr>
                                <tr>
                                    <th>
                                        <input
                                            type="text"
                                            name="id"
                                            onChange={handleChangeParams}
                                            onKeyDown={onEnterPress}
                                            value={filterParams?.id || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                    <th>
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="date"
                                                name="date_from"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.date_from || ''}
                                                style={{ width: '100%' }}
                                            />
                                            -
                                            <input
                                                type="date"
                                                name="date_to"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.date_to || ''}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <select
                                            name="warehouse_id"
                                            onChange={changeHandler}
                                            value={filterParams?.warehouse_id || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите склад</option>
                                            {!!filtersData?.wareHouses?.length && filtersData?.wareHouses?.map((item, index) => {
                                                return (
                                                    <option key={`user-warehouse-item-${index}`} value={item.id}>{item.title_ru}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <input
                                            type="text"
                                            name="comment"
                                            onKeyDown={onEnterPress}
                                            onChange={handleChangeParams}
                                            value={filterParams?.comment || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                    <th>
                                        <select
                                            name="status"
                                            onChange={changeHandler}
                                            value={filterParams?.status || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите статус</option>
                                            {!!filtersData?.statuses?.length && filtersData?.statuses?.map((item, index) => {
                                                return (
                                                    <option key={`user-status-item-${index}`} value={item.id}>{item.title_ru}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <select
                                            name="status_payment"
                                            onChange={changeHandler}
                                            value={filterParams?.status_payment || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите статус</option>
                                            {order_status_payment?.map((item, index) => {
                                                return (
                                                    <option key={`user-status-payment-item-${index}`} value={item.id}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <select
                                            name="type_payment"
                                            onChange={changeHandler}
                                            value={filterParams?.type_payment || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите тип</option>
                                            {!!filtersData?.payments?.length && filtersData?.payments?.map((item, index) => {
                                                return (
                                                    <option key={`user-type-payment-item-${index}`} value={item.id}>{item.title_ru}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="text"
                                                name="price_from"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.price_from || ''}
                                                style={{ width: '100%' }}
                                            />
                                            -
                                            <input
                                                type="text"
                                                name="price_to"
                                                onKeyDown={onEnterPress}
                                                onChange={handleChangeParams}
                                                value={filterParams?.price_to || ''}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </th>
                                    <th>
                                        <select
                                            name="source"
                                            onChange={changeHandler}
                                            value={filterParams?.source || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите тип</option>
                                            {!!filtersData?.sources?.length && filtersData?.sources?.map((item, index) => {
                                                return (
                                                    <option key={`user-manager-id-item-${index}`} value={item.value}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <select
                                            name="updated_by"
                                            onChange={changeHandler}
                                            value={filterParams?.updated_by || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите менеджера</option>
                                            {!!filtersData?.managers?.length && filtersData?.managers?.map((item, index) => {
                                                return (
                                                    <option key={`user-manager-id-item-${index}`} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <select
                                            name="manager_id"
                                            onChange={changeHandler}
                                            value={filterParams?.manager_id || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите менеджера</option>
                                            {!!filtersData?.managers?.length && filtersData?.managers?.map((item, index) => {
                                                return (
                                                    <option key={`user-manager-id-item-${index}`} value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <select
                                            name="domain"
                                            onChange={changeHandler}
                                            value={filterParams?.domain || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите сайт</option>
                                            {!!filtersData?.domains?.length && filtersData?.domains?.map((item, index) => {
                                                return (
                                                    <option key={`user-domain-item-${index}`} value={item.id}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th />
                                    <th>
                                        <select
                                            name="delivery_type"
                                            onChange={changeHandler}
                                            value={filterParams?.delivery_type || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите тип</option>
                                            {!!filtersData?.deliveryTypes?.length && filtersData?.deliveryTypes?.map((item, index) => {
                                                return (
                                                    <option key={`user-delivery-type-item-${index}`} value={item.id}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                    <th>
                                        <input
                                            type="text"
                                            name="ttn"
                                            onKeyDown={onEnterPress}
                                            onChange={handleChangeParams}
                                            value={filterParams?.ttn || ''}
                                            style={{ width: '100%' }}
                                        />
                                    </th>
                                    <th>
                                        <select
                                            name="delivery_status"
                                            onChange={changeHandler}
                                            value={filterParams?.delivery_status || ''}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="">выберите статус</option>
                                            {!!filtersData?.deliveryStatus?.length && filtersData?.deliveryStatus?.map((item, index) => {
                                                return (
                                                    <option key={`user-delivery-status-item-${index}`} value={item.value}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {!!orderList?.data?.length
                                    ? orderList?.data?.map((e, i) => (
                                        <TableListViewRow
                                            id={i}
                                            key={`user-order-item-${i}`}
                                            controlsOff
                                        >
                                            <td>
                                                {checkPermission('admin.order.edit')
                                                    ? <Link to={`/app/order/update/${e.id}`} target="_blank">{e.id}</Link>
                                                    : <span>{e.id}</span>
                                                }
                                            </td>
                                            <td>{getTZtoDate(e.created_at, true)}</td>
                                            <td>{e?.warehouse?.id ? e?.warehouse?.title_ru : '---'}</td>
                                            <td>
                                                {!!e?.comments?.length && e?.comments.map(comment => {
                                                    const text = comment.text.split('\\n');
                                                    return text.map((item, indexText) => <div key={`item-${i}-comment-${indexText}`}>{item}</div>);
                                                })}
                                            </td>
                                            <td>{!!filtersData?.statuses?.length
                                                ? findOrderStatusById(filtersData?.statuses, e.status)?.name
                                                : e.status ? e.status : '---'}</td>
                                            <td>{getOrderStatusPaymentName(e.status_payment)?.name}</td>
                                            <td>{e?.type_payment && filtersData?.payments && filtersData?.payments?.find(item => item.id === e?.type_payment)?.title_ru}</td>
                                            <td>{e?.manager_id ? getNumbersWithSpaces(e.total) : ''}</td>
                                            <td>
                                                {filtersData?.sources
                                                    ? filtersData?.sources?.find(source => source?.value === e?.source)?.title
                                                    : e?.source
                                                }
                                            </td>
                                            <td>{e.updatedBy ? e.updatedBy?.name : '---'}</td>
                                            <td>{e.manager ? e.manager?.name : '---'}</td>
                                            <td>{geDomainName(e.domain_id).name}</td>
                                            <td>{e?.delivery?.estimated_delivery_date ? new Date(e.delivery?.estimated_delivery_date).toLocaleDateString() : '---'}</td>
                                            <td>{!!filtersData?.deliveryTypes?.length
                                                ? findDeliveryTypeNameById(filtersData?.deliveryTypes, e.delivery_type)?.title_ua
                                                : e.delivery_type ? e.delivery_type : '---'}</td>
                                            <td>{e?.delivery?.ttn_number}</td>
                                            <td>
                                                {filtersData?.deliveryStatus
                                                    ? filtersData?.deliveryStatus?.find(status => status.value === e?.delivery?.delivery_status)?.title
                                                    : e?.delivery?.delivery_status
                                                }
                                            </td>
                                        </TableListViewRow>
                                    ))
                                    : (
                                        <TableListViewRow disabled>
                                            <td colSpan={15} style={{textAlign: 'center', paddingTop: '10px'}}>
                                                Нет данных
                                            </td>
                                        </TableListViewRow>
                                    )}
                                </tbody>
                            </TableListViewTable>
                        </>
                    ) : <Loader/>}
                </Form>

                {formProps.load && <Loader/>}
            </div>
        </form>
    );
};

export default TabOrders;
