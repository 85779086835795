import { createUser, editUser, getUserEdit, removeUser } from '../../lib/api/users'
import { getDomains } from "../../lib/api/domains";
import { parseAll } from "../../lib/api/_parseApi";
import { refreshToken } from "../../lib/crud/refreshToken";
import { getStatusAll } from "../../lib/api/statuses";

export const getData = (clientID) =>
    Promise.allSettled([clientID && getUserEdit(clientID), getDomains(), getStatusAll()])
        .then(async (res) => {
            const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

            if (findStatus401) {
                return await refreshToken(() => window.location.reload())
            }

            return ({
                fields: clientID && res[0]?.value?.data,
                domains: res[1]?.value?.data,
                statuses: res[2]?.value?.data?.data
            })
        })


export const create = createUser;
export const edit = editUser;
export const remove = removeUser;

export const parseDataSend = (data, clientID) => {
    let d = { ...data };
    const isUpdate = typeof clientID !== 'undefined';

    d = parseAll(d, true);

    return d;
};

