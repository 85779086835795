const filterStatusOrder = (statusArray) => {
    const checkRoleShopManager = localStorage?.getItem("roles")?.includes('shop-manager');

    if(checkRoleShopManager) {
        return statusArray?.filter(status => {
            if(status.status_to === 19) {
                return status?.status_to !== 19;
            }
            if(status?.status_to === 20) {
                return status?.status_to !== 20;
            }
            if(status?.status_to === 21) {
                return status?.status_to !== 21;
            }
            return true;
        });
    } else {
        return statusArray;
    }
};

export default filterStatusOrder;