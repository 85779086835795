import {
  BENEFIT_GET,
  BENEFIT_CREATE,
  BENEFIT_DELETE,
  BENEFIT_GET_EDIT,
  BENEFIT_EDIT, BENEFIT_GET_ALL,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBenefits = (params) => fetchAx({
  url: BENEFIT_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getBenefitsAll = () => fetchAx({
  url: BENEFIT_GET_ALL,
  method: 'GET',
});

export const createBenefit = (data) => fetchAx({
  url: BENEFIT_CREATE,
  method: 'POST',
  data,
});

export const getBenefitEdit = (id) => fetchAx({
  url: BENEFIT_GET_EDIT.join(id),
  method: 'GET',
});

export const editBenefit = (id, data) => fetchAx({
  url: BENEFIT_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBenefit = (id) => fetchAx({
  url: BENEFIT_DELETE.join(id),
  method: 'DELETE',
});
