import React from "react";
import cn from "classnames";
import SidebarLeft from "./SidebarLeft";

import s from './GridEditViewNew.module.scss';

const GridEditViewNew = ({
  left, children, styleWrap
}) => (
  <div className={s.wrap} style={styleWrap}>
      <SidebarLeft>{left}</SidebarLeft>
      {children}
  </div>
);

export default GridEditViewNew;
