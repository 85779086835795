import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  WH_CREATE, WH_DELETE, WH_EDIT, WH_GET, WH_GET_EDIT, WH_PARCEL_SENDERS_GET,
} from '../../const/api';

export const getWh = (params) => fetchAx({
  url: WH_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getWhParcelSenders = (params) => fetchAx({
  url: WH_PARCEL_SENDERS_GET,
  method: 'GET',
  params: {
    ...params,
  },
});

export const getWhAll = (params) => fetchAx({
  url: WH_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    is_active: 1,
    ...params,
  },
});

export const createWh = (data) => fetchAx({
  url: WH_CREATE,
  method: 'POST',
  data,
});

export const getWhEdit = (id) => fetchAx({
  url: WH_GET_EDIT.join(id),
  method: 'GET',
});

export const editWh = (id, data) => fetchAx({
  url: WH_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeWh = (id) => fetchAx({
  url: WH_DELETE.join(id),
  method: 'DELETE',
});
