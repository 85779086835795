import {
  SPEC_OFFER_BANNER_GET,
  SPEC_OFFER_BANNER_GET_ALL,
  SPEC_OFFER_BANNER_CREATE,
  SPEC_OFFER_BANNER_GET_EDIT,
  SPEC_OFFER_BANNER_EDIT,
  SPEC_OFFER_BANNER_DELETE,
  SPEC_OFFER_BANNER_CLONE_GET_EDIT
} from '../../const/api';

import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSpecOfferBanners = (params) => fetchAx({
  url: SPEC_OFFER_BANNER_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getSpecOfferBannersALl = () => fetchAx({
  url: SPEC_OFFER_BANNER_GET_ALL,
  method: 'GET',
});

export const createSpecOfferBanners = (data) => fetchAx({
  url: SPEC_OFFER_BANNER_CREATE,
  method: 'POST',
  data,
});

export const getSpecOfferBannersEdit = (id) => fetchAx({
  url: SPEC_OFFER_BANNER_GET_EDIT.join(id),
  method: 'GET',
});

export const editSpecOfferBanners = (id, data) => fetchAx({
  url: SPEC_OFFER_BANNER_EDIT.join(id),
  method: 'PUT',
  data,
});


export const getSpecOfferBannersCloneEdit = (id) => fetchAx({
  url: SPEC_OFFER_BANNER_CLONE_GET_EDIT.join(id),
  method: 'GET',
});


export const removeSpecOfferBanners = (id) => fetchAx({
  url: SPEC_OFFER_BANNER_DELETE.join(id),
  method: 'DELETE',
});
