import {
    getSortCategories,
    removeSortCategories,
    editSortCategories
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains()
])
    .then((res) => ({
        domains: res[0]?.value?.data
    }));

export const edit = editSortCategories;
export const getData = getSortCategories;
export const remove = removeSortCategories;
