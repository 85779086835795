import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { DEVELOPMENT_ENVIRONMENT, DOMAIN_API } from "../../const/api";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, remove, updateSort, getFiltersData } from "./api";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import checkPermission from "../../lib/checkPermission";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import TopPanelNew from "../../components/common/TopPanelNew";
import errorMessage from "../../lib/errorMessage";
import Button from "../../components/ui/Button";
import icon_save from "../../assets/icons/icon_save.svg";
import rangeArrObjs from "../../lib/rangeArrObjs";
import { refreshSortBannerCatalogHorizontal } from "../../lib/api/sort";
import { toast } from "react-toastify";
import {edit} from "./api";

const SortBannerCatalogHorizontalList = () => {
    const [chooseBannerType, setChooseBannerType] = useState("catalog-horizontal");
    const params = new URL(window.location.href).searchParams;
    const domain_id = params.get('domain_id');
    const category_id = params.get('category_id');
    const [currentCard, setCurrentCard] = useState(null);

    const [selectedSort, setSelectedSort] = useState([]);
    const [sortArray, setSortArray] = useState([1]);

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
        filters: {
            domain_id: domain_id ? domain_id : '1',
            category_id: category_id ? category_id : '39'
        }
    });

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (!!filtersData?.categories?.length) {
            setCategories(filtersData?.categories?.filter(item => item.domain_id?.toString() === urlParams?.['domain_id']?.toString()));
        }
    }, [filtersData?.categories, urlParams?.['domain_id']]);

    const changePageBanner = async (value) => {
        switch (value) {
            case 'catalog':
                setChooseBannerType('catalog');
                navigate('/app/sort/banner/catalog');
                break;
            case 'catalog-horizontal':
                setChooseBannerType('catalog-horizontal');
                navigate('/app/sort/banner/catalog-horizontal');
                break;
            case 'header':
                setChooseBannerType('header');
                navigate('/app/sort/banner/header');
                break;
            case 'main-page':
                setChooseBannerType('main-page');
                navigate('/app/sort/banner/main-page');
                break;
            case 'menu':
                setChooseBannerType('menu');
                navigate('/app/sort/banner/menu');
                break;
        }
    };

    const handleSelectSort = (e, value) => {
        setSelectedSort(prev => {
            const temp = [...prev];

            const parseItem = {
                id: e?.id,
                position: +value
            };

            if (!!temp?.length) {
                const findSortIndex = temp.findIndex(itemSelect => itemSelect.id === parseItem.id)

                if (findSortIndex !== -1) {
                    temp[findSortIndex] = parseItem;
                    return temp;
                }
                temp.push(parseItem);
            } else {
                temp.push(parseItem);
            }

            return temp;
        })
    }

    const handleUpdateSort = async () => {
        setLoad(true);
        await updateSort({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '39',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
            positions: selectedSort
        })
            .then((res) => {
                setSelectedSort([]);
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                })
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    const handleRefreshSort = async () => {
        setLoad(true);
        await refreshSortBannerCatalogHorizontal({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '39',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })
            .then((res) => {
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                });
                toast('Рефреш виконано', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        if (rest?.meta?.total) {
            const totalArray = [...Array(rest?.meta?.total + 1).keys()].filter(item => !!item);

            if (!!rest?.data?.length) {
                rest?.data?.forEach(item => {
                    if (!totalArray?.includes(item?.position)) {
                        totalArray.push(item?.position)
                    }
                })
            }
            setSortArray(totalArray);
        }
    }, [rest?.meta?.total, rest?.data]);

    const dropHandler = async (e, card) => {
        e.preventDefault()

        const id = currentCard.id
        const index = card.position > rest?.data.length ? rest?.data.length : card.position

        await edit({
            domain_id: urlParams?.['domain_id'] || '1',
            category_id: urlParams?.['category_id'] || '39',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })(id, index ).then((res) => setRest(prev => {
            const temp = {...prev};
            temp.data = res.data?.data;

            return temp;
        }))
    }

    return (
        <>
            <TopPanelNew
                title='Сортування'
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                            permissionName: 'admin.sort.banner-catalog-horizontal',
                        },
                        {
                            title: 'Сортування',
                            url: `/app/sort/banner/catalog-horizontal${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                            permissionName: 'admin.sort.banner-catalog-horizontal',
                        },
                    ],
                }}

                customButtonTop={checkPermission('admin.sort.banner-catalog-horizontal.update')
                    ? (
                        <>
                            <Button onClick={handleUpdateSort} disabled={!selectedSort?.length} purple>
                                <img src={icon_save} alt="icon save"/>
                                Зберегти / Сортувати
                            </Button>
                        </>
                    ) : null
                }
                positionSticky
            />

            <div>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Sort banners list`}/>
                {!load && (
                    <TopPagination
                        tabs={(
                            <TopNavTabs tabs={[
                                {
                                    title: 'Товари',
                                    url: '/app/sort/products',
                                    permissionName: 'admin.sort.products'
                                },
                                {
                                    title: 'Банера',
                                    url: '/app/sort/banner/catalog-horizontal',
                                    permissionName: 'admin.sort.banner-catalog-horizontal',
                                    isActive: true
                                },
                                {
                                    title: 'Акції',
                                    url: '/app/sort/special-offers',
                                    permissionName: 'admin.sort.special-offers'
                                },
                                {
                                    title: 'Категорії супутніх товарів',
                                    url: '/app/sort/categories',
                                    permissionName: 'admin.sort.category-sort'
                                }
                            ]}
                            />
                        )}
                        pagination={(
                            <>
                                {rest?.meta?.total ? (
                                    <>
                                        <TableListViewPagination
                                            length={+rest?.meta?.last_page}
                                            current={+rest?.meta?.current_page}
                                            loc={location}
                                            navigate={navigate}
                                        />
                                        <TableListViewPerPage
                                            urlParams={urlParams}
                                            onSelect={(val, params) => pushUrlParams(params)}
                                            total={+rest?.meta?.total}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}
                        stickySecond
                    />
                )}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 28}}>
                    <div style={{display: 'flex', gap: '24px'}}>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!filtersData?.domains?.length
                                    ? (
                                        <select
                                            name="domain_id"
                                            className={s_Select.select}
                                            id="b-domain"
                                            onChange={(e) => pushUrlParams({
                                                ...urlParams,
                                                domain_id: e.target.value,
                                                category_id: filtersData?.categories?.find(item => item.domain_id?.toString() === e.target.value)?.children?.[0]?.id
                                            })}
                                        >
                                            {!!filtersData?.domains?.length && filtersData?.domains.map((domain) => (
                                                <option
                                                    key={domain.id}
                                                    value={domain.id}
                                                    selected={domain.id?.toString() === urlParams?.domain_id}
                                                >
                                                    {domain.title}
                                                </option>
                                            ))}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                            </div>
                        </div>

                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!filtersData?.banner_types?.length
                                    ? (
                                        <select
                                            name="banner_type"
                                            className={s_Select.select}
                                            id="b-banner-type"
                                            onChange={(e) => changePageBanner(e.target.value)}
                                        >
                                            {!!filtersData?.banner_types?.length && filtersData?.banner_types.map((banner) => (
                                                <option
                                                    key={banner.value}
                                                    value={banner.value}
                                                    selected={banner.value === chooseBannerType}
                                                >
                                                    {banner.title}
                                                </option>
                                            ))}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-banner-type">Тип банерів</label>
                            </div>
                        </div>

                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!categories?.length
                                    ? (
                                        <select
                                            name="categories"
                                            className={s_Select.select}
                                            id="b-categories"
                                            onChange={(e) => pushUrlParams({
                                                ...urlParams,
                                                category_id: e.target.value
                                            })}
                                        >
                                            {!!categories?.length && rangeArrObjs(categories, 'children', (e, i, iArr, depth) => {
                                                const nest = new Array(depth).join('--');

                                                if (iArr?.length === 1) {
                                                    return null;
                                                }

                                                if (urlParams?.domain_id) {
                                                    if (urlParams?.domain_id?.toString() === e.domain_id?.toString()) {
                                                        return (
                                                            <option
                                                                key={iArr.join('-')}
                                                                value={e.id}
                                                                selected={urlParams?.category_id?.toString()
                                                                    ? e.id?.toString() === urlParams?.category_id?.toString()
                                                                    : e.id?.toString() === '39'
                                                                }
                                                            >
                                                                {`${nest} ${e.title}`}
                                                            </option>
                                                        );
                                                    }
                                                } else {
                                                    if (e.domain_id?.toString() === '1') {
                                                        return (
                                                            <option
                                                                key={iArr.join('-')}
                                                                value={e.id}
                                                                selected={urlParams?.category_id?.toString()
                                                                    ? e.id?.toString() === urlParams?.category_id?.toString()
                                                                    : e.id?.toString() === '39'
                                                                }
                                                            >
                                                                {`${nest} ${e.title}`}
                                                            </option>
                                                        );
                                                    }
                                                }
                                            })}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-categories">Категорії</label>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: checkPermission('admin.sort.banner-catalog-horizontal.refresh')
                            ? 'flex'
                            : 'none',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px'
                    }}>
                        <Button onClick={handleRefreshSort} purple>
                            Рефреш сортування
                        </Button>
                    </div>
                </div>
                <TableListViewTable>
                    <TableHead
                        arrayChildren={[
                            'ID', 'Назва товару УКР', 'Назва товару РУС',
                            'Зображення PC', 'Зображення Mobile', 'Позиція', 'Статус'
                        ]}
                        filterChildren={<TableListViewFiltersRow
                            urlParams={urlParams}
                            fields={[
                                {},
                                {type: 'text', name: 'title_ua'},
                                {type: 'text', name: 'title_ru'},
                                {},
                                {},
                                {},
                                {
                                    type: 'select', name: 'is_active', options: [
                                        {
                                            id: 0,
                                            title: 'Вимкнено',
                                        },
                                        {
                                            id: 1,
                                            title: 'Активна'
                                        }
                                    ]
                                },
                                {}
                            ]}
                            onSubmit={pushUrlParams}
                        />}
                    />
                    <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={7}/>
                        )
                        : (rest && (
                            rest?.data?.length
                                ? rest?.data?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`sort-banners-item-${e.id}`}
                                        remove={{
                                            api: remove({
                                                domain_id: urlParams?.['domain_id'],
                                                category_id: urlParams?.['category_id'],
                                            }),
                                            success: () => setRest(getSplicedData(i, rest)),
                                            alert: 'Сортування банеру видалено',
                                        }}
                                        permissionRemove='admin.sort.banner-catalog-horizontal.delete'
                                        card={e}
                                        setCurrentCard={setCurrentCard}
                                        drop={dropHandler}
                                    >
                                        <td>
                                            {checkPermission('admin.banner-catalog-horizontal.index') ? (
                                                <a href={`${DOMAIN_API}/app/banner-management/catalog-horizontal/update/${e?.id}`}>{e?.id}</a>
                                            ) : (
                                                <span>
                                                    {e?.id || '---'}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ua || e?.title || e?.name || '---'}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {e?.title_ru || e?.title || e?.name || '---'}
                                            </span>
                                        </td>
                                        <td>{e?.cover_ua ? <img src={e?.cover_ua?.conversions?.['thumb-webp'] || e?.cover_ua?.original} alt="" /> : "---"}</td>
                                        <td>{e?.mobile_ua ? <img src={e?.mobile_ua?.conversions?.['thumb-webp'] || e?.mobile_ua?.original} alt="" /> : "---"}</td>
                                        <td>
                                            <select
                                                name={`sort-name-${e.id}`}
                                                className="form-select"
                                                onChange={(event) => handleSelectSort(e, event.target.value)}
                                                defaultValue={e?.position}
                                            >
                                                {sortArray.map((item) => (
                                                    <option value={item} key={`sort-name-${item}`}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <span>
                                                {e.is_active ? <StatusCircle green text="Активна"/> :
                                                    <StatusCircle red text="Вимкнено"/>}
                                            </span>
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={7} style={{paddingTop: '22px'}}>Немає Сортування Горизонтальних Банерів</td>
                                    </TableListViewRow>
                                )
                        ))}
                    </tbody>
                </TableListViewTable>
            </div>
        </>
    );
};

export default SortBannerCatalogHorizontalList;
