import React, {useEffect, useState} from "react";
import fetchAx from "../../../../lib/fetchAx";
import { DOMAIN_API } from "../../../../const/api";
import getTZtoDate from "../../../../lib/getTZtoDate";
import Button from "../../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import s_Table from '../orderTable.module.scss';
import icon_update from '../../../../assets/icons/icon_update.svg';
import icon_lock from '../../../../assets/icons/icon_lock.svg';
import { toast } from "react-toastify";
import icon_unlock from "../../../../assets/icons/icon_unlock.svg";
import cn from "classnames";
import errorMessage from "../../../../lib/errorMessage";
import { refreshToken } from "../../../../lib/crud/refreshToken";

const ModalPrivateLog = ({ active, setActive, orderId, title }) => {
    const [logPrivat, setLogPrivat] = useState([]);
    const [load, setLoad] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const handleChangeDisabled = () => setDisabled(!disabled);

    const getLogoPrivat = (id) => fetchAx({
        url: `${DOMAIN_API}/api/admin/order/${id}/api-log`,
        method: 'GET',
    });

    const sendLogoPrivat = (id, data) => fetchAx({
        url: `${DOMAIN_API}/api/admin/order/${id}/mono`,
        method: 'POST',
        data
    });

    const handleUpdateLog = async () => {
        setLoad(true);
        await getLogoPrivat(orderId)
            .then((res) => setLogPrivat(res.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getLogoPrivat(orderId)
                            .then((res) => setLogPrivat(res.data))
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            })
            .finally(() => setLoad(false));
    };

    const handleSendRefund = async () => {
        await sendLogoPrivat(orderId, { action: 'refund' })
            .then((res) => {
                if(!!res?.data?.error?.length) {
                    toast.error(res?.data?.error || 'Error', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(!!res?.data?.message?.length) {
                    toast(res?.data?.message);
                }
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await sendLogoPrivat(orderId, { action: 'refund' })
                            .then((res) => {
                                if(!!res?.data?.error?.length) {
                                    toast.error(res?.data?.error || 'Error', {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                                if(!!res?.data?.message?.length) {
                                    toast(res?.data?.message);
                                }
                            })
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            });
        await handleUpdateLog();
    };
    const handleSendConfirm = async () => {
        await sendLogoPrivat(orderId, { action: 'confirm' })
            .then((res) => {
                if(!!res?.data?.error?.length) {
                    toast.error(res?.data?.error || 'Error', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(!!res?.data?.message?.length) {
                    toast(res?.data?.message);
                }
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await sendLogoPrivat(orderId, { action: 'confirm' })
                            .then((res) => {
                                if(!!res?.data?.error?.length) {
                                    toast.error(res?.data?.error || 'Error', {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                                if(!!res?.data?.message?.length) {
                                    toast(res?.data?.message);
                                }
                            })
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            });
        await handleUpdateLog();
    };
    const handleSendStatus = async () => {
        await sendLogoPrivat(orderId, { action: 'status' })
            .then((res) => {
                if(!!res?.data?.error?.length) {
                    toast.error(res?.data?.error || 'Error', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                if(!!res?.data?.message?.length) {
                    toast(res?.data?.message);
                }
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await sendLogoPrivat(orderId, { action: 'status' })
                            .then((res) => {
                                if(!!res?.data?.error?.length) {
                                    toast.error(res?.data?.error || 'Error', {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                                if(!!res?.data?.message?.length) {
                                    toast(res?.data?.message);
                                }
                            })
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            });
        await handleUpdateLog();
    };

    useEffect(() => {
        setLoad(true);
        getLogoPrivat(orderId)
            .then((res) => setLogPrivat(res.data))
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getLogoPrivat(orderId)
                            .then((res) => setLogPrivat(res.data))
                    })
                } else {
                    await errorMessage(err, 'api/admin/audit GET')
                }
            })
            .finally(() => setLoad(false));
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                        <div className={s_Modal.modal__update} onClick={handleUpdateLog}>
                            <img src={icon_update} alt="Icon update" />
                            Обновить
                        </div>
                    </div>
                    <div className={cn(s_Modal.modal__unlock, {
                        [s_Modal.modal__lock]: disabled,
                    })} onClick={handleChangeDisabled}>
                        {disabled ? (
                            <>
                                <img src={icon_lock} alt="Icon lock" />
                                Разблокировать
                            </>
                        ) : (
                            <>
                                <img src={icon_unlock} alt="Icon lock" />
                                Заблокировать
                            </>
                        )}
                    </div>
                </div>
                <div className={s_Modal.modal__box}>
                    <div className={s_Table.table__inner}>
                        <div className={s_Table.table__wrapper} style={{ minHeight: '25px' }}>
                            {!load ? (
                                    <table className={s_Table.table}>
                                        <thead className={s_Table.thead}>
                                            <tr>
                                                <th>
                                                    <div>Дата</div>
                                                </th>
                                                <th>
                                                    <div>Ответ</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={s_Table.tbody}>
                                            {!!logPrivat.length ? logPrivat.map(item => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <div>{getTZtoDate(item.created_at, true)}</div>
                                                    </td>
                                                    <td>
                                                        <div>{item.response}</div>
                                                    </td>
                                                </tr>
                                            )) : <div style={{ padding: 10 }}>Лог привата по заказу отсутствует</div>}
                                        </tbody>
                                    </table>
                                )
                                : (
                                    <div className="p-relative">
                                        <div className="loading" />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                        <div className={s_Modal.modal__buttons}>
                            <div>
                                <Button purple disabled={disabled} onClick={handleSendConfirm}>Подтвердить</Button>
                            </div>
                            <div>
                                <Button purple disabled={disabled} onClick={handleSendRefund}>Возврат</Button>
                            </div>
                            <div>
                                <Button purple disabled={disabled} onClick={handleSendStatus}>Статус</Button>
                            </div>
                        </div>

                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPrivateLog;