import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove, getFiltersData } from "./api";
import { APPLICATION_STATUS, APPLICATION_TYPES } from "../../lib/const/product";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import rangeArrObjs from "../../lib/rangeArrObjs";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";

const ProductFeedbackList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product feedback list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Дата відправки', 'Прізвище', "Ім'я", 'Телефон', 'Місто', 'Категорія', 'Товар',
                        'Наявність', 'Коментар', 'Сайт', 'Номер замовлення', 'Тип', 'Статус', 'Менеджер'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {type: 'text', name: 'id', attr: {style: {maxWidth: '100px'}}},
                            {type: 'date', name: 'created_at'},
                            {type: 'text', name: 'surname'},
                            {type: 'text', name: 'name'},
                            {type: 'text', name: 'phone'},
                            {},
                            {
                                type: 'select',
                                name: 'category_id',
                                options: !!filtersData?.categories?.length && rangeArrObjs(filtersData.categories, 'children', (e, i, iArr, depth) => {
                                    if (urlParams?.domain_id) {
                                        if (e?.domain_id === +urlParams?.domain_id) {
                                            return e;
                                        } else {
                                            return null;
                                        }
                                    }
                                    if (e?.domain_id === 1) {
                                        const parseData = e;
                                        if (parseData.title.includes('(SamsungShop)')) return parseData;
                                        parseData.title = `${parseData.title} (SamsungShop)`;
                                        return parseData;
                                    }
                                    if (e?.domain_id === 2) {
                                        const parseData = e;
                                        if (parseData.title.includes('(HR)')) return parseData;
                                        parseData.title = `${parseData.title} (HR)`;
                                        return parseData;
                                    }
                                    if (e?.domain_id === 3) {
                                        const parseData = e;
                                        if (parseData.title.includes('(Design)')) return parseData;
                                        parseData.title = `${parseData.title} (Design)`;
                                        return parseData;
                                    }
                                    if (e?.domain_id === 10) {
                                        const parseData = e;
                                        if (parseData.title.includes('(Main)')) return parseData;
                                        parseData.title = `${parseData.title} (Main)`;
                                        return parseData;
                                    }

                                    return null;
                                })
                            },
                            {type: 'text', name: 'product_name'},
                            {type: 'checkbox', name: 'has_product_quantity_main_warehouses', id: 'prodf-check'},
                            {type: 'text', name: 'comment'},
                            {type: 'select', name: 'domain_id', options: !!filtersData?.domains?.length && filtersData.domains},
                            {type: 'text', name: 'order_id', attr: { style: { width: '120px' } }},
                            {type: 'select', name: 'type', options: APPLICATION_TYPES},
                            {type: 'select', name: 'status', options: APPLICATION_STATUS},
                            {
                                type: 'select',
                                name: 'manager_id',
                                options: !!filtersData?.managers?.length && filtersData.managers,
                                optProps: ['id', 'name']
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={14}/>
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`prod-feed-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: "Форму зворотнього зв'язку видалено",
                                    }}
                                    editLink={`/app/product-feedback/update/${e.id}`}
                                    permissionRemove='admin.product-feedback.destroy'
                                    permissionEdit='admin.product-feedback.edit'
                                    deleteButton={false}
                                >
                                    <td>
                                        {checkPermission('admin.product-feedback.edit')
                                            ? <Link to={`/app/product-feedback/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.surname || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.settlement ? e?.settlement?.title : '---'}
                                        </span>
                                    </td>
                                    <td>{e?.product?.categories ? e?.product?.categories?.map((item, index) => {
                                        if (e?.product?.categories?.length === (index + 1)) return `${item.title}`
                                        return `${item.title}, `
                                    }) : ''}</td>
                                    <td>
                                        <span>
                                            {e?.product
                                                ? checkPermission('admin.product.edit')
                                                    ? <Link to={`/app/product/update/${e?.product?.id}`} target="_blank">{e?.product?.title}</Link>
                                                    : <span>{e?.product?.title}</span>
                                                : '---'}
                                        </span>
                                    </td>
                                    <td>{e?.product?.warehouse_quantity_main?.map((ware, indProdWare) => (
                                        <div className={s_Table.availability__block}
                                             key={`product-warehouses-${indProdWare}`}>
                                            {ware.quantity ? (
                                                <>
                                                    <div className={s_Table.availability__stock}>
                                                        {ware.number.toString() === '310' ? '510' : ware.number}
                                                    </div>
                                                    <div className={s_Table.availability__counter}>
                                                        {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                                                    </div>
                                                </>
                                            ) : null}

                                        </div>
                                    ))}</td>
                                    <td>
                                        <span>
                                            {e?.comment || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.domain ? e?.domain?.title : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.order_id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.type ? APPLICATION_TYPES[e.type - 1].title : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {(e.status || e.status === 0) ? APPLICATION_STATUS[e.status].title : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.manager ? e?.manager?.name : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={14}>Немає форм зворотнього зв'язку</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default ProductFeedbackList;
