export default (editor, opts = {}) => {
  const dc = editor.DomComponents

  dc.addType(opts.name, {
    model: {
      defaults: {
        type: opts.name,
        tagName: 'replacement',
        editable: true,
        droppable: false,
        attributes: {
          'data-gjs-type': opts.name
        },
        textable: 1,
        traits: [{
          type: 'select',
          label: 'Tag',
          name: 'data-domain-id',
          options: [
            {
              name: 'SamsungShop',
              id: 1
            },
            {
              name: 'HR',
              id: 2
            }
          ]
        }]
      }
    },
    isComponent: (el) => {
      if (el.className && el.className.includes('domain-id-custom')) {
        return {
          type: opts.name
        }
      }
    }
  })
}
