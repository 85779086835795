import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  PRODUCT_CONSULTATION_CREATE, PRODUCT_CONSULTATION_DELETE, PRODUCT_CONSULTATION_EDIT,
  PRODUCT_CONSULTATION_GET, PRODUCT_CONSULTATION_GET_EDIT,
} from '../../const/api';

export const getProductConsultation = (params) => fetchAx({
  url: PRODUCT_CONSULTATION_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createProductConsultation = (data) => fetchAx({
  url: PRODUCT_CONSULTATION_CREATE,
  method: 'POST',
  data,
});

export const getProductConsultationEdit = (id) => fetchAx({
  url: PRODUCT_CONSULTATION_GET_EDIT.join(id),
  method: 'GET',
});

export const editProductConsultation = (id, data) => fetchAx({
  url: PRODUCT_CONSULTATION_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeProductConsultation = (id) => fetchAx({
  url: PRODUCT_CONSULTATION_DELETE.join(id),
  method: 'DELETE',
});
