import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import s from "./Portal.module.scss";
import icon_close from "../../../assets/icons/icon_close_modal.svg"

const portal = document.getElementById("modal-root")

const Portal = ({ children, onClose }) => {

    const handleCloseModal = () => {
        portal.classList.remove(s.wrap);
        if (onClose) {
            onClose();
        }
    }

    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (ref.current && !ref?.current?.contains(e.target)) {
                if (onClose) {
                    portal.classList.remove(s.wrap);
                    onClose();
                }
            }
        }
        document.addEventListener("click", checkIfClickedOutside)
        return () => {
            document.removeEventListener("click", checkIfClickedOutside)
        }
    }, [onClose]);

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.overflow = "auto"
        }
    }, []);

    useEffect(() => {
        portal.classList.add(s.wrap);
    }, []);

    return ReactDOM.createPortal(
        <div className={s.box} ref={ref}>
            {onClose ? (
                <div className={s.btn_close} onClick={handleCloseModal}>
                    <img src={icon_close} alt="Icon close modal" />
                </div>
            ) : null}
            {children}
        </div>,
        portal)
}

export default Portal