import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const TopAndBottomEditView = () => {
  const { id: tbmID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: tbmID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/menu/top-and-bottom/not-found',
      remove: `/app/menu/top-and-bottom${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/menu/top-and-bottom${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Меню создано',
      edit: 'Меню обновлено',
      remove: 'Меню удалено',
    },
  });
  /* eslint-disable */
  return (
    <GridEditView>
      <div className="box-wrap">
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Top and bottom edit`} />
        {tbmID
          ? (
            <Title>
              Меню -
              <small>{data && data?.fields?.title_ru}</small>
            </Title>
          )
          : <Title>Новое меню</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-name-ua">Название УКР:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ua"
                className={cn('form-input', {
                  'is-error': errors.title_ua,
                })}
                type="text"
                id="benefit-name-ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
            </ColRight>
          </Group>

          {/* title_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-name-ru">Название РУC:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ru"
                className={cn('form-input', {
                  'is-error': errors?.title_ru,
                })}
                type="text"
                id="benefit-name-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors?.title_ru && <p className="form-input-hint">{errors?.title_ru?.message}</p>}
            </ColRight>
          </Group>

          <div className="divider text-center" data-content="Cсылка" />

          {/* link_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-link-ua">Cсылка УКР:</label>
            </ColLeft>
            <ColRight>
              <input
                name="link_ua"
                className={cn('form-input', {
                  'is-error': errors.link_ua,
                })}
                type="url"
                id="benefit-link-ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.link_ua && <p className="form-input-hint">{errors.link_ua.message}</p>}
            </ColRight>
          </Group>

          {/* link_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="benefit-link-ru">Cсылка РУС:</label>
            </ColLeft>
            <ColRight>
              <input
                name="link_ru"
                className={cn('form-input', {
                  'is-error': errors.link_ru,
                })}
                type="url"
                id="benefit-link-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
              {errors.link_ru && <p className="form-input-hint">{errors.link_ru.message}</p>}
            </ColRight>
          </Group>

          {/* position */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-position">Позиция*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="position"
                className={cn('form-input', {
                  'is-error': errors.position,
                })}
                type="number"
                id="bml-position"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.position && <p className="form-input-hint">{errors.position.message}</p>}
            </ColRight>
          </Group>

          {/* domain_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="so-domain">Домен*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="domain_id"
                      className={cn('form-select', {
                        'is-error': errors.domain_id,
                      })}
                      id="so-domain"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                      {data && !!data.domains && data.domains.map((domain) => (
                        <option
                          key={domain.id}
                          value={domain.id}
                        >
                          {domain.title}
                        </option>
                      ))}
                    </select>

                    {errors.domain_id
                      && <p className="form-input-hint">{errors.domain_id.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft/>
            <ColRight>
              <label className="form-switch">
                <input
                  name="is_active"
                  type="checkbox"
                  id="benefit-status-active"
                  ref={register}
                />
                <i className="form-icon" />
                {' '}
                Статус активности
              </label>
            </ColRight>
          </Group>

          {/* visibility */}
          <Group>
            <ColLeft>
                <label className="form-label" htmlFor="so-domain">Вид меню:</label>
            </ColLeft>
            <ColRight>
                 <select
                      name="is_top"
                      className='form-select'
                      id="tbm-is_top"
                      ref={register}
                    >
                   <option value={0}>
                     Нижнее меню
                   </option>
                   <option value={1}>
                     Верхнее меню
                   </option>
                 </select>
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default TopAndBottomEditView;
