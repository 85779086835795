import React from 'react';
import loadable from '../../lib/loadable';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import useLayout from '../../lib/useLayout';

export default loadable(() => import('./index'), {
  fallback: <LoadingIndicator />,
},
() => {
  useLayout('page-main');
});
