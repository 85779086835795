export default (editor, opts = {}) => {
    const dc = editor.DomComponents;

    dc.addType(opts.name, {
        model: {
            defaults: {
                script: function () {
                    const script = document.createElement("script");
                    script.src = 'https://unpkg.com/accordion-js@3.3.2/dist/accordion.min.js';
                    script.innerHTML = new Accordion('.accordion-container');
                    document.body.appendChild(script);
                },
            },
        },
        isComponent: (el) => {
            if(el.className && el.className.includes('accordion-container')) {
                return {
                    type: opts.name,
                };
            }
        },
    });
};