import fetchAx from "../fetchAx";
import {PRODUCT_FEEDBACK_GET, SERVICE_CENTERS_GET, SERVICE_CENTERS_LIST_GET} from "../../const/api";
import {LIST_PER_PAGE} from "../../const/view";

export const getServiceCenterRegistrations = (params) => fetchAx({
    url: SERVICE_CENTERS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const getServiceCenterList = () => fetchAx({
  url: SERVICE_CENTERS_LIST_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
  },
});

export const removeServiceCenterRegistrations = (params) => fetchAx({
    url: PRODUCT_FEEDBACK_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});