import {
  BANK_CREATE, BANK_GET_EDIT, BANK_EDIT, BANK_DELETE, BANKS_GET, BANK_PRODUCT,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBanks = (params) => fetchAx({
  url: BANKS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getBanksAll = () => fetchAx({
  url: BANKS_GET,
  method: 'GET',
});

export const createBank = (data) => fetchAx({
  url: BANK_CREATE,
  method: 'POST',
  data,
});

export const getBankEdit = (id) => fetchAx({
  url: BANK_GET_EDIT.join(id),
  method: 'GET',
});

export const editBank = (id, data) => fetchAx({
  url: BANK_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBank = (id) => fetchAx({
  url: BANK_DELETE.join(id),
  method: 'DELETE',
});

export const getBankProduct = (params) => fetchAx({
  url: BANK_PRODUCT,
  method: 'GET',
  params: {
    ...params,
    per_page: LIST_PER_PAGE.xxl,
  }
});
