import {
  ACCESSORY_RELATION_GET,
  ACCESSORY_RELATION_CREATE,
  ACCESSORY_RELATION_GET_EDIT,
  ACCESSORY_RELATION_EDIT,
  ACCESSORY_RELATION_DELETE,
  ACCESSORY_RELATION_GET_PRODUCT_FROM,
  ACCESSORY_RELATION_GET_PRODUCT_TO,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getAccessoryRelation = (params) => fetchAx({
  url: ACCESSORY_RELATION_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createAccessoryRelation = (data) => fetchAx({
  url: ACCESSORY_RELATION_CREATE,
  method: 'POST',
  data,
});

export const getAccessoryRelationEdit = (id) => fetchAx({
  url: ACCESSORY_RELATION_GET_EDIT.join(id),
  method: 'GET',
});

export const getAccessoryRelationProductFrom = (id) => fetchAx({
  url: ACCESSORY_RELATION_GET_PRODUCT_FROM.join(id),
  method: 'GET',
});

export const getAccessoryRelationProductTo = (id) => fetchAx({
  url: ACCESSORY_RELATION_GET_PRODUCT_TO.join(id),
  method: 'GET',
});

export const editAccessoryRelation = (id, data) => fetchAx({
  url: ACCESSORY_RELATION_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeAccessoryRelation = (id) => fetchAx({
  url: ACCESSORY_RELATION_DELETE.join(id),
  method: 'DELETE',
});
