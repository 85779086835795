import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import TopPanelNew from "../../components/common/TopPanelNew";
import getTZtoDate from "../../lib/getTZtoDate";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s from "./Benefit.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import BlockUa from "./components/BlockUa";
import BlockRu from "./components/BlockRu";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const BenefitEditView = () => {
  const { id: benefitID } = useParams();
  const formH = useForm();
  const {
    handleSubmit,
    reset,
  } = formH;

  const navigate = useNavigate();

  const { load, data, submitHandler } = useEdit({
    targetID: benefitID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/benefit/not-found',
      remove: `/app/main/benefits${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/benefits${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Преимущество создано',
      edit: 'Преимущество обновлено',
      remove: 'Преимущество удалено',
    },
  });
  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Benefit edit`} />
      <TopPanelNew
          title={benefitID ? `Преимущество - ${data && data.fields.title_ru}` : "Новое преимущество"}
          breadcrumbs={{
            items: [
              {
                title: "Dashboard",
                url: "/app",
              },
              {
                title: "Преимущества",
                url: `/app/main/benefits${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              },
              {
                title: benefitID ? `Преимущество - ${data && data.fields.id}` : "Новое преимущество",
                url: benefitID ? `/app/main/benefit/update/${data && data.fields.id}` : "/app/main/benefit/create",
              },
            ],
          }}
          back={`/app/main/benefits${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          updateDate={data?.fields?.updated_at && getTZtoDate(data?.fields?.updated_at, true)}
          btnRemove={{
            api: remove,
            alert: `Удалить преимущество “${data?.fields?.title_ua}”?`,
            href: "/app/main/benefits",
            navigate
          }}
          btnEdit
      />
      <div>
        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          <div>
            <div className={s.top}>
              <div className={s.top__item}>
                {/* domain_id */}
                <div className={s.top__input}>
                  <div className={s_Select.select__inner}>
                    <div className={s_Select.select__wrapper}>
                      {data
                          ? (
                              <select
                                  name="domain_id"
                                  className={cn(s_Select.select, {
                                    [s_Select.select_error]: formH.errors?.domain_id,
                                  })}
                                  id="b-domain"
                                  ref={formH.register({
                                    required: VALIDATION.select.required,
                                  })}
                              >
                                <option value="">выберите домен</option>
                                {data && !!data.domains && data.domains.map((domain) => (
                                    <option
                                        key={domain.id}
                                        value={domain.id}
                                        selected={domain.id === data?.fields?.domain_id}
                                    >
                                      {domain.title}
                                    </option>
                                ))}
                              </select>
                          )
                          : (
                              <div className="p-relative">
                                <div className="loading" />
                              </div>
                          )}
                      <label className={s_Select.label} htmlFor="b-domain">Домен*</label>
                    </div>
                    {formH.errors?.domain_id
                    && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                  </div>
                </div>

                {/* position */}
                <div className={s.top__input}>
                  <InputWrapper
                      label="Позиция*"
                      id="b-position"
                      errorComponent={formH.errors?.position &&
                      <p className="form-input-hint">{formH?.errors?.position?.message}</p>}
                  >
                    <input
                        className={cn(s_Input.input, {
                          [s_Input.input_error]: formH.errors?.position,
                        })}
                        type="text"
                        placeholder="Позиция*"
                        maxLength={INPUT_MAX_LENGTH}
                        ref={formH.register({
                          required: VALIDATION.req.required
                        })}
                        name="position"
                        id="b-position"
                        defaultValue={data?.fields?.position}
                    />
                  </InputWrapper>
                </div>
              </div>
              <div className={s.top__item}>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="b-is-active"
                    label="Активность"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </div>
            </div>
            <div className={s.block}>
              <BlockUa formH={formH} data={data} />
              <BlockRu formH={formH} data={data} />
            </div>
          </div>

        </Form>

        {load && <Loader />}
      </div>
    </>
  );
};

export default BenefitEditView;
