import cn from 'classnames';
import React from 'react';

const ColFull = ({ children, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn('column col-12', attrs && attrs.className),
  };

  return (
    <div {...attrsMerge}>{children}</div>
  );
};

export default ColFull;
