import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from '../../lib/crud/useList';
import {getData} from "./api";
import TableListViewTable from "../../components/common/TableListView/Table";
import TableListViewRowLoader from "../../components/common/TableListView/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListView/Row";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PaymentsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    })

    return(
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Payment list`} />
            <TableListViewTable>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Название УКР</th>
                    <th>Название РУC</th>
                    <th>Иконка</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5} />
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`payment-item-${e.id}`}
                                    editLink={`/app/payment/update/${e.id}`}
                                    permissionEdit='admin.payment.edit'
                                    deleteButton={false}
                                >
                                    <td>
                                        {checkPermission('admin.payment.edit')
                                            ? <Link to={`/app/payment/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>{e.title_ua}</td>
                                    <td>{e.title_ru}</td>
                                    <td>{e.icon}</td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={5} />
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>

            {!load && (
                <TableListViewFooter
                    left={(
                        <>
                            {+rest?.total && (
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    total={+rest?.total}
                                    perPage={+rest?.per_page}
                                    loc={location}
                                />
                            )}
                        </>
                    )}
                    right={(
                        <TableListViewPerPage
                            urlParams={urlParams}
                            onSelect={(val, params) => pushUrlParams(params)}
                        />
                    )}
                    rightCn="col-auto"
                />
            )}
        </div>
    )
}

export default PaymentsList
