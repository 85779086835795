import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const CreditTypeEditView = () => {
  const { id: catID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: catID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/credit-type/not-found',
      remove: `/app/credit-types${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/credit-types${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Тип кредита создан',
      edit: 'Тип кредита обновлен',
      remove: 'Тип кредита удален',
    },
  });
/* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Credit type edit`} />
      <div className="box-wrap">
        {catID
          ? (
            <Title>
              {' '}
              Тип кредита -
              <small>{data && data.fields.title}</small>
            </Title>
          )
          : <Title>Новый тип кредита</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="credit_type-name">
                Название типа кредита*:
              </label>
            </ColLeft>
            <ColRight>
              <input
                name="title"
                className={cn('form-input', {
                  'is-error': errors.title,
                })}
                type="text"
                id="credit_type-name"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
            </ColRight>
          </Group>

          {/* payment_count */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="payment-count">Количество платежей:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="payment_count"
                  className={'form-input'}
                  type="number"
                  id="payment-count"
                  placeholder="Введите число"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-is-active">Статус активности:</label>
            </ColLeft>
            <ColRight>
              <select
                name="is_active"
                className={cn('form-select', {
                  'is-error': errors.is_active,
                })}
                id="bank-is-active"
                ref={register({
                  required: VALIDATION.select.required,
                })}
              >
                <option value="">Выберите статус</option>
                <option value="0">Не активный</option>
                <option value="1">Активный</option>
              </select>
              {errors.is_active
                            && <p className="form-input-hint">{errors.is_active.message}</p>}
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default CreditTypeEditView;
