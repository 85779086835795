import {
    createAdmin,
    editAdmin,
    getAdminEdit,
    getAdminStatuses,
    removeAdmin
} from "../../lib/api/admins";
import { getRoles } from "../../lib/api/roles";
import { parseAll } from "../../lib/api/_parseApi";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (adminID) =>
    Promise.allSettled([adminID && getAdminEdit(adminID), getRoles(), getAdminStatuses()])
        .then(async (res) => {
            const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

            if (findStatus401) {
                return await refreshToken(() => window.location.reload())
            }

            return ({
                fields: adminID && res[0]?.value?.data,
                roles: res[1]?.value?.data?.data,
                statuses: res[2]?.value?.data,
            })
        })


export const create = createAdmin;
export const edit = editAdmin;
export const remove = removeAdmin;

export const parseDataSend = (data, adminID) => {
    let d = { ...data };
    if(!d?.internal_phone){
        d.internal_phone = null;
    }

    const isUpdate = typeof adminID !== 'undefined';

    d = parseAll(d, true);

    return d;
};

