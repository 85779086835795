import React from "react";
import s from "./Textarea.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";

const Textarea = ({ name, id, placeholder, rows = 4, defaultValue, formH,  attrs }) => {

  return (
      <div className={s.wrap}>
                  <textarea
                      name={name}
                      className={s.textarea}
                      id={id}
                      placeholder={placeholder}
                      rows={rows}
                      ref={formH.register}
                      defaultValue={defaultValue}
                      maxLength={INPUT_MAX_LENGTH}
                      {...attrs}
                  />
        <label className={s.label__textarea} htmlFor={id}>{placeholder}</label>
      </div>
  );
};

export default Textarea;
