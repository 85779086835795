import React from 'react';
import cn from 'classnames';
import s from './TopPagination.module.scss';

const TopPagination = ({ tabs, pagination, stickySecond }) => (
  <div className={cn(s.wrap, {
      [s.wrap_second_sticky]: stickySecond
  })}>
    <div className={s.col}>
      {tabs}
    </div>
    <div className={s.col}>
      {pagination}
    </div>
  </div>
);

export default TopPagination;
