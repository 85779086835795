import React from 'react';
import Loader from '../../../ui/Loader';
import TableListViewRow from './Row';

const TableListViewRowLoader = ({
  cols,
}) => (
  <>
    <TableListViewRow disabled controlsOff>
      <td colSpan={cols + 1}>
        <Loader />
      </td>
    </TableListViewRow>

    <TableListViewRow disabled>
      <td colSpan={cols} />
    </TableListViewRow>
  </>
);

export default TableListViewRowLoader;
