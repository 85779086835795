import { getCurtRulesPromocodeServiceCenters, removeCurtRule} from '../../lib/api/cart_rules';
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
  getDomains()
])
  .then((res) => ({
    domains: res[0]?.value?.data,
  }));

export const getData = getCurtRulesPromocodeServiceCenters;
export const remove = removeCurtRule;
