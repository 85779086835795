import React from 'react';
import useIsMounted from "../../lib/useIsMounted";
import useRemove from "../../lib/crud/useRemove";
import icon_delete from "../../assets/icons/icon_delete.svg";

const RemoveComponent = ({ id, remove = { api: null, success: null, alert: null } }) => {
    const isMounted = useIsMounted();
    const { load, removeHandler } = useRemove({
        id,
        isMounted,
        ...remove,
    });

    if(load) {
        setTimeout(() => {
            document.location.reload();
        }, 1000);
    }

    return (
        <div onClick={removeHandler} style={{cursor: 'pointer'}}>
            <img src={icon_delete} alt="icon delete" />
        </div>
    );
};

export default RemoveComponent;