import React, { useEffect, useState } from "react";
import s from "./ModalCreate.module.scss";
import { getCat } from "../../../lib/api/categories";
import InputFetchResult from "../../../components/ui/InputFetchResultNew";
import { useForm } from "react-hook-form";
import Form from "../../../components/ui/FormEditView/Form";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import Group from "../../../components/ui/FormEditView/Group";
import Button from "../../../components/ui/Button";
import { postSortCategories } from "../../../lib/api/sort";
import errorMessage from "../../../lib/errorMessage";
import Loader from "../../../components/ui/Loader";
import { toast } from "react-toastify";
import { getData } from "../api";

const ModalCreate = ({ onClose, domainId, setRest, rest }) => {
    const formH = useForm();

    const [chooseCategory, setChooseCategory] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [load, setLoad] = useState(false);
    const [confirmCreate, setConfirmCreate] = useState(false);

    console.log(categoryId, 'categoryId')
    console.log(chooseCategory, 'chooseCategory')

    const [sortArray, setSortArray] = useState([1]);

    const {
        handleSubmit,
        register
    } = formH;

    const submitHandler = async (data) => {
        setLoad(true);

        const statusUpdate = await postSortCategories(+data?.category_id, +data?.sort, {domain_id: domainId,
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page
        }).then(res => {
            if (!!res.data?.data?.length) {
                window.location.reload();
            }

            if (res?.data?.success === false) {
                toast.warning(`Категорія ${chooseCategory?.title_ua} є у списку`, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            return res;

        }).catch(err => errorMessage(err, '')).finally(() => setLoad(false));

        if (!!statusUpdate?.data?.data?.length) {
            await getData({
                domain_id: domainId?.toString(),
                page: rest?.meta?.page,
                per_page: rest?.meta?.per_page
            }).then(res => {
                setRest(res?.data);
                toast(`Товар ${chooseCategory?.title_ua} додано`, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onClose();
            }).catch(err => errorMessage(err, '')).finally(() => setLoad(false));
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (rest?.meta?.total) {
            const totalArray = [...Array(rest?.meta?.total + 2).keys()].filter(item => !!item);
            if (rest?.meta?.total === 20) {
                const defaultArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
                setSortArray(defaultArray);
            } else {
                setSortArray(totalArray);
            }
        }
    }, [rest?.meta?.total]);

    return (
        <div className={s.wrap}>
            <div className={s.title}
                 style={{
                     maxWidth: !confirmCreate ? 'max-content' : '100%',
                     textAlign: !confirmCreate ? 'left' : 'center'
                 }}
            >
                {!confirmCreate ? 'Додати категорію до сортування' : 'Ви впевнені, що бажаєте додати?'}
            </div>
            <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0 } }} >

                <Group attrs={{ className: confirmCreate && s.hidden_block }}>
                    <ColFull>
                        <InputFetchResult
                            name="category_id"
                            form={formH}
                            searchParam="title_ua"
                            func={getCat}
                            id="new-category-id"
                            placeholder="Назва*"
                            setProductId={setCategoryId}
                            callback={(e) => setChooseCategory(e)}
                            params={{
                                domain_id: domainId?.toString(),
                                is_active: 1
                            }}
                            message="Категорію не знайдено або вона в іншому домені"
                            required
                        />
                    </ColFull>
                </Group>

                <Group attrs={{ className: confirmCreate && s.hidden_block }}>
                    <ColFull>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                <select
                                    name="sort"
                                    className={s_Select.select}
                                    id="pg-sort"
                                    ref={register}
                                    defaultValue={1}
                                >
                                    {sortArray.map((itemSort) => (
                                        <option key={`modal-sort-item-${itemSort}`} value={itemSort}>
                                            {itemSort}
                                        </option>
                                    ))}
                                </select>
                                <label className={s_Select.label} htmlFor="pg-sort">Позиція</label>
                            </div>
                        </div>
                    </ColFull>
                </Group>

                {confirmCreate && rest?.meta?.total >= 20 && (
                    <div style={{ marginBottom: 24, textAlign: 'center' }}>
                        <span className={s.text_confirm}>
                            Кількість категорій перевищена. Після додавання буде видалено останній товар:
                        </span>
                        <br />
                        <strong className={s.text_confirm}>
                            ID {chooseCategory?.id} / {chooseCategory?.title_ua}
                        </strong>
                    </div>
                )}
                <div className={s.btn_wrap} style={{ justifyContent: (confirmCreate && rest?.meta?.total >= 20) ? 'center' : 'flex-end' }}>
                    {rest?.meta?.total >= 20
                        ? !confirmCreate
                            ? <Button
                                type="button"
                                onClick={() => setConfirmCreate(true)}
                                purple
                                disabled={!categoryId}
                            >
                                Додати
                            </Button>
                            : <Button
                                type="button"
                                onClick={handleSubmit(submitHandler)}
                                purple
                                disabled={!categoryId}
                            >
                                Додати
                            </Button>
                        : <Button
                            type="button"
                            onClick={handleSubmit(submitHandler)}
                            purple
                            disabled={!categoryId}
                        >
                            Додати
                        </Button>}
                    <Button onClick={onClose} border>Скасувати</Button>
                </div>
                {load && <Loader />}
            </Form>
        </div>
    );
};

export default ModalCreate;