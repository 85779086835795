import React from "react";

const TableHead = ({ arrayChildren, filterChildren, showAction = true }) => {
    return (
        <thead>
        <tr>
            {!!arrayChildren?.length && arrayChildren?.map((itemHead, index) => {
                if (typeof itemHead === 'string') {
                    return <th key={`table-head-${index}`}>{itemHead}</th>
                }
                return <th key={`table-head-${index}`} {...itemHead?.attr}>
                    {itemHead?.title || itemHead}
                </th>
            })}
            {showAction && <th style={{ width: '75px' }} />}
        </tr>
        {filterChildren && filterChildren}
        </thead>
    );
};

export default TableHead;
