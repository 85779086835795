import React from 'react';
import cn from "classnames";
import s from "../PopularGood.module.scss";
import { DOMAIN_CLIENT_API } from "../../../const/api";
import icon_mono from "../../../assets/icons/icon_mono.svg";
import icon_privatbank from "../../../assets/icons/icon_privatbank.svg";
import icon_info from "../../../assets/icons/icon_info_black.svg";

const ShowBlock = ({ data, position, desc }) => {
    return (
        <div className={cn(s.show, {
            [s.show__full]: (position?.toString() === "1") || (position?.toString() === "6")
        })}>
            <div className={s.show__img}>
                <img src={`${DOMAIN_CLIENT_API}/${data?.fields?.product?.product_images?.[0]?.image_list?.[0]?.img}`} alt={data?.fields?.product?.product_images?.[0]?.image_list?.[0]?.alt_ua} />
            </div>
            <div>
                <div className={s.show__title}>{data?.fields?.product?.title_ua}</div>
                {desc && ((position?.toString() === "1") || (position?.toString() === "6")) && (
                    <div className={s.show__desc}>{desc}</div>
                )}
                <div>
                    <div>
                        <div className={s.show__price}>
                            XX XXX
                            <span className={s.show__price_small}> грн</span>
                        </div>
                        {((position?.toString() !== "1") && (position?.toString() !== "6")) && (
                            <div className={s.show__mounth}>
                                <span>або XXX грн на XX міс.</span>
                                <img src={icon_info} alt="Icon info" />
                            </div>
                        )}
                    </div>
                    <div className={s.show__credit}>
                        <div>
                            <img src={icon_mono} alt="Icon mono" />
                            <span>х XX міс</span>
                        </div>
                        <div>
                            <img src={icon_privatbank} alt="Icon privat bank" />
                            <span>х XX міс</span>
                        </div>
                    </div>
                    <div className={s.show__btn}>Купити</div>
                </div>
            </div>
        </div>
    );
};

export default ShowBlock;