import React, { useState } from 'react';
import { Link } from "react-router-dom";
import cn from "classnames";
import { PubSub as ps } from "pubsub-js";
import { EDIT } from "../../../const/pubsub";
import Breadcrumbs from "./Breadcrumbs";
import Button from "../../ui/Button";
import s from "./TopPanelNew.module.scss";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import icon_save from "../../../assets/icons/icon_save.svg";
import IconApply from "../../icon/IconApply";
import checkPermission from "../../../lib/checkPermission";
import BtnCreate from "../TopPanelNew/BtnCreate";
import IconRemove from "../../icon/IconRemove";
import ModalRemove from "../../ui/Modal/ModalRemove";
import Modal from "../../ui/Modal";
import icon_add from "../../../assets/icons/icon_add.svg";
import BtnClearParams from "./BtnClearParams";
import BtnExport from "../TopPanel/BtnExport";

const TopPanelNew = ({
                         breadcrumbs,
                         back,
                         updateDate,
                         title,
                         btnEdit,
                         btnApply,
                         btnCreate,
                         btnModal,
                         btnRemove = { api: null, success: null, alert: null, id: null },
                         btnExport = { api: null, success: null, alert: null },
                         btnClearParams,
                         showIsActive,
                         ModalCustom,
                         customButtonTop = null,
                         customButtonTopFirst = null,
                         marginNone,
                         positionSticky = null,
                         showIsAutoAppend
}) => {
    const [activeModalRemove, setActiveModalRemove] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [activeModalCustom, setActiveModalCustom] = useState(false);

    function saveHandler() {
        ps.publish(EDIT.save);
    }

    function applyHandler() {
        localStorage.setItem('apply', 'on');
        ps.publish(EDIT.save);
    }

    const toggleModal = () => setActiveModalRemove(true);

    return (
        <>
            <div className={cn(s.topPanel, {
                [s.topPanel_sticky]: positionSticky
            })} style={{ margin: marginNone && 0 }}>
                <div className={s.breadcrumbs__wrapper}>
                    <div>
                        {breadcrumbs && (
                            <Breadcrumbs {...breadcrumbs} />
                        )}
                        <div className={s.title}>
                            <div>{title}</div>
                        </div>
                    </div>
                    <div className={s.button__wrapper}>
                        {customButtonTopFirst && customButtonTopFirst}
                        {btnExport?.href && (checkPermission(btnExport?.permissionName) && (
                            <div className={s.button__block}>
                                <BtnExport {...btnExport} />
                            </div>
                        ))}
                        {btnClearParams && checkPermission(btnClearParams.permissionName) && (
                            <div className={s.button__block}>
                                <BtnClearParams {...btnClearParams} />
                            </div>
                        )}
                        {btnCreate && checkPermission(btnCreate.permissionName) && (
                            <div className={s.button__block}>
                                <BtnCreate {...btnCreate} />
                            </div>
                        )}
                        {btnModal && checkPermission(btnModal.permissionName) && (
                            <div className={s.button__block}>
                                <Button purple onClick={() => ModalCustom ? setActiveModalCustom(true) : setActiveModal(true)}>
                                    <img src={icon_add} alt="Icon create" />
                                    Створити
                                </Button>
                                {ModalCustom ? (
                                    <ModalCustom
                                        active={activeModalCustom}
                                        setActive={setActiveModalCustom}
                                    />
                                ) : (
                                    <Modal active={activeModal} setActive={setActiveModal} {...btnModal} />
                                )}
                            </div>
                        )}
                        {customButtonTop && customButtonTop}
                    </div>
                    {(!showIsActive && updateDate) && (
                        <div className={s.order_edited}>
                            <div className={s.order_edited__block}>
                                <div className={s.order_edited__title}>Відредаговано:</div>
                                <div className={s.order_edited__date}>{updateDate}</div>
                            </div>
                        </div>
                    )}
                    {(showIsActive && btnEdit) && (
                        <div className={s.buttons__wrapper}>
                            <div className={s.buttons__group}>
                                {btnApply && (
                                    <div className={s.button__block}>
                                        <Button border onClick={applyHandler}>
                                            <IconApply />
                                            Застосувати
                                        </Button>
                                    </div>
                                )}
                                <div className={s.button__block}>
                                    <Button purple onClick={saveHandler}>
                                        <img src={icon_save} alt="Save icon"/>
                                        {btnCreate ? "Створити" : "Зберегти" }
                                    </Button>
                                </div>
                            </div>
                            {(btnRemove.api && btnApply) && (
                                <div className={s.button__remove}>
                                    <Button red onClick={toggleModal}>
                                        <IconRemove />
                                        Видалити
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {btnEdit && (
                    <div className={s.footerNew}>
                        <div>
                            {back && (
                                <Link to={back} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <img src={icon_arrow_back} alt="Arrow back icon"/>
                                    Назад
                                </Link>
                            )}
                        </div>
                        <div style={showIsAutoAppend && { display: 'flex' }}>
                        {showIsAutoAppend && showIsAutoAppend}
                        {showIsActive ? showIsActive
                            : btnEdit && (
                            <div className={s.buttons__wrapper}>
                                <div className={s.buttons__group}>

                                    {btnApply && (
                                        <>
                                            <div className={s.button__block}>
                                                <Link to={back}>Скасувати</Link>
                                            </div>
                                            <div className={s.button__block}>
                                                <Button border onClick={applyHandler}>
                                                    <IconApply />
                                                    Застосувати
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                    <div className={s.button__block}>
                                        <Button purple onClick={saveHandler}>
                                            <img src={icon_save} alt="Save icon"/>
                                            {btnCreate ? "Створити" : "Зберегти" }
                                        </Button>
                                    </div>
                                </div>
                                {(btnRemove.api && btnApply) && (
                                    <div className={s.button__remove}>
                                        <Button red onClick={toggleModal}>
                                            <IconRemove />
                                            Видалити
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                        </div>
                    </div>
                )}
            </div>
            {btnRemove.api && (
                <ModalRemove
                    title={btnRemove.alert}
                    active={activeModalRemove}
                    setActive={setActiveModalRemove}
                    funcRemove={btnRemove.api}
                    navigate={btnRemove.navigate}
                    href={btnRemove.href}
                    id={btnRemove.id}
                />
            )}
        </>
    );
};

export default TopPanelNew;