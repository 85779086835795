import React, { useEffect, useState, Fragment } from 'react';
import s_Table from "../../OrderEditView/fakeData/orderTable.module.scss";
import s from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { geDomainName } from "../../../lib/const/domains";
import { getProductSearch } from "../../../lib/api/products";
import { useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { DOMAIN_API } from "../../../const/api";
import fetchAx from "../../../lib/fetchAx";
import Button from "../../../components/ui/Button";
import cn from "classnames";
import getNumbersWithSpaces from "../../../lib/getNumbersWithSpaces";
import errorMessage from "../../../lib/errorMessage";

const OrderProductTable = ({ formProps, form, setActiveAvailability, setProductId }) => {
    const [productsData, setProductsData] = useState([]);
    const [jde, setJde] = useState('');
    const [disable, setDisable] = useState(false);

    const navigate = useNavigate();

    const dataFields = formProps.data && formProps.data.fields;

    const {remove} = useFieldArray(
        {
            control: form.control,
            name: 'products',
            keyName: 'field_id',
        },
    );

    const handleRemove = (i, id) => {
        // if (onRemove) onRemove(id);
        remove(i);

        setProductsData((prev) => {
            const productsData = [...prev];

            if (productsData[i]) {
                productsData.splice(i, 1);
            }

            return productsData;
        });
    };

    const handleServiceRemove = (index, number) => {
        setProductsData((prev) => {
            const productsData = [...prev];

            if (productsData[index]) {
                productsData[index].product.additional_services.splice(number, 1);
            }

            return productsData;
        });
    };

    const addProduct = async () => {
        setDisable(true);
        await getProductSearch({jde})
            .then((res) => setProductsData((prev) => {
                if(res.data.data[0]) {
                    setJde('');
                    setDisable(false);
                    return [...prev,
                        {
                            // TODO: need fix domain_id when finish word on backend
                            domain: {id: 1, name: geDomainName(1).name},
                            domain_id: 1,
                            product: res.data.data[0],
                            product_id: res.data.data[0].id,
                        }
                    ];
                } else {
                    setJde('');
                    toast.error('Не верный JDE', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setDisable(false);
                    return [...prev];
                }
            })).catch(async (err) => await errorMessage(err , ''));
    };

    useEffect(() => {
        formProps.data?.fields?.cart_products?.map((c, id) => {
            setProductsData((prev) => {
                const productsData = [...prev];
                productsData[id] = {
                    ...prev[id],
                    product: {
                        ...c.product,
                        manager_id: c.manager_id,
                        price: c.price,
                        price_final: c.price_final,
                        additional_services: c.additional_services,
                        special_offers: c.special_offers,
                    },
                    user_id: c.user_id,
                    user_type: c.user_type,
                    product_id: c.product_id,
                    quantity: c.quantity,
                    warehouse: c.warehouse,
                    purchase_quantity: c.purchase_quantity,
                    discount_absolute: c.discount_absolute,
                    discount_bpm: c.discount_bpm,
                    discount_percent: c.discount_percent,
                    selected: c.selected,
                    return_quantity: c.return_quantity,
                };
                return productsData;
            })
        })
    }, []);

    const handleSplitOrder = async () => {
        const parseProducts = productsData.map((product, index) => {
            const selected = formProps.formH.watch(`products[${index}].selected`);
            return {
                ...product,
                additional_services: product.product.additional_services,
                special_offers: product.product.special_offers,
                selected: +selected,
            };
        });

        const data = {
            domain_id: dataFields?.domain_id,
            manager_id: dataFields?.manager_id,
            user_id: dataFields?.user_id,
            user_type: dataFields?.user_type,
            status: dataFields?.status,
            status_payment: dataFields?.status_payment,
            type: dataFields?.type,
            type_payment: dataFields?.type_payment,
            comment_warehouse: dataFields?.comment_warehouse,
            delivery_type: dataFields?.delivery_type,
            warehouse_id: dataFields?.warehouse_id,
            products: parseProducts,
            order_user_info: {
                ...dataFields?.order_user_info,
            },
            credit_user: {
                ...dataFields?.credit_user,
            },
            delivery_delfast: {
                ...dataFields?.delivery_delfast,
            },
            delivery_justin: {
                ...dataFields?.delivery_justin,
            },
            delivery_nova_poshta_address: {
                ...dataFields?.delivery_nova_poshta_address,
            },
            delivery_nova_poshta_warehouse: {
                ...dataFields?.delivery_nova_poshta_warehouse,
            },
            delivery_pickup: {
                ...dataFields?.delivery_pickup,
            },
            delivery_price: dataFields?.delivery_price,
            used_bonuses: dataFields?.used_bonuses,
        };

        if(data.manager_id === null) {
            delete data.manager_id;
        }

        if(data.warehouse_id === null) {
            delete data.warehouse_id;
        }
       await fetchAx({
            url: `${DOMAIN_API}/api/admin/order/${formProps?.orderID}/split`,
            method: 'PUT',
            data,
        })
           .then((res) => console.log(res))
           .catch(async (err) => await errorMessage(err , ''));
        navigate('/app/orders');
        toast('Сделан подзаказ', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <div className={s_Table.table__inner}>
            <div className={s_Table.table__wrapper}>
                <table className={s_Table.table}>
                    <thead className={s_Table.thead}>
                        <tr>
                            <th/>
                            <th>
                                <div>JDE / код МП</div>
                            </th>
                            <th>
                                <div>Название товара</div>
                            </th>
                            <th>
                                <div>Закупка</div>
                            </th>
                            <th>
                                <div>Наличие</div>
                            </th>
                            <th>
                                <div>Кол-во</div>
                            </th>
                            <th>
                                <div>Возврат</div>
                            </th>
                            <th>
                                <div>Скидка, BPM</div>
                            </th>
                            <th>
                                <div>Скидка, %</div>
                            </th>
                            <th>
                                <div>Скидка, абс</div>
                            </th>
                            <th>
                                <div>Вых. цена</div>
                            </th>
                            <th>
                                <div>Стоимость</div>
                            </th>
                            <th>
                                <div>Менеджер</div>
                            </th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody className={s_Table.tbody}>
                        {productsData?.map((item, index) => {
                            const checkService = item?.product?.additional_services?.length !== 0;
                            const checkOffers = item?.product?.special_offers?.length !== 0;

                            const filterWarehouses = item?.product?.warehouse_quantity_main?.filter(ware => {
                                switch (ware.number.toString()) {
                                    case('310'):
                                    case('513'):
                                    case('610'):
                                    case('699'):
                                    case('511'): {
                                        return true;
                                    }
                                    default:
                                        return false;
                                }
                            });

                            return (
                                <Fragment key={`table-body-item-${index}`}>
                                    <tr>
                                        <td>
                                            <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                <input
                                                    className={s.checkbox}
                                                    type="checkbox"
                                                    id={`table_product_${index}`}
                                                    name={`products[${index}].selected`}
                                                    checked={false}
                                                    ref={form.register}
                                                    readOnly
                                                />
                                                <label htmlFor={`table_product_${index}`}
                                                       className={`${s.label} ${s_Table.label}`}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={s_Table.jde}>{item.product.jde}</div>
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].product_id`}
                                                value={item.product_id}
                                                readOnly
                                            />
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].user_id`}
                                                value={item.user_id}
                                                readOnly
                                            />
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].user_type`}
                                                value={item.user_type}
                                                readOnly
                                            />
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].domain_id`}
                                                value={item.domain_id}
                                                readOnly
                                            />
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].warehouse_id`}
                                                value={item.warehouse_id}
                                                readOnly
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <div style={{display: 'flex'}}>
                                                    {!!!checkService && <div className={cn(s_Table.service__block, {
                                                        [s_Table.service__block_second]: index === 1,
                                                        [s_Table.service__block_third]: index === 2,
                                                    })} />}
                                                    {!!!checkOffers && <div className={cn(s_Table.service__block, {
                                                        [s_Table.offer__block]: index === 0,
                                                        [s_Table.offer__block_2]: index === 1,
                                                        [s_Table.offer__block_3]: index === 2,
                                                        [s_Table.offer__block_4]: index === 3,
                                                        [s_Table.offer__block_5]: index === 4,
                                                    })}
                                                    />}
                                                </div>
                                                <Link
                                                    to={`/app/product/update/${item.product.id}`}><strong>{item.product.title_ua}</strong></Link>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={s_Table.purchase__block}>
                                                <div className={s_Table.purchase__price}>699</div>
                                                <div>
                                                    <input
                                                        type="number"
                                                        defaultValue={item.purchase_quantity || 0}
                                                        name={`products[${index}].purchase_quantity`}
                                                        ref={form.register}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {!!filterWarehouses?.length && filterWarehouses.map(ware => (
                                                    <div className={s_Table.availability__block} key={ware.id}>
                                                        <div className={s_Table.availability__stock}>
                                                            {ware.number.toString() === '310' ? '510' : ware.number}
                                                        </div>
                                                        <div className={s_Table.availability__counter}>
                                                            {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                                                        </div>
                                                    </div>
                                                ))}
                                                <div>
                                                    <Button
                                                        purple
                                                        full
                                                        small
                                                        onClick={() => {
                                                            setActiveAvailability(true);
                                                            setProductId(item.product_id);
                                                        }}
                                                    >
                                                        Наличие
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={item.quantity || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].quantity`}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={item.return_quantity || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].return_quantity`}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={item.discount_bpm || 0}
                                                    readOnly
                                                />
                                                <input
                                                    type="hidden"
                                                    value={item.discount_bpm || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].discount_bpm`}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={item.discount_percent || 0}
                                                    name={`products[${index}].discount_percent`}
                                                    ref={form.register}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={item.discount_absolute || 0}
                                                    name={`products[${index}].discount_absolute`}
                                                    ref={form.register}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div className={s_Table.price_all}>{item.product.price_final}</div>
                                                <div
                                                    className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>({item.product.price})
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div className={s_Table.price_all}>{item.product.price_final}</div>
                                                <div
                                                    className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>({item.product.price})
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={s_Table.manager__name}>
                                                {item.product.manager_id ? item.product.manager_id : 'Клиент'}
                                            </div>
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].manager_id`}
                                                value={item.product.manager_id}
                                                readOnly
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <button type="button" disabled className={s_Table.btn__delete}
                                                        onClick={() => handleRemove(index)}/>
                                            </div>
                                        </td>
                                    </tr>
                                    {item.product.additional_services?.map((service, ind) => (
                                        <tr key={ind} className={checkService && s_Table.color_service_gray}>
                                            <td />
                                            <td>
                                                <div className={s_Table.jde}>{service?.additional_service?.jde}</div>
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].additional_service_id`}
                                                    value={service?.additional_service_id}
                                                    readOnly
                                                />
                                            </td>
                                            <td>
                                                <div>
                                                    {checkService && <div className={`${s_Table.service__block} ${index === 1 && s_Table.service__block_second} ${index === 2 && s_Table.service__block_third}`} />}
                                                    <div>{service?.additional_service?.title_ua || service?.title}</div>
                                                </div>
                                            </td>
                                            <td />
                                            <td>
                                                <div>
                                                    <div className={s_Table.availability__block}>
                                                        <div className={s_Table.availability__stock}>510</div>
                                                        <div className={s_Table.availability__counter}>2</div>
                                                    </div>
                                                    <div className={s_Table.availability__block}>
                                                        <div className={s_Table.availability__stock}>511</div>
                                                        <div className={s_Table.availability__counter}>18</div>
                                                    </div>
                                                    <div className={s_Table.availability__block}>
                                                        <div className={s_Table.availability__stock}>513</div>
                                                        <div className={s_Table.availability__counter}>5</div>
                                                    </div>
                                                    <div className={s_Table.availability__block}>
                                                        <div className={s_Table.availability__stock}>610</div>
                                                        <div className={s_Table.availability__counter}>7</div>
                                                    </div>
                                                    <div className={s_Table.availability__block}>
                                                        <div className={s_Table.availability__stock}>Сторонние 699</div>
                                                        <div className={s_Table.availability__counter}>+</div>
                                                    </div>
                                                    <div>
                                                        <Button purple full disabled small>Наличие</Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="number"
                                                           value={service?.quantity}
                                                           ref={form.register}
                                                           name={`products[${index}].additional_services[${ind}].quantity`}
                                                           readOnly
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="number" defaultValue="0.00" readOnly />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="number" defaultValue="0.00" readOnly />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="number" defaultValue="0.00" readOnly />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="number" defaultValue="0.00" readOnly />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className={s_Table.price_all}>{service?.additional_service?.price || service?.price}</div>
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].additional_services[${ind}].price`}
                                                        value={service?.additional_service?.price}
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className={s_Table.price_all}>{service?.additional_service?.price || service?.price}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div />
                                            </td>
                                            <td>
                                                <div>
                                                    <button type="button" disabled className={s_Table.btn__delete} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {item.product.special_offers?.map((offers, i) => {
                                        const filterOffersWarehouses = offers?.promo_product?.warehouse_quantity_main?.filter(ware => {
                                            switch (ware.number.toString()) {
                                                case('310'):
                                                case('513'):
                                                case('610'):
                                                case('699'):
                                                case('511'): {
                                                    return true;
                                                }
                                                default:
                                                    return false;
                                            }
                                        });
                                        return (
                                            <tr key={i} className={cn('', {
                                                [s_Table.color_offer_1]: checkOffers && (index === 0),
                                                [s_Table.color_offer_2]: checkOffers && (index === 1),
                                                [s_Table.color_offer_3]: checkOffers && (index === 2),
                                                [s_Table.color_offer_4]: checkOffers && (index === 3),
                                                [s_Table.color_offer_5]: checkOffers && (index === 4),
                                            })}>
                                                <td />
                                                <td>
                                                    <div className={s_Table.jde}>{offers?.promo_product?.jde}</div>
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].gift_id`}
                                                        value={offers?.promo_product?.id}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].special_offer_id`}
                                                        value={offers?.special_offer_id}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].order_product_id`}
                                                        value={offers?.order_product_id}
                                                    />
                                                </td>
                                                <td>
                                                    <div>
                                                        {checkOffers && <div className={cn(s_Table.service__block, {
                                                            [s_Table.offer__block]: index === 0,
                                                            [s_Table.offer__block_2]: index === 1,
                                                            [s_Table.offer__block_3]: index === 2,
                                                            [s_Table.offer__block_4]: index === 3,
                                                            [s_Table.offer__block_5]: index === 4,
                                                        })}
                                                        />}
                                                        <Link to={`/app/product/update/${offers?.promo_product?.id}`}>
                                                            <strong>
                                                                {offers?.promo_product?.title}
                                                            </strong>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={s_Table.purchase__block}>
                                                        <div className={s_Table.purchase__price}>699</div>
                                                        <div>
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                defaultValue={item.purchase_quantity || 0}
                                                                name={`products[${index}].special_offers[${i}].purchase_quantity`}
                                                                ref={form.register}
                                                                readOnly={statusDisableAll}
                                                                onChange={(e) => onChangeProductOffer('purchase_quantity', +e.target.value, +offers.id, +item.id)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {!!filterOffersWarehouses?.length && filterOffersWarehouses.map(ware => (
                                                            <div className={s_Table.availability__block}>
                                                                <div className={s_Table.availability__stock}>{ware.number}</div>
                                                                <div className={s_Table.availability__counter}>
                                                                    {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div>
                                                            <Button
                                                                purple
                                                                full
                                                                small
                                                                onClick={() => {
                                                                    setActiveAvailability(true);
                                                                    setProductId(offers?.promo_product?.id);
                                                                }}
                                                            >
                                                                Наличие
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            defaultValue={offers?.quantity}
                                                            ref={form.register}
                                                            name={`products[${index}].special_offers[${i}].quantity`}
                                                            min={0}
                                                            max={item?.quantity}
                                                            readOnly={statusDisableAll}
                                                            onChange={(e) => onChangeProductOffer('quantity', +e.target.value, +offers.id, +item.id)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input type="number" defaultValue="0.00" disabled />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input type="number" defaultValue="0.00" disabled />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input type="number" defaultValue="0.00" disabled />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <input type="number" defaultValue="0.00" disabled />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className={s_Table.price_all}>{getNumbersWithSpaces((offers?.price - item.refunded))}</div>
                                                        <input
                                                            type="hidden"
                                                            ref={form.register}
                                                            name={`products[${index}].special_offers[${i}].price`}
                                                            value={offers?.price}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className={s_Table.price_all}>{getNumbersWithSpaces(offers?.price_final)}</div>
                                                        <input
                                                            type="hidden"
                                                            ref={form.register}
                                                            name={`products[${index}].special_offers[${i}].price_final`}
                                                            value={offers?.price_final}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={s_Table.manager__name}>
                                                        {item?.manager ? item?.manager?.name : '---'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button type="button" className={s_Table.btn__delete}
                                                                onClick={() => removeOffer(index, i, formProps?.orderID, item.id, offers.id)} disabled={statusDisableAll ? statusDisableAll : !checkEdit}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className={s_Table.table__footer}>
                <div>
                    <div className={s_Table.suborder}>
                        <div>
                            <Button purple small disabled onClick={() => handleSplitOrder(dataFields)}>Отделить в подзаказ</Button>
                        </div>
                    </div>
                    <div className={s_Table.add_product}>
                        <div>
                            <input
                                type="number"
                                value={jde}
                                onChange={({target}) => setJde(target.value)}
                                placeholder="Введите JDE"
                                readOnly
                            />
                        </div>
                        <div>
                            <Button purple small onClick={addProduct} disabled>Добавить товар</Button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={s_Table.bonus_user}>
                        <div>
                            <input
                                type="number"
                                id="table_used_bonuses"
                                name="used_bonuses"
                                value={formProps?.data?.fields?.used_bonuses}
                                ref={form.register}
                                readOnly
                            />
                            <label htmlFor="table_used_bonuses">Бонусов:</label>
                        </div>
                        <div>
                            <Button purple small disabled>Списать</Button>
                        </div>
                    </div>
                    <div className={s_Table.total_price}>
                        <div>Итого:<b>{formProps.data.fields?.price_final}</b><span> ({formProps.data.fields?.price})</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OrderProductTable;