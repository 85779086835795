import Constants from "./constants";

export const initialSate = {};

const reducer = (state = initialSate, action) => {
  switch (action.type) {
    case Constants.NOTICE_CHANGE:
      return {
        ...state,
        notice: action.payload,
      };

    case Constants.CLEAR_NOTICE:
      return {
        ...state,
        notice: [],
      };

  default:
    return state;
  }
};

export default reducer;
