import React from 'react';
import { Link } from 'react-router-dom';
import { uuid } from 'uuidv4';

const Breadcrumbs = ({ items }) => (
  <ul className="breadcrumb p-0 m-0">
    {items && items.map((item) => (
      <li
        key={`${uuid()}`}
        className="breadcrumb-item"
      >
        {item.url
          ? <Link to={item.url}>{item.title}</Link>
          : item.title}
      </li>
    ))}
  </ul>
);

export default Breadcrumbs;
