import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger } from "redux-logger";
import { ENV_DEV } from '../const/env';
import DevTools from '../containers/DevTools';
import rootReducer from './reducers';

const bindMiddleware = () => {
  if (ENV_DEV) {
    return compose(
      // applyMiddleware(thunk, createLogger()),
      applyMiddleware(thunk),
      DevTools.instrument(),
    );
  }

  return applyMiddleware(thunk);
};

const configureStore = (preloadedState) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    bindMiddleware(),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
