import React from "react";
// import cn from "classnames";

// import s from "./SidebarLeft.module.scss";

const GridEditViewSidebarLeft = ({ children }) => (
  <div>
    <div className="-sticky">
      {children}
    </div>
  </div>
);

export default GridEditViewSidebarLeft;
