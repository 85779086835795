import React, {useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, remove } from "./api";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import { geDomainName } from "../../lib/const/domains";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import ModalCategoriesMenu from "./Modal/ModalCategoriesMenu";

const CategoriesMenuList = () => {
  const [activeModal, setActiveModal] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
      <div style={{ margin: 0 }}>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Menu categories list`} />
        {!load && (
            <TopPagination
                tabs={(
                    <TopNavTabs tabs={[
                      {
                        title: 'Верхнє меню',
                        url: '/app/menu/top-and-bottom',
                        permissionName: 'admin.top-bot-menu.index'
                      },
                      {
                        title: 'Меню категорій',
                        url: '/app/menu/categories',
                        permissionName: 'admin.menu.index',
                        isActive: true
                      }
                    ]}
                    />
                )}
                pagination={(
                    <>
                        {+rest?.total && (
                            <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.total}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )}
        <TableListViewTable>
          <TableHead
              arrayChildren={[
                'ID', 'Назва банера УКР', 'Назва банера РУС', 'Позиція', 'Домен', 'Тип'
              ]}
          />
          <tbody>
          {load
              ? (
                  <TableListViewRowLoader cols={8} />
              )
              : (rest && (
                  rest?.data?.length
                      ? rest?.data?.map((e, i) => (
                          <TableListViewRow
                              id={e.id}
                              key={`menu-category-item-${e.id}`}
                              remove={{
                                api: remove,
                                success: () => setRest(getSplicedData(i, rest)),
                                alert: 'Меню категорії видалено',
                              }}
                              editLink={`/app/menu/categories/update/${e.id}`}
                              permissionRemove='admin.menu.destroy'
                              permissionEdit='admin.menu.edit'
                              setActiveModal={setActiveModal}
                              setActiveId={setActiveId}
                              tagA={false}
                          >
                            <td>
                              {checkPermission('admin.menu.edit')
                                  ? <Link to={`/app/menu/categories/update/${e.id}`}>{e.id}</Link>
                                  : <span>{e.id}</span>
                              }
                            </td>
                            <td>
                              <span>
                                {e?.category?.title_ua || '---'}
                              </span>
                            </td>
                            <td>
                              <span>
                                {e?.category?.title_ru || '---'}
                              </span>
                            </td>
                            <td>
                              <span>
                                {e?.position || '---'}
                              </span>
                            </td>
                            <td>
                              <span>
                                {e?.category?.domain_id ? geDomainName(e?.category?.domain_id)?.name : '---'}
                              </span>
                            </td>
                            <td>
                              <span>
                                {e?.view_type?.toString() === "1"
                                      ? 'Списком'
                                      : e?.view_type?.toString() === "2"
                                      ? 'Плиткою'
                                      : '---'}
                              </span>
                            </td>
                          </TableListViewRow>
                      ))
                      : (
                          <TableListViewRow disabled>
                            <td colSpan={9} style={{ paddingTop: '22px' }}>Немає меню категорії</td>
                          </TableListViewRow>
                      )
              ))}
          </tbody>
        </TableListViewTable>
        {activeModal && (
            <ModalCategoriesMenu
                title="Редагувати меню"
                active={activeModal}
                setActive={setActiveModal}
                editId={activeId}
            />
        )}
      </div>
  );
};

export default CategoriesMenuList;
