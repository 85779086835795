import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import ColRight from "../../../components/ui/FormEditView/ColRight";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputDate from "../../../components/ui/InputDate";
import cn from "classnames";
import { VALIDATION } from "../../../const/text";
import { order_status_payment } from "../../../lib/const/order";
import { uid } from "react-uid";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "./orderStyle.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputWrapper from "../../../components/ui/InputWrapper";
import { addPromo, removePromo } from "../api";
import Loader from "../../../components/ui/Loader";
import Button from "../../../components/ui/Button";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import RSelect from "../../../components/ui/RSelect";
import Constants from "../../../store/orderEdit/constants";
import filterStatusOrder from "../../../lib/helpers/filterStatusOrderShopManager";
import errorMessage from "../../../lib/errorMessage";
import {getCurtRulesCouponsCreate, getCurtRulesDiscountPks} from "../../../lib/api/cart_rules";
import {toast} from "react-toastify";
import { getOrderEdit } from "../../../lib/api/order";

const OrderParamsInfo = ({
                             formProps,
                             setActiveCancelVariant,
                             cancelVariants,
                             statusDisableAll,
                             setRequiredFields,
                             requiredFields,
                             adminCanEdit,
                             setFieldsReqEmail,
                             orderStatus,
                             productsData,
                             setOrderStatus,
                             setProductsData,
                             setTotalPriceData
}) => {
    const dispatch = useDispatch();

    const onChange = (key, value) => dispatch({type: Constants.ON_CHANGE, payload: {[key]: value}});
    const onChangeObj = (key, value, name, id) => dispatch({type: Constants.ON_CHANGE_OBJ, payload: {key, value, name, id}});

    const orderState = useSelector(state => state.orderEdit);

    const form = formProps.formH;
    const dataFields = formProps.data && formProps.data.fields;
    const [availableStatuses, setAvailableStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [promoCode, setPromoCode] = useState('');
    const [warehouseId, setWarehouseId] = useState('');
    const [warehouseSelected, setWarehouseSelected] = useState(null);
    const [actualShipmentWarehouseId, setActualShipmentWarehouseId] = useState('');
    const statusPaymentWatch = form.watch('status_payment');

    const deliveryTypeWatch = formProps.formH.watch('delivery_type');

    useEffect(() => {
        if(orderStatus?.toString() === '4') {
            setRequiredFields(true);
        }
    }, [orderStatus]);

    useEffect(() => {
      if(!formProps.data?.fields?.status_payment){
        setAvailableStatuses([1])
      } else if(formProps.data.fields.status_payment === 1){
        setAvailableStatuses([1, 2])
      } else if(formProps.data.fields.status_payment === 2){
        setAvailableStatuses([3, 4])
      }
    },[]);

    useEffect(() => {
        if(dataFields?.cancel_variant_id) {
            form.clearErrors("cancel_variant_id");
        } else if ((+orderStatus === 16) || (+orderStatus === 17)) {
            form.setError("cancel_variant_id", { type: 'text', message: "Поле должно быть заполненно" });
        }

        if(orderState?.cancel_variant_id) {
            form.clearErrors("cancel_variant_id")
        }
    }, [orderStatus, orderState?.cancel_variant_id, dataFields?.cancel_variant_id]);

    const handleClickPromo = async () => {
        try {
            formProps.setLoad(true);
            await addPromo(dataFields.id, {code: promoCode}).then(async(res) => {
                await getOrderEdit(formProps?.orderID).then(resOrder => {
                    resOrder.data.products.map((item, index) => {
                        setProductsData((prev) => {
                            const productsData = [...prev];
                            productsData[index] = {
                                ...prev[index],
                                product: {
                                    ...item.product,
                                    manager_id: item.manager_id,
                                    price: item.price,
                                    price_final: item.price_final,
                                    total: item.total,
                                    total_full: item.total_full,
                                    additional_services: item.additional_services,
                                    special_offers: item.special_offers,
                                    discounts: item.discounts
                                },
                                manager: item.manager,
                                manager_id: item.manager_id,
                                refunded: item.refunded,
                                has_bundles: item.has_bundles,
                                has_gifts: item.has_gifts,
                                has_discount_offers: item.has_discount_offers,
                                has_additional_services: item.has_additional_services,
                                id: item.id,
                                user_id: item.user_id,
                                user_type: item.user_type,
                                product_id: item.product_id,
                                quantity: item.quantity,
                                warehouse: item.warehouse,
                                purchase_quantity: item.purchase_quantity,
                                discount_amount: Math.round(item.discount_amount),
                                discount_absolute: item.discount_absolute,
                                discount_bpm: item.discount_bpm,
                                total: item.total,
                                discount_percent: item.discount_percent,
                                selected: item.selected,
                                return_quantity: item.return_quantity,
                            };
                            return productsData;
                        })
                    })
                    setTotalPriceData({
                        total: resOrder.data.total,
                        refunded: resOrder.data.refunded,
                        price_final: resOrder.data.price_final,
                    });
                    setPromoCode(resOrder.data?.coupon_code || '');
                    dataFields.coupon_code = resOrder.data?.coupon_code;
                }).catch(async (err) => await errorMessage(err , ''));
            }).finally(() => formProps.setLoad(false));
        } catch (err) {
            if(err.response.status === 422) {
                form.setError('coupon_code', { type: 'text', message: err.response.data.message });
            } else {
                form.setError('coupon_code', { type: 'text', message: "Неизвестная ошибка" });
            }
            await errorMessage(err, '');
        }
    }

    const handleClickRemovePromo = async () => {
        formProps.setLoad(true);
        await removePromo(dataFields.id)
            .then(async (res) => {
                await getOrderEdit(formProps?.orderID).then(resOrder => {
                    resOrder.data.products.map((item, index) => {
                        setProductsData((prev) => {
                            const productsData = [...prev];
                            productsData[index] = {
                                ...prev[index],
                                product: {
                                    ...item.product,
                                    manager_id: item.manager_id,
                                    price: item.price,
                                    price_final: item.price_final,
                                    total: item.total,
                                    total_full: item.total_full,
                                    additional_services: item.additional_services,
                                    special_offers: item.special_offers,
                                    discounts: item.discounts
                                },
                                manager: item.manager,
                                manager_id: item.manager_id,
                                refunded: item.refunded,
                                has_bundles: item.has_bundles,
                                has_gifts: item.has_gifts,
                                has_discount_offers: item.has_discount_offers,
                                has_additional_services: item.has_additional_services,
                                id: item.id,
                                user_id: item.user_id,
                                user_type: item.user_type,
                                product_id: item.product_id,
                                quantity: item.quantity,
                                warehouse: item.warehouse,
                                purchase_quantity: item.purchase_quantity,
                                discount_amount: Math.round(item.discount_amount),
                                discount_absolute: item.discount_absolute,
                                discount_bpm: item.discount_bpm,
                                total: item.total,
                                discount_percent: item.discount_percent,
                                selected: item.selected,
                                return_quantity: item.return_quantity,
                            };
                            return productsData;
                        })
                    })
                    setTotalPriceData({
                        total: resOrder.data.total,
                        refunded: resOrder.data.refunded,
                        price_final: resOrder.data.price_final,
                    });
                    setPromoCode(resOrder.data?.coupon_code || '');
                    dataFields.coupon_code = resOrder.data?.coupon_code;
                }).catch(async (err) => await errorMessage(err , ''));
            })
            .catch(async (err) => await errorMessage(err , ''))
            .finally(() => formProps.setLoad(false));
    }

    const warehouseIdWatch = form.watch('warehouse_id')

    const findCurrentWareHouse = useMemo(() => {
        if(!!formProps.data?.wareHouses?.length && warehouseIdWatch) {
            return formProps.data?.wareHouses?.find(item => item.id === +warehouseIdWatch);
        }
    }, [formProps?.data?.wareHouses, warehouseIdWatch]);

    const findCurrentActualShipmentWarehouseId = useMemo(() => {
        if(!!formProps.data?.wareHouses?.length && dataFields?.actual_shipment_warehouse_id) {
            return formProps.data?.wareHouses?.find(item => item.id === dataFields?.actual_shipment_warehouse_id);
        }
    }, [formProps?.data?.wareHouses, dataFields?.warehouse_id]);

    const filterStatus = useMemo(() => {
        if (!!dataFields?.status_changes?.length) {
            if(!!localStorage?.getItem('roles')?.length) {
                if (JSON.parse(localStorage?.getItem('roles'))?.some(itemRole => ['admin', 'super-admin']?.includes(itemRole))) {
                    if (!!productsData?.length) {
                        return filterStatusOrder(dataFields?.status_changes)
                    } else {
                        const findStatusCancel = dataFields?.status_changes?.find(status => status?.status_to === 17)
                        if (findStatusCancel) {
                            return [findStatusCancel]
                        }
                    }
                } else {
                    if (!!productsData?.length) {
                        const parseStatus = dataFields?.status_changes?.filter(status => ![28, 29]?.includes(status?.status_to))
                        return filterStatusOrder(parseStatus)
                    } else {
                        const findStatusCancel = dataFields?.status_changes?.find(status => status?.status_to === 17)
                        if (findStatusCancel) {
                            return [findStatusCancel]
                        }
                    }
                }
            }
        }

        return [];
    }, [dataFields, productsData]);

    const wareHouseActualShipmentList = useMemo(() => {
        if (formProps?.data?.wareHouses) {
            return formProps.data?.wareHouses?.filter(({ type }) => [2, 4].includes(type))
        }
        return null;
    }, [formProps?.data?.wareHouses]);

    useEffect(() => {
        if(dataFields) {
            if(dataFields?.coupon_code !== null) {
                setPromoCode(dataFields?.coupon_code)
            }

            if(dataFields?.warehouse_id !== null) {
                setWarehouseId(dataFields?.warehouse_id)
            }

            if(dataFields?.actual_shipment_warehouse_id) {
                setActualShipmentWarehouseId(dataFields?.actual_shipment_warehouse_id)
            }
            if(dataFields?.status && formProps?.data?.statuses) {
                const findSelected = formProps?.data?.statuses?.find((item) => item?.id === dataFields?.status);
                setSelectedStatus(findSelected);
            }
        }
    }, [dataFields]);

    useEffect(() => {
        if(deliveryTypeWatch === '7' && !!formProps.data?.wareHouses?.length) {
            const findWarehouse = formProps.data?.wareHouses?.find(item => item.id === 662);
            if (findWarehouse) {
                setWarehouseSelected(findWarehouse);
            }
            onChange('warehouse_id', '662');
            setWarehouseId('662');
        }
    }, [deliveryTypeWatch]);

    useEffect(() => {
        if(dataFields?.warehouse) {
            setWarehouseSelected(dataFields.warehouse);
        }
    }, [dataFields]);

    useEffect(() => {
        setFieldsReqEmail(prev => ({
            ...prev,
            warehouse_id: warehouseId.toString(),
        }));
    }, [warehouseId]);

    useEffect(() => {
        if(requiredFields) {
            form.setError('warehouse_id', { type: 'text', message: 'Поле не должно быть пустым' });

            if(!!warehouseId?.toString()?.length) {
                form.clearErrors('warehouse_id');
            }
        } else {
            form.clearErrors('warehouse_id');
        }
    }, [requiredFields, warehouseId]);

    useEffect(() => {
        if(typeof statusPaymentWatch !== 'undefined') {
            setFieldsReqEmail(prev => ({
                ...prev,
                status_payment: statusPaymentWatch,
            }));
        }
    }, [statusPaymentWatch]);

    useEffect(() => {
        onChange('promoCode', dataFields?.coupon_code);
    }, [dataFields?.coupon_code]);

    useEffect(() => {
        onChange('promoCode', promoCode);
    }, [promoCode, orderStatus])

    return(
        <Item title='Параметры заказа' statusDisableAll={statusDisableAll}>
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag='div'>

                    {/* created_at */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s.wrapper}>
                                <div className={s.input__wrapper}>
                                    {dataFields?.id ? (
                                        <InputDate
                                            withZero={false}
                                            id="or-created_at"
                                            name="created_at"
                                            className={s.input}
                                            defVal={dataFields?.created_at.split(" ")[0]}
                                            placeholder="Выберите дату"
                                            formH={form}
                                            required={false}
                                            disabled={true}
                                        />
                                    ) : (
                                        <InputDate
                                            withZero={false}
                                            id="or-created_at"
                                            name="created_at"
                                            className={s.input}
                                            defVal=''
                                            placeholder="Выберите дату"
                                            formH={form}
                                            required={false}
                                        />
                                    )}

                                    <label className={s.label} htmlFor="or-created_at">Дата создания</label>
                                </div>
                            </div>
                            <input type="hidden" name="created_at" defaultValue={dataFields?.created_at.split("T")[0]} ref={form.register} />
                        </ColFull>
                    </Group>

                    {/* manager */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.text__wrapper}>
                                <div>Создан менеджером</div>
                                <div className={s_Select.text}>{dataFields?.manager ? dataFields?.manager.name : 'Клиент'}</div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="domain_id"
                                                className={cn(s_Select.select, {
                                                    [s_Select.select_error]: form.errors?.domain_id,
                                                })}
                                                id="or-domain"
                                                ref={form.register({
                                                    required: VALIDATION.select.required,
                                                })}
                                                onChange={(e) => onChange('domain_id', e.target.value)}
                                            >
                                                <option value="">выберите cайт</option>
                                                {formProps.data && !!formProps.data.domains && formProps.data.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                        selected={domain.id === dataFields?.domain_id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="or-domain">Сайт*</label>
                                </div>
                                {form.errors?.domain_id
                                && <p className="form-input-hint">{form.errors.domain_id?.message}</p>}
                            </div>
                        </ColFull>
                    </Group>

                    {/* warehouse_id */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s_Input.z_index_6]: dataFields?.status === 33,
                        })}}>
                        <ColFull>
                            {formProps.data
                                ? (
                                    <div className={s_Select.select__inner}>
                                        <div className={cn('', {
                                            "react_select__wrapper_error": form.errors.warehouse_id?.message
                                        })}>
                                            <RSelect
                                                props={{
                                                    options: formProps.data?.wareHouses,
                                                    getOptionLabel: (e)=> e.title_ru || e.title_ua,
                                                    getOptionValue: (e) => e.id,
                                                    classNamePrefix: 'react-select',
                                                    placeholder: 'Склад отгрузки*',
                                                    value: warehouseSelected,
                                                    onChange: (e) => {
                                                        if (e) {
                                                            onChange('warehouse_id', e?.id ? e?.id : '');
                                                            setWarehouseSelected(e);
                                                            return setWarehouseId(e?.id ? e?.id : '');
                                                        } else {
                                                            onChange('warehouse_id', null);
                                                            setWarehouseSelected(null);
                                                            return setWarehouseId('');
                                                        }
                                                    },
                                                }}
                                            />
                                            {form.errors?.warehouse_id
                                            && <p className="form-input-hint">{form.errors.warehouse_id?.message}</p>}
                                            <label className={`${s_Select.label} ${s_Select.label_react_select}`} htmlFor="or-warehouse_id">Склад отгрузки*</label>
                                        </div>

                                        {requiredFields && (
                                            <input type="hidden" ref={form.register({
                                                required: VALIDATION.req.required,
                                            })} name="warehouse_id" value={warehouseId} />
                                        )}
                                        {!requiredFields && <input type="hidden" ref={form.register} name="warehouse_id" value={warehouseId} />}
                                    </div>
                                )
                                : (
                                    <div className="p-relative">
                                        <div className="loading" />
                                    </div>
                                )}
                        </ColFull>
                    </Group>

                    {/* actual_shipment_warehouse_id */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s_Input.z_index_6]: dataFields?.status === 33,
                        })}}>
                        <ColFull>
                            {formProps.data
                                ? warehouseId && (
                                    <div className={s_Select.select__inner}>
                                        <div>
                                            <RSelect
                                                props={{
                                                    options: wareHouseActualShipmentList,
                                                    getOptionLabel: (e)=> e.title_ru || e.title_ua,
                                                    getOptionValue: (e) => e.id,
                                                    classNamePrefix: 'react-select',
                                                    placeholder: 'Склад фактической отгрузки',
                                                    defaultValue: findCurrentActualShipmentWarehouseId,
                                                    onChange: (e) => {
                                                        if (e) {
                                                            onChange('actual_shipment_warehouse_id', e?.id ? e?.id : '');
                                                            return setActualShipmentWarehouseId(e?.id ? e?.id : '');
                                                        } else {
                                                            onChange('actual_shipment_warehouse_id', null);
                                                            return setActualShipmentWarehouseId('');
                                                        }
                                                    },
                                                }}
                                            />
                                            <label
                                                className={`${s_Select.label} ${s_Select.label_react_select}`}
                                                htmlFor="or-actual_shipment_warehouse_id"
                                            >
                                                Склад фактической отгрузки
                                            </label>
                                        </div>
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name="actual_shipment_warehouse_id"
                                            value={actualShipmentWarehouseId}
                                        />
                                    </div>
                                )
                                : (
                                    <div className="p-relative">
                                        <div className="loading" />
                                    </div>
                                )}
                        </ColFull>
                    </Group>

                    {/* warehouse street and phone */}
                    {!!findCurrentWareHouse?.phones?.length && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <div className={s_Select.text__wrapper}>
                                    <div>Адрес и телефон</div>
                                    <div>
                                        {findCurrentWareHouse?.phones?.map((phone, indPhone) => (
                                            <div className={s_Select.phone__item} key={phone.id}>
                                                <div className={s_Select.text}>
                                                    {(dataFields?.warehouse_id && (indPhone === 0)) && (
                                                        <span className={s_Select.phone__address}>
                                                            {findCurrentWareHouse?.address}<br/>
                                                        </span>
                                                    )}
                                                    {phone.phone}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                    {/* domain_title */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.text__wrapper}>
                                <div>Создан в магазине</div>
                                <div className={s_Select.text}>{formProps?.data?.fields?.domain?.title ? formProps?.data?.fields?.domain?.title : ''}</div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* order_comment[text] */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s_Input.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.textarea__wrapper}>
                                    <textarea
                                        name="order_comment.text"
                                        className={s_Select.textarea}
                                        id="or-order_comment"
                                        placeholder="Комментарий к заказу"
                                        rows="4"
                                        ref={form.register}
                                        onChange={(e) => onChangeObj('text', e.target.value, 'order_comment')}
                                    />
                                    <label className={s_Select.label__textarea} htmlFor="or-order_comment">Комментарий к заказу</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* coupon_code */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s_Input.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <div className={s_Select.promocode}>
                                <div>
                                    <InputWrapper
                                        label="Промокод"
                                        id="or-coupon-code"
                                        errorComponent={form.errors.coupon_code && <p className="form-input-hint">{form.errors.coupon_code.message}</p>}
                                    >
                                        <input
                                            className={cn(s.input, {
                                                [s.input_error]: form.errors.coupon_code,
                                            })}
                                            type="text"
                                            placeholder="Промокод"
                                            maxLength={INPUT_MAX_LENGTH}
                                            ref={form.register}
                                            name="coupon_code"
                                            id="or-coupon-code"
                                            value={promoCode}
                                            onChange={(e) => setPromoCode(e.target.value)}
                                            readOnly={statusDisableAll}
                                        />
                                    </InputWrapper>
                                </div>

                                {dataFields?.coupon_code
                                    ? <span onClick={handleClickRemovePromo}>Отменить</span>
                                    : <button type="button" onClick={handleClickPromo} disabled={promoCode === ""} />
                                }

                            </div>
                        </ColFull>
                    </Group>

                    {/* status */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data && (
                                        <select
                                            name="status"
                                            className={cn(s_Select.select, {
                                                [s_Select.select_error]: form.errors?.status,
                                            })}
                                            id="order-status"
                                            ref={form.register({
                                                required: VALIDATION.select.required,
                                            })}
                                            onChange={(e) => {
                                                onChange('status', Number(e.target.value));
                                                setOrderStatus(Number(e.target.value));
                                            }}
                                            disabled={!formProps?.data?.fields?.status_transition?.is_allowed}
                                        >
                                            <option value="">выберите статус</option>
                                            <option value={Number(selectedStatus?.id)} selected={selectedStatus?.id === dataFields?.status}>{selectedStatus?.name}</option>
                                            {!!filterStatus?.length && filterStatus?.map((item) => {
                                                const nameStatus = formProps?.data?.statuses?.find((order_status) => order_status?.id === item?.status_to)?.name;
                                                return (
                                                    <option
                                                        key={uid(item?.status_to)}
                                                        value={Number(item?.status_to)}
                                                    >
                                                        {nameStatus}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    )}
                                    <label className={s_Select.label} htmlFor="order-status">
                                        Статус заказа
                                    </label>
                                </div>
                                {form.errors?.status
                                && <p className="form-input-hint">{form.errors.status?.message}</p>}
                            </div>
                        </ColFull>
                    </Group>

                    {/* cancel_variant_id */}
                    {((+orderStatus === 16) || (+orderStatus === 17)) && (
                        <Group attrs={{className: 'mt-1 mb-1', style: {zIndex: 10, position: "relative"}}}>
                            <ColFull>
                                <div className={`${s_Select.select__inner} ${s_Select.select__inner_btn}`}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                name="cancel_variant_id"
                                                className={cn(s_Select.select, {
                                                    [s_Select.select_error]: form.errors?.cancel_variant_id,
                                                })}
                                                id="or-cancel_variant"
                                                ref={form.register({
                                                    required: VALIDATION.select.required,
                                                })}
                                                onChange={(e) => onChange('cancel_variant_id', e.target.value)}
                                            >
                                                <option value="">выберите причину отказа</option>
                                                {!!cancelVariants.length && cancelVariants.map((type) => (
                                                    <option
                                                        key={type.id}
                                                        value={type.id}
                                                        selected={type.id === dataFields?.cancel_variant_id}
                                                    >
                                                        {type.title_ru || type.title_ua}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={s_Select.label} htmlFor="or-cancel_variant">
                                                Причина отказа
                                            </label>
                                        </div>
                                        {form.errors?.cancel_variant_id
                                        && <p className="form-input-hint" style={{ color: "#da3b2f" }}>{form.errors.cancel_variant_id.message}</p>}
                                    </div>
                                    <Button border borderRadius onClick={() => setActiveCancelVariant(true)}>+</Button>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                    {/* status_payment */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data && (
                                        <select
                                            name="status_payment"
                                            className={cn(s_Select.select, {
                                                [s_Select.select_error]: form.errors?.status_payment,
                                            })}
                                            id="or-order_status_payment"
                                            ref={form.register({
                                                required: VALIDATION.select.required,
                                            })}
                                            onChange={(e) => onChange('status_payment', e.target.value)}
                                        >
                                            <option value="">выберите статус</option>
                                            {order_status_payment.map((status) => (
                                                <option
                                                    key={uid(status)}
                                                    value={status.id}
                                                    // disabled={!availableStatuses.includes(status.id)}
                                                    selected={status.id === dataFields?.status_payment}
                                                >
                                                    {status.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <label className={s_Select.label} htmlFor="order-status-payment">Статус оплаты</label>
                                </div>
                                {form.errors?.status_payment
                                && <p className="form-input-hint">{form.errors.status_payment.message}</p>}
                            </div>
                        </ColFull>
                    </Group>

                    {/* type */}
                    <ColRight>
                        <input
                            type="hidden"
                            name={`type`}
                            defaultValue={4}
                            ref={form.register}
                        />
                    </ColRight>
                </Form>

                {formProps.load && <Loader />}
            </form>
        </Item>
    )
}

export default OrderParamsInfo
