import {
    getSortBannersTypes,
    getSortBannerMainPage,
    removeSortBannerMainPage,
    updateSortBannerMainPage,
    refreshSortBannerMainPage, editSortBannerMainPage
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getSortBannersTypes()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banner_types: res[1]?.value?.data
    }));


export const edit = editSortBannerMainPage;

export const getData = getSortBannerMainPage;
export const updateSort = updateSortBannerMainPage;

export const refreshSort = refreshSortBannerMainPage;
export const remove = removeSortBannerMainPage;
