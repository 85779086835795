import { getCreditOffers, removeCreditOffer } from '../../lib/api/creditOffers';
import { getDomains } from '../../lib/api/domains';
import { getBanks } from '../../lib/api/banks';

export const getFiltersData = () => Promise.allSettled([
  getDomains(),
  getBanks(),
])
  .then((res) => ({
    domains: res[0]?.value?.data,
    banks: res[1]?.value?.data?.data,
  }));

export const getData = getCreditOffers;
export const remove = removeCreditOffer;
