import { getOrderDocuments } from "../../lib/api/order_document";
import fetchAx from "../../lib/fetchAx";
import { DOMAIN_API } from "../../const/api";

const orderId = location.pathname.split('/')[4];

const removeOrderDocument = (templateId) => fetchAx({
    url: `${DOMAIN_API}/api/admin/order/${orderId}/document/${templateId}`,
    method: 'DELETE'
});

export const getData = getOrderDocuments;
export const remove = removeOrderDocument;
