import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import Group from "../../../components/ui/FormEditView/Group";
import ColLeft from "../../../components/ui/FormEditView/ColLeft";
import ColRight from "../../../components/ui/FormEditView/ColRight";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import GrapesjsConstructor from "../../../components/ui/GrapesjsConstructor/GrapesjsConstructor";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import BlockImages from "../../../components/ui/GrapesjsConstructor/Component/BlockImages";

const TabConstructorUa = ({
                          index = 0,
                          formProps,
                          data,
                          htmlData,
                        }) => {
  const dataFields = formProps.data && formProps.data.fields;

  const form = useForm({
    defaultValues: dataFields,
  });

  // add to tabs form
  useEffect(() => {
    formProps.tabsForm.addForm(form, index);
  }, []);

  function submitHandler(e) {
    formProps.tabsForm.validate(e, formProps.submitHandler);
  }

  /* eslint-disable */
  return (
      <form
          style={formProps.tabsForm.getDStyle(index)}
          onSubmit={submitHandler}
      >
        <button type="submit" className="d-hide" />
        <div className="box-wrap box-wrap--mb">
          <Form Tag="div">

            {/* desc_ua */}
            <Group attrs={{ className: 'max-width' }}>
              <ColLeft col={2}>
                <label className="form-label" htmlFor="art-desc-ua">Анонс УКР:</label>
              </ColLeft>
              <ColRight col={10}>
                <input
                    className='form-input'
                    name="desc_ua"
                    type="text"
                    id="art-desc-ua"
                    placeholder="Введите анонс"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={form.register}
                />
              </ColRight>
            </Group>

            <GrapesjsConstructor
                grapesjsId="gjs-ua"
                galleryId={dataFields?.id}
                galleryType="article"
                galleryImageType="images"
                initHtml={htmlData}
                formH={form}
                data={data}
                nameFieldHtml="body_ua.text"
                nameFieldCss="body_ua.css"
                nameAssets="assets_data_ua"
            />

              <Group>
                  <ColFull>
                      {dataFields?.id && (
                          <BlockImages
                              formH={form}
                              galleryImageType="images"
                              galleryId={dataFields?.id}
                              galleryType="article"
                              typeFile="video"
                              nameParent="assets_videos_ua"
                              idParent="assets-videos-ua"
                              create
                              remove
                              copyBtn
                          />
                      )}
                  </ColFull>
              </Group>

          </Form>
          {formProps.load && <Loader />}
        </div>
      </form>
  );
};

export default TabConstructorUa;

