import {parseAll} from '../../lib/api/_parseApi';
import {createSeoMeta, editSeoMeta, getSeoMetaEdit, removeSeoMeta,} from '../../lib/api/page_settings';
import {getDomains} from "../../lib/api/domains";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (seoMetaId) => Promise.allSettled([getDomains(), seoMetaId
&& getSeoMetaEdit(seoMetaId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: seoMetaId && res[1]?.value?.data,
    })
  });

export const create = createSeoMeta;
export const edit = editSeoMeta;
export const remove = removeSeoMeta;

export const parseDataSend = (data, catId) => {
  let d = {...data};
  const isUpdate = typeof catId !== 'undefined';
  d = parseAll(d, !isUpdate, [ 'is_active', 'is_noindex', 'is_nofollow'
  ]);

  d.redirect = 0;

  return d;
};

export const parseDataGet = (data) => {
  const d = {...data};

  return d;
};
