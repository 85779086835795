import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import {
  getSelectionEdit,
  createSelection,
  editSelection,
  removeSelection
} from "../../lib/api/selections";
import { refreshToken } from "../../lib/crud/refreshToken";
import { getCatTree } from "../../lib/api/categories";

export const getData = (collectionID) => Promise.allSettled([getDomains(), getCatTree(), collectionID
&& getSelectionEdit(collectionID)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      categories: res[1]?.value?.data,
      fields: collectionID && res[2]?.value?.data?.data,
    })
  });

export const create = createSelection;
export const edit = editSelection;
export const remove = removeSelection;

export const parseDataSend = (data, bmhId) => {
  let d = { ...data };
  const isUpdate = typeof bmhId !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'is_active'
  ]);

  d.title_ua = d.title;

  if (!!d?.category_ids?.length) {
      d.category_ids = d?.category_ids?.filter(item => item)?.map(item => +item);
  } else {
      d.category_ids = [];
  }

  if (!!d?.product_ids?.length) {
      d.product_ids = d?.product_ids?.filter(item => item)?.map(item => +item);
  } else {
      d.product_ids = [];
  }

  if (!!d?.excluded_product_ids?.length) {
      d.excluded_product_ids = d?.excluded_product_ids?.filter(item => item)?.map(item => +item);
  } else {
      d.excluded_product_ids = null;
  }

  delete d.product_id;

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

    if (!!d?.excluded_product_ids?.length) {
        d.excluded_product_ids = d?.excluded_product_ids?.map(item => item?.toString());
    } else {
        d.excluded_product_ids = null;
    }

    if (d?.starts_at) {
        d['starts_at'] = `${d.starts_at} ${d.time_from}`;
    }

    if (d?.finish_at) {
        d['finish_at'] = `${d.finish_at} ${d.time_to}`;
    }

  return d;
};
