import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useEdit from "../../lib/crud/useEdit";
import Loader from "../../components/ui/Loader";
import Form from "../../components/ui/FormEditView/Form";
import Group from "../../components/ui/FormEditView/Group";
import {
    create, edit, getData, parseDataGet, parseDataSend, remove,
} from "./api";
import InputFile from "../../components/ui/InputFile";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import s from "./Warehouse.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import BasicInformation from "./components/BasicInformation";
import SenderInformation from "./components/SenderInformation";
import ColFull from "../../components/ui/FormEditView/ColFull";

const WarehouseEditView = () => {
    const {id: whID} = useParams();
    const formH = useForm();
    const {
        register,
        handleSubmit,
        reset,
        watch
    } = formH;

    const {load, data, submitHandler} = useEdit({
        targetID: whID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/warehouse/not-found',
            remove: `/app/warehouses${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/warehouses${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Склад створено',
            edit: 'Склад оновлено',
            remove: 'Склад видалено',
        },
    });
    console.log(whID, 'whID');
    /* eslint-disable */
    return (
        <>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Warehouse edit`}/>
            <Form attrs={{onSubmit: handleSubmit(submitHandler), style: {paddingTop: 0}}}>

                <button type="submit" className="d-hide"/>

                <TopPanelChild
                    title={whID ? data && data?.fields?.title_ua : "Новий склад"}
                    breadcrumbs={{
                        items: [
                            {
                                title: "Головна",
                                url: "/app",
                            },
                            {
                                title: "Склади",
                                url: `/app/warehouses${document?.referrer?.split('?')?.[1] ? `?${document?.referrer?.split('?')?.[1]}` : ''}`
                            },
                            {
                                title: whID ? data && data?.fields?.title_ua : "Новий склад"
                            },
                        ],
                    }}
                    back={`/app/warehouses${document?.referrer?.split('?')?.[1] ? `?${document?.referrer?.split('?')?.[1]}` : ''}`}
                    id={whID}
                    children={<div className={s.top__item}>
                        {/* is_active */}
                        <CheckboxSwitch
                            name="is_active"
                            id="b-is-active"
                            label="Активність"
                            formH={formH}
                            defaultChecked={data?.fields?.is_active}
                        />
                    </div>}
                    btnEdit
                    btnCreate={{
                        title: 'Створити',
                        permissionName: 'admin.characteristic.store',
                    }}
                />

                {!load ? (
                    <div className={s.top}>
                        <BasicInformation data={data} formH={formH}/>
                        <SenderInformation data={data} formH={formH}/>
                        {/* img_ua */}
                        <Group>
                            <ColFull>
                                <InputFile
                                    name="img"
                                    val={watch('img')}
                                    formH={formH}
                                    ref={register}
                                    acceptStr="PNG, JPG, GIF"
                                    accept="image/*"
                                    sizeMB={1}
                                    width={342}
                                    height={192}
                                    maxSize="1920х1080 px"
                                    labelDownload="Додати зображення"
                                />
                            </ColFull>
                        </Group>
                    </div>
                ) : <Loader/>}
            </Form>
        </>
    );
};

export default WarehouseEditView;
