import React from 'react';
import cn from "classnames";
import s from "../SpecOfferEdit.module.scss";
import ProductBlock from "./block/ProductBlock";
import BlockCheckbox from "./block/BlockCheckbox";

const TypeOne = ({
                     activeTab,
                     setActiveTab,
                     tabList,
                     bonusTypeID,
                     domainWatch,
                     data,
                     formH,
                     showDiscountType1,
                     rightSectionSecondOptionName,
                     register,
                     title
                 }) => {
    return (
        <div style={{ width: "100%" }}>
            {!!tabList.length && <ul className={s.tab}>
                {tabList.map((el, index) => {
                    return (
                        <li className={cn(s.tab__item, {
                            ['active']: el.id === activeTab,
                            [s.tab__line]: el.id === activeTab,
                        })} key={index}>
                            <div onClick={() => setActiveTab(el.id)}>{el.title}</div>
                        </li>
                    )
                })}
            </ul>}
            <div className={s.tabs__wrapper} style={(!!tabList.length || rightSectionSecondOptionName) ? {margin: '0'} : {}}>

                {bonusTypeID &&  ['1', '2', '3'].includes(bonusTypeID.toString()) && (
                    <>
                        {/* TAB A */}
                        <ProductBlock
                            name="special_offer_type1_products"
                            sendName="special_offer_type1_product"
                            title={title ? title : 'Товар А'}
                            domainWatch={domainWatch}
                            data={data}
                            formH={formH}
                            activeTab={activeTab}
                            showActive={0}
                            discountType3ProductA={showDiscountType1}
                            rightSectionSecondOptionName={rightSectionSecondOptionName}
                            register={register}
                        />

                        {/* TAB unaccounted_products */}
                        <BlockCheckbox
                            data={data}
                            formH={formH}
                            activeTab={activeTab}
                            showActive={1}
                            name="special_offer_type1_bonus"
                            addName="bonus_type1"
                            sendName="special_offer_type1_bonus_type1"
                            listName="unaccounted_products"
                            title="Не підрахункові товари"
                        />

                        {/* TAB additional_services */}
                        <BlockCheckbox
                            data={data}
                            formH={formH}
                            activeTab={activeTab}
                            showActive={2}
                            name="special_offer_type1_bonus"
                            addName="bonus_type2"
                            sendName="special_offer_type1_bonus_type2"
                            listName="additional_services"
                            title="Додаткови сервисі"
                        />
                    </>
                )}
            </div>
        </div>
    );
};
export default TypeOne;