export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.custom-col-img-top-text-bottom > div.col {
                justify-content: flex-start;
                align-items: flex-start;
            
            }
        
            div.desc_text_custom > span {
                font-size: 18px;
                line-height: 24px;
            }
            
            div.small_text_custom > span {
                font-size: 14px;
                line-height: 16px;
            }
            
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                 h3.h3-native {
                    font-size: 24px !important;
                }
                
                div.small_text_custom > span {
                    font-size: 11px;
                    line-height: 13px;
                }
                
                div.desc_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row row-cols-1 custom-col-img-top-text-bottom">
                <div class="col d-flex flex-column">
                    <div class="col d-flex mb-1 mb-lg-2">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                    <h3 class="h3-native d-flex mb-2 mb-lg-4"><span>Безупречный экран для безупречной графики</span></h3>
                    <div class="desc_text_custom d-flex mb-2 mb-lg-4"><span>Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости. Благодаря адаптивной частоте обновления до 120 Гц обеспечивается необходимая плавность на дисплее и низкое потребление энергии.</span></div>
                    <div class="small_text_custom d-flex mb-2 mb-lg-4"><span>*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3. Яркость дисплея может достигать уровня 1750 nit, усиливая контраст между светлыми и темными фрагментами контента для лучшего качества. Коэффициент контраста составляет 3 000 000:1, что сделает ваш мобильный опыт незабываемым.</span></div>
                </div>
            </div>
            ${style}
        `,
    })
};