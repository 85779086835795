import { uid } from 'react-uid';
import cn from 'classnames';
import React from 'react';

import s from './SideNavEditView.module.scss';

const SideNavEditView = ({
  items, onClick, checkActiveCb, checkErrorCb,
}) => {
  function clickHandle(e, i) {
    if (onClick) onClick(i);
  }

  return (
    <div className={s.wrap}>
      <ul className={s.list}>
        {items.map((item, index) => {
          const active = checkActiveCb(index);
          const error = checkErrorCb(index);

          return (
            <li
              className={s.item}
              key={uid(item)}
            >
              <button
                type="button"
                className={cn(s.link,
                  { 'bg-secondary': active },
                  { 'text-error': error })}
                onClick={(e) => {
                  clickHandle(e, index);
                  if(item.onClick) {
                    item.onClick()
                  }
                }}
              >
                {item.title}
                {error && <span className="bg-error" />}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SideNavEditView;
