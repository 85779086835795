import React from 'react';
import { LIST_PER_PAGE } from '../../../../const/view';

import s from './SelectPerPage.module.scss';
import SelectCustom from "./SelectCustom";

const TableListViewPerPage = ({ total, urlParams, onSelect }) => {
  return (
    <div className={s.wrap}>
      {urlParams && onSelect && (
        <SelectCustom onSelect={onSelect} urlParams={urlParams} />
      )}
      <div className={s.text}>Всего: <b>{total}</b></div>
    </div>
  );
};

export default TableListViewPerPage;
