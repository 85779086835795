import { ERROR_FILE_INVALID } from '../../const/text';
import { editMedia, saveMedia } from '../api/media';
import { refreshToken } from "../crud/refreshToken";
import errorMessage from "../errorMessage";

/**
 * Load file and get url on success
 *
 * @param file
 * @param onSuccess
 * @param domain_id
 * @param collection_name
 * @param entity_id
 * @param entity_type
 * @param errorText
 * @returns {Promise<void>}
 */
const loadMedia = async (
    file,
    onSuccess,
    errorText = '',
    domain_id = '1',
    collection_name = 'images',
    entity_id,
    entity_type,
    altData = {},
    setLoad
   ) => {
  const formData = new FormData();
  formData.append('file', file ,file.name);
  formData.append('domain_id', domain_id);
  formData.append('collection_name', collection_name);
  formData.append('entity_id', entity_id);
  formData.append('entity_type', entity_type);

  try {
    // set data
    const resp = await saveMedia(formData);
    if(!!Object.keys(altData)?.length) {
      await editMedia(resp?.data?.media?.id, {custom_properties: altData}).then(res => {
        if (onSuccess) onSuccess(res?.data);
      })
    } else {
      if (onSuccess) onSuccess(resp?.data?.media);
    }

  } catch (err) {
    if(err?.response?.status === 401) {
      await refreshToken(async () => {
        const resp = await saveMedia(formData);
        if(!!Object.keys(altData)?.length) {
          await editMedia(resp?.data?.media?.id, {custom_properties: altData}).then(res => {
            if (onSuccess) onSuccess(res?.data);
          })
        } else {
          if (onSuccess) onSuccess(resp?.data?.media);
        }
      })
    } else {
      alert(ERROR_FILE_INVALID + errorText);
      await errorMessage(err, '/api/admin/media/position POST')
    }

    if (setLoad) {
      setLoad(false);
    }
  }
};

export default loadMedia;
