import { getPurchases, sendPurchase } from '../../lib/api/purchase';
import { getDomains } from "../../lib/api/domains";
import { getWh } from "../../lib/api/warehouses";
import { getAdminsAll } from "../../lib/api/admins";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getWh(), getAdminsAll()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        wareHouses: res[1]?.value?.data,
        managers: res[2]?.value?.data?.data,
    }));

export const getData = getPurchases;
export const sendData = sendPurchase;
