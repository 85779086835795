import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import useIsMounted from "../../lib/useIsMounted";
import useList from '../../lib/crud/useList';
import { getData, remove } from "./api";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import getSplicedData from "../../lib/crud/getSplicedData";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import checkPermission from "../../lib/checkPermission";
import CopyComponent from "../../components/ui/CopyComponent";
import getTZtoDate from "../../lib/getTZtoDate";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const FeedsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    })

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Feed list`} />
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Назва', 'Ім\'я файлу', 'Дата генерації', 'Статус',
                        'Фід URL', 'Статус активності'
                    ]}
                />
                <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={6} />
                        )
                        : (rest && (
                            rest?.data?.length
                                ? rest?.data?.map((e, i) => (
                                    <TableListViewRow
                                        id={e?.id}
                                        key={`feed-item-${e?.id}`}
                                        remove={{
                                            api: remove,
                                            success: () => setRest(getSplicedData(i, rest)),
                                            alert: 'Фід видалено',
                                        }}
                                        editLink={`/app/feed/update/${e?.id}`}
                                        permissionRemove='admin.product-feed-settings.destroy'
                                        permissionEdit='admin.product-feed-settings.edit'
                                    >
                                        <td>
                                            {checkPermission('admin.product-feed-settings.edit')
                                                ? <Link to={`/app/feed/update/${e.id}`}>{e.id}</Link>
                                                : <span>{e.id}</span>
                                            }
                                        </td>
                                        <td>
                                          <span>
                                            {e?.title || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e?.filename || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e?.generated_at ? getTZtoDate(e?.generated_at, true) : '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e?.status || '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                              {e?.feed_url ? <CopyComponent copyText={e?.feed_url} /> : '---'}
                                          </span>
                                        </td>
                                        <td>
                                          <span>
                                            {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                          </span>
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={6}>Немає фідів</td>
                                    </TableListViewRow>
                                )
                        ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
}

export default FeedsList
