import {
    getSubscribers, getSubscribersTypes,
    removeSubscriber,
} from '../../lib/api/subscribers';
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
  getDomains(), getSubscribersTypes(),
])
  .then((res) => ({
      domains: res[0]?.value?.data,
      types: res[1]?.value?.data,
  }));

export const getData = getSubscribers;
export const remove = removeSubscriber;

