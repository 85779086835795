import React from 'react';

export default function Dashboard() {
  return (
    <div className="columns">
      {/*<div className="column col-4">*/}
      {/*  <div className="box-wrap">*/}

      {/*    <div className="card">*/}
      {/*      <div className="card-image">*/}
      {/*        <img src="//picsum.photos/id/201/800/600" className="img-responsive" alt="" />*/}
      {/*      </div>*/}
      {/*      <div className="card-header">*/}
      {/*        <div className="card-title h5">Apple</div>*/}
      {/*        <div className="card-subtitle text-gray">Hardware and software</div>*/}
      {/*      </div>*/}
      {/*      <div className="card-body">To make a contribution to the world by making tools for the mind that advance humankind.</div>*/}
      {/*      <div className="card-footer">*/}
      {/*        <button type="button" className="btn btn-primary">More</button>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="column col-4">*/}
      {/*  <div className="box-wrap">*/}

      {/*    <div className="card">*/}
      {/*      <div className="card-header">*/}
      {/*        <button type="button" className="btn btn-primary float-right"><i className="icon icon-more-vert" /></button>*/}
      {/*        <div className="card-title h5">Моя активность</div>*/}
      {/*        <div className="card-subtitle text-gray">884 Sales</div>*/}
      {/*      </div>*/}

      {/*      <div className="card-body">*/}
      {/*        <div className="timeline">*/}
      {/*          <div className="timeline-item" id="timeline-example-1">*/}
      {/*            <div className="timeline-left">*/}
      {/*              <a className="timeline-icon" href="#timeline-example-1">.</a>*/}
      {/*            </div>*/}
      {/*            <div className="timeline-content">*/}
      {/*              <div className="tile-content">*/}
      {/*                <p className="tile-subtitle">08:42</p>*/}
      {/*                <p className="tile-title">Outlines keep you honest. And keep structure</p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}

      {/*          <div className="timeline-item" id="timeline-example-2">*/}
      {/*            <div className="timeline-left">*/}
      {/*              <a className="timeline-icon" href="#timeline-example-2">.</a>*/}
      {/*            </div>*/}
      {/*            <div className="timeline-content">*/}
      {/*              <div className="tile-content">*/}
      {/*                <p className="tile-subtitle">10:00</p>*/}
      {/*                <p className="tile-title">AEOL meeting</p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}

      {/*          <div className="timeline-item" id="timeline-example-3">*/}
      {/*            <div className="timeline-left">*/}
      {/*              <a className="timeline-icon" href="#timeline-example-3">.</a>*/}
      {/*            </div>*/}
      {/*            <div className="timeline-content">*/}
      {/*              <div className="tile-content">*/}
      {/*                <p className="tile-subtitle">10:00</p>*/}
      {/*                <p className="tile-title">AEOL meeting</p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          ...*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}
