import { getBannerCatalog, removeBannerCatalog } from '../../lib/api/bannerCatalog';
import { getCategoriesMenu } from "../../lib/api/categories_menu";
import { LIST_PER_PAGE } from "../../const/view";
import {getCatTree} from "../../lib/api/categories";
import {getDomains} from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getCatTree(1), getDomains()
])
    .then((res) => {
        const parseCategory = []
        const parseCategoriesActionNodes = (nodes) => {
            if (!!nodes.children?.length) {
                nodes.children.map((node) => parseCategoriesActionNodes(node))
            }

            return parseCategory.push({
                id: nodes.id,
                title: nodes.title
            });
        }

        parseCategoriesActionNodes(res[0]?.value?.data[0])

        return {
            categories: parseCategory,
            domains: res[1]?.value?.data
        }
    });

export const getData = getBannerCatalog;
export const remove = removeBannerCatalog;