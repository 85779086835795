import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";
import {
    createBannerHome, editBannerHome, getBannerHomeEdit, removeBannerHome,
} from "../../lib/api/bannerHome";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (bmhId) => Promise.allSettled([getDomains(), getCatTree(), bmhId && getBannerHomeEdit(bmhId)])
    .then(async (res) => {

        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if (findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        return ({
            domains: res[0]?.value?.data,
            categories: res[1]?.value?.data,
            fields: bmhId && res[2]?.value?.data,
        })
    });

export const create = createBannerHome;
export const edit = editBannerHome;
export const remove = removeBannerHome;

export const parseDataSend = (data, bmhId) => {
    let d = { ...data };
    const isUpdate = typeof bmhId !== 'undefined';

    d['date_from'] = `${d.date_from} ${d.time_from}`;
    d['date_to'] = `${d.date_to} ${d.time_to}`;

    d = parseAll(d, !isUpdate, [
        'has_gift',
        'is_active',
    ]);

    return d;
};

export const parseDataGet = (data) => {
    const d = { ...data };
    const toStr = [
        // 'dimension_type',
        // 'document_type'
    ];

    toStr.forEach((p) => {
        d[p] = `${d[p] }`;
    });

    return d;
};
