import removeDuplicates from "../../../lib/helpers/removeDuplicates";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";

let options = [];
export const handleGet = async (api, search, page, searchName = 'title', clearOptions = false) => {
    if(clearOptions) {
        options = [];
    }
    await api({
        [searchName]: search,
        per_page: 50,
        page: page
    })
        .then((r) => {
            if (!!r?.data?.data.length) {
                if(!!search?.length) {
                    const parseData = r?.data?.data?.map(item => ({
                        label: `${item.title || item.name || item.title_ru || item.title_ua}`,
                        value: item.id,
                    }));
                    options = [...parseData];
                } else {
                    r?.data?.data.forEach((element) => {
                        options.push({
                            label: `${element.title || element.name || element.title_ru || element.title_ua}`,
                            value: element.id,
                        });
                    });
                }
            } else {
                options = r?.data?.data;
            }
        })
        .catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await api({
                        [searchName]: search,
                        per_page: 50,
                        page: page
                    })
                        .then((r) => {
                            if (!!r?.data?.data.length) {
                                if(!!search?.length) {
                                    const parseData = r?.data?.data?.map(item => ({
                                        label: `${item.title || item.name || item.title_ru || item.title_ua}`,
                                        value: item.id,
                                    }));
                                    options = [...parseData];
                                } else {
                                    r?.data?.data.forEach((element) => {
                                        options.push({
                                            label: `${element.title || element.name || element.title_ru || element.title_ua}`,
                                            value: element.id,
                                        });
                                    });
                                }
                            } else {
                                options = r?.data?.data;
                            }
                        })
                })
            } else {
                await errorMessage(err , '')
            }
        });

    options = removeDuplicates(options);
};

const sleep = ms =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

const optionsPerPage = 50;

const loadOptions = async (api, search, page, searchName) => {
    await sleep(1000);
    await handleGet(api, search, page, searchName);

    let filteredOptions;
    if (!!search?.length) {
        filteredOptions = options;
    } else {
        const searchLower = search.toLowerCase();

        filteredOptions = options.filter((item) => {
            return item?.label?.toLowerCase()?.includes(searchLower);
        });
    }

    const hasMore = Math.ceil((filteredOptions.length / optionsPerPage) + 1) > page;
    let slicedOptions

    if (!!search?.length) {
        slicedOptions = filteredOptions;
    } else {
        if(optionsPerPage < filteredOptions?.length) {
            slicedOptions = filteredOptions.slice(((page - 1) * optionsPerPage));
        } else {
            slicedOptions = filteredOptions;
        }
    }

    return {
        options: slicedOptions,
        hasMore
    };
};

export default loadOptions;
