import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import checkPermission from "../../lib/checkPermission";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { getData, remove } from "./api";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import getTZtoDate from "../../lib/getTZtoDate";

const SelectionsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted
  });

  /* eslint-disable */
  return (
      <div>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Selections list`} />
        {!load && (
            <TopPagination
                pagination={(
                    <>
                        {!!rest?.meta?.total && (
                            <>
                                <TableListViewPagination
                                    length={+rest?.meta?.last_page}
                                    current={+rest?.meta?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.meta?.total}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )}
          <TableListViewTable>
              <TableHead
                  arrayChildren={[
                      {attr: {style: {width: '40px'}}, title: 'ID'}, {attr: {style: {width: '100%'}}, title: 'Назва вибірки'}, <div>Кількість<br/>товарів</div>, <div>Кількість<br/>категорій</div>,
                      <div>Кількість<br/>виключених<br/>товарів</div>, <div>Дата створення<br/>Дата оновлення</div>,
                      'Статус активності'
                  ]}
                  filterChildren={<TableListViewFiltersRow
                      urlParams={urlParams}
                      fields={[
                          { },
                          { type: 'text', name: 'title' },
                          { },
                          { },
                          { },
                          { },
                          { },
                          { }
                      ]}
                      onSubmit={pushUrlParams}
                  />}
              />
              <tbody>
              {load
                  ? (
                      <TableListViewRowLoader cols={7} />
                  )
                  : (rest && (
                      rest?.data?.length
                          ? rest?.data?.map((e, i) => (
                              <TableListViewRow
                                  id={e?.id}
                                  key={`selection-item-${e?.id}`}
                                  remove={{
                                      api: remove,
                                      success: () => setRest(getSplicedData(i, rest)),
                                      alert: 'Вибірки товарів видалено',
                                  }}
                                  editLink={`/app/selection/update/${e?.id}`}
                                  permissionRemove='admin.selections.destroy'
                                  permissionEdit='admin.selections.edit'
                              >
                                  <td>
                                      {checkPermission('admin.selections.edit')
                                          ? <Link to={`/app/selection/update/${e.id}`}>{e.id}</Link>
                                          : <span>{e.id}</span>
                                      }
                                  </td>
                                  <td>
                                      <span>
                                        {e?.title || '---'}
                                      </span>
                                  </td>
                                  <td>
                                      <span>
                                        {e?.product_ids?.length}
                                      </span>
                                  </td>
                                  <td>
                                      <span>
                                        {e?.category_ids?.length}
                                      </span>
                                  </td>
                                  <td>
                                      <span>
                                        {e?.excluded_product_ids?.length || 0}
                                      </span>
                                  </td>
                                  <td>
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                          <div style={{ whiteSpace: 'nowrap' }}>
                                              {e?.created_at ? 'Створення: ' + getTZtoDate(e?.created_at) : 'Створення: ---'}
                                          </div>
                                          <div style={{ whiteSpace: 'nowrap' }}>
                                              {e?.updated_at ? 'Оновлення: ' + getTZtoDate(e?.updated_at) : 'Оновлення: ---'}
                                          </div>
                                      </div>
                                  </td>
                                  <td>
                                      <span>
                                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                      </span>
                                  </td>
                              </TableListViewRow>
                          ))
                          : (
                              <TableListViewRow disabled>
                                  <td colSpan={7}>Немає вибірки товарів</td>
                              </TableListViewRow>
                          )
                  ))}
              </tbody>
          </TableListViewTable>
    </div>
  );
};

export default SelectionsList;
