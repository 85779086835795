import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  PRODUCT_FEEDBACK_CREATE, PRODUCT_FEEDBACK_CREATE_ORDER, PRODUCT_FEEDBACK_DELETE, PRODUCT_FEEDBACK_EDIT,
  PRODUCT_FEEDBACK_GET, PRODUCT_FEEDBACK_GET_EDIT,
} from '../../const/api';

export const getProductFeedback = (params) => fetchAx({
  url: PRODUCT_FEEDBACK_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createProductFeedback = (data) => fetchAx({
  url: PRODUCT_FEEDBACK_CREATE,
  method: 'POST',
  data,
});

export const getProductFeedbackEdit = (id) => fetchAx({
  url: PRODUCT_FEEDBACK_GET_EDIT.join(id),
  method: 'GET',
});

export const editProductFeedback = (id, data) => fetchAx({
  url: PRODUCT_FEEDBACK_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeProductFeedback = (id) => fetchAx({
  url: PRODUCT_FEEDBACK_DELETE.join(id),
  method: 'DELETE',
});

export const createOrderProductFeedback = (id) => fetchAx({
  url: PRODUCT_FEEDBACK_CREATE_ORDER.join(id),
  method: 'POST',
});
