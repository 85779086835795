import React from 'react';
import { LIST_PER_PAGE } from '../../../../const/view';

import s from './SelectPerPage.module.scss';

const TableListViewPerPage = ({ urlParams, onSelect }) => {
  const name = 'per_page';
  const perPage = localStorage.getItem('per_page');
  const defaultVal = (urlParams && urlParams[name]) || perPage || LIST_PER_PAGE.default;

  function selectHandle(e) {
    if (onSelect) {
      localStorage.setItem('per_page', e.target.value);
      onSelect(e.target.value, { [name]: e.target.value });
    }
  }

  return (
    <div className={s.wrap}>
      <div className="input-group">
        <span className="input-group-addon addon-sm">выводить по:</span>
        <select
          className="form-select select-sm"
          defaultValue={defaultVal}
          onChange={selectHandle}
        >
          {Object.keys(LIST_PER_PAGE).map((e) => (
              <option
                  value={LIST_PER_PAGE[e]}
                  key={`list-per-view-${e}`}
                  selected={LIST_PER_PAGE[e].toString() === perPage}
              >
                {LIST_PER_PAGE[e]}
              </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TableListViewPerPage;
