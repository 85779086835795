import { parseAll } from "../../lib/api/_parseApi";
import {
  createBrand, editBrand,
  getBrandEdit,
  removeBrand,
} from "../../lib/api/brands";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (brandID) => Promise.allSettled([brandID && getBrandEdit(brandID)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: brandID && res[0]?.value?.data,
    })
  });

export const create = createBrand;
export const edit = editBrand;
export const remove = removeBrand;

export const parseDataSend = (data, bankId) => {
  let d = { ...data };
  const isUpdate = typeof bankId !== 'undefined';

  d = parseAll(d, !isUpdate, []);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
