import React, { useEffect, useState } from 'react';
import GalleryImages from '../../GalleryImages';
import { ModalMedia } from "./Modal";
import { getMedia, removeMedia } from "../../../../lib/api/media";
import errorMessage from "../../../../lib/errorMessage";
import ModalRemove from "../../Modal/ModalRemove";

const BlockImages = ({
                         galleryType,
                         galleryId,
                         galleryImageType = 'images',
                         typeFile,
                         formH,
                         nameParent,
                         idParent,
                         remove = false,
                         create = false,
                         copyBtn = false
}) => {

  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);
  const [typeCoverData, setTypeCoverData] = useState([]);

  const [dataModal, setDataModal] = useState({});

  useEffect(() => {
    getMedia({
      'entity_type': galleryType,
      'entity_id': galleryId,
      per_page: 1000
    }).then(res => {
      const filterCover = res?.data?.data?.filter(item => ((item?.collection_name === galleryImageType) && !item?.mime_type?.includes("video")));

      if (typeFile === "video") {
        const filterVideo = res?.data?.data?.filter(item => item?.mime_type?.includes("video"));
        setTypeCoverData(filterVideo);

      } else {
        if(filterCover) {
          setTypeCoverData(filterCover);
        }
      }
    }).catch(async (err) => await errorMessage(err, '/api/admin/media GET'));
  }, []);

  /* eslint-disable */
  return (
    <div>
      {galleryId && (
          <GalleryImages
              id={idParent || `product_img`}
              nameParent={nameParent || 'product_images[image_list]'}
              formH={formH}
              accept="video/*"
              acceptStr="PNG, JPG, GIF"
              imageData={typeCoverData}
              setImageData={setTypeCoverData}
              setActiveMedia={setActiveMedia}
              setActiveRemove={setActiveRemove}
              setDataModal={setDataModal}
              prodID={galleryId}
              entityType={galleryType}
              collectionName={galleryImageType}
              showDeleteBtn={remove}
              showCreateBtn={create}
              marginLeft={!create && 0}
              copyBtn={copyBtn}
              typeFile={typeFile}
          />
      )}

      {activeMedia && <ModalMedia
          active={activeMedia}
          setActive={setActiveMedia}
          data={dataModal}
          collectionName={galleryImageType}
          setImageData={setTypeCoverData}
          title='Редактирование картинки'
      />}

      {activeRemove && <ModalRemove
          active={activeRemove}
          setActive={setActiveRemove}
          data={dataModal}
          funcRemove={removeMedia}
          setRemoveItem={setTypeCoverData}
          title='Удалить изображение'
      />}
    </div>
  );
};

export default BlockImages;
