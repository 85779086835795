import {
    USERS_GET,
    USER_CREATE,
    USER_DELETE,
    USERS_GET_EDIT,
    USER_EDIT
} from '../../const/api'
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

// get list clients
export const getUsers = (params) => fetchAx({
    url: USERS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
})

export const createUser = (data) => fetchAx({
    url: USER_CREATE,
    method: 'POST',
    data
})

export const getUserEdit = (id) => fetchAx({
    url: USERS_GET_EDIT.join(id),
    method: 'GET',
})

export const editUser = (id, data) => fetchAx({
    url: USER_EDIT.join(id),
    method: 'PUT',
    data
})

export const removeUser = (id) => fetchAx({
    url: USER_DELETE.join(id),
    method: 'DELETE'
})