import { getPermissions, removePermissions } from '../../lib/api/permissionsAdmin';
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains(),
])
    .then((res) => ({
        domains: res[0]?.value?.data,
    }));

export const getData = getPermissions;
export const remove = removePermissions;
