import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
    getData, remove, edit, create, parseDataGet, parseDataSend,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const StatusEditView = () => {
    const { id: statusID } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: statusID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/status/not-found',
            remove: `/app/statuses${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/statuses${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Статус создан',
            edit: 'Статус обновлен',
            remove: 'Статус удален',
        },
    });

    /* eslint-disable */
    return (
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Status edit`} />
            <div className="box-wrap">
                {statusID
                    ? (
                        <Title>
                            Статус -
                            <small>{data && data.fields.title_ru}</small>
                        </Title>
                    )
                    : <Title>Новый статус</Title>}

                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide" />

                    {/* id */}
                    {statusID ? (
                            <Group>
                                <ColLeft>
                                    <label className="form-label" htmlFor="so-id">ID статуса*:</label>
                                </ColLeft>
                                <ColRight>
                                    <input
                                        name="id"
                                        id="so-id"
                                        className='form-input'
                                        type="text"
                                        ref={register}
                                        readOnly
                                    />
                                </ColRight>
                            </Group>
                        ) : (
                        <Group>
                            <ColLeft>
                                <label className="form-label" htmlFor="so-id">ID статуса*:</label>
                            </ColLeft>
                            <ColRight>
                                <input
                                    name="id"
                                    className={cn('form-input', {
                                        'is-error': errors.id,
                                    })}
                                    type="number"
                                    id="so-id"
                                    placeholder="Введите ID"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={register({
                                        required: VALIDATION.req.required,
                                    })}
                                />
                                {errors.id && <p className="form-input-hint">{errors.id.message}</p>}
                            </ColRight>
                        </Group>
                    )}

                    {/* name */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-name">Имя статуса*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="name"
                                className={cn('form-input', {
                                    'is-error': errors.name,
                                })}
                                type="text"
                                id="so-name"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {errors.name && <p className="form-input-hint">{errors.name.message}</p>}
                        </ColRight>
                    </Group>

                    {/* title_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-name-ua">Название УКР*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ua"
                                className={cn('form-input', {
                                    'is-error': errors.title_ua,
                                })}
                                type="text"
                                id="so-name-ua"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
                        </ColRight>
                    </Group>

                    {/* title_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="so-name-ru">Название РУС*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ru"
                                className={cn('form-input', {
                                    'is-error': errors.title_ru,
                                })}
                                type="text"
                                id="so-name-ru"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
                        </ColRight>
                    </Group>

                </Form>

                {load && <Loader />}
            </div>
        </GridEditView>
    );
};

export default StatusEditView;
