import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  getCatTree,
} from '../../lib/api/categories';
import {
  createUnaccountedProduct,
  editUnaccountedProduct, getUnaccountedProductEdit,
  removeUnaccountedProduct,
} from '../../lib/api/unaccounted_products';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (unProductsID) => Promise.allSettled([getDomains(), getCatTree(), unProductsID
&& getUnaccountedProductEdit(unProductsID)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      categories: res[1]?.value?.data,
      fields: unProductsID && res[2]?.value?.data,
    })
  });

export const create = createUnaccountedProduct;
export const edit = editUnaccountedProduct;
export const remove = removeUnaccountedProduct;

export const parseDataSend = (data, unProductsID) => {
  let d = { ...data };
  const isUpdate = typeof unProductsID !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
