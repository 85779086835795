import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createService, editService, getServiceEdit, removeService,
} from '../../lib/api/service';
import { getUniqueServicesAll } from '../../lib/api/unique_service';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (serviceID) => Promise.allSettled([getDomains(), serviceID
&& getServiceEdit(serviceID), getUniqueServicesAll()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: serviceID && res[1]?.value?.data,
      blockServices: res[2]?.value?.data,
    })
  });

export const create = createService;
export const edit = editService;
export const remove = removeService;

export const parseDataSend = (data, serviceID) => {
  let d = { ...data };
  const isUpdate = typeof serviceID !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
