import Item from '../Item'
import UserInfo from "../../../components/ui/UserInfo";
import getTZtoDate from "../../../lib/getTZtoDate";
import React from "react";

const UserProfile = ({ data }) => {
    return(
        <Item title="Профиль пользователя">
            <UserInfo>
                <li>
                    <div>ID</div>
                    <strong>{data?.user?.id ? data?.user?.id : '--' }</strong>
                </li>
                <li>
                    <div>Фамилия</div>
                    <strong>{data?.user?.surname ? data?.user?.surname : '--' }</strong>
                </li>
                <li>
                    <div>Имя</div>
                    <strong>{data?.user?.name ? data?.user?.name : '--' }</strong>
                </li>
                <li>
                    <div>Отчество</div>
                    <strong>{data?.user?.patronymic ? data?.user?.patronymic : '--' }</strong>
                </li>
                <li>
                    <div>Телефон</div>
                    <strong>{data?.user?.phone ? data?.user?.phone : '--' }</strong>
                </li>
                <li>
                    <div>
                        E-mail
                    </div>
                    <strong>
                        {data?.user?.email ? data?.user?.email : '--'}
                    </strong>
                </li>
                <li>
                    <div>
                        Дата регистрации
                    </div>
                    <strong>
                        {data?.user?.created_at ? getTZtoDate(data?.user?.created_at, false) : '--'}
                    </strong>
                </li>
            </UserInfo>
        </Item>
    );
};

export default UserProfile;
