import {
  BANNER_MENU_GET_ALL,
  BANNER_MENU_CREATE,
  BANNER_MENU_GET_EDIT,
  BANNER_MENU_EDIT,
  BANNER_MENU_DELETE, BANNER_MENU_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBannerMenu = (params) => fetchAx({
  url: BANNER_MENU_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getBannerMenuAll = () => fetchAx({
  url: BANNER_MENU_GET_ALL,
  method: 'GET',
});

export const createBannerMenu = (data) => fetchAx({
  url: BANNER_MENU_CREATE,
  method: 'POST',
  params: data,
});

export const getBannerMenuEdit = (id) => fetchAx({
  url: BANNER_MENU_GET_EDIT.join(id),
  method: 'GET',
});

export const editBannerMenu = (id, data) => fetchAx({
  url: BANNER_MENU_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBannerMenu = (id) => fetchAx({
  url: BANNER_MENU_DELETE.join(id),
  method: 'DELETE',
});
