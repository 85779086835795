import React from "react";
import s from "./StatusCircle.module.scss";
import cn from "classnames";

const StatusCircle = ({ red, yellow, grey, green, blue, text = '', style }) => (
    <div className={s.wrap}>
        <div className={cn(s.circle, {
            [s.green]: green,
            [s.red]: red,
            [s.yellow]: yellow,
            [s.grey]: grey,
            [s.blue]: blue,
        })} style={style} />
        {text}
    </div>
);

export default StatusCircle;