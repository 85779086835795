import fetchAx from '../fetchAx';
import {
    FEEDS_GET,
    FEED_CREATE,
    FEED_GET_EDIT,
    FEED_EDIT,
    FEED_DELETE,
    FEED_TYPES_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from "../../const/view";

export const getFeeds = (params) => fetchAx({
    url: FEEDS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
  },
});

export const getFeedTypes = () => fetchAx({
    url: FEED_TYPES_GET,
    method: 'GET',
});

export const createFeed = (data) => fetchAx({
    url: FEED_CREATE,
    method: 'POST',
    data,
});

export const getFeedEdit = (id) => fetchAx({
    url: FEED_GET_EDIT.join(id),
    method: 'GET',
});

export const editFeed = (id, data) => fetchAx({
    url: FEED_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeFeed = (id) => fetchAx({
    url: FEED_DELETE.join(id),
    method: 'DELETE',
});
