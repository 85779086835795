import { useState } from 'react';

/**
 * Tabs Form Hook
 *
 */
const useTabsForm = ({ tabs, defI = 0 }) => {
  const [forms, setForms] = useState([]);
  const [activeIndex, setActiveIndex] = useState(defI);
  const [errors, setErrors] = useState([]);

  function addForm(form, i) {
    setForms((prevArr) => {
      const fArr = [...prevArr];
      fArr[i] = form;

      return fArr;
    });
  }

  function addError(err, i) {
    setErrors((prevArr) => {
      const errArr = [...prevArr];
      errArr[i] = err;

      return errArr;
    });
  }

  function getDStyle(i) {
    return activeIndex !== i ? {
      pointerEvents: 'none',
      position: 'absolute',
      height: 0,
      width: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      zIndex: '-1',
    } : {};
  }
/* eslint-disable */
  async function validate(e, cb) {
    if (e) e.preventDefault();

    let dataF = {}; let
      error = false;
    setErrors([]);

    await Promise.all(
      forms.map(async (form, i) => {
        let data = null;
        await form.handleSubmit((d) => data = {...d})();
        if (!data) {
          error = true;
          addError(error, i);
        }
        dataF = {...dataF, ...data};
      }),
    );

    if ((e?.type !== 'submit') || (e === null)) {
      if (!error && cb) cb(dataF);
    }
  }

  function isActive(i) {
    return activeIndex === i;
  }

  function isError(i) {
    return errors[i];
  }

  return {
    items: tabs.map((e) => {
      if(typeof e === 'string') {
        return (
            {
              title: e,
              // active: isActive(i)
            }
        )
      } else {
        return (
            {
              title: e.title,
              onClick: e.func
            }
        )
      }

    }),
    forms,
    setActiveIndex,
    getDStyle,
    isActive,
    isError,
    addForm,
    validate,
  };
};

export default useTabsForm;
