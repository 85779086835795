import { Link } from 'react-router-dom';
import { uuid } from 'uuidv4';
import React from 'react';
import checkPermission from "../../../lib/checkPermission";

const TopNavTabs = ({ tabs }) => (
  <ul className="tab tab-block">
    {!!tabs.length && tabs.map((el) => {
      if(checkPermission(el.permissionName)) {
        return (
            <li className={`tab-item ${el?.isActive ? 'active' : ''}`} key={`${uuid()}`}>
              <Link to={`${el.url}`}>{el.title}</Link>
            </li>
        )
      }
      return null;
    })}
  </ul>
);

export default TopNavTabs;
