import {getUsers, removeUser} from "../../lib/api/users";
import {getDomains} from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains(),
])
    .then((res) => ({
        domains: res[0]?.value?.data,
    }));

export const getData = getUsers;
export const remove = removeUser;