export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.custom-col-image-center-text {}
            
            div.custom-col-image-center-text-item {
                display: flex;
                align-items: center;
                min-height: 416px;
                overflow: hidden;
                border-radius: 26px;
                background-color: #f4f4f4;
                max-width: 988px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 !important;
            }
            
            div.custom-col-image-center-text-left {
                padding: 80px 0 80px 40px;
            }
            
            div.custom-col-image-center-text-left > h3.h3-native {
                text-align: left;
            }
            
            h3.h3-native {
                font-size: 36px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h3.h3-native {
                    font-size: 28px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h3.h3-native {
                    font-size: 24px !important;
                }
                
                div.custom-col-image-center-text-item {
                    flex-direction: column;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row custom-col-image-center-text">
                <div class="custom-col-image-center-text-item">
                    <div class="custom-col-image-center-text-left">
                        <h3 class="h3-native d-flex mb-2"><span>Безупречный экран для безупречной графики</span></h3>
                    </div>
                    <div class="custom-col-image-center-text-right">
                        <img src="https://via.placeholder.com/300" alt="" />
                    </div>
                </div>
            </div>
            ${style}
        `,
    })
};