import grapesjs from "grapesjs";
import loadComponents from "./components";
import loadBlock from "./blocks";

export default grapesjs.plugins.add('colFullComponent', (editor, opts) => {
    let options = {
        label: 'Col full',
        name: 'col full',
        category: 'Col',
    };

    for(let name in options) {
        if(!(name in opts)) opts[name] = options[name];
    }

    loadBlock(editor, opts);
    loadComponents(editor, opts);
})