import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  getData, remove, edit, create, parseDataGet, parseDataSend,
} from './api';
import InputFile from "../../components/ui/InputFile";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const SpecialistsEditView = () => {
  const { id: devID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    watch,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: devID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/specialist/not-found',
      remove: `/app/specialists${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/specialists${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Специалист создан',
      edit: 'Специалист обновлен',
      remove: 'Специалист удален',
    },
  });

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Specialists edit`} />
      <div className="box-wrap">
        {devID
          ? (
            <Title>
              Специалист -
              <small>{data && data.fields.name_ua}</small>
            </Title>
          )
          : <Title>Новый специалист</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* cover */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-cover">Фото:</label>
              <div>Максимальное разрешение картинки 1920x1080</div>
            </ColLeft>
            <ColRight>
              <InputFile
                  name="cover"
                  val={watch('cover')}
                  formH={formH}
                  ref={register}
                  acceptStr="PNG, JPG, GIF"
                  accept="image/*"
                  crop={[168, 198]}
                  sizeMB={1}
              />
            </ColRight>
          </Group>

          {/* name_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-name-ru">Имя специалиста РУ*:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="name_ru"
                  className={cn('form-input', {
                    'is-error': errors.name_ua,
                  })}
                  type="text"
                  id="specialist-name-ru"
                  placeholder="Введите имя"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.name_ru && <p className="form-input-hint">{errors.name_ru.message}</p>}
            </ColRight>
          </Group>

          {/* name_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-name-ua">Имя специалиста УКР*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="name_ua"
                className={cn('form-input', {
                  'is-error': errors.name_ua,
                })}
                type="text"
                id="specialist-name-ua"
                placeholder="Введите имя"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.name_ua && <p className="form-input-hint">{errors.name_ua.message}</p>}
            </ColRight>
          </Group>

          {/* phone */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-phone">Телефон*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="phone"
                className={cn('form-input', {
                  'is-error': errors.phone,
                })}
                type="number"
                id="specialist-phone"
                placeholder="Введите номер"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.phone && <p className="form-input-hint">{errors.phone.message}</p>}
            </ColRight>
          </Group>

          {/* email */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-email">Email*:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="email"
                  className={cn('form-input', {
                    'is-error': errors.email,
                  })}
                  type="text"
                  id="specialist-email"
                  placeholder="Введите емайл"
                  maxLength={INPUT_MAX_LENGTH}
                  ref={register({
                    required: VALIDATION.req.required,
                  })}
              />
              {errors.email && <p className="form-input-hint">{errors.email.message}</p>}
            </ColRight>
          </Group>

          {/* desc_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-desc-ru">
                Краткое описание РУ:
              </label>
            </ColLeft>
            <ColRight>
              <textarea
                  name="desc_ru"
                  className="form-input"
                  id="specialist-desc-ru"
                  placeholder="Введите текст"
                  rows="4"
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* desc_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-desc-ua">
                Краткое описание УКР:
              </label>
            </ColLeft>
            <ColRight>
              <textarea
                  name="desc_ua"
                  className="form-input"
                  id="specialist-desc-ua"
                  placeholder="Введите текст"
                  rows="4"
                  ref={register}
              />
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft />
            <ColRight>
              <label className="form-switch">
                <input
                  name="is_active"
                  type="checkbox"
                  id="specialist-status-active"
                  ref={register}
                />
                <i className="form-icon" />
                Статус активности
              </label>
            </ColRight>
            <ColLeft />
            <ColRight>
              <label className="form-switch">
                <input
                    name="is_fired"
                    type="checkbox"
                    id="specialist-status-fired"
                    ref={register}
                />
                <i className="form-icon" />
                Працює
              </label>
            </ColRight>
          </Group>

          {/* position */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="specialist-position">Сортировка*:</label>
            </ColLeft>
            <ColRight>
              <input
                  name="position"
                  className='form-input'
                  type="number"
                  id="specialist-position"
                  placeholder="Введите значение"
                  ref={register}
              />
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default SpecialistsEditView;
