import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, remove, getFiltersData } from "./api";
import { APPLICATION_STATUS } from "../../lib/const/product";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";

const ProductB2BList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Product b2b list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Дата відправки', 'Прізвище', "Ім'я", 'Телефон',
                        'JDE Код товару', 'Назва товару', 'Статус', 'Менеджер'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'created_at'},
                            {type: 'text', name: 'surname'},
                            {type: 'text', name: 'name'},
                            {type: 'text', name: 'phone'},
                            {type: 'text', name: 'product_jde'},
                            {type: 'text', name: 'product_title_ru'},
                            {type: 'select', name: 'status_id', options: APPLICATION_STATUS},
                            {type: 'text', name: 'manager'},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={9}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`prod-b2b-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Форма B2B на покупку видалена',
                                    }}
                                    editLink={`/app/product-b2b/update/${e.id}`}
                                    permissionRemove='admin.product-b2b.destroy'
                                    permissionEdit='admin.product-b2b.edit'
                                >
                                    <td>
                                        {checkPermission('admin.product-b2b.edit')
                                            ? <Link to={`/app/product-b2b/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.surname || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.product?.jde || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.product?.title_ru || e?.product?.title_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {(e.status || e.status === 0) ? APPLICATION_STATUS[e.status].title : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.manager ? e?.manager?.name : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={9}>Немає форм B2B</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default ProductB2BList;
