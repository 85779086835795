import React, {useEffect, useState} from 'react';
import { LIST_PER_PAGE } from "../../../../const/view";
import icon_arrow from "../../../../assets/icons/icon_arrow-down-small.svg";
import s from "./SelectPerPage.module.scss";
import cn from "classnames";

const SelectCustom = ({ onSelect, urlParams }) => {
    const [choosePerPage, setChoosePerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const perPage = localStorage.getItem('per_page');

    const handleOpen = () => setOpen(!open);

    const selectHandle = (value) => {
        if (onSelect) {
            localStorage.setItem('per_page', value);
            onSelect(value, { per_page: value });
        }
    };

    useEffect(() => {
        if(perPage) {
            setChoosePerPage(perPage);
        } else {
            setChoosePerPage(urlParams['per_page'] || 10);
        }
    }, [perPage]);

    return (
        <div className={s.block}>
            <span className={s.text}>Выводить по</span>
            <div className={s.select}>
                <div className={s.select__trigger} onClick={handleOpen}>
                    <span>{choosePerPage}</span>
                    <img className={cn({
                        [s.open]: open
                    })} src={icon_arrow} alt="Icon arrow" />
                </div>
                {open && (
                    <div className={s.select__open}>
                        {Object.keys(LIST_PER_PAGE).map((e) => (
                            <div
                                key={`list-per-view-${e}`}
                                className={cn(s.select__item, {
                                    [s.active]: choosePerPage?.toString() === LIST_PER_PAGE[e]?.toString()
                                })}
                                onClick={() => selectHandle(LIST_PER_PAGE[e])}
                            >
                                {LIST_PER_PAGE[e]}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectCustom;