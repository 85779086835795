import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, getFiltersData, remove } from './api';
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PermissionAdminList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    filtersData,
    setRest,
    urlParams,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Permission admin list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th>Группа</th>
            <th>Модуль</th>
            <th>Тип</th>
          </tr>

          <TableListViewFiltersRow
              urlParams={urlParams}
              fields={[
                {},
                { type: 'text', name: 'name' },
                {},
                {},
                {}
              ]}
              onSubmit={pushUrlParams}
          />
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={5} />
            )
            : (rest && !!rest?.data?.length
                ? rest.data.map((e, i) => {
                  return (
                      <TableListViewRow
                          id={e.id}
                          key={`permission-item-${e.id}`}
                          remove={{
                            api: remove,
                            success: () => setRest(getSplicedData(i, rest)),
                            alert: 'Разрешение удалено',
                          }}
                          permissionRemove='admin.admin.permissions.destroy'
                          permissionEdit='admin.admin.permissions.edit'
                          controlsOff
                      >
                        <td>{e.id}</td>
                        <td>{e.name || '-'}</td>
                        <td>{e.group_title || '-'}</td>
                        <td>{e.module || '-'}</td>
                        <td>{e.type_title || '-'}</td>
                      </TableListViewRow>
                  )
                  })
                : (
                  <TableListViewRow disabled>
                      <td colSpan={4}>Немає permission</td>
                  </TableListViewRow>
            ))}
        </tbody>
      </TableListViewTable>

      {!load && (
          <TableListViewFooter
              left={(
                  <>
                      {rest?.meta?.total > 0 && (
                          <TableListViewPagination
                              length={+rest?.meta?.last_page}
                              current={+rest?.meta?.current_page}
                              total={+rest?.meta?.total}
                              perPage={+rest?.meta?.per_page}
                              loc={location}
                          />
                      )}
                  </>
              )}
              right={(
                  <TableListViewPerPage
                      urlParams={urlParams}
                      onSelect={(val, params) => pushUrlParams(params)}
                  />
              )}
              rightCn="col-auto"
          />
      )}
    </div>
  );
};

export default PermissionAdminList;
