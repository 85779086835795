export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="accordion-container">
  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger"><span>Lorem ipsum dolor sit amet.</span></button>
    </h6>
    <div class="ac-panel">
      <p class="ac-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  </div>

  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger"><span>Lorem ipsum dolor sit amet.</span></button>
    </h6>
    <div class="ac-panel">
      <p class="ac-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  </div>

  <div class="ac">
    <h6 class="ac-header">
      <button type="button" class="ac-trigger"><span>Lorem ipsum dolor sit amet.</span></button>
    </h6>
    <div class="ac-panel">
      <p class="ac-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
  </div>
</div>
        `,
    })
};