import { getWhAll } from "../../lib/api/warehouses";
import { getCatTree } from "../../lib/api/categories";
import { createFeed, editFeed, getFeedEdit, removeFeed, getFeedTypes } from "../../lib/api/feeds";
import { getDomains } from "../../lib/api/domains";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (fId) => Promise.allSettled(
    [
        getDomains(),
        getWhAll({domain_id: 1}),
        getCatTree(1),
        getFeedTypes(),
        fId && getFeedEdit(fId)
    ]
)
    .then(async (res) => {
        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if (findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        const parseCategories = res[2]?.value?.data?.map(item => {
            const parseSecondLevel = item.children.map(itemSecond => {
                const parseThirdLevel = itemSecond.children.map(itemThird => {
                    const parseFourthLevel = itemThird.children.map(itemFourth => ({
                        value: itemFourth.id,
                        label: itemFourth.title,
                        children: null,
                    }));
                    return {
                        value: itemThird.id,
                        label: itemThird.title,
                        children: parseFourthLevel,
                    };
                });
                return {
                    value: itemSecond.id,
                    label: itemSecond.title,
                    children: parseThirdLevel,
                };
            })
            return {
                value: item.id,
                label: item.title,
                children: parseSecondLevel,
            };
        });
        return ({
            domains: res[0]?.value?.data,
            wareHouses: res[1]?.value?.data?.data,
            categories: parseCategories,
            feedTypes: res[3]?.value?.data,
            fields: fId && res[4]?.value?.data,
        });
    })

export const create = createFeed;
export const edit = editFeed;
export const remove = removeFeed;

export const parseDataSend = (data, fId) => {
    let d = { ...data };
    const isUpdate = typeof fId !== 'undefined';

    if(!!d.categories.length) {
        const parseCat = d.categories.split(',').map(item => +item);
        d.categories = parseCat;
    }

    const parseWareHouse = () => {
        const copyData = [...d['warehouses']];
        return copyData?.filter(item => item !== false);
    }

    d['warehouses'] = parseWareHouse();

    if(d?.warehouses_check_all === 'true') {
        d['warehouses'] = d['warehouses-all'];
        delete d['warehouses_check_all'];
        delete d['warehouses-all'];
        delete d['warehouses_all'];
    }

    d['filename'] = `${d?.filename}.xml`;
    if(!!d?.included_products?.length) {
        d['included_products'] = d?.included_products?.map(item => +item);
    } else {
        d['included_products'] = [];
    }
    if(!!d?.excluded_products?.length) {
        d['excluded_products'] = d?.excluded_products?.map(item => +item);
    } else {
        d['excluded_products'] = [];
    }

    return d;
};
