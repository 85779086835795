export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div><button class="button-custom" type="button">Button</button></div> 
        `,
    })
};