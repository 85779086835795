import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  PRODUCT_B2B_CREATE, PRODUCT_B2B_DELETE, PRODUCT_B2B_EDIT,
  PRODUCT_B2B_GET, PRODUCT_B2B_GET_EDIT,
} from '../../const/api';

export const getProductB2b = (params) => fetchAx({
  url: PRODUCT_B2B_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createProductB2b = (data) => fetchAx({
  url: PRODUCT_B2B_CREATE,
  method: 'POST',
  data,
});

export const getProductB2bEdit = (id) => fetchAx({
  url: PRODUCT_B2B_GET_EDIT.join(id),
  method: 'GET',
});

export const editProductB2b = (id, data) => fetchAx({
  url: PRODUCT_B2B_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeProductB2b = (id) => fetchAx({
  url: PRODUCT_B2B_DELETE.join(id),
  method: 'DELETE',
});
