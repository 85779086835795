import React from 'react';
import s from "../PopularGood.module.scss";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import cn from "classnames";
import { VALIDATION } from "../../../const/text";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import InputFetchResult from "../../../components/ui/InputFetchResultNew";
import { getProduct } from "../../../lib/api/products";
import Textarea from "../../../components/ui/Textarea";

const FieldsBlock = ({ data, formH }) => {
    return (
        <div className={s.left}>
            <div className={s.left__header}>
                {/* domain_id */}
                <div>
                    <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                            {data
                                ? (
                                    <select
                                        name="domain_id"
                                        className={cn(s_Select.select, {
                                            [s_Select.select_error]: formH.errors?.domain_id,
                                        })}
                                        id="pg-domain"
                                        ref={formH.register({
                                            required: VALIDATION.select.required,
                                        })}
                                    >
                                        <option value="">выберите домен</option>
                                        {data && !!data.domains && data.domains.map((domain) => (
                                            <option
                                                key={domain.id}
                                                value={domain.id}
                                                selected={domain.id === data?.fields?.domain_id}
                                            >
                                                {domain.title}
                                            </option>
                                        ))}
                                    </select>
                                )
                                : (
                                    <div className="p-relative">
                                        <div className="loading" />
                                    </div>
                                )}
                            <label className={s_Select.label} htmlFor="pg-domain">Домен*</label>
                        </div>
                        {formH.errors?.domain_id
                        && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                    </div>
                </div>

                {/* position */}
                <div>
                    <InputWrapper
                        label="Позиция*"
                        id="pg-position"
                        errorComponent={formH.errors?.position &&
                        <p className="form-input-hint">{formH?.errors?.position?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH.errors?.position,
                            })}
                            type="text"
                            placeholder="Позиция*"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                                required: VALIDATION.req.required
                            })}
                            name="position"
                            id="pg-position"
                            defaultValue={data?.fields?.position}
                        />
                    </InputWrapper>
                </div>
            </div>
            <div className={s.left__block}>

                {/* product_id */}
                <InputFetchResult
                    name="product_id"
                    form={formH}
                    searchParam="jde[]"
                    func={getProduct}
                    defVal={data?.fields?.product}
                    id="pg-product-id"
                    placeholder="Навание товара / JDE*"
                    required
                />

                {/* desc_ua */}
                <Textarea
                    name="desc_ua"
                    id="pg-desc-ua"
                    placeholder="Описание на украинском"
                    formH={formH}
                    defaultValue={data?.fields?.desc_ua}
                />

                {/* desc_ru */}
                <Textarea
                    name="desc_ru"
                    id="pg-desc-ru"
                    placeholder="Описание на русском"
                    formH={formH}
                    defaultValue={data?.fields?.desc_ru}
                />
            </div>
        </div>
    );
};

export default FieldsBlock;