import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import checkPermission from "../../lib/checkPermission";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { getData } from "./api";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const SectionsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted
  });

  /* eslint-disable */
  return (
      <div>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Selections list`} />
        {!load && (
            <TopPagination
                pagination={(
                    <>
                        {+rest?.total > 0 && (
                            <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.total}
                                />
                            </>
                        )}
                    </>
                )}
            />
        )}
          <TableListViewTable>
              <TableHead
                  arrayChildren={[
                      'ID', 'Назва меню', 'Іконка', 'Посилання', 'Дочірні', 'Статус активності'
                  ]}
              />
              <tbody>
              {load
                  ? (
                      <TableListViewRowLoader cols={6} />
                  )
                  : (rest && (
                      rest?.data?.length
                          ? rest?.data.find(item => item.id === 1)?.children?.map((e, i) => (
                              <TableListViewRow
                                  id={e?.id}
                                  key={`selection-item-${e?.id}`}
                                  editLink={`/app/section/update/${e?.id}`}
                                  permissionEdit='admin.admin.sections.edit'
                                  deleteButton={false}
                              >
                                  <td>
                                      {checkPermission('admin.admin.sections.edit')
                                          ? <Link to={`/app/section/update/${e.id}`}>{e.id}</Link>
                                          : <span>{e.id}</span>
                                      }
                                  </td>
                                  <td>{e.title}</td>
                                  <td>img</td>
                                  <td>{e.url}</td>
                                  <td>
                                      {!!e?.children?.length ? (
                                          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                              / {e?.children.map(child => (
                                                  <div key={`selection-item-child-item-${child.id}`}>
                                                      {child.title} /
                                                  </div>
                                              ))}
                                          </div>
                                      ) : '---'}
                                  </td>
                                  <td>
                                      {e.is_active
                                          ? <StatusCircle green text="Активна" />
                                          : <StatusCircle red text="Не активна" />}
                                  </td>
                              </TableListViewRow>
                          ))
                          : (
                              <TableListViewRow disabled>
                                  <td colSpan={6} />
                              </TableListViewRow>
                          )
                  ))}
              </tbody>
          </TableListViewTable>
    </div>
  );
};

export default SectionsList;
