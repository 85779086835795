import React, { useEffect, useState } from 'react';

const Discount = ({
  formH = {}, name = '', defValue, defaultSum,
}) => {
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    if (defValue) { setSelectValue(defValue); }
  }, [defValue]);

  return (
    <>
      <select onChange={(e) => setSelectValue(e.target.value)} className="form-select" value={selectValue}>
        <option value="">Выберите скидку</option>
        <option value="1">Процентная скидка</option>
        <option value="2">Абсолютная скидка</option>
      </select>
      {(selectValue.toString() === '1')
             && (
               <input
                 type="number"
                 ref={formH.register}
                 className="form-input"
                 placeholder="Введите процентную скидку"
                 name={`${name}.discount_percent`}
                 defaultValue={defaultSum}
               />
             )}

      {(selectValue.toString() === '2')
             && (
               <input
                 type="number"
                 ref={formH.register}
                 className="form-input"
                 placeholder="Введите абсолютную скидку"
                 name={`${name}.discount_value`}
                 defaultValue={defaultSum}
               />
             )}
    </>
  );
};

export default Discount;
