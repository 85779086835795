import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PubSub as ps } from 'pubsub-js';
import { toast } from 'react-toastify';
import { EDIT } from '../../const/pubsub';
import { ERROR_RESP, WONT_REMOVE } from '../../const/text';
import useIsMounted from '../useIsMounted';
import confirmDialog from '../confirmDialog';

import './../../scss/modules/react-toastify.scss';
import { getMedia } from "../api/media";
import { refreshToken } from "./refreshToken";
import errorMessage from "../errorMessage";

const useEdit = ({
  targetID,
  updateFields,
  handleSubmit,
  submitCb,
  orderID,
  asyncDataGetKey,
  api = {
    create: null,
    edit: null,
    remove: null,
    get: null,
  },
  parse = {
    get: null,
    send: null,
  },
  redirects = {
    notFound: '',
    remove: '',
    save: '',
  },
  alerts = {
    create: '',
    edit: '',
    remove: '',
  },
  customTargetID = false
}) => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  // send form
  async function sendForm(dataF) {
    setLoad(true);
    ps.publish(EDIT.load, true);

    try {
      if (!isMounted) return;

      if (targetID) {
        // send edit

        const createValue = localStorage.getItem('create');

        if(createValue === 'on') {
          localStorage.removeItem('create');
          api.create(orderID, dataF)
        } else if (orderID) {
          await api.edit(orderID, targetID, dataF);
        } else if (customTargetID && data?.fields?.id) {
          await api.edit(data?.fields?.id, dataF);
        } else {
          await api.edit(targetID, dataF);
        }

        if (alerts.edit) toast(alerts.edit, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // send create
        await api.create(dataF);

        if (alerts.create) toast(alerts.create);
      }

      const applyValue = localStorage.getItem('apply');

      if(applyValue === 'on') {
        localStorage.removeItem('apply');
        document.location.reload();
        // const d = await api.get(targetID);
        //
        // if (d.fields) d.fields = parse.get ? parse.get(d.fields, targetID) : d.fields;
        //
        // // set data
        // setData(d);
      }
      if(!applyValue) {
        if (redirects?.reload) {
          document.location.href = document.location.origin + redirects.save + localStorage.getItem('sectionPrevId');
        } else {
          navigate(redirects.save);
        }
      }
    } catch (err) {
      if(err?.response?.status === 401) {
        await refreshToken(async () => {
          await sendForm(dataF).then((res) => window.location.reload());
        })
      } else {
        await errorMessage(err, '')
      }
    }

    setLoad(false);
    ps.publish(EDIT.load, false);
  }

  // handle submit
  function submitHandler(dataF) {
    if (!data) return;

    // console.log(parse.send(dataF, targetID)); //@todo
    sendForm(parse.send ? parse.send(dataF, targetID) : dataF);
  }

  // send remove
  async function sendRemove() {
    setLoad(true);
    ps.publish(EDIT.load, true);

    try {
      if (!isMounted) return;

      await api.remove(targetID);

      if (alerts.remove) toast(alerts.remove);
      navigate(redirects.remove);
    } catch (err) {
      await errorMessage(err, '')
    }

    setLoad(false);
    ps.publish(EDIT.load, false);
  }

  // handle remove
  async function removeHandler() {
    try {
      await confirmDialog(WONT_REMOVE);
      await sendRemove();
    } catch (err) {
      await errorMessage(err, '')
    }
  }

  // load data
  useEffect(() => {
    (async function () {
      try {
        // set data
        if (!isMounted) return;

        const d = await api.get(targetID);
        // add object data
        if(d[asyncDataGetKey] && d?.fields) {
          d.fields = {
            ...d.fields,
            [asyncDataGetKey]: d[asyncDataGetKey]
          }
        }

        if(d?.fields?.article_id) {
          const mediaData = await getMedia({
            'entity_type': 'article',
            'entity_id': d?.fields?.article_id,
            page: 1,
            per_page: 500,
          }).then(res => {
            const parseMedia = res?.data?.data?.map(item => ({
              id: item.id.toString(),
              src: item?.conversions?.original,
              alts: item?.custom_properties,
            }));
            return parseMedia;
          }).catch(async (err) => {
            await errorMessage(err, '')
          });
          if(mediaData) {
            d.fields = {
              ...d.fields,
              [asyncDataGetKey]: [...d[asyncDataGetKey], ...mediaData]
            }
          }
        }

        // parse get data
        if (d.fields) d.fields = parse.get ? parse.get(d.fields, targetID) : d.fields;

        // reset form fields (if update)
        if (d.fields && updateFields) updateFields(d.fields);

        // set data
        setData(d);

        // trigger create enable
        ps.publish(EDIT.enable);
      } catch (err) {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            const d = await api.get(targetID);
            // add object data
            if(d[asyncDataGetKey] && d?.fields) {
              d.fields = {
                ...d.fields,
                [asyncDataGetKey]: d[asyncDataGetKey]
              }
            }

            if(d?.fields?.article_id) {
              const mediaData = await getMedia({
                'entity_type': 'article',
                'entity_id': d?.fields?.article_id,
                page: 1,
                per_page: 500,
              }).then(res => {
                const parseMedia = res?.data?.data?.map(item => ({
                  id: item.id.toString(),
                  src: item?.conversions?.original,
                  alts: item?.custom_properties,
                }));
                return parseMedia;
              }).catch(async (err) => {
                await errorMessage(err, '')
              });
              if(mediaData) {
                d.fields = {
                  ...d.fields,
                  [asyncDataGetKey]: [...d[asyncDataGetKey], ...mediaData]
                }
              }
            }

            // parse get data
            if (d.fields) d.fields = parse.get ? parse.get(d.fields, targetID) : d.fields;

            // reset form fields (if update)
            if (d.fields && updateFields) updateFields(d.fields);

            // set data
            setData(d);

            // trigger create enable
            ps.publish(EDIT.enable);
            window.location.reload()
          })
        } else {
          console.log(err);
          if (targetID) {
            navigate(redirects.notFound);
          } else {
            toast.error(ERROR_RESP, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        }
      }

      if (targetID) setLoad(false);
    }());
  }, []);

  // subscribe save / remove
  useEffect(() => {
    // on create
    const tokenSave = ps.subscribe(EDIT.save, () => {
      if (handleSubmit) handleSubmit(submitHandler)();
      if (submitCb) submitCb(submitHandler);
    });
    const tokenRemove = ps.subscribe(EDIT.remove, removeHandler);

    return () => {
      ps.unsubscribe(tokenSave);
      ps.unsubscribe(tokenRemove);
    };
  }, [data, submitCb]);

  return {
    load,
    setLoad,
    data,
    setData,
    submitHandler,
  };
};

export default useEdit;
