import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import Button from "../../../components/ui/Button";
import s_FormSelect from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import { getProduct } from "../../../lib/api/products";
import errorMessage from "../../../lib/errorMessage";
import Loader from "../../../components/ui/Loader";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import icon_search from "../../../assets/icons/icon_search.svg";

const Modal = ({ active, setActive, title, category, excludedProductIds, setExcludedProductIds, setTableList }) => {
    const [productsList, setProductsList] = useState([]);
    const [filterProducts, setFilterProducts] = useState([]);
    const [searchProduct, setSearchProduct] = useState('');
    const [load, setLoad] = useState(false);
    const [chooseProduct, setChooseProduct] = useState([]);

    const handleChangeExcluded = () => {
        if (setTableList) {
            setTableList(prev => {
                const temp = [...prev];

                const findIndex = temp?.findIndex((item) => item?.id === category?.id)

                if (findIndex && (findIndex !== -1)) {
                    const currentLength = productsList?.filter(item => chooseProduct?.includes(item?.id?.toString()));

                    temp[findIndex].excluded_products_count = currentLength?.length;
                }

                return temp;
            })
        }
        setExcludedProductIds(chooseProduct);
        setActive(false);
    }

    const handleChangeText = (value) => setSearchProduct(value);

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            changeList();
        }
    }

    const changeList = () => {
        if (!!searchProduct?.length) {
            setFilterProducts(prev => prev?.filter((item => {
                if (!!searchProduct?.split(' ')?.length) {
                    const splitSearchText = searchProduct?.split(' ');
                    return splitSearchText?.some(itemS => item?.jde?.includes(itemS));
                } else {
                    return item?.jde?.includes(searchProduct);
                }
            })));
        } else {
            setFilterProducts(productsList);
        }
    };

    useEffect(() => {
        if (category?.id) {
            const handleGetProducts = async () => {
                setLoad(true);
                await getProduct({categories: category.id, per_page: 10000})
                    .then((res) => setProductsList(res?.data?.data))
                    .catch(async (err) => await errorMessage(err, `/products`))
                    .finally(() => setLoad(false));
            }

            handleGetProducts();
        }

        if (!!excludedProductIds?.length) {
            setChooseProduct(excludedProductIds);
        }
    }, []);

    useEffect(() => {
        if (!!productsList?.length) {
            setFilterProducts(productsList);
        } else {
            setFilterProducts([]);
        }
    }, [productsList]);

     return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>
                    <InputWrapper
                        label="Пошук JDE"
                        id="col-modal-search"
                    >
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Пошук JDE"
                            name="search-modal-jde"
                            id="col-modal-search"
                            value={searchProduct}
                            onChange={(e) => handleChangeText(e.target.value)}
                            onKeyDown={onEnterPress}
                        />
                        <div className={s_Modal.input_icon} onClick={changeList}>
                            <img src={icon_search} alt="Icon search" />
                        </div>
                    </InputWrapper>

                    <div className={s_Modal.product__block}>
                        {!load ? (
                            <ul>
                                {!!filterProducts?.length ? filterProducts.map((product, i) => (
                                    <li key={product.id}>
                                        <div className={s_FormSelect.checkbox__wrapper}>
                                            <input
                                                className={s_FormSelect.checkbox}
                                                type="checkbox"
                                                id={`col-excluded-product-ids-${i}`}
                                                name={`excluded_product_ids[${i}]`}
                                                value={product.id}
                                                checked={chooseProduct?.includes(product.id?.toString())}
                                                onChange={(e) => {
                                                    setChooseProduct((prev) => {
                                                        if (!!prev?.length) {
                                                            const temp = [...prev];
                                                            if (temp?.includes(e.target.value)) {
                                                                const findIndex = temp?.findIndex(item => item?.toString() === e.target.value);

                                                                if (findIndex !== -1) {
                                                                    temp.splice(findIndex, 1);
                                                                }

                                                                return temp;
                                                            }
                                                            return [...prev, e.target.value];
                                                        }
                                                        return [e.target.value];
                                                    })
                                                }}
                                            />
                                            <label htmlFor={`col-excluded-product-ids-${i}`} className={s_FormSelect.label}>
                                                {product?.title_ua || product?.title_ru || '---'} ({product?.jde})
                                            </label>
                                        </div>
                                    </li>
                                )) : 'Немає товарів'}

                            </ul>
                        ) : <Loader />}

                    </div>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{ justifyContent: 'flex-start' }}>
                        <div>
                            <Button onClick={handleChangeExcluded} purple>Додати товари</Button>
                        </div>
                        <div>
                            <Button border onClick={() => setActive(false)}>Скасувати</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;