const Constants = {
    ON_CHANGE: 'ON_CHANGE',
    ON_CHANGE_OBJ: 'ON_CHANGE_OBJ',
    ON_CHANGE_DELIVERY_OBJ: 'ON_CHANGE_DELIVERY_OBJ',
    ON_CHANGE_PRODUCT: 'ON_CHANGE_PRODUCT',
    ON_CHANGE_PRODUCT_SERVICE: 'ON_CHANGE_PRODUCT_SERVICE',
    ON_CHANGE_PRODUCT_SERVICE_MODAL: 'ON_CHANGE_PRODUCT_SERVICE_MODAL',
    REMOVE_PRODUCT_SERVICE_MODAL: 'REMOVE_PRODUCT_SERVICE_MODAL',
    ON_CHANGE_PRODUCT_OFFER: 'ON_CHANGE_PRODUCT_OFFER',
    USER_INFO: 'USER_INFO',
    USED_BONUSES: 'USED_BONUSES',
    NEW_BPM_DISCOUNT: 'NEW_BPM_DISCOUNT',
    CLEAR_FORM: 'CLEAR_FORM',

    ORDER_SUCCEEDED: 'ORDER_SUCCEEDED',
    ORDER_FAILED: 'ORDER_FAILED',

    ADD_SELECTED_ORDER: 'ADD_SELECTED_ORDER',
};

export default Constants;