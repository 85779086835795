import React from 'react';
import ReactToPrint from "react-to-print";
import Button from "../../components/ui/Button";
import icon_print from "../../assets/icons/icon_print.svg";
import {getOrderDocumentPdf} from "../../lib/api/order_document";

const Print = ({printData}) => {
    let componentRef;
    if(printData){
        const wrapper = document.querySelector('#ref');
        wrapper.innerHTML= printData;
    }

    return (
        <div>
            <ReactToPrint
                trigger={() => <Button purple><img src={icon_print} alt="Print icon"/>
                    Печать</Button>}
                content={() => componentRef}
             />
            <div style={{ display: "none" }}>
                <div ref={(el) => componentRef = el} id="ref" style={{ margin: "30px", fontSize: "14px" }}>
                    {printData}
                </div>
            </div>
        </div>
    );
};

export default Print;