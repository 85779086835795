import { getProductFeedback, removeProductFeedback } from '../../lib/api/product_feedback';
import { getDomains } from "../../lib/api/domains";
import { getAdminsAll } from "../../lib/api/admins";
import { getCatTree } from "../../lib/api/categories";


export const getFiltersData = () => Promise.allSettled([
  getDomains(), getAdminsAll(), getCatTree(),
])
  .then((res) => ({
      domains: res[0]?.value?.data,
      managers: res[1]?.value?.data?.data,
      categories: res[2]?.value?.data,
  }));

export const getData = getProductFeedback;
export const remove = removeProductFeedback;
