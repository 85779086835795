import {
    getSortBannersTypes,
    getSortBannerHeader,
    removeSortBannerHeader,
    updateSortBannerHeader,
    refreshSortBannerHeader, editSortBannerHeader
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getSortBannersTypes()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banner_types: res[1]?.value?.data
    }));


export const edit = editSortBannerHeader;
export const getData = getSortBannerHeader;
export const updateSort = updateSortBannerHeader;

export const refreshSort = refreshSortBannerHeader;
export const remove = removeSortBannerHeader;
