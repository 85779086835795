import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { HeadProvider, Meta } from 'react-head';
import { CookiesProvider } from 'react-cookie';
import DevTools from './containers/DevTools';
import { ENV_DEV, SENTRY_DSN } from './const/env';
import configureStore from './store';
import App from './App';
import * as Sentry from '@sentry/react';

import './scss/main.scss';

const store = configureStore();
// todo: add env variables, pass release versions
Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https://st3.protoria.ua/*"],
        }),
        new Sentry.Replay(),
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <HeadProvider>
        <Meta
          content="utf-8"
          httpEquiv="encoding"
        />
        <Meta
          content="text/html;charset=utf-8"
          httpEquiv="Content-Type"
        />
        <CookiesProvider>
          <App />
        </CookiesProvider>
        <ToastContainer
          autoClose={2000}
          draggable={false}
          pauseOnHover={false}
          progress={0}
          hideProgressBar
          position={toast.POSITION.BOTTOM_LEFT}
        />
      </HeadProvider>
      {ENV_DEV && <DevTools />}
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
