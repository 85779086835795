import {
  REVIEWS_GET, REVIEWS_GET_EDIT, REVIEWS_EDIT, REVIEWS_DELETE, REVIEWS_STATUSES_GET, REVIEWS_VISIT_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getReviews = (params) => fetchAx({
  url: REVIEWS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getReviewsEdit = (id) => fetchAx({
  url: REVIEWS_GET_EDIT.join(id),
  method: 'GET',
});

export const editReviews = (id, data) => fetchAx({
  url: REVIEWS_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeReviews = (id) => fetchAx({
  url: REVIEWS_DELETE.join(id),
  method: 'DELETE',
});

export const getReviewsStatuses = () => fetchAx({
  url: REVIEWS_STATUSES_GET,
  method: 'GET',
});

export const getReviewsVisit = (id) => fetchAx({
  url: REVIEWS_VISIT_GET.join(id),
  method: 'GET',
});

