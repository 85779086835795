/**
 * Convert Time Zone format to Date
 *
 * @param date
 * @param tzString
 * @returns {Date}
 */
export function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string'
      ? new Date(date)
      : date
    ).toLocaleString('en-US', { timeZone: tzString }),
  );
}

/**
 * Get TimeZone to js Date formatted string
 *
 * @param tz
 * @param timeOn
 * @returns {string}
 */
const getTZtoDate = (tz, timeOn = false) => {
  const dt = convertTZ(tz);

  const date = `${
        dt.getDate().toString().padStart(2, '0')}.${
        (dt.getMonth() + 1).toString().padStart(2, '0')}.${
		dt.getFullYear().toString().padStart(4, '0')}`;
  const time = `${
		dt.getHours().toString().padStart(2, '0')}:${
		dt.getMinutes().toString().padStart(2, '0')}`;
  // :${dt.getSeconds().toString().padStart(2, '0')}`;

  return timeOn ? `${date } ${ time}` : date;
};
export default getTZtoDate;

/// generate date format yyyy-mm-dd HH:mm:ss

export const formatUTC = (dateInt, addOffset = false) => {
    let date = (!dateInt || dateInt.length < 1) ? new Date : new Date(dateInt);
    if (typeof dateInt === "string") {
        return date;
    } else {
        const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
        const offsetDate = new Date();
        offsetDate.setTime(date.getTime() + offset * 60000)
        return offsetDate;
    }
}