import React, { useState } from 'react';
import s from './Modal.module.scss';
import Button from "../Button";
import { toast } from "react-toastify";
import Loader from "../Loader";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";

const ModalRemove = ({ active, setActive, title, href, navigate, data, funcRemove, setRemoveItem, id }) => {
    const [load, setLoad] = useState(false);

    const removeItem = async () => {
        setLoad(true);

        const handleRemove = async () => {
            await funcRemove(id || data.id).then(res => {

                if(setRemoveItem) {
                    setRemoveItem(prev => {
                        const temp = [...prev];
                        temp.splice(data?.indexItem, 1);
                        return temp;
                    });
                }

                if(href) {
                    navigate(href);
                }

                toast(res?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setActive(false);
            })
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await handleRemove()
                        })
                    } else {
                        await errorMessage(err, 'DELETE')
                    }
                })
                .finally(() => setLoad(false))
        }

        await handleRemove()
    };

    return (
        <div
            className={active ? `${s.modal} ${s.active}` : s.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s.modal__content}
                style={{ minWidth: 'auto' }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.modal__header}>
                    <div className={s.modal__title} style={{ textAlign: "center", marginRight: 0 }}>
                        {title ? title : 'Видалити'}
                    </div>
                </div>
                <div className={s.modal__footer}>
                    <div className={s.modal__group} style={{ justifyContent: "center" }}>
                        <div>
                            <Button border onClick={() => setActive(false)}>Ні, лишити!</Button>
                        </div>
                        <div>
                            <Button red disabled={load} onClick={removeItem}>Так, видалити!</Button>
                        </div>
                    </div>
                </div>
            </div>
            {load && <Loader />}
        </div>
    );
};

export default ModalRemove;
