import React, { useEffect, useState } from 'react';
import { VALIDATION } from '../../../const/text';
import cn from "classnames";

const InputDate = ({
  defVal = '', name, id, className, formH, withZero = true, required= true, custom = false, disabled = false
}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(defVal);
  }, [defVal]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const dateName = `${name}-dop`

  return (
    <>
      <input
        id={id}
        className={required
          ? cn(`form-input ${className}`, {
            'is-error': formH.errors[dateName],
          })
          : `form-input ${className}`}
        type="date"
        value={value}
        ref={required ? formH.register({
          required: VALIDATION.req.required,
        }): formH.register}
        name={dateName}
        onChange={handleChange}
        placeholder="Выберите дату"
        disabled={disabled}
      />
      <input
        type="text"
        id={`${id }-2`}
        name={name}
        value={`${value }${withZero ? ' 00:00:00' : ''}`}
        style={{ display: 'none' }}
        ref={required ? formH.register({
          required: VALIDATION.req.required,
        }): formH.register}
        readOnly
      />
      {!custom
          ? formH.errors[dateName] && <p className="form-input-hint text-error">{formH.errors[dateName].message}</p>
          : null
      }
    </>
  );
};

export default InputDate;
