import {
  PRODUCT_GET,
  PRODUCT_GET_SEARCH,
  PRODUCT_CREATE,
  PRODUCT_DELETE,
  PRODUCT_GET_EDIT,
  PRODUCT_EDIT,
  PRODUCT_SELECT_ALL,
  PRODUCT_SELECT,
  PRODUCT_SELECT_ALL_DELETE,
  PRODUCT_SELECT_APPLY,
  PRODUCT_GET_SEARCH_LIST,
  PRODUCT_MEDIA_GET,
  PRODUCT_AVAILABILITY_GET,
  PRODUCT_ADDITIONAL_OPTIONS_GET,
  PRODUCT_PRICES_GET,
  PRODUCT_COPY_CHARACTERISTICS_GET,
  PRODUCT_STATES_GET,
  PRODUCT_DOMAIN_OPTION_DELETE
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getProduct = (params) => fetchAx({
  url: PRODUCT_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xl,
    ...params,
  },
});

export const getProductSearch = (params) => fetchAx({
  url: PRODUCT_GET_SEARCH,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    ...params,
  },
});

export const getProductSearchList = (params) => fetchAx({
  url: PRODUCT_GET_SEARCH_LIST,
  method: 'GET',
  params: {
    lang: 'ru',
    page: 1,
    limit: 10000,
    ...params,
  },
});

export const createProduct = (data) => fetchAx({
  url: PRODUCT_CREATE,
  method: 'POST',
  data,
});

export const getProductEdit = (id) => fetchAx({
  url: PRODUCT_GET_EDIT.join(id),
  method: 'GET',
});

export const editProduct = (id, data) => fetchAx({
  url: PRODUCT_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeProduct = (id) => fetchAx({
  url: PRODUCT_DELETE.join(id),
  method: 'DELETE',
});

export const removeProductDomainOption = (id) => fetchAx({
  url: PRODUCT_DOMAIN_OPTION_DELETE.join(id),
  method: 'DELETE',
});

export const productSelect = (data) => fetchAx({
  url: PRODUCT_SELECT,
  method: 'POST',
  data,
});

export const productSelectAll = (data) => fetchAx({
  url: PRODUCT_SELECT_ALL,
  method: 'POST',
  data,
});

export const removeProductSelectAll = () => fetchAx({
  url: PRODUCT_SELECT_ALL_DELETE,
  method: 'DELETE',
});

export const applyProductSelect = (data, params) => fetchAx({
  url: `${PRODUCT_SELECT_APPLY}${params}`,
  method: 'POST',
  data
});

export const getProductMedia = (id) => fetchAx({
  url: PRODUCT_MEDIA_GET.join(id),
  method: 'GET',
});

export const uploadProductMedia = (id, data) => fetchAx({
  url: PRODUCT_MEDIA_GET.join(id),
  method: 'POST',
  data
});

export const getProductAvailability = (id) => fetchAx({
  url: PRODUCT_AVAILABILITY_GET.join(id),
  method: 'GET',
});

export const getProductPrices = (id) => fetchAx({
  url: PRODUCT_PRICES_GET.join(id),
  method: 'GET',
});

export const getProductAdditionalOptions = (params) => fetchAx({
  url: PRODUCT_ADDITIONAL_OPTIONS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    ...params,
  },
});

export const getProductCharacteristics = (jde) => fetchAx({
  url: PRODUCT_COPY_CHARACTERISTICS_GET.join(jde),
  method: 'GET'
});

export const getProductStates = () => fetchAx({
  url: PRODUCT_STATES_GET,
  method: 'GET'
});
