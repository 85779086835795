import { getProductConsultation, removeProductConsultation } from '../../lib/api/product_consultation';
import { getConsultationsManagersAll } from "../../lib/api/specialists";
import { getWhAll } from "../../lib/api/warehouses";

export const getFiltersData = () => Promise.allSettled([
    getConsultationsManagersAll(), getWhAll(),
]).then((res) => ({
    specialists: res[0]?.value?.data?.data,
    wareHouses: res[1]?.value?.data?.data,
}));

export const getData = getProductConsultation;
export const remove = removeProductConsultation;
