import React, { Fragment } from 'react';
import s from "../../OrderEditView/TopPanel/topPanel.module.scss";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
    return (
        <div className={s.breadcrumbs}>
            {items && items.map((item, index) => (
                <Fragment key={index}>
                    {item.url ? (
                        <>
                            <Link to={item.url}>{item.title}</Link> /
                        </>
                    ) : <span>{item.title}</span>}
                </Fragment>

            ))}
        </div>
    );
};

export default Breadcrumbs;