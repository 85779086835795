import React from "react";
import Block from "../../../components/ui/Block/Block";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s from "../Warehouse.module.scss";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import cn from "classnames";
import { VALIDATION } from "../../../const/text";
import { getDeliveriesSettlements } from "../../../lib/api/delivery";
import InputFetchResult from "../../../components/ui/InputFetchResultNew";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputMulti from "../../../components/ui/InputMultiNew";

const SenderInformation = ({ formH, data }) => {
    return (
        <Block
            title="Інформація відправника"
            classNameWrapper={s.block}
            styleContent={{ height: '70vh', overflow: 'hidden auto', backgroundColor: '#fff' }}
        >
            {/* parcel_sender_id */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                            <select
                                name="parcel_sender_id"
                                className={cn(s_Select.select, {
                                    [s_Select.select_error]: formH.errors?.parcel_sender_id,
                                })}
                                id="wh-parcel-sender-id"
                                ref={formH.register({
                                    required: VALIDATION.select.required,
                                })}
                            >
                                <option value="">виберіть відправника</option>
                                {!!data?.parcelSenders?.length && data?.parcelSenders?.map(parcelSender => (
                                    <option
                                        value={parcelSender.id}
                                        key={parcelSender.id}
                                        selected={parcelSender?.id?.toString() === data?.fields?.parcel_sender_id?.toString()}
                                    >
                                        {parcelSender.surname} {parcelSender.name} {parcelSender.patronymic}
                                    </option>
                                ))}
                            </select>
                            <label className={s_Select.label} htmlFor="wh-parcel-sender-id">Відправник посилки</label>
                        </div>
                        {formH.errors?.parcel_sender_id
                        && <p className="form-input-hint">{formH.errors.parcel_sender_id?.message}</p>}
                    </div>
                </ColFull>
            </Group>

            {/* settlement_id */}
            <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputFetchResult
                        name="settlement_id"
                        form={formH}
                        searchParam="title"
                        func={getDeliveriesSettlements}
                        defVal={data?.fields?.settlement}
                        id="wh-settlement-id"
                        placeholder="Область/Населений пункт"
                    />
                </ColFull>
            </Group>

            {/* address_street_ua */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper label="Вулиця УКР" id="wh-address-street-ua">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Вулиця УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="address_street_ua"
                            id="wh-address-street-ua"
                            defaultValue={data?.fields?.address_street_ua}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* address_street_ru */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper label="Вулиця РУС" id="wh-address-street-ru">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Вулиця РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="address_street_ru"
                            id="wh-address-street-ru"
                            defaultValue={data?.fields?.address_street_ru}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* address_house_number */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper label="Будинок" id="wh-address-house-number">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Будинок"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="address_house_number"
                            id="wh-address-house-number"
                            defaultValue={data?.fields?.address_house_number}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0, display: 'flex', gap: '16px' } }}>
                    {/* located_in_ua */}
                    <InputWrapper label="Розташований у УКР" id="wh-located-in-ua" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Розташований у УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="located_in_ua"
                            id="wh-located-in-ua"
                            defaultValue={data?.fields?.located_in_ua}
                        />
                    </InputWrapper>

                    {/* located_in_ru */}
                    <InputWrapper label="Розташований у РУС" id="wh-located-in-ru" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Розташований у РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="located_in_ru"
                            id="wh-located-in-ru"
                            defaultValue={data?.fields?.located_in_ru}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0, display: 'flex', gap: '16px' } }}>
                    {/* latitude */}
                    <InputWrapper label="Широта" id="wh-latitude" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Широта"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="latitude"
                            id="wh-latitude"
                            defaultValue={data?.fields?.latitude}
                        />
                    </InputWrapper>

                    {/* longitude */}
                    <InputWrapper label="Довгота" id="wh-longitude" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Довгота"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="longitude"
                            id="wh-longitude"
                            defaultValue={data?.fields?.longitude}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0, display: 'flex', gap: '16px' } }}>
                    {/* work_time_ua */}
                    <InputWrapper label="Робочий час УКР" id="wh-work-time-ua" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Робочий час УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="work_time_ua"
                            id="wh-work-time-ua"
                            defaultValue={data?.fields?.work_time_ua}
                        />
                    </InputWrapper>

                    {/* work_time_ru */}
                    <InputWrapper label="Робочий час РУС" id="wh-work-time-ru" style={{ marginRight: 0, width: '100%' }}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Робочий час РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="work_time_ru"
                            id="wh-work-time-ru"
                            defaultValue={data?.fields?.work_time_ru}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* email */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputWrapper
                        label="Email"
                        id="wh-email"
                        errorComponent={formH?.errors?.email &&
                        <p className="form-input-hint">{formH?.errors?.email?.message}</p>}
                    >
                        <input
                            className={cn(s_Input.input, {
                                [s_Input.input_error]: formH?.errors?.email,
                            })}
                            type="email"
                            placeholder="Email"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Не валідний email"
                                }
                            })}
                            name="email"
                            id="wh-email"
                            defaultValue={data?.fields?.email}
                        />
                    </InputWrapper>
                </ColFull>
            </Group>

            {/* phones */}
            <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                <ColFull attrs={{ style: { paddingLeft: 0, paddingRight: 0 } }}>
                    <InputMulti type="text" name="phones" maxItems={4} form={formH} defVal={data?.fields?.phones} />
                </ColFull>
            </Group>
        </Block>
    );
};

export default SenderInformation;