const getNumbersWithSpaces = (number) => {
    if (number) {
        const result = `${number}`.split('').reverse().map((el, index) => index % 3 !== 2 ? el : ` ${el}`).reverse().join('');
        if(number.toString().includes('.00')) {
            return result.substring(0, result.length - 4) + '.00';
        }
        return result + '.00';
    }
    return '0.00';
}

export default getNumbersWithSpaces;