// @todo
/* eslint-disable */
export const DOMAINS = [
  {
    id: 1,
    name: 'samsungshop',
    title: 'samsungshop',
  },
  {
    id: 2,
    name: 'hr',
    title: 'hr',
  },
  {
    id: 3,
    name: 'design',
    title: 'design',
  },
  {
    id: 4,
    name: 'main',
    title: 'main',
  },
];

export const geDomainName = (type) => DOMAINS.find((el) => type?.toString() === el.id?.toString());
