import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from '../../lib/crud/useList';
import { getData, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListView/Table";
import TableListViewRowLoader from "../../components/common/TableListView/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListView/Row";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import { geDomainName } from "../../lib/const/domains";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const DeliveryList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    })

    return(
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Delivery list`} />
            <TableListViewTable>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                        <th>Домен</th>
                        <th>Статус активности</th>
                        <th> </th>
                    </tr>
                    <TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {},
                            { type: 'select', name: 'domain_id', options: filtersData?.domains },
                            {},
                            {},
                            // {
                            //     type: 'select',
                            //     name: 'is_active',
                            //     options: [
                            //         {
                            //             id: 0,
                            //             title: 'отключена',
                            //         },
                            //         {
                            //             id: 1,
                            //             title: 'активна',
                            //         },
                            //     ],
                            // },
                        ]}
                        onSubmit={pushUrlParams}
                    />
                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5} />
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`delivery-item-${e.id}`}
                                    editLink={`/app/delivery/update/${e.id}`}
                                    permissionRemove='admin.delivery.destroy'
                                    permissionEdit='admin.delivery.edit'
                                    deleteButton={false}
                                >
                                    <td>
                                        {checkPermission('admin.delivery.edit')
                                            ? <Link to={`/app/delivery/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>{e.title}</td>
                                    <td>{e?.domain_id ? geDomainName(e.domain_id)?.name : '---'}</td>
                                    <td>{e?.is_active ? 'активна' : 'отключена'}</td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={5} />
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>

            {!load && (
                <TableListViewFooter
                    left={(
                        <>
                            {+rest?.total && (
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    total={+rest?.total}
                                    perPage={+rest?.per_page}
                                    loc={location}
                                />
                            )}
                        </>
                    )}
                    right={(
                        <TableListViewPerPage
                            urlParams={urlParams}
                            onSelect={(val, params) => pushUrlParams(params)}
                        />
                    )}
                    rightCn="col-auto"
                />
            )}
        </div>
    )
}

export default DeliveryList
