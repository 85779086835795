import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import { getData, remove, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import getSplicedData from "../../lib/crud/getSplicedData";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import getObjInArrFind from "../../lib/helpers/getObjInArrFind";

const ArticlesList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        filtersData,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    })
    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Article list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Зображення', 'Тип', 'Заголовок', 'Дата початку', 'Дата закінчення', 'Статус'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {},
                            {
                                type: 'select',
                                name: 'type',
                                options: filtersData?.types && filtersData.types,
                                optProps: ['value', 'title']
                            },
                            {type: 'text', name: 'title_ru'},
                            {type: 'date', name: 'publish_from'},
                            {type: 'date', name: 'publish_to'},
                            {
                                type: 'select',
                                name: 'status',
                                options: filtersData?.status && filtersData.status,
                                optProps: ['value', 'title']
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={7}/>
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`article-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Новину видалено',
                                    }}
                                    editLink={`/app/article/update/${e.id}`}
                                    permissionRemove='admin.articles.destroy'
                                    permissionEdit='admin.articles.edit'
                                >
                                    <td>
                                        {checkPermission('admin.articles.edit')
                                            ? <Link to={`/app/article/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        {e?.cover?.conversions?.original
                                            ? <img src={e?.cover?.conversions?.original} alt=""/>
                                            : '---'}
                                    </td>
                                    <td>
                                        <span>
                                          {e?.type
                                              ? !!filtersData?.types?.length
                                              && getObjInArrFind(filtersData?.types, 'value', e?.type)?.title
                                              : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                          {e?.title_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                          {e?.publish_at || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                          {e?.expired_at || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                          {e?.status
                                              ? !!filtersData?.status?.length
                                              && getObjInArrFind(filtersData?.status, 'value', e?.status)?.title
                                              : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7}>Немає новин</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    )
}

export default ArticlesList;