import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import StatusCircle from "../../components/ui/StatusCircle";

const PromocodeOfNonPurchasingActivityList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Promocode list`}/>
            {!load && (
                <TopPagination
                    tabs={(
                        <TopNavTabs tabs={[
                            {
                                title: 'Серії промокодів',
                                url: '/app/promocode-series',
                                permissionName: 'admin.cart-rules.index'
                            },
                            {
                                title: 'Промокоди не купівельної активності',
                                url: '/app/promocode',
                                permissionName: 'admin.cart-rules.index',
                                isActive: true
                            },
                            {
                                title: 'Промокоди регістрації',
                                url: '/app/promocode-register',
                                permissionName: 'admin.cart-rules.index'
                            },
                            {
                              title: 'Промокоды СЦ',
                              url: '/app/promocode-service-centers',
                              permissionName: 'admin.cart-rules.index'
                            }
                        ]}
                        />
                    )}
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Назва серії промокоду', 'Опис УКР', 'Опис ХРУ', 'Статус активності', 'Термін дії від', 'Термін дії до', 'Домен'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'name', attr: {placeholder: 'Пошук'}},
                            {type: 'text', name: 'desc_ua', attr: {placeholder: 'Пошук'}},
                            {type: 'text', name: 'desc_ru', attr: {placeholder: 'Пошук'}},
                            {
                                type: 'select', name: 'is_active',
                                options: [
                                    {
                                        id: 0,
                                        title: 'Вимкнена',
                                    },
                                    {
                                        id: 1,
                                        title: 'Активна',
                                    },
                                ],
                            },
                            {type: 'date', name: 'start_from', width: '18%'},
                            {type: 'date', name: 'end_to', width: '18%'},
                            {
                                type: 'select',
                                name: 'domain_id',
                                options: filtersData?.domains && filtersData?.domains,
                                optProps: ['id', 'title']
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={11}/>
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`cart-rule-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Промокод не купівельної активності видалений',
                                    }}
                                    editLink={`/app/promocode/update/${e.id}`}
                                    permissionRemove='admin.cart-rules.destroy'
                                    permissionEdit='admin.cart-rules.edit'
                                >
                                    <td>
                                        {checkPermission('admin.cart-rules.edit')
                                            ? <Link to={`/app/promocode/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                              <span>
                                {e.name || '---'}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {e.desc_ua || '---'}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {e.desc_ru || '---'}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {e.is_active ? <StatusCircle green text="Активна"/> :
                                    <StatusCircle red text="Не активна"/>}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {e?.starts_at || '---'}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {e?.ends_at || '---'}
                              </span>
                                    </td>
                                    <td>
                              <span>
                                {!!e?.domains?.length && e?.domains[0]?.title || '---'}
                              </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7} style={{paddingTop: '22px'}}>Немає промокоді не купівельної активності
                                    </td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    )
};

export default PromocodeOfNonPurchasingActivityList;
