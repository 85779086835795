import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import { NOTICES_GET } from '../../const/api';

export const getNotices = (params) => fetchAx({
  url: NOTICES_GET,
  method: 'GET',
  params: {
    // per_page: LIST_PER_PAGE.xxxl,
    ...params,
  },
});
