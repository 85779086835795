import cn from 'classnames';
import React from 'react';

const ColLeft = ({ children, col = 4, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(`column col-${col} col-sm-12`, attrs && attrs.className),
  };

  return (
    <div {...attrsMerge}>{children}</div>
  );
};

export default ColLeft;
