import { parseAll } from '../../lib/api/_parseApi';
import { createDomain, removeDomain, getDomainEdit, editDomain } from '../../lib/api/domains';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (domainId) => Promise.allSettled([domainId && getDomainEdit(domainId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: domainId && res[0]?.value?.data,
    })
  });

export const create = createDomain;
export const edit = editDomain;
export const remove = removeDomain;

export const parseDataSend = (data, bankId) => {
  let d = { ...data };
  const isUpdate = typeof bankId !== 'undefined';

  d = parseAll(d, !isUpdate, [
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
