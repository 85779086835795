import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
    CART_RULE_CREATE,
    CART_RULE_EDIT,
    CART_RULE_GET_EDIT,
    CART_RULES_GET,
    CART_RULE_DELETE,
    CART_RULES_ACTION_TYPES_GET,
    CART_RULES_CONDITIONS_GET,
    CART_RULES_COUPONS_GET,
    CART_RULE_COUPONS_DELETE,
    CART_RULE_COUPONS_CREATE,
    CART_RULES_COUPONS_CREATE_GET,
} from '../../const/api';

export const getCurtRules = (params) => fetchAx({
    url: CART_RULES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        coupon_type: [0, 1],
        page: 1,
        ...params,
    },
});

export const getCurtRulesPromocode = (params) => fetchAx({
    url: CART_RULES_GET,
    method: 'GET',
    params: {
        coupon_type: 2,
        per_page: LIST_PER_PAGE.default,
        page: 1,
        ...params,
    },
});

export const getCurtRulesPromocodeRegister = (params) => fetchAx({
    url: CART_RULES_GET,
    method: 'GET',
    params: {
        coupon_type: 3,
        per_page: LIST_PER_PAGE.default,
        page: 1,
        ...params,
    },
});

export const getCurtRulesPromocodeServiceCenters = (params) => fetchAx({
  url: CART_RULES_GET,
  method: 'GET',
  params: {
    coupon_type: 5,
    per_page: LIST_PER_PAGE.default,
    page: 1,
    ...params,
  },
});

export const getCurtRulesAll = () => fetchAx({
    url: CART_RULES_GET,
    method: 'GET',
});

export const getCurtRulesActionTypes = () => fetchAx({
    url: CART_RULES_ACTION_TYPES_GET,
    method: 'GET',
});

export const getCurtRulesConditions = () => fetchAx({
    url: CART_RULES_CONDITIONS_GET,
    method: 'GET',
});

export const createCurtRule = (data) => fetchAx({
    url: CART_RULE_CREATE,
    method: 'POST',
    data,
});

export const getCurtRuleEdit = (id) => fetchAx({
    url: CART_RULE_GET_EDIT.join(id),
    method: 'GET',
});

export const editCurtRule = (id, data) => fetchAx({
    url: CART_RULE_EDIT.join(id),
    method: 'PUT',
    data,
});

export const removeCurtRule = (id) => fetchAx({
    url: CART_RULE_DELETE.join(id),
    method: 'DELETE',
});

export const getCurtRulesCoupons = (id, params) => fetchAx({
    url: CART_RULES_COUPONS_GET.join(id),
    method: 'GET',
    params: {
        page: 1,
        per_page: LIST_PER_PAGE.default,
        ...params
    }
});

export const getCurtRulesCouponsCreate = (id, params) => fetchAx({
    url: CART_RULES_COUPONS_CREATE_GET.join(id),
    method: 'GET',
    params: {
        page: 1,
        per_page: LIST_PER_PAGE.default,
        ...params
    }
});

export const createCurtCoupon = (data) => fetchAx({
    url: CART_RULE_COUPONS_CREATE,
    method: 'POST',
    data,
});

export const removeCurtRuleCoupon = (id) => fetchAx({
    url: CART_RULE_COUPONS_DELETE.join(id),
    method: 'DELETE',
});


/* Discounts Pks */

export const getCurtRulesDiscountPks = () => fetchAx({
    url: CART_RULES_GET + '/create-type/4',
    method: 'GET'
});
