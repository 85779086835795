import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getData, remove } from "./api";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import { ModalAddDocument } from "./Modal";
import TopPanel from "./TopPanel/TopPanel";
import getSplicedData from "../../lib/crud/getSplicedData";
import RemoveComponent from "./removeComponent";
import getTZtoDate from "../../lib/getTZtoDate";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";
import icon_edit from "../../assets/icons/icon_edit.svg";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const OrderDocumentList = () => {
    const { id: orderId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    });

    const [openAdd, setOpenAdd] = useState(false);

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Order document list`} />
            <TopPanel formProps={rest} orderID={orderId} setOpenAdd={setOpenAdd} breadcrumbs={{
                items: [
                    {
                        title: 'Головна',
                        url: '/app',
                    },
                    {
                        title: 'Заказы',
                        url: '/app/orders',
                    },
                    {
                        title: `Заказ #${orderId}`,
                        url: `/app/order/update/${orderId}`,
                    },
                    {
                        title: 'Документы к заказу'
                    },
                ]
            }} />
            <div>
                <div className={s_Table.table__inner}>
                    <div className={s_Table.table__wrapper}>
                        <table className={s_Table.table}>
                            <thead className={s_Table.thead}>
                                <tr>
                                    <th>
                                        <div>ID</div>
                                    </th>
                                    <th>
                                        <div>Дата формирования</div>
                                    </th>
                                    <th>
                                        <div>Дата обновления</div>
                                    </th>
                                    <th>
                                        <div>Название</div>
                                    </th>
                                    <th>
                                        <div>Менеджер</div>
                                    </th>
                                    <th>
                                        <div>Отправление клиенту</div>
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody className={s_Table.tbody}>
                                {rest && rest.map((item, i) => (
                                    <tr key={item.id}>
                                        <td>
                                            <div>{item?.id}</div>
                                        </td>
                                        <td>
                                            <div>{getTZtoDate(item.created_at, true)}</div>
                                        </td>
                                        <td>
                                            <div>{getTZtoDate(item.updated_at, true)}</div>
                                        </td>
                                        <td>
                                            <div>
                                                {checkPermission('admin.order.document.edit')
                                                    ? <Link to={`/app/order/update/${orderId}/document/${item.id}`}>{item?.template?.name}</Link>
                                                    : <span>{item?.template?.name}</span>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div>{item?.manager?.name} {item?.manager?.surname}</div>
                                        </td>
                                        <td>
                                            <div>{item?.sent_at ? item?.sent_at : '---'}</div>
                                        </td>
                                        <td>
                                            <div className={s_Table.icon__block}>
                                                <div>
                                                    <Link to={`/app/order/update/${orderId}/document/${item.id}`}>
                                                        <img src={icon_edit} alt="icon edit" />
                                                    </Link>
                                                </div>
                                                <RemoveComponent id={item.id} remove={{
                                                    api: remove,
                                                    success: () => setRest(getSplicedData(i, rest)),
                                                    alert: 'Документ удален',
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {openAdd && (
                <ModalAddDocument active={openAdd} setActive={setOpenAdd} data={rest} orderID={orderId} title="Новый документ" />
            )}
        </div>
    );
};

export default OrderDocumentList;
