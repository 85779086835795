export const isAuthenticated = () => {
    const token = localStorage.getItem('tokenData');
    if(token) return true;

    return false;
}

export const isAuth = async navigate => {
    if(!localStorage?.tokenData){
        return navigate('/login')
    }
}
