import fetchAx from "../fetchAx";
import { AUDIT_GET } from "../../const/api";
import { LIST_PER_PAGE } from "../../const/view";

export const getAudit = (params) => fetchAx({
    url: AUDIT_GET,
    method: 'GET',
    params: {
        // per_page: LIST_PER_PAGE.xxxl,
        ...params
    }
});