import React, {useEffect, useState} from 'react';
import s from './CheckboxSwitch.module.scss';
import cn from "classnames";

const CheckboxSwitch = ({ name, defaultChecked, id, label, fontWeight, onClickFunc, formH, styleLabel = {}, reverse = false }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if(defaultChecked) setActive(true);
    }, [defaultChecked]);

    return (
        <div className={s.switch}>
            <label
                className={cn(s.switch__title, {
                    [s.active]: active,
                    [s.switch__title_reverse]: reverse
                })}
                htmlFor={id}
                style={{ fontWeight: fontWeight || 700, ...styleLabel }}
            >
                {label}
            </label>
            <div className={cn(s.checkbox, {
                [s.active]: active,
                [s.checkbox_reverse]: reverse
            })}>
                <input
                    id={id}
                    type="checkbox"
                    defaultChecked={defaultChecked}
                    name={name}
                    onClick={() => setActive((prev) => {
                        if (onClickFunc) {
                            onClickFunc(!prev)
                        }
                        return !prev;
                    })}
                    ref={formH && formH?.register}
                />
            </div>
        </div>
    );
};

export default CheckboxSwitch;