export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            h1.h1-native{
                font-size: 58px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            @media screen and (max-width: 992px) {
                h1.h1-native {
                    font-size: 46px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h1.h1-native {
                    font-size: 34px !important;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `<h1 class="h1-native d-flex align-center justify-content-center mb-2 mt-2 mb-lg-4 mt-lg-4"><span>Заголовок H1</span></h1> ${style}`,
    })
};