import {
    ARTICLES_GET,
    ARTICLE_CREATE,
    ARTICLE_DELETE,
    ARTICLE_GET_EDIT,
    ARTICLE_EDIT,
    ARTICLE_GET_TYPES,
    ARTICLE_GET_STATUS,
} from '../../const/api'
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

export const getArticles = (params) => fetchAx({
    url: ARTICLES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        page: 1,
        ...params,
    },
})

export const createArticle = (data) => fetchAx({
    url: ARTICLE_CREATE,
    method: 'POST',
    data
})

export const getArticleEdit = (id) => fetchAx({
    url: ARTICLE_GET_EDIT.join(id),
    method: 'GET',
})

export const editArticle = (id, data) => fetchAx({
    url: ARTICLE_EDIT.join(id),
    method: 'PUT',
    data
})

export const removeArticle = (id) => fetchAx({
    url: ARTICLE_DELETE.join(id),
    method: 'DELETE'
})

export const getArticleTypes = () => fetchAx({
    url: ARTICLE_GET_TYPES,
    method: 'GET',
})

export const getArticleStatus = () => fetchAx({
    url: ARTICLE_GET_STATUS,
    method: 'GET',
})