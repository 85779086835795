import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, getFiltersData, remove } from './api';
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import getSplicedData from "../../lib/crud/getSplicedData";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const RoleList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
   const {
    rest,
    filtersData,
    load,
    setRest,
    urlParams,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  /* eslint-disable */
  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Role list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
              urlParams={urlParams}
              fields={[
                {},
                { type: 'text', name: 'name' },
                {},
              ]}
              onSubmit={pushUrlParams}
              loading={!filtersData}
          />

        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={3} />
            )
            : (rest && (
                  rest?.data?.length
                      ? rest?.data?.map((e, i) => (
                          <TableListViewRow
                              id={e.id}
                              key={e.id}
                              remove={{
                                api: remove,
                                success: () => setRest(getSplicedData(i, rest)),
                                alert: 'Роль удалена',
                              }}
                              editLink={`/app/role/update/${e.id}`}
                              permissionRemove='admin.admin.roles.destroy'
                              permissionEdit='admin.admin.roles.edit'
                          >
                            <td>
                                {checkPermission('admin.admin.roles.edit')
                                    ? <Link to={`/app/role/update/${e.id}`}>{e.id}</Link>
                                    : <span>{e.id}</span>
                                }
                            </td>
                            <td>{e.name || '-'}</td>
                          </TableListViewRow>
                      ))
                      : (
                          <TableListViewRow disabled>
                              <td colSpan={2}>Немає ролей</td>
                          </TableListViewRow>
                      )
              ))}
        </tbody>
      </TableListViewTable>

      {!load && (
          <TableListViewFooter
              left={(
                  <>
                      {rest?.meta?.total > 0 && (
                          <TableListViewPagination
                              length={+rest?.meta?.last_page}
                              current={+rest?.meta?.current_page}
                              total={+rest?.meta?.total}
                              perPage={+rest?.meta?.per_page}
                              loc={location}
                          />
                      )}
                  </>
              )}
              right={(
                  <TableListViewPerPage
                      urlParams={urlParams}
                      onSelect={(val, params) => pushUrlParams(params)}
                  />
              )}
              rightCn="col-auto"
          />
      )}

    </div>
  );
};

export default RoleList;
