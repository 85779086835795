import {editSortSpecialOffers, getSortSpecialOffers, removeSortSpecialOffers} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";
import Promise from "q";

export const getFiltersData = () => Promise.allSettled([
    getDomains()
])
    .then((res) => ({
        domains: res[0]?.value?.data
    }));

export const edit = editSortSpecialOffers
export const getData = getSortSpecialOffers;
export const remove = removeSortSpecialOffers;
