import { toast } from "react-toastify";
import { refreshToken } from "./crud/refreshToken";
import removeLocalStorage from "./helpers/removeLocalStorage";
import { DOMAIN_API } from "../const/api";

const errorMessage = async (err, message) => {
    if(err?.response?.status === 422){
        toast.error(
            <>
                <h3>Ошибки валидации:</h3>
                {err?.response?.data?.errors && Object.values(err?.response?.data?.errors).map(e =>
                    <p>- {e}</p>
                )}
            </>, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    } else if(err?.response?.status === 403) {
        toast.error(err?.response?.data?.message || err?.response?.message || `Authorization ${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if(err?.response?.status === 404) {
        toast.error(err?.response?.data?.message || err?.response?.data?.error || err?.response?.message || `API not found ${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if(err?.response?.status === 401) {
        return;
    } else if(err?.response?.status === 'customBPNDiscount') {
        toast.error(`${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if(err?.response?.status === 'customDocumentPrint') {
        toast.error(`${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if(err?.response?.status === 'customDocumentSelect') {
        toast.error(`${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error(err?.response?.data?.message || err?.response?.message || err?.message || `Error ${message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export default errorMessage;