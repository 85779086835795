import fetchAx from '../fetchAx';
import {
    SHIFT_CLOSE,
    SHIFT_OPEN,
    SHIFT_STATUS_GET
} from '../../const/api';

export const getShiftStatus = () => fetchAx({
    url: SHIFT_STATUS_GET,
    method: 'GET',
});

export const openShift = () => fetchAx({
    url: SHIFT_OPEN,
    method: 'POST',
});

export const closeShift = () => fetchAx({
    url: SHIFT_CLOSE,
    method: 'DELETE',
});