import React from 'react';
// import cn from "classnames";

// import s from "./GridEditViewSidebar.module.scss";

const SidebarRight = ({ children }) => (
  <div className="column col-lg-12 col-2">
    <div className="-sticky">
      {children}
    </div>
  </div>
);

export default SidebarRight;
