import cn from 'classnames';
import React from 'react';

import s from './Table.module.scss';

const TableListViewTable = ({ children, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn('table table-hover', s.table, attrs && attrs.className),
  };

  return (
    <div className={s.wrap}>
      <table {...attrsMerge}>{children}</table>
    </div>
  );
};

export default TableListViewTable;
