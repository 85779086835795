import React from "react";
import s from "./CopySpecOfferBanner.module.scss";
import icon_arrow_right_big_border from "../../../assets/icons/icon_arrow_right_big_border.svg";
import {Link, useNavigate} from "react-router-dom";
import errorMessage from "../../../lib/errorMessage";
import { getSpecOfferBannersCloneEdit } from "../../../lib/api/specOfferBanners";
import getTZtoDate from "../../../lib/getTZtoDate";

const CopySpecOfferBanner = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className={s.wrapper}>
            <div className={s.block} onClick={() => {
                if (data?.parent?.id) {
                    navigate(`/app/spec-offer-banner/update/${data?.parent?.id}`);
                    window.location.reload();
                }
            }} style={{ borderColor: data?.parent === null ? '#5755D1' : '#A2B3C2'}}>
                <div style={{ marginRight: '12px' }}>Дата кінця: {data?.parent?.date_to
                    ? getTZtoDate(data?.parent?.date_to)
                    : data?.date_to
                        ? getTZtoDate(data?.date_to)
                        : '--.--.--'}
                </div>
                <div>Час: {data?.parent?.date_to
                    ? data?.parent?.date_to?.split(' ')?.[1]
                    : data?.date_to
                        ? data?.date_to?.split(' ')?.[1]
                        : '--:--'}</div>
            </div>
            <div>
                <img src={icon_arrow_right_big_border} alt="icon arrow right" />
            </div>
            <div className={s.block} onClick={async () => {
                if (data?.child?.id) {
                    navigate(`/app/spec-offer-banner/update/${data?.child?.id}`);
                    window.location.reload();
                } else {
                    if (data?.parent === null) {
                        await getSpecOfferBannersCloneEdit(data?.id).then((res) => {
                            navigate(`/app/spec-offer-banner/update/${res?.data?.id}`);
                            window.location.reload();
                        }).catch((err) => errorMessage(err, ''));
                    }
                }
            }} style={{ borderColor: data?.parent?.id ? '#5755D1' : '#A2B3C2'}}>
                <div style={{ marginRight: '12px' }}>
                    Дата початку: {data?.child?.date_from ? getTZtoDate(data?.child?.date_from) : data?.parent
                        ? data?.date_from
                            ? data?.date_from
                                ? getTZtoDate(data?.date_from)
                                : '--.--.--'
                            : '--.--.--'
                        : '--.--.--'}
                </div>
                <div>Час: {data?.child?.date_from ? data?.child?.date_from?.split(' ')?.[1] : data?.parent
                    ? data?.date_from
                        ? data?.date_from
                            ? data?.date_from?.split(' ')?.[1]
                            : '--:--'
                        : '--:--'
                    : '--:--'}</div>
            </div>
        </div>
    );
};

export default CopySpecOfferBanner;