import React, { useEffect, useState } from "react";
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import ColRight from "../../../components/ui/FormEditView/ColRight";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputDate from "../../../components/ui/InputDate";
import { in_cart_status, order_status_payment } from "../../../lib/const/order";
import { uid } from "react-uid";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputWrapper from "../../../components/ui/InputWrapper";

const OrderParamsInfo = ({ formProps }) => {
    const form = formProps.formH;
    const dataFields = formProps.data && formProps.data.fields;
    const [availableStatuses, setAvailableStatuses] = useState([]);
    const status = form.watch('status');

    useEffect(() => {
      if(!formProps.data?.fields?.status_payment){
        setAvailableStatuses([1])
      } else if(formProps.data.fields.status_payment === 1){
        setAvailableStatuses([1, 2])
      } else if(formProps.data.fields.status_payment === 2){
        setAvailableStatuses([3, 4])
      }
    },[]);

    return(
        <Item title='Параметры заказа'>
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag='div'>

                    {/* created_at */}
                    <Group attrs={{className: 'mt-1 mb-0'}}>
                        <ColFull>
                            <div className={s.wrapper}>
                                <div className={s.input__wrapper}>
                                    <InputDate
                                        withZero={false}
                                        id="or-created_at"
                                        name="created_at"
                                        className={s.input}
                                        defVal={dataFields?.created_at.split("T")[0]}
                                        placeholder="Выберите дату"
                                        formH={form}
                                        required={false}
                                    />
                                    <label className={s.label} htmlFor="or-created_at">Дата создания</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* manager */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.text__wrapper}>
                                <div>Создан менеджером</div>
                                <div className={s_Select.text}>{dataFields?.manager ? dataFields?.manager.name : 'Клиент'}</div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="domain_id"
                                                className={s_Select.select}
                                                id="or-domain"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value="">выберите cайт</option>
                                                {formProps.data && !!formProps.data.domains && formProps.data.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                        selected={domain.id === dataFields?.domain_id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="or-domain">Сайт*</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* warehouse_id */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="warehouse_id"
                                                className={s_Select.select}
                                                id="or-warehouse_id"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value="">выберите склад</option>
                                                {formProps.data?.wareHouses?.map((type) => (
                                                    <option
                                                        key={type.id}
                                                        value={type.id}
                                                        selected={type.id === dataFields?.warehouse_id}
                                                    >
                                                        {type.title_ru || type.title_ua}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="or-warehouse_id">Склад отгрузки*</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* domain_title */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.text__wrapper}>
                                <div>Создан в магазине</div>
                                <div className={s_Select.text}>{formProps?.data?.fields?.domain?.title ? formProps?.data?.fields?.domain?.title : ''}</div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* order_comment[text] */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.textarea__wrapper}>
                                    <textarea
                                        name="comment"
                                        className={s_Select.textarea}
                                        id="or-comment"
                                        placeholder="Комментарий к заказу"
                                        rows="4"
                                        ref={form.register}
                                    />
                                    <label className={s_Select.label__textarea} htmlFor="or-comment">Комментарий к заказу</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* Promo code */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Промокод" id="or-promocode">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Промокод"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="promocode"
                                    id="or-promocode"
                                    value={dataFields?.promocode}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* status */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data && (
                                        <select
                                            name="status"
                                            className={s_Select.select}
                                            id="order-status"
                                            ref={form.register}
                                            disabled
                                        >
                                            <option value="">выберите статус</option>
                                            {in_cart_status.map((status) => (
                                                <option
                                                    key={uid(status)}
                                                    value={status.id}
                                                    selected={status.id === dataFields?.status}
                                                >
                                                    {status.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <label className={s_Select.label} htmlFor="order-status">
                                        Статус заказа
                                    </label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* cancel_variant_id */}
                    {((+status === 16) || (+status === 17)) && (
                        <Group attrs={{className: 'mt-0 mb-0'}}>
                            <ColFull>
                                <div className={s_Select.select__inner}>
                                    <div className={s_Select.select__wrapper}>
                                        <select
                                            name="cancel_variant_id"
                                            className={s_Select.select}
                                            id="or-cancel_variant"
                                            ref={form.register}
                                            disabled
                                        >
                                            <option value="">выберите причину отказа</option>
                                            {formProps.data?.cancelVariants?.map((type) => (
                                                <option
                                                    key={type.id}
                                                    value={type.id}
                                                    selected={type.id === dataFields?.cancel_variant_id}
                                                >
                                                    {type.title_ru || type.title_ua}
                                                </option>
                                            ))}
                                        </select>
                                        <label className={s_Select.label} htmlFor="or-cancel_variant">
                                            Причина отказа
                                        </label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                    {/* status_payment */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data && (
                                        <select
                                            name="status_payment"
                                            className={s_Select.select}
                                            id="or-order_status_payment"
                                            ref={form.register}
                                            disabled
                                        >
                                            <option value="">выберите статус</option>
                                            {order_status_payment.map((status) => (
                                                <option
                                                    key={uid(status)}
                                                    value={status.id}
                                                    disabled={!availableStatuses.includes(status.id)}
                                                    selected={status.id === dataFields?.status_payment}
                                                >
                                                    {status.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    <label className={s_Select.label} htmlFor="order-status-payment">Статус оплаты</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* type */}
                    <ColRight>
                        <input
                            type="hidden"
                            name={`type`}
                            defaultValue={4}
                            ref={form.register}
                        />
                    </ColRight>
                </Form>
            </form>
        </Item>
    )
}

export default OrderParamsInfo
