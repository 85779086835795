import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import TableListViewFiltersRow from '../../components/common/TableListView/FiltersRow';
import { getData, remove } from './api';
import getTZtoDate from "../../lib/getTZtoDate";
import getSplicedData from "../../lib/crud/getSplicedData";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const TemplateList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Template list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Имя</th>
            <th>Дата</th>
            <th>Активный</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
            urlParams={urlParams}
            fields={[
              { type: 'text', name: 'id' },
              { type: 'text', name: 'name' },
              { type: 'date', name: 'date_from', secondLvl: { type: 'date', name: 'date_to' } },
              {
                  type: 'select',
                  name: 'is_active',
                  options: [
                      {
                          id: 0,
                          title: 'отключена',
                      },
                      {
                          id: 1,
                          title: 'активна',
                      },
                  ],
              },
              {},
            ]}
            onSubmit={pushUrlParams}
          />

        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={5} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                      <TableListViewRow
                          id={e.id}
                          key={`in_template-item-${e.id}`}
                          editLink={`/app/template/update/${e?.id}`}
                          remove={{
                              api: remove,
                              success: () => setRest(getSplicedData(i, rest)),
                              alert: 'Шаблон удален',
                          }}
                          permissionRemove='admin.template.destroy'
                          permissionEdit='admin.template.edit'
                      >
                          <td>
                              {checkPermission('admin.template.edit')
                                  ? <Link to={`/app/template/update/${e?.id}`}>{e.id}</Link>
                                  : <span>{e.id}</span>
                              }
                          </td>
                          <td>{e?.name}</td>
                          <td>{getTZtoDate(e.updated_at, true)}</td>
                          <td>{e?.is_active ? 'активен' : 'отключен'}</td>
                      </TableListViewRow>
                  ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={5} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>

      {!load && (
          <TableListViewFooter
              left={(
                  <>
                      {+rest?.total && (
                          <TableListViewPagination
                              length={+rest?.last_page}
                              current={+rest?.current_page}
                              total={+rest?.total}
                              perPage={+rest?.per_page}
                              loc={location}
                          />
                      )}
                  </>
              )}
              right={(
                  <TableListViewPerPage
                      urlParams={urlParams}
                      onSelect={(val, params) => pushUrlParams(params)}
                  />
              )}
              rightCn="col-auto"
          />
      )}
    </div>
  );
};

export default TemplateList;
