import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";
import {
  createBank, editBank, getBankEdit, removeBank,
} from "../../lib/api/banks";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (bankId) => Promise.allSettled([getDomains(), getCatTree(), bankId
&& getBankEdit(bankId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      categories: res[1]?.value?.data,
      fields: bankId && res[2]?.value?.data,
    })
  });

export const create = createBank;
export const edit = editBank;
export const remove = removeBank;

export const parseDataSend = (data, bankId) => {
  let d = { ...data };
  const isUpdate = typeof bankId !== 'undefined';

  d = parseAll(d, !isUpdate, [
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
