import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import {
    createIM, editIM, getIMEdit, removeIM,
} from "../../lib/api/InstallmentManagement";
import { getBanksAll } from "../../lib/api/banks";
import { getCatAll, getCatTree} from "../../lib/api/categories";
import { refreshToken } from "../../lib/crud/refreshToken";
import parseTree from "../../lib/helpers/parseTree";

export const getData = (imId) => Promise.allSettled([getDomains(), getCatTree(), getBanksAll(), imId
&& getIMEdit(imId), getCatAll()])
    .then(async (res) => {
        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if (findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        const parsedTree = parseTree(res[1]?.value?.data);

        return ({
            domains: res[0]?.value?.data,
            categories: parsedTree,
            banks: res[2]?.value?.data?.data,
            fields: imId && res[3]?.value?.data,
            categoryAll: res[4]?.value?.data,
        })
    });

export const create = createIM;
export const edit = editIM;
export const remove = removeIM;

export const parseDataSend = (data, imId) => {
    let d = {...data};
    const isUpdate = typeof imId !== 'undefined';

    if (!!d?.categories?.length) {
        d.categories = d.categories.split(',');
    } else {
        d.categories = [];
    }

    if (!!d?.products_turn_on?.length) {
        d.products_turn_on = d?.products_turn_on.split(',');
    } else {
        d.products_turn_on = [];
    }

    d.products_turn_on = d?.products_turn_on?.filter((item, pos) => {
        return d?.products_turn_on?.indexOf(item) === pos;
    });

    if (!!d?.products_turn_off?.length) {
        d.products_turn_off = d?.products_turn_off.split(',');
    } else {
        d.products_turn_off = [];
    }

    d.products_turn_off = d?.products_turn_off?.filter((item, pos) => {
        return d?.products_turn_off?.indexOf(item) === pos;
    });

    d = parseAll(d, !isUpdate, [
        'bonus_limit',
        'bonus_value',
        'dimension_type',
        'document_type',
        'is_active',
        'for_business',
        'catalog_type',
    ]);

    return d;
};

export const parseDataGet = (data) => {
    const d = {...data};

    return d;
};
