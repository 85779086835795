import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { getData, remove } from './api';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import { geDomainName } from "../../lib/const/domains";
import checkPermission from "../../lib/checkPermission";
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewPagination from '../../components/common/TableListViewNew/Pagination';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from '../../components/common/TableListViewNew/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import TopNavTabs from '../../components/ui/TopNavTabsNew/TopNavTabs';
import getImgUrl from '../../lib/helpers/getImgUrl';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import {getFiltersData} from "../BannerManagementHomeList/api";


const BannerManagementHeadList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    filtersData,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner head list`} />
      {!load && (
          <TopPagination
              tabs={(
                  <TopNavTabs tabs={[
                    {
                      title: 'Банер головна сторінка',
                      url: '/app/banner-management/home',
                      permissionName: 'admin.banner-main-page.index'
                    },
                    {
                      title: 'Банер меню',
                      url: '/app/banner-management/menu',
                      permissionName: 'admin.banner-menu.index'
                    },
                    {
                      title: 'Горизонтальний банер каталогу',
                      url: '/app/banner-management/catalog-horizontal',
                      permissionName: 'admin.banner-catalog-horizontal.index'
                    },
                    {
                      title: 'Банер над шапкою сайту',
                      url: '/app/banner-management/head',
                      permissionName: 'admin.banner-header.index',
                      isActive: true
                    },
                    {
                      title: 'Вертикальний банер каталогу',
                      url: '/app/banner-management/catalog',
                      permissionName: 'admin.banner-catalog.index'
                    }
                  ]}
                  />
              )}
              pagination={(
                  <>
                      {rest?.meta?.total && (
                          <>
                              <TableListViewPagination
                                  length={+rest?.meta?.last_page}
                                  current={+rest?.meta?.current_page}
                                  loc={location}
                                  navigate={navigate}
                              />
                              <TableListViewPerPage
                                  urlParams={urlParams}
                                  onSelect={(val, params) => pushUrlParams(params)}
                                  total={+rest?.meta?.total}
                              />
                          </>
                      )}
                  </>
              )}
          />
      )}
      <TableListViewTable>
        <TableHead
            arrayChildren={[
              'ID', 'Назва банера', 'Зображення УКР', 'Зображення РУС', 'Посилання УКР',
              'Посилання РУС', 'Позиція', 'Домен', 'Статус активності'
            ]}
            filterChildren={<TableListViewFiltersRow
                urlParams={urlParams}
                fields={[
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        type: 'select', name: 'domain_id', options: filtersData?.domains
                    },
                    {},
                    {}
                ]}
                onSubmit={pushUrlParams}
            />}
        />
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={10} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`banner-management-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Банер над шапкой сайта удален',
                    }}
                    editLink={`/app/banner-management/head/update/${e.id}`}
                    permissionRemove='admin.banner-header.destroy'
                    permissionEdit='admin.banner-header.edit'
                  >
                    <td>
                      {checkPermission('admin.banner-header.edit')
                          ? <Link to={`/app/banner-management/head/update/${e.id}`}>{e.id}</Link>
                          : <span>{e.id}</span>
                      }
                    </td>
                    <td>
                      <span>
                        {e?.name || '---'}
                      </span>
                    </td>
                    <td>{e?.cover_ua && <img src={e?.cover_ua?.original || e?.cover_ua?.conversions?.original} alt="" />}</td>
                    <td>{e?.cover_ru && <img src={e?.cover_ru?.original || e?.cover_ru?.conversions?.original} alt="" />}</td>
                    <td>
                      <span>
                        {e?.link_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.link_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.position || 0}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.domain?.title || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                      </span>
                    </td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={9} style={{ paddingTop: '22px' }}>Немає банерів над шапкою сайту</td>
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default BannerManagementHeadList;
