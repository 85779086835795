import fetchAx from "../fetchAx";
import {
    DELIVERIES_GET,
    DELIVERIES_PROVIDERS_GET,
    DELIVERY_EDIT,
    DELIVERY_GET_EDIT,
    DELIVERY_OFFICES_GET,
    DELIVERY_SETTLEMENTS_GET,
    DELIVERY_STREETS_GET, DELIVERY_TERMINALS_GET,
    DELIVERY_TIME_INTERVALS_GET,
    DELIVERY_TYPES_GET,
} from "../../const/api";
import { LIST_PER_PAGE } from "../../const/view";

export const getDeliveries = (params) => fetchAx({
    url: DELIVERIES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
  },
});

export const getDeliveriesProviders = () => fetchAx({
    url: DELIVERIES_PROVIDERS_GET,
    method: 'GET',
});

export const getDeliveryEdit = (id) => fetchAx({
    url: DELIVERY_GET_EDIT.join(id),
    method: 'GET',
});

export const editDelivery = (id, data) => fetchAx({
    url: DELIVERY_EDIT.join(id),
    method: 'PUT',
    data,
});

export const getDeliveriesSettlements = (params) => fetchAx({
    url: DELIVERY_SETTLEMENTS_GET,
    method: 'GET',
    params: {
        ...params,
        per_page: LIST_PER_PAGE.xxxl,
    },
});

export const getDeliveryTypes = (params) => fetchAx({
    url: DELIVERY_TYPES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getDeliveryTimeIntervals = (params) => fetchAx({
    url: DELIVERY_TIME_INTERVALS_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getDeliveryOffices = (params) => fetchAx({
    url: DELIVERY_OFFICES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getDeliveryTerminals = (params) => fetchAx({
    url: DELIVERY_TERMINALS_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getDeliveryStreets = (params) => fetchAx({
    url: DELIVERY_STREETS_GET,
    method: 'GET',
    params: {
        ...params,
    },
});
