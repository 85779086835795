import React, { useEffect, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import s from "./CheckboxTreeWrap.module.scss";
import cn from "classnames";
import icon_checked from "../../../assets/icons/icon_checked.svg";
import icon_uncheck from "../../../assets/icons/icon_uncheck.svg";
import icon_indeterminate from "../../../assets/icons/icon_indeterminate.svg";
import icon_arrow_right from "../../../assets/icons/icon_arrow-right.svg";
import s_Parent from "../../../containers/AccessoryRelationEditView/AccessoryRelation.module.scss";
import InputWrapper from "../InputWrapper";
import s_Input from "../InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../../containers/AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../assets/icons/icon_search.svg";

const CheckboxTreeWrap = ({
                              data,
                              setData,
                              setArrayTrigger,
                              dataAll,
                              nameParent,
                              all,
                              formH,
                              allCheck,
                              indexItem,
                              nameTrigger,
                              arrayTrigger,
                              openExpanded,
                              onClickCustom,
                              expandedBtn = false,
                              marginLeft = true,
                              filtered = false
}) => {
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const getExpandedNodes = (nodes) => {
        return nodes.reduce((acc, ite) => {
            if (ite?.children?.length) {
                acc.push(ite.value);
                acc.push(...getExpandedNodes(ite?.children));
            }
            return acc;
        }, []);
    };

    const expandedNodes = useMemo(() => {
        return getExpandedNodes(data);
    }, [data]);

    useEffect(() => {
        if(expandedNodes && (nameTrigger !== 'conditions') && (nameTrigger !== 'product_from_ids') && (nameTrigger !== 'product_to_ids') && (nameTrigger !== 'permissions')) {
            setExpanded(expandedNodes);
        }
    }, [expandedNodes]);

    useEffect(() => {
        if(openExpanded) {
            setExpanded(expandedNodes);
        }
    }, [openExpanded]);

    useEffect(() => {
        if(arrayTrigger && (arrayTrigger[indexItem]?.attribute === "product|category_ids") && (Array.isArray(arrayTrigger[indexItem]?.value))) {
            setChecked(arrayTrigger[indexItem].value);
        }
    }, [arrayTrigger]);

    useEffect(() => {
        if(dataAll && (nameParent === 'categories')) {
            const parseCategory = dataAll?.fields?.[nameParent]?.map(item => item.id);
            setChecked(parseCategory);
            const parseCategories = () => {
                return dataAll[nameParent].map(item => {
                    const parseSecondLevel = item?.children?.map(itemSecond => {
                        const parseThirdLevel = itemSecond?.children?.map(itemThird => itemThird.value);
                        if (parseThirdLevel) {
                            return [itemSecond.value, ...parseThirdLevel?.flat()];
                        }
                        return [itemSecond.value];
                    });
                    if (parseSecondLevel) {
                        return [item.value,...parseSecondLevel?.flat()];
                    }
                    return [item.value];
                });
            };
            setExpanded(parseCategories()?.flat());
        }
        if(dataAll && ((nameParent === 'products_turn_on') || (nameParent === 'products_turn_off'))) {
            const parseProduct = dataAll?.fields?.[nameParent]?.map(item => item.id);
            setChecked(parseProduct);
        }
        if(dataAll && ((nameTrigger === 'product_from_ids') || (nameTrigger === 'product_to_ids'))) {
            const parseProduct = dataAll?.fields?.data?.[nameTrigger]?.map(item => item);
            setChecked(parseProduct);
        }

        if(dataAll && (nameTrigger === 'permissions')) {
            const parsePermissions = dataAll?.fields?.[nameTrigger]?.map(item => item.id);
            setChecked(parsePermissions);
        }

        const parseName = nameTrigger?.split('product_')[1];
        if(!!dataAll?.fields?.data?.[`cat_${parseName}`]?.length) {
            if(dataAll?.fields?.data?.id) {
                setChecked(dataAll?.fields?.data?.[`cat_${parseName}`]);
            }
        }

        if(dataAll && (nameParent === 'cat_ids')) {
            const parseCategory = dataAll?.fields?.[nameParent]?.map(item => item);
            setChecked(parseCategory);
        }
    }, [dataAll?.fields]);

    useEffect(() => {
        if(nameParent === 'categories') {
            const parseCategories = () => {
                return data.map(item => {
                    const parseSecondLevel = item.children.map(itemSecond => {
                        const parseThirdLevel = itemSecond.children.map(itemThird => itemThird.value);
                        return [itemSecond.value, ...parseThirdLevel.flat()];
                    });
                    return [item.value,...parseSecondLevel.flat()];
                });
            };

            if(all?.length === parseCategories().flat().length) {

                setChecked(parseCategories().flat());
            }
        }


        if((nameParent === 'products_turn_on') || (nameParent === 'products_turn_off')) {
            const parseProductList = data.map(item => item.value);
            if(all?.length === parseProductList.length) {

                setChecked(parseProductList);
            }
        }

        if((all?.length === 0) && (allCheck === false)) {

            setChecked([]);
        }
    }, [allCheck]);

    const [filterText, setFilterText] = useState('');
    const [nodesFilter, setNodesFilter] = useState([]);
    useEffect(() => {
        setNodesFilter(data);
    }, [data]);

    const onFilterChange = (e) => {
        setFilterText(e.target.value);
    }

    const filterTree = () => {
        setNodesFilter(data.reduce(filterNodes, []));
    }

    const filterNodes = (filtered, node) => {
        const children = (node.children || []).reduce(filterNodes, []);

        if (filterText?.includes(',')) {
            filterText?.split(',')?.forEach(item => {
                if (node?.jde === item?.replace(' ', '')) {
                    filtered.push({ ...node, ...(children.length && { children }) });
                }
            })
        }

        if (node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 || children.length) {
            filtered.push({ ...node, ...(children.length && { children }) });
        }

        if (!!filterText?.length) {
            setExpanded(expandedNodes);
        } else {
            setExpanded([]);
        }

        return filtered;
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            filterTree();
        }
    }

    return (
        <>
            {filtered && (
                <div className={s_Parent.mb20}>
                    <InputWrapper label="Поиск" id="search-prod">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Поиск"
                            id="search-prod"
                            value={filterText}
                            onChange={onFilterChange}
                            onKeyDown={onEnterPress}
                        />
                        <div className={s_Cat.input_icon} onClick={filterTree}>
                            <img src={icon_search} alt="Icon search" />
                        </div>
                    </InputWrapper>
                </div>
            )}
            <div className={cn(s.wrap, {
                [s.expandedBtn]: expandedBtn,
                [s.wrap_all]: marginLeft
            })}>
                <CheckboxTree
                    nodes={nodesFilter}
                    checked={checked}
                    expanded={expanded}
                    onCheck={(checked, ...props) => {
                        if (!!!filterText.length) {
                            setChecked(checked);
                        } else {
                            setChecked(prev => {
                                let temp = [];
                                if (!!prev?.length) {
                                    temp = [...prev]
                                }
                                temp = temp?.map(item => item?.toString());

                                if(temp?.includes(props[0]?.value?.toString())) {
                                    const findIndex = temp?.findIndex(itemTemp => itemTemp?.toString() === props[0]?.value?.toString());

                                    if (findIndex !== -1) {
                                        temp.splice(findIndex, 1);
                                    }

                                } else {
                                    temp.push(props[0]?.value?.toString())
                                }

                                if (onClickCustom) {
                                    onClickCustom(props)
                                }

                                return [...temp];
                            })
                        }
                        if(setData) {
                            setData(checked);
                        }
                        if(setArrayTrigger) {
                            setArrayTrigger(checked);
                        }
                    }}
                    onExpand={(expanded) => setExpanded(expanded)}
                    icons={{
                        check: <img className={s.img} src={icon_checked} onClick={(e) => {
                            // const cat_id = e.nativeEvent.path[2].children[0].id.substr(-3, 3).replace('-', '');
                        }}/>,
                        uncheck: <img className={s.img} src={icon_uncheck} onClick={(e) => {
                            // const cat_id = e.nativeEvent.path[2].children[0].id.substr(-3, 3).replace('-', '');
                        }} />,
                        halfCheck: <img className={s.img} src={icon_indeterminate} />,
                        expandClose: <img className={`${s.img} ${s.img__left}`} src={icon_arrow_right} />,
                        expandOpen: <img className={`${s.img} ${s.img__right}`} src={icon_arrow_right} />,
                        // expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                        // collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                        // parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
                        // parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
                        // leaf: <div>leaf</div>
                    }}
                />

                {formH && <input type="hidden" name={`${nameParent}`} value={checked} ref={formH.register} />}
            </div>
        </>
    );
};

export default CheckboxTreeWrap;
