import React, { useState } from 'react';
import s from './CopyComponent.module.scss';
import icon_copy from '../../../assets/icons/icon_copy.svg';

const CopyComponent = ({ copyText, children, minHeight = 40 }) => {
    const [copyShow, setCopyShow] = useState(false);

    const handleCopyText = () => {
        let textField = document.createElement('textarea');
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        setCopyShow(true);
        textField.remove();
        setTimeout(() => {
            setCopyShow(false);
        }, 2000);
    };

    return (
        <div className={s.wrap} style={{ minHeight }} title={copyText}>
            {children && children}
            <div className={s.img} onClick={handleCopyText}>
                <img src={icon_copy} alt="Copy order image icon"/>
                {copyShow && <div className={s.copy}>Скопировано!</div>}
            </div>
        </div>
    );
};

export default CopyComponent;