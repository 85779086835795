import {removeAdmin, getAdmins, getAdminStatuses} from "../../lib/api/admins";
import { getRoles } from "../../lib/api/roles";

export const getFiltersData = () => Promise.allSettled([
    getRoles(), getAdminStatuses()
])
    .then((res) => ({
        roles: res[0]?.value?.data?.data,
        statuses: res[1]?.value?.data,
    }));

export const getData = getAdmins;
export const remove = removeAdmin;