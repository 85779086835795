import cn from 'classnames';
import React from 'react';

// import s from "./TableDynamicFieldsHead.module.scss";

const TableDynamicFieldsHead = ({ children, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className),
  };

  return (
    <thead {...attrsMerge}>
      {children}
    </thead>
  );
};

export default TableDynamicFieldsHead;
