import React, { useEffect, useState } from 'react';
import s from "../../review.module.scss";
import { getAudit } from "../../../../lib/api/audit";
import errorMessage from "../../../../lib/errorMessage";
import getTZtoDate from "../../../../lib/getTZtoDate";

const ReviewHistory = ({ id, data }) => {
    const [historyData, setHistoryData] = useState([]);

    const getStatusesName = (value) => {
        if(data?.statuses) {
            return data?.statuses.find((status) => status?.value?.toString() === value?.toString());
        }
    };

    useEffect(() => {
        if(id) {
            getAudit({auditable_type: 'social_reviews', auditable_id: id})
                .then((res) => setHistoryData(res?.data?.data))
                .catch(async (err) => await errorMessage(err, 'api/admin/audit GET'));
        }
    }, [id]);
    return (
        <div className={s.block}>
            <div className={s.block__header}>История редактирования</div>
            <div className={s.block__content}>
                <div className={s.message}>
                    {!!historyData?.length && historyData?.map((history, index) => {
                        let parseOldValues;
                        let parseNewValues;

                        if(history?.old_values) {
                            parseOldValues = Object.keys(history?.old_values);
                        }
                        if(history?.new_values) {
                            parseNewValues = Object.keys(history?.new_values);
                        }
                        return (
                            <div className={s.message__item} key={index}>
                                <div className={s.message__top}>
                                    <div className={s.block__date}>{getTZtoDate(history?.created_at, true)}</div>
                                </div>
                                <div className={s.message__block}>
                                    <div className={s.message__name}>
                                        <div>
                                            <strong>{history?.user?.name}</strong> (модератор)
                                        </div>
                                        {/*<div className={s.block__date}>14:28</div>*/}
                                    </div>
                                    <div className={s.history__item}>
                                        <div className={s.history__old}>
                                            <b className={s.history__title}>Старые данные</b>
                                            <div>{parseOldValues && parseOldValues?.map((oldValue, oldInd) => {
                                                let keyOld;
                                                let valueOld;

                                                if(oldValue === "reply") {
                                                    keyOld = "Отзыв модератора";
                                                }

                                                if(oldValue === "status") {
                                                    keyOld = "Статус";
                                                    valueOld = getStatusesName(history?.old_values[oldValue])?.title;
                                                }

                                                return (
                                                    <div key={oldInd}>
                                                        {keyOld ? keyOld : oldValue}:
                                                        <b dangerouslySetInnerHTML={{__html: valueOld ? valueOld : history?.old_values[oldValue]}} />
                                                    </div>
                                                )
                                            })}</div>
                                        </div>
                                        <div className={s.history__new}>
                                            <b className={s.history__title}>Новые данные</b>
                                            <div>{parseNewValues && parseNewValues?.map((newValue, newInd) => {
                                                let keyNew;
                                                let valueNew;

                                                if(newValue === "reply") {
                                                    keyNew = "Отзыв модератора";
                                                }

                                                if(newValue === "status") {
                                                    keyNew = "Статус";
                                                    valueNew = getStatusesName(history?.new_values[newValue])?.title;
                                                }

                                                return (
                                                    <div key={newInd}>
                                                        {keyNew ? keyNew : newValue}:
                                                            <b dangerouslySetInnerHTML={{__html: valueNew ? valueNew : history?.new_values[newValue]}} />
                                                    </div>
                                                )
                                            })}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default ReviewHistory;