import React, { useEffect, useState } from "react";
import cn from "classnames";
import s from "../../SpecOfferEdit.module.scss";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../../AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../../assets/icons/icon_search.svg";
import s_Table from "../../../OrderEditView/fakeData/orderTable.module.scss";

const BlockCheckbox = ({
                           addName = "",
                           sendName,
                           name,
                           formH,
                           listName,
                           data,
                           activeTab,
                           showActive,
                           title
}) => {
    const [productsChoose, setProductsChoose] = useState([]);
    const [filterProductsList, setFilterProductsList] = useState([]);
    const [searchProducts, setSearchProducts] = useState('');

    const handleFilterProducts = () => {
        if(!!searchProducts?.length) {
            const filterProducts = data[listName]
                .filter(item => item.title_ua.toLowerCase() === searchProducts.toLowerCase());
            setFilterProductsList(filterProducts);
        } else {
            setFilterProductsList(data[listName])
        }
    }

    const onEnterPressFilterProducts = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            handleFilterProducts();
        }
    }

    useEffect(() => {
        if(!!data?.fields?.[name]?.[addName]?.length) {
            setProductsChoose(data?.fields[name]?.[addName]);
        }

        if(!!data?.[listName]?.length) {
            setFilterProductsList(data[listName]);
        }
    }, [data])

    return (
        <>
            <div className={cn(s.cat__block, {
                [s.overflowHidden]: activeTab !== showActive
            })} style={{ width: "480px" }}>
                <div className={s.title}>{title}</div>
                <div>
                    <InputWrapper label="Пошук" id={`search-prod-${showActive}`}>
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Пошук"
                            id={`search-prod-${showActive}`}
                            value={searchProducts}
                            onChange={(e) => setSearchProducts(e.target.value)}
                            onKeyDown={onEnterPressFilterProducts}
                        />
                        <div className={s_Cat.input_icon} onClick={handleFilterProducts}>
                            <img src={icon_search} alt="Icon search" />
                        </div>
                    </InputWrapper>
                </div>
                <div className={s.cat__list}>
                    <ul>
                        {!!filterProductsList?.length && filterProductsList?.map((product, index) => {
                            const findChecked = productsChoose?.find(item => item.id === product.id);
                            return (
                                <li key={product.id}>
                                    <div className={s.checkbox_list__item} onClick={() => {
                                        setProductsChoose(prev => {
                                            if(!!prev?.length) {
                                                const temp = [...prev];
                                                const findDuplicate = temp.findIndex(item => item.id === product.id);
                                                if(findDuplicate !== -1) {
                                                    temp.splice(findDuplicate, 1);
                                                } else {
                                                    temp.push(product);
                                                }
                                                return temp;
                                            }
                                            return [product]
                                        })
                                    }}>
                                        <div className={s.checkbox__wrapper}>
                                            <input
                                                className={s.checkbox}
                                                type="checkbox"
                                                id={`product-${showActive}-${index}`}
                                                name={`${name}.${addName}[${index}]`}
                                                checked={findChecked ? findChecked.id === product.id : false}
                                            />
                                            <label
                                                htmlFor={`product-${showActive}-${index}`}
                                                className={`${s.label} ${s_Table.label}`}
                                            />
                                        </div>
                                        <span>{product.title || product.title_ua}</span>
                                    </div>
                                </li>
                            )
                        })}
                        {!!productsChoose?.length ? productsChoose.map((item, index) => (
                            <input
                                key={`choose-block-product-${index}`}
                                type="hidden"
                                name={`${sendName}[${index}]`}
                                value={item.id}
                                ref={formH.register}
                            />
                        )) : <input
                            type="hidden"
                            name={sendName}
                            value={[]}
                            ref={formH.register}
                        />}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default BlockCheckbox;