import { removeMedia, getMedia, getMediaTypes } from "../../lib/api/media";
import { getDomains } from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getMediaTypes(), getDomains()
])
    .then((res) => ({
        types: res[0]?.value?.data,
        domains: res[1]?.value?.data,
    }));

export const getData = getMedia;
export const remove = removeMedia;