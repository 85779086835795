import React from 'react';
import s from "../../OrderEditView/fakeData/orderStyle.module.scss";
import Item from "../Item";

const OrderCommentHistory = ({ formProps }) => {
    const dataFields = formProps.data && formProps.data.fields;

    const formatDate = (value) => {
        const date = new Date(value).toLocaleDateString();
        const dateTime = new Date(value).toLocaleTimeString().slice(0,-3);
        return `${date} ${dateTime}`;
    }

    return (
        <Item title={`История комментариев (${dataFields?.comments?.length ? dataFields?.comments?.length : '0'})`}>
            <ul className={s.prevOrder}>
                {dataFields?.comments?.map((comment) => (
                    <li className={s.comment__item} key={comment.id}>
                        <div className={s.comment__block}>
                            <div className={s.comment__author}>{comment.user.surname} {comment.user.name}</div>
                            <div className={s.comment__date}>{formatDate(comment.created_at)}</div>
                        </div>
                        <div className={s.comment__text}>
                            {comment.text}
                        </div>
                    </li>
                ))}
            </ul>
        </Item>
    );
};

export default OrderCommentHistory;