import {
    ADMINS_GET,
    ADMIN_CREATE,
    ADMIN_GET_EDIT,
    ADMIN_EDIT,
    ADMIN_DELETE,
    ADMIN_STATUSES_GET
} from '../../const/api'
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

export const getAdmins = (params) => fetchAx({
    url: ADMINS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const getAdminsAll = (params) => fetchAx({
    url: ADMINS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.xxxl,
        ...params,
    },
});

export const createAdmin = (data) => fetchAx({
    url: ADMIN_CREATE,
    method: 'POST',
    data
});

export const getAdminEdit = (id) => fetchAx({
    url: ADMIN_GET_EDIT.join(id),
    method: 'GET',
});

export const editAdmin = (id, data) => fetchAx({
    url: ADMIN_EDIT.join(id),
    method: 'PUT',
    data
});

export const removeAdmin = (id) => fetchAx({
    url: ADMIN_DELETE.join(id),
    method: 'DELETE'
});

export const getAdminStatuses = () => fetchAx({
    url: ADMIN_STATUSES_GET,
    method: 'GET'
});