import {Link, useLocation, useNavigate} from 'react-router-dom';
import React from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewPagination from '../../components/common/TableListView/Pagination';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewFooter from '../../components/common/TableListView/Footer';
import TableListViewPerPage from '../../components/common/TableListView/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData, remove } from './api';
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const HrDomainMaskList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - HR Domain Mask list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th>Дата создания</th>
            <th>Дата обновления</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={5} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`hr-domain-mask-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Hr Domain mask удалена',
                    }}
                    editLink={`/app/hr-domain-mask/update/${e.id}`}
                    permissionRemove='admin.hr-domain-mask.destroy'
                    permissionEdit='admin.hr-domain-mask.edit'
                    controlsOff
                    showDeleteButton
                  >
                    <td>
                      {checkPermission('admin.hr-domain-mask.edit')
                          ? <Link to={`/app/hr-domain-mask/update/${e.id}`}>{e.id}</Link>
                          : <span>{e.id}</span>
                      }
                    </td>
                    <td>{e.title}</td>
                    <td>{e?.created_at ? getTZtoDate(e.created_at) : '---'}</td>
                    <td>{e?.updated_at ? getTZtoDate(e.updated_at) : '---'}</td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={5} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>

      {!load && (
        <TableListViewFooter
          left={(
              <>
                {+rest?.total && (
                    <TableListViewPagination
                        length={+rest?.last_page}
                        current={+rest?.current_page}
                        total={+rest?.total}
                        perPage={+rest?.per_page}
                        loc={location}
                    />
                )}
              </>
          )}
          right={(
            <TableListViewPerPage
              urlParams={urlParams}
              onSelect={(val, params) => pushUrlParams(params)}
            />
          )}
          rightCn="col-auto"
        />
      )}
    </div>
  );
};

export default HrDomainMaskList;
