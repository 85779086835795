import React, { lazy, Suspense } from 'react';

/**
 * Lazy load wrapper for react components
 * (used for containers or large components)
 *
 * @param importFunc
 * @param fallback
 * @param fcBody
 * @returns {function(*)}
 */
const loadable = (importFunc, { fallback = null } = { fallback: null }, fcBody) => {
  const LazyComponent = lazy(importFunc);

  return (props) => {
    if (fcBody) fcBody();

    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default loadable;
