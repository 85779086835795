import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const CategoriesMenuEditView = () => {
  const { id: cmID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: cmID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/menu/categories/not-found',
      remove: `/app/menu/categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/menu/categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Меню категорий создано',
      edit: 'Меню категорий обновлено',
      remove: 'Меню категорий удалено',
    },
  });

  const domainIdWatch = formH.watch('domain_id');

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if(domainIdWatch && data) {
      const result = data.categories.find(item => item.domain_id === +domainIdWatch);
      setCategories(result.children);
    }
  }, [domainIdWatch, data]);

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Menu categories edit`} />
      <div className="box-wrap">
        {cmID
          ? (
            <Title>
              Меню категорий -
              <small>{data && data?.fields?.title_ru}</small>
            </Title>
          )
          : <Title>Новое меню категорий</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* visibility */}
          <Group>
            <ColLeft>
                <label className="form-label" htmlFor="so-domain">Тип*:</label>
            </ColLeft>
            <ColRight>
                 <select
                      name="view_type"
                      className='form-select'
                      id="cm-view_type"
                      ref={register}
                    >
                   <option value={1}>
                     Списком
                   </option>
                   <option value={2}>
                     Плиткой
                   </option>
                 </select>
            </ColRight>
          </Group>

          {/* position */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-position">Позиция*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="position"
                className={cn('form-input', {
                  'is-error': errors.position,
                })}
                type="number"
                id="bml-position"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.position && <p className="form-input-hint">{errors.position.message}</p>}
            </ColRight>
          </Group>

          {/* domain_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-domain">Домен*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="domain_id"
                      className={cn('form-select', {
                        'is-error': errors.domain_id,
                      })}
                      id="cm-domain"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                      <option value="">выберите домен</option>
                      {data && !!data.domains && data.domains.map((domain) => (
                        <option
                          key={domain.id}
                          value={domain.id}
                        >
                          {domain.title}
                        </option>
                      ))}
                    </select>

                    {errors.domain_id
                      && <p className="form-input-hint">{errors.domain_id.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

          {/* category_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bml-category_id">Выбрать категорию*:</label>
            </ColLeft>
            <ColRight>
              {data
                ? (
                  <>
                    <select
                      name="category_id"
                      className={cn('form-select', {
                        'is-error': errors.category_id,
                      })}
                      id="bml-category_id"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                    >
                      <option value="">выберите категорию</option>
                      {data && !!categories.length && categories?.map((cat) => (
                          <option
                              key={cat.id}
                              value={cat.id}
                              selected={data?.fields?.category_id === cat.id}
                          >
                            {cat.title}
                          </option>
                      ))}
                      {/*{data && !!categories.length && categories?.map((cat, n) => {*/}
                      {/*  const mapItem = cat?.children?.map((it, number) => {*/}
                      {/*    return <option value={it.id} key={`category-item-${number}`}>{it.title}</option>;*/}
                      {/*  });*/}
                      {/*  return <optgroup label={cat.title} key={`optgroup-condition-attribute-${n}`}>*/}
                      {/*    {mapItem}*/}
                      {/*  </optgroup>*/}
                      {/*})}*/}
                    </select>

                    {errors.category_id
                      && <p className="form-input-hint">{errors.category_id.message}</p>}
                  </>
                )
                : (
                  <div className="p-relative">
                    <div className="loading" />
                  </div>
                )}
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default CategoriesMenuEditView;
