import React, { useEffect, useState } from 'react';
import { getMedia, removeMedia, saveMedia } from "../../../lib/api/media";
import errorMessage from "../../../lib/errorMessage";
import Grapesjs from "grapesjs";
import { SEND_MEDIA } from "../../../const/api";
import plugin from "grapesjs-preset-webpage";
import pluginBasic from "grapesjs-blocks-basic";
import tablePlugin from "grapesjs-blocks-table";
import swiperSlideFiveComponent from "./CustomPlugin/swiperSlideFive/swiperSlideFiveComponent";
import accordionComponent from "./CustomPlugin/accordion/accordionComponent";
import accordionDiamondComponent from "./CustomPlugin/accordionDiamond/accordionDiamondComponent";
import buttonComponent from "./CustomPlugin/button/buttonComponent";
import h1Component from "./CustomPlugin/h1/h1Component";
import h2Component from "./CustomPlugin/h2/h2Component";
import h3Component from "./CustomPlugin/h3/h3Component";
import h4Component from "./CustomPlugin/h4/h4Component";
import h5Component from "./CustomPlugin/h5/h5Component";
import h6Component from "./CustomPlugin/h6/h6Component";
import listComponent from "./CustomPlugin/list/listComponent";
import listOlComponent from "./CustomPlugin/listOl/listOlComponent";
import linkDivComponent from "./CustomPlugin/linkDiv/linkDivComponent";
import colFullComponent from "./CustomPlugin/collFull/colFullComponent";
import colTwoComponent from "./CustomPlugin/collTwo/colTwoComponent";
import colThreeComponent from "./CustomPlugin/collThree/colThreeComponent";
import smallTextComponent from "./CustomPlugin/smallText/smallTextComponent";
import descTextComponent from "./CustomPlugin/descText/descTextComponent";
import titleTextComponent from "./CustomPlugin/titleText/titleTextComponent";
import colLeftImgComponent from "./CustomPlugin/collLeftImg/colLeftImgComponent";
import colRightImgComponent from "./CustomPlugin/collRightImg/colRightImgComponent";
import colCenterImgComponent from "./CustomPlugin/collCenterImg/colCenterImgComponent";
import collCenterTextImgBottomComponent from "./CustomPlugin/collCenterTextImgBottom/collCenterTextImgBottomComponent";
import collImgTopTextBottomComponent from "./CustomPlugin/collImgTopTextBottom/collImgTopTextBottomComponent";
import colFourComponent from "./CustomPlugin/collFour/colFourComponent";
import imageAbsoluteTitleComponent from "./CustomPlugin/imageAbsoluteTitle/imageAbsoluteTitleComponent";
import imageAbsoluteTitleLeftComponent from "./CustomPlugin/imageAbsoluteTitleLeft/imageAbsoluteTitleLeftComponent";
import imageAbsoluteTitleRightComponent from "./CustomPlugin/imageAbsoluteTitleRight/imageAbsoluteTitleRightComponent";
import colTitleFourComponent from "./CustomPlugin/colTitleFour/colTitleFourComponent";
import colTitleThreeComponent from "./CustomPlugin/colTitleThree/colTitleThreeComponent";
import imageFiveRightComponent from "./CustomPlugin/imageFiveRight/imageFiveRightComponent";
import imageFiveLeftComponent from "./CustomPlugin/imageFiveLeft/imageFiveLeftComponent";
import imageFiveLeftScaleComponent from "./CustomPlugin/imageFiveLeftScale/imageFiveLeftScaleComponent";
import imageFiveRightScaleComponent from "./CustomPlugin/imageFiveRightScale/imageFiveRightScaleComponent";
import swiperSlideShowThreeComponent from "./CustomPlugin/swiperSlideShowThree/swiperSlideShowThreeComponent";
import showOnDomainComponent from "./CustomPlugin/showOnDomain/showOnDomainComponent";
import fourBlockHoverComponent from "./CustomPlugin/collFourBlock/fourBlockHoverComponent";
import imageTwoComponent from "./CustomPlugin/imageTwo/imageTwoComponent";
import collTwoTextComponent from "./CustomPlugin/collTwoText/collTwoTextComponent";
import collTwoBlockHoverComponent from "./CustomPlugin/collTwoBlockHover/collTwoBlockHoverComponent";
import collCharacteristicComponent from "./CustomPlugin/collCharacteristic/collCharacteristicComponent";
import collFeaturesComponent from "./CustomPlugin/collFeatures/collFeaturesComponent";
import imageCenterTextComponent from "./CustomPlugin/imageCenterText/imageCenterTextComponent";
import { toast } from "react-toastify";
import Button from "../Button";
import Loader from "../Loader";
import { PubSub as ps } from "pubsub-js";
import { EDIT } from "../../../const/pubsub";
import "grapesjs/dist/css/grapes.min.css";
import BlockImages from "./Component/BlockImages";
import s from "./GrapesjsConstructor.module.scss";

const GrapesjsConstructor = ({
                                 grapesjsId = 'gjs',
                                 galleryId,
                                 galleryType,
                                 galleryImageType = 'images',
                                 initHtml,
                                 formH,
                                 data,
                                 nameFieldHtml,
                                 nameFieldCss,
                                 nameAssets,
                                 typeConstructor = 'article',
                                 duplicateBtn,
                                 blockImagesShow = true,
                                 indexTab
                             }) => {

    const [createNative, setCreateNative] = useState(false);

    const createNativeFunc = async () => {
        if(!!!galleryId && (galleryType === 'article')) {
            if (indexTab) {
                localStorage.setItem('tabIndexProductConstructor', indexTab);
            }
            await setCreateNative(true);
            localStorage.setItem('apply', 'on');
            ps.publish(EDIT.save);
        }
    }

    const [load, setLoad] = useState(false);

    const [editor, setEditor] = useState(null);
    const [assets, setAssets] = useState([]);

    const [dataHtml, setDataHtml] = useState(null);
    const [dataCss, setDataCss] = useState(null);

    const loadGrapesJs = async () => {
        const e = await Grapesjs.init({
            container: `#${grapesjsId}`,
            storageManager: {
                type: 'onChange',
            },
            assetManager: {
                assets: assets,
                upload: SEND_MEDIA,
                uploadName: 'files',
                multiUpload: false,
                embedAsBase64: false,
                uploadFile: function(e) {
                    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

                    if(!!files?.length) {
                        let formData = new FormData();

                        formData.append('file', files[0] ,files[0]?.name);
                        formData.append('domain_id', 1);
                        formData.append('collection_name', galleryImageType);
                        formData.append('entity_id', galleryId);
                        formData.append('entity_type', galleryType);

                        setLoad(true);

                        saveMedia(formData)
                            .then(res => {
                                const parseData = {
                                    id: res.data.media.id.toString(),
                                    src: res.data.media.conversions.original,
                                };
                                setAssets(prev => {
                                    if(!!prev?.length) {
                                        return [...prev, parseData]
                                    }
                                    return [parseData]
                                });
                                setLoad(false);
                            })
                            .catch(async (err) => await errorMessage(err, '/api/admin/media POST'))
                            .finally(() => setLoad(false));
                    }
                }
            },
            components: initHtml,
            width: "auto",
            domComponents: {},
            plugins: [
                plugin, pluginBasic, accordionComponent, buttonComponent, showOnDomainComponent,
                h1Component, h2Component, h3Component, h4Component, h5Component, h6Component, listComponent,
                listOlComponent, linkDivComponent,swiperSlideFiveComponent, accordionDiamondComponent,
                colFullComponent, colTwoComponent, colThreeComponent, smallTextComponent, descTextComponent, titleTextComponent,
                colLeftImgComponent, colRightImgComponent, colCenterImgComponent, colFourComponent, imageAbsoluteTitleComponent,
                colTitleFourComponent, colTitleThreeComponent, imageFiveRightComponent, imageFiveLeftComponent, swiperSlideShowThreeComponent,
                tablePlugin, imageAbsoluteTitleLeftComponent, imageAbsoluteTitleRightComponent, fourBlockHoverComponent,
                imageFiveLeftScaleComponent, imageFiveRightScaleComponent, collCenterTextImgBottomComponent, collImgTopTextBottomComponent,
                imageTwoComponent, collTwoTextComponent, collTwoBlockHoverComponent, collCharacteristicComponent, collFeaturesComponent,
                imageCenterTextComponent
            ],
            pluginsOpts: {
                [plugin]: { /* options */ },
                [pluginBasic]: { /* options */ },
                [tablePlugin]: { /* options */ },
                [swiperSlideFiveComponent]: { /* options */ },
                [accordionComponent]: { /* options */ },
                [accordionDiamondComponent]: { /* options */ },
                [buttonComponent]: { /* options */ },
                [h1Component]: { /* options */ },
                [h2Component]: { /* options */ },
                [h3Component]: { /* options */ },
                [h4Component]: { /* options */ },
                [h5Component]: { /* options */ },
                [h6Component]: { /* options */ },
                [listComponent]: { /* options */ },
                [listOlComponent]: { /* options */ },
                [linkDivComponent]: { /* options */ },
                [colFullComponent]: { /* options */ },
                [colTwoComponent]: { /* options */ },
                [colThreeComponent]: { /* options */ },
                [smallTextComponent]: { /* options */ },
                [descTextComponent]: { /* options */ },
                [titleTextComponent]: { /* options */ },
                [colLeftImgComponent]: { /* options */ },
                [colRightImgComponent]: { /* options */ },
                [colCenterImgComponent]: { /* options */ },
                [colFourComponent]: { /* options */ },
                [imageAbsoluteTitleComponent]: { /* options */ },
                [colTitleFourComponent]: { /* options */ },
                [colTitleThreeComponent]: { /* options */ },
                [imageFiveRightComponent]: { /* options */ },
                [imageFiveLeftComponent]: { /* options */ },
                [swiperSlideShowThreeComponent]: { /* options */ },
                [imageAbsoluteTitleLeftComponent]: { /* options */ },
                [imageAbsoluteTitleRightComponent]: { /* options */ },
                [fourBlockHoverComponent]: { /* options */ },
                [imageFiveLeftScaleComponent]: { /* options */ },
                [imageFiveRightScaleComponent]: { /* options */ },
                [collCenterTextImgBottomComponent]: { /* options */ },
                [collImgTopTextBottomComponent]: { /* options */ },
                [imageTwoComponent]: { /* options */ },
                [collTwoTextComponent]: { /* options */ },
                [collTwoBlockHoverComponent]: { /* options */ },
                [collCharacteristicComponent]: { /* options */ },
                [collFeaturesComponent]: { /* options */ },
                [imageCenterTextComponent]: { /* options */ }
            },
            canvas: {
                styles: [
                    'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css',
                    'https://unpkg.com/accordion-js@3.3.2/dist/accordion.min.css',
                    'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'
                ],
                scripts: [
                    'https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js',
                    'https://unpkg.com/accordion-js@3.3.2/dist/accordion.min.js'
                ]
            },
        });
        e.on('change:changesCount', ee => {
            // Change!
            const resultHtml = ee.getHtml();
            const resultCss = ee.getCss();

            setDataHtml(resultHtml);
            setDataCss(resultCss);
        });
        e.on('asset:remove', (asset) => {
            setLoad(true);

            removeMedia(asset.attributes.id).then(res => {
                toast(res?.data?.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
                .catch(async (err) => await errorMessage(err, '/api/admin/media DELETE'))
                .finally(() => setLoad(false));
        });
        e.StyleManager.addSector('sector-id-1', {
            name: 'Border',
            open: true,
            properties: [
                {
                    name: 'Border left',
                    property: 'border-left',
                    type: 'composite',
                    properties: [{
                        name: 'Width',
                        type: 'integer',
                        units: ['px', 'em', 'rem'],
                        property: 'border-left-width',
                    },{
                        name: 'Style',
                        type: 'select',
                        property: 'border-left-style',
                        options: [
                            {value: 'none'},
                            {value: 'solid'},
                            {value: 'dotted'},
                            {value: 'dashed'},
                            {value: 'double'},
                            {value: 'groove'},
                            {value: 'ridge'},
                            {value: 'inset'},
                            {value: 'outset'}
                        ]
                    }, {
                        name: 'Color',
                        type: 'color',
                        property: 'border-left-color',
                    }]
                },
                {
                    name: 'Border right',
                    property: 'border-right',
                    type: 'composite',
                    properties: [{
                        name: 'Width',
                        type: 'integer',
                        units: ['px', 'em', 'rem'],
                        property: 'border-right-width',
                    },{
                        name: 'Style',
                        type: 'select',
                        property: 'border-right-style',
                        options: [
                            {value: 'none'},
                            {value: 'solid'},
                            {value: 'dotted'},
                            {value: 'dashed'},
                            {value: 'double'},
                            {value: 'groove'},
                            {value: 'ridge'},
                            {value: 'inset'},
                            {value: 'outset'}
                        ]
                    }, {
                        name: 'Color',
                        type: 'color',
                        property: 'border-right-color',
                    }]
                },
                {
                    name: 'Border top',
                    property: 'border-top',
                    type: 'composite',
                    properties: [{
                        name: 'Width',
                        type: 'integer',
                        units: ['px', 'em', 'rem'],
                        property: 'border-top-width',
                    },{
                        name: 'Style',
                        type: 'select',
                        property: 'border-top-style',
                        options: [
                            {value: 'none'},
                            {value: 'solid'},
                            {value: 'dotted'},
                            {value: 'dashed'},
                            {value: 'double'},
                            {value: 'groove'},
                            {value: 'ridge'},
                            {value: 'inset'},
                            {value: 'outset'}
                        ]
                    }, {
                        name: 'Color',
                        type: 'color',
                        property: 'border-top-color',
                    }]
                },
                {
                    name: 'Border bottom',
                    property: 'border-bottom',
                    type: 'composite',
                    properties: [{
                        name: 'Width',
                        type: 'integer',
                        units: ['px', 'em', 'rem'],
                        property: 'border-bottom-width',
                    },{
                        name: 'Style',
                        type: 'select',
                        property: 'border-bottom-style',
                        options: [
                            {value: 'none'},
                            {value: 'solid'},
                            {value: 'dotted'},
                            {value: 'dashed'},
                            {value: 'double'},
                            {value: 'groove'},
                            {value: 'ridge'},
                            {value: 'inset'},
                            {value: 'outset'}
                        ]
                    }, {
                        name: 'Color',
                        type: 'color',
                        property: 'border-bottom-color',
                    }]
                }
            ],
        });

        const findStyle = initHtml?.split('<style>')[1]?.split('</style>')[0];

        if(findStyle) {
            e.setStyle(`img{max-width:100%; width: 100%;} td{border: 1px solid} ${findStyle}`);
            setDataCss(`img{max-width:100%;  width: 100%;} td{border: 1px solid} ${findStyle}`);
        }

        e.Css.setRule('.gjs-row', { display: 'flex', 'align-items': 'flex-start', });
        e.Css.setRule('.gjs-cell', { width: '100%', height: '100%', 'min-width': '100px', 'min-height': '100px' });

        setEditor(e)
    };

    useEffect(() => {
        if(!!assets.length && editor) {
            editor.config.assetManager.assets = assets;
            editor.AssetManager.clear();
            editor.AssetManager.add(assets);
            setEditor(editor);
        }
    }, [assets, editor]);

    useEffect(() => {
        if(data?.fields && data?.assets_data) {
            setAssets(data?.assets_data);
        }
    }, [data?.fields, data?.assets_data]);

    useEffect(() => {
        if(galleryId) {
            getMedia({
                'entity_type': galleryType,
                'entity_id': galleryId,
                page: 1,
                per_page: 1000,
            }).then(res => {
                const parseMedia = res?.data?.data?.map(item => ({
                    id: item.id.toString(),
                    src: item?.conversions?.original,
                }));
                setAssets(parseMedia);
            }).catch(async (err) => await errorMessage(err, '/api/admin/media GET'));
        }
    }, [galleryId]);

    useEffect(() => {
        loadGrapesJs();
    }, [initHtml]);

    useEffect(() => {
        if(initHtml) {
            setDataHtml(initHtml);
        }
    }, [initHtml]);

    useEffect(() => {
        const editBtn = document.getElementById("edit-btn")
        const saveBtn = document.getElementById("save-btn")
        if(dataHtml?.includes('https://via.placeholder.com') || dataHtml?.includes('data:image/svg+xml')) {
            if(editBtn) {
                editBtn.disabled = true;
                editBtn.title = `Завантажте фото, щоб зберегти ${nameFieldHtml}`;
            }

            if(saveBtn) {
                saveBtn.disabled = true;
                saveBtn.title = `Завантажте фото, щоб зберегти ${nameFieldHtml}`;
            }
        } else {
            if(editBtn) {
                editBtn.disabled = false;
                editBtn.title = '';
            }

            if(saveBtn) {
                saveBtn.disabled = false;
                saveBtn.title = '';
            }
        }
    }, [dataHtml]);

    return (
        <div>
            {(galleryId || (typeConstructor === 'article')) ? (
                <>
                    <input type="text" name={nameFieldHtml} value={dataHtml} ref={formH.register} style={{ display: 'none' }}/>
                    <input type="text" name={nameFieldCss} value={dataCss} ref={formH.register} style={{ display: 'none' }}/>
                    {!!assets?.length && (
                        <input type="text" name={nameAssets} value={JSON.stringify(assets)} ref={formH.register} style={{ display: 'none' }}/>
                    )}
                    {galleryType === 'article' && (
                        <input type="text" name={`article_id`} value={galleryId} ref={formH.register} style={{ display: 'none' }}/>
                    )}

                    <div style={{ borderRadius: '16px', overflow: 'hidden' }} id={grapesjsId} />

                    {blockImagesShow && (
                        <BlockImages formH={formH} galleryImageType={galleryImageType} galleryId={galleryId} galleryType={galleryType} />
                    )}
                </>
            ) : (
                <div className={s.group_create}>
                    <Button purple onClick={createNativeFunc}>Створити нативний контент</Button>
                    {duplicateBtn}
                </div>
            )}

            {createNative && (
                <>
                    <input type="text" name={`article.title_ru`} value={data?.fields?.title_ru ? data?.fields?.title_ru : data?.fields?.title_ua} ref={formH.register} style={{ display: 'none' }}/>
                    <input type="text" name={`article.title_ua`} value={data?.fields?.title_ua ? data?.fields?.title_ua : data?.fields?.title_ru} ref={formH.register} style={{ display: 'none' }}/>
                    <input type="text" name={`article.body_ua`} value={'<div></div>'} ref={formH.register} style={{ display: 'none' }}/>
                    <input type="text" name={`article.body_ru`} value={'<div></div>'} ref={formH.register} style={{ display: 'none' }}/>
                </>
            )}


            {load && <Loader />}
        </div>
    );
};

export default GrapesjsConstructor;