import { EASY_PRO_GET, EASY_PRO_SHOPS_GET } from '../../const/api'
import fetchAx from '../fetchAx';
import { LIST_PER_PAGE } from "../../const/view";

export const getEasyPro = (params) => fetchAx({
    url: EASY_PRO_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
})


export const getEasyProShops = (params) => fetchAx({
    url: EASY_PRO_SHOPS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.xxxl,
        ...params,
    },
})