import s_Modal from '../Modal/ModalAdminVerification.module.scss'
import Button from "../../../components/ui/Button";
import React from "react";
import {PubSub as ps} from "pubsub-js";
import {EDIT} from "../../../const/pubsub";

const ModalAdminVerification = ({active, setActive}) => {
    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__title}>Указанный внутренний  номер Бинотел занят, подтвердить передачу номера?</div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                        <div>
                            <Button purple onClick={() => ps.publish(EDIT.save)}>Заменить</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAdminVerification;