import React from 'react';
import cn from "classnames";
import s from "../SpecOfferEdit.module.scss";
import ProductBlock from "./block/ProductBlock";

const TypeSecond = ({
                     activeTab,
                     setActiveTab,
                     tabList,
                     domainWatch,
                     data,
                     formH
}) => {
    return (
        <div style={{ width: "100%" }}>
            <ul className={s.tab}>
                {!!tabList.length && tabList.map((el, index) => {
                    return (
                        <li className={cn(s.tab__item, {
                            ['active']: el.id === activeTab,
                            [s.tab__line]: el.id === activeTab,
                        })} key={index}>
                            <div onClick={() => setActiveTab(el.id)}>{el.title}</div>
                        </li>
                    )
                })}
            </ul>
            <div className={s.tabs__wrapper}>

                {/* TAB A */}
                <ProductBlock
                    name="special_offer_type2_products"
                    sendName="special_offer_type2_product"
                    title="Товар А"
                    domainWatch={domainWatch}
                    data={data}
                    formH={formH}
                    activeTab={activeTab}
                    showActive={0}
                />

                {/* TAB B */}
                <ProductBlock
                    name="type2_product_gift"
                    sendName="special_offer_type2_product_gift"
                    title="Товар Б"
                    domainWatch={domainWatch}
                    data={data}
                    formH={formH}
                    activeTab={activeTab}
                    showActive={1}
                    priceCart
                    priceOrder
                />
            </div>
        </div>
    );
};

export default TypeSecond;