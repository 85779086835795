import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import cn from 'classnames';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import {getMedia, removeMedia} from "../../lib/api/media";
import errorMessage from "../../lib/errorMessage";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "./BannerManagementCatalog.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import ImageLoad from "../../components/ui/ImageLoad";
import { ModalMedia } from "../BannerManagementHomeEditView/Modal";
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import ColLeft from "../../components/ui/FormEditView/ColLeft";
import ColRight from "../../components/ui/FormEditView/ColRight";

const BannerManagementCatalogEditView = () => {
  const { id: bmhID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: bmhID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/banner-management/catalog/not-found',
      remove: `/app/banner-management/catalog${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/banner-management/catalog${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Вертикальний банер каталогу створено',
      edit: 'Вертикальний банер каталогу оновлено',
      remove: 'Вертикальний банер каталогу видалено',
    },
  });

  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);
  const [typeCoverDataUa, setTypeCoverDataUa] = useState([]);
  const [typeMobileDataUa, setTypeMobileDataUa] = useState([]);

  const [typeCoverDataRu, setTypeCoverDataRu] = useState([]);
  const [typeMobileDataRu, setTypeMobileDataRu] = useState([]);

  const [dataModal, setDataModal] = useState({});

  const [categories, setCategories] = useState([]);

  const [date, setDate] = useState({
    starts_at: '',
    finish_at: '',
  });
  const [time, setTime] = useState({
    time_from: '00:00:00',
    time_to: '23:59:59'
  });

  useEffect(() => {
    if(data?.fields?.starts_at || data?.fields?.finish_at) {
      setDate({
        starts_at: data?.fields?.starts_at?.split(' ')[0],
        finish_at: data?.fields?.finish_at?.split(' ')[0],
      });

      setTime({
        time_from: data?.fields?.starts_at?.split(' ')[1],
        time_to: data?.fields?.finish_at?.split(' ')[1],
      });
    }
  }, [data]);

  useEffect(() => {
    if(data?.categories) {
     setCategories(data?.categories);
    }
  }, [data?.categories]);

  useEffect(() => {
    if(bmhID) {
      getMedia({
        'entity_type': 'promo_catalog',
        'entity_id': bmhID,
        page: 1,
        per_page: 500,
      }).then(res => {
        const filterLang = (lang) => res?.data?.data?.filter(item => item?.custom_properties?.locale === lang);
        const filterLangRu = filterLang('ru');
        const filterCoverRu = filterLangRu?.filter(item => item?.collection_name === 'cover');
        const filterMobileRu = filterLangRu?.filter(item => item?.collection_name === 'mobile');

        if(filterCoverRu) {
          setTypeCoverDataRu(filterCoverRu);
        }

        if(filterMobileRu) {
          setTypeMobileDataRu(filterMobileRu);
        }

        const filterLangUa = filterLang('ua');
        const filterCoverUa = filterLangUa?.filter(item => item?.collection_name === 'cover');
        const filterMobileUa = filterLangUa?.filter(item => item?.collection_name === 'mobile');

        if(filterCoverUa) {
          setTypeCoverDataUa(filterCoverUa);
        }

        if(filterMobileUa) {
          setTypeMobileDataUa(filterMobileUa);
        }

      }).catch(err => errorMessage(err, '/api/admin/media GET'))
    }

  }, [bmhID]);

  /* eslint-disable */
  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner catalog edit`} />
        <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

          <button type="submit" className="d-hide" />

          <TopPanelChild
              title={bmhID ? data && data?.fields?.title_ua : "Новий Горизонтальний банер каталогу"}
              breadcrumbs={{
                items: [
                  {
                    title: "Головна",
                    url: "/app",
                  },
                  {
                    title: "Горизонтальний банер каталогу",
                    url: `/app/banner-management/catalog${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: bmhID ? data && data?.fields?.title_ua: "Новий Горизонтальний банер каталогу"
                  },
                ],
              }}
              back={`/app/banner-management/catalog${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              id={bmhID}
              children={<div className={s.top__item}>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="b-is-active"
                    label="Активність"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </div>}
              btnEdit
          />

          {!load ? (
              <div className={s.top}>
                <div className={s.top__input}>
                  {/* domain_id */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          {data
                              ? (
                                  <select
                                      name="domain_id"
                                      className={cn(s_Select.select, {
                                        [s_Select.select_error]: formH.errors?.domain_id,
                                      })}
                                      id="b-domain"
                                      ref={formH.register({
                                        required: VALIDATION.select.required,
                                      })}
                                  >
                                    <option value="">виберіть домен</option>
                                    {data && !!data.domains && data.domains.map((domain) => (
                                        <option
                                            key={domain.id}
                                            value={domain.id}
                                            selected={domain.id === data?.fields?.domain_id}
                                        >
                                          {domain.title}
                                        </option>
                                    ))}
                                  </select>
                              )
                              : (
                                  <div className="p-relative">
                                    <div className="loading" />
                                  </div>
                              )}
                          <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                        </div>
                        {formH.errors?.domain_id
                        && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>

                  {/* category_id */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          {data
                              ? (
                                  <select
                                      name="category_id"
                                      className={cn(s_Select.select, {
                                        [s_Select.select_error]: formH.errors?.category_id,
                                      })}
                                      id="bml-category_id"
                                      ref={formH.register({
                                        required: VALIDATION.select.required,
                                      })}
                                  >
                                    <option value="">Оберіть категорію</option>
                                    {data && !!categories.length && categories.map((cat) => (
                                        <option
                                            key={cat.id}
                                            value={cat.id}
                                            selected={data?.fields?.category_id?.toString() === cat?.id?.toString()}
                                        >
                                          {cat?.title}
                                        </option>
                                    ))}
                                  </select>
                              )
                              : (
                                  <div className="p-relative">
                                    <div className="loading" />
                                  </div>
                              )}
                          <label className={s_Select.label} htmlFor="bml-category_id">Категорія</label>
                        </div>
                        {formH.errors?.category_id
                        && <p className="form-input-hint">{formH.errors.category_id?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>

                  {/* starts_at */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="starts_at"
                              id="bml-starts-at"
                              placeholder="Термін дії від"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.starts_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                starts_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-date_form">Термін дії від</label>
                        </div>
                        {errors?.starts_at
                        && <p className="form-input-hint">{errors?.starts_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_from"
                              id="bml-time-from"
                              placeholder="Час"
                              ref={register}
                              value={time.time_from}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_from: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_from">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* finish_at */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="finish_at"
                              id="bml-finish-at"
                              placeholder="Термін дії до"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.finish_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                finish_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-finish_at">Термін дії до</label>
                        </div>
                        {errors?.finish_at
                        && <p className="form-input-hint">{errors?.finish_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_to"
                              id="bml-time_to"
                              placeholder="Час"
                              ref={register}
                              value={time.time_to}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_to: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_to">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* page */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Сторінка"
                          id="bml-page"
                          errorComponent={errors?.page &&
                          <p className="form-input-hint">{errors?.page?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.page,
                            })}
                            type="number"
                            placeholder="Сторінка"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="page"
                            id="bml-page"
                            defaultValue={data?.fields?.page}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* position */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Позиція"
                          id="bml-position"
                          errorComponent={errors?.position &&
                          <p className="form-input-hint">{errors?.position?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.position,
                            })}
                            type="number"
                            placeholder="Позиція"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="position"
                            id="bml-position"
                            defaultValue={data?.fields?.position}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>
                </div>
                <div className={s.top__img_block}>

                  {/* title_ua */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва УКР"
                          id="bml-name-ua"
                          errorComponent={errors?.title_ua &&
                          <p className="form-input-hint">{errors?.title_ua?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.title_ua,
                            })}
                            type="text"
                            placeholder="Назва УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="title_ua"
                            id="bml-name-ua"
                            defaultValue={data?.fields?.title_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* link_ua */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання УКР"
                          id="bml-link-ua"
                          errorComponent={errors?.link_ua &&
                          <p className="form-input-hint">{errors?.link_ua?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ua,
                            })}
                            type="text"
                            placeholder="Посилання УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ua"
                            id="bml-link-ua"
                            defaultValue={data?.fields?.link_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block}>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ua_mobile`}
                            nameParent={`product_images_ua_mobile[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataUa}
                            setImageData={setTypeMobileDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_catalog'
                            collectionName='mobile'
                            windowName='mobile'
                            width={'80%'}
                            height={380}
                            classNameMedia={`${s.media} ${s.media__small}`}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x390 px<br />
                          (720x780 px)
                        </div>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ImageLoad
                            id={`product_img_ua_cover`}
                            nameParent={`product_images_ua_cover[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataUa}
                            setImageData={setTypeCoverDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_catalog'
                            collectionName='cover'
                            windowName='desktop'
                            width={'100%'}
                            height={540}
                            classNameMedia={`${s.media} ${s.media__big}`}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          305x550 px<br />
                          (610x1100 px)
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={s.top__img_block}>
                  {/* title_ru */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва РУС"
                          id="bml-name-ru"
                          errorComponent={errors?.title_ru &&
                          <p className="form-input-hint">{errors?.title_ru?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.title_ru,
                            })}
                            type="text"
                            placeholder="Назва РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="title_ru"
                            id="bml-name-ru"
                            defaultValue={data?.fields?.title_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* link_ru */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання РУС"
                          id="bml-link-ru"
                          errorComponent={errors?.link_ru &&
                          <p className="form-input-hint">{errors?.link_ru?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ru,
                            })}
                            type="text"
                            placeholder="Посилання РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ru"
                            id="bml-link-ru"
                            defaultValue={data?.fields?.link_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block}>
                      <div style={{ width: '100%', marginBottom: 16 }}>
                        <ImageLoad
                            id={`product_img_ru_mobile`}
                            nameParent={`product_images_ru_mobile[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataRu}
                            setImageData={setTypeMobileDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_catalog'
                            collectionName='mobile'
                            windowName='mobile'
                            width={'80%'}
                            height={380}
                            classNameMedia={`${s.media} ${s.media__small}`}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x390 px<br />
                          (720x780 px)
                        </div>
                      </div>
                      <div style={{ width: '100%' }}>
                        <ImageLoad
                            id={`product_img_ru_cover`}
                            nameParent={`product_images_ru_cover[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataRu}
                            setImageData={setTypeCoverDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_catalog'
                            collectionName='cover'
                            windowName='desktop'
                            width={'100%'}
                            height={540}
                            classNameMedia={`${s.media} ${s.media__big}`}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          305x550 px<br />
                          (610x1100 px)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : <Loader />}
        </Form>

        {activeMedia && <ModalMedia
            active={activeMedia}
            setActive={setActiveMedia}
            data={dataModal}
            collectionName={dataModal?.collection_name || 'cover'}
            locale={dataModal?.custom_properties?.locale === 'ru' ? 'ru' : 'ua'}
            setImageData={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataRu
                    : dataModal?.collection_name === 'mobile' && setTypeMobileDataRu
                : dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataUa
                    : dataModal?.collection_name === 'mobile' && setTypeMobileDataUa
            }
            title='Редагування картинки'
        />}
        {activeRemove && <ModalRemove
            active={activeRemove}
            setActive={setActiveRemove}
            data={dataModal}
            funcRemove={removeMedia}
            setRemoveItem={dataModal?.custom_properties?.locale === 'ru'
                ? dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataRu
                    : dataModal?.collection_name === 'mobile' && setTypeMobileDataRu
                : dataModal?.collection_name === 'cover'
                    ? setTypeCoverDataUa
                    : dataModal?.collection_name === 'mobile' && setTypeMobileDataUa
            }
            title='Видалити банер меню'
        />}
      </>
  )
};

export default BannerManagementCatalogEditView;
