import { parseAll } from '../../lib/api/_parseApi';
import {
  createConsultationsManager, editConsultationsManager, getConsultationsManagerEdit, removeConsultationsManager,
} from '../../lib/api/specialists';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (manId) => Promise.allSettled([manId && getConsultationsManagerEdit(manId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: manId && res[0]?.value?.data,
    })
  });

export const create = createConsultationsManager;
export const edit = editConsultationsManager;
export const remove = removeConsultationsManager;

export const parseDataSend = (data, soId) => {
  let d = { ...data };
  const isUpdate = typeof soId !== 'undefined';

  if(d.cover === "") {
    d.cover = null;
  }

  d = parseAll(d, !isUpdate, ['is_active']);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
