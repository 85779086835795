import { getBannerHead, removeBannerHead } from '../../lib/api/bannerHead';
import {getDomains} from "../../lib/api/domains";

export const getFiltersData = () => Promise.allSettled([
    getDomains()
])
    .then((res) => ({
        domains: res[0]?.value?.data
    }));

export const getData = getBannerHead;
export const remove = removeBannerHead;
