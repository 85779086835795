import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import Button from "../../../components/ui/Button";
import s from "./topPanel.module.scss";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import icon_add from "../../../assets/icons/icon_add.svg";

const TopPanel = ({ breadcrumbs, orderID, setOpenAdd }) => {
    return (
        <div>
            <div className={s.breadcrumbs__wrapper}>
                <div>
                    <Breadcrumbs {...breadcrumbs}/>
                    <div className={s.title}>
                        <div>Заказ #{orderID}</div>
                    </div>
                </div>
            </div>
            <div className={s.footer}>
                <div>
                    <Link to={`/app/order/update/${orderID}`}>
                        <img src={icon_arrow_back} alt="Arrow back icon"/>
                        Назад
                    </Link>
                </div>
                <div className={s.buttons__wrapper}>
                    <div className={s.buttons__group}>
                        <div className={s.button__block}>
                            <Button purple onClick={() => setOpenAdd(true)}>
                                <img src={icon_add} alt="Add document"/>
                                Добавить
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopPanel;