import fetchAx from "../fetchAx";
import {
    RESET_PASSWORD,
    POST_NEW_PASSWORD,
    LOGIN_USER,
    LOGOUT_USER,
    OAUTH_TOKEN_GET, ADMIN_PERMISSIONS_GET
} from "../../const/api";


// post email to reset user password
export const postResetEmail = (data) => fetchAx({
    url: RESET_PASSWORD,
    method: 'POST',
    data
})

export const postNewPassword = (data) => fetchAx({
    url: POST_NEW_PASSWORD,
    method: 'POST',
    data
})

export const loginUser = (data) => fetchAx({
    url: LOGIN_USER,
    method: 'POST',
    data
})

export const logoutUser  = (data) => fetchAx({
    url: LOGOUT_USER,
    method: 'POST',
    data
})

export const oauthTokenUser  = (data) => fetchAx({
    url: OAUTH_TOKEN_GET,
    method: 'POST',
    data
})

export const oauthTokenRefreshUser  = (data) => fetchAx({
    url: OAUTH_TOKEN_GET,
    method: 'POST',
    data
})

export const adminPermissions  = (token) => fetchAx({
    url: ADMIN_PERMISSIONS_GET,
    method: 'GET',
    headers: {
        Authorization: token
    }
})



