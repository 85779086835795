import React, { useEffect, useState } from 'react';
import CopyComponent from "../../../../components/ui/CopyComponent";
import s from "../../review.module.scss";
import icon_star_full from "../../../../assets/icons/icon_star_full.svg";
import icon_star from "../../../../assets/icons/icon_star.svg";
import getTZtoDate from "../../../../lib/getTZtoDate";
import { Link } from "react-router-dom";

const ReviewUser = ({ data, bodySend, setBodySend }) => {
    const [ratingStars, setRatingStars] = useState([false, false, false, false, false]);

    useEffect(() => {
        if(data?.fields?.data?.rating) {

            setRatingStars(prev => {
                const temp = [...prev];

                for (let i = 0; i < +data?.fields?.data?.rating; i++) {
                    temp[i] = true;
                }

                return temp;
            })
        }

    }, [data]);

    return (
        <div className={s.block}>
            <div className={s.block__header}>Отзыв пользователя</div>
            <div className={s.block__content}>
                <div className={s.review_client}>
                    <div className={s.review_client__item}>
                        <div className={s.review_client__block}>
                            <div className={s.review_client__title}>{data?.fields?.data?.product?.title}</div>
                            <div className={s.flex}>
                                <div className={s.review_client__jde}>JDE: {data?.fields?.data?.product?.jde}</div>
                                <div className={s.review_client__copy}>
                                    <CopyComponent copyText={data?.fields?.data?.product?.jde} />
                                </div>
                            </div>
                        </div>
                        <div className={s.review_client__block}>
                            <div className={s.flex}>
                                <div className={s.review_client__star}>
                                    {ratingStars?.map((star, index) => {
                                        if(star) {
                                            return <img key={index} src={icon_star_full} alt="Icon star full" />;
                                        } else {
                                            return <img key={index} src={icon_star} alt="Icon star" />;
                                        }
                                    })}
                                </div>
                                <div className={s.review_client__star_count}>({data?.fields?.data?.rating})</div>
                            </div>
                            <div className={s.block__date}>{getTZtoDate(data?.fields?.data?.created_at, true)}</div>
                        </div>
                        <div className={s.review_client__block}>
                            <div className={s.review_client__text}>
                                <textarea
                                    value={bodySend}
                                    onChange={(e) => setBodySend(e.target.value)}
                                    rows={5}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.review_client__footer}>
                        <div className={s.review_client__name}>{data?.fields?.data?.user?.name}</div>
                        <div className={s.review_client__profile}>
                            <Link to={`/app/user/client/update/${data?.fields?.data?.user?.id}`} target="_blank">
                                Смотреть профиль
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewUser;