import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';

import {
  createCurtRule,
  editCurtRule,
  getCurtRuleEdit,
  removeCurtRule,
  getCurtRulesActionTypes,
  getCurtRulesConditions, getCurtRulesCoupons,
} from '../../lib/api/cart_rules';
import { getCatTree } from '../../lib/api/categories';
import { getMarkersAll } from '../../lib/api/markers';
import { getAdditionalServicesAll } from '../../lib/api/additional_services';
import { getSpecOffersType } from '../../lib/api/specOffers';
import { convertTZ } from "../../lib/getTZtoDate";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pcSeriesID) => Promise.allSettled([
  getDomains(), getMarkersAll(), getCatTree(), getAdditionalServicesAll(),
  getCurtRulesActionTypes(), getCurtRulesConditions(), getSpecOffersType(), pcSeriesID && getCurtRulesCoupons(pcSeriesID),
  pcSeriesID && getCurtRuleEdit(pcSeriesID)])
    .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
        return await refreshToken(() => window.location.reload())
      }

      const parseActionTypes = Object?.entries(res[4]?.value?.data)?.map(item => item[1]);

      const parseCategoriesAction = [];

      const parseCategoriesActionNodes = (nodes) => {

        if (!!nodes.children?.length) {
          nodes.children.map((node) => parseCategoriesActionNodes(node))
        }

        return parseCategoriesAction.push({
          value: nodes.id,
          label: nodes.title
        });
      }

      res[2]?.value?.data?.forEach(item => {
        parseCategoriesActionNodes(item);
      });

      const parseCategoriesNodes = (nodes) => {

        if (!!nodes.children?.length) {
          return {
            value: nodes.id,
            label: nodes.title,
            children: nodes.children.map((node) => parseCategoriesNodes(node))
          }
        }

        return {
          value: nodes.id,
          label: nodes.title,
          children: null
        }
      }

      const parseCategories = res[2]?.value?.data?.[0]?.children?.map(item => {
        return parseCategoriesNodes(item);
      });

      return ({
        domains: res[0]?.value?.data,
        markers: res[1]?.value?.data,
        categories: parseCategories,
        additional_services: res[3]?.value?.data,
        action_types: parseActionTypes,
        conditions: res[5]?.value?.data,
        specialOffers: res[6]?.value?.data?.data,
        coupons: pcSeriesID && res[7]?.value?.data?.data,
        fields: pcSeriesID && res[8]?.value?.data,
        categories_action: parseCategoriesAction
      });
    });

export const create = createCurtRule;
export const edit = editCurtRule;
export const remove = removeCurtRule;
export const getPromo = getCurtRulesCoupons;

export const parseDataSend = (data, pcSeriesID) => {
  let d = { ...data };
  const isUpdate = typeof pcSeriesID !== 'undefined';

  d['starts_at'] = `${d.date_starts_at} ${d.time_starts_at}`;
  d['ends_at'] = `${d.date_ends_at} ${d.time_ends_at}`;

  d['has_simple_discount'] = !d?.has_simple_discount

  d.coupon_type = 2

  d = parseAll(d, !isUpdate, [
    'promo_code_list',
    'special_offer_id',
    'uses_per_coupon',
    'has_many_coupons',
    'discount_quantity',
    'discount_amount',
    'conditions_type',
    'coupon_type'
  ]);

  const getTZtoDate = (tz) => {
    const dt = convertTZ(tz);

    const date = `${
        dt.getFullYear().toString().padStart(4, '0')}-${
        (dt.getMonth() + 1).toString().padStart(2, '0')}-${
        dt.getDate().toString().padStart(2, '0')}`;
    const time = `${
        dt.getHours().toString().padStart(2, '0')}:${
        dt.getMinutes().toString().padStart(2, '0')}`;

    return `${date } ${ time}`;
  };

  d.starts_at = getTZtoDate(d.starts_at);
  d.ends_at = getTZtoDate(d.ends_at);

  const parseConditions = d?.conditions?.map(item => item);

  if(parseConditions) {
    d.conditions = parseConditions;
  }

  const parseDiscountConditions = d?.discount_conditions?.map(item => {
    if(item.attribute === "product|category_ids") {
      return ({
        ...item,
        attribute: item.attribute?.replace("s", ""),
        value: item.value
      });
    } else {
      return ({
        ...item,
        value: item.value
      });
    }

  });

  if(parseDiscountConditions) {
    d.discount_conditions = parseDiscountConditions;
  } else {
    d.discount_conditions = []
  }

  if (typeof d?.domains === "string") {
    d.domains = d?.domains?.split('')
  } else {
    const parseDomains = d?.domains?.map(item => +item);

    if(!!parseDomains?.length) {
      d.domains = parseDomains;
    }
  }

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  d['has_simple_discount'] = !d?.has_simple_discount

  if (!!d?.discount_conditions?.length) {
    const parseDiscountConditions = d?.discount_conditions?.map(item => {
      if(item.attribute === "product|category_id") {
        return ({
          ...item,
          attribute: item.attribute + "s"
        });
      } else {
        return item;
      }
    });

    d.discount_conditions = parseDiscountConditions
  }
  return d;
};
