import {
  SERVICE_GET,
  SERVICE_CREATE,
  SERVICE_DELETE,
  SERVICE_GET_EDIT,
  SERVICE_EDIT,
  SERVICE_GET_ALL,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getServices = (params) => fetchAx({
  url: SERVICE_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getServicesAll = (params) => fetchAx({
  url: SERVICE_GET_ALL,
  method: 'GET',
  params: {
    ...params,
  },
});

export const createService = (data) => fetchAx({
  url: SERVICE_CREATE,
  method: 'POST',
  data,
});

export const getServiceEdit = (id) => fetchAx({
  url: SERVICE_GET_EDIT.join(id),
  method: 'GET',
});

export const editService = (id, data) => fetchAx({
  url: SERVICE_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeService = (id) => fetchAx({
  url: SERVICE_DELETE.join(id),
  method: 'DELETE',
});
