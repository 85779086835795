export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
        .swiper-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
</style>
    `;

    bm.add(opts.name, {
        label: `
            <i class="fa fa-arrows-h"></i>
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div style="max-width: 100%;">
                <div class="swiper-container mySwiperShowThree">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                          <div>
                            <img src="https://via.placeholder.com/300" alt="Slider alt 1" />
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div>
                            <img src="https://via.placeholder.com/300" alt="Slider alt 2" />
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div>
                            <img src="https://via.placeholder.com/300" alt="Slider alt 3" />
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div>
                            <img src="https://via.placeholder.com/300" alt="Slider alt 4" />
                          </div>
                      </div>
                      <div class="swiper-slide">
                          <div>
                            <img src="https://via.placeholder.com/300" alt="Slider alt 5" />
                          </div>
                      </div>
                    </div>
                    
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            ${style}
        `,
    })
};