import {
  POPULAR_CATEGORY_GET,
  POPULAR_CATEGORY_CREATE,
  POPULAR_CATEGORY_DELETE,
  POPULAR_CATEGORY_GET_EDIT,
  POPULAR_CATEGORY_EDIT,
  POPULAR_CATEGORY_GET_ALL,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getPopularCategories = (params) => fetchAx({
  url: POPULAR_CATEGORY_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getPopularCategoryAll = (params) => fetchAx({
  url: POPULAR_CATEGORY_GET_ALL,
  method: 'GET',
  params: {
    ...params,
  },
});

export const createPopularCategory = (data) => fetchAx({
  url: POPULAR_CATEGORY_CREATE,
  method: 'POST',
  data,
});

export const getPopularCategoryEdit = (id) => fetchAx({
  url: POPULAR_CATEGORY_GET_EDIT.join(id),
  method: 'GET',
});

export const editPopularCategory = (id, data) => fetchAx({
  url: POPULAR_CATEGORY_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removePopularCategory = (id) => fetchAx({
  url: POPULAR_CATEGORY_DELETE.join(id),
  method: 'DELETE',
});
