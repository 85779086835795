import React from 'react';
import { useForm } from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import InputFetchResult from '../../components/ui/InputFetchResultNew';
import { getCat } from '../../lib/api/categories';
import TopPanelNew from "../../components/common/TopPanelNew";
import List from "./component/List/List";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../const/view";
import s from "./PopularCategory.module.scss";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const PopularCategoryEditView = () => {
  const { id: popularCategoryID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const navigate = useNavigate();

  const { load, data, submitHandler } = useEdit({
    targetID: popularCategoryID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/main/popular-category/not-found',
      remove: `/app/main/popular-categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/main/popular-categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Популярная категория создана',
      edit: 'Популярная категория обновлена',
      remove: 'Популярная категория удалена',
    },
  });
  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Popular categories edit`} />
      <TopPanelNew
          title={popularCategoryID ? `Популярная категория - ${data && data?.fields?.category?.title_ru}` : "Новая популярная категория"}
          breadcrumbs={{
            items: [
              {
                title: "Dashboard",
                url: "/app",
              },
              {
                title: "Популярный товар",
                url: `/app/main/popular-categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              },
              {
                title: popularCategoryID ? `Популярная категория - ${data && data?.fields?.category?.title_ru}` : "Новая популярная категория",
              },
            ],
          }}
          back={`/app/main/popular-categories${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          btnEdit
      />

      <Form attrs={{
        onSubmit: handleSubmit(submitHandler),
      }}
      >
        <button type="submit" className="d-hide" />

        <List children={(
            <div className={s.top}>
              {/* domain_id */}
              <div>
                <div className={s_Select.select__inner}>
                  <div className={s_Select.select__wrapper}>
                    {data
                        ? (
                            <select
                                name="domain_id"
                                className={cn(s_Select.select, {
                                  [s_Select.select_error]: formH.errors?.domain_id,
                                })}
                                id="c-domain"
                                ref={formH.register({
                                  required: VALIDATION.select.required,
                                })}
                            >
                              <option value="">выберите домен</option>
                              {data && !!data.domains && data.domains.map((domain) => (
                                  <option
                                      key={domain.id}
                                      value={domain.id}
                                      selected={domain.id === data?.fields?.domain_id}
                                  >
                                    {domain.title}
                                  </option>
                              ))}
                            </select>
                        )
                        : (
                            <div className="p-relative">
                              <div className="loading" />
                            </div>
                        )}
                    <label className={s_Select.label} htmlFor="c-domain">Домен*</label>
                  </div>
                  {formH.errors?.domain_id
                  && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                </div>
              </div>

              {/* position */}
              <div>
                <InputWrapper
                    label="Позиция*"
                    id="c-position"
                    errorComponent={formH.errors?.position &&
                    <p className="form-input-hint">{formH?.errors?.position?.message}</p>}
                >
                  <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH.errors?.position,
                      })}
                      type="text"
                      placeholder="Позиция*"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={formH.register({
                        required: VALIDATION.req.required
                      })}
                      name="position"
                      id="c-position"
                      defaultValue={data?.fields?.position}
                  />
                </InputWrapper>
              </div>

              {/* category_id */}
              <div className={s.top__category}>
                <InputFetchResult
                    name="category_id"
                    form={formH}
                    searchParam="title_ru"
                    func={getCat}
                    defVal={data?.fields?.category}
                    id="c-category-id"
                    placeholder="Категория*"
                    required
                />
              </div>
            </div>
        )} />

      </Form>

      {load && <Loader />}
    </>
  );
};

export default PopularCategoryEditView;
