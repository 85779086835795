import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { VALIDATION } from '../../../const/text';
import Group from '../../../components/ui/FormEditView/Group';
import ColLeft from '../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../components/ui/FormEditView/ColRight';
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputDate from "../../../components/ui/InputDate";

const TabInfo = ({ index = 0, formProps }) => {
    const form = useForm();
    const dataFields = formProps.data && formProps.data.fields;
    // add to tabs form
    useEffect(() => {
        formProps.tabsForm.addForm(form, index);
    }, []);

    // set data form fields
    useEffect(() => {
        if (!dataFields) return;

        form.reset(dataFields);
    }, [dataFields]);

    function submitHandler(e) {
        formProps.tabsForm.validate(e, formProps.submitHandler);
    }

    /* eslint-disable */
    return (
        <form
            style={formProps.tabsForm.getDStyle(index)}
            onSubmit={submitHandler}
        >
            <button type="submit" className="d-hide" />

            <div className="box-wrap box-wrap--mb">
                <Form Tag="div">
                    {/* status */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-status">Статус:</label>
                        </ColLeft>
                        <ColRight>
                            {formProps?.data && (
                                <>
                                    <select
                                        name="status"
                                        className="form-select"
                                        id="art-status"
                                        ref={form.register}
                                    >
                                        <option value="">выберите статус</option>
                                        {!!formProps?.data?.status?.length && formProps?.data?.status.map((status, index) => (
                                            <option
                                                key={index}
                                                value={status.value}
                                                selected={status.value === formProps?.data?.fields?.status}
                                            >
                                                {status.title}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}
                        </ColRight>
                    </Group>

                    {/* type */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-status">Тип*:</label>
                        </ColLeft>
                        <ColRight>
                            {formProps?.data && (
                                <>
                                    <select
                                        name="type"
                                        className={cn("form-select", {
                                            "is-error": form?.errors.type,
                                        })}
                                        id="art-type"
                                        ref={form.register({
                                            required: VALIDATION.select.required,
                                        })}
                                    >
                                        <option value="">выберите тип</option>
                                        {!!formProps?.data?.types?.length && formProps?.data?.types.map((type, index) => (
                                            <option
                                                key={index}
                                                value={type.value}
                                                selected={type.value === formProps?.data?.fields?.type}
                                            >
                                                {type.title}
                                            </option>
                                        ))}
                                    </select>
                                    {form?.errors?.type
                                    && <p className="form-input-hint">{form.errors.type.message}</p>}
                                </>
                            )}
                        </ColRight>
                    </Group>

                    {/* title_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-title-ru">Название РУ*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                className={cn('form-input', {
                                    'is-error': form.errors.title_ru,
                                })}
                                name="title_ru"
                                type="text"
                                id="art-title-ru"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {form.errors.title_ru
                            && <p className="form-input-hint">{form.errors.title_ru.message}</p>}
                        </ColRight>
                    </Group>

                    {/* title_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-title-ua">Название УКР*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                className={cn('form-input', {
                                    'is-error': form.errors.title_ua,
                                })}
                                name="title_ua"
                                type="text"
                                id="art-title-ua"
                                placeholder="Введите название"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={form.register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {form.errors.title_ua
                            && <p className="form-input-hint">{form.errors.title_ua.message}</p>}
                        </ColRight>
                    </Group>

                    {/* publish_at */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-publish-at">Сроки от*:</label>
                        </ColLeft>
                        <ColRight>
                            <InputDate
                                name="publish_at"
                                className="form-input"
                                id="art-publish-at"
                                defVal={formProps?.data?.fields?.publish_at?.split(' ')[0]}
                                placeholder="Выберите дату"
                                formH={form}
                                withZero={false}
                            />
                        </ColRight>
                    </Group>

                    {/* expired_at */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="art-expired-at">Сроки до:</label>
                        </ColLeft>
                        <ColRight>
                            <InputDate
                                name="expired_at"
                                className="form-input"
                                id="art-expired-at"
                                defVal={formProps?.data?.fields?.expired_at?.split(' ')[0]}
                                placeholder="Выберите дату"
                                formH={form}
                                withZero={false}
                                required={false}
                            />
                        </ColRight>
                    </Group>

                </Form>

                {formProps.load && <Loader />}
            </div>
        </form>
    );
};

export default TabInfo;
