import { EMAIL_SUBSCRIPTION_GET, EMAIL_SUBSCRIPTION_SOURCES_GET } from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getEmailSubscription = (params) => fetchAx({
  url: EMAIL_SUBSCRIPTION_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getEmailSubscriptionSources = () => fetchAx({
  url: EMAIL_SUBSCRIPTION_SOURCES_GET,
  method: 'GET'
});