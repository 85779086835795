import React, { useCallback, useEffect, useState } from 'react';
import ColLeft from '../../../../components/ui/FormEditView/ColLeft';
import ColRight from '../../../../components/ui/FormEditView/ColRight';
import Group from '../../../../components/ui/FormEditView/Group';
import SelectInput from '../../../../components/ui/SelectInput';
import { getProduct } from '../../../../lib/api/products';
import TableItems from '../../../../components/ui/TableItems';
import removeDuplicates from "../../../../lib/helpers/removeDuplicates";

const TypeSix = ({ list, state, formH }) => {
  const { typeID } = state;

  const [productsAList, setProductsAList] = useState([]);
  const [productsAMarkerFilter, setProductsAMarkerFilter] = useState([]);
  const [productsACategoriesFilter, setProductsACategoriesFilter] = useState([]);
  const [productsAJDEFilter, setProductsAJDEFilter] = useState([]);

  const [productsBList, setProductsBList] = useState([]);
  const [productsBJDEFilter, setProductsBJDEFilter] = useState([]);

  const [productsСList, setProductsСList] = useState([]);
  const [productsСJDEFilter, setProductsСJDEFilter] = useState([]);

  useEffect(() => {
    if (state?.products) {
      setProductsAList([...state?.products]);
      setProductsAJDEFilter([...state?.products]);
    }
  }, [state?.products]);

  useEffect(() => {
    if (state?.bonusProduct1) {
      setProductsBList([...state?.bonusProduct1]);
      setProductsBJDEFilter([...state?.bonusProduct1]);
    }
  }, [state?.bonusProduct1]);

  useEffect(() => {
    if (state?.bonusProduct2) {
      setProductsСList([...state?.bonusProduct2]);
      setProductsСJDEFilter([...state?.bonusProduct2]);
    }
  }, [state?.bonusProduct2]);

  const parseDataA = useCallback(() => {
    const mrk = [...productsAMarkerFilter];
    const ctg = [...productsACategoriesFilter];
    const jde = [...productsAJDEFilter];

    const parseJde = removeDuplicates(jde);

    const totArr = [...mrk, ...ctg, ...parseJde];
    setProductsAList(totArr);
  }, [productsAMarkerFilter, productsACategoriesFilter, productsAJDEFilter]);

  const parseDataB = useCallback(() => {
    const jde = [...productsBJDEFilter];

    const parseJde = removeDuplicates(jde);

    const totArr = [...parseJde];
    setProductsBList(totArr);
  }, [productsBJDEFilter]);

  const parseDataС = useCallback(() => {
    const jde = [...productsСJDEFilter];

    const parseJde = removeDuplicates(jde);

    const totArr = [...parseJde];
    setProductsСList(totArr);
  }, [productsСJDEFilter]);

  const removeAItem = (e, el) => {
    e.preventDefault();
    const temp = [...productsAList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsAList(temp);
    }
  };

  const removeBItem = (e, el) => {
    e.preventDefault();
    const temp = [...productsBList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsBList(temp);
      setProductsBJDEFilter(temp);
    }
  };

  const removeСItem = (e, el) => {
    e.preventDefault();
    const temp = [...productsСList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setProductsСList(temp);
      setProductsСJDEFilter(temp);
    }
  };

  useEffect(() => {
    setProductsAList([]);
    parseDataA();
  }, [parseDataA]);

  useEffect(() => {
    setProductsBList([]);
    parseDataB();
  }, [parseDataB]);

  useEffect(() => {
    setProductsСList([]);
    parseDataС();
  }, [parseDataС]);

  const domainWatch = formH.watch('domain_id');
  const categoriesList = list.categories.filter(item => item.domain_id.toString() === domainWatch);

  /* eslint-disable */
  return (
    <>
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-domain">Домен:</label>
        </ColLeft>
        <ColRight>
          <select
            name="domain_id"
            className="form-select"
            id="so-domain"
            ref={formH.register()}
          >
            { list.domains.map((domain, i) => domain.title === 'samsungshop'
              && (
                <option
                  key={`so-domain-${i}`}
                  value={domain.id}
                >
                  {domain.title}
                </option>
              ))}
          </select>
        </ColRight>
      </Group>

      <div
        className="divider text-center"
        data-content="Выбрать товары А, которые принимают участие в специальном предложении"
      />
      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-markers">Выбрать по маркеру:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="markers"
            name="markers"
            list={list.markers}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAMarkerFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-categories">Выбрать по категории:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="list"
            title="categories"
            name="categories"
            list={categoriesList[0]?.children}
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsACategoriesFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-jde">Выбрать по JDE:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="value"
            title="jde[]"
            name="jde[]"
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsAJDEFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      {productsAList && productsAList.length !== 0 && (
        <TableItems
          formH={formH}
          list={productsAList}
          addName=".product_id"
          title="Выбраные товары А"
          name={`special_offer_type${typeID}_product`}
          thNames={['JDE код', 'Название', '']}
          func={{
            remove: removeAItem,
          }}
        />
      )}

      <div
        className="divider text-center"
        data-content="Выбрать товары Б, которые могут быть подарком к товарам А"
      />

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-jde">Выбрать по JDE:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="value"
            title="jde[]"
            name="jde[]"
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsBJDEFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      {productsBList && productsBList.length !== 0 && (
        <TableItems
          formH={formH}
          list={productsBList}
          isPrice
          addName=".product_id"
          title="Выбраные товары Б"
          name={`special_offer_type${typeID}_bonus_product1`}
          thNames={['JDE код', 'Название', 'Стоимость для корзины', 'Стоимость для заказа', '']}
          func={{
            remove: removeBItem,
          }}
        />
      )}

      <div
        className="divider text-center"
        data-content="Выбрать товары В, которые могут быть подарком к товарам А"
      />

      <Group>
        <ColLeft>
          <label className="form-label" htmlFor="so-jde">Выбрать по JDE:</label>
        </ColLeft>
        <ColRight>
          <SelectInput
            searchBy="value"
            title="jde[]"
            name="jde[]"
            api={{
              get: getProduct,
            }}
            methods={{
              set: setProductsСJDEFilter,
            }}
            visibleSelected={false}
          />
        </ColRight>
      </Group>

      {productsСList && productsСList.length !== 0 && (
        <TableItems
          formH={formH}
          list={productsСList}
          isPrice
          addName=".product_id"
          title="Выбраные товары В"
          name={`special_offer_type${typeID}_bonus_product2`}
          thNames={['JDE код', 'Название', 'Стоимость для корзины', 'Стоимость для заказа', '']}
          func={{
            remove: removeСItem,
          }}
        />
      )}
    </>
  );
};

export default TypeSix;
