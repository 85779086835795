import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { DOMAIN_API } from "../../../../const/api";
import fetchAx from "../../../../lib/fetchAx";
import Button from "../../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import InputRadio from "../../../../components/ui/InputRadio";
import getNumbersWithSpaces from "../../../../lib/getNumbersWithSpaces";
import errorMessage from "../../../../lib/errorMessage";
import { getBundles, getDiscountSpecialOffers, getGifts, removeOrderCouponPks } from "../../../../lib/api/order";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import s from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../orderTable.module.scss";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../../AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../../assets/icons/icon_search.svg";
import InputWrapper from "../../../../components/ui/InputWrapper";
import { parseDataGet } from "../../api";

const ModalSpecialOffer = ({ active, setActive, title, orderID, formProps, setProductsData, productId, productIndex, productsData }) => {
    const [load, setLoad] = useState(false);

    const [giftsHave, setGiftsHave] = useState(false);
    const [giftChoose, setGiftChoose] = useState(true);
    const [giftB, setGiftB] = useState([]);
    const [giftC, setGiftC] = useState([]);
    const [promoProductB, setPromoProductB] = useState(null);
    const [promoProductC, setPromoProductC] = useState(null);

    const [bundleChoose, setBundleChoose] = useState(false);
    const [isBundleAllowed, setIsBundleAllowed] = useState(false);
    const [bundlesData, setBundlesData] = useState([]);
    const [bundlesHave, setBundlesHave] = useState(false);
    const [promoProductBundle, setPromoProductBundle] = useState(null);

    const [discountSpecialOffersData, setDiscountSpecialOffersData] = useState([]);
    const [promoProductDiscountSpecialOffers, setPromoProductDiscountSpecialOffers] = useState(null);
    const [discountSpecialOffersHave, setDiscountSpecialOffersHave] = useState(false);

    const [filterBundle, setFilterBundle] = useState([]);
    const [searchBundle, setSearchBundle] = useState('');
    const [catIdBundle, setCatIdBundle] = useState(null);

    const handleSearchBundle = useCallback(() => {
        if(!!searchBundle?.length && !!bundlesData?.length) {
            if (+searchBundle) {
                setFilterBundle(bundlesData.filter(item => item?.jde === searchBundle));
            } else {
                setFilterBundle(bundlesData.filter(item => item?.title?.toLowerCase()?.includes(searchBundle?.toLowerCase())));
            }
        } else {
            setFilterBundle(bundlesData);
        }
    }, [searchBundle, bundlesData]);

    const onEnterPressSearchBundle = useCallback((e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            handleSearchBundle();
        }
    }, [bundlesData, filterBundle, searchBundle]);

    const [bundleCategoryList, setBundleCategoryList] = useState([]);

    const getBundleCategories = async () => fetchAx({
        url: `${DOMAIN_API}/api/admin/order/${orderID}/products/${productId}/bundle-categories`,
        method: 'GET'
    });

    const sendPromoProducts = async (data) => fetchAx({
        url: `${DOMAIN_API}/api/admin/order/${orderID}/products/${productId}/promo-products`,
        method: 'POST',
        data
    });

    const handleGetGifts = async () => {
        setLoad(true);
        await getGifts(orderID, productId).then((res) => {
            if(!!res?.data?.length) {
                const parseGiftB = res.data.filter(item => item.type === 'GIFT_B');
                const parseGiftC = res.data.filter(item => item.type === 'GIFT_C');
                setGiftB(parseGiftB);
                setGiftC(parseGiftC);

                if(!!productsData[productIndex]?.product?.special_offers?.length && !promoProductB) {
                    productsData[productIndex]?.product?.special_offers?.forEach(specOffer => {
                        if(!!parseGiftB?.length) {
                            const findGiftB = parseGiftB?.find(dataB => dataB.id === specOffer?.gift_id);
                            if(findGiftB) {
                                setPromoProductB(findGiftB);
                                setIsBundleAllowed(findGiftB.is_bundle_allowed);
                            }
                        }

                        if(!!parseGiftC?.length && !promoProductC) {
                            const findGiftC = parseGiftC?.find(dataC => dataC.id === specOffer?.gift_id);
                            if(findGiftC) {
                                setPromoProductC(findGiftC);
                                setIsBundleAllowed(findGiftC.is_bundle_allowed);
                            }
                        }
                    })
                }
            } else {
                handleGetBundles();
                setBundleChoose(true);
            }
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getGifts(orderID, productId).then((res) => {
                            if(!!res?.data?.length) {
                                const parseGiftB = res.data.filter(item => item.type === 'GIFT_B');
                                const parseGiftC = res.data.filter(item => item.type === 'GIFT_C');
                                setGiftB(parseGiftB);
                                setGiftC(parseGiftC);

                                if(!!productsData[productIndex]?.product?.special_offers?.length && !promoProductB) {
                                    productsData[productIndex]?.product?.special_offers?.forEach(specOffer => {
                                        if(!!parseGiftB?.length) {
                                            const findGiftB = parseGiftB?.find(dataB => dataB.id === specOffer?.gift_id);
                                            if(findGiftB) {
                                                setPromoProductB(findGiftB);
                                                setIsBundleAllowed(findGiftB.is_bundle_allowed);
                                            }
                                        }

                                        if(!!parseGiftC?.length && !promoProductC) {
                                            const findGiftC = parseGiftC?.find(dataC => dataC.id === specOffer?.gift_id);
                                            if(findGiftC) {
                                                setPromoProductC(findGiftC);
                                                setIsBundleAllowed(findGiftC.is_bundle_allowed);
                                            }
                                        }
                                    })
                                }
                            } else {
                                handleGetBundles();
                                setBundleChoose(true);
                            }
                        })
                    })
                } else {
                    await errorMessage(err, `api/admin/order/${orderID}/products/${productId}/gifts GET`)
                }
            })
            .finally(() => setLoad(false));
    };

    const handleGetBundles = async () => {
        setLoad(true);
        await getBundles(orderID, productId).then((res) => {
            if(!!res?.data?.length) {
                setBundlesData(res.data);

                if(!!productsData[productIndex]?.product?.special_offers?.length && !promoProductBundle) {
                    productsData[productIndex]?.product?.special_offers?.forEach(specOffer => {
                        const findBundle = res?.data?.find(dataBundle => dataBundle.id === specOffer?.gift_id);
                        if(findBundle) {
                            setPromoProductBundle(prev => {
                                if (!!prev?.length) {
                                    const temp = [...prev];

                                    const findIndex = temp?.findIndex(item => item.special_offer_id === findBundle?.special_offer_id)
                                    if (findIndex === -1) {
                                        temp.push(findBundle);
                                    }
                                    return temp;
                                }
                                return [findBundle]
                            });
                        }
                    })
                }
            }
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getBundles(orderID, productId).then((res) => {
                            if(!!res?.data?.length) {
                                setBundlesData(res.data);

                                if(!!productsData[productIndex]?.product?.special_offers?.length && !promoProductBundle) {
                                    productsData[productIndex]?.product?.special_offers?.forEach(specOffer => {
                                        const findBundle = res?.data?.find(dataBundle => dataBundle.id === specOffer?.gift_id);
                                        if(findBundle) {
                                            setPromoProductBundle(prev => {
                                                if (!!prev?.length) {
                                                    const temp = [...prev];
                                                    const findIndex = temp?.findIndex(item => item.special_offer_id === findBundle?.special_offer_id)
                                                    if (findIndex === -1) {
                                                        temp.push(findBundle);
                                                    }
                                                    return temp;
                                                }
                                                return [findBundle]
                                            });
                                        }
                                    })
                                }
                            }
                        })
                    })
                } else {
                    await errorMessage(err, `api/admin/order/${orderID}/products/${productId}/bundles GET`)
                }
            })
            .finally(() => setLoad(false));
    };

    const handleGetDiscountSpecialOffers = async () => {
        setLoad(true);
        await getDiscountSpecialOffers(orderID, productId).then((res) => {
            if(!!res?.data?.length) {
                setDiscountSpecialOffersData(res.data);

                if(!!productsData[productIndex]?.product?.discounts?.length && !promoProductDiscountSpecialOffers) {
                    setPromoProductDiscountSpecialOffers(productsData[productIndex]?.product?.discounts);
                }
            }
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getDiscountSpecialOffers(orderID, productId).then((res) => {
                            if(!!res?.data?.length) {
                                setDiscountSpecialOffersData(res.data);

                                if(!!productsData[productIndex]?.product?.special_offers?.length && !promoProductDiscountSpecialOffers) {
                                    productsData[productIndex]?.product?.special_offers?.forEach(specOffer => {
                                        const findDiscountSpecialOffer = res?.data?.find(dataDiscountSpecialOffer => dataDiscountSpecialOffer.id === specOffer?.gift_id);
                                        if(findDiscountSpecialOffer) {
                                            setPromoProductDiscountSpecialOffers(prev => {
                                                if (!!prev?.length) {
                                                    const temp = [...prev];

                                                    const findIndex = temp?.findIndex(item => item.special_offer_id === findDiscountSpecialOffer?.special_offer_id)
                                                    if (findIndex === -1) {
                                                        temp.push(findDiscountSpecialOffer);
                                                    }
                                                    return temp;
                                                }
                                                return [findDiscountSpecialOffer]
                                            });
                                        }
                                    })
                                }
                            }
                        })
                    })
                } else {
                    await errorMessage(err, `api/admin/order/${orderID}/products/${productId}/discount-special-offers GET`)
                }
            })
            .finally(() => setLoad(false));
    };

    useEffect(() => {
        if(productsData[productIndex]?.has_gifts) {
            setGiftsHave(true);
        }

        if(productsData[productIndex]?.has_bundles) {
            setBundlesHave(true);
        }

        if(productsData[productIndex]?.has_discount_offers) {
            setDiscountSpecialOffersHave(true);
        }

        const handleGetCategories = async () => {
            await getBundleCategories()
                .then((res) => setBundleCategoryList(res?.data))
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await getBundleCategories()
                                .then((res) => setBundleCategoryList(res?.data))
                        })
                    } else {
                        await errorMessage(err, `api/admin/order/${orderID}/products/${productId}/bundle-categories GET`)
                    }
                });
        };

        handleGetCategories();
    }, []);

    useEffect(() => {
        if(giftChoose) {
            setBundlesData([]);
            handleGetGifts();
        }

        if(bundleChoose) {
            setGiftB([]);
            handleGetBundles();
        }
        handleGetDiscountSpecialOffers()
    }, [bundleChoose, giftChoose]);

    useEffect(() => {
        if(isBundleAllowed) {
            setLoad(true);
            handleGetBundles();
        } else {
            setBundlesData([]);
        }
    }, [isBundleAllowed]);

    useEffect(() => {
        if(!!productsData[productIndex]?.product?.special_offers?.length && !!giftB?.length) {
            setPromoProductB(productsData[productIndex]?.product?.special_offers?.find(special => {
                const findB = giftB.find(itemB => special.promo_product?.jde?.toString() === itemB.jde.toString());
                if(findB) {
                    return true;
                } else {
                    return false;
                }
            }));
        }
    }, [giftB]);

    useEffect(() => {
        if(!!productsData[productIndex]?.product?.special_offers?.length && !!giftC?.length) {
            setPromoProductC(productsData[productIndex]?.product?.special_offers?.find(special => {
                const findC = giftC.find(itemC => special.promo_product?.jde?.toString() === itemC.jde.toString());
                if(findC) {
                    return true;
                } else {
                    return false;
                }
            }));
        }
    }, [giftC]);

    useEffect(() => {
        if(!!productsData[productIndex]?.product?.special_offers?.length && !!bundlesData?.length) {
            const findBundle = productsData[productIndex]?.product?.special_offers?.find(special => {
                const findBundl = bundlesData.find(itemBundle => special.special_offer_id === itemBundle.special_offer_id);
                if(findBundl) {
                    return true;
                } else {
                    return false;
                }
            });
            if(findBundle) {
                setPromoProductBundle(prev => {
                    if (!!prev?.length) {
                        const temp = [...prev];
                        const findIndex = temp?.findIndex(item => item.special_offer_id === findBundle?.special_offer_id)
                        if (findIndex === -1) {
                            temp.push(findBundle)
                        }
                        return temp;
                    }
                    return [findBundle]
                });
            }
            if(isBundleAllowed) {
                setPromoProductBundle(null);
            }
        }
    }, [bundlesData]);

    const handleSend = async () => {
        const data = () => {
            if(isBundleAllowed) {
                if(promoProductB && promoProductC && promoProductBundle && promoProductDiscountSpecialOffers) {
                    const parseDateProductB = {
                        promo_products: [promoProductB.gift_id || promoProductB.id],
                        special_offer_id: promoProductB.special_offer_id
                    };

                    const parseDateProductC = {
                        promo_products: [promoProductC.gift_id || promoProductC.id],
                        special_offer_id: promoProductC.special_offer_id
                    };

                    const parseDateProductBundle = promoProductBundle?.map(item => {
                        return {
                            promo_products: [item.gift_id || item.id],
                            special_offer_id: item.special_offer_id
                        }
                    });

                    const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                        return {
                            promo_products: [],
                            special_offer_id: item.special_offer_id
                        }
                    });
                    return [parseDateProductB, parseDateProductC, ...parseDateProductBundle, ...parseDateProductDiscountSpecialOffers]
                }
                if(promoProductB && promoProductC && promoProductBundle) {
                    const parseDateProductB = {
                        promo_products: [promoProductB.gift_id || promoProductB.id],
                        special_offer_id: promoProductB.special_offer_id
                    };

                    const parseDateProductC = {
                        promo_products: [promoProductC.gift_id || promoProductC.id],
                        special_offer_id: promoProductC.special_offer_id
                    };

                    const parseDateProductBundle = promoProductBundle?.map(item => {
                        return {
                            promo_products: [item.gift_id || item.id],
                            special_offer_id: item.special_offer_id
                        }
                    });
                    return [parseDateProductB, parseDateProductC, ...parseDateProductBundle]
                }
                if(promoProductB && promoProductBundle && promoProductDiscountSpecialOffers) {
                    const parseDateProductB = {
                        promo_products: [promoProductB.gift_id || promoProductB.id],
                        special_offer_id: promoProductB.special_offer_id
                    };

                    const parseDateProductBundle = promoProductBundle?.map(item => {
                        return {
                            promo_products: [item.gift_id || item.id],
                            special_offer_id: item.special_offer_id
                        }
                    });

                    const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                        return {
                            promo_products: [],
                            special_offer_id: item.special_offer_id
                        }
                    });
                    return [parseDateProductB, ...parseDateProductBundle, ...parseDateProductDiscountSpecialOffers]
                }
                if(promoProductB && promoProductBundle) {
                    const parseDateProductB = {
                        promo_products: [promoProductB.gift_id || promoProductB.id],
                        special_offer_id: promoProductB.special_offer_id
                    };

                    const parseDateProductBundle = promoProductBundle?.map(item => {
                        return {
                            promo_products: [item.gift_id || item.id],
                            special_offer_id: item.special_offer_id
                        }
                    });
                    return [parseDateProductB, ...parseDateProductBundle]
                }
            }
            if(promoProductB && promoProductC && promoProductDiscountSpecialOffers) {
                const parseDateProductB = {
                    promo_products: [promoProductB.gift_id || promoProductB.id],
                    special_offer_id: promoProductB.special_offer_id
                };

                const parseDateProductC = {
                    promo_products: [promoProductC.gift_id || promoProductC.id],
                    special_offer_id: promoProductC.special_offer_id
                };

                const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                    return {
                        promo_products: [],
                        special_offer_id: item.special_offer_id
                    }
                });
                return [parseDateProductB, parseDateProductC, ...parseDateProductDiscountSpecialOffers];
            }
            if(promoProductB && promoProductC) {
                const parseDateProductB = {
                    promo_products: [promoProductB.gift_id || promoProductB.id],
                    special_offer_id: promoProductB.special_offer_id
                };

                const parseDateProductC = {
                    promo_products: [promoProductC.gift_id || promoProductC.id],
                    special_offer_id: promoProductC.special_offer_id
                };
                return [parseDateProductB, parseDateProductC];
            }

            if(promoProductB && promoProductDiscountSpecialOffers) {
                const parseDateProductB = {
                    promo_products: [promoProductB.gift_id || promoProductB.id],
                    special_offer_id: promoProductB.special_offer_id
                };

                const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                    return {
                        promo_products: [],
                        special_offer_id: item.special_offer_id
                    }
                });
                return [parseDateProductB, ...parseDateProductDiscountSpecialOffers];
            }
            if(promoProductC && promoProductDiscountSpecialOffers) {
                const parseDateProductC = {
                    promo_products: [promoProductC.gift_id || promoProductC.id],
                    special_offer_id: promoProductC.special_offer_id
                };

                const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                    return {
                        promo_products: [],
                        special_offer_id: item.special_offer_id
                    }
                });
                return [parseDateProductC, ...parseDateProductDiscountSpecialOffers];
            }
            if(promoProductBundle && promoProductDiscountSpecialOffers) {
                const parseDateProductBundle = promoProductBundle?.map(item => {
                    return {
                        promo_products: [item.gift_id || item.id],
                        special_offer_id: item.special_offer_id
                    }
                });

                const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                    return {
                        promo_products: [],
                        special_offer_id: item.special_offer_id
                    }
                });
                return [...parseDateProductBundle, ...parseDateProductDiscountSpecialOffers];
            }

            if(promoProductB) {
                const parseDateProductB = {
                    promo_products: [promoProductB.gift_id || promoProductB.id],
                    special_offer_id: promoProductB.special_offer_id
                };
                return [parseDateProductB];
            }
            if(promoProductC) {
                const parseDateProductC = {
                    promo_products: [promoProductC.gift_id || promoProductC.id],
                    special_offer_id: promoProductC.special_offer_id
                };
                return [parseDateProductC];
            }
            if(promoProductBundle) {
                const parseDateProductBundle = promoProductBundle?.map(item => {
                    return {
                        promo_products: [item.gift_id || item.id],
                        special_offer_id: item.special_offer_id
                    }
                });
                return parseDateProductBundle;
            }

            if(promoProductDiscountSpecialOffers) {
                const parseDateProductDiscountSpecialOffers = promoProductDiscountSpecialOffers?.map(item => {
                    return {
                        promo_products: [],
                        special_offer_id: item.special_offer_id
                    }
                });
                return parseDateProductDiscountSpecialOffers;
            }
        }

        const sendData = [];
        let saveInd = null;

        const parseData = data();

        if(!!parseData?.length) {
            parseData.forEach((prod, indProd) => {
                const findSpecOfferDub = parseData.find((item, indItem) => {
                    if(indItem !== indProd) {
                        return ((item.special_offer_id === prod.special_offer_id) && (indProd !== indItem));
                    }
                });
                if((typeof saveInd === 'number') && (saveInd !== indProd)) {
                    return sendData.push(prod);
                }

                if(findSpecOfferDub) {
                    const findIndex = parseData.findIndex((fInd) => fInd.promo_products[0] === findSpecOfferDub.promo_products[0]);
                    if(findIndex) {
                        saveInd = findIndex;
                    }
                    return sendData.push({promo_products: [...prod.promo_products, ...findSpecOfferDub.promo_products], special_offer_id: prod.special_offer_id});
                }
                return sendData.push(prod);
            });

            const filterRes = sendData.reduce((o, i) => {
                const findO = o.find(v => {
                    return v.special_offer_id == i.special_offer_id;
                });
                if (!findO) o.push(i);
                return o;
            }, []);

            if (formProps?.data?.fields?.has_pks_discount) {
                await removeOrderCouponPks(formProps?.orderID)
                    .then((res) => {
                        const parseData = parseDataGet(res?.data, formProps?.orderID);

                        if (formProps?.setData) {
                            formProps?.setData(prev => {
                                const temp = {...prev};

                                temp.fields = parseData;

                                return temp;
                            })
                        }
                        if (!!res?.data?.products?.length) {
                            res?.data?.products?.map((c, id) => {
                                setProductsData((prev) => {
                                    const productsData = [...prev];
                                    productsData[id] = {
                                        ...prev[id],
                                        product: {
                                            ...c.product,
                                            manager_id: c.manager_id,
                                            price: c.price,
                                            price_final: c.price_final,
                                            total: c.total,
                                            total_full: c.total_full,
                                            additional_services: c.additional_services,
                                            special_offers: c.special_offers,
                                            discounts: c.discounts
                                        },
                                        discount_pks: c.discount_pks,
                                        manager: c.manager,
                                        manager_id: c.manager_id,
                                        refunded: c.refunded,
                                        has_bundles: c.has_bundles,
                                        has_gifts: c.has_gifts,
                                        has_discount_offers: c.has_discount_offers,
                                        has_additional_services: c.has_additional_services,
                                        id: c.id,
                                        user_id: c.user_id,
                                        user_type: c.user_type,
                                        product_id: c.product_id,
                                        quantity: c.quantity,
                                        warehouse: c.warehouse,
                                        purchase_quantity: c.purchase_quantity,
                                        discount_amount: Math.round(c.discount_amount),
                                        discount_absolute: c.discount_absolute,
                                        discount_bpm: c.discount_bpm,
                                        total: c.total,
                                        discount_percent: c.discount_percent,
                                        selected: c.selected,
                                        return_quantity: c.return_quantity,
                                    };
                                    return productsData;
                                })
                            })
                        }

                    })
                    .catch(async (err) => await errorMessage(err, ''))
            }

            await sendPromoProducts(filterRes)
                .then((res) => location.reload())
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await sendPromoProducts(filterRes)
                                .then((res) => location.reload())
                        })
                    } else {
                        await errorMessage(err, `api/admin/order/${orderID}/products/${productId}/promo-products POST`)
                    }
                });
        }
    };

    useEffect(() => {
        if (!!bundlesData?.length) {
            setFilterBundle(bundlesData);
        }
    }, [bundlesData]);

    useEffect(() => {
        if(!searchBundle?.length && !!bundlesData?.length) {
            setFilterBundle(bundlesData);
        }
    }, [searchBundle, bundlesData]);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>
                    {!load ? (
                        <>
                            {giftsHave && (
                                <>
                                    <div className={s_Modal.special_offer}>
                                        <div className={s_Modal.offer_custom__title}>
                                            {!isBundleAllowed && (
                                                <InputRadio
                                                    id={`gift-custom`}
                                                    name={`promo_products`}
                                                    value={giftChoose}
                                                    onClick={(e) => {
                                                        setBundlesData([]);
                                                        setPromoProductBundle(null);
                                                        setGiftChoose(e?.target?.checked);
                                                        setBundleChoose(false);
                                                    }}
                                                />
                                            )}
                                            {!!giftC?.length ? "Купи А - получи Б и В в подарок" : "Купи А - получи Б в подарок"}
                                        </div>

                                        {!!giftB?.length ? (
                                            <div className={s_Modal.special_offer__wrapper}>
                                                {giftB.map(item => {
                                                    const checkSpecial = productsData[productIndex]?.product?.special_offers?.find(special => special.promo_product?.jde?.toString() === item.jde.toString());
                                                    return (
                                                        <div className={s_Modal.special_offer__block} key={`gift-item-${item.id}`}>
                                                            <InputRadio
                                                                id={`gift-${item.jde}`}
                                                                name={`promo_products_b`}
                                                                defaultChecked={checkSpecial ? checkSpecial?.promo_product?.jde === item.jde.toString() : promoProductB?.jde?.toString() === item.jde.toString()}
                                                                onClick={() => {
                                                                    setPromoProductB(item);
                                                                    setIsBundleAllowed(item.is_bundle_allowed);
                                                                }}
                                                            />
                                                            <div>{item.jde}</div>
                                                            <div>{item.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : 'Нету товаров'}
                                    </div>

                                    {(!!giftB?.length && !!giftC.length) && (
                                        <div className={s_Modal.special_offer}>
                                            <div className={s_Modal.offer_custom__title}>Купи А - получи Б и В в подарок</div>
                                            <div className={s_Modal.special_offer__wrapper}>
                                                {giftC.map(item => {
                                                    const checkSpecial = productsData[productIndex]?.product?.special_offers?.find(special => special.promo_product?.jde?.toString() === item.jde.toString());

                                                    return (
                                                        <div className={s_Modal.special_offer__block} key={`gift-item-${item.id}`}>
                                                            <InputRadio
                                                                id={`gift-${item.id}`}
                                                                name={`promo_products_c`}
                                                                defaultChecked={checkSpecial ? checkSpecial?.promo_product?.jde === item.jde.toString() : promoProductC?.jde?.toString() === item.jde.toString()}
                                                                onClick={() => {
                                                                    setIsBundleAllowed(item.is_bundle_allowed);
                                                                    setPromoProductC(item);
                                                                }}
                                                            />
                                                            <div>{item.jde}</div>
                                                            <div>{item.title}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {bundlesHave && (
                                <div className={s_Modal.special_offer}>
                                    <div className={s_Modal.offer_custom__title}>
                                        {!isBundleAllowed && (
                                            <InputRadio
                                                id={`bundle-custom`}
                                                name={`promo_products`}
                                                value={bundleChoose}
                                                onClick={(e) => {
                                                    setGiftB([]);
                                                    setBundleChoose(e?.target?.checked);
                                                    setGiftChoose(false);
                                                    setPromoProductB(null);
                                                    setPromoProductC(null);
                                                }}
                                            />
                                        )}
                                        Купи А - получи Б со скидкой
                                    </div>
                                    <div style={{ margin: '16px 0' }}>
                                        <div className={s_Modal.special_offer__cat}>
                                            {!!bundleCategoryList?.length && bundleCategoryList?.map((catItem) => {
                                                return (
                                                    <button
                                                        className={cn(s_Modal.special_offer__cat_item, {
                                                            [s_Modal.special_offer__cat_item_active]: catIdBundle === catItem.id
                                                        })}
                                                        key={`bundle-cat-item-${catItem?.id}`}
                                                        type="button"
                                                        onClick={() => {
                                                            if (catIdBundle) {
                                                                if (catIdBundle === catItem.id) {
                                                                    setCatIdBundle(null);
                                                                    setFilterBundle(bundlesData);
                                                                } else {
                                                                    setFilterBundle(bundlesData?.filter(item => item?.category_id === catItem?.id));
                                                                    setCatIdBundle(catItem.id);
                                                                }
                                                            } else {
                                                                setCatIdBundle(catItem.id);
                                                                setFilterBundle(bundlesData?.filter(item => item?.category_id === catItem?.id));
                                                            }
                                                        }}
                                                    >
                                                        {catItem?.title}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <InputWrapper label="Пошук" id="search-bundle-prod" style={{ marginTop: 16 }}>
                                            <input
                                                className={s_Input.input}
                                                type="text"
                                                placeholder="Пошук"
                                                id="search-bundle-prod"
                                                value={searchBundle}
                                                onChange={e => setSearchBundle(e.target.value)}
                                                onKeyDown={onEnterPressSearchBundle}
                                            />
                                            <div className={s_Cat.input_icon} onClick={handleSearchBundle}>
                                                <img src={icon_search} alt="Icon search" />
                                            </div>
                                        </InputWrapper>
                                    </div>
                                    {!!filterBundle?.length && (
                                        <div className={s_Modal.special_offer__wrapper}>
                                            {filterBundle.map((item, index) => {
                                                const checkSpecial = productsData[productIndex]?.product?.special_offers?.find(special => special.promo_product?.jde?.toString() === item.jde.toString());
                                                const defCheckBundle = () => {
                                                    if(!productsData[productIndex]?.product?.special_offers?.length && isBundleAllowed) {
                                                        return false;
                                                    } else if (checkSpecial) {
                                                        return checkSpecial?.promo_product?.jde === item.jde.toString();
                                                    }
                                                }

                                                const checkDisable = () => {
                                                    if (!!promoProductBundle?.compatible_special_offers?.length) {
                                                        return !promoProductBundle?.compatible_special_offers?.includes(item?.special_offer_id);
                                                    }
                                                    if (!!promoProductBundle?.length) {
                                                        return !promoProductBundle?.every(itemFilter => {
                                                            return itemFilter?.jde === item?.jde || itemFilter?.compatible_special_offers?.includes(item?.special_offer_id)
                                                        })
                                                    }
                                                    return false;
                                                }

                                                const checkChoose = () => {
                                                    if (promoProductBundle?.jde) {
                                                       return promoProductBundle?.jde === item?.jde
                                                    }
                                                    if (!!promoProductBundle?.length) {
                                                        return !!promoProductBundle?.find(itemFilter => itemFilter?.jde === item?.jde)
                                                    }
                                                    return false;
                                                }

                                                return (
                                                    <div className={s_Modal.special_offer__block} key={`gift-item-${item.id}`}>
                                                        <div className={s.checkbox__wrapper}>
                                                            <input
                                                                className={s.checkbox}
                                                                type="checkbox"
                                                                id={`gift-item-${item.id}`}
                                                                name={`products[${productIndex}].promo_products_bundle[${index}]`}
                                                                defaultChecked={defCheckBundle()}
                                                                checked={checkChoose()}
                                                                disabled={checkDisable()}
                                                                style={{ height: 20, width: 20, zIndex: 3, transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
                                                                onClick={(e) => {
                                                                    setPromoProductBundle(prev => {
                                                                        if (!!prev?.length) {
                                                                            const temp = [...prev];

                                                                            const findItem = temp?.findIndex(itemFind => item?.special_offer_id === itemFind?.special_offer_id)

                                                                            if (findItem !== -1) {
                                                                                temp.splice(findItem, 1);
                                                                            } else {
                                                                                temp.push(item);
                                                                            }
                                                                            return temp;
                                                                        }

                                                                        return [item];
                                                                    })
                                                                }}
                                                            />
                                                            <label htmlFor={`gift-item-${item.id}`}
                                                                   className={`${s.label} ${s_Table.label}`}/>
                                                        </div>
                                                        <div>{item.jde}</div>
                                                        <div style={{ minWidth: "770px" }}>{item.title}</div>
                                                        <div style={{ minWidth: "50px" }}>-{item.discount_percent}%</div>
                                                        <div>
                                                            <span>{getNumbersWithSpaces(item.price)}</span>
                                                            {getNumbersWithSpaces(item.price_final)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}

                            {!!discountSpecialOffersData?.length && (
                                <div className={s_Modal.special_offer}>
                                    <div className={s_Modal.offer_custom__title}>
                                        Акційні пропозиції
                                    </div>
                                    {!!discountSpecialOffersData?.length && (
                                        <div className={s_Modal.special_offer__wrapper}>
                                            {discountSpecialOffersData.map((item, index) => {
                                                const checkSpecial = productsData[productIndex]?.product?.discounts?.find(special => special?.special_offer_id === item?.special_offer_id);
                                                const defCheckBundle = () => {
                                                    if(!productsData[productIndex]?.product?.discounts?.length && isBundleAllowed) {
                                                        return false;
                                                    } else if (checkSpecial) {
                                                        return checkSpecial?.special_offer_id === item?.special_offer_id;
                                                    }
                                                }

                                                const checkDisable = () => {
                                                    if (!!promoProductDiscountSpecialOffers?.compatible_special_offers?.length) {
                                                        return !promoProductDiscountSpecialOffers?.compatible_special_offers?.includes(item?.special_offer_id);
                                                    }
                                                    if (!!promoProductDiscountSpecialOffers?.length) {
                                                        return !promoProductDiscountSpecialOffers?.filter(itemFilter => {
                                                            if (itemFilter?.special_offer_id === item?.special_offer_id) {
                                                                return true;
                                                            } else {
                                                                return itemFilter?.compatible_special_offers?.includes(item?.special_offer_id)
                                                            }
                                                        })?.length
                                                    }
                                                    return false;
                                                }

                                                const checkChoose = () => {
                                                    if (promoProductDiscountSpecialOffers?.special_offer_id) {
                                                        return promoProductDiscountSpecialOffers?.special_offer_id === item?.special_offer_id
                                                    }
                                                    if (!!promoProductDiscountSpecialOffers?.length) {
                                                        return !!promoProductDiscountSpecialOffers?.find(itemFilter => itemFilter?.special_offer_id === item?.special_offer_id)
                                                    }
                                                    return false;
                                                }

                                                return (
                                                    <div className={s_Modal.special_offer__block} key={`gift-item-${item.id}`}>
                                                        <div className={s.checkbox__wrapper}>
                                                            <input
                                                                className={s.checkbox}
                                                                type="checkbox"
                                                                id={`discount-item-${item.id}`}
                                                                name={`products[${productIndex}].discounts[${index}]`}
                                                                defaultChecked={defCheckBundle()}
                                                                checked={checkChoose()}
                                                                disabled={checkDisable()}
                                                                style={{ height: 20, width: 20, zIndex: 3, transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
                                                                onClick={(e) => {
                                                                    setPromoProductDiscountSpecialOffers(prev => {
                                                                        if (!!prev?.length) {
                                                                            const temp = [...prev];

                                                                            const findItem = temp?.findIndex(itemFind => item?.special_offer_id === itemFind?.special_offer_id)

                                                                            if (findItem !== -1) {
                                                                                temp.splice(findItem, 1);
                                                                            } else {
                                                                                temp.push(item);
                                                                            }
                                                                            return temp;
                                                                        }

                                                                        return [item];
                                                                    })
                                                                }}
                                                            />
                                                            <label htmlFor={`discount-item-${item.id}`}
                                                                   className={`${s.label} ${s_Table.label}`}/>
                                                        </div>
                                                        <div style={{ minWidth: "770px" }}>{item.title}</div>
                                                        <div style={{ minWidth: "50px" }}>-{item.discount_percent}%</div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span>{getNumbersWithSpaces(item.price_final)}</span>
                                                            <span style={{ color: '#828282' }}>({getNumbersWithSpaces(item.price)})</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}

                        </>
                    ) : (
                        <div className="p-relative">
                            <div className="loading" />
                        </div>
                    )}
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button purple onClick={handleSend}>Добавить и сохранить</Button>
                        </div>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSpecialOffer;
