import {
  BANNER_HEAD_GET_ALL,
  BANNER_HEAD_GET,
  BANNER_HEAD_CREATE,
  BANNER_HEAD_GET_EDIT,
  BANNER_HEAD_EDIT, BANNER_HEAD_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getBannerHead = (params) => fetchAx({
  url: BANNER_HEAD_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getBannerHeadAll = () => fetchAx({
  url: BANNER_HEAD_GET_ALL,
  method: 'GET',
});

export const createBannerHead = (data) => fetchAx({
  url: BANNER_HEAD_CREATE,
  method: 'POST',
  data,
});

export const getBannerHeadEdit = (id) => fetchAx({
  url: BANNER_HEAD_GET_EDIT.join(id),
  method: 'GET',
});

export const editBannerHead = (id, data) => fetchAx({
  url: BANNER_HEAD_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeBannerHead = (id) => fetchAx({
  url: BANNER_HEAD_DELETE.join(id),
  method: 'DELETE',
});
