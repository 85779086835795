import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  getData, remove, edit, parseDataGet, parseDataSend,
} from './api';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";

const HrDomainMaskViewEdit = () => {
  const { id: devID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: devID,
    updateFields: reset,
    handleSubmit,
    api: {
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/hr-domain-mask/not-found',
      remove: `/app/hr-domain-mask${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/hr-domain-mask${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'HR domain mask создана',
      edit: 'HR domain mask обновлена',
      remove: 'HR domain mask удалена',
    },
  });

  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - HR domain mask edit`} />
      <div className="box-wrap">
        {devID
          ? (
            <Title>
              HR маска -
              <small>{data && data.fields.title}</small>
            </Title>
          )
          : <Title>Новое устройство</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="hr-domain-mask-title">Название домена*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title"
                className={cn('form-input', {
                  'is-error': errors.title,
                })}
                type="text"
                id="hr-domain-mask-title"
                placeholder="Введите название домена"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
            </ColRight>
          </Group>

        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default HrDomainMaskViewEdit;
