import {
    COOPERATION_B2B_CREATE,
    COOPERATION_B2B_EDIT,
    COOPERATION_B2B_GET,
    COOPERATION_B2B_GET_EDIT,
    COOPERATION_B2B_STATUSES_GET,
    COOPERATION_B2B_TYPES_GET
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getCooperationB2B = (params) => fetchAx({
    url: COOPERATION_B2B_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});

export const createCooperationB2B = (data) => fetchAx({
    url: COOPERATION_B2B_CREATE,
    method: 'POST',
    data,
});

export const getCooperationB2BEdit = (id) => fetchAx({
    url: COOPERATION_B2B_GET_EDIT.join(id),
    method: 'GET',
});

export const editCooperationB2B = (id, data) => fetchAx({
    url: COOPERATION_B2B_EDIT.join(id),
    method: 'PUT',
    data,
});

export const getCooperationB2BStatus = (params) => fetchAx({
    url: COOPERATION_B2B_STATUSES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});

export const getCooperationB2BTypes = (params) => fetchAx({
    url: COOPERATION_B2B_TYPES_GET,
    method: 'GET',
    params: {
        ...params,
    },
});
