import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { getData, getFiltersData, remove } from './api';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import getImgUrl from '../../lib/helpers/getImgUrl';
import { geDomainName } from '../../lib/const/domains';
import checkPermission from "../../lib/checkPermission";
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TableListViewPagination from '../../components/common/TableListViewNew/Pagination';
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from '../../components/common/TableListViewNew/SelectPerPage';
import TableListViewRowLoader from '../../components/common/TableListViewNew/Row/RowLoader';
import TopNavTabs from '../../components/ui/TopNavTabsNew/TopNavTabs';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import getTZtoDate from "../../lib/getTZtoDate";

const BannerManagementMenuList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    filtersData,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div style={{ margin: 0 }}>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner menu list`} />
      {!load && (
          <TopPagination
              tabs={(
                  <TopNavTabs tabs={[
                    {
                      title: 'Банер головна сторінка',
                      url: '/app/banner-management/home',
                      permissionName: 'admin.banner-main-page.index'
                    },
                    {
                      title: 'Банер меню',
                      url: '/app/banner-management/menu',
                      permissionName: 'admin.banner-menu.index',
                      isActive: true
                    },
                    {
                        title: 'Горизонтальний банер каталогу',
                        url: '/app/banner-management/catalog-horizontal',
                        permissionName: 'admin.banner-catalog-horizontal.index'
                    },
                    {
                      title: 'Банер над шапкою сайту',
                      url: '/app/banner-management/head',
                      permissionName: 'admin.banner-header.index'
                    },
                    {
                        title: 'Вертикальний банер каталогу',
                        url: '/app/banner-management/catalog',
                        permissionName: 'admin.banner-catalog.index'
                    }
                  ]}
                  />
              )}
              pagination={(
                  <>
                      {rest?.meta?.total && (
                          <>
                              <TableListViewPagination
                                  length={+rest?.meta?.last_page}
                                  current={+rest?.meta?.current_page}
                                  loc={location}
                                  navigate={navigate}
                              />
                              <TableListViewPerPage
                                  urlParams={urlParams}
                                  onSelect={(val, params) => pushUrlParams(params)}
                                  total={+rest?.meta?.total}
                              />
                          </>
                      )}
                  </>
              )}
          />
      )}
      <TableListViewTable>
        <TableHead
            arrayChildren={[
              'ID', 'Назва банера УКР', 'Назва банера РУС', 'Зображення УКР', 'Зображення РУС',
              'Категорія', 'Посилання УКР', 'Посилання РУС', 'Позиція', 'Дата початку', 'Дата закінчення', 'Домен', 'Статус активності'
            ]}
            filterChildren={<TableListViewFiltersRow
                urlParams={urlParams}
                fields={[
                    { },
                    { type: 'text', name: 'title_ua' },
                    { type: 'text', name: 'title_ru' },
                    { },
                    { },
                    {
                        type: 'select',
                        name: 'category_id',
                        options: filtersData?.categories && filtersData?.categories,
                        optProps: ['id', 'title']
                    },
                    { },
                    { },
                    { },
                    { type: 'date', name: 'starts_at'},
                    { type: 'date', name: 'finish_at' },
                    {
                        type: 'select', name: 'domain_id', options: filtersData?.domains
                    },
                    { },
                    { }
                ]}
                onSubmit={pushUrlParams}
            />}
        />
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={11} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                  <TableListViewRow
                    id={e.id}
                    key={`banner-management-item-${e.id}`}
                    remove={{
                      api: remove,
                      success: () => setRest(getSplicedData(i, rest)),
                      alert: 'Банер меню видалено',
                    }}
                    editLink={`/app/banner-management/menu/update/${e.id}`}
                    permissionRemove='admin.banner-menu.destroy'
                    permissionEdit='admin.banner-menu.edit'
                  >
                    <td>
                      {checkPermission('admin.banner-menu.edit')
                        ? <Link to={`/app/banner-management/menu/update/${e.id}`}>{e.id}</Link>
                        : <span>{e.id}</span>
                      }
                    </td>
                    <td>
                      <span>
                        {e.title_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.title_ru || '---'}
                      </span>
                    </td>
                    <td>{e?.cover_ua && <img src={e?.cover_ua?.original || e?.cover_ua?.conversions?.original} alt=""/>}</td>
                    <td>{e?.cover_ru && <img src={e?.cover_ru?.original || e?.cover_ru?.conversions?.original} alt=""/>}</td>
                    <td>
                      <span>
                        {e?.category_id ? !!filtersData?.categories?.length && filtersData?.categories?.find(category => category?.id === e?.category_id)?.title : '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.link_ua || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.link_ru || '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.position || 0}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.starts_at ? getTZtoDate(e.starts_at, true) : '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.finish_at ? getTZtoDate(e.finish_at, true) : '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e?.domain_id ? geDomainName(e.domain_id)?.name : '---'}
                      </span>
                    </td>
                    <td>
                      <span>
                        {e.is_active ? <StatusCircle green text="Активна"/> : <StatusCircle red text="Не активна"/>}
                      </span>
                    </td>
                  </TableListViewRow>
                ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={11} style={{paddingTop: '22px'}}>Немає банерів меню</td>
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default BannerManagementMenuList;
