import React, { useEffect, useState } from 'react';
import s from "./PromocodeTab.module.scss";
import s_Block from "../../PromocodeOfNonPurchasingActivityEditView.module.scss";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import Button from "../../../../components/ui/Button";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import fetchAx from "../../../../lib/fetchAx";
import { DOMAIN_API } from "../../../../const/api";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import errorMessage from "../../../../lib/errorMessage";
import { toast } from "react-toastify";
import { getPromo } from "../../api";
import importIcon from "../../../../assets/icons/icon_import.svg";
import addIcon from "../../../../assets/icons/icon_add.svg";
import TableListViewTable from "../../../../components/common/TableListViewNew/Table";
import TableListViewRow from "../../../../components/common/TableListViewNew/Row";
import Input from "../../../../components/common/TableListViewNew/Input/Input";
import icon_delete from "../../../../assets/icons/icon_delete.svg";
import ModalRemove from "../../../../components/ui/Modal/ModalRemove";
import { createCurtCoupon, removeCurtRuleCoupon } from "../../../../lib/api/cart_rules";
import TableListViewPagination from "../../../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../../../components/common/TableListViewNew/SelectPerPage";
import Loader from "../../../../components/ui/Loader";

const PromocodeTab = ({ data, serID }) => {
    const [activeModalRemove, setActiveModalRemove] = useState(false);
    const [load, setLoad] = useState(false);

    const [promocodeResponse, setPromocodeResponse] = useState(null);
    const [promocodeList, setPromocodeList] = useState([]);
    const [promocodeChooseData, setPromocodeChooseData] = useState(null);
    const [promoCode, setPromoCode] = useState('');
    const [promoBonusAmount, setPromoBonusAmount] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleAddSubmit = async () => {
        setLoad(true);
        await createCurtCoupon({
            code: promoCode,
            bonus_amount: +promoBonusAmount,
            cart_rule_id: +data?.fields?.id
        }).then((res) => {
            setPromocodeList(prev => [res.data, ...prev]);
            setPromoCode('');
            setPromoBonusAmount('');
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await createCurtCoupon({
                        code: promoCode,
                        cart_rule_id: +data?.fields?.id
                    }).then((res) => {
                        setPromocodeList(prev => [res.data, ...prev]);
                        setPromoCode('');
                        setPromoBonusAmount('');
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        }).finally(() => setLoad(false));
    };

    const handleRemoveSubmit = async (item) => {
        setPromocodeChooseData(item);
        setActiveModalRemove(true);
    };

    const handleImportSubmit = async (files) => {

        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLoad(true);
        const result = await fetchAx({
            url: `${DOMAIN_API}/api/admin/cart-rules/${serID}/import`,
            method: 'POST',
            data: formData
        }).then((res) => toast("Промокода добавлены", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    const result = await fetchAx({
                        url: `${DOMAIN_API}/api/admin/cart-rules/${serID}/import`,
                        method: 'POST',
                        data: formData
                    }).then((res) => toast("Промокода добавлены", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }))
                    if(result) {
                        await getPromo(serID, {code: searchText})
                            .then((res) => setPromocodeList(res.data.data))
                            .catch(async (err) => await errorMessage(err , ''));
                    }
                })
            } else {
                await errorMessage(err, '')
            }
        }).finally(() => setLoad(false));

        if(result) {
            getPromo(serID, {code: searchText})
                .then((res) => setPromocodeList(res.data.data))
                .catch(async (err) => await errorMessage(err , ''));
        }
    };

    const handleOnChangeFilter = (e) => setSearchText(e.target.value);
    const clearFilter = () => setSearchText('');

    useEffect(() => {
        if(data) {
            setPromocodeList(data.coupons);
        }
    }, [data]);

    useEffect(() => {
        getPromo(data?.fields?.id, {code: searchText})
            .then((res) => {
                setPromocodeResponse(res.data)
                setPromocodeList(res.data.data)
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getPromo(data?.fields?.id, {code: searchText})
                            .then((res) => {
                                setPromocodeResponse(res.data)
                                setPromocodeList(res.data.data)
                            })
                    })
                } else {
                    await errorMessage(err, '')
                }
            });
    }, [searchText]);

    return (
        <div>
            <div className={s.block_top}>
                <div className={s.block_top__left}>
                    <div className={s.block_top__input}>
                        <InputWrapper label="Додати код" id="ps-promo-code-add">
                            <input
                                name="promo_code_text"
                                className={s_Input.input}
                                type="text"
                                id="ps-promo-code-add"
                                placeholder="Додати код"
                                value={promoCode}
                                onChange={e => setPromoCode(e.target.value)}
                                maxLength={INPUT_MAX_LENGTH}
                            />
                        </InputWrapper>
                        <InputWrapper label="Сума бонусу" id="ps-promo-bonus-amount-add">
                            <input
                                name="promo_code_bonus_amount"
                                className={s_Input.input}
                                type="number"
                                id="ps-promo-bonus-amount-add"
                                placeholder="Сума бонусу"
                                value={promoBonusAmount}
                                onChange={e => setPromoBonusAmount(e.target.value)}
                            />
                        </InputWrapper>
                    </div>
                    <div className={s.block_top__button}>
                        <Button purple onClick={handleAddSubmit}>
                            <img src={addIcon} alt="add"/>
                            Добавити
                        </Button>
                        <div className={s_Block.input__wrapper}>
                            <input type="file" className={s_Block.input__file} id="inputGroupFile01"
                                   onChange={(e) => handleImportSubmit(e.target.files)}
                            />
                            <label htmlFor="inputGroupFile01" className={s_Block.input__file_button}>
                        <span className={s_Block.input__file_button_text}>
                            <img src={importIcon} alt="import"/>
                            Імпорт
                        </span>
                            </label>
                        </div>
                    </div>
                </div>
                {promocodeResponse && (
                    <div className={s.block_top__right}>
                        <TableListViewPagination
                            length={+promocodeResponse?.meta?.last_page}
                            current={+promocodeResponse?.meta?.current_page}
                            url={`api/admin/cart-rules/${data?.fields?.id}/coupons`}
                            setData={setPromocodeList}
                        />
                        <TableListViewPerPage
                            total={+promocodeResponse?.meta?.total}
                        />
                    </div>
                )}
            </div>
            <div className={s.table_wrapper}>
                <TableListViewTable>
                    <thead>
                    <tr>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                            <div>
                                <span style={{ whiteSpace: 'nowrap' }}>Код промокоду</span>
                                <Input
                                    value={searchText}
                                    onChange={handleOnChangeFilter}
                                    placeholder="Пошук"
                                    funcClearValue={clearFilter}
                                    style={{ maxWidth: "100%", width: '100%' }}
                                    styleWrapper={{ width: '100%' }}
                                />
                            </div>
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                            Сума бонусу
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 12 }}>
                            Дата створення
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                            Термін дії
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }}>
                            Кількість використань
                        </th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: '#edeef0', zIndex: 2 }} />
                    </tr>
                    </thead>
                    <tbody>
                    { !!promocodeList?.length
                        ? promocodeList?.map((e, i) => (
                            <TableListViewRow
                                id={e.id}
                                key={`table-promo-code-id-${i}`}
                                controlsOff
                            >
                                <td>
                                  <span>
                                    {e.code || '---'}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {e?.bonus_amount || 0}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {e.created_at || '---'}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {e.expired_at || '---'}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {e.times_used || 0}
                                  </span>
                                </td>
                                <td>
                                  <button type="button" className={s.btn_danger} onClick={() => handleRemoveSubmit(e)}>
                                      <img src={icon_delete} alt="icon delete" />
                                  </button>
                                </td>
                            </TableListViewRow>
                        ))
                        : (
                            <TableListViewRow disabled>
                                <td colSpan={4} style={{ paddingTop: '22px' }}>Немає промокодів</td>
                            </TableListViewRow>
                        )}
                    </tbody>
                </TableListViewTable>
            </div>
            {load && <Loader />}
            {activeModalRemove && promocodeChooseData && (
                <ModalRemove
                    active={activeModalRemove}
                    setActive={setActiveModalRemove}
                    title={`Видалити промокод ${promocodeChooseData?.code}?`}
                    funcRemove={removeCurtRuleCoupon}
                    data={promocodeChooseData}
                    setRemoveItem={setPromocodeList}
                />
            )}
        </div>
    );
};

export default PromocodeTab;