import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  CREDIT_TYPE_CREATE,
  CREDIT_TYPE_DELETE,
  CREDIT_TYPE_EDIT,
  CREDIT_TYPE_GET_EDIT,
  CREDIT_TYPES_GET,
} from '../../const/api';

export const getCreditTypes = (params) => fetchAx({
  url: CREDIT_TYPES_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxl,
    ...params,
  },
});
export const createCreditType = (data) => fetchAx({
  url: CREDIT_TYPE_CREATE,
  method: 'POST',
  data,
});

export const getCreditTypeEdit = (id) => fetchAx({
  url: CREDIT_TYPE_GET_EDIT.join(id),
  method: 'GET',
});

export const editCreditType = (id, data) => fetchAx({
  url: CREDIT_TYPE_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeCreditType = (id) => fetchAx({
  url: CREDIT_TYPE_DELETE.join(id),
  method: 'DELETE',
});
