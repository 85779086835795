import {Link, useLocation, useNavigate} from "react-router-dom";
import React from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import {getData, getFiltersData, remove} from './api';
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import getTZtoDate from "../../lib/getTZtoDate";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import checkPermission from "../../lib/checkPermission";
import styles from "react-date-range/dist/styles";

const ReviewsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();

    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Review list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {rest?.meta?.total > 0 && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable >
                <TableHead
                    arrayChildren={[
                        'ID', {
                            title: 'Відгук',
                            attr: { style: { maxWidth: '300px' } }
                        }, 'Рейтинг', 'Користувач', 'Придбав товар',
                        'Замовлення', 'Менеджер', 'Товар', 'Статус активності', 'Дата створення', 'Дата оновлення'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {
                                type: 'text',
                                name: 'id',
                                attr: {
                                    style: {
                                        maxWidth: '50px',
                                        paddingLeft: '0'
                                    }
                                }
                            },
                            {},
                            {
                                type: 'text',
                                name: 'rating_from',
                                attr: {
                                    style: {
                                        maxWidth: '50px'
                                    }
                                },
                                attrWrapper: {
                                    style: {
                                        display: 'block'
                                    }
                                },
                                secondLvl: {
                                    type: 'text',
                                    name: 'rating_to',
                                    attr: {
                                        style: {
                                            maxWidth: '50px'
                                        }
                                    }
                                },
                            },
                            {
                                type: 'text',
                                name: 'user',
                                attr: {
                                    placeholder: "І'мя або телефон",
                                    style: {
                                        maxWidth: '120px'
                                    }
                                }
                            },
                            {
                                type: 'select',
                                name: 'has_purchases',

                                options: [
                                    {
                                        id: 0,
                                        title: 'Не купив'
                                    },
                                    {
                                        id: 1,
                                        title: 'Купив'
                                    }
                                ],
                            },
                            {},
                            {},
                            { type: 'text', name: 'jde', attr: {
                                    style: {
                                        maxWidth: '120px'
                                    }
                                }},
                            {
                                type: 'select',
                                name: 'status',
                                options: filtersData && filtersData?.statuses,
                                optProps: ['value', 'title']
                            },
                            {
                                type: 'date', name: 'created_from', secondLvl: {
                                    type: 'date', name: 'created_to'
                                }, attrWrapper: {
                                    style: {
                                        display: 'block'
                                    }
                                }
                            },
                            {
                                type: 'date', name: 'updated_from', secondLvl: {
                                    type: 'date', name: 'updated_to'
                                }, attrWrapper: {
                                    style: {
                                        display: 'block'
                                    }
                                }
                            },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />

                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={11}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`review-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Отзыв удален',
                                    }}
                                    editLink={`/app/review/update/${e.id}`}
                                    permissionRemove='admin.social.reviews.destroy'
                                    permissionEdit='admin.social.reviews.edit'
                                >
                                    <td>
                                        {checkPermission('admin.social.reviews.edit')
                                            ? <Link to={`/app/review/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td >
                                        <span>
                                            {e?.body || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.rating || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.user?.name || '---'}
                                            <br/>
                                            <br/>
                                            {e?.user?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.has_purchases ? 'Так' : 'Ні'}
                                        </span>
                                    </td>
                                    <td>
                                        {e?.has_purchases ? (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                flexDirection: 'column',
                                                gap: 5
                                            }}>
                                                {!!e?.offline_orders?.length && e?.offline_orders?.map(item => (
                                                    <div key={`review-link-order-offline-${item?.id}`}>
                                                        <div style={{ whiteSpace: 'nowrap' }}>Створено: {item?.created_at}</div>
                                                        <div>Магазин: {item?.warehouse_name}</div>
                                                    </div>
                                                ))}
                                                {!!e?.orders?.length && e?.orders?.map(item => (
                                                    <span key={`review-link-order-${item?.id}`}>
                                                        <Link to={`/app/order/update/${item?.id}`} target="_blank">{item?.id}</Link>
                                                    </span>
                                                ))}
                                            </div>
                                        ) : '---'}

                                    </td>
                                    <td>
                                        <span>
                                            {e?.manager?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.product?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        {filtersData ? filtersData?.statuses?.find(status => status?.value === e?.status)?.title : e?.status}
                                    </td>
                                    <td>
                                        <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.updated_at ? getTZtoDate(e?.updated_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={11}>Немає відгуків</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default ReviewsList;
