/**
 * Parse string by pattern (with prefix "%")
 *
 * patObj - { a: 1, b: 2 }
 * "test %a and %b" to  "test 1 and 2"
 *
 * @param str
 * @param patObj
 * @returns {String}
 */
const parseStrPat = (str, patObj) => {
  let res = str;

  if (patObj) {
    Object.keys(patObj).forEach((e) => {
      res = res.replace(`%${ e}`, patObj[e]);
    });
  }

  return res;
};

export default parseStrPat;
