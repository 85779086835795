import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VALIDATION } from '../../const/text';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
    getData, remove, edit, create, parseDataGet, parseDataSend,
} from './api';
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Select from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import cn from "classnames";
import {INPUT_MAX_LENGTH} from "../../const/view";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const SubscriberEditView = () => {
    const { id: subsID } = useParams();
    const formH = useForm();
    const {
        register,
        errors,
        handleSubmit,
        reset,
    } = formH;

    const { load, data, submitHandler } = useEdit({
        targetID: subsID,
        // updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/subscription/not-found',
            remove: `/app/subscriptions${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/subscriptions${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Подписка создана',
            edit: 'Подписка обновлена',
            remove: 'Подписка удалена',
        },
    });

    /* eslint-disable */
    return (
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Subscriptions edit`} />
            <div className="box-wrap">
                {subsID
                    ? (
                        <Title>
                            Статус -
                            <small>{data && data.fields.title_ru}</small>
                        </Title>
                    )
                    : <Title>Новый статус</Title>}

                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide" />

                    {/* surname */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-surname">Фамилия:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="surname"
                                className='form-input'
                                type="text"
                                id="sub-surname"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                        </ColRight>
                        {/*<ColRight>*/}
                        {/*    <input*/}
                        {/*        name="surname"*/}
                        {/*        className={cn('form-input', {*/}
                        {/*            'is-error': errors.surname,*/}
                        {/*        })}*/}
                        {/*        type="text"*/}
                        {/*        id="sub-surname"*/}
                        {/*        placeholder="Введите текст"*/}
                        {/*        maxLength={INPUT_MAX_LENGTH}*/}
                        {/*        ref={register}*/}
                        {/*    />*/}
                        {/*    {errors.surname && <p className="form-input-hint">{errors.surname.message}</p>}*/}
                        {/*</ColRight>*/}
                    </Group>

                    {/* name */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-name">Имя:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="name"
                                className='form-input'
                                type="text"
                                id="sub-name"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                        </ColRight>
                    </Group>

                    {/* instagram */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-instagram">Instagram:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="name"
                                className='form-input'
                                type="text"
                                id="sub-name"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                        </ColRight>
                    </Group>

                    {/* email */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-email">Email:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="email"
                                className='form-input'
                                type="email"
                                id="sub-email"
                                placeholder="Введите email"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                        </ColRight>
                    </Group>

                    {/* phone */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-phone">Телефон:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="phone"
                                className='form-input'
                                type="email"
                                id="sub-phone"
                                placeholder="Введите телефон"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register}
                            />
                        </ColRight>
                    </Group>

                    {/* page */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-page">Страница*:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name="page"
                                className="form-select"
                                id="sub-page"
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            >
                                <option value="">выберите страницу</option>
                                {data && data.roles.map((role) => <option value={role.id} selected={data?.fields?.role_id  === role.id}>{role.name}</option>)}
                            </select>
                            {errors.page && <p className="form-input-hint">{errors.page.message}</p>}
                        </ColRight>
                    </Group>

                    {/* warehouse_id */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="sub-warehouse-id">Магазин*:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name="warehouse_id"
                                className="form-select"
                                id="sub-warehouse-id"
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            >
                                <option value="">выберите магазин</option>
                                {data && data.roles.map((role) => <option value={role.id} selected={data?.fields?.role_id  === role.id}>{role.name}</option>)}
                            </select>
                            {errors.warehouse_id && <p className="form-input-hint">{errors.warehouse_id.message}</p>}
                        </ColRight>
                    </Group>


                    {/* Включать товар со склада сторонних поставщиков */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.checkbox__wrapper}>
                                <input
                                    className={s_Select.checkbox}
                                    type="checkbox"
                                    id="f-teg-umt1"
                                    name="teg_umt1"
                                    ref={register}
                                    defaultChecked={data?.fields?.teg_umt1}
                                />
                                <label htmlFor="f-teg-umt1" className={s_Select.label}>я соглашаюсь с пользовательским соглашением</label>
                            </div>
                        </ColFull>
                    </Group>

                </Form>

                {load && <Loader />}
            </div>
        </GridEditView>
    );
};

export default SubscriberEditView;
