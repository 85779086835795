/* eslint-disable */

export const reviews_cancel_variant = [
    {
        id: 0,
        title: 'Нецензурная лексика',
    },
    {
        id: 1,
        title: 'Политические высказывания',
    },
    {
        id: 2,
        title: 'Агрессия'
    },
    {
        id: 3,
        title: 'Оскорбления других пользователей',
    },
    {
        id: 4,
        title: 'Другая причина',
    },
];