import {
    getSortBannersTypes,
    getSortBannerMenu,
    removeSortBannerMenu,
    updateSortBannerMenu,
    refreshSortBannerMenu,
    editSortBannerMenu
} from "../../lib/api/sort";
import { getDomains } from "../../lib/api/domains";
import { getCatTree } from "../../lib/api/categories";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getSortBannersTypes(), getCatTree()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banner_types: res[1]?.value?.data,
        categories: res[2]?.value?.data
    }));

export const edit = editSortBannerMenu;

export const getData = getSortBannerMenu;
export const updateSort = updateSortBannerMenu;

export const refreshSort = refreshSortBannerMenu;
export const remove = removeSortBannerMenu;
