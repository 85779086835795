import React from "react";
import s from "./TopPanelNew.module.scss";

const TopInfo = ({ data }) => {
    return (
        <div className={s.top_info}>
            {!!data?.length && data?.map((info, index) => {
                if (info?.hidden) {
                    return null;
                }
                return (
                    <div className={s.top_info__item} key={`top-info-item-${index}`}>
                        <div className={s.top_info__title}>{info?.title}:</div>
                        <div className={s.top_info__value}>{info?.value}</div>
                    </div>
                )
            })}
        </div>
    );
};

export default TopInfo;