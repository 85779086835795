import React from 'react';
import cn from 'classnames';

// import s from "./TableDynamicFieldsBody.module.scss";

const TableDynamicFieldsBody = ({
  children,
  attrs,
}) => {
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className),
  };

  return (
    <tbody {...attrsMerge}>
      {children}
    </tbody>
  );
};

export default TableDynamicFieldsBody;
