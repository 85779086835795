import React, { useEffect, useState } from 'react';
import Button from "../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import errorMessage from "../../../lib/errorMessage";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import Group from "../../../components/ui/FormEditView/Group";
import s_input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import InputWrapper from "../../../components/ui/InputWrapper";
import Loader from "../../../components/ui/Loader";
import s_OrderStyle from "../../OrderEditView/fakeData/orderStyle.module.scss";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputMask from "react-input-mask";
import s from "../../OrderEditView/fakeData/orderStyle.module.scss";
import { editCooperationB2B, getCooperationB2BEdit } from "../../../lib/api/cooperation_b2b";

const ModalEditCooperationB2B = ({ active, setActive, filtersData, activeId, title }) => {
    const [data, setData] = useState({});
    const [load, setLoad] = useState(false);

    const sendData = async (status) => {
        const parseSendData = {
            id: +data?.id,
            name: data.name,
            phone: data.phone,
            email: data.email,
            company_name: data.company_name,
            company_hours: +data.company_hours,
            manager_comment: data.manager_comment,
            type: data.type,
            status: data.status,
            created_at: data.created_at,
            updated_at: data.updated_at,
            manager_id: +data.manager_id,
            domain_id: +data.domain_id
        };

        if (status) {
            parseSendData.status = status
        }

        await editCooperationB2B(activeId, parseSendData)
            .then((res) => window.location.reload())
            .catch(async (err) => await errorMessage(err, ''))
            .finally(() => setLoad(false));
    };

    const handlerOnChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    useEffect(() => {
        setLoad(true);
        getCooperationB2BEdit(activeId)
            .then((res) => {
                const parseRes = {
                    id: res?.data?.id,
                    name: res.data.name,
                    phone: res.data.phone,
                    email: res.data.email,
                    company_name: res.data.company_name,
                    company_hours: res.data.company_hours,
                    manager_comment: res.data.manager_comment,
                    type: res.data.type,
                    status: res.data.status,
                    created_at: res.data.created_at,
                    updated_at: res.data.updated_at,
                    manager_id: res.data.manager_id,
                    domain_id: res.data.domain_id
                };

                setData(parseRes);
            })
            .catch(async (err) => await errorMessage(err, ''))
            .finally(() => setLoad(false));
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                {!!Object.keys(data)?.length ? (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div className={s_Modal.modal__title}>{title}</div>
                        </div>

                        <div className={s_Modal.modal__box}>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper
                                        label="ПІБ"
                                        id="cooperation-b2b-name"
                                    >
                                        <input
                                            className={s_Input.input}
                                            type="text"
                                            placeholder="ПІБ"
                                            maxLength={INPUT_MAX_LENGTH}
                                            name="name"
                                            id="cooperation-b2b-name"
                                            defaultValue={data?.name}
                                            onChange={handlerOnChange}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper label='Телефон' id='cooperation-b2b-phone'>
                                        <InputMask mask="+389999999999" value={data?.phone} onChange={handlerOnChange}>
                                            {(inputProps) =>
                                                <input
                                                    className={s_input.input}
                                                    type='phone'
                                                    placeholder='Телефон'
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    name='Телефон'
                                                    id='cooperation-b2b-phone'
                                                    value={data?.phone}
                                                    onChange={handlerOnChange}
                                                    {...inputProps}
                                                />
                                            }
                                        </InputMask>
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper
                                        label="Email"
                                        id="cooperation-b2b-email"
                                    >
                                        <input
                                            className={s_Input.input}
                                            type="email"
                                            placeholder="Email"
                                            maxLength={INPUT_MAX_LENGTH}
                                            name="email"
                                            id="cooperation-b2b-email"
                                            defaultValue={data?.email}
                                            onChange={handlerOnChange}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper
                                        label="Назва компанії"
                                        id="cooperation-b2b-name-company"
                                    >
                                        <input
                                            className={s_Input.input}
                                            type="text"
                                            placeholder="Назва компанії"
                                            maxLength={INPUT_MAX_LENGTH}
                                            name="company_name"
                                            id="cooperation-b2b-name-company"
                                            defaultValue={data?.company_name}
                                            onChange={handlerOnChange}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <InputWrapper
                                        label="Кількість робітників"
                                        id="cooperation-b2b-company-hours"
                                    >
                                        <input
                                            className={s_Input.input}
                                            type="number"
                                            placeholder="Кількість робітників"
                                            maxLength={INPUT_MAX_LENGTH}
                                            name="company_hours"
                                            id="cooperation-b2b-company-hours"
                                            defaultValue={data?.company_hours}
                                            onChange={handlerOnChange}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name="type"
                                                className={s_OrderStyle.select}
                                                value={data?.type}
                                                id='cooperation-b2b-application-type'
                                                onChange={handlerOnChange}
                                            >
                                                <option value="">---</option>
                                                {!!filtersData?.types?.length && filtersData?.types?.map((type, index) => (
                                                    <option key={`cooperation-b2b-type-${index}`} value={type.value}>{type.title}</option>
                                                ))}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`cooperation-b2b-application-type`}>Тип заявки</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull attrs={{ style: { padding: 0 } }}>
                                    <div className={s.textarea__wrapper}>
                                        <textarea
                                            name="manager_comment"
                                            className={s.textarea}
                                            id="cooperation-b2b-manager-comment"
                                            placeholder="Комментар"
                                            rows="4"
                                            defaultValue={data?.manager_comment}
                                            maxLength={INPUT_MAX_LENGTH}
                                            onChange={handlerOnChange}
                                        />
                                        <label className={s.label__textarea} htmlFor="cooperation-b2b-manager-comment">Комментар</label>
                                    </div>
                                </ColFull>
                            </Group>
                        </div>
                    </>
                ) : load && <Loader />}

                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div className={s_Modal.btn_group}>
                            <Button green onClick={() => sendData('completed')}>Оброблено</Button>
                            <Button red onClick={() => sendData('canceled')}>Відхилена</Button>
                            <Button purple onClick={() => sendData()}>Зберегти</Button>
                        </div>
                        <div className={s_Modal.cancel} onClick={() => setActive(false)}>Відміна</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditCooperationB2B;