import { parseAll } from '../../lib/api/_parseApi';
import {
    editReviews, getReviewsEdit, removeReviews, getReviewsStatuses, getReviewsVisit,
} from '../../lib/api/reviews';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (reviewId) => Promise.allSettled([reviewId
&& getReviewsEdit(reviewId), getReviewsStatuses(), reviewId && getReviewsVisit(reviewId)])
    .then(async (res) => {
        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if (findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        return ({
            fields: reviewId && res[0]?.value?.data,
            statuses: res[1]?.value?.data,
            editInfo: res[2]?.value?.data,
        })
    });

export const edit = editReviews;
export const remove = removeReviews;

export const parseDataSend = (data, catId) => {
  let d = { ...data };
  const isUpdate = typeof catId !== 'undefined';

  d = parseAll(d, !isUpdate, []);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
