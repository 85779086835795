import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import getSplicedData from '../../lib/crud/getSplicedData';
import { getData, remove, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListView/Table";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import TableListViewRowLoader from "../../components/common/TableListView/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListView/Row";
import getTZtoDate from "../../lib/getTZtoDate";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";


const AdminsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        filtersData,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    })
    return(
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Admins edit`} />
            <TableListViewTable>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>ФИО</th>
                    <th>Имейл</th>
                    <th>Телефон</th>
                    <th>Идентификатор в Бинотел</th>
                    <th>Статус активности</th>
                    <th>Роль в системе </th>
                    <th>Дата регистрации</th>
                    <th> </th>
                </tr>

                <TableListViewFiltersRow
                    urlParams={urlParams}
                    fields={[
                        {},
                        { type: 'text', name: 'name' },
                        { type: 'text', name: 'email' },
                        { type: 'text', name: 'phone' },
                        { type: 'text', name: 'internal_phone' },
                        { type: 'select', name: 'status', options: filtersData && filtersData?.statuses && filtersData?.statuses, optProps: ["value", "title"]  },
                        { type: 'select', name: 'roles', options: filtersData && filtersData?.roles && filtersData?.roles?.map(b => ({...b, title: b.name}))  },
                        { },
                        { },
                    ]}
                    onSubmit={pushUrlParams}
                />
                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={7} />
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`admin-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Админ  удален',
                                    }}
                                    editLink={`/app/admin/update/${e.id}`}
                                    permissionRemove='admin.admin.destroy'
                                    permissionEdit='admin.admin.edit'
                                >
                                    <td>
                                        {checkPermission('admin.admin.edit')
                                            ? <Link to={`/app/admin/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>{e?.name}</td>
                                    <td>{e?.email}</td>
                                    <td>{e?.phone}</td>
                                    <td>{e?.internal_phone ? e?.internal_phone : '---'}</td>
                                    <td>{e?.status ? e?.status : ''}</td>
                                    <td>
                                        {!!e?.roles?.length ?
                                            e?.roles?.map((role, index) => <div key={`order-item-${index}`}>{role},</div>)
                                            : '--'
                                        }
                                    </td>
                                    <td>{e?.created_at ? getTZtoDate(e?.created_at) : '--'}</td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7} />
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>

            {!load && (
                <>
                    {+rest?.meta?.total && (
                        <TableListViewFooter
                            left={(
                                <TableListViewPagination
                                    length={+rest?.meta?.last_page}
                                    current={+rest?.meta?.current_page}
                                    total={+rest?.meta?.total}
                                    perPage={+rest?.meta?.per_page}
                                    loc={location}
                                />
                            )}
                            right={(
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                />
                            )}
                            rightCn="col-auto"
                        />
                    )}
                </>
            )}
        </div>
    )
}
export default AdminsList