import {Link, useLocation, useNavigate} from 'react-router-dom';
import React from 'react';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import { getData } from './api';
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const ContactList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Contact list`} />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Название УКР</th>
            <th>Название РУ</th>
            <th>Адрес УКР</th>
            <th>Адрес РУ</th>
            <th>Телефоны</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={7} />
            )
            : (rest && Object.keys(rest).length !== 0 && (
                  <TableListViewRow
                    id={rest.id}
                    key={`contact-item-${rest.id}`}
                    editLink={`/app/contact/update/${rest.id}`}
                    permissionRemove='admin.static-page.destroy'
                    permissionEdit='admin.static-page.edit'
                    deleteButton={false}
                  >
                    <td>
                      {checkPermission('admin.static-page.edit')
                          ? <Link to={`/app/contact/update/${rest.id}`}>{rest.id}</Link>
                          : <span>{rest.id}</span>
                      }
                    </td>
                    <td>{rest.email}</td>
                    <td>{rest.address?.map(a => <li>{a.title_ua}</li>)}</td>
                    <td>{rest.address?.map(a => <li>{a.title_ru}</li>)}</td>
                    <td>{rest.address?.map(a => <li>{a.street_ua}</li>)}</td>
                    <td>{rest.address?.map(a => <li>{a.street_ru}</li>)}</td>
                    <td>{rest.phones?.map(p => <li>{p.phone}</li>)}</td>
                  </TableListViewRow>
                )
            )}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default ContactList;
