import cn from 'classnames';
import React from 'react';

// import s from "./TableDynamicFieldsRowH.module.scss";

const TableDynamicFieldsRowH = ({ children, attrs, withoutControls = false }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(attrs && attrs.className),
  };

  return (
    <tr {...attrsMerge}>
      {children}

      {/* need for controls */}
      {!withoutControls && <th> </th>}
    </tr>
  );
};

export default TableDynamicFieldsRowH;
