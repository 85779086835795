import React, {
    forwardRef, useEffect, useRef, useState,
} from "react";
import cn from "classnames";
import Cropper from "cropperjs";
import get from "lodash/get";
import { ERROR_FILE_SIZE_INVALID } from "../../../const/text";
import parseStrPat from "../../../lib/parseStrPat";
import getImgUrl from "../../../lib/helpers/getImgUrl";
import loadFile from "../../../lib/helpers/loadFile";
import getMbToBytes from "../../../lib/helpers/getMbToBytes";

import s from "./InputFile.module.scss";
import s_Button from "../Button/Button.module.scss";
import downloadIcon from "../../../assets/icons/icon_download.svg";
import icon_delete from "../../../assets/icons/icon_delete.svg";
/* eslint-disable */
// @todo: isolate from react-hook-form
const InputFile = forwardRef(({
                                  name,
                                  val,
                                  defVal,
                                  formH,
                                  sm,
                                  onClear,
                                  acceptStr,
                                  accept,
                                  // crop,
                                  sizeMB = 5,
                                  className,
                                  maxWidth = 1920,
                                  maxHeight = 1080,
                                  removeBtn = true,
                                  addDomainUrl = true,
                                  setLocalValueArray,
                                  arrayIndex,
                                  labelDownload = 'Завантажити файл',
                                  width = 150,
                                  height = 150,
                                  maxSize,
                                  objectFit = 'cover',
                                  bottomText
                              }, ref) => {
    const cropRef = useRef(null);
    const [imgUrl, setImgUrl] = useState(null);

    // init crop
    useEffect(() => {
        if (!cropRef.current) return;

        const cr = new Cropper(cropRef.current, {
            // aspectRatio: crop[0] / crop[1],
            autoCrop: false,
            ready() {
                this.cropper.getCroppedCanvas().toBlob((blob) => {
                    loadFile(blob, (url) => {
                        formH.setValue(name, url);
                        if (setLocalValueArray) {
                            setLocalValueArray(prev => {
                                const temp = [...prev];
                                temp[arrayIndex]['img'] = url;
                                return temp;
                            });
                        }
                    }, acceptStr);
                });
            },
        });

        return () => {
            cr.destroy();
        };
    }, [cropRef, imgUrl]);

    async function fileHandler(e) {
        const fList = e.target.files;
        if (!fList.length) return;

        let reader = new FileReader();
        reader.onload = function (e) {
            let img = document.createElement('img');
            img.onload = function () {
                if (maxWidth < this.width || maxHeight < this.height) {
                    alert(`Допустимый размер картинки: ${maxWidth}x${maxHeight}`);
                }
            };
            img.src = e.target.result;
        }
        reader.readAsDataURL(fList[0]);

        // sizeMB validation
        if (sizeMB && fList[0].size > getMbToBytes(sizeMB)) {
            alert(parseStrPat(ERROR_FILE_SIZE_INVALID, {n: '5мб'}));
            return;
        }

        // load file to server
        await loadFile(fList[0], (url) => {
            formH.setValue(name, url);
            if (setLocalValueArray) {
                setLocalValueArray(prev => {
                    const temp = [...prev];
                    temp[arrayIndex]['img'] = url
                    return temp;
                });
            }
        }, acceptStr);
    }

    function clearHandler() {
        if (onClear) onClear();
        formH.setValue(name, '');
        if (setLocalValueArray) {
            setLocalValueArray(prev => {
                const temp = [...prev];
                temp[arrayIndex]['img'] = '';
                return temp;
            });
        }
    }

    const [showComp, setShowComp] = useState('');

    const Block = () => {
        return (
            <div className={s.button_delete}>
                <button
                    className={`btn btn-action s-circle ${s_Button.btn_danger}`}
                    type="button"
                    onClick={clearHandler}
                >
                    <img src={icon_delete} alt="icon delete" />
                </button>
            </div>
        )
    }
    const handleLeave = () => setShowComp('');
    const handleHover = () => setShowComp(Block());

    return (
        <div className={className} onMouseOver={handleHover} onMouseLeave={handleLeave}>
            <div className={cn(s.wrap,
                {[s.sizeSm]: sm})}
            >

                {/* crop */}
                {imgUrl && (
                    <div className={s.cropBox}>
                        <img src={addDomainUrl ? getImgUrl(imgUrl) : imgUrl} alt="" ref={cropRef}/>
                    </div>
                )}
                {/* end crop */}

                {/* @todo: !!don't use input hidden (bug RHF with useFieldArray) */}
                <input
                    className={s.inputHidden}
                    type="text"
                    name={name}
                    ref={ref}
                    {...defVal && {defaultValue: defVal}}
                />

                <div
                    className={cn('', s.imgBox,
                        {
                            [s.imgBoxError]: !!get(formH.errors, name),
                            [s.imgBox__no_border]: val
                        }
                    )}
                    style={{ width, height }}
                >
                    <input
                        type="file"
                        onChange={fileHandler}
                        accept={accept}
                    />

                    {val
                        ? (
                            <img src={addDomainUrl ? getImgUrl(val) : val} alt="" style={{ objectFit }}/>
                        )
                        : sm
                            ? <div className={s.add_img}><img src={downloadIcon} alt="download"/></div>
                            : (
                                <>
                                    <div className={s.add_img}>
                                        <img src={downloadIcon} alt="download"/>
                                        <div className={s.add_img__text}>{labelDownload}</div>
                                    </div>
                                    {bottomText && (
                                        <div className={s.bottom_text}>{bottomText}</div>
                                    )}
                                </>
                            )}
                    {maxSize && (
                        <div className={s.add_img__size}>{maxSize}</div>
                    )}

                    {val && showComp}

                </div>
            </div>
        </div>
    );
});

export default InputFile;
