import React, { useState } from 'react';
import Button from "../../../../components/ui/Button";
import s from './modal.module.scss';
import errorMessage from "../../../../lib/errorMessage";
import InputRadio from "../../../../components/ui/InputRadio";
import { reviews_cancel_variant } from "../../../../lib/const/reviews";
import s_orderStyle from "../../../OrderEditView/fakeData/orderStyle.module.scss";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import { editReviews } from "../../../../lib/api/reviews";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ModalCancel = ({ active, setActive, title, id }) => {
    const navigate = useNavigate();

    const [load, setLoad] = useState(false);
    const [cancelVariant, setCancelVariant] = useState(reviews_cancel_variant);
    const [sendVariant, setSendVariant] = useState(reviews_cancel_variant[0]);
    const [sendData, setSendData] = useState({
        status: 'rejected',
        reply: 'Нецензурная лексика',
    });

    const handleChange = (e) => setSendData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
    }));

    const cancelReview = () => {
        editReviews(id, sendData)
            .then(res => {
                toast('Отзыв опубликован', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate('/app/reviews');
            })
            .catch(async (err) => await errorMessage(err, `api/admin/social/reviews/${id} PUT`))
            .finally(() => setLoad(false));
    };

    return (
        <div
            className={active ? `${s.modal} ${s.active}` : s.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s.modal__header}>
                    <div className={s.modal__title}>{title}</div>
                    <span>Выберите причину отклонения отзыва:</span>
                </div>

                <div>
                    {!!cancelVariant?.length && cancelVariant.map(variant => {
                        const checkValue = variant?.id === sendVariant?.id;
                        return (
                            <div className={s.radio__item} key={variant?.id}>
                                <InputRadio
                                    name={`variant-${sendVariant?.id}`}
                                    id={`variant`}
                                    onChange={() => {
                                        setSendVariant(variant);
                                        setSendData(prev => ({
                                            ...prev,
                                            reply: variant?.title
                                        }))
                                    }}
                                    defaultChecked={checkValue}
                                    label={variant?.title}
                                    valueInput={variant?.title}
                                />
                            </div>
                        );
                    })}
                    {sendVariant?.id === 4 && (
                        <div className={`${s_orderStyle.textarea__wrapper} ${s.mt_15}`}>
                                    <textarea
                                        name="reply"
                                        className={s_orderStyle.textarea}
                                        id="r-reply"
                                        placeholder="Укажите причину"
                                        rows="4"
                                        value={sendData?.reply}
                                        onChange={handleChange}
                                        maxLength={INPUT_MAX_LENGTH}
                                    />
                            <label className={s_orderStyle.label__textarea} htmlFor="r-reply">Укажите причину</label>
                        </div>
                    )}
                </div>

                <div className={s.modal__footer}>
                    <Button red onClick={cancelReview}>Отклонить отзыв</Button>
                </div>
            </div>
        </div>
    );
};

export default ModalCancel;