import React from "react";
import cn from "classnames";
import s from "./Block.module.scss";

const Block = ({ title, children, style, styleContent, classNameWrapper }) => {
    return (
        <div className={cn(s.block,{
            [classNameWrapper]: classNameWrapper
        })} style={style}>
            <div className={s.block__title}>{title}</div>
            <div className={s.block__content} style={styleContent}>
                {children}
            </div>
        </div>
    );
};

export default Block;