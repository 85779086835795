import {
  SET_LAYOUT_CLASSNAME,
} from './action';

export const initialSate = {
  pageCn: 'initial',
};

const reducer = (state = initialSate, action) => {
  switch (action.type) {
  case SET_LAYOUT_CLASSNAME:
    return {
      ...state,
      pageCn: action.str,
    };

  default:
    return state;
  }
};

export default reducer;
