import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData, remove } from "./api";
import { WAREHOUSE_TYPES, wareHouseTypes } from "../../lib/const/warehouseTypes";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const WarehouseList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    filtersData,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Warehouse list`} />
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[ 'ID', 'Номер складу', 'Назва УКР', 'Назва РУС', 'Тип складу', 'Домен', 'Статус активності' ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            { },
                            { type: 'text', name: 'number' },
                            { type: 'text', name: 'title_ua' },
                            { type: 'text', name: 'title_ru' },
                            { type: 'select', name: 'type', options: WAREHOUSE_TYPES },
                            { type: 'select', name: 'domain_id', options: filtersData?.domains },
                            { type: 'select', name: 'is_active', options: [
                                    {
                                        id: 0,
                                        title: 'Не активна',
                                    },
                                    {
                                        id: 1,
                                        title: 'Активна',
                                    },
                                ] },
                            { },
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (<TableListViewRowLoader cols={7} />)
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e?.id}
                                    key={`wh-item-${e?.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Склад видалено',
                                    }}
                                    editLink={`/app/warehouse/update/${e?.id}`}
                                    permissionRemove='admin.warehouse.destroy'
                                    permissionEdit='admin.warehouse.edit'
                                >
                                    <td>
                                        {checkPermission('admin.warehouse.edit')
                                            ? <Link to={`/app/warehouse/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                            {e?.number || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.title_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.title_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.type ? wareHouseTypes(e?.type) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.domain?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7}>Немає складів</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default WarehouseList;
