import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import TopPanel from "./Components/TopPanel/TopPanel";
import s from "./review.module.scss";
import { ModalCancel } from "./Components/Modal";
import ReviewUser from "./Components/ReviewUser/ReviewUser";
import ReviewModerator from "./Components/ReviewModerator/ReviewModerator";
import ReviewHistory from "./Components/ReviewHistory/ReviewHistory";
import { getReviewsVisit } from "../../lib/api/reviews";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import { Helmet } from "react-helmet";
import { checkVisitOrderEdit } from "../../lib/api/order";
import errorMessage from "../../lib/errorMessage";
import {refreshToken} from "../../lib/crud/refreshToken";

const ReviewEditView = () => {
  const { id: reviewID } = useParams();
  const navigate = useNavigate();

  const { load, data } = useEdit({
    targetID: reviewID,
    api: {
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/review/not-found',
      remove: `/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      edit: 'Отзыв обновлен',
      remove: 'Отзыв удален',
    },
  });

  const [activeCancelModal, setActiveCancelModal] = useState(false);
  const [replySend, setReplySend] = useState('');
  const [bodySend, setBodySend] = useState('');

  const [checkEdit, setCheckEdit] = useState(false);
  const [editManager, setEditManager] = useState(null);

  useEffect(() => {
    if(data?.fields?.data?.reply) {
      setReplySend(data?.fields?.data?.reply);
    }

    if(data?.fields?.data?.body) {
      setBodySend(data?.fields?.data?.body);
    }

    if(data?.editInfo) {
      setCheckEdit(data?.editInfo?.canEdit);
      setEditManager(data?.editInfo);
      delete data.editInfo;
    }

  }, [data]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      getReviewsVisit(reviewID).then(res => {
        setCheckEdit(res?.data?.canEdit);
        setEditManager(res?.data);
      }).catch(async (err) => {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            await checkVisitOrderEdit(reviewID).then(res => {
              setCheckEdit(res?.data?.canEdit);
              setEditManager(res?.data);
            });
          })
        } else {
          await errorMessage(err, '')
        }
      });
    }, 5000);

    return(() => {
      clearInterval(intervalId)
    })
  }, [checkEdit]);

/* eslint-disable */
  return (
      <div>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Review edit`} />
        {data?.fields ? (
           <>
             <Form>
               <TopPanel
                   breadcrumbs={{
                     items: [
                       {
                         title: 'Головна',
                         url: '/app',
                       },
                       {
                         title: 'Отзывы',
                         url: `/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                       },
                       {
                         title: `Отзыв #${reviewID}`,
                       },
                     ]
                   }}
                   data={data}
                   id={reviewID}
                   setModalCancel={setActiveCancelModal}
                   sendData={replySend}
                   bodySend={bodySend}
                   checkEdit={checkEdit}
                   editManager={editManager}
                   editBtn
               />

               <div className={s.wrapper__block}>
                 <ReviewUser data={data} bodySend={bodySend} setBodySend={setBodySend} />
                 <ReviewModerator data={data} replySend={replySend} setReplySend={setReplySend} checkEdit={checkEdit} />
                 <ReviewHistory id={reviewID} data={data} />
               </div>

             </Form>

             {activeCancelModal && (
                 <ModalCancel active={activeCancelModal} setActive={setActiveCancelModal} title={`Отклонить отзыв #${reviewID}`} id={reviewID} />
             )}
           </>
        ) : load && <Loader />}
    </div>
  );
};

export default ReviewEditView;
