import cn from 'classnames';
import React from 'react';
import s from './ColRight.module.scss';

const ColRight = ({ children, col = 8, attrs }) => {
  const attrsMerge = {
    ...attrs,
    className: cn(`column col-${col} col-sm-12 ${s.block}`, attrs && attrs.className),
  };

  return (
    <div {...attrsMerge}>{children}</div>
  );
};

export default ColRight;
