import { useEffect, useState } from 'react';
import { getSpecOfferBannersTag } from '../api/specOfferBannersTag';

/* eslint-disable */
const useTags = ({
  defaultValues = {},
}) => {
  const [tagID, setTagID] = useState(null);

  const [tagsList, setTagsList] = useState([]);

  const [tagsPage, setTagsPage] = useState(2);

  const _init = () => {
    // set tags
    if (defaultValues?.tags !== undefined) {
      defaultValues?.tags.length !== 0 && defaultValues?.tags.map((el) => {
        setTagID((prev) => {
          if (prev != null) {
            setTagID([...prev, el.id]);
          } else {
            setTagID([el.id]);
          }
        });
      });
    }
  };

  useEffect(() => {
    _init();
  }, [defaultValues]);

  const getTagsID = (e) => {
    const elem = e?.target;
    const parent_id = elem.parentElement.id;
    const options = document.querySelector(`#${parent_id}`)?.querySelectorAll('option');

    const temp_arr = [];
    options?.length !== 0 && Array.from(options).forEach((el) => {
      if (el.selected) {
        temp_arr.push(+el.value);
      }
    });

    setTagID(temp_arr);
  };

  const fetchTags = (params = {}) => {
    getSpecOfferBannersTag({
      tags: tagID,
      per_page: 100,
      ...params,
    }).then((r) => {
      if (r?.data?.data.length != 0) {
        setTagsList((prev) => {
          const arr = [...prev, ...r?.data?.data];
          setTagsList(arr);
        });
      }

      if (Object.keys(params).length != 0) {
        setTagsPage(tagsPage + 1);
      }
    });
  };

  useEffect(() => {
        setTagsList([]);
        setTagsPage(2);
        fetchTags();
  }, []);

  const loadMore = async (e, type) => {
    const scrollTopMax = e?.target.scrollHeight - (e?.target.scrollTop - 2) <= e?.target.offsetHeight; // we take scroll of element and decrease border height

    if (scrollTopMax) {
        await fetchTags({
          page: tagsPage,
        });
    }
  };

  const resetScroll = (name) => {
    const select = document.querySelector(`#${name}`);
    if (select !== null) {
      select.scrollTop = select.scrollHeight;
    }
  };

  useEffect(() => {
    if (tagsList.length > 10) {
      resetScroll('credit-offer-banner-tags');
    }
  }, [tagsList]);

  return {
    actionTag: {
      getTagsID,
      loadMore,
    },
    stateTag: {
      tagsList,
    },
  };
};

export default useTags;
