import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import {
  edit, getData, parseDataGet, parseDataSend,
} from './api';
import MainContainer from "./MainContainer";
import ProductsList from "./fakeData/productsList";
import ItemsContainer from "./ItemsContainer";
import UserProfile from "./fakeData/userProfile";
import OrderCommentHistory from "./fakeData/orderCommentHistory";
import OrderPreviousOrders from "./fakeData/orderPreviousOrders";
import OrderDelivery from "./fakeData/orderDelivery";
import OrderParamsInfo from "./fakeData/orderParamsInfo";
import OrderCreditInfo from "./fakeData/orderCreditInfo";
import OrderUserInfo from "./fakeData/orderUserInfo";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const InCartEditView = () => {
  const { id: inCartID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: inCartID,
    updateFields: reset,
    handleSubmit,
    api: {
      edit,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/in-cart/not-found',
      remove: `/app/in-carts${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/in-carts${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      edit: 'В корзине обновлено',
    },
  });

  const formProps = {
    inCartID,
    data,
    handleSubmit,
    submitHandler,
    formH,
    load
  };

  /* eslint-disable */
  return (
      <MainContainer submitHandler={submitHandler} handleSubmit={handleSubmit}>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - In cart edit`} />
        {data ? <ProductsList formProps={formProps} /> : <Loader />}
        <ItemsContainer>

          <div>
            {data ? <UserProfile formProps={formProps} data={data.fields} /> : <Loader />}
            {data ? <OrderCommentHistory formProps={formProps} /> : <Loader />}
            {data ? <OrderPreviousOrders formProps={formProps} /> : <Loader />}
          </div>
          <div>
            {data ? <OrderDelivery formProps={formProps} /> : <Loader />}
          </div>
          <div>
            {data ? <OrderParamsInfo formProps={formProps} /> : <Loader />}
          </div>
          <div>
            {data ? <OrderCreditInfo formProps={formProps} /> : <Loader />}
          </div>
          <div>
            {data ? <OrderUserInfo formProps={formProps} /> : <Loader />}
          </div>

        </ItemsContainer>
      </MainContainer>
  );
};

export default InCartEditView;
