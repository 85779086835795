import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import s from './SelectLocalInput.module.scss';
import { VALIDATION } from '../../../const/text';

const SelectLocalInput = ({
  name = '', addName = '', list = [], data = [], formH = {}, placeholder = '', defVal = [], req, activeAll = false,
}) => {
  const selectInputRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [selectedItemList, setSelectedItemList] = useState([]);
  /* eslint-disable */
  // useEffect(() => {
  //   setSelectedItemList([]);
  // }, [list]);

  useEffect(() => {
    if (defVal && defVal.length !== 0) {
      const _t = setTimeout(() => setSelectedItemList(defVal), 100);
      return clearTimeout(_t);
    }
  }, [defVal]);

  useEffect(() => {
    defVal.length !== 0 && setSelectedItemList(defVal);
  }, [defVal]);

  useEffect(() => {
    if(req && !selectedItemList?.length) {
      formH.setError(`${name}[0]${addName}`, "This field is required");
    } else {
      formH.clearErrors(`${name}[0]${addName}`);
    }
  }, [selectedItemList]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!selectInputRef?.current?.contains(e.target)) setIsActive(false);
    });

    return document.removeEventListener('click', () => {
    });
  }, []);

  const renderList = () => {
    if (searchList.length !== 0) {
      return searchList.map((el, i) => (
        <div
          key={`search-${name}-list-item-key-${i}`}
          className={s.select_input_locale__search__item}
          onClick={() => addItem(el)}
        >
          {el.title || el.title_ru || el.title_ua || el.name}
        </div>
      ));
    }
    return list.length !== 0 && list.map((el, i) => (
      <div
        key={`search-${name}-list-item-key-${i}`}
        className={s.select_input_locale__search__item}
        onClick={() => addItem(el)}
      >
        {el.title || el.title_ru || el.title_ua  || el.name}
      </div>
    ));
  };

  const handleClick = () => setIsActive(!isActive);
  const handleChange = (e) => {
    const temp = [];
    if (list.length !== 0) {
      list.filter((el) => {
        const title = el.title_ru?.toLowerCase() || el.title_ua?.toLowerCase() || el.title?.toLowerCase() || el.name?.toLowerCase();
        if (title.includes(e.target.value.toLowerCase())) {
          temp.push(el);
        }
        return null;
      });
    }
    return setSearchList(temp);
  };

  const addItem = (el) => {
    const temp = [...selectedItemList];
    const index = temp.find((i) => i.id === el.id);
    if (!index) {
      temp.push(el);
      setSelectedItemList(temp);
    }
    setIsActive(false);
  };

  const addAllItem = (obj) => {
    if(selectedItemList.length >= 1) {
      setSelectedItemList([]);
      setIsActive(false);
    } else {
      setIsActive(false);
      setSelectedItemList(obj);
    }
  };

  const removeItem = (el) => {
    const temp = [...selectedItemList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setSelectedItemList(temp);
    }
  };

  return (
    <div className={s.select_input_locale__container} ref={selectInputRef}>
      <input
        className="form-input"
        placeholder={placeholder}
        onClick={handleClick}
        onChange={handleChange}
      />
      {isActive && (
          <div className={s.select_input_locale__search}>
            {activeAll && (
                <div
                    className={s.select_input_locale__search__item}
                    onClick={() => addAllItem(list)}
                >
                  Выбрать все
                </div>
            )}
            {renderList()}
          </div>
      )}
      <div className={s.select_input_locale__labels}>
        {selectedItemList && selectedItemList.length !== 0 ? selectedItemList.map((el, k) => {
          const title = el.title_ru || el.title_ua || el.title || el.name;
          if (title) {
            return (
              <div
                key={`selected-${name}-list-item-key-${k}`}
                onClick={() => removeItem(el)}
                className={`${s.select_input_locale__label__item} label mb-2`}
              >
                <input
                  type="text"
                  className={req
                    ? cn('form-input',
                      { 'is-error': formH?.errors?.[`${name}[${k}]${addName}`] })
                    : 'form-input'}
                  style={{ display: 'none' }}
                  name={`${name}[${k}]${addName}`}
                  value={el.id}
                  ref={req ? formH.register({ required: VALIDATION.req.required }) : formH.register}
                  readOnly
                />
                <span>{title}</span>
              </div>
            );
          }
          return <></>;
        }) : (
            <input
                type="text"
                className={req
                    ? cn("form-input",
                        { "is-error": formH?.errors?.[`${name}[0]${addName}`] })
                    : "form-input"}
                style={{ display: "none" }}
                name={`${name}[0]${addName}`}
                value=""
                ref={req ? formH.register({ required: VALIDATION.req.required }) : formH.register}
                readOnly
            />
        )}

        {/* TODO: Need remove this after test stage*/}
        {/*data?.[name]?.map((el, k) => {*/}
        {/*  const title = el.title_ru || el.title_ua || el.title || el.name;*/}
        {/*  if (title) {*/}
        {/*    return (*/}
        {/*      <div*/}
        {/*        key={`selected-${name}-list-item-key-${k}`}*/}
        {/*        onClick={() => removeItem(el)}*/}
        {/*        className={`${s.select_input_locale__label__item} label mb-2`}*/}
        {/*      >*/}
        {/*        <input*/}
        {/*          type="text"*/}
        {/*          style={{ display: 'none' }}*/}
        {/*          name={`${name}[${k}]${addName}`}*/}
        {/*          value={el.id}*/}
        {/*          ref={formH.register}*/}
        {/*          readOnly*/}
        {/*        />*/}
        {/*        <span>{title}</span>*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  }*/}
        {/*  return <></>;*/}
        {/*})}*/}
      </div>
    </div>
  );
};

export default SelectLocalInput;
