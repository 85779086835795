// @todo
export const wareHouseTypes = (type) => {
  return WAREHOUSE_TYPES.find(({id}) => id.toString() === type.toString())?.title
};

export const WAREHOUSE_TYPES = [
  {
    id: 1,
    title: 'Собственный склад',
  },
  {
    id: 2,
    title: 'Розничный магазин',
  },
  {
    id: 3,
    title: 'Склад поставщика',
  },
  {
    id: 4,
    title: 'Пункт самовывоза',
  },
  {
    id: 5,
    title: 'Виртуальный склад',
  },
];
