export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `<h5 class="h5-native d-flex align-center justify-content-center mb-2 mb-lg-4"><span>Заголовок H5</span></h5>`,
    })
};