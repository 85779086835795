import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const BanksList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banks list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                          {+rest?.total && (
                              <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    loc={location}
                                    navigate={navigate}
                                />
                                <TableListViewPerPage
                                    urlParams={urlParams}
                                    onSelect={(val, params) => pushUrlParams(params)}
                                    total={+rest?.total}
                                />
                              </>
                          )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
              <TableHead
                arrayChildren={[
                  'ID', 'Назва УКР', 'Назва РУС', 'Банк ID', 'Статус активності'
                ]}
              />
              <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`bank-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Банк видалено',
                                    }}
                                    editLink={`/app/bank/update/${e.id}`}
                                    permissionRemove='admin.bank.destroy'
                                    permissionEdit='admin.bank.edit'
                                >
                                    <td>
                                        {checkPermission('admin.bank.edit')
                                            ? <Link to={`/app/bank/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title_ua || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title_ru || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e?.tf_bank_id || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                      </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                  <td colSpan={5}>Немає банків</td>
                                </TableListViewRow>
                            )
                    ))}
              </tbody>
            </TableListViewTable>
        </div>
    );
};

export default BanksList;
