import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
import {
  IM_CREATE, IM_DELETE, IM_EDIT, IM_GET, IM_GET_EDIT,
} from '../../const/api';

export const getIM = (params) => fetchAx({
  url: IM_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getIMAll = () => fetchAx({
  url: IM_GET,
  method: 'GET',
});

export const createIM = (data) => fetchAx({
  url: IM_CREATE,
  method: 'POST',
  data,
});

export const getIMEdit = (id) => fetchAx({
  url: IM_GET_EDIT.join(id),
  method: 'GET',
});

export const editIM = (id, data) => fetchAx({
  url: IM_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeIM = (id) => fetchAx({
  url: IM_DELETE.join(id),
  method: 'DELETE',
});
