/* eslint-disable */
/**
 * Parse All Fields
 *
 * @param obj
 * @param removeEmpty
 * @param toNumbArr
 * @returns {Object}
 */
export const parseAll = (obj, removeEmpty, toNumbArr) => {
  const res = { ...obj };

  for (const [prop, val] of Object.entries(res)) {
    // to number if need
    if (toNumbArr && toNumbArr.includes(prop)) res[prop] = _parseApi.toInt(val);

    // remove empty string
    if (removeEmpty && typeof res[prop] === 'string' && !res[prop]) delete res[prop];
  }

  return res;
};

/**
 * Parse All Fields by Array
 *	remove if return empty object
 *
 * @param obj
 * @param arr {*[]}
 * @param removeEmpty
 * @returns {*}
 */
const parseAllArr = (obj, arr, removeEmpty) => {
  const res = { ...obj };
  arr.forEach((e) => {
    res[e.name] = res[e.name].map((obj) => _parseApi.parseAll(obj, e.removeEmt || removeEmpty, e.numArr)).filter((resMap) => !!Object.keys(resMap).length);
  });

  return res;
};

const parseArrAllNumber = (obj, nameArr) => {
  const res = { ...obj };
  const objKeys = res[nameArr].map((itemObj, indexObj) => Object.keys(res[nameArr][indexObj]));
  res[nameArr] = res[nameArr]?.map((itemArr, index) => {
    const temp = {...itemArr};
    objKeys[index].forEach((key) => {
      temp[key] = +temp[key];
    })
    return temp;
  })

  return res;
};

/**
 * Parse val to integer
 * if NaN -> return ''
 *
 * @param val
 * @param emptyStr
 * @returns {string|number}
 */
export const toInt = (val, emptyStr = true) => {
  // let res = parseInt(val, 10);
  let res = +val;
  if (emptyStr) res = isNaN(res) ? '' : res;

  return res;
};

// @todo
// export const toIntArr = (arrTarget, obj, removeEmpty = false) => {
// 	const copyObj = {...obj};
//
// 	arrTarget.forEach((prop) => {
// 		copyObj[prop] = parseInt(copyObj[prop], 10);
//
// 		if ( removeEmpty && isNaN(copyObj[prop]) ) delete copyObj[prop];
// 	});
//
// 	return copyObj;
// }

const _parseApi = {
  parseAll,
  parseAllArr,
  toInt,
  parseArrAllNumber,
};
export default _parseApi;
