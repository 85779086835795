import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getArticleEdit } from "../../../lib/api/articles";
import errorMessage from "../../../lib/errorMessage";

const LinkToAnotherPage = ({ title, api }) => {
    const { id } = useParams();
    const [data, setData] = useState('');

    useEffect(() => {
        const fetchLink = async () => {
            try {
                const res = await getArticleEdit(id);
                const view_url = res.data.data;

                setData(view_url);
            } catch (error) {
                await errorMessage(err, '')
            }
        };

        fetchLink();
    }, [id]);

    return (
        <a href={data.view_url} className="btn btn-primary" style={{color: "white", textDecoration: "none", display: data?.type === 'news' ? "flex" : "none"}}>
            {title}
        </a>
    );
};

export default LinkToAnotherPage;
