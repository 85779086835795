import { uid } from "react-uid";
import cn from "classnames";
import React from "react";

import s from "./SideNavEditViewNew.module.scss";

const SideNavEditViewNew = ({
  items, onClick, checkActiveCb, checkErrorCb,
}) => {
  function clickHandle(e, i) {
    if (onClick) onClick(i);
  }

  return (
      <ul className={s.tab}>
          {items.map((item, index) => {
              const active = checkActiveCb(index);
              const error = checkErrorCb(index);

              return (
                  <li
                      className={cn(s.tab__item, {
                        ['active']: active
                      })}
                      key={uid(item)}
                  >
                      <button
                          type="button"
                          className={cn('', {
                                'text-error': error,
                                [s.tab__line]: active
                              })}
                          onClick={(e) => {
                              clickHandle(e, index);
                              if(item.onClick) {
                                  item.onClick()
                              }
                          }}
                      >
                          {item.title}
                          {error && <span className="bg-error" />}
                      </button>
                  </li>
              );
          })}
      </ul>
  );
};

export default SideNavEditViewNew;
