import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import ModalQuickFilter from "./Modal/ModalQuickFilter";

const QuickFilterList = () => {
    const [activeModal, setActiveModal] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Quick filter list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={['ID', 'Назва УКР', 'Назва РУС', 'ЧПУ УКР', 'ЧПУ РУС']}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'title_ua'},
                            {type: 'text', name: 'title_ru'},
                            {},
                            {},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (<TableListViewRowLoader cols={6}/>)
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e?.id}
                                    key={`spec-category-item-${e?.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Швидкий фільтр видалено',
                                    }}
                                    editLink={`/app/quick-filter/update/${e?.id}`}
                                    permissionRemove='admin.quick-filter.destroy'
                                    permissionEdit='admin.quick-filter.edit'
                                    setActiveModal={setActiveModal}
                                    setActiveId={setActiveId}
                                    tagA={false}
                                >
                                    <td>
                                        {checkPermission('admin.quick-filter.edit')
                                            ? <Link to={`/app/quick-filter/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                width: e?.title_ua?.length > 30 ? '150px' : 'auto',
                                                wordWrap: 'break-word',
                                                display: e?.title_ua?.length > 30 ? '-webkit-box' : 'block',
                                                '-webkit-box-orient': e?.title_ua?.length > 30 ? 'vertical' : 'inherit'
                                            }}
                                        >
                                            {e?.title_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                width: e?.title_ru?.length > 30 ? '150px' : 'auto',
                                                wordWrap: 'break-word',
                                                display: e?.title_ru?.length > 30 ? '-webkit-box' : 'block',
                                                '-webkit-box-orient': e?.title_ru?.length > 30 ? 'vertical' : 'inherit'
                                            }}
                                        >
                                            {e?.title_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                width: e?.url_ua?.length > 30 ? '150px' : 'auto',
                                                wordWrap: 'break-word',
                                                display: e?.url_ua?.length > 30 ? '-webkit-box' : 'block',
                                                '-webkit-box-orient': e?.url_ua?.length > 30 ? 'vertical' : 'inherit'
                                            }}
                                        >
                                            {e?.url_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            style={{
                                                width: e?.url_ru?.length > 30 ? '150px' : 'auto',
                                                wordWrap: 'break-word',
                                                display: e?.url_ru?.length > 30 ? '-webkit-box' : 'block',
                                                '-webkit-box-orient': e?.url_ru?.length > 30 ? 'vertical' : 'inherit'
                                            }}
                                        >
                                            {e?.url_ru || '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={6}>Немає швидких фільтрів</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>

            {activeModal && (
                <ModalQuickFilter
                    title="Редагувати швидкий фільтр"
                    active={activeModal}
                    setActive={setActiveModal}
                    editId={activeId}
                />
            )}
        </div>
    );
};

export default QuickFilterList;
