import React, {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import cn from 'classnames';
import Cropper from 'cropperjs';
import get from 'lodash/get';
import { ERROR_FILE_SIZE_INVALID } from '../../../const/text';
import parseStrPat from '../../../lib/parseStrPat';
import getImgUrl from '../../../lib/helpers/getImgUrl';
import loadFile from '../../../lib/helpers/loadFile';
import getMbToBytes from '../../../lib/helpers/getMbToBytes';

import s from './InputFile.module.scss';
import icon_delete from "../../../assets/icons/icon_delete.svg";
/* eslint-disable */
// @todo: isolate from react-hook-form
const InputFile = forwardRef(({
  name,
  val,
  defVal,
  formH,
  sm,
  onClear,
  acceptStr,
  accept,
  // crop,
  sizeMB = 5,
  className,
  maxWidth = 1920,
  maxHeight = 1080,
  removeBtn = true,
  addDomainUrl = true,
  border = false,
  placeHolder,
}, ref) => {
  const cropRef = useRef(null);
  const [imgUrl, setImgUrl] = useState(null);

  // init crop
  useEffect(() => {
    if (!cropRef.current) return;

    const cr = new Cropper(cropRef.current, {
      // aspectRatio: crop[0] / crop[1],
      autoCrop: false,
      ready() {
        this.cropper.getCroppedCanvas().toBlob((blob) => {
          loadFile(blob, (url) => {
            formH.setValue(name, url);
          }, acceptStr);
        });
      },
    });

    return () => {
      cr.destroy();
    };
  }, [cropRef, imgUrl]);

  async function fileHandler(e) {
    const fList = e.target.files;
    if (!fList.length) return;

    let reader = new FileReader();
    reader.onload = function(e) {
      let img = document.createElement('img');
      img.onload = function() {
        if(maxWidth < this.width || maxHeight < this.height) {
          alert(`Допустимый размер картинки: ${maxWidth}x${maxHeight}`);
        }
      };
      img.src = e.target.result;
    }
    reader.readAsDataURL(fList[0]);

    // sizeMB validation
    if (sizeMB && fList[0].size > getMbToBytes(sizeMB)) {
      alert(parseStrPat(ERROR_FILE_SIZE_INVALID, { n: '5мб' }));
      return;
    }

    // load file to server
    await loadFile(fList[0], (url) => {
      formH.setValue(name, url);
    }, acceptStr);
  }

  function clearHandler() {
    if (onClear) onClear();
    formH.setValue(name, '');
  }

  return (
    <div className={className}>
      <div className={cn(s.wrap, {
          [s.sizeSm]: sm,
          [s.border]: border
        })}
      >

        {/* crop */}
        {imgUrl && (
          <div className={s.cropBox}>
            <img src={addDomainUrl ? getImgUrl(imgUrl) : imgUrl} alt="" ref={cropRef} />
          </div>
        )}
        {/* end crop */}

        {/* @todo: !!don't use input hidden (bug RHF with useFieldArray) */}
        <input
          className={s.inputHidden}
          type="text"
          name={name}
          ref={ref}
          {...defVal && { defaultValue: defVal }}
        />

        <div
          className={cn(s.imgBox,
            // {[s.imgBoxError]: !!formH.errors[name]}
            {
              [s.imgBoxError]: !!get(formH.errors, name),
              [s.img]: val
            })}
          style={(val && border) ? { padding: 0, border: 0, overflow: 'hidden' } : {}}
        >
          <input
            type="file"
            onChange={fileHandler}
            accept={accept}
          />

          {removeBtn && val && (
              <div className={s.control}>
                <button
                    className={s.remove}
                    type="button"
                    onClick={clearHandler}
                >
                  <img src={icon_delete} alt="icon delete" />
                </button>
              </div>
          )}

          {val
            ? (
                <img src={addDomainUrl ? getImgUrl(val) : val} alt="" />
            )
            : (
                <>
                  <i className="icon icon-download" />
                  <div>{placeHolder ? placeHolder : 'Завантажити зображення'}</div>
                </>
              )}
        </div>
      </div>
    </div>
  );
});

export default InputFile;
