import React, { useEffect, useState } from 'react';
import { VALIDATION } from '../../../const/text';
import cn from "classnames";
import s_Input from "../InputWrapper/InputWrapper.module.scss";

const InputDateNew = ({
                          defVal = '',
                          name,
                          id,
                          className,
                          formH,
                          placeholder,
                          withZero = true,
                          required= true,
                          custom = false,
                          disabled = false,
                          min
}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(defVal);
  }, [defVal]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const dateName = `${name}-dop`

  return (
    <>
        <div className={s_Input.wrapper}>
            <div className={s_Input.input__wrapper}>
              <input
                id={id}
                className={required
                  ? cn(s_Input.input, {
                    'is-error': formH.errors[dateName],
                  })
                  : `form-input ${className}`}
                type="date"
                value={value}
                ref={required ? formH.register({
                  required: VALIDATION.req.required,
                }): formH.register}
                name={dateName}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
                min={min}
              />
                <label className={s_Input.label} htmlFor={id}>{placeholder}</label>
            </div>
            {!custom
                ? formH.errors[dateName] && <p className="form-input-hint text-error">{formH.errors[dateName].message}</p>
                : null
            }
        </div>
      <input
        type="text"
        id={`${id }-2`}
        name={name}
        value={`${value }${withZero ? ' 00:00:00' : ''}`}
        style={{ display: 'none' }}
        ref={required ? formH.register({
          required: VALIDATION.req.required,
        }): formH.register}
        readOnly
      />
    </>
  );
};

export default InputDateNew;
