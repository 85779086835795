import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  createProductFeedback,
  getProductFeedbackEdit,
  editProductFeedback,
  removeProductFeedback,
} from '../../lib/api/product_feedback';
import { getCitiesAdmin } from "../../lib/api/cities";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (pfId) => Promise.allSettled([getDomains(), pfId
&& getProductFeedbackEdit(pfId), getCitiesAdmin()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: pfId && res[1]?.value?.data,
      cities: res[2]?.value?.data
    })
  });

export const create = createProductFeedback;
export const edit = editProductFeedback;
export const remove = removeProductFeedback;

export const parseDataSend = (data, pfId) => {
  let d = { ...data };
  const isUpdate = typeof pfId !== 'undefined';

  d = parseAll(d, !isUpdate, [
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
