import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {VALIDATION} from '../../const/text';
import {INPUT_MAX_LENGTH} from '../../const/view';

import s from './deviceEdit.module.scss';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {create, edit, getData, parseDataGet, parseDataSend, remove,} from './api';
import InputDate from '../../components/ui/InputDate';
import InputFile from "../../components/ui/InputFile";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import rangeArrObjs from "../../lib/rangeArrObjs";

const DevicesViewEdit = () => {
    const {id: devID} = useParams();
    const formH = useForm();
    const [selectedCategory, setSelectedCategory] = useState({title: 'Виберіть категорію товару'})

    const {
        register,
        errors,
        handleSubmit,
        reset,
        watch,
    } = formH;

    const {load, data, submitHandler} = useEdit({
        targetID: devID,
        updateFields: reset,
        handleSubmit,
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            get: parseDataGet,
            send: parseDataSend,
        },
        redirects: {
            notFound: '/app/device/not-found',
            remove: `/app/devices${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/devices${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Устройство создано',
            edit: 'Устройство обновлено',
            remove: 'Устройство удалено',
        },
    });

    function findCategoryById(categories, categoryId, level = '--') {
        for (const category of categories) {
            if (category.id === categoryId) {
                return {...category, level};
            }
            if (category.children) {
                const result = findCategoryById(category.children, categoryId, level + '--');
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    useEffect(() => {
        if (!!data?.categories[0]?.children.length && !!data?.fields?.category_id) {
            const result = findCategoryById(data?.categories[0]?.children, data?.fields?.category_id);
            if (result) {
                setSelectedCategory(result);
            }
        }
    }, [data])

    /* eslint-disable */
    return (
        <GridEditView>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Device edit`}/>
            <div className="box-wrap">
                {devID
                    ? (
                        <Title>
                            Устройство -
                            <small>{data && data.fields.title_ru}</small>
                        </Title>
                    )
                    : <Title>Новое устройство</Title>}

                <Form attrs={{
                    onSubmit: handleSubmit(submitHandler),
                }}
                >
                    <button type="submit" className="d-hide"/>

                    {/* title_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-title-ua">Название устройства УКР*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ua"
                                className={cn('form-input', {
                                    'is-error': errors.title_ua,
                                })}
                                type="text"
                                id="dev-title-ua"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
                        </ColRight>
                    </Group>

                    {/* title_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-title-ru">Название устройства РУ*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="title_ru"
                                className={cn('form-input', {
                                    'is-error': errors.title_ru,
                                })}
                                type="text"
                                id="dev-title-ru"
                                placeholder="Введите текст"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={register({
                                    required: VALIDATION.req.required,
                                })}
                            />
                            {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
                        </ColRight>
                    </Group>

                    {/* category */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="category-select">Категория:</label>
                        </ColLeft>
                        <ColRight>
                            <select
                                name={`category_id`}
                                className={s.select__category}
                                id={`category_id`}
                                ref={formH.register({required: VALIDATION.select.required})}
                            >
                                <option value={selectedCategory.id}>{selectedCategory?.level && selectedCategory?.level} {selectedCategory.title}</option>
                                {data?.categories && rangeArrObjs(data?.categories, 'children', (e, i, iArr, depth) => {
                                    const nest = new Array(depth).join('--');
                                    const disable = e.children.length > 0 && e.children?.find(item => !item?.is_hidden);

                                    if (+e.domain_id === 1) {
                                        return (
                                            <option
                                                key={iArr.join('-')}
                                                value={e.id}
                                                disabled={disable}
                                            >
                                                {`${nest} ${e.title}`}
                                            </option>
                                        );
                                    }

                                    return null
                                })}
                            </select>
                            {errors.category_id && <p className="form-input-hint">{errors.category_id.message}</p>}
                        </ColRight>
                    </Group>

                    {/* starts_at */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-starts-at">Сроки от:</label>
                        </ColLeft>
                        <ColRight>
                            <InputDate
                                name="starts_at"
                                className="form-input"
                                id="dev-starts-at"
                                defVal={data?.fields?.starts_at.split(" ")[0]}
                                placeholder="Выберите дату"
                                formH={formH}
                            />
                            {errors.starts_at && <p className="form-input-hint">{errors.starts_at.message}</p>}
                        </ColRight>
                    </Group>

                    {/* finish_at */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-finish-at">Сроки до:</label>
                        </ColLeft>
                        <ColRight>
                            <InputDate
                                name="finish_at"
                                className="form-input"
                                id="dev-finish-at"
                                defVal={data?.fields?.finish_at.split(" ")[0]}
                                placeholder="Выберите дату"
                                formH={formH}
                            />
                            {errors.finish_at && <p className="form-input-hint">{errors.finish_at.message}</p>}
                        </ColRight>
                    </Group>

                    {/* page_banner */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-page-banner">Банер на странице
                                консультации:</label>
                            <div>Максимальное разрешение картинки 1920x1080</div>
                        </ColLeft>
                        <ColRight>
                            <InputFile
                                name="page_banner"
                                val={watch('page_banner')}
                                formH={formH}
                                ref={register}
                                acceptStr="PNG, JPG, GIF"
                                accept="image/*"
                                crop={[168, 198]}
                                sizeMB={1}
                            />
                        </ColRight>
                    </Group>

                    {/* logo_banner */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-logo-banner">Банер в списке консультаций:</label>
                            <div>Максимальное разрешение картинки 1920x1080</div>
                        </ColLeft>
                        <ColRight>
                            <InputFile
                                name="logo_banner"
                                val={watch('logo_banner')}
                                formH={formH}
                                ref={register}
                                acceptStr="PNG, JPG, GIF"
                                accept="image/*"
                                crop={[168, 198]}
                                sizeMB={1}
                            />
                        </ColRight>
                    </Group>

                    {/* is_active */}
                    <Group>
                        <ColLeft/>
                        <ColRight>
                            <label className="form-switch">
                                <input
                                    name="is_active"
                                    type="checkbox"
                                    id="dev-status-active"
                                    ref={register}
                                />
                                <i className="form-icon"/>
                                Статус активности
                            </label>
                        </ColRight>
                    </Group>

                    {/* desc_ua */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-desc-ua">
                                Текст на странице консультации УКР:
                            </label>
                        </ColLeft>
                        <ColRight>
              <textarea
                  name="desc_ua"
                  className="form-input"
                  id="dev-desc-ua"
                  placeholder="Введите текст"
                  rows="4"
                  ref={register}
              />
                        </ColRight>
                    </Group>

                    {/* desc_ru */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-desc-ru">
                                Текст на странице консультации РУ:
                            </label>
                        </ColLeft>
                        <ColRight>
              <textarea
                  name="desc_ru"
                  className="form-input"
                  id="dev-desc-ru"
                  placeholder="Введите текст"
                  rows="4"
                  ref={register}
              />
                        </ColRight>
                    </Group>

                    {/* position */}
                    <Group>
                        <ColLeft>
                            <label className="form-label" htmlFor="dev-position">Сортировка*:</label>
                        </ColLeft>
                        <ColRight>
                            <input
                                name="position"
                                className='form-input'
                                type="number"
                                id="dev-position"
                                placeholder="Введите значение"
                                ref={register}
                            />
                        </ColRight>
                    </Group>

                </Form>

                {load && <Loader/>}
            </div>
        </GridEditView>
    );
};

export default DevicesViewEdit;
