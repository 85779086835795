export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.desc_text_custom > span {
                font-size: 18px;
                line-height: 24px;
            }
            
            div.small_text_custom > span {
                font-size: 14px;
                line-height: 16px;
            }
            
            h2.h2-native {
                font-size: 48px !important;
                line-height: 1.2 !important;
                font-weight: bold !important;
            }
            
            div.col-title-four-custom > div.row {
                align-items: flex-start;
            }
            
            @media screen and (max-width: 992px) {
                h2.h2-native {
                    font-size: 34px !important;
                }
            }
            
            @media screen and (max-width: 576px) {
                h2.h2-native {
                    font-size: 28px !important;
                }
                
                div.small_text_custom > span {
                    font-size: 11px;
                    line-height: 13px;
                }
                
                div.desc_text_custom > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row d-flex flex-column text-center col-title-four-custom">
                <div class="mb-2 mb-lg-4">
                    <h2 class="h2-native"><span>Заголовок H2</span></h2>
                </div>
                <div class="row row-cols-1 row-cols-sm-4">
                    <div class="col mb-4 mb-sm-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                        <div class="desc_text_custom d-flex mb-2 mb-lg-4 mt-2 mt-lg-4"><span class="text-center">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-center">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3.</span></div>
                    </div>
                    <div class="col mb-4 mb-sm-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                        <div class="desc_text_custom d-flex mb-2 mb-lg-4 mt-2 mt-lg-4"><span class="text-center">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-center">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3.</span></div>
                    </div>
                    <div class="col mb-4 mb-sm-0">
                        <img src="https://via.placeholder.com/300" alt="" />
                        <div class="desc_text_custom d-flex mb-2 mb-lg-4 mt-2 mt-lg-4"><span class="text-center">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-center">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3.</span></div>
                    </div>
                    <div class="col">
                        <img src="https://via.placeholder.com/300" alt="" />
                        <div class="desc_text_custom d-flex mb-2 mb-lg-4 mt-2 mt-lg-4"><span class="text-center">Детали на экране Dynamic AMOLED 2X выглядят совершенно, независимо от уровня яркости.</span></div>
                        <div class="small_text_custom d-flex"><span class="text-center">*Экран Galaxy S23 Ultra - Dynamic AMOLED 2X с Vision Booster сертифицирован VDE Germany за охват 100% мобильного цветового объема в диапазоне DCI-P3.</span></div>
                    </div>
                </div>
            </div>
            
            ${style}
        `,
    })
};