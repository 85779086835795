import {
  ROLES_GET,
  ROLES_CREATE,
  ROLES_DELETE,
  ROLES_GET_EDIT,
  ROLES_EDIT,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getRoles = (params) => fetchAx({
  url: ROLES_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    page: 1,
    ...params
  }
});

export const createRole = (data) => fetchAx({
  url: ROLES_CREATE,
  method: 'POST',
  data,
});

export const getRoleEdit = (id) => fetchAx({
  url: ROLES_GET_EDIT.join(id),
  method: 'GET',
});

export const editRole = (id, data) => fetchAx({
  url: ROLES_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeRole = (id) => fetchAx({
  url: ROLES_DELETE.join(id),
  method: 'DELETE',
});
