import {
  TOP_BOT_MENU_GET,
  TOP_BOT_MENU_CREATE,
  TOP_BOT_MENU_DELETE,
  TOP_BOT_MENU_GET_EDIT,
  TOP_BOT_MENU_EDIT,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getTopBottomMenu = (params) => fetchAx({
  url: TOP_BOT_MENU_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createTopBottomMenu = (data) => fetchAx({
  url: TOP_BOT_MENU_CREATE,
  method: 'POST',
  data,
});

export const getTopBottomMenuEdit = (id) => fetchAx({
  url: TOP_BOT_MENU_GET_EDIT.join(id),
  method: 'GET',
});

export const editTopBottomMenu = (id, data) => fetchAx({
  url: TOP_BOT_MENU_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeTopBottomMenu = (id) => fetchAx({
  url: TOP_BOT_MENU_DELETE.join(id),
  method: 'DELETE',
});
