import { parseAll } from "../../lib/api/_parseApi";

import {
  createRole,
  editRole,
  getRoleEdit,
  removeRole,
} from "../../lib/api/roles";
import { refreshToken } from "../../lib/crud/refreshToken";
import { getSections } from "../../lib/api/sections";

export const getData = (rId) => Promise.allSettled([rId && getRoleEdit(rId), getSections()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))
    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    const parseSections = (arrayChildren) => {

        return arrayChildren.map(item => {
            const temp = {...item};

            let filterArray = []

            if (!!temp?.permissions?.length) {
                temp?.permissions?.forEach((permissionItem) => {
                    const findIndexType = filterArray.findIndex(filterItem => filterItem?.type === permissionItem?.type);

                    if (findIndexType !== -1) {
                        filterArray[findIndexType].title = permissionItem?.type_title || permissionItem?.type;
                        filterArray[findIndexType].type = permissionItem?.type;
                        filterArray[findIndexType].items.push(permissionItem);
                    } else {
                        filterArray.push({
                            id: item.id,
                            type: permissionItem?.type,
                            title: permissionItem?.type_title,
                            items: [{...permissionItem}]
                        })
                    }
                })
            }

            if (!!filterArray?.length) {
                temp.typePermission = [...filterArray]
            } else {
                temp.typePermission = []
            }

            if (!!temp?.children) {
                temp.children = parseSections(temp.children)
            }

            return temp;
        })
    }

    return ({
      fields: rId && res[0]?.value?.data,
      sections: parseSections(res[1]?.value?.data?.data?.[0]?.children),
    })
  });

export const create = createRole;
export const edit = editRole;
export const remove = removeRole;

export const parseDataSend = (data, rId) => {
  let d = { ...data };
  const isUpdate = typeof rId !== 'undefined';

  if(!!d?.permissions?.length) {
    d['permission_ids'] = d?.permissions.map(permission => +permission.id);
  } else {
    d['permission_ids'] = [];
  }

  delete d?.permissions

  d = parseAll(d, !isUpdate, []);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
