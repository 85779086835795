import {
  CONSULTATIONS_TOPICS_GET, CONSULTATIONS_TOPIC_CREATE, CONSULTATIONS_TOPIC_GET_EDIT,
  CONSULTATIONS_TOPIC_EDIT, CONSULTATIONS_TOPIC_DELETE,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getTopics = (params) => fetchAx({
  url: CONSULTATIONS_TOPICS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    page: 1,
    ...params,
  },
});

export const getTopicsAll = (params) => fetchAx({
  url: CONSULTATIONS_TOPICS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxxl,
    page: 1,
    ...params,
  },
});

export const createTopic = (data) => fetchAx({
  url: CONSULTATIONS_TOPIC_CREATE,
  method: 'POST',
  data,
});

export const getTopicEdit = (id) => fetchAx({
  url: CONSULTATIONS_TOPIC_GET_EDIT.join(id),
  method: 'GET',
});

export const editTopic = (id, data) => fetchAx({
  url: CONSULTATIONS_TOPIC_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeTopic = (id) => fetchAx({
  url: CONSULTATIONS_TOPIC_DELETE.join(id),
  method: 'DELETE',
});