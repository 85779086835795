import { useEffect, useState, useCallback } from 'react';
import { getProductSearchList } from '../api/products';
import { getCatSearch } from '../api/categories';
import { getDomains } from '../api/domains';

/* eslint-disable */
const useDomainCategoriesProducts = ({
                                         defaultValues = {}, domainId
                                     }) => {
    const [domainID, setDomainID] = useState(null);
    const [categoriesID, setCategoriesID] = useState(null);
    const [categorySelectShow, setCategorySelectShow] = useState(null);
    const [categoryPriceType, setCategoryPriceType] = useState(null);

    const [domainsList, setDomainsList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const [productsList, setProductsList] = useState([]);

    const [productsOnData, setProductsOnData] = useState([]);
    const [productPage, setProductPage] = useState(2);

    /* AdditionalServices */
    const [productsOnPriceTypeData, setProductsOnPriceTypeData] = useState([]);

    const [productsOffData, setProductsOffData] = useState([]);

    const [fetchedAll, setFetchedAll] = useState(false);
    const [domainChanged, setDomainChanged] = useState(false);

    const _init = () => {
        // set domain id
        defaultValues?.domain_id && setDomainID(defaultValues?.domain_id);

        // set categories
        if (defaultValues?.categories !== undefined) {
            const parseCat = defaultValues?.categories.map(item => item.id);
            setCategoriesID(parseCat);
        }

        // set categories price_type
        if (defaultValues?.categories !== undefined) {
            const parseCatType = defaultValues?.categories.map(item => ({
                category_id: item.id,
                price_type: item?.pivot?.price_type || 'promo'
            }));
            setCategoryPriceType(parseCatType);
        }
    };

    const checker = useCallback((arr, target) => {
        return (target?.every(v => arr?.includes(v)))
    }, []);

    useEffect(() => {
        _init();
    }, [defaultValues]);

    useEffect(() => {
        if(domainId){
            setDomainID(domainId)
        }
    }, [domainId])

    const getCategoriesID = (array) => setCategoriesID(array);

    const getCategoryShow = (category) => setCategorySelectShow(category);

    const getCategoryPriceType = (category) => setCategoryPriceType(category);

    const getProductsOnID = (array) => setProductsOnData(array);

    const getProductsOnPriceType = (array) => setProductsOnPriceTypeData(array)

    const getProductsOffID = (array) => setProductsOffData(array);

    const getCategoriesAll = (categories) => setCategoriesID(categories);

    const getProductOnAll = (products) => setProductsOnData(products);

    const getProductOffAll = (products) => setProductsOffData(products);

    const getDomain = (e) => {
        setFetchedAll(false);
        setDomainChanged(true);
        setProductsList([]);
        setProductPage(2);
        return setDomainID(e?.target?.value);
    };

    const fetchDomains = () => {
        getDomains().then((r) => {
            if (r?.data.length !== 0) {
                setDomainsList((prev) => {
                    const arr = [...prev, ...r?.data];
                    setDomainsList(arr);
                });
            }
        });
    };

    const fetchCategories = (params = {}) => {
        if (!fetchedAll){
            getCatSearch({
                domain_id: domainID,
                ...params,
            }).then((r) => {
                if (r?.data?.length) {
                    setCategoriesList(r?.data);
                } else setFetchedAll(true)
            });
        }
    };

    const fetchProducts = (params = {}) => {
        if(categorySelectShow) {
            getProductSearchList({
                cat_ids: categorySelectShow,
                domain_id: domainID,
                ...params,
            }).then((r) => {
                if (Object.keys(params).length !== 0) {
                    setProductPage(productPage + 1);
                    setProductsList((prev) => {
                        const data = r.data.data.filter(el => !productsList.map(i => i.id).includes(el.id))
                        const arr = [...prev, ...data];
                        return arr;
                    })} else {
                    setProductPage(2);
                    setProductsList([ ...r.data.data]);
                }
            });
        } else {
            setProductsList([]);
        }
    };

    useEffect(() => {
        fetchDomains();
    }, []);

    useEffect(() => {
        if (categorySelectShow != null) {
            fetchProducts();
        }
    }, [categorySelectShow]);

    useEffect(() => {
        const parseProductOn = defaultValues?.products_turn_on?.map(item => item.id.toString());
        setProductsOnData(parseProductOn)
    }, [defaultValues?.products_turn_on]);

    useEffect(() => {
        const parseProductOn = defaultValues?.products_turn_on?.map(item => ({
            id: item?.id,
            price_type: item?.pivot?.price_type,
            category_id: item?.category_id,
        }));
        setProductsOnPriceTypeData(parseProductOn)
    }, [defaultValues?.products_turn_on]);

    useEffect(() => {
        const parseProductOff = defaultValues?.products_turn_off?.map(item => item.id.toString());
        setProductsOffData(parseProductOff)
    }, [defaultValues?.products_turn_off]);

    useEffect(() => {
        if(!domainChanged && categoriesID && !checker(categoriesList?.map(c=>c.id), categoriesID)){
            fetchCategories()
        }
    }, [categoriesID])


    useEffect(() => {
        if (domainID !== '') {
            if (domainID !== null) {
                setCategoriesList([]);
                fetchCategories();
            } else {
                setCategoriesList([]);
                setProductsList([]);
            }
        } else {
            setDomainID(null);
        }
    }, [domainID]);

    const loadMore = async (e, type) => {
        const scrollTopMax = e?.target.scrollHeight - e?.target.scrollTop <= e?.target.offsetHeight; // we take scroll of element and decrease border height

        if (scrollTopMax) {

            if (type === 'products_turn_on' || type === 'products_turn_off') {
                await fetchProducts({
                    page: productPage,
                });
            }
        }
    };

    const resetScroll = (name) => {
        const select = document.querySelector(`#${name}`);
        if (select !== null) {
            select.scrollTop = select.scrollHeight;
        }
    };

    useEffect(() => {
        if (productsList.length > 10) {
            resetScroll('im-product-turn-on' || 'credit-offer-product-turn-on');
        }
    }, [productsList]);


    return {
        action: {
            getDomain,
            getCategoriesID,
            getCategoryPriceType,
            getCategoriesAll,
            getProductOnAll,
            getProductOffAll,
            getProductsOnID,
            getProductsOffID,
            getCategoryShow,
            getProductsOnPriceType,
            loadMore
        },
        state: {
            domainID,
            domainsList,
            categoriesList,
            productsList,
            productsOnData,
            productsOnPriceTypeData,
            productsOffData,
            categoriesID,
            categoryPriceType,
            categorySelectShow
        }
    };
};

export default useDomainCategoriesProducts;
