import { getQF, removeQF } from '../../lib/api/quickFilter';

export const getFiltersData = () => Promise.allSettled([])
  .then(() => ({
    /* domains: res[0]?.value?.data,
       categories: res[1]?.value?.data */
  }));

export const getData = getQF;
export const remove = removeQF;
