import {
  CATEGORIES_MENU_GET,
  CATEGORIES_MENU_CREATE,
  CATEGORIES_MENU_DELETE,
  CATEGORIES_MENU_GET_EDIT,
  CATEGORIES_MENU_EDIT,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getCategoriesMenu = (params) => fetchAx({
  url: CATEGORIES_MENU_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createCategoriesMenu = (data) => fetchAx({
  url: CATEGORIES_MENU_CREATE,
  method: 'POST',
  data,
});

export const getCategoriesMenuEdit = (id) => fetchAx({
  url: CATEGORIES_MENU_GET_EDIT.join(id),
  method: 'GET',
});

export const editCategoriesMenu = (id, data) => fetchAx({
  url: CATEGORIES_MENU_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeCategoriesMenu = (id) => fetchAx({
  url: CATEGORIES_MENU_DELETE.join(id),
  method: 'DELETE',
});
