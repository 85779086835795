import React, {useEffect, useState} from 'react';
import s from "../../common/TableListView/Row/Row.module.scss";

const InputMulti = ({
  name,
  maxItems,
  form,
  defVal
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(!!defVal?.length){
      defVal.forEach(v => setItems((prev) => [...prev,
      <div className="form-group">
        <input
            className="form-input"
            type="text"
            name={`${name}[${prev.length}].phone`}
            ref={form.register}
            defaultValue={v}
        />
      </div>,
    ]))
    }
  }, [defVal])

  const addItem = () => {
    setItems((prev) => [...prev,
      <div className="form-group">
        <input
            className="form-input"
            type="text"
            name={`${name}[${prev.length}].phone`}
            ref={form.register}
        />
      </div>,
    ]);
  };
  const removeItem = (index) => {
    setItems((prev) => {
      const temp = [...prev];
      if (index !== -1) {
        temp.splice(index, 1);
        return temp;
      }
    });
  };
  return (
    <fieldset name={name} ref={form.register}>

      {/* eslint-disable-next-line react/no-array-index-key */}
      {items.map((el, i) => (
          <div style={{ display: "flex" }}>
            <div className='mb-2' key={`input-${name}-key-${i}`}>{el}</div>
            <button
                type="button"
                className={`btn btn-action ml-2 s-circle ${s.btn_danger}`}
                onClick={() => removeItem(i)}
            >
              <i className="icon icon-delete" />
            </button>
          </div>
      ))}
      {/* eslint-disable-next-line max-len */}
      {maxItems !== items.length && <div className="btn btn-primary" onClick={() => addItem()}>добавить</div>}
    </fieldset>
  );
};

export default InputMulti;
