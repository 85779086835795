import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import GridEditView from '../../components/ui/GridEditView';
import Title from '../../components/ui/FormEditView/Title';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import InputFile from '../../components/ui/InputFile';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const BanksEditView = () => {
  const { id: bankID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: bankID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/banks/not-found',
      remove: `/app/banks${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/banks${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Банк создан',
      edit: 'Банк обновлен',
      remove: 'Банк удален',
    },
  });
  /* eslint-disable */
  return (
    <GridEditView>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Bank edit`} />
      <div className="box-wrap">
        {bankID
          ? (
            <Title>
              Банк -
              <small>{data && data.fields.title_ru}</small>
            </Title>
          )
          : <Title>Новый банк</Title>}

        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide" />

          {/* title_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-name-ru">Название банка РУ*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ru"
                className={cn('form-input', {
                  'is-error': errors.title_ru,
                })}
                type="text"
                id="bank-name-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title_ru && <p className="form-input-hint">{errors.title_ru.message}</p>}
            </ColRight>
          </Group>

          {/* title_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-name-ua">Название банка УКР*:</label>
            </ColLeft>
            <ColRight>
              <input
                name="title_ua"
                className={cn('form-input', {
                  'is-error': errors.title_ua,
                })}
                type="text"
                id="bank-name-ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.title_ua && <p className="form-input-hint">{errors.title_ua.message}</p>}
            </ColRight>
          </Group>

          {/* img */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="img">Изображение банка*:</label>
              <div>Максимальное разрешение картинки 500x500</div>
            </ColLeft>
            <ColRight>
              <Controller
                as={InputFile}
                control={control}
                className={cn('form-input-file', {
                  'is-error': errors.title_ua,
                })}
                name="img"
                rules={{ required: VALIDATION.req.required }}
                val={watch('img')}
                formH={formH}
                acceptStr="PNG, JPG, GIF"
                acceptStr="PNG, JPG, GIF"
                accept="image/*"
                crop={[168, 198]}
                sizeMB={1}
                maxWidth={500}
                maxHeight={500}
              />
              {errors.img && <p className="form-input-hint">{errors.img.message}</p>}
            </ColRight>
          </Group>

          {/* bank_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="tf-bank-id">Банк ID:</label>
            </ColLeft>
            <ColRight>
              <input
                name="tf_bank_id"
                className={cn('form-input', {
                  'is-error': errors.tf_bank_id,
                })}
                type="number"
                id="tf-bank-id"
                placeholder="Введите чило"
                maxLength={INPUT_MAX_LENGTH}
                ref={register({
                  required: VALIDATION.req.required,
                })}
              />
              {errors.tf_bank_id && <p className="form-input-hint">{errors.tf_bank_id.message}</p>}
            </ColRight>
          </Group>

          {/* text_ru */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="text-ru">Подпись РУС:</label>
            </ColLeft>
            <ColRight>
              <textarea
                name="text_ru"
                className="form-input"
                type="text"
                id="text-ru"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* text_ua */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="text-ua">Подпись УКР:</label>
            </ColLeft>
            <ColRight>
              <textarea
                name="text_ua"
                className="form-input"
                type="text"
                id="text-ua"
                placeholder="Введите текст"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* sort_id */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="sort-id">Сортировка:</label>
            </ColLeft>
            <ColRight>
              <input
                name="sort"
                className="form-input"
                type="number"
                id="sort-id"
                placeholder="Введите число"
                maxLength={INPUT_MAX_LENGTH}
                ref={register}
              />
            </ColRight>
          </Group>

          {/* is_active */}
          <Group>
            <ColLeft>
              <label className="form-label" htmlFor="bank-active">Статус активности:</label>
            </ColLeft>
            <ColRight>
              <select
                name="is_active"
                className={cn('form-select', {
                  'is-error': errors.is_active,
                })}
                id="bank-active"
                ref={register({
                  required: VALIDATION.select.required,
                })}
              >
                <option value="">Выберите статус</option>
                <option value="0">Не активный</option>
                <option value="1">Активный</option>
              </select>
              {errors.is_active
                && <p className="form-input-hint">{errors.is_active.message}</p>}
            </ColRight>
          </Group>
        </Form>

        {load && <Loader />}
      </div>
    </GridEditView>
  );
};

export default BanksEditView;
