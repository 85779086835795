import axios from "axios";
import errorMessage from "../errorMessage";

/**
 * Load file
 *
 * @param url
 * @param fileName
 * @param setLoad
 * @returns {Promise<void>}
 */
const fileDownload = async (url, fileName = 'image.png', setLoad) => {
  const createLink = (res) => {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(res);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  }

  await axios.get(url, {
    responseType: 'blob',
  })
      .then((res) => createLink(res.data))
      .catch((err) => errorMessage(err, ''))
      .finally(() => {
        if (setLoad) {
          setLoad(false);
        }
      })
};

export default fileDownload;
