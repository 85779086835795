import { getArticles, removeArticle, getArticleTypes, getArticleStatus } from "../../lib/api/articles";

export const getFiltersData = () => Promise.allSettled([
    getArticleTypes(), getArticleStatus()
])
    .then((res) => ({
        types: res[0]?.value?.data,
        status: res[1]?.value?.data,
    }));

export const getData = getArticles;
export const remove = removeArticle;