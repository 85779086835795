import {
  HR_DOMAIN_MASK_GET,
  HR_DOMAIN_MASK_DELETE,
  HR_DOMAIN_MASK_CREATE,
  HR_DOMAIN_MASK_EDIT,
  HR_DOMAIN_MASK_GET_EDIT
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getHrDomainMask = (params) => fetchAx({
  url: HR_DOMAIN_MASK_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getHrDomainMaskEdit = (id) => fetchAx({
  url: HR_DOMAIN_MASK_GET_EDIT.join(id),
  method: 'GET',
});

export const editHrDomainMask = (id, data) => fetchAx({
  url: HR_DOMAIN_MASK_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeHrDomainMask = (id) => fetchAx({
  url: HR_DOMAIN_MASK_DELETE.join(id),
  method: 'DELETE',
});
