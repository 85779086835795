import grapesjs from "grapesjs";
import loadComponents from "./components";
import loadBlock from "./blocks";

export default grapesjs.plugins.add('accordionDiamondComponent', (editor, opts) => {
    let options = {
        label: 'Accordion diamond',
        name: 'caccordionDiamond',
        category: 'Custom',
    };

    for(let name in options) {
        if(!(name in opts)) opts[name] = options[name];
    }

    loadBlock(editor, opts);
    loadComponents(editor, opts);
})