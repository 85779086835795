import { parseAll } from '../../lib/api/_parseApi';
import {
  createRedirect, editRedirect, getRedirectEdit, removeRedirect,
} from '../../lib/api/redirects';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (redirectId) => Promise.allSettled([redirectId
&& getRedirectEdit(redirectId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      fields: redirectId && res[0]?.value?.data,
    })
  });

export const create = createRedirect;
export const edit = editRedirect;
export const remove = removeRedirect;

export const parseDataSend = (data, redirectId) => {
  let d = { ...data };
  const isUpdate = typeof redirectId !== 'undefined';

  d = parseAll(d, !isUpdate, ['status']);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
