import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";
import {
  createBenefit, editBenefit, getBenefitEdit, removeBenefit,
} from "../../lib/api/benefits";
import { refreshToken } from "../../lib/crud/refreshToken";

export const getData = (benefitId) => Promise.allSettled([getDomains(), benefitId
&& getBenefitEdit(benefitId)])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    return ({
      domains: res[0]?.value?.data,
      fields: benefitId && res[1]?.value?.data,
    })
  });

export const create = createBenefit;
export const edit = editBenefit;
export const remove = removeBenefit;

export const parseDataSend = (data, benefitId) => {
  let d = { ...data };
  const isUpdate = typeof benefitId !== 'undefined';

  d = parseAll(d, !isUpdate, [
    'has_gift',
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };
  const toStr = [
    // 'dimension_type',
    // 'document_type'
  ];

  toStr.forEach((p) => {
    d[p] = `${d[p] }`;
  });

  return d;
};
