import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import cn from 'classnames';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import { getMedia, removeMedia } from "../../lib/api/media";
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import { ModalMedia } from "./Modal";
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import ModalRemove from "../../components/ui/Modal/ModalRemove";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "./BannerManagementHome.module.scss";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import ImageLoad from "../../components/ui/ImageLoad";
import errorMessage from "../../lib/errorMessage";

const BannerManagementHomeEditView = () => {
  const { id: bmhID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: bmhID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/banner-management/home/not-found',
      remove: `/app/banner-management/home${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/banner-management/home${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Банер головної сторінки створено',
      edit: 'Банер головної сторінки оновлено',
      remove: 'Банер головної сторінки видалено',
    },
  });

  const [activeMedia, setActiveMedia] = useState(false);
  const [activeRemove, setActiveRemove] = useState(false);
  const [typeCoverDataUa, setTypeCoverDataUa] = useState([]);
  const [typeCoverDataRu, setTypeCoverDataRu] = useState([]);
  const [typeMobileDataUa, setTypeMobileDataUa] = useState([]);
  const [typeMobileDataRu, setTypeMobileDataRu] = useState([]);

  const [dataModal, setDataModal] = useState({});

  const [date, setDate] = useState({
    date_from: '',
    date_to: '',
  });
  const [time, setTime] = useState({
    time_from: '00:00:00',
    time_to: '23:59:59'
  });

  useEffect(() => {
    if(data?.fields?.date_from || data?.fields?.date_to) {
      setDate({
        date_from: data?.fields?.date_from?.split(' ')[0],
        date_to: data?.fields?.date_to?.split(' ')[0],
      });

      setTime({
        time_from: data?.fields?.date_from?.split(' ')[1],
        time_to: data?.fields?.date_to?.split(' ')[1],
      });
    }
  }, [data]);

  useEffect(() => {
    if(bmhID) {
      getMedia({
        'entity_type': 'promo_main_page',
        'entity_id': bmhID,
        page: 1,
        per_page: 500,
      }).then(res => {
        const filterLang = (lang) => res?.data?.data?.filter(item => item?.custom_properties?.locale === lang);
        const filterLangRu = filterLang('ru');
        const filterCoverRu = filterLangRu?.filter(item => item?.collection_name === 'cover');

        if(filterCoverRu) {
          setTypeCoverDataRu(filterCoverRu);
        }

        const filterMobileRu = filterLangRu?.filter(item => item?.collection_name === 'mobile');
        if(filterMobileRu) {
          setTypeMobileDataRu(filterMobileRu);
        }

        const filterLangUa = filterLang('ua');
        const filterCoverUa = filterLangUa?.filter(item => item?.collection_name === 'cover');

        if(filterCoverUa) {
          setTypeCoverDataUa(filterCoverUa);
        }

        const filterMobileUa = filterLangUa?.filter(item => item?.collection_name === 'mobile');
        if(filterMobileUa) {
          setTypeMobileDataUa(filterMobileUa);
        }

      }).catch(err => errorMessage(err, '/api/admin/media GET'))
    }

  }, [bmhID]);

  /* eslint-disable */
  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Banner home edit`} />
        <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

          <button type="submit" className="d-hide" />

          <TopPanelChild
              title={bmhID ? data && data?.fields?.title_ua : "Новий банер головної сторінки"}
              breadcrumbs={{
                items: [
                  {
                    title: "Головна",
                    url: "/app",
                  },
                  {
                    title: "Банер головна сторінка",
                    url: `/app/banner-management/home${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: bmhID ? data && data?.fields?.title_ua: "Новий банер головної сторінки"
                  },
                ],
              }}
              back={`/app/banner-management/home${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              id={bmhID}
              children={<div className={s.top__item}>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="b-is-active"
                    label="Активність"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </div>}
              btnEdit
          />

          {!load ? (
              <div className={s.top}>
                <div className={s.top__input}>
                  {/* domain_id */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          {data
                              ? (
                                  <select
                                      name="domain_id"
                                      className={cn(s_Select.select, {
                                        [s_Select.select_error]: formH.errors?.domain_id,
                                      })}
                                      id="b-domain"
                                      ref={formH.register({
                                        required: VALIDATION.select.required,
                                      })}
                                  >
                                    <option value="">виберіть домен</option>
                                    {data && !!data.domains && data.domains.map((domain) => (
                                        <option
                                            key={domain.id}
                                            value={domain.id}
                                            selected={domain.id === data?.fields?.domain_id}
                                        >
                                          {domain.title}
                                        </option>
                                    ))}
                                  </select>
                              )
                              : (
                                  <div className="p-relative">
                                    <div className="loading" />
                                  </div>
                              )}
                          <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                        </div>
                        {formH.errors?.domain_id
                        && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>

                  {/* date_from */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="date_from"
                              id="bml-date_form"
                              placeholder="Термін дії від"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.date_from}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                date_from: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-date_form">Термін дії від</label>
                        </div>
                        {errors?.date_from
                        && <p className="form-input-hint">{errors?.date_from?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_from"
                              id="bml-time_from"
                              placeholder="Час"
                              ref={register}
                              value={time.time_from}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_from: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_from">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* date_to */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="date_to"
                              id="bml-date_to"
                              placeholder="Термін дії до"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.date_to}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                date_to: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-date_to">Термін дії до</label>
                        </div>
                        {errors?.date_to
                        && <p className="form-input-hint">{errors?.date_to?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_to"
                              id="bml-time_to"
                              placeholder="Час"
                              ref={register}
                              value={time.time_to}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_to: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="bml-time_to">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* position */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Позиція"
                          id="bml-position"
                          errorComponent={errors?.position &&
                          <p className="form-input-hint">{errors?.position?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.position,
                            })}
                            type="text"
                            placeholder="Позиція"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="position"
                            id="bml-position"
                            defaultValue={data?.fields?.position}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>
                </div>
                <div className={s.top__img_block}>

                  {/* title_ua */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва УКР"
                          id="bml-name-ua"
                          errorComponent={errors?.title_ua &&
                          <p className="form-input-hint">{errors?.title_ua?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.title_ua,
                            })}
                            type="text"
                            placeholder="Назва УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="title_ua"
                            id="bml-name-ua"
                            defaultValue={data?.fields?.title_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* link_ua */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання УКР"
                          id="bml-link-ua"
                          errorComponent={errors?.link_ua &&
                          <p className="form-input-hint">{errors?.link_ua?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ua,
                            })}
                            type="text"
                            placeholder="Посилання УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ua"
                            id="bml-link-ua"
                            defaultValue={data?.fields?.link_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block}>
                      <div>
                        <ImageLoad
                            id={`product_img_mobile_ua`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataUa}
                            setImageData={setTypeMobileDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_main_page'
                            collectionName='mobile'
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x480 px<br />
                          (720x960 px)
                        </div>
                      </div>
                      <div style={{ width: 'calc(100% - 152px)' }}>
                        <ImageLoad
                            id={`product_img_ua`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataUa}
                            setImageData={setTypeCoverDataUa}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_main_page'
                            collectionName='cover'
                            windowName='tablet / desktop'
                            width={'100%'}
                            altData={{
                              alt_ua: '',
                              locale: 'ua'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1530x500 px<br />
                          (3060x1000 px)
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={s.top__img_block}>
                  {/* title_ru */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва РУС"
                          id="bml-name-ru"
                          errorComponent={errors?.title_ru &&
                          <p className="form-input-hint">{errors?.title_ru?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.title_ru,
                            })}
                            type="text"
                            placeholder="Назва РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="title_ru"
                            id="bml-name-ru"
                            defaultValue={data?.fields?.title_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* link_ru */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <InputWrapper
                          label="Посилання РУС"
                          id="bml-link-ru"
                          errorComponent={errors?.link_ru &&
                          <p className="form-input-hint">{errors?.link_ru?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.link_ru,
                            })}
                            type="text"
                            placeholder="Посилання РУС"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="link_ru"
                            id="bml-link-ru"
                            defaultValue={data?.fields?.link_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  <div>
                    <div className={s.img_block}>
                      <div>
                        <ImageLoad
                            id={`product_img_mobile_ru`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeMobileDataRu}
                            setImageData={setTypeMobileDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_main_page'
                            collectionName='mobile'
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          360x480 px<br />
                          (720x960 px)
                        </div>
                      </div>
                      <div style={{ width: 'calc(100% - 152px)' }}>
                        <ImageLoad
                            id={`product_img_ru`}
                            nameParent={`product_images[image_list]`}
                            formH={formH}
                            accept="image/*"
                            acceptStr="PNG, JPG, GIF"
                            imageData={typeCoverDataRu}
                            setImageData={setTypeCoverDataRu}
                            setActiveMedia={setActiveMedia}
                            setActiveRemove={setActiveRemove}
                            setDataModal={setDataModal}
                            prodID={bmhID}
                            entityType='promo_main_page'
                            collectionName='cover'
                            windowName='tablet / desktop'
                            width={'100%'}
                            langLabel="HRU"
                            altData={{
                              alt_ru: '',
                              locale: 'ru'
                            }}
                        />
                        <div style={{ textAlign: 'center', marginTop: '16px' }}>
                          1530x500 px<br />
                          (3060x1000 px)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ) : <Loader />}
        </Form>

         {activeMedia && <ModalMedia
               active={activeMedia}
               setActive={setActiveMedia}
               data={dataModal}
               collectionName={dataModal?.collection_name === 'cover' ? 'cover' : 'mobile'}
               locale={dataModal?.custom_properties?.locale === 'ru' ? 'ru' : 'ua'}
               setImageData={dataModal?.custom_properties?.locale === 'ru'
                   ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                   : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
               }
               title='Редагування картинки'
           />}
           {activeRemove && <ModalRemove
               active={activeRemove}
               setActive={setActiveRemove}
               data={dataModal}
               funcRemove={removeMedia}
               setRemoveItem={dataModal?.custom_properties?.locale === 'ru'
                   ? dataModal?.collection_name === 'cover' ? setTypeCoverDataRu : setTypeMobileDataRu
                   : dataModal?.collection_name === 'cover' ? setTypeCoverDataUa : setTypeMobileDataUa
               }
               title='Видалити банер з головної сторінки'
           />}
      </>
  )
};

export default BannerManagementHomeEditView;