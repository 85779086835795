import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData, remove } from "./api";
import { geDomainName } from "../../lib/const/domains";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";

const CreditOffersList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Credit offer list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        'ID', 'Назва', 'Домен', 'Тип банківського продукту', 'Статус активності'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'title'},
                            {type: 'select', name: 'domain_id', options: filtersData?.domains && filtersData.domains},
                            {
                                type: 'select',
                                name: 'is_active',
                                options: [
                                    {
                                        id: 0,
                                        title: 'Не активна',
                                    },
                                    {
                                        id: 1,
                                        title: 'Активна',
                                    },
                                ],
                            },
                            {},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                        loading={!filtersData}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={5}/>
                    )
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`credit-offer-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Кредитну пропозицію видалено',
                                    }}
                                    editLink={`/app/credit-offer/update/${e.id}`}
                                    permissionRemove='admin.credit-offer.destroy'
                                    permissionEdit='admin.credit-offer.edit'
                                >
                                    <td>
                                        {checkPermission('admin.credit-offer.edit')
                                            ? <Link to={`/app/credit-offer/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        <span>
                                            {e?.title || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.domain_id ?  geDomainName(e.domain_id)?.name : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.credit_type_id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={5}>Немає кредитних пропозицій</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default CreditOffersList;
