import {
  SPEC_OFFER_BANNER_TAG_GET,
  SPEC_OFFER_BANNER_TAG_CREATE,
  SPEC_OFFER_BANNER_TAG_GET_EDIT,
  SPEC_OFFER_BANNER_TAG_EDIT,
  SPEC_OFFER_BANNER_TAG_DELETE,
} from '../../const/api';

import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSpecOfferBannersTag = (params) => fetchAx({
  url: SPEC_OFFER_BANNER_TAG_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createSpecOfferBannersTag = (data) => fetchAx({
  url: SPEC_OFFER_BANNER_TAG_CREATE,
  method: 'POST',
  data,
});

export const getSpecOfferBannersTagEdit = (id) => fetchAx({
  url: SPEC_OFFER_BANNER_TAG_GET_EDIT.join(id),
  method: 'GET',
});

export const editSpecOfferBannersTag = (id, data) => fetchAx({
  url: SPEC_OFFER_BANNER_TAG_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeSpecOfferBannersTag = (id) => fetchAx({
  url: SPEC_OFFER_BANNER_TAG_DELETE.join(id),
  method: 'DELETE',
});
