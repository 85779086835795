import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import GridEditView from '../../components/ui/GridEditView';
import Title from "../../components/ui/FormEditView/Title";
import useEdit from "../../lib/crud/useEdit";
import {
    create, edit, getData, parseDataSend, parseDataGet, remove,
} from './api';
import Loader from "../../components/ui/Loader";
import useTabsForm from "../../lib/useTabsForm";
import SideNavEditView from "../../components/common/SideNavEditView";
import TabInfo from "./tabs/TabInfo";
import TabMetaTeg from "./tabs/TabMetaTeg";
import TabProduct from "./tabs/TabProduct";
import TabConstructor from "./constructor/TabConstructor";
import TabConstructorUa from "./constructor/TabConstructorUa";
import TabImages from "./tabs/TabImages";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const ArticleEditView = () => {
    const { id: clientID } = useParams();

    const tabsForm = useTabsForm({
        tabs: [
            'Параметры',
            'Изображения',
            'Конструктор РУ',
            'Конструктор УКР',
            'Мета-теги',
            'Товары',
        ],
    });

    const { load, data, submitHandler } = useEdit({
        targetID: clientID,
        submitCb: (cb) => tabsForm.validate(null, cb),
        api: {
            create,
            edit,
            remove,
            get: getData,
        },
        parse: {
            send: parseDataSend,
            get: parseDataGet,
        },
        asyncDataGetKey: 'assets_data',
        redirects: {
            notFound: '/app/article/not-found',
            remove: `/app/articles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
            save: `/app/articles${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
        },
        alerts: {
            create: 'Новость создана',
            edit: 'Новость обновлена',
            remove: 'Новость удалена',
        },
    });

    const formProps = {
        clientID,
        load,
        data,
        tabsForm,
        submitHandler,
    };

    const [htmlConstructorRu, setHtmlConstructorRu] = useState('');
    const [htmlConstructorUa, setHtmlConstructorUa] = useState('');

    useEffect(() => {
        if(data?.fields?.body_ru) {
            setHtmlConstructorRu(data?.fields?.body_ru);
        }
    }, [data?.fields?.body_ru]);

    useEffect(() => {
        if(data?.fields?.body_ua) {
            setHtmlConstructorUa(data?.fields?.body_ua);
        }
    }, [data?.fields?.body_ua]);

    return(
        <GridEditView
            left={(
                <div className="box-wrap box-wrap--sticky box-wrap--mb">
                    {data && (
                        <SideNavEditView
                            items={tabsForm.items}
                            onClick={tabsForm.setActiveIndex}
                            checkActiveCb={tabsForm.isActive}
                            checkErrorCb={tabsForm.isError}
                        />
                    )}

                    {load && <Loader />}
                </div>
            )}
            col={9}
            /* reverse */
        >
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Article edit`} />
            <div className="box-wrap box-wrap--mb">
                {clientID
                    ? (
                        <Title single>
                            Новость -
                            <small>{data && data.fields.id}</small>
                        </Title>
                    )
                    : <Title single>Новыя новость</Title>}
            </div>

            <div className="p-relative">
                {data ? <TabInfo formProps={formProps} index={0} /> : <Loader />}
                {data ? <TabImages formProps={formProps} index={1} /> : <Loader />}
                {data ? <TabConstructor formProps={formProps} data={data} htmlData={htmlConstructorRu} setHtmlData={setHtmlConstructorRu} index={2} /> : <Loader />}
                {data ? <TabConstructorUa formProps={formProps} data={data} htmlData={htmlConstructorUa} setHtmlData={setHtmlConstructorUa} index={3} /> : <Loader />}
                {data ? <TabMetaTeg formProps={formProps} index={4} /> : <Loader />}
                {data ? <TabProduct formProps={formProps} index={5} /> : <Loader />}
            </div>
        </GridEditView>
    )
}

export default ArticleEditView;