export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.h2-custom-absolute {
                top: 40px;
            }
            div.h2-custom-absolute > h2 > span {
                font-weight: 700;
                font-size: 50px !important;
                line-height: 65px !important;
            }
            
            @media screen and (max-width: 992px) {
                div.h2-custom-absolute {
                    top: 20px;
                }
                div.h2-custom-absolute > h2 > span {
                    font-size: 36px !important;
                    line-height: 40px !important;
                }
            }
            
            @media screen and (max-width: 480px) {
                div.h2-custom-absolute {
                    top: 10px;
                }
                div.h2-custom-absolute > h2 > span {
                    font-size: 15px !important;
                    line-height: 20px !important;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
                <div class="image-absolute-title-native position-relative">
                    <div class="position-absolute h2-custom-absolute start-50 translate-middle-x">
                        <h2><span>Заголовок H2</span></h2>
                    </div>
                    <img src="https://via.placeholder.com/1530x680" alt="" />
                </div>
                ${style}
        `,
    })
};