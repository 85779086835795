import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import sSelect from "../../OrderEditView/fakeData/orderStyle.module.scss";
import Button from "../../../components/ui/Button";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import { getDomains } from "../../../lib/api/domains";
import { createTopBottomMenu, editTopBottomMenu, getTopBottomMenuEdit } from "../../../lib/api/top_and_bottom_menu";
import errorMessage from "../../../lib/errorMessage";

const ModalTopAndBottomMenu = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);
    const [dataSelect, setDataSelect] = useState(null);

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            title_ua: '',
            title_ru: '',
            link_ua: '',
            link_ru: '',
            position: 1,
            domain_id: 1,
            is_active: false,
            is_top: 1
        }
    });

    const {
        register,
        errors,
        watch,
        handleSubmit,
        setValue
    } = formH;

    const isActiveWatch = watch('is_active')

    const submitHandler = async (data) => {
        if (editId) {
            await editTopBottomMenu(editId, data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        } else {
            await createTopBottomMenu(data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        }
    }

    useEffect(() => {
        const getFiltersData = () => Promise.allSettled([getDomains()])
            .then((res) => setDataSelect({
                domains: res[0]?.value?.data
            }));

        getFiltersData()
    }, []);

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getTopBottomMenuEdit(editId).then((res) => {
                setValue('title_ua', res.data.title_ua);
                setValue('title_ru', res.data.title_ru);
                setValue('link_ua', res.data.link_ua);
                setValue('link_ru', res.data.link_ru);
                setValue('position', res.data.position);
                setValue('domain_id', res.data.domain_id);
                setValue('is_active', !!res.data.is_active);
                setValue('visibility', res.data.visibility);
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Нове верхнє меню'}</div>
                        </div>
                        <div>
                            {/* is_active */}
                            <CheckboxSwitch
                                name="is_active"
                                id="tabm-is-active"
                                formH={formH}
                                defaultChecked={isActiveWatch}
                            />
                        </div>
                    </div>
                    <div>
                        {/* title_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Назва УКР"
                                    id="tabm-name-ua"
                                    errorComponent={errors?.title_ua &&
                                    <p className="form-input-hint">{errors?.title_ua?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.title_ua,
                                        })}
                                        type="text"
                                        placeholder="Назва УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ua"
                                        id="tabm-name-ua"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* title_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Назва РУС"
                                    id="tabm-name-ru"
                                    errorComponent={errors?.title_ru &&
                                    <p className="form-input-hint">{errors?.title_ru?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.title_ru,
                                        })}
                                        type="text"
                                        placeholder="Назва РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="title_ru"
                                        id="tabm-name-ru"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* link_ua */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Посилання УКР"
                                    id="tabm-link-ua"
                                    errorComponent={errors?.link_ua &&
                                    <p className="form-input-hint">{errors?.link_ua?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.link_ua,
                                        })}
                                        type="text"
                                        placeholder="Посилання УКР"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="link_ua"
                                        id="tabm-link-ua"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* link_ru */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Посилання РУС"
                                    id="tabm-link-ru"
                                    errorComponent={errors?.link_ru &&
                                    <p className="form-input-hint">{errors?.link_ru?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.link_ru,
                                        })}
                                        type="text"
                                        placeholder="Посилання РУС"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="link_ru"
                                        id="tabm-link-ru"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* position */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Позиція"
                                    id="tabm-position"
                                    errorComponent={errors?.position &&
                                    <p className="form-input-hint">{errors?.position?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.position,
                                        })}
                                        type="number"
                                        placeholder="Позиція"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="position"
                                        id="tabm-position"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* domain_id */}
                        {!!dataSelect?.domains?.length && (
                            <Group attrs={{ style: { margin: 0 } }}>
                                <ColFull>
                                    <div className={sSelect.select__inner}>
                                        <div className={sSelect.select__wrapper}>
                                            <select
                                                name="domain_id"
                                                className={cn(sSelect.select, {
                                                    'is-error': errors.domain_id,
                                                })}
                                                id="tabm-domain"
                                                ref={register({
                                                    required: VALIDATION.select.required,
                                                })}
                                            >
                                                {dataSelect?.domains.map((domain) => (
                                                    <option
                                                        key={domain.id}
                                                        value={domain.id}
                                                    >
                                                        {domain.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={sSelect.label} htmlFor="tabm-domain">Домен</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                        )}

                        {/* is_top */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <div className={sSelect.select__inner}>
                                    <div className={sSelect.select__wrapper}>
                                        <select
                                            name="is_top"
                                            className={cn(sSelect.select, {
                                                'is-error': errors.is_top,
                                            })}
                                            id="tabm-is-top"
                                            ref={register({
                                                required: VALIDATION.select.required,
                                            })}
                                        >
                                            <option value={0}>Нижнє меню</option>
                                            <option value={1}>Верхнє меню</option>
                                        </select>
                                        <label className={sSelect.label} htmlFor="tabm-is-top">Вид меню</label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>

                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__group}>
                            <div className={s_Modal.modal__buttons}>
                                <div>
                                    <Button purple type='submit' disabled={load}>Додати</Button>
                                </div>
                            </div>

                            <div>
                                <Button border onClick={() => setActive(false)}>Скасувати</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalTopAndBottomMenu;