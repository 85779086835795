import {
  SEO_META_CREATE, SEO_META_GET_EDIT, SEO_META_EDIT, SEO_META_DELETE, SEO_META_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSeoMeta = (params) => fetchAx({
  url: SEO_META_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createSeoMeta = (data) => fetchAx({
  url: SEO_META_CREATE,
  method: 'POST',
  data,
});

export const getSeoMetaEdit = (id) => fetchAx({
  url: SEO_META_GET_EDIT.join(id),
  method: 'GET',
});

export const editSeoMeta = (id, data) => fetchAx({
  url: SEO_META_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeSeoMeta = (id) => fetchAx({
  url: SEO_META_DELETE.join(id),
  method: 'DELETE',
});

