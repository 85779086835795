import Constants from "./constants";

export const initialSate = {};

const reducer = (state = initialSate, action) => {
  switch (action.type) {
    case Constants.ON_CHANGE_ORDER_FILTERS:
      return {
        ...state,
        ...action.data,
      };

    case Constants.CLEAR_ORDER_FILTERS:
      return {};

    default:
      return state;
  }
};

export default reducer;
