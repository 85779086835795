import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import s from "./topPanel.module.scss";
import s_Review from "./../../review.module.scss";
import icon_arrow_back from "../../../../assets/icons/icon_arrow-back.svg";
import Button from "../../../../components/ui/Button";
import icon_arrow_send from "../../../../assets/icons/icon_arrow_send.svg";
import icon_close from "../../../../assets/icons/icon_close.svg";
import getTZtoDate from "../../../../lib/getTZtoDate";
import Loader from "../../../../components/ui/Loader";
import { editReviews } from "../../../../lib/api/reviews";
import errorMessage from "../../../../lib/errorMessage";
import { toast } from "react-toastify";
import CopyComponent from "../../../../components/ui/CopyComponent";
import icon_lock from "../../../../assets/icons/icon_lock.svg";
import {checkVisitOrderEdit} from "../../../../lib/api/order";
import {refreshToken} from "../../../../lib/crud/refreshToken";

const TopPanel = ({
                      data,
                      breadcrumbs,
                      editBtn,
                      setModalCancel,
                      id,
                      sendData,
                      bodySend,
                      checkEdit,
                      editManager,
}) => {
    const [load, setLoad] = useState(false);
    const [statusList, setStatusList] = useState([]);

    const navigate = useNavigate();

    const [statusShow, setStatusShow] = useState(false);
    const [statusCheck, setStatusCheck] = useState('');
    const dataFields = data && data?.fields?.data;

    function saveHandler() {
        setLoad(true);
        const parseData = {};
        if(!!sendData?.length) {
            parseData['reply'] = sendData;
        }
        if(!!bodySend?.length) {
            parseData['body'] = bodySend;
        }
        parseData['status'] = "approved";
        editReviews(id, parseData)
            .then(res => {
                toast('Отзыв опубликован', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(`/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`);
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await editReviews(id, parseData)
                            .then(res => {
                                toast('Отзыв опубликован', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                navigate(`/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`);
                            })
                        window.location.reload();
                    })
                } else {
                    await errorMessage(err, '')
                }
                await errorMessage(err, `api/admin/social/reviews/${id} PUT`);
            })
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        if(data?.statuses) {
            setStatusList(data?.statuses);
        }

        if(data?.fields?.data?.status) {
            setStatusCheck(data?.fields?.data?.status);
        }
    }, [data]);

    const getStatusesName = (value) => statusList.find((status) => status?.value?.toString() === value?.toString());

    return (
        <>
            <div>
                <div className={s.breadcrumbs__wrapper}>
                    <div>
                        <Breadcrumbs {...breadcrumbs}/>
                        <div className={s.title}>
                            <div>{`Отзыв #${id} от ${getTZtoDate(dataFields?.created_at, true)}`}</div>
                            <div className={s_Review.review_client__copy}>
                                <CopyComponent copyText={`Отзыв #${id} от ${getTZtoDate(dataFields?.created_at, true)}`} />
                            </div>
                        </div>
                    </div>
                    {!checkEdit ? (
                            <div className={s.no_edit}>
                                <div className={s.no_edit__item}>
                                    <div className={s.no_edit__block}>
                                        <img src={icon_lock} alt="icon lock" />
                                        <div>Документ заблокирован</div>
                                    </div>
                                </div>
                                <div className={s.no_edit__item}>
                                    <div>Редактирует: <b>{editManager?.reservedBy?.name}</b></div>
                                </div>
                            </div>
                        ) : dataFields?.id && (
                        <div className={s.order_edited}>
                            <div className={s.order_edited__item}>
                                <div className={s.order_edited__block}>
                                    <div className={s.order_edited__title}>Отредактировано:</div>
                                    <div className={s.order_edited__date}>{dataFields?.updated_at && getTZtoDate(dataFields?.updated_at, true)}</div>
                                </div>
                                <div className={s.order_edited__block}>
                                    <div className={s.order_edited__title}>Модератор:</div>
                                    <div className={s.order_edited__date}>{dataFields?.manager ? dataFields?.manager?.name : '---'}</div>
                                </div>
                            </div>
                            <div className={s.order_edited__box}>
                                <button type="button" className={s.btn__status} onClick={() => setStatusShow(!statusShow)}>
                                    {getStatusesName(statusCheck)?.title}
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            </div>
            <div className={s.footer}>
                <div>
                    <Link to={`/app/reviews${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}>
                        <img src={icon_arrow_back} alt="Arrow back icon"/>
                        Назад
                    </Link>
                </div>
                {editBtn && (
                    <div className={s.buttons__wrapper}>
                        <div className={s.buttons__group}>
                            <div className={s.button__block}>
                                <Link to="/app/reviews">Отменить</Link>
                            </div>
                            <div className={s.button__block}>
                                <Button purple disabled={!checkEdit} onClick={saveHandler}>
                                    <img src={icon_arrow_send} alt="Accept icon"/>
                                    Опубликовать
                                </Button>
                            </div>

                            <div className={s.button__box}>
                                <div className={s.button__block}>
                                    <Button red disabled={!checkEdit} onClick={() => setModalCancel(prev => !prev)}>
                                        <img src={icon_close} alt="Accept icon"/>
                                        Отклонить
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {load && <Loader />}
        </>
    );
};

export default TopPanel;