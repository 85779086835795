import React from "react";
import s from "./CategoryWithProduct.module.scss";
import CheckboxTreeWrap from "../../../../components/ui/CheckboxTreeWrap";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Parent from "../../../AccessoryRelationEditView/AccessoryRelation.module.scss";
import icon_search from "../../../../assets/icons/icon_search.svg";

const CategoryWithProduct = ({ data, formH }) => {

    return (
        <div className={s.wrapper}>
            <div className={s.categories}>
                <div className={s_Parent.title}>Категории</div>
                <div className={s.tree_wrap}>
                    <CheckboxTreeWrap
                        data={data?.categories[0].children}
                        setData={() => {}}
                        formH={formH}
                        dataAll={data}
                        nameParent={`categories`}
                        nameTrigger={`feed`}
                        marginLeft={false}
                        expandedBtn
                        filtered
                    />
                </div>
            </div>
        </div>
    );
};

export default CategoryWithProduct;